import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { isEmpty } from 'lodash'
import * as yup from 'yup'
import type { GridColDef, GridRowId, GridRowParams, GridRowSelectionModel } from '@mui/x-data-grid'
import { DataGrid } from '@mui/x-data-grid'
import {
  Autocomplete,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@mui/material'
import Dialog from 'components/dialog/dialog'
import Icon from 'components/icon'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import {
  useGetAccountPermissionDetailQuery,
  useGetMainMenuListMutation,
  useGetSubMenuListMutation,
  useUpdateAccountPermissionMutation
} from 'store/assignment'
import { useGetMenuTreeQuery } from 'store/menu'
import { useGetRolesListDropdownMutation } from 'store/roles'
import type { PartialMenuPermissionMenusProps, PartialMenuPermissionProps } from 'modules/partial'
import type {
  DetailStateProps,
  ErrorProps,
  RolesAssignmentFunctionProps,
  RolesAssignmentMenuProps
} from 'modules/types'
import GlobalStyle from 'modules/styles'
import DialogStyle from './style'

type SubMenuProps = {
  menu?: string
  name: string
  id: number
  functions: RolesAssignmentFunctionProps[]
  convertedFunctions: string
}

const Edit = ({ open, id, onClose }: DetailStateProps & { id: string }) => {
  if (!open) return <></>

  const {
    data: dataAccount,
    isFetching: isAccountFetching,
    isLoading: isAccountLoading
  } = useGetAccountPermissionDetailQuery(id)

  const {
    data: dataMenu,
    isFetching: isMenuFetching,
    isLoading: isMenuLoading
  } = useGetMenuTreeQuery()

  const [getRolesListDropdown, rolesList] = useGetRolesListDropdownMutation()
  const [getMainMenuList, mainMenuList] = useGetMainMenuListMutation()
  const [getSubMenuList, subMenuList] = useGetSubMenuListMutation()
  const [updateAccountPermission, updateAccount] = useUpdateAccountPermissionMutation()

  const [isDeleteButtonPressed, setIsDeleteButtonPressed] = useState(false)

  const selectedRole =
    rolesList &&
    rolesList.data &&
    rolesList.data.find((el) => el.name == (dataAccount && dataAccount.roleName))

  const selectedMainMenu =
    dataMenu &&
    dataMenu.mainMenu &&
    dataMenu.mainMenu
      .map((main) => {
        const subMenu = main.subMenu.map((subMenu) => {
          const functions = subMenu.listFunction.filter(
            (func) => dataAccount && dataAccount.permissions.includes(func.name)
          )

          const filtered = functions.map((func) => ({
            id: func.functionId,
            name: func.name,
            type: func.type
          }))

          const list = {
            menu: main.name,
            name: subMenu.name,
            id: subMenu.subMenuId,
            functions: filtered,
            convertedFunctions: filtered.map((func) => func.name).join(', ')
          }

          return list
        })

        const filtered = subMenu.filter((sub) => sub.functions.length)

        return filtered
      })
      .filter((menu) => menu.length)
      .flat(1)

  const isNotEmpty = subMenuList && subMenuList.data && subMenuList.data.length > 0

  const subMenuColumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 96 },
    { field: 'name', headerName: 'Sub Menu', width: 240 }
  ]

  const functionNameColumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 96 },
    { field: 'name', headerName: 'Function Name', width: 240 }
  ]

  const selectedColumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 96 },
    { field: 'menu', headerName: 'Main Menu', width: 160 },
    { field: 'name', headerName: 'Sub Menu', width: 160 },
    { field: 'convertedFunctions', headerName: 'Function Name', width: 256 },
    {
      field: 'Action',
      headerName: 'Action',
      width: 96,
      sortable: false,
      renderCell: ({ row }: Partial<GridRowParams>) => (
        <IconButton onClick={() => onDeleteSelectionRow(row)}>
          <Icon icon='DeleteForever' />
        </IconButton>
      )
    }
  ]

  const [functionNameRows] = useState<{ id: string; name: string }[]>([
    { id: 'CREATE', name: 'Create' },
    { id: 'READ', name: 'View' },
    { id: 'UPDATE', name: 'Edit / Update' },
    { id: 'DELETE', name: 'Delete' }
  ])

  const [selectedSubMenu, setSelectedSubMenu] = useState<(RolesAssignmentMenuProps | undefined)[]>(
    selectedMainMenu as (RolesAssignmentMenuProps | undefined)[]
  )

  const [selectedFunction, setSelectedFunction] =
    useState<({ id: string; name: string } | undefined)[]>(functionNameRows)

  const [selectedRow, setSelectedRow] = useState<(RolesAssignmentMenuProps | undefined)[]>(
    selectedMainMenu as (RolesAssignmentMenuProps | undefined)[]
  )

  const onSubMenuSelectionRow = (ids: GridRowSelectionModel) => {
    const subMenu = subMenuList && subMenuList.data
    const list = isEmpty(selectedRow) ? subMenu : selectedRow.concat(subMenu)

    const SelectionRow = ids.map((id) => list && list.find((row) => row && row.id == id))

    setSelectedSubMenu(SelectionRow)
  }

  const onFunctionSelectionRow = (ids: GridRowSelectionModel) => {
    const SelectionRow = ids.map((id) => functionNameRows.find((row) => row.id == id))

    setSelectedFunction(SelectionRow)
  }

  const onAddSelectionRow = () => {
    const menu =
      mainMenuList &&
      mainMenuList.data &&
      mainMenuList.data.find((menu) => formik.values.menuId == menu.id)

    const functionId = selectedFunction.map((func) => func && func.id)
    const currentListedId =
      subMenuList && subMenuList.data && subMenuList.data.map((el) => el && el.id)

    const selected = selectedSubMenu.map((sub) => {
      if (sub) {
        const result: SubMenuProps = {
          menu: sub.menu,
          name: sub.name,
          id: sub.id,
          functions: sub.functions.filter((func) => functionId.includes(func.type)),
          convertedFunctions: sub.functions
            .filter((func) => functionId.includes(func.type))
            .map(({ name }) => name)
            .join(', ')
        }

        if (currentListedId && currentListedId.includes(sub.id)) {
          result.menu = menu && menu.name
        }

        return result
      }

      return undefined
    })

    const merged = (selectedRow ?? []).concat(selected)
    const filter = merged.filter(
      (obj, id) => obj && merged.findIndex((item) => (item && item.id) == obj.id) == id
    )

    setSelectedRow(filter)
  }

  const onDeleteSelectionRow = (row: RolesAssignmentMenuProps) => {
    const SelectionRow = selectedRow.filter((selected) => selected && selected.id !== row.id)
    setSelectedRow(SelectionRow)
    setIsDeleteButtonPressed(true)
  }

  const convertMenuPermissionMenus = (values: PartialMenuPermissionProps) => {
    const data = { ...values }

    delete data.menuId

    const mainMenu =
      selectedMainMenu &&
      selectedMainMenu.map((main) => main && main.functions.map((func) => func.id))

    const selectedList = selectedRow.map(
      (selected) => selected && selected.functions.map((func) => func.id)
    )

    const selectedFlattened = selectedList.flat(1)
    const selectMapped = selectedFlattened.map((id) => ({ FunctionId: id }))

    const listMenu = mainMenu && mainMenu.flat(1)
    const listMapped = listMenu && listMenu.map((id) => ({ FunctionId: id }))

    const resultFromList =
      listMapped &&
      listMapped.map((el) => ({
        FunctionId: el.FunctionId,
        Action: selectMapped.map((menu) => menu.FunctionId).includes(el.FunctionId)
          ? 'INSERT'
          : 'DELETE'
      }))

    const resultFromSelect =
      selectMapped &&
      selectMapped.map((el) => ({
        FunctionId: el.FunctionId as number,
        Action: 'INSERT'
      }))

    const merged = resultFromList && resultFromList.concat(resultFromSelect)
    const result =
      merged &&
      merged.filter(
        (obj, id) =>
          obj &&
          merged &&
          merged.findIndex((item) => (item && item.FunctionId) == obj.FunctionId) == id
      )

    return {
      ...data,
      Menus: result as PartialMenuPermissionMenusProps[]
    }
  }

  const onSubmit = (values: PartialMenuPermissionProps) => {
    const menus = convertMenuPermissionMenus(values)
    updateAccountPermission(menus)
  }

  const scheme = yup.object<PartialMenuPermissionProps>({
    userId: yup.string().required('Assign is required'),
    roleId: yup.number().positive('RoleId is required').required('RoleId is required'),
    menuId: yup.number().notRequired(),
    Menus: yup.array().of(
      yup.object({
        FunctionId: yup
          .number()
          .positive('FunctionId is required')
          .required('FunctionId is required'),
        Action: yup.string().required('Action is required')
      })
    )
  })

  const formik = useFormik<PartialMenuPermissionProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      userId: (dataAccount && dataAccount.userId) || '',
      roleId: (selectedRole && selectedRole.id) || 0,
      menuId: 0,
      Menus: []
    },
    onSubmit: onSubmit
  })

  useEffect(() => {
    getRolesListDropdown()
  }, [])

  if (
    isEmpty(selectedRow) &&
    selectedMainMenu &&
    selectedMainMenu.length &&
    !isDeleteButtonPressed
  ) {
    setSelectedRow(selectedMainMenu)
  }

  if (
    isEmpty(selectedSubMenu) &&
    !selectedSubMenu &&
    selectedRow &&
    selectedRow.length &&
    !isDeleteButtonPressed
  ) {
    setSelectedSubMenu(selectedRow)
  }

  return (
    <>
      <Dialog
        title='Edit Role Assignment'
        open={open}
        maxWidth='md'
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={rolesList.isLoading || updateAccount.isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          {(isAccountLoading || isAccountFetching) &&
            !dataAccount &&
            (isMenuLoading || isMenuFetching) &&
            !dataMenu && <Loading />}
          {dataAccount && dataMenu && (
            <>
              {selectedRole && (
                <Autocomplete
                  options={rolesList.data || []}
                  getOptionLabel={(list) => list.name}
                  isOptionEqualToValue={(option, value) =>
                    option && value ? option.id == value.id : false
                  }
                  value={selectedRole}
                  readOnly
                  ListboxProps={GlobalStyle.ListBox}
                  renderOption={(props, item) => (
                    <li {...props} key={item.id}>
                      {item.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name='roleId'
                      label='Select A Role'
                      value={formik.values.userId}
                      error={
                        formik.touched && formik.touched.roleId && Boolean(formik.errors.roleId)
                      }
                      helperText={
                        formik.touched &&
                        formik.touched.roleId &&
                        formik.errors &&
                        formik.errors.roleId
                      }
                      InputProps={{
                        ...params.InputProps,
                        readOnly: true,
                        endAdornment: (
                          <>
                            {rolesList.isLoading && <CircularProgress color='inherit' size={20} />}
                            {params.InputProps.endAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                />
              )}
              <TextField
                id='userId'
                variant='outlined'
                label='Assign Access To'
                value={dataAccount && dataAccount.fullName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched && formik.touched.userId && Boolean(formik.errors.userId)}
                helperText={
                  formik.touched && formik.touched.userId && formik.errors && formik.errors.userId
                }
                inputProps={{ readOnly: true }}
                fullWidth
              />
              <Typography {...DialogStyle.Permission}>Permissions</Typography>
              <Autocomplete
                options={mainMenuList.data || []}
                getOptionLabel={(list) => list.name}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.id == value.id : false
                }
                onOpen={() => getMainMenuList()}
                onChange={(_, id) => {
                  formik.setFieldValue('menuId', id && id.id)
                  getSubMenuList((id && id.id) || 0)
                }}
                onBlur={formik.handleBlur}
                ListboxProps={GlobalStyle.ListBox}
                renderOption={(props, item) => (
                  <li {...props} key={item.id}>
                    {item.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='menuId'
                    label='Select Main Menu'
                    error={formik.touched && formik.touched.menuId && Boolean(formik.errors.menuId)}
                    helperText={
                      formik.touched &&
                      formik.touched.menuId &&
                      formik.errors &&
                      formik.errors.menuId
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {mainMenuList.isLoading && <CircularProgress color='inherit' size={20} />}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
              />
              <Grid container columnSpacing={2}>
                <Grid item xs={6}>
                  <DataGrid
                    rows={(subMenuList && subMenuList.data) || []}
                    columns={subMenuColumns}
                    rowSelectionModel={
                      selectedSubMenu && (selectedSubMenu.map((el) => el && el.id) as GridRowId[])
                    }
                    onRowSelectionModelChange={onSubMenuSelectionRow}
                    keepNonExistentRowsSelected
                    checkboxSelection
                    hideFooter
                    autoHeight
                  />
                </Grid>
                {subMenuList && subMenuList.data && (
                  <Grid item xs={6}>
                    <DataGrid
                      rows={functionNameRows}
                      columns={functionNameColumns}
                      rowSelectionModel={selectedFunction.map((el) => el && el.id) as GridRowId[]}
                      onRowSelectionModelChange={onFunctionSelectionRow}
                      checkboxSelection
                      hideFooter
                      autoHeight
                    />
                  </Grid>
                )}
              </Grid>
              <Button variant='contained' disabled={!isNotEmpty} onClick={onAddSelectionRow}>
                Add
              </Button>
              {selectedRow && !isEmpty(selectedRow) && (
                <DataGrid rows={selectedRow} columns={selectedColumns} hideFooter autoHeight />
              )}
            </>
          )}
        </Container>
      </Dialog>

      <Notification
        open={!updateAccount.isLoading && !updateAccount.isUninitialized}
        onClose={() => (updateAccount.isError ? updateAccount.reset() : location.reload())}
        isError={updateAccount.isError}
        message={GLOBAL.returnExceptionMessage(
          updateAccount.isError,
          updateAccount.error as ErrorProps
        )}
      />
    </>
  )
}

export default Edit
