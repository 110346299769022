import { useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  TextField,
  Typography
} from '@mui/material'
import ReactQuill from 'react-quill'
import { decode } from 'html-entities'
import type { GridColDef } from '@mui/x-data-grid'
import { DataGrid } from '@mui/x-data-grid'
import Drawer from 'components/drawer/detail'
import Loading from 'components/loading/loading'
import type { DetailStateProps, ErrorProps, VoucherListTokenProps } from 'modules/types'
import { useGetListVoucherDetailQuery, useGetListVoucherTokenMutation } from 'store/voucher'
import Dialog from 'components/dialog/dialog'
import { useGetVoucherListDropdownMutation } from 'store/tokenRequest'
import GlobalStyle from 'modules/styles'
import { useRevokeTokenMutation, useMoveTokenMutation } from 'store/token'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { PartialTokenStateProps } from 'modules/partial'
import BackdropImage from 'components/backdropImage/backdropImage'
import DrawerStyle from './style'

const Detail = ({ open, id, onClose }: DetailStateProps & { id: string | number }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const { data, isFetching, isLoading } = useGetListVoucherDetailQuery(id)
  const loading = !data && (isLoading || isFetching)

  const [getVoucherToken, voucherToken] = useGetListVoucherTokenMutation()
  const [getVoucherListDropdown, voucherDropdown] = useGetVoucherListDropdownMutation()
  const [moveToken, move] = useMoveTokenMutation()
  const [revokeToken, revoke] = useRevokeTokenMutation()

  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [isRevoke, setIsRevoke] = useState<boolean>(false)
  const [dialogTitle, setDialogTitle] = useState<string>('')
  const [changeValue, setChangeValue] = useState<string | null>(null)
  const [previewBackdrop, setPreviewBackdrop] = useState<boolean>(false)
  const [bannerBackdrop, setBannerBackdrop] = useState<boolean>(false)

  const nameId = (data && data.nameId) || '-'
  const nameEn = (data && data.nameEn) || '-'
  const voucherSubCategory = (data && data.voucherSubCategory) || '-'
  const voucherCategory = (data && data.voucherCategory) || '-'
  const partner = (data && data.partnerName) || '-'
  const tokenCategory = (data && data.tokenCategory) || '-'
  const monetaryValue = (data && data.monetaryValue) || '-'
  const descriptionId = (data && data.descriptionId) || '-'
  const descriptionEn = (data && data.descriptionEn) || '-'
  const termsAndConditionsId = (data && data.termsAndConditionsId) || '-'
  const termsAndConditionsEn = (data && data.termsAndConditionsEn) || '-'
  const voucherType = (data && data.voucherType) || '-'
  const applicableAreaMode = (data && data.applicableAreaMode) || '-'
  const listVoucherToken = (data && data.listVoucherToken) || []
  const image = (data && data.image) || ''
  const banner = (data && data.banner) || ''

  const gridDataFirstLabel = [
    'Name ID',
    'Name EN-US',
    'Voucher Sub Category',
    'Voucher Category',
    'Partner',
    'Token Category',
    'Monetary Value'
  ]

  const gridDataFirstValue = [
    nameId,
    nameEn,
    voucherSubCategory,
    voucherCategory,
    partner,
    tokenCategory,
    monetaryValue
  ]

  const gridDataSecondLabel = ['voucher Type', 'applicable Area Mode']
  const gridDataSecondValue = [voucherType, applicableAreaMode]

  const selectedColumns: GridColDef[] = [
    { field: 'name', headerName: 'Token Request / Import Name', flex: 1 },
    { field: 'source', headerName: 'Source' }
  ]

  const stateVoucher = (title: 'Move' | 'Revoke') => {
    setIsRevoke(title === 'Revoke')
    getVoucherToken(Number(id)).then(() => {
      setDialogTitle(title)
      setOpenDialog(true)
    })
  }

  const submitChangeValue = () => {
    const voucherTokenId = voucherToken.data && voucherToken.data.map((el) => el.id)
    const payload = {
      voucherTokenId,
      [isRevoke ? 'reason' : 'voucherId']: changeValue
    } as unknown as PartialTokenStateProps

    return isRevoke ? revokeToken(payload) : moveToken(payload)
  }

  return (
    <>
      <Drawer open={open} title='Voucher Detail' onClose={onClose}>
        <Container {...DrawerStyle.Container}>
          {loading && <Loading />}
          {!loading && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  marginBottom: 2,
                  flexDirection: 'column',
                  justifyContent: 'flex-end'
                }}
              >
                <Button variant='contained' color='error' onClick={() => stateVoucher('Move')}>
                  Move all tokens from this voucher
                </Button>
                <Button variant='contained' color='error' onClick={() => stateVoucher('Revoke')}>
                  Revoke all tokens from this voucher
                </Button>
              </Box>
              <Grid container spacing={2}>
                {gridDataFirstValue.map((item, index) => (
                  <Grid key={index} item xs={12}>
                    <TextField
                      variant='outlined'
                      label={gridDataFirstLabel[index]}
                      value={item}
                      inputProps={{ readOnly: true }}
                      fullWidth
                    />
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <Typography {...DrawerStyle.Title}>Description ID</Typography>
                  <ReactQuill
                    theme='snow'
                    value={descriptionId ? decode(descriptionId) : ''}
                    readOnly={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography {...DrawerStyle.Title}>Description EN-US</Typography>
                  <ReactQuill
                    theme='snow'
                    value={descriptionEn ? decode(descriptionEn) : ''}
                    readOnly={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography {...DrawerStyle.Title}>Terms and Condition ID</Typography>
                  <ReactQuill
                    theme='snow'
                    value={termsAndConditionsId ? decode(termsAndConditionsId) : ''}
                    readOnly={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography {...DrawerStyle.Title}>Terms and Condition EN-US</Typography>
                  <ReactQuill
                    theme='snow'
                    value={termsAndConditionsEn ? decode(termsAndConditionsEn) : ''}
                    readOnly={true}
                  />
                </Grid>
                {gridDataSecondValue.map((item, index) => (
                  <Grid key={index} item xs={12}>
                    <TextField
                      variant='outlined'
                      label={gridDataSecondLabel[index]}
                      value={item}
                      inputProps={{ readOnly: true }}
                      fullWidth
                    />
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <Typography {...DrawerStyle.Title}>Token</Typography>
                  <DataGrid
                    getRowId={(row: VoucherListTokenProps) => row.created}
                    rows={listVoucherToken as readonly VoucherListTokenProps[]}
                    columns={selectedColumns}
                    hideFooter
                    autoHeight
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography {...DrawerStyle.Title}>Voucher Image</Typography>
                  <Box {...DrawerStyle.Image}>
                    {image && (
                      <BackdropImage
                        open={previewBackdrop}
                        onOpen={() => setPreviewBackdrop(true)}
                        onClose={() => setPreviewBackdrop(false)}
                      >
                        <img src={image} alt='Voucher Image' width='100%' />
                      </BackdropImage>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography {...DrawerStyle.Title}>Voucher Image Banner</Typography>
                  <Box {...DrawerStyle.Image}>
                    {banner && (
                      <BackdropImage
                        open={bannerBackdrop}
                        onOpen={() => setBannerBackdrop(true)}
                        onClose={() => setBannerBackdrop(false)}
                      >
                        <img src={banner} alt='Voucher Image Banner' width='100%' />
                      </BackdropImage>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </>
          )}
        </Container>
      </Drawer>
      <Dialog
        title={`${dialogTitle} Token`}
        open={openDialog}
        onCancel={() => {
          setChangeValue(null)
          setOpenDialog(false)
        }}
        onSubmit={() => submitChangeValue()}
        loading={isRevoke ? revoke.isLoading : move.isLoading}
        isDisabled={isEmpty(changeValue)}
      >
        <TextField
          multiline
          rows={4}
          value={voucherToken.data && voucherToken.data.map((el) => el.code).join('\n')}
          inputProps={{ readOnly: true }}
        />
        {!isRevoke && (
          <Autocomplete
            key={changeValue}
            options={(voucherDropdown && voucherDropdown.data) || []}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) =>
              option && value ? option.id == value.id : false
            }
            onOpen={() => getVoucherListDropdown()}
            onChange={(_, voucher) => setChangeValue((voucher && String(voucher.id)) || null)}
            value={
              (voucherDropdown &&
                voucherDropdown.data &&
                voucherDropdown.data.find((e) => e.id == changeValue)) ||
              null
            }
            ListboxProps={GlobalStyle.ListBox}
            renderOption={(props, item) => (
              <li {...props} key={item.id}>
                {item.name}
              </li>
            )}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                name='voucherToken'
                label='Voucher Name'
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {voucherDropdown.isLoading && <CircularProgress color='inherit' size={20} />}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
        )}
        {isRevoke && (
          <TextField
            label='Reason'
            id='Reason'
            multiline
            rows={4}
            onChange={(event) => setChangeValue(event.target.value)}
          />
        )}
      </Dialog>
      <Notification
        open={!revoke.isLoading && !revoke.isUninitialized}
        onClose={() => (revoke.isError ? revoke.reset() : location.reload())}
        isError={Boolean(revoke.error) && revoke.isError}
        message={GLOBAL.returnExceptionMessage(revoke.isError, revoke.error as ErrorProps)}
      />
      <Notification
        open={!move.isLoading && !move.isUninitialized}
        onClose={() => (move.isError ? move.reset() : location.reload())}
        isError={Boolean(move.error) && move.isError}
        message={GLOBAL.returnExceptionMessage(move.isError, move.error as ErrorProps)}
      />
    </>
  )
}

export default Detail
