/* eslint-disable @typescript-eslint/no-explicit-any */
import { Card, CardHeader, CardMedia, Container, Grid, TextField } from '@mui/material'
import dayjs from 'dayjs'
import Drawer from 'components/drawer/detail'
import Loading from 'components/loading/loading'
import type { DetailStateProps } from 'modules/types'
import { useGetTokenDetailFailedQuery, useGetTokenDetailQuery } from 'store/token'
import DrawerStyle from './style'

const Detail = ({
  open,
  id,
  isFailed,
  onClose
}: DetailStateProps & { id: string | number; isFailed: boolean }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const { data, isFetching, isLoading } = isFailed
    ? useGetTokenDetailFailedQuery(id)
    : useGetTokenDetailQuery(id)

  const arrData = [
    { value: 'code', label: 'Code' },
    { value: 'validFrom', label: 'Valid From', type: 'date' },
    { value: 'validUntil', label: 'Valid Until', type: 'date' },
    { value: 'voucherName', label: 'Voucher Name' },
    { value: 'partnerName', label: 'Partner' },
    { value: 'isPurchased', label: 'Purchased (Yes/No)', type: 'bool' },
    { value: 'isClaimed', label: 'Claimed (Yes/No)', type: 'bool' },
    { value: 'isRevoked', label: 'Revoked (Yes/No)', type: 'bool' },
    { value: 'purchasedBy', label: 'Purchased By Id' },
    { value: 'purchasedByName', label: 'Purchased By Name' },
    { value: 'claimedBy', label: 'Claimed By Id' },
    { value: 'claimedByName', label: 'Claimed By Name' },
    { value: 'revokedBy', label: 'Revoked By Id' },
    { value: 'revokedByName', label: 'Revoked By Name' },
    { value: 'revocationNotes', label: 'Revoked Notes' },
    { value: 'remarks', label: 'Remark' },
    { value: 'tokenImportId', label: 'Token Import Id' },
    { value: 'tokenRequestId', label: 'Token Request Id' },
    { value: 'isExpired', label: 'Expired' },
    { value: 'voucherImage', label: 'Voucher Image', type: 'image' }
  ]

  const checkBoolean = (str: string) => {
    if (str?.toString() == 'false') {
      return 'No'
    } else if (str?.toString() == 'true') {
      return 'Yes'
    }
    return str
  }

  return (
    <>
      <Drawer open={open} title='Detail Token' onClose={onClose}>
        <Container {...DrawerStyle.Container}>
          {(isLoading || isFetching) && !data && <Loading />}
          {data && (
            <>
              <Grid container spacing={2} columns={16}>
                {arrData.map((item, index) => {
                  if (item.type === 'image') {
                    return (
                      <Grid item xs={8} key={index}>
                        <Card key={index}>
                          <CardHeader subheader={item.label} />
                          <CardMedia
                            component='img'
                            height='100'
                            image={data[item.value as never]}
                            alt={item.label}
                            sx={{ objectFit: 'contain' }}
                          />
                        </Card>
                      </Grid>
                    )
                  }
                  return (
                    <Grid item xs={8} key={index}>
                      <TextField
                        key={index}
                        variant='outlined'
                        label={item.label}
                        value={
                          data && item?.type === 'date'
                            ? dayjs(data[item.value as never]).format('DD MMMM YYYY HH:mm')
                            : checkBoolean(data[item.value as never])
                        }
                        inputProps={{ readOnly: true }}
                      />
                    </Grid>
                  )
                })}
              </Grid>
            </>
          )}
        </Container>
      </Drawer>
    </>
  )
}

export default Detail
