import CONSTANT from 'modules/constant'
import type {
  PaginationProps,
  ResultProps,
  VoucherDetailProps,
  VoucherListProps,
  VoucherListTokenCodeProps
} from 'modules/types'
import type { PartialVoucherEnableProps } from 'modules/partial'
import { API, download, headers } from './api'

const VoucherAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getListVoucher: builder.mutation<PaginationProps<VoucherListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_LIST,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    downloadListVoucher: builder.mutation<PaginationProps<VoucherListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        responseHandler: download,
        formData: true
      })
    }),
    enableListVoucher: builder.mutation<ResultProps, PartialVoucherEnableProps>({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_ENABLED,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    getListVoucherDetail: builder.query<VoucherDetailProps, string | number>({
      query: (id) => ({
        url: CONSTANT.URL_VOUCHER_DETAIL + id,
        method: 'GET',
        headers: headers()
      })
    }),
    createListVoucher: builder.mutation<ResultProps, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_CREATE,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    updateListVoucher: builder.mutation<ResultProps, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_UPDATE,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    getListVoucherToken: builder.mutation<VoucherListTokenCodeProps[], number>({
      query: (id) => ({
        url: CONSTANT.URL_VOUCHER_LIST_TOKEN + id,
        method: 'GET',
        headers: headers()
      })
    }),
    deleteListVoucher: builder.mutation<ResultProps, { Id: number }>({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_DELETE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    })
  })
})

export const {
  useGetListVoucherMutation,
  useDownloadListVoucherMutation,
  useEnableListVoucherMutation,
  useGetListVoucherDetailQuery,
  useCreateListVoucherMutation,
  useUpdateListVoucherMutation,
  useGetListVoucherTokenMutation,
  useDeleteListVoucherMutation
} = VoucherAPI
