import CONSTANT from 'modules/constant'
import type { PartialUpdateEvProps } from 'modules/partial'
import type {
  DetailEvLocationProps,
  EvLocationProps,
  PaginationProps,
  ResultProps
} from 'modules/types'
import { API, download, headers } from './api'

export const EvLocation = API.injectEndpoints({
  endpoints: (builder) => ({
    getEvLocation: builder.mutation<PaginationProps<EvLocationProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_EV_LOCATION,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body
      })
    }),
    downloadEvLocation: builder.mutation<PaginationProps<EvLocationProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_EV_LOCATION_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        responseHandler: download,
        body: body
      })
    }),
    createEvLocation: builder.mutation<ResultProps, PartialUpdateEvProps>({
      query: (body) => ({
        url: CONSTANT.URL_EV_LOCATION_CREATE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    updateEvLocation: builder.mutation<ResultProps, { body: PartialUpdateEvProps; id: number }>({
      query: ({ body, id }) => ({
        url: CONSTANT.URL_EV_LOCATION_UPDATE + id,
        method: 'PUT',
        headers: headers(),
        body: body
      })
    }),
    getDetailEvLocation: builder.query<DetailEvLocationProps, number | string>({
      query: (id) => ({
        url: CONSTANT.URL_EV_LOCATION_DETAIL + id,
        method: 'GET',
        headers: headers()
      })
    }),
    deleteEvLocation: builder.mutation<ResultProps, number | string>({
      query: (id) => ({
        url: CONSTANT.URL_EV_LOCATION_DELETE + id,
        method: 'DELETE',
        headers: headers({ useContentType: false })
      })
    })
  })
})

export const {
  useGetEvLocationMutation,
  useDownloadEvLocationMutation,
  useCreateEvLocationMutation,
  useDeleteEvLocationMutation,
  useUpdateEvLocationMutation,

  useGetDetailEvLocationQuery
} = EvLocation
