/* eslint-disable @typescript-eslint/no-explicit-any */

import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { Autocomplete, CircularProgress, Container, TextField } from '@mui/material'
import Dialog from 'components/dialog/dialog'
import Loading from 'components/loading/loading'
import type { DetailStateProps, ErrorProps, VoucherSubCategoryListProps } from 'modules/types'
import {
  useGetVoucherCategoryDropdownMutation,
  useGetVoucherSubCategoryDetailQuery,
  useUpdateVoucherSubCategoryMutation
} from 'store/voucherCategory'
import type { PayloadVoucherSubCategoryProps } from 'modules/partial'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import GlobalStyle from 'modules/styles'
import DialogStyle from './style'

const Edit = ({ open, id, onClose }: DetailStateProps & { id: string | number }) => {
  if (!open) return <></>

  const { data, isFetching, isLoading } = useGetVoucherSubCategoryDetailQuery(id)
  const [updateVoucherSubCategory, update] = useUpdateVoucherSubCategoryMutation()
  const [getVoucherCategoryDropdown, dropdownCategory] = useGetVoucherCategoryDropdownMutation()

  const scheme = yup.object<PayloadVoucherSubCategoryProps>({
    nameEn: yup.string().required('Voucher Category Name English is required'),
    nameId: yup.string().required('Voucher Category Name ID is required'),
    description: yup.string().required('Voucher Category Description is required'),
    seq: yup.number().required('Voucher Category Sequence is required'),
    categoryId: yup.number().required(' Category Name is required')
  })

  const formik = useFormik<VoucherSubCategoryListProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      id: (data && data?.id) || '',
      nameEn: (data && data?.nameEn) || '',
      nameId: (data && data?.nameId) || '',
      description: (data && data?.description) || '',
      seq: (data && data?.seq) || '',
      categoryId: (data && data.categoryId) || '',
      createdAt: '',
      status: ''
    },
    onSubmit: (values: VoucherSubCategoryListProps) => handleSubmit(values)
  })

  const handleSubmit = (e: VoucherSubCategoryListProps) => {
    const payload: any = {
      nameEn: e.nameEn,
      nameId: e.nameId,
      Description: e.description,
      Seq: Number(e.seq),
      Id: e.id,
      CategoryId: Number(e.categoryId),
      status: 'Enabled'
    }
    updateVoucherSubCategory(payload)
  }

  return (
    <>
      <Dialog
        open={open}
        title='Update Voucher Sub Category'
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          {(isLoading || isFetching) && !data && <Loading />}
          {data && (
            <>
              <FormikProvider value={formik}>
                <TextField
                  id='nameEn'
                  variant='outlined'
                  label='Voucher Sub Category Name EN-US'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.nameEn}
                  error={formik.touched && formik.touched.nameEn && Boolean(formik.errors.nameEn)}
                  helperText={
                    formik.touched && formik.touched.nameEn && formik.errors && formik.errors.nameEn
                  }
                  fullWidth
                />
                <TextField
                  id='nameId'
                  variant='outlined'
                  label='Voucher Sub Category Name ID'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.nameId}
                  error={formik.touched && formik.touched.nameId && Boolean(formik.errors.nameId)}
                  helperText={
                    formik.touched && formik.touched.nameId && formik.errors && formik.errors.nameId
                  }
                  fullWidth
                />

                <Autocomplete
                  options={dropdownCategory.data || []}
                  defaultValue={{
                    name: data.categoryName || '',
                    id: data?.categoryId || '',
                    key: '',
                    language: ''
                  }}
                  getOptionLabel={(list) => list.name}
                  isOptionEqualToValue={(option, value) =>
                    option && value ? option.id == value.id : false
                  }
                  onOpen={() => getVoucherCategoryDropdown()}
                  onChange={(_, id) => formik.setFieldValue('categoryId', id && id.id)}
                  ListboxProps={GlobalStyle.ListBox}
                  renderOption={(props, item) => (
                    <li {...props} key={item.id}>
                      {item.name}
                    </li>
                  )}
                  readOnly
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name='categoryId'
                      label='Select A Category'
                      error={
                        formik.touched &&
                        formik.touched.categoryId &&
                        Boolean(formik.errors.categoryId)
                      }
                      helperText={
                        formik.touched &&
                        formik.touched.categoryId &&
                        formik.errors &&
                        formik.errors.categoryId
                      }
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {dropdownCategory.isLoading && (
                              <CircularProgress color='inherit' size={20} />
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                />

                <TextField
                  id='description'
                  variant='outlined'
                  label='Description'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                  error={
                    formik.touched &&
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched &&
                    formik.touched.description &&
                    formik.errors &&
                    formik.errors.description
                  }
                  fullWidth
                />

                <TextField
                  id='seq'
                  variant='outlined'
                  label='Sequence'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.seq}
                  error={formik.touched && formik.touched.seq && Boolean(formik.errors.seq)}
                  helperText={
                    formik.touched && formik.touched.seq && formik.errors && formik.errors.seq
                  }
                  fullWidth
                />
              </FormikProvider>
            </>
          )}
        </Container>
      </Dialog>

      <Notification
        open={!update.isLoading && !update.isUninitialized}
        onClose={() => (update.isError ? update.reset() : location.reload())}
        isError={Boolean(update.error) && update.isError}
        message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
      />
    </>
  )
}

export default Edit
