import type { LoadingButtonProps } from '@mui/lab'
import type { BoxProps, ContainerProps, StackProps, TextFieldProps } from '@mui/material'

class DetailStyle {
  static Container: ContainerProps = {
    maxWidth: false,
    disableGutters: true,
    sx: {
      display: 'flex',
      gap: '1rem',
      flexDirection: 'column'
    }
  }

  static Stack: StackProps = {
    direction: 'column',
    spacing: 2
  }

  static Submit: LoadingButtonProps = {
    sx: {
      alignSelf: 'end'
    }
  }

  static wrapperBox: BoxProps = {
    sx: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      position: 'relative'
    }
  }

  static wrapperLeftContent: BoxProps = {
    sx: {
      padding: '10px 24px 24px 10px'
    }
  }

  static textField: TextFieldProps = {
    sx: {
      marginBottom: '10px'
    }
  }

  static wrapperRightContent: BoxProps = {
    sx: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignSelf: 'stretch'
      // padding: '12px'
    }
  }

  static wrapperImage: BoxProps = {
    sx: {
      height: '300px',
      border: '1px solid #eee',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '10px'
    }
  }
}

export default DetailStyle
