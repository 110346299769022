import CONSTANT from 'modules/constant'
import type {
    CarInformationDetailProps,
    CarInformationListProps,
    CarSharingListProps,
    PaginationProps,
    RegistrationStatusDropdownListProps,
    ResultProps,
} from 'modules/types'
import type { PartialApproveProps, PartialDeleteCarSharingProps, PartialDropdownListProps, PartialUpdateAccountStatusProps } from 'modules/partial'
import { API, download, headers } from './api'

const CarInformationAPI = API.injectEndpoints({
    endpoints: (builder) => ({
        getCarInformation: builder.mutation<PaginationProps<CarInformationListProps>, FormData>({
            query: (body) => ({
                url: CONSTANT.URL_PROFILING_CAR_INFORMATION_LIST,
                method: 'POST',
                headers: headers({ useContentType: false }),
                body: body,
                formData: true
            })
        }),
        getRegistrationStatusDropdownList: builder.mutation<RegistrationStatusDropdownListProps[], PartialDropdownListProps>({
            query: () => ({
                url: CONSTANT.URL_PROFILING_CAR_INFORMATION_REGISTRATION_STATUS,
                method: 'GET',
                headers: headers()
            })
        }),
        getCarInformationDetail: builder.query<CarInformationDetailProps, number | string>({
            query: (id) => ({
                url: CONSTANT.URL_PROFILING_CAR_INFORMATION_DETAIL + id,
                method: 'GET',
                headers: headers()
            })
        }),
        getCarSharing: builder.mutation<CarSharingListProps[], number | string>({
            query: (vin) => ({
                url: CONSTANT.URL_PROFILING_CAR_SHARING_STATUS + vin,
                method: 'GET',
                headers: headers()
            })
        }),
        approve: builder.mutation<ResultProps, PartialApproveProps>({
            query: (body) => ({
                url: CONSTANT.URL_PROFILING_CAR_INFORMATION_APPROVE,
                method: 'POST',
                headers: headers(),
                body: body
            })
        }),
        reject: builder.mutation<ResultProps, PartialApproveProps>({
            query: (body) => ({
                url: CONSTANT.URL_PROFILING_CAR_INFORMATION_REJECT,
                method: 'POST',
                headers: headers(),
                body: body
            })
        }),
        changeVin: builder.mutation<ResultProps, PartialApproveProps>({
            query: (body) => ({
                url: CONSTANT.URL_PROFILING_CAR_INFORMATION_CHANGE_VIN,
                method: 'POST',
                headers: headers(),
                body: body
            })
        }),
        AddNotes: builder.mutation<ResultProps, PartialApproveProps>({
            query: (body) => ({
                url: CONSTANT.URL_PROFILING_CAR_INFORMATION_ADD_NOTES,
                method: 'POST',
                headers: headers(),
                body: body
            })
        }),
        UpdateAccountStatus: builder.mutation<ResultProps, PartialUpdateAccountStatusProps>({
            query: (body) => ({
                url: CONSTANT.URL_PROFILING_CAR_INFORMATION_UPDATE_ACCOUNT_STATUS,
                method: 'POST',
                headers: headers(),
                body: body
            })
        }),
        downloadCarInformation: builder.mutation<PaginationProps<CarInformationListProps>, FormData>({
            query: (body) => ({
                url: CONSTANT.URL_PROFILING_CAR_INFORMATION_DOWNLOAD,
                method: 'POST',
                headers: headers({ useContentType: false }),
                responseHandler: download,
                body: body,
                formData: true
            })
        }),
        deleteCarSharing: builder.mutation<ResultProps, PartialDeleteCarSharingProps>({
            query: (body) => ({
                url: CONSTANT.URL_PROFILING_CAR_SHARING_DELETE,
                method: 'POST',
                headers: headers(),
                body: body
            })
        }),
    })
})

export const {
    useGetCarInformationMutation,
    useGetRegistrationStatusDropdownListMutation,
    useGetCarInformationDetailQuery,
    useGetCarSharingMutation,
    useApproveMutation,
    useRejectMutation,
    useChangeVinMutation,
    useAddNotesMutation,
    useUpdateAccountStatusMutation,
    useDownloadCarInformationMutation,
    useDeleteCarSharingMutation
} = CarInformationAPI
