import { FieldArray, FormikProvider, useFormik } from 'formik'
import ReactQuill from 'react-quill'
import { useLocation } from 'react-router-dom'
import * as yup from 'yup'
import { Container, Stack, TextField } from '@mui/material'
import { encode } from 'html-entities'
import Dialog from 'components/dialog/dialog'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import { useCreateFaqListMutation } from 'store/faq'
import type { PartialFaqListDetailDataProps, PartialFaqListDetailProps } from 'modules/partial'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import DialogStyle from './style'

import 'react-quill/dist/quill.snow.css'

const Create = ({ open, onClose }: DetailStateProps) => {
  const [createFaqList, create] = useCreateFaqListMutation()

  const path = useLocation()
  const split = path.pathname.split('/')

  const scheme = yup.object<PartialFaqListDetailDataProps>({
    categoryKey: yup.string().required('Category Name is required'),
    key: yup.string(),
    data: yup.array().of(
      yup.object({
        language: yup.string().required('Language is required'),
        order: yup
          .number()
          .positive()
          .typeError('Order must be a number')
          .required('Order is required'),
        question: yup.string().required('Question is required'),
        ShortAnswer: yup.string().required('Short Answer is required'),
        AnswerDetails: yup.string()
      })
    )
  })

  const formik = useFormik<PartialFaqListDetailProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      categoryKey: split[split.length - 1] || '',
      key: '',
      data: [
        { language: 'en-us', order: null, question: '', ShortAnswer: '', AnswerDetails: '' },
        { language: 'id', order: null, question: '', ShortAnswer: '', AnswerDetails: '' }
      ]
    },
    onSubmit: (values: PartialFaqListDetailProps) =>
      createFaqList({
        ...values,
        data: values.data.map((el) => ({ ...el, AnswerDetails: encode(el.AnswerDetails) }))
      })
  })

  const errorData = formik.errors.data as PartialFaqListDetailDataProps[]

  return (
    <>
      <Dialog
        title='Create New FAQ List'
        open={open}
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={create.isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          <TextField
            id='categoryKey'
            variant='outlined'
            label='Category Name'
            value={formik.values.categoryKey}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            inputProps={{ readOnly: true }}
            error={
              formik.touched && formik.touched.categoryKey && Boolean(formik.errors.categoryKey)
            }
            helperText={
              formik.touched &&
              formik.touched.categoryKey &&
              formik.errors &&
              formik.errors.categoryKey
            }
            fullWidth
          />
          <FormikProvider value={formik}>
            {formik.values.data.map((_, index) => (
              <FieldArray
                key={index}
                name='data'
                render={() => (
                  <Stack {...DialogStyle.Stack}>
                    <TextField
                      id={`data[${index}].language`}
                      variant='outlined'
                      label='Language'
                      value={formik.values.data[index].language}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      inputProps={{ readOnly: true }}
                      fullWidth
                    />
                    <TextField
                      id={`data[${index}].order`}
                      variant='outlined'
                      label='Order'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.data &&
                        formik.touched.data[index] &&
                        formik.touched.data[index].order &&
                        errorData &&
                        errorData[index] &&
                        Boolean(errorData[index].order)
                      }
                      helperText={
                        formik.touched.data &&
                        formik.touched.data[index] &&
                        formik.touched.data[index].order &&
                        errorData &&
                        errorData[index] &&
                        errorData[index].order
                      }
                      fullWidth
                    />
                    <TextField
                      id={`data[${index}].question`}
                      variant='outlined'
                      label='Question'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.data &&
                        formik.touched.data[index] &&
                        formik.touched.data[index].question &&
                        errorData &&
                        errorData[index] &&
                        Boolean(errorData[index].question)
                      }
                      helperText={
                        formik.touched.data &&
                        formik.touched.data[index] &&
                        formik.touched.data[index].question &&
                        errorData &&
                        errorData[index] &&
                        errorData[index].question
                      }
                      fullWidth
                    />
                    <TextField
                      id={`data[${index}].ShortAnswer`}
                      variant='outlined'
                      label='Short Answer'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.data &&
                        formik.touched.data[index] &&
                        formik.touched.data[index].ShortAnswer &&
                        errorData &&
                        errorData[index] &&
                        Boolean(errorData[index].ShortAnswer)
                      }
                      helperText={
                        formik.touched.data &&
                        formik.touched.data[index] &&
                        formik.touched.data[index].ShortAnswer &&
                        errorData &&
                        errorData[index] &&
                        errorData[index].ShortAnswer
                      }
                      fullWidth
                    />
                    <ReactQuill
                      theme='snow'
                      id={`data[${index}].AnswerDetails`}
                      onChange={(e) =>
                        formik.setFieldValue(`data[${index}].AnswerDetails`, GLOBAL.emptyQuill(e))
                      }
                    />
                  </Stack>
                )}
              />
            ))}
          </FormikProvider>
        </Container>
      </Dialog>

      <Notification
        open={!create.isLoading && !create.isUninitialized}
        onClose={() => (create.isError ? create.reset() : location.reload())}
        isError={Boolean(create.error) && create.isError}
        message={GLOBAL.returnExceptionMessage(create.isError, create.error as ErrorProps)}
      />
    </>
  )
}

export default Create
