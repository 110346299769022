import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import {
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
  Chip,
  FormControl,
  InputLabel,
  Select,
  Typography
} from '@mui/material'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import Icon from 'components/icon'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import CONSTANT from 'modules/constant'
import GLOBAL from 'modules/global'
import GlobalStyle from 'modules/styles'
import {
  useGetFaqListMutation,
  useDownloadFaqListMutation,
  useDeleteFaqListMutation,
  useEnableFaqListMutation
} from 'store/faq'
import type { PartialFaqListProps } from 'modules/partial'
import type { ErrorProps, ParamProps } from 'modules/types'
import MenuModalItem from 'components/menuItem/menuItem'
import Create from './create/create'
import Detail from './detail/detail'
import Edit from './edit/edit'

const FaqList = () => {
  const params = useParams<ParamProps>()

  const [getFaqList, faqList] = useGetFaqListMutation()
  const [deleteFaqList, deleteList] = useDeleteFaqListMutation()
  const [enableFaqList, enableList] = useEnableFaqListMutation()
  const [downloadFaqList, download] = useDownloadFaqListMutation()

  const [dialog, setDialog] = useState<boolean>(false)
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [dialogCreate, setDialogCreate] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [dialogUpdate, setDialogUpdate] = useState<boolean>(false)
  const [menu, setMenu] = useState<null | number>(null)

  const [filter, setFilter] = useState({
    visible: ''
  })

  const [payload, setPayload] = useState<PartialFaqListProps>({
    start: 0,
    length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    field: CONSTANT.DEFAULT_FAQ_LIST_FIELD.join(','),
    search: { value: '' },
    category: (params.key && GLOBAL.decodeUrl(params.key)) || '',
    visible: ''
  })

  const onFilter = (state: boolean) => setDialog(state)
  const onSearch = (value: string) => setPayload({ ...payload, start: 0, search: { value } })

  const onResync = () => {
    const formData = new FormData()

    formData.append('start', payload.start.toString())
    formData.append('length', payload.length.toString())
    formData.append('field', payload.field)
    formData.append('category', payload.category)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(payload.visible)) formData.append('visible', payload.visible)

    getFaqList(formData)
  }

  const onCreate = () => setDialogCreate(true)

  const onChangePage = (value: number) => setPayload({ ...payload, start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, length: value })

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('start', String(0))
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())
    formData.append('field', payload.field)
    formData.append('category', payload.category)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(payload.visible)) formData.append('visible', payload.visible)
    if (type && !isEmpty(type)) formData.append('Download-Type', type)

    downloadFaqList(formData)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const tableHeadTitles = ['Language', 'Question', 'Short Answer', 'Status']

  const openAnchor = Boolean(anchor)

  const totalPage = faqList.data && GLOBAL.tableTotalPage(faqList.data.recordsTotal)

  const numbers = GLOBAL.tableNumber(payload.start)

  useEffect(() => {
    onResync()
  }, [payload])

  return (
    <>
      <Content
        title='FAQ List'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        onAdditional={onCreate}
        additionalTitle='Create New FAQ List'
        additionalPrefixIcon='Create'
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={faqList.data && faqList.data.recordsTotal}
        page={payload.start + 1}
        isLoading={faqList.isLoading}
        useBackButton
        useDownloadDropdown
      >
        {faqList.isSuccess &&
          faqList.data &&
          faqList.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.key}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.key}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.key}`}
                  aria-labelledby={`button-${index}-${table.key}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View
                  </MenuItem>
                  <MenuItem onClick={() => onRemoveAnchor(() => setDialogUpdate(true))}>
                    Edit
                  </MenuItem>
                  <MenuModalItem
                    title={table.status == 'Visible' ? 'Disable' : 'Enable'}
                    onClick={() =>
                      onRemoveAnchor(() =>
                        enableFaqList({
                          key: table.key || '',
                          status: table.status == 'Visible' ? 'hidden' : 'visible'
                        })
                      )
                    }
                  >
                    {table.status == 'Visible' ? 'Disable' : 'Enable'}
                  </MenuModalItem>
                  <MenuModalItem
                    title='Delete'
                    loading={deleteList.isLoading}
                    onClick={() => onRemoveAnchor(() => deleteFaqList({ id: table.id }))}
                  >
                    <Typography {...GlobalStyle.ErrorTypography}>Delete</Typography>
                  </MenuModalItem>
                </Menu>
                <Detail
                  id={table.id}
                  open={drawerDetail && menu == index}
                  onClose={() => setDrawerDetail(false)}
                />
                <Edit
                  id={table.id}
                  open={dialogUpdate && menu == index}
                  onClose={() => setDialogUpdate(false)}
                />
              </TableCell>
              <TableCell>{table.language || '-'}</TableCell>
              <TableCell {...GlobalStyle.EllipsisTable}>{table.question || '-'}</TableCell>
              <TableCell {...GlobalStyle.EllipsisTable}>{table.shortAnswer || '-'}</TableCell>
              <TableCell>
                <Chip
                  label={table.status}
                  color={table.status == 'Visible' ? 'success' : 'error'}
                />
              </TableCell>
            </TableRow>
          ))}
      </Content>

      {faqList.isLoading && <Loading />}

      <Create open={dialogCreate} onClose={() => setDialogCreate(false)} />

      <Filter
        open={dialog}
        title='Filter'
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
        onReset={() => {
          setFilter({
            visible: ''
          })
          setPayload({
            ...payload,
            start: 0,
            visible: ''
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({ ...payload, start: 0, ...filter })
          onFilter(false)
        }}
      >
        <FormControl fullWidth>
          <InputLabel id='select-label'>Visibility</InputLabel>
          <Select
            labelId='select-label'
            id='id-select-label'
            value={filter.visible}
            label='Visibility'
            onChange={(event) => setFilter({ ...filter, visible: event.target.value })}
          >
            <MenuItem value='Visible'>Visible</MenuItem>
            <MenuItem value='Hidden'>Hidden</MenuItem>
          </Select>
        </FormControl>
      </Filter>

      <Notification
        open={!faqList.isLoading && !faqList.isUninitialized && !faqList.isSuccess}
        onClose={() => (faqList.isError ? faqList.reset() : location.reload())}
        isError={Boolean(faqList.error) && faqList.isError}
        message={GLOBAL.returnExceptionMessage(faqList.isError, faqList.error as ErrorProps)}
      />

      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />

      <Notification
        open={!deleteList.isLoading && !deleteList.isUninitialized}
        onClose={() => (deleteList.isError ? deleteList.reset() : location.reload())}
        isError={Boolean(deleteList.error) && deleteList.isError}
        message={GLOBAL.returnExceptionMessage(deleteList.isError, deleteList.error as ErrorProps)}
      />

      <Notification
        open={!enableList.isLoading && !enableList.isUninitialized}
        onClose={() => (enableList.isError ? enableList.reset() : location.reload())}
        isError={Boolean(enableList.error) && enableList.isError}
        message={GLOBAL.returnExceptionMessage(enableList.isError, enableList.error as ErrorProps)}
      />
    </>
  )
}

export default FaqList
