import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { isEmpty } from 'lodash'
import * as yup from 'yup'
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import moment from 'moment'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { PartialUpdatePromoCodeProps, PartialUpdatePromoCodeTempProps } from 'modules/partial'
import type {
  DetailStateProps,
  DropdownCategoryListProps,
  ErrorProps,
  MemberTypeDropDown,
  TableVoucherPromoCodeProps
} from 'modules/types'
import Icon from 'components/icon'
import GlobalStyle from 'modules/styles'
import { useDropDownPromoCodeBatchRewardMutation } from 'store/promoCodeBatch'
import { useGetMemberTypeDropdownMutation } from 'store/dropdown'
import { useGetVoucherListDropdownMutation } from 'store/tokenRequest'
import Loading from 'components/loading/loading'
import { useGetDetailPromoCodeQuery, useUpdatePromoCodeMutation } from 'store/promoCode'
import Dialog from 'components/dialog/dialog'
import DialogStyle from './style'

const Update = ({ open, onClose, id }: DetailStateProps & { id: number }) => {
  if (!open) return <></>
  const { data, isLoading, isFetching, isSuccess } = useGetDetailPromoCodeQuery(id)
  const [dropDownPromoCodeBatchReward, dropdownReward] = useDropDownPromoCodeBatchRewardMutation()
  const [getVoucherListDropdown, voucherDropdown] = useGetVoucherListDropdownMutation()
  const [getMemberTypeDropdown, memberType] = useGetMemberTypeDropdownMutation()
  const [updatePromoCode, update] = useUpdatePromoCodeMutation()
  const [valVoucher, setValVoucher] = useState<string>('')
  const [qty, setQty] = useState<number | string>('')
  const [randomKey, setRandomKey] = useState<number>(0)
  const [tableVoucher, setTableVoucher] = useState<TableVoucherPromoCodeProps[]>([])
  const [rewardType, setRewardType] = useState<string>((data && data.rewardType) || '')
  const [userType, setUserType] = useState<MemberTypeDropDown[]>([])
  const [listTempVoucher, setListTempVoucher] = useState<DropdownCategoryListProps>({
    id: '',
    name: '',
    key: '',
    language: ''
  })
  const [payloadDate, setPayloadDate] = useState<{
    minSignUpDate: string | null
    maxSignUpDate: string | null
  }>({
    minSignUpDate: '',
    maxSignUpDate: ''
  })
  const [payload, setPayload] = useState<{
    coinsAmount: number | null
    maxRedemption: number | null
  }>({
    coinsAmount: (data && data.coinsAmount) || null,
    maxRedemption: (data && data.maxRedemption) || null
  })
  const [date, setDate] = useState<{
    minDateSignUp: Dayjs | null
    maxDateSignUp: Dayjs | null
    validFrom: Dayjs | null
    validUntil: Dayjs | null
  }>({
    minDateSignUp: (data && dayjs(data.minSignUpDate)) || null,
    maxDateSignUp: (data && dayjs(data.maxSignUpDate)) || null,
    validFrom: (data && dayjs(data.validFrom)) || null,
    validUntil: (data && dayjs(data.validTo)) || null
  })

  const scheme = yup.object<PartialUpdatePromoCodeTempProps>({
    code: yup.string().required('Promo Code Length Name is required'),
    name: yup.string().required('Campaign Name is required'),
    rewardType: yup.string().required('Reward is required'),
    validFrom: yup.string().required('Valid From is required'),
    validTo: yup.string().required('Valid Until is required'),
    messageTitle: yup.string().required('Message Title is required'),
    messageBody: yup.string().required('Message Title is required')
  })

  const formik = useFormik<PartialUpdatePromoCodeTempProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      name: (data && data.name) || '',
      code: (data && data.code) || '',
      rewardType: (data && data.rewardType) || '',
      validFrom: (data && GLOBAL.formatDate(data.validFrom)) || '',
      validTo: (data && GLOBAL.formatDate(data.validTo)) || '',
      messageTitle: (data && data.messageTitle) || '',
      messageBody: (data && data.messageBody) || ''
    },
    onSubmit: (values: PartialUpdatePromoCodeTempProps) => handleSubmit(values)
  })

  useEffect(() => {
    if (data && isSuccess) {
      const defaultTableVoucher =
        (data &&
          data.vouchers.map((item) => ({
            id: item.voucherId,
            name: item.voucherName,
            qty: item.quantity,
            key: null,
            language: null
          }))) ||
        []
      setTableVoucher(defaultTableVoucher)
      const defaultUserType =
        (data &&
          data.members &&
          data.members.map((item) => ({
            id: item.memberId,
            key: item.memberName,
            name: item.memberName
          }))) ||
        []
      setUserType(defaultUserType)
      setRewardType(data.rewardType)
      setDate({
        minDateSignUp: (data.minSignUpDate && dayjs(data.minSignUpDate)) || null,
        maxDateSignUp: (data.maxSignUpDate && dayjs(data.maxSignUpDate)) || null,
        validFrom: (data.validFrom && dayjs(data.validFrom)) || null,
        validUntil: (data.validTo && dayjs(data.validTo)) || null
      })
      setPayloadDate({
        minSignUpDate:
          (data.minSignUpDate && moment(data.minSignUpDate).format('YYYY-MM-DD')) || null,
        maxSignUpDate:
          (data.maxSignUpDate && moment(data.maxSignUpDate).format('YYYY-MM-DD')) || null
      })
      setPayload({
        coinsAmount: (data && data.coinsAmount) || null,
        maxRedemption: (data && data.maxRedemption) || null
      })
    }
  }, [isSuccess, data])

  const handleSubmit = (e: PartialUpdatePromoCodeTempProps) => {
    const formatUser =
      userType?.length > 0
        ? userType.map((item) => ({
            memberId: item.id,
            memberName: item.name
          }))
        : []

    const formatVoucher =
      tableVoucher?.length > 0
        ? tableVoucher.map((item) => ({
            quantity: item.qty,
            voucherId: item.id,
            voucherName: item.name
          }))
        : []

    const formatPayload: PartialUpdatePromoCodeProps = {
      ...e,
      minSignUpDate: !isEmpty(payloadDate.minSignUpDate) ? payloadDate.minSignUpDate : null,
      maxSignUpDate: !isEmpty(payloadDate.maxSignUpDate) ? payloadDate.maxSignUpDate : null,
      ...payload,
      validFrom: dayjs(e.validFrom).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      validTo: dayjs(e.validTo).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      members: formatUser,
      vouchers: formatVoucher
    }

    updatePromoCode({ body: formatPayload, id })
  }
  const onAddChargerPoint = () => {
    const combineVal = { ...listTempVoucher, qty }
    const tempTable = tableVoucher.concat(combineVal)
    setTableVoucher(tempTable)
    setValVoucher('')
    setQty('')
    setRandomKey(randomKey + 1)
    setListTempVoucher({
      id: '',
      name: '',
      key: '',
      language: ''
    })
  }

  const onDelTableChargerPoint = (id: string | number) => {
    const tempTable = tableVoucher.filter((item) => item.id !== id)
    setTableVoucher(tempTable)
  }

  const DescMessage = () => (
    <Box sx={{ marginBottom: '10px' }}>
      <Typography variant='caption' display='block' gutterBottom color='#525252'>
        *You can use these placeholder in the field above
      </Typography>
      <Typography variant='caption' display='block' gutterBottom color='#525252'>
        <span
          style={{ fontSize: '14px', fontWeight: '500', color: '#242423' }}
        >{`{userFullName} `}</span>
        Name of the recipient
      </Typography>
      <Typography variant='caption' display='block' gutterBottom color='#525252'>
        <span
          style={{ fontSize: '14px', fontWeight: '500', color: '#242423' }}
        >{`{voucherName} `}</span>
        Voucher Title
      </Typography>
      <Typography variant='caption' display='block' gutterBottom color='#525252'>
        <span
          style={{ fontSize: '14px', fontWeight: '500', color: '#242423' }}
        >{`{coinsAmount} `}</span>
        Coin amount given to user
      </Typography>
    </Box>
  )

  return (
    <>
      <Dialog
        isDisabled={!formik.isValid}
        title='Update Promo Code'
        open={open}
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={update.isLoading}
        maxWidth='md'
      >
        <Container {...DialogStyle.Container}>
          {(isLoading || isFetching) && <Loading />}

          {data && isSuccess && (
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Box sx={{ display: 'flex', flex: 1, gap: 2, flexDirection: 'column' }}>
                <TextField
                  id='name'
                  focused
                  variant='outlined'
                  label='Campaign Name'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  error={formik.touched && formik.touched.name && Boolean(formik.errors.name)}
                  helperText={
                    formik.touched && formik.touched.name && formik.errors && formik.errors.name
                  }
                  fullWidth
                />
                <TextField
                  id='code'
                  variant='outlined'
                  label='Promo Code'
                  onChange={(e) => formik.setFieldValue('code', GLOBAL.capitalize(e.target.value))}
                  onBlur={(e) => formik.setFieldValue('code', GLOBAL.capitalize(e.target.value))}
                  value={formik.values.code}
                  error={formik.touched && formik.touched.code && Boolean(formik.errors.code)}
                  helperText={
                    formik.touched && formik.touched.code && formik.errors && formik.errors.code
                  }
                  fullWidth
                />
                <Autocomplete
                  sx={{ marginBottom: '10px' }}
                  options={(dropdownReward && dropdownReward.data) || []}
                  getOptionLabel={(option) => option.text}
                  isOptionEqualToValue={(option, value) =>
                    option && value ? option.value == value.value : false
                  }
                  defaultValue={{
                    text: (data && data.rewardType) || '',
                    value: Math.floor(Math.random() * 11),
                    group: ''
                  }}
                  onOpen={() => dropDownPromoCodeBatchReward()}
                  onChange={(_, reward) => {
                    if (!isEmpty(reward)) {
                      formik.setFieldValue('rewardType', reward.text)
                      setRewardType(reward.text)
                    }
                  }}
                  value={
                    (dropdownReward &&
                      dropdownReward.data &&
                      dropdownReward.data.find((e) => e.text == rewardType)) ||
                    undefined
                  }
                  ListboxProps={GlobalStyle.ListBox}
                  renderOption={(props, item) => (
                    <li {...props} key={item.value}>
                      {item.text}
                    </li>
                  )}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name='Reward'
                      label='Reward'
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {dropdownReward.isLoading && (
                              <CircularProgress color='inherit' size={20} />
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                />
                {rewardType == 'Voucher' && (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Autocomplete
                        key={'Voucher-' + randomKey}
                        sx={{ width: '70%', marginBottom: '10px', marginRight: '5px' }}
                        options={voucherDropdown.data || []}
                        getOptionLabel={(list) => list.name}
                        isOptionEqualToValue={(option, value) =>
                          option && value ? option.id == value.id : false
                        }
                        onOpen={() => getVoucherListDropdown()}
                        onChange={(_, voucher) => {
                          if (voucher) {
                            setListTempVoucher(voucher)
                            setValVoucher(voucher.name)
                          }
                        }}
                        ListboxProps={GlobalStyle.ListBox}
                        value={
                          voucherDropdown &&
                          voucherDropdown.data &&
                          voucherDropdown.data.find((e) => e.name == valVoucher)
                        }
                        renderOption={(props, item) => (
                          <li {...props} key={item.id}>
                            {item.name}
                          </li>
                        )}
                        fullWidth
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name='voucherId'
                            label='Voucher'
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {voucherDropdown.isLoading && (
                                    <CircularProgress color='inherit' size={20} />
                                  )}
                                  {params.InputProps.endAdornment}
                                </>
                              )
                            }}
                          />
                        )}
                      />
                      <TextField
                        sx={{ width: '30%' }}
                        id='Qty'
                        variant='outlined'
                        label='Qty'
                        type='number'
                        inputProps={{
                          min: '0'
                        }}
                        fullWidth
                        value={qty}
                        onChange={(e) => setQty(parseInt(e.target.value))}
                      />
                      <Button
                        sx={{ height: '56px', marginLeft: '5px' }}
                        variant='contained'
                        disabled={isEmpty(valVoucher && qty.toString())}
                        onClick={onAddChargerPoint}
                      >
                        Add
                      </Button>
                    </Box>
                    <TableContainer
                      component={Paper}
                      sx={{ maxHeight: 512, marginBottom: '10px' }}
                      variant='outlined'
                    >
                      <Table aria-label='Data Table' stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell>Voucher</TableCell>
                            <TableCell>Qty</TableCell>
                            <TableCell>Actions</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {tableVoucher.map((table, index) => (
                            <TableRow key={index}>
                              <TableCell>{table.name}</TableCell>
                              <TableCell>{table.qty}</TableCell>
                              <TableCell>
                                <IconButton
                                  onClick={() => onDelTableChargerPoint(table.id)}
                                  sx={{ color: 'red' }}
                                >
                                  <Icon icon='Delete' />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TextField
                      id='max'
                      variant='outlined'
                      label='Maximal Redemption Count(max token distributed for this campaign)'
                      fullWidth
                      value={payload.maxRedemption}
                      onChange={(e) =>
                        setPayload({ ...payload, maxRedemption: parseInt(e.target.value) })
                      }
                      type='number'
                      inputProps={{
                        min: '0'
                      }}
                    />
                  </>
                )}

                {rewardType == 'Coin' && (
                  <TextField
                    id='coin'
                    variant='outlined'
                    label='Coin Amount'
                    type='number'
                    inputProps={{
                      min: '0'
                    }}
                    value={payload.coinsAmount}
                    onChange={(e) =>
                      setPayload({ ...payload, coinsAmount: parseInt(e.target.value) })
                    }
                    fullWidth
                  />
                )}
              </Box>
              <Box sx={{ display: 'flex', flex: 1, gap: 2, flexDirection: 'column' }}>
                <Autocomplete
                  fullWidth
                  sx={{ marginBottom: '10px' }}
                  multiple
                  id='fixed-tags-demo'
                  value={userType}
                  onOpen={() => getMemberTypeDropdown()}
                  isOptionEqualToValue={(option, value) =>
                    option && value ? option.name == value.name : false
                  }
                  defaultValue={userType}
                  onChange={(event, newValue) => {
                    setUserType(newValue)
                  }}
                  options={(memberType && memberType.data) || []}
                  getOptionLabel={(option) => option.name}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip label={option.name} {...getTagProps({ index })} key={index} />
                    ))
                  }
                  renderInput={(params) => <TextField {...params} label='User Type' />}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                  <DatePicker
                    value={date.validFrom}
                    onChange={(e) => {
                      const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                      formik.setFieldValue('validFrom', newDate)
                      setDate((prevState) => ({ ...prevState, validFrom: e }))
                    }}
                    label='Valid From'
                    format='DD-MM-YYYY'
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                  <DatePicker
                    value={date.validUntil}
                    minDate={dayjs(formik.values.validFrom)}
                    onChange={(e) => {
                      const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                      formik.setFieldValue('validTo', newDate)
                      setDate((prevState) => ({ ...prevState, validUntil: e }))
                    }}
                    label='Valid Until'
                    format='DD-MM-YYYY'
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                  <DatePicker
                    value={date.minDateSignUp}
                    onChange={(e) => {
                      const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                      setPayloadDate({ ...payloadDate, minSignUpDate: newDate })
                      setDate((prevState) => ({ ...prevState, minDateSignUp: e }))
                    }}
                    label='Minimum Sign Up Date'
                    format='DD-MM-YYYY'
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                  <DatePicker
                    minDate={dayjs(payloadDate.minSignUpDate)}
                    value={date.maxDateSignUp}
                    onChange={(e) => {
                      const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                      setPayloadDate({ ...payloadDate, maxSignUpDate: newDate })
                      setDate((prevState) => ({ ...prevState, maxDateSignUp: e }))
                    }}
                    label='Maximum Sign Up Date'
                    format='DD-MM-YYYY'
                  />
                </LocalizationProvider>
                <TextField
                  id='messageTitle'
                  variant='outlined'
                  label='Message Title'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.messageTitle}
                  error={
                    formik.touched &&
                    formik.touched.messageTitle &&
                    Boolean(formik.errors.messageTitle)
                  }
                  helperText={
                    formik.touched &&
                    formik.touched.messageTitle &&
                    formik.errors &&
                    formik.errors.messageTitle
                  }
                  fullWidth
                />
                <DescMessage />
                <TextField
                  id='messageBody'
                  variant='outlined'
                  label='Message Body'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.messageBody}
                  error={
                    formik.touched &&
                    formik.touched.messageBody &&
                    Boolean(formik.errors.messageBody)
                  }
                  helperText={
                    formik.touched &&
                    formik.touched.messageBody &&
                    formik.errors &&
                    formik.errors.messageBody
                  }
                  fullWidth
                />
                <DescMessage />
              </Box>
            </Box>
          )}
        </Container>
      </Dialog>
      <Notification
        open={!update.isLoading && !update.isUninitialized}
        onClose={() => (update.isError ? update.reset() : location.reload())}
        isError={Boolean(update.error) && update.isError}
        message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
      />
    </>
  )
}

export default Update
