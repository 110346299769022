import CONSTANT from 'modules/constant'
import type {
  EvModelDetailProps,
  EvModelDropdownProps,
  EvModelListProps,
  PaginationProps,
  ResultProps
} from 'modules/types'
import type { PartialEvModelProps } from 'modules/partial'
import { API, download, headers } from './api'

const EvModelAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getEvModel: builder.mutation<PaginationProps<EvModelListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_CATALOG_EV_MODEL_LIST,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    getEvModelDropdownList: builder.mutation<EvModelDropdownProps[], void>({
      query: () => ({
        url: CONSTANT.URL_CATALOG_EV_MODEL_DROPDOWN,
        method: 'GET',
        headers: headers()
      })
    }),
    downloadEvModel: builder.mutation<PaginationProps<EvModelListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_CATALOG_EV_MODEL_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true,
        responseHandler: download
      })
    }),
    getEvModelDetail: builder.query<EvModelDetailProps, number | string>({
      query: (id) => ({
        url: CONSTANT.URL_CATALOG_EV_MODEL + id,
        method: 'GET',
        headers: headers()
      })
    }),
    deleteEvModel: builder.mutation<ResultProps, number>({
      query: (id) => ({
        url: CONSTANT.URL_CATALOG_EV_MODEL + id,
        method: 'DELETE',
        headers: headers()
      })
    }),
    createEvModel: builder.mutation<ResultProps, PartialEvModelProps>({
      query: (body) => ({
        url: CONSTANT.URL_CATALOG_EV_MODEL_CREATE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    updateEvModel: builder.mutation<ResultProps, { body: PartialEvModelProps; id: number }>({
      query: ({ body, id }) => ({
        url: CONSTANT.URL_CATALOG_EV_MODEL + id,
        method: 'PUT',
        headers: headers(),
        body: body
      })
    })
  })
})

export const {
  useGetEvModelMutation,
  useGetEvModelDropdownListMutation,
  useDownloadEvModelMutation,
  useDeleteEvModelMutation,
  useCreateEvModelMutation,
  useUpdateEvModelMutation,

  useGetEvModelDetailQuery
} = EvModelAPI
