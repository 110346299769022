import CONSTANT from 'modules/constant'
import type { PaginationProps, ResultProps, VoucherFaqProps, VoucherListProps } from 'modules/types'
import type { PartialVoucherFAQProps } from 'modules/partial'
import { API, headers } from './api'

const VoucherAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getListVoucherFaq: builder.mutation<PaginationProps<VoucherListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_FAQ_LIST,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    getVoucherFaqDetail: builder.query<VoucherFaqProps, number>({
      query: (id) => ({
        url: CONSTANT.URL_VOUCHER_FAQ_DETAIL + id,
        method: 'GET',
        headers: headers()
      })
    }),
    updateVoucherFaq: builder.mutation<ResultProps, PartialVoucherFAQProps>({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_FAQ_UPDATE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    })
  })
})

export const {
  useGetListVoucherFaqMutation,
  useGetVoucherFaqDetailQuery,
  useUpdateVoucherFaqMutation
} = VoucherAPI
