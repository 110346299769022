import { useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import { Autocomplete, CircularProgress, Container, TextField } from '@mui/material'
import * as yup from 'yup'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import type { Dayjs } from 'dayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import Dialog from 'components/dialog/dialog'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { PayloadVoucherCategoryProps } from 'modules/partial'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import {
  useCreateVoucherCategoryMutation,
  useGetVoucherCategoryDropdownMutation
} from 'store/voucherCategory'
import GlobalStyle from 'modules/styles'
import DialogStyle from './style'

const Create = ({ open, onClose }: DetailStateProps) => {
  const [createVoucherCategory, create] = useCreateVoucherCategoryMutation()
  const [getVoucherCategoryDropdown, dropdownCategory] = useGetVoucherCategoryDropdownMutation()
  const [startDate, setStartDate] = useState<Dayjs | null>(null)
  const [endDate, setEndDate] = useState<Dayjs | null>(null)

  const scheme = yup.object<PayloadVoucherCategoryProps>({
    icon: yup.mixed().required('Icon Voucher Category is required'),
    image: yup.mixed().required('Image Voucher Category is required'),
    nameEn: yup.string().required('Voucher Category Name English is required'),
    nameId: yup.string().required('Voucher Category Name ID is required'),
    Description: yup.string().required('Voucher Category Description is required'),
    Seq: yup.number().required('Voucher Category Sequence is required')
  })

  const formik = useFormik<PayloadVoucherCategoryProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      image: '',
      icon: '',
      nameEn: '',
      nameId: '',
      Description: '',
      Seq: ''
    },
    onSubmit: (values: PayloadVoucherCategoryProps) => handleSubmit(values)
  })

  const handleChangeStartDate = (date: Dayjs | null) => {
    setStartDate(date)
    // const newDate = (!isEmpty(date) && date?.format('YYYY-MM-DD')) || ''
  }

  const handleChangeEndDate = (date: Dayjs | null) => {
    setEndDate(date)
    // const newDate = (!isEmpty(date) && date?.format('YYYY-MM-DD')) || ''
  }

  const handleSubmit = (e: PayloadVoucherCategoryProps) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const payload: any = {
      nameEn: e.nameEn,
      nameId: e.nameId,
      Description: e.Description,
      Seq: e.Seq,
      icon: ''
    }
    const formData = new FormData()
    formData.append('image', e.image)
    formData.append('data', JSON.stringify(payload))
    createVoucherCategory(formData).then()
  }

  return (
    <>
      <Dialog
        open={open}
        title='Add Token Request'
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={create.isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          <FormikProvider value={formik}>
            <TextField
              id='nameEn'
              variant='outlined'
              label='Request Name'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched && formik.touched.nameEn && Boolean(formik.errors.nameEn)}
              helperText={
                formik.touched && formik.touched.nameEn && formik.errors && formik.errors.nameEn
              }
              fullWidth
            />

            <Autocomplete
              options={dropdownCategory.data || []}
              getOptionLabel={(list) => list.name}
              isOptionEqualToValue={(option, value) =>
                option && value ? option.id == value.id : false
              }
              onOpen={() => getVoucherCategoryDropdown()}
              onChange={(_, id) => formik.setFieldValue('categoryId', id && id.id)}
              ListboxProps={GlobalStyle.ListBox}
              renderOption={(props, item) => (
                <li {...props} key={item.id}>
                  {item.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='partnerId'
                  label='Voucher Name'
                  // error={
                  //   formik.touched && formik.touched.partnerId && Boolean(formik.errors.partnerId)
                  // }
                  // helperText={
                  //   formik.touched &&
                  //   formik.touched.partnerId &&
                  //   formik.errors &&
                  //   formik.errors.partnerId
                  // }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {dropdownCategory.isLoading && (
                          <CircularProgress color='inherit' size={20} />
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
            />

            <TextField
              id='nameId'
              variant='outlined'
              label='Quantity'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched && formik.touched.nameId && Boolean(formik.errors.nameId)}
              helperText={
                formik.touched && formik.touched.nameId && formik.errors && formik.errors.nameId
              }
              fullWidth
            />

            <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
              <DatePicker
                value={startDate}
                onChange={handleChangeStartDate}
                label='Valid From'
                format='DD-MM-YYYY'
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
              <DatePicker
                value={endDate}
                minDate={dayjs('')}
                onChange={handleChangeEndDate}
                label='Valid Until'
                format='DD-MM-YYYY'
              />
            </LocalizationProvider>
          </FormikProvider>
        </Container>
      </Dialog>

      <Notification
        open={!create.isLoading && !create.isUninitialized}
        onClose={() => (create.isError ? create.reset() : location.reload())}
        isError={Boolean(create.error) && create.isError}
        message={GLOBAL.returnExceptionMessage(create.isError, create.error as ErrorProps)}
      />
    </>
  )
}

export default Create
