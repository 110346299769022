import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
  Chip,
  FormControl,
  InputLabel,
  Select,
  Autocomplete,
  CircularProgress,
  TextField
} from '@mui/material'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import Icon from 'components/icon'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import CONSTANT from 'modules/constant'
import GLOBAL from 'modules/global'
import {
  useActivateDealerMutation,
  useDownloadDealerMutation,
  useGetDealerDropdownMutation,
  useGetDealerMutation,
  useResyncDealerMutation
} from 'store/dealer'
import { useGetProvinceMutation, useGetCityMutation } from 'store/region'
import type { PartialFormDataProps, PartialLocationDealerProps } from 'modules/partial'
import type { ErrorProps } from 'modules/types'
import MenuModalItem from 'components/menuItem/menuItem'
import GlobalStyle from 'modules/styles'
import Detail from './detail/detail'

const Dealer = () => {
  const [getDealer, dealer] = useGetDealerMutation()
  const [getDealerDropdown, dealerDropdown] = useGetDealerDropdownMutation()
  const [resyncDealer, resync] = useResyncDealerMutation()
  const [downloadDealer, download] = useDownloadDealerMutation()
  const [activateDealer, activate] = useActivateDealerMutation()
  const [getProvince, province] = useGetProvinceMutation()
  const [getCity, city] = useGetCityMutation()

  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [menu, setMenu] = useState<null | number>(null)

  const [filter, setFilter] = useState<PartialLocationDealerProps>({
    dealerId: '',
    status: '',
    province: '',
    city: ''
  })

  const [payload, setPayload] = useState<PartialLocationDealerProps & PartialFormDataProps>({
    start: 0,
    length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    field: CONSTANT.DEFAULT_LOCATION_DEALER_FIELD.join(','),
    search: { value: '' },
    status: '',
    province: '',
    city: '',
    dealerId: ''
  })

  const onFilter = (state: boolean) => setDialogFilter(state)
  const onSearch = (value: string) => setPayload({ ...payload, start: 0, search: { value } })

  const onResync = (sync = false) => {
    const formData = new FormData()

    formData.append('start', payload.start.toString())
    formData.append('length', payload.length.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(payload.status)) formData.append('status', payload.status)
    if (!isEmpty(payload.province)) formData.append('province', payload.province)
    if (!isEmpty(payload.city)) formData.append('city', payload.city)
    if (!isEmpty(payload.dealerId)) formData.append('dealerId', payload.dealerId)

    if (sync) return resyncDealer({}).then(() => getDealer(formData))

    getDealer(formData)
  }

  const onChangePage = (value: number) => setPayload({ ...payload, start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, length: value })

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('start', String(0))
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(payload.status)) formData.append('status', payload.status)
    if (!isEmpty(payload.province)) formData.append('province', payload.province)
    if (!isEmpty(payload.city)) formData.append('city', payload.city)
    if (!isEmpty(payload.dealerId)) formData.append('dealerId', payload.dealerId)
    if (type && !isEmpty(type)) formData.append('Download-Type', type)

    downloadDealer(formData)
  }

  const onAsyncFilter = (callback: CallableFunction) => {
    const formData = new FormData()

    formData.append('start', payload.start.toString())
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(filter.status)) formData.append('status', filter.status)
    if (!isEmpty(filter.province)) formData.append('province', filter.province)
    if (!isEmpty(filter.city)) formData.append('city', filter.city)
    if (!isEmpty(filter.dealerId)) formData.append('dealerId', filter.dealerId)

    callback(formData)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const tableHeadTitles = ['Dealer ID', 'Dealer Code', 'Name', 'City', 'Status']

  const openAnchor = Boolean(anchor)

  const totalPage = dealer.data && GLOBAL.tableTotalPage(dealer.data.recordsTotal)

  const numbers = GLOBAL.tableNumber(payload.start)

  useEffect(() => {
    onResync()
  }, [payload])

  return (
    <>
      <Content
        title='Dealer Location'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={() => onResync(true)}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={dealer.data && dealer.data.recordsTotal}
        page={payload.start + 1}
        isLoading={resync.isLoading || dealer.isLoading}
        useDownloadDropdown
      >
        {dealer.isSuccess &&
          dealer.data &&
          dealer.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.dealerId}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.dealerId}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.dealerId}`}
                  aria-labelledby={`button-${index}-${table.dealerId}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View
                  </MenuItem>
                  <MenuModalItem
                    title={table.status == 'Enabled' ? 'Disable' : 'Enable'}
                    onClick={() =>
                      onRemoveAnchor(() =>
                        activateDealer({
                          dealerId: table.dealerId,
                          IsActive: !(table.status == 'Enabled')
                        })
                      )
                    }
                  >
                    {table.status == 'Enabled' ? 'Disable' : 'Enable'}
                  </MenuModalItem>
                </Menu>
                <Detail
                  id={table.dealerId}
                  open={drawerDetail && menu == index}
                  onClose={() => setDrawerDetail(false)}
                />
              </TableCell>
              <TableCell>{table.dealerId || '-'}</TableCell>
              <TableCell>{table.dealerCode || '-'}</TableCell>
              <TableCell>{table.name || '-'}</TableCell>
              <TableCell>{table.city || '-'}</TableCell>
              <TableCell>
                <Chip
                  label={table.status}
                  color={table.status == 'Enabled' ? 'success' : 'error'}
                />
              </TableCell>
            </TableRow>
          ))}
      </Content>

      {(resync.isLoading || dealer.isLoading) && <Loading />}

      <Filter
        open={dialogFilter}
        title='Filter'
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
        onReset={() => {
          setFilter({
            dealerId: '',
            status: '',
            province: '',
            city: ''
          })
          setPayload({
            ...payload,
            start: 0,
            dealerId: '',
            status: '',
            province: '',
            city: ''
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({ ...payload, start: 0, ...filter })
          onFilter(false)
        }}
      >
        <Autocomplete
          options={(dealerDropdown && dealerDropdown.data) || []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.id == value.id : false
          }
          onOpen={() => getDealerDropdown()}
          onChange={(_, dealer) =>
            setFilter({
              ...filter,
              dealerId: (dealer && dealer.dealerid) || ''
            })
          }
          value={
            (dealerDropdown &&
              dealerDropdown.data &&
              dealerDropdown.data.find((e) => e.dealerid == filter.dealerId)) ||
            null
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.id}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='dealerId'
              label='Dealer Name'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {dealerDropdown.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <FormControl fullWidth>
          <InputLabel id='select-label'>Status</InputLabel>
          <Select
            labelId='select-label'
            id='id-select-label'
            value={filter.status}
            label='Status'
            onChange={(event) => setFilter({ ...filter, status: event.target.value })}
          >
            <MenuItem value='Enabled'>Enabled</MenuItem>
            <MenuItem value='Disabled'>Disabled</MenuItem>
          </Select>
        </FormControl>
        <Autocomplete
          options={(province.data && province.data.data) || []}
          getOptionLabel={(option) => option.province}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.provinceId == value.provinceId : false
          }
          onOpen={() => onAsyncFilter((data: FormData) => getProvince(data))}
          onChange={(_, province) =>
            setFilter({ ...filter, province: (province && province.province) || '', city: '' })
          }
          value={
            (province &&
              province.data &&
              province.data.data.find((e) => e.province == filter.province)) ||
            null
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.provinceId}>
              {item.province}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='province'
              label='Province'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {province.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <Autocomplete
          options={(city.data && city.data.data) || []}
          getOptionLabel={(option) => option.city}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.cityId == value.cityId : false
          }
          onOpen={() => onAsyncFilter((data: FormData) => getCity(data))}
          onChange={(_, city) => setFilter({ ...filter, city: (city && city.city) || '' })}
          value={
            (city && city.data && city.data.data.find((e) => e.city == filter.city)) || undefined
          }
          disabled={isEmpty(filter.province)}
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.cityId}>
              {item.city}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='city'
              label='City'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {city.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
      </Filter>

      <Notification
        open={!dealer.isLoading && !dealer.isUninitialized && !dealer.isSuccess}
        onClose={() => (dealer.isError ? dealer.reset() : location.reload())}
        isError={Boolean(dealer.error) && dealer.isError}
        message={GLOBAL.returnExceptionMessage(dealer.isError, dealer.error as ErrorProps)}
      />

      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />

      <Notification
        open={!activate.isLoading && !activate.isUninitialized}
        onClose={() => (activate.isError ? activate.reset() : location.reload())}
        isError={Boolean(activate.error) && activate.isError}
        message={GLOBAL.returnExceptionMessage(activate.isError, activate.error as ErrorProps)}
      />
    </>
  )
}

export default Dealer
