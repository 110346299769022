import { useEffect } from 'react'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { Autocomplete, CircularProgress, Container, TextField } from '@mui/material'
import Dialog from 'components/dialog/dialog'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { PartialUpdateEvModelProps } from 'modules/partial'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import { useGetEvModelDetailQuery, useUpdateEvModelMutation } from 'store/evModel'
import { useGetCarBrandDropdownMutation } from 'store/dropdown'
import GlobalStyle from 'modules/styles'
import DialogStyle from './style'

const Edit = ({ open, id, name, onClose }: DetailStateProps & { id: number; name: string }) => {
  if (!open) return <></>

  const { data, isFetching, isLoading, isSuccess } = useGetEvModelDetailQuery(id)
  const [getCarBrandDropdown, brandDropdown] = useGetCarBrandDropdownMutation()
  const [updateEvModel, update] = useUpdateEvModelMutation()

  const scheme = yup.object<PartialUpdateEvModelProps>({
    BrandId: yup.number().required('EV Car Brand is required'),
    Name: yup.string().required('EV Car Model Id is required'),
    Description: yup.string().required('EV Car Model Id is required')
  })

  useEffect(() => {
    getCarBrandDropdown()
  }, [isSuccess])

  const selectedCarBrand =
    (!brandDropdown.isLoading &&
      brandDropdown &&
      brandDropdown.data &&
      brandDropdown.data.find((brand) => brand.name.toLowerCase() == name.toLowerCase())) ||
    null

  const formik = useFormik<PartialUpdateEvModelProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      Name: (data && data.name) || '',
      BrandId: (data && data.brandId) || null,
      Description: (data && data.description) || '',
      Status: (data && data.status) || 'Enabled'
    },
    onSubmit: (values: PartialUpdateEvModelProps) => handleSubmit(values)
  })

  const handleSubmit = (e: PartialUpdateEvModelProps) => {
    updateEvModel({ body: e, id })
  }

  return (
    <>
      <Dialog
        open={open}
        title='Update EV Car Model'
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          {(isLoading || isFetching) && !data && <Loading />}
          {data && (
            <>
              <FormikProvider value={formik}>
                <Autocomplete
                  options={(brandDropdown && brandDropdown.data) || []}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option && value ? option.id == value.id : false
                  }
                  onOpen={() => null}
                  value={selectedCarBrand}
                  ListboxProps={GlobalStyle.ListBox}
                  readOnly
                  renderOption={(props, item) => (
                    <li {...props} key={item.id}>
                      {item.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name='BrandId'
                      label='Brand Car Name'
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {brandDropdown.isLoading && (
                              <CircularProgress color='inherit' size={20} />
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                />
                <TextField
                  id='Name'
                  variant='outlined'
                  label='EV Car Model'
                  onChange={formik.handleChange}
                  value={formik.values.Name}
                  onBlur={formik.handleBlur}
                  error={formik.touched && formik.touched.Name && Boolean(formik.errors.Name)}
                  helperText={
                    formik.touched && formik.touched.Name && formik.errors && formik.errors.Name
                  }
                  fullWidth
                />
                <TextField
                  id='Description'
                  variant='outlined'
                  label='Description'
                  onChange={formik.handleChange}
                  value={formik.values.Description}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched &&
                    formik.touched.Description &&
                    Boolean(formik.errors.Description)
                  }
                  helperText={
                    formik.touched &&
                    formik.touched.Description &&
                    formik.errors &&
                    formik.errors.Description
                  }
                  fullWidth
                />
              </FormikProvider>
            </>
          )}
        </Container>
      </Dialog>

      <Notification
        open={!update.isLoading && !update.isUninitialized}
        onClose={() => (update.isError ? update.reset() : location.reload())}
        isError={Boolean(update.error) && update.isError}
        message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
      />
    </>
  )
}

export default Edit
