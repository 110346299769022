import * as yup from 'yup'
import { Container, TextField } from '@mui/material'
import Dialog from 'components/dialog/dialog'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import type { PartialEvChargingUrlProps } from 'modules/partial'
import FORMIK from 'modules/formik'
import { useUpdateChargingUrlMutation, useGetChargingDetailQuery } from 'store/evChargingUrl'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import DialogStyle from 'components/dialog/style'
import Loading from 'components/loading/loading'

const Edit = ({ open, id, onClose }: DetailStateProps & { id: number }) => {
  if (!open) return <></>

  const { data, isLoading, isFetching } = useGetChargingDetailQuery(id)

  const [updateChargingUrl, update] = useUpdateChargingUrlMutation()

  const scheme = yup.object<PartialEvChargingUrlProps>({
    code: yup.string().required('Code is required'),
    url: yup.string().required('URL is required'),
    remark: yup.string().required('Notes is required'),
    active: yup.boolean()
  })

  const initialValues: PartialEvChargingUrlProps = {
    code: (data && data.code) || '',
    url: (data && data.url) || '',
    remark: (data && data.remark) || '',
    active: (data && data.active) || false
  }

  const formik = FORMIK.useFormFormik<PartialEvChargingUrlProps>(scheme, initialValues, (values) =>
    updateChargingUrl({ body: values, id: data && data.id })
  )

  const errorData = formik.errors
  const loading = !data && (isLoading || isFetching)

  return (
    <>
      <Dialog
        title='Update New EV Charging URL'
        open={open}
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={update.isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          {loading && <Loading />}
          {!loading && (
            <>
              <TextField
                id='code'
                variant='outlined'
                label='QR Value'
                value={formik.values.code}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched && formik.touched.code && Boolean(errorData.code)}
                helperText={formik.touched && formik.touched.code && errorData && errorData.code}
                fullWidth
              />
              <TextField
                id='url'
                variant='outlined'
                label='Charge Box ID'
                value={formik.values.url}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched && formik.touched.url && Boolean(errorData.url)}
                helperText={formik.touched && formik.touched.url && errorData && errorData.url}
                fullWidth
              />
              <TextField
                id='remark'
                variant='outlined'
                label='Notes'
                value={formik.values.remark}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched && formik.touched.remark && Boolean(errorData.remark)}
                helperText={
                  formik.touched && formik.touched.remark && errorData && errorData.remark
                }
                fullWidth
              />
            </>
          )}
        </Container>
      </Dialog>

      <Notification
        open={!update.isLoading && !update.isUninitialized}
        onClose={() => (update.isError ? update.reset() : location.reload())}
        isError={Boolean(update.error) && update.isError}
        message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
      />
    </>
  )
}

export default Edit
