import CONSTANT from 'modules/constant'
import type {
  PartialFaqCategoryDetailProps,
  PartialFaqListDetailProps,
  PartialStatusProps
} from 'modules/partial'
import type { FaqCategoryProps, FaqListProps, PaginationProps, ResultProps } from 'modules/types'
import { API, download, headers } from './api'

const FaqAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    createFaqCategory: builder.mutation<ResultProps, PartialFaqCategoryDetailProps>({
      query: (body) => ({
        url: CONSTANT.URL_FAQ_CATEGORY_CREATE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    getFaqCategory: builder.mutation<PaginationProps<FaqCategoryProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_FAQ_CATEGORY,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    getFaqCategoryDetail: builder.query<PartialFaqCategoryDetailProps, { key: string }>({
      query: (body) => ({
        url: CONSTANT.URL_FAQ_CATEGORY_DETAIL,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    enableFaqCategory: builder.mutation<ResultProps, PartialStatusProps>({
      query: (body) => ({
        url: CONSTANT.URL_FAQ_CATEGORY_STATUS,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    updateFaqCategory: builder.mutation<ResultProps, PartialFaqCategoryDetailProps>({
      query: (body) => ({
        url: CONSTANT.URL_FAQ_CATEGORY_UPDATE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    deleteFaqCategory: builder.mutation<ResultProps, { key: string }>({
      query: (body) => ({
        url: CONSTANT.URL_FAQ_CATEGORY_DELETE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    downloadFaqCategory: builder.mutation<PaginationProps<FaqCategoryProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_FAQ_CATEGORY_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        responseHandler: download,
        body: body,
        formData: true
      })
    }),
    createFaqList: builder.mutation<ResultProps, PartialFaqListDetailProps>({
      query: (body) => ({
        url: CONSTANT.URL_FAQ_LIST_CREATE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    getFaqList: builder.mutation<PaginationProps<FaqListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_FAQ_LIST,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    getFaqListDetail: builder.query<PartialFaqListDetailProps, { id: number }>({
      query: (body) => ({
        url: CONSTANT.URL_FAQ_LIST_DETAIL,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    enableFaqList: builder.mutation<ResultProps, PartialStatusProps>({
      query: (body) => ({
        url: CONSTANT.URL_FAQ_LIST_STATUS,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    updateFaqList: builder.mutation<ResultProps, PartialFaqListDetailProps>({
      query: (body) => ({
        url: CONSTANT.URL_FAQ_LIST_UPDATE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    deleteFaqList: builder.mutation<ResultProps, { id: number }>({
      query: (body) => ({
        url: CONSTANT.URL_FAQ_LIST_DELETE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    downloadFaqList: builder.mutation<PaginationProps<FaqListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_FAQ_LIST_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        responseHandler: download,
        body: body,
        formData: true
      })
    })
  })
})

export const {
  useGetFaqCategoryMutation,
  useCreateFaqCategoryMutation,
  useGetFaqCategoryDetailQuery,
  useUpdateFaqCategoryMutation,
  useEnableFaqCategoryMutation,
  useDeleteFaqCategoryMutation,
  useDownloadFaqCategoryMutation,

  useGetFaqListMutation,
  useCreateFaqListMutation,
  useGetFaqListDetailQuery,
  useUpdateFaqListMutation,
  useEnableFaqListMutation,
  useDeleteFaqListMutation,
  useDownloadFaqListMutation
} = FaqAPI
