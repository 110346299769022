import { Container, Grid, TextField } from '@mui/material'
import Drawer from 'components/drawer/detail'
import type { DetailStateProps } from 'modules/types'
import Loading from 'components/loading/loading'
import { useGetChargeBoxGroupDetailQuery } from 'store/evChargeBoxGroup'
import DrawerStyle from './style'

const Detail = ({ open, id, onClose }: DetailStateProps & { id: number }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const { data, isFetching, isLoading } = useGetChargeBoxGroupDetailQuery(id)

  const chargeBoxGroupId = (data && data.code) || '-'
  const chargeBoxGroupIdMyHyundai = (data && data.id) || '-'
  const chargePointId = (data && data.chargePointId) || '-'
  const chargePointName = (data && data.chargePointName) || '-'

  const gridDataLabel = [
    'Charge Box Group ID by PlugIt',
    'Charge Box Group ID by myHyundai',
    'Charge Point ID',
    'Charge Point Name'
  ]

  const gridDataValue = [
    chargeBoxGroupId,
    chargeBoxGroupIdMyHyundai,
    chargePointId,
    chargePointName
  ]

  return (
    <Drawer open={open} title='EV Charge Box Group Detail' onClose={onClose}>
      <Container {...DrawerStyle.Container}>
        {(isLoading || isFetching) && !data && <Loading />}
        {data && (
          <Grid container spacing={2}>
            {gridDataValue.map((item, index) => (
              <Grid key={index} item xs={12}>
                <TextField
                  variant='outlined'
                  label={gridDataLabel[index]}
                  value={item}
                  inputProps={{ readOnly: true }}
                  fullWidth
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
    </Drawer>
  )
}

export default Detail
