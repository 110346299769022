import { Field, FieldArray, FormikProvider, useFormik } from 'formik'
import ReactQuill from 'react-quill'
import * as yup from 'yup'
import { Container, Stack, TextField } from '@mui/material'
import { encode } from 'html-entities'
import Dialog from 'components/dialog/dialog'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import { useCreatePrivacyPolicyMutation } from 'store/privacy'
import type { PartialPrivacyDetailDataProps, PartialPrivacyDetailProps } from 'modules/partial'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import DialogStyle from './style'

import 'react-quill/dist/quill.snow.css'

const Create = ({ open, onClose }: DetailStateProps) => {
  const [createPrivacyPolicy, create] = useCreatePrivacyPolicyMutation()

  const scheme = yup.object<PartialPrivacyDetailDataProps>({
    key: yup.string().required('Key is required'),
    data: yup.array().of(
      yup.object({
        language: yup.string().required('Language is required'),
        body: yup.string()
      })
    )
  })

  const formik = useFormik<PartialPrivacyDetailProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      key: '',
      data: [
        { language: 'ID', body: '', status: 'Enabled' },
        { language: 'EN-US', body: '', status: 'Enabled' }
      ]
    },
    onSubmit: (values: PartialPrivacyDetailProps) =>
      createPrivacyPolicy({
        ...values,
        data: values.data.map((el) => ({ ...el, body: encode(el.body) }))
      })
  })

  return (
    <>
      <Dialog
        title='Create New Privacy Policy'
        open={open}
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={create.isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          <TextField
            id='key'
            variant='outlined'
            label='Privacy Policy Name'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched && formik.touched.key && Boolean(formik.errors.key)}
            helperText={formik.touched && formik.touched.key && formik.errors && formik.errors.key}
            fullWidth
          />
          <FormikProvider value={formik}>
            {formik.values.data.map((_, index) => (
              <FieldArray
                key={index}
                name='data'
                render={() => (
                  <Stack {...DialogStyle.Stack}>
                    <TextField
                      id={`data[${index}].language`}
                      variant='outlined'
                      label='Language'
                      value={formik.values.data[index].language}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      inputProps={{ readOnly: true }}
                      fullWidth
                    />
                    <Field>
                      {() => (
                        <ReactQuill
                          theme='snow'
                          id={`data[${index}].body`}
                          onChange={(e) =>
                            formik.setFieldValue(`data[${index}].body`, GLOBAL.emptyQuill(e))
                          }
                        />
                      )}
                    </Field>
                  </Stack>
                )}
              />
            ))}
          </FormikProvider>
        </Container>
      </Dialog>

      <Notification
        open={!create.isLoading && !create.isUninitialized}
        onClose={() => (create.isError ? create.reset() : location.reload())}
        isError={Boolean(create.error) && create.isError}
        message={GLOBAL.returnExceptionMessage(create.isError, create.error as ErrorProps)}
      />
    </>
  )
}

export default Create
