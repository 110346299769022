import { useState } from 'react'
import { isEmpty } from 'lodash'
import { Container, TextField } from '@mui/material'
import Dialog from 'components/dialog/dialog'
import Notification from 'components/notification'
import type { DetailStateProps, ErrorFetchProps, ErrorProps } from 'modules/types'
import { useUploadVoucherGiftMutation } from 'store/voucherGift'
import DialogStyle from './style'

const Upload = ({ open, onClose }: DetailStateProps) => {
  const [file, setFile] = useState<string | Blob>('')
  const [hasErr, setHasErr] = useState<boolean>(false)
  const [isErr, setIsErr] = useState<boolean>(false)
  const [isSuccess, setIsSucces] = useState<boolean>(false)
  const [messageSucces, setMessageSucces] = useState<string>('')
  const [messageErr, setMessageErr] = useState<string>('')

  const [uploadVoucherGift, upload] = useUploadVoucherGiftMutation()

  const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e && e.target && e.target.files

    if (files && files.length > 0 && !isEmpty(files)) {
      setFile(files[0])
      setHasErr(false)
    }
  }

  const onSubmit = () => {
    if (!file) {
      setHasErr(true)
      return
    }
    const formData = new FormData()

    formData.append('payload', file)
    uploadVoucherGift(formData)
      .unwrap()
      .then(() => {
        setIsSucces(true)
        setMessageSucces('Sukses')
        setIsErr(false)
      })
      .catch((err: ErrorProps | ErrorFetchProps | Response) => {
        setIsErr(true)
        const error = err as ErrorProps
        const errorFetch = err as ErrorFetchProps
        const message = 'data' in err && error.data ? error.data.Message : errorFetch.message
        setMessageErr(message)
      })
  }

  return (
    <>
      <Dialog
        open={open}
        title='Upload Gift Voucher'
        onCancel={onClose}
        onSubmit={onSubmit}
        loading={upload.isLoading}
        isDisabled={!file.toString().length}
      >
        <Container {...DialogStyle.Container}>
          <TextField
            id='File'
            variant='standard'
            label='File'
            inputProps={{
              accept:
                '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
            }}
            type='file'
            onChange={onChangeFile}
            error={hasErr && !file}
            helperText={hasErr && !file && 'file Cannot be empty'}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
        </Container>
      </Dialog>
      <Notification
        open={isSuccess}
        onClose={() => setIsSucces(false)}
        isError={false}
        message={messageSucces}
      />
      <Notification
        open={isErr}
        onClose={() => setIsErr(false)}
        isError={true}
        message={messageErr}
      />
    </>
  )
}

export default Upload
