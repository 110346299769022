import { Container, TextField } from '@mui/material'
import dayjs from 'dayjs'
import Drawer from 'components/drawer/detail'
import Loading from 'components/loading/loading'
import type { DetailStateProps } from 'modules/types'
import { useGetTokenRequestDetailQuery } from 'store/tokenRequest'
import DrawerStyle from './style'

const Detail = ({ open, id, onClose }: DetailStateProps & { id: string | number }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const { data, isFetching, isLoading } = useGetTokenRequestDetailQuery(id)

  const arrData = [
    { value: 'voucherId', label: 'Voucher ID' },
    { value: 'voucherName', label: 'Voucher Name' },
    { value: 'partner', label: 'Partner' },
    { value: 'progress', label: 'Progress' },
    { value: 'progressNotes', label: 'Progress Note' },
    { value: 'taskStatus', label: 'Task Status' },
    { value: 'quantity', label: 'Quantity' },
    { value: 'validFrom', label: 'Valid From', type: 'date' },
    { value: 'validUntil', label: 'Valid Until', type: 'date' },
    { value: 'startTime', label: 'Start Time', type: 'date' },
    { value: 'endTime', label: 'End Time', type: 'date' }
  ]

  return (
    <>
      <Drawer open={open} title='Detail Token Request' onClose={onClose}>
        <Container {...DrawerStyle.Container}>
          {(isLoading || isFetching) && !data && <Loading />}
          {data && (
            <>
              {arrData.map((item, index) => (
                <TextField
                  key={index}
                  variant='outlined'
                  label={item.label}
                  value={
                    data && item?.type === 'date'
                      ? dayjs(data[item.value as never]).format('DD MMMM YYYY HH:mm')
                      : data[item.value as never]
                  }
                  inputProps={{ readOnly: true }}
                  fullWidth
                />
              ))}
            </>
          )}
        </Container>
      </Drawer>
    </>
  )
}

export default Detail
