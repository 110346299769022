import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import type { DialogProps } from '@mui/material'
import type { Children } from 'modules/types'
import ModalStyle from './style'

type FilterProps = DialogProps &
  Children & {
    title?: string
    isSubmitDisabled?: boolean
    onCancel?: () => void
    onReset?: () => void
    onSubmit?: () => void
  }

const Filter = ({ isSubmitDisabled = false, ...props }: FilterProps) => (
  <Dialog {...props} fullWidth>
    <DialogTitle>{props.title}</DialogTitle>
    <DialogContent>
      <Container {...ModalStyle.Container}>{props.children}</Container>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.onCancel}>Cancel</Button>
      <Button onClick={props.onReset}>Reset</Button>
      <Button disabled={isSubmitDisabled} variant='contained' onClick={props.onSubmit}>
        Submit
      </Button>
    </DialogActions>
  </Dialog>
)

export default Filter
