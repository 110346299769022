import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Autocomplete,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  TextField
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import CONSTANT from 'modules/constant'
import type { PartialEvTransactionFilter } from 'modules/partial'
import Loading from 'components/loading/loading'
import Icon from 'components/icon'
import { useGetChargePointDropdownListMutation } from 'store/evChargePoint'
import { useGetChargeBoxGroupDropdownListMutation } from 'store/evChargeBoxGroup'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { ErrorProps } from 'modules/types'
import GlobalStyle from 'modules/styles'
import {
  useDownloadEvTransactionFinishedMutation,
  useGetEvTransactionFinishedMutation
} from 'store/evTransaction'
import Detail from './detail/detail'

const EVHistoryFinishedList = () => {
  const navigate = useNavigate()

  const [getEvTransactionFinished, evFinished] = useGetEvTransactionFinishedMutation()
  const [downloadEvTransactionFinished, download] = useDownloadEvTransactionFinishedMutation()
  const [getChargePointDropdownList, chargePointDropdown] = useGetChargePointDropdownListMutation()
  const [getChargeBoxGroupDropdownList, chargeBoxGroupDropdown] =
    useGetChargeBoxGroupDropdownListMutation()

  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [triggerDate, setTriggerDate] = useState<Dayjs | null>(null)
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [menu, setMenu] = useState<null | number>(null)

  const [filter, setFilter] = useState({
    ChargePointId: '',
    ChargeBoxGroupId: '',
    StartTrigger: ''
  })

  const [payload, setPayload] = useState<PartialEvTransactionFilter>({
    Start: 0,
    Length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    Filter: '',
    Search: '',
    ChargePointId: '',
    ChargeBoxGroupId: '',
    StartTrigger: ''
  })

  const onResync = () => {
    const formData = new FormData()

    formData.append('Start', payload.Start.toString())
    formData.append('Length', payload.Length.toString())

    if (!isEmpty(payload.Search)) formData.append('Search', payload.Search)
    if (!isEmpty(payload.Filter)) formData.append('Filter', payload.Filter)
    if (!isEmpty(payload.ChargePointId)) formData.append('ChargePointId', payload.ChargePointId)
    if (!isEmpty(payload.ChargeBoxGroupId))
      formData.append('ChargeBoxGroupId', payload.ChargeBoxGroupId)
    if (!isEmpty(payload.StartTrigger))
      formData.append('StartTrigger', String(payload.StartTrigger))

    getEvTransactionFinished(formData)
  }

  const onChangePage = (value: number) => setPayload({ ...payload, Start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, Length: value })

  const onSearch = (value: string) => setPayload({ ...payload, Start: 0, Search: value })
  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }
  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const onFilter = (state: boolean) => setDialogFilter(state)

  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('start', String(0))
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())

    if (!isEmpty(payload.Search)) formData.append('Search', payload.Search)
    if (!isEmpty(payload.Filter)) formData.append('Filter', payload.Filter)
    if (!isEmpty(payload.ChargePointId)) formData.append('ChargePointId', payload.ChargePointId)
    if (!isEmpty(payload.ChargeBoxGroupId))
      formData.append('ChargeBoxGroupId', payload.ChargeBoxGroupId)
    if (!isEmpty(payload.StartTrigger))
      formData.append('StartTrigger', String(payload.StartTrigger))
    if (type && !isEmpty(type)) formData.append('Content-Type', type)

    downloadEvTransactionFinished(formData)
  }

  const handleChangeStartTrigger = (date: Dayjs | null) => {
    const newDate = (!isEmpty(date) && date.format('YYYY-MM-DD')) || ''
    setFilter({ ...filter, StartTrigger: newDate })

    setTriggerDate(date)
  }

  const tableHeadTitles = [
    'Charging Transaction ID (Hyundai)',
    'ID Tag',
    'Charging Transaction Status',
    'Charge Point Name',
    'Charge Box Group ID',
    'User Member ID',
    'Start Trigger',
    'Time Elapsed',
    'kWh Elapsed',
    'Total Charging Payment',
    'Token Ending Balance'
  ]

  useEffect(() => {
    onResync()
  }, [payload])

  const openAnchor = Boolean(anchor)
  const totalPage = evFinished.data && GLOBAL.tableTotalPage(evFinished.data.recordsTotal)
  const numbers = GLOBAL.tableNumber(payload.Start)

  return (
    <>
      <Content
        title='Finished'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        page={payload.Start + 1}
        isLoading={evFinished.isLoading}
        totalRecords={evFinished.data && evFinished.data.recordsTotal}
        useDownloadDropdown
      >
        {evFinished.isSuccess &&
          evFinished.data &&
          evFinished.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.chargePointName}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.chargePointName}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.chargePointName}`}
                  aria-labelledby={`button-${index}-${table.chargePointName}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      onRemoveAnchor(() => navigate(GLOBAL.encodeUrl(String(table.transactionId))))
                    }
                  >
                    View Log
                  </MenuItem>
                </Menu>
                <Detail
                  id={String(table.transactionId)}
                  open={drawerDetail && menu == index}
                  onClose={() => setDrawerDetail(false)}
                />
              </TableCell>
              <TableCell>{table.transactionCode || '-'}</TableCell>
              <TableCell>{table.idTag || '-'}</TableCell>
              <TableCell>{table.status || '-'}</TableCell>
              <TableCell>{table.chargePointName || '-'}</TableCell>
              <TableCell>{table.chargeBoxGroupCode || '-'}</TableCell>
              <TableCell>{table.memberCode || '-'}</TableCell>
              <TableCell>{table.startTrigger || '-'}</TableCell>
              <TableCell>{table.timeElapsed || '-'}</TableCell>
              <TableCell>{table.kwhElapsed}</TableCell>
              <TableCell>{GLOBAL.numberFormat(table.totalChargingPayment) || '-'}</TableCell>
              <TableCell>{GLOBAL.numberFormat(table.tokenEndingBalance) || '-'}</TableCell>
            </TableRow>
          ))}
      </Content>

      {evFinished.isLoading && <Loading />}

      <Notification
        open={!evFinished.isLoading && !evFinished.isUninitialized && !evFinished.isSuccess}
        onClose={() => (evFinished.isError ? evFinished.reset() : location.reload())}
        isError={evFinished.isError}
        message={GLOBAL.returnExceptionMessage(evFinished.isError, evFinished.error as ErrorProps)}
      />

      <Filter
        open={dialogFilter}
        title='Filter'
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
        onReset={() => {
          setTriggerDate(null)
          setFilter({
            ChargePointId: '',
            ChargeBoxGroupId: '',
            StartTrigger: ''
          })
          setPayload({
            ...payload,
            Start: 0,
            ChargePointId: '',
            ChargeBoxGroupId: '',
            StartTrigger: ''
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({ ...payload, ...filter, Start: 0 })
          onFilter(false)
        }}
      >
        <Autocomplete
          options={(chargePointDropdown && chargePointDropdown.data) || []}
          getOptionLabel={(option) => option.text}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.value == value.value : false
          }
          onOpen={() => getChargePointDropdownList(CONSTANT.DEFAULT_DROPDOWN_PAYLOAD)}
          onChange={(_, chargePoint) =>
            setFilter({
              ...filter,
              ChargePointId: (chargePoint && chargePoint.value.toString()) || ''
            })
          }
          value={
            (chargePointDropdown &&
              chargePointDropdown.data &&
              chargePointDropdown.data.find(
                (e) => String(e.value) == String(filter.ChargePointId)
              )) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.value}>
              {item.text}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='ChargePointId'
              label='Charge Point'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {chargePointDropdown.isLoading && (
                      <CircularProgress color='inherit' size={20} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <Autocomplete
          key={filter.ChargePointId}
          options={(chargeBoxGroupDropdown && chargeBoxGroupDropdown.data) || []}
          getOptionLabel={(option) => option.text}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.value == value.value : false
          }
          onOpen={() =>
            getChargeBoxGroupDropdownList({
              ...CONSTANT.DEFAULT_DROPDOWN_PAYLOAD,
              id: Number(filter.ChargePointId)
            })
          }
          onChange={(_, chargeBoxGroup) =>
            setFilter({
              ...filter,
              ChargeBoxGroupId: (chargeBoxGroup && chargeBoxGroup.value.toString()) || ''
            })
          }
          value={
            (chargeBoxGroupDropdown &&
              chargeBoxGroupDropdown.data &&
              chargeBoxGroupDropdown.data.find(
                (e) => String(e.value) == String(filter.ChargeBoxGroupId)
              )) ||
            undefined
          }
          disabled={isEmpty(filter.ChargePointId)}
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.value}>
              {item.text}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='ChargeBoxGroupId'
              label='Charge Box Group ID'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {chargeBoxGroupDropdown.isLoading && (
                      <CircularProgress color='inherit' size={20} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
          <DatePicker
            value={triggerDate}
            onChange={handleChangeStartTrigger}
            label='Start Time'
            format='DD-MM-YYYY'
          />
        </LocalizationProvider>
      </Filter>

      <Notification
        open={!evFinished.isLoading && !evFinished.isUninitialized && !evFinished.isSuccess}
        onClose={() => evFinished.reset()}
        isError={Boolean(evFinished.error) && evFinished.isError}
        message={GLOBAL.returnExceptionMessage(evFinished.isError, evFinished.error as ErrorProps)}
      />

      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />
    </>
  )
}

export default EVHistoryFinishedList
