import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  TextField
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import type { Dayjs } from 'dayjs'
import { useLocation, useParams } from 'react-router-dom'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import CONSTANT from 'modules/constant'
import type { PartialTokenSubImportProps } from 'modules/partial'
import Icon from 'components/icon'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { DropdownCategoryListProps, ErrorProps, ParamProps } from 'modules/types'
import GlobalStyle from 'modules/styles'
import {
  useDownloadTokenSubImportMutation,
  useGetCampaignDropdownMutation,
  useGetCampaignTypeDropdownMutation,
  useGetListTokenSubImportMutation
} from 'store/tokenImport'
import RevokeToken from 'pages/voucher/token/list/revoke/revoke'
import MoveToken from 'pages/voucher/token/list//move/move'
import { useGetTokenPartnerDropdownMutation } from 'store/token'
import {
  useGetTokenCategoryDropdownMutation,
  useGetVoucherListDropdownMutation
} from 'store/tokenRequest'
import Detail from './detail/detail'
import Edit from './edit/edit'

const TokenImportSub = () => {
  const params = useParams<ParamProps>()
  const locationState = useLocation()

  const key = params && params.key

  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [dialogFilter, setDialogFilter] = useState<boolean>(false)

  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [dialogRevoke, setDialogRevoke] = useState<boolean>(false)
  const [dialogUpdate, setDialogUpdate] = useState<boolean>(false)
  const [dialogMove, setDialogMove] = useState<boolean>(false)
  const [menu, setMenu] = useState<null | number>(null)
  const [getListTokenSubImport, tokenSubImport] = useGetListTokenSubImportMutation()
  const [downloadTokenSubImport, download] = useDownloadTokenSubImportMutation()
  const [getTokenPartnerDropdown, partnerDropdown] = useGetTokenPartnerDropdownMutation()
  const [getVoucherListDropdown, voucherDropdown] = useGetVoucherListDropdownMutation()
  const [getTokenCategoryDropdown, tokenCategoryDropdown] = useGetTokenCategoryDropdownMutation()
  const [getCampaignDropdown, campaignDropdown] = useGetCampaignDropdownMutation()
  const [getCampaignTypeDropdown, campaignTypeDropdown] = useGetCampaignTypeDropdownMutation()

  const [listChecked, setListChecked] = useState([])
  const [isChecked, setIsChecked] = useState<boolean>(false)

  const [payload, setPayload] = useState<PartialTokenSubImportProps>({
    start: 0,
    length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    field: CONSTANT.DEFAULT_TOKEN_SUB_REQUEST_FIELD.join(','),
    search: { value: '' },
    status: '',
    tokenImportId: locationState.state.id
  })

  const [filter, setFilter] = useState({
    minValidFrom: null,
    maxValidFrom: null,
    minValidUntil: null,
    maxValidUntil: null,
    minCreatedDate: null,
    maxCreatedDate: null,
    minPurchasedDate: null,
    maxPurchasedDate: null,
    minClaimedDate: null,
    maxClaimedDate: null,
    minRevokedDate: null,
    maxRevokedDate: null,
    voucherId: '',
    voucherName: '',
    partner: '',
    purchasedFlag: '',
    purchasedBy: '',
    claimedFlag: '',
    claimedBy: '',
    revokedFlag: '',
    revokedBy: '',
    remarks: '',
    campaignName: '',
    campaignType: ''
  })

  const dateFilter = [
    { value: 'minValidFrom', label: 'Min Valid From', id: 'min-valid-from' },
    { value: 'maxValidFrom', label: 'Max Valid From', id: 'max-valid-from' },
    { value: 'minValidUntil', label: 'Min Valid Until', id: 'min-valid-until' },
    { value: 'maxValidUntil', label: 'Max Valid Until', id: 'max-valid-until' },
    { value: 'minCreatedDate', label: 'Min Created Date', id: 'min-created-date' },
    { value: 'maxCreatedDate', label: 'Max Created Date', id: 'max-created-date' },
    { value: 'minPurchasedDate', label: 'Min Purchased Date', id: 'min-purchased-date' },
    { value: 'maxPurchasedDate', label: 'Max Purchased Date', id: 'max-purchased-date' },
    { value: 'minClaimedDate', label: 'Min Claimed Date', id: 'min-claimed-date' },
    { value: 'maxClaimedDate', label: 'Max Claimed Date', id: 'max-claimed-date' },
    { value: 'minRevokedDate', label: 'Min Revoked Date', id: 'min-revoked-date' },
    { value: 'maxRevokedDate', label: 'Max Revoked Date', id: 'max-revoked-date' }
  ]

  const onSearch = (value: string) => setPayload({ ...payload, start: 0, search: { value } })
  const onResync = () => {
    const formData = new FormData()
    formData.append('start', payload.start.toString())
    formData.append('length', payload.length.toString())
    formData.append('field', payload.field)
    formData.append('token-import-id', payload.tokenImportId.toString())

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    dateFilter.map((item) => {
      if (!isEmpty(filter[item.value as never]))
        formData.append(item.id, filter[item.value as never])
    })
    if (!isEmpty(filter.voucherId)) formData.append('voucher-id', filter.voucherId)
    if (!isEmpty(filter.partner)) formData.append('partner', filter.partner)
    if (!isEmpty(filter.purchasedFlag)) formData.append('purchased-flag', filter.purchasedFlag)
    if (!isEmpty(filter.purchasedBy)) formData.append('purchased-by', filter.purchasedBy)
    if (!isEmpty(filter.claimedFlag)) formData.append('claimed-flag', filter.claimedFlag)
    if (!isEmpty(filter.claimedBy)) formData.append('claimed-by', filter.claimedBy)
    if (!isEmpty(filter.revokedFlag)) formData.append('revoked-flag', filter.revokedFlag)
    if (!isEmpty(filter.revokedBy)) formData.append('revoked-by', filter.revokedBy)
    if (!isEmpty(filter.remarks)) formData.append('remarks', filter.remarks)

    getListTokenSubImport(formData)
  }
  const onFilter = (state: boolean) => setDialogFilter(state)
  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('start', String(0))
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())
    formData.append('field', payload.field)
    formData.append('token-import-id', payload.tokenImportId.toString())

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    dateFilter.map((item) => {
      if (!isEmpty(filter[item.value as never]))
        formData.append(item.id, filter[item.value as never])
    })
    if (!isEmpty(filter.voucherId)) formData.append('voucher-id', filter.voucherId)
    if (!isEmpty(filter.partner)) formData.append('partner', filter.partner)
    if (!isEmpty(filter.purchasedFlag)) formData.append('purchased-flag', filter.purchasedFlag)
    if (!isEmpty(filter.purchasedBy)) formData.append('purchased-by', filter.purchasedBy)
    if (!isEmpty(filter.claimedFlag)) formData.append('claimed-flag', filter.claimedFlag)
    if (!isEmpty(filter.claimedBy)) formData.append('claimed-by', filter.claimedBy)
    if (!isEmpty(filter.revokedFlag)) formData.append('revoked-flag', filter.revokedFlag)
    if (!isEmpty(filter.revokedBy)) formData.append('revoked-by', filter.revokedBy)
    if (!isEmpty(filter.remarks)) formData.append('remarks', filter.remarks)
    if (type && !isEmpty(type)) formData.append('Download-Type', type)

    downloadTokenSubImport(formData)
  }

  const onChangePage = (value: number) => setPayload({ ...payload, start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, length: value })

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const tableHeadTitles = ['Code', 'Partner', 'Valid From', 'Valid Until', 'Notes', 'Status']

  const openAnchor = Boolean(anchor)
  const totalPage =
    tokenSubImport.data &&
    Math.ceil(tokenSubImport.data.recordsTotal / CONSTANT.DEFAULT_PAGINATION_PER_PAGE)

  const numbers = Array.from(
    Array(CONSTANT.DEFAULT_PAGINATION_PER_PAGE * (payload.start + 1)).keys()
  ).slice(-CONSTANT.DEFAULT_PAGINATION_PER_PAGE)

  useEffect(() => {
    onResync()
  }, [payload])

  const handleChangeDate = (key: string) => (date: Dayjs | null) => {
    const newDate = (!isEmpty(date) && date?.format('YYYY-MM-DD')) || ''
    setFilter({ ...filter, [key]: newDate })
  }

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) =>
    event.target.checked
      ? setListChecked([...listChecked, event.target.value] as never)
      : setListChecked([...listChecked].filter((o) => o !== event.target.value))
  const handleCheckAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(!isChecked)

    return event.target.checked
      ? setListChecked([
          ...listChecked,
          ...(((tokenSubImport.data && tokenSubImport.data.data.map((x) => x.id + '-' + x.code)) ||
            undefined) as never)
        ])
      : setListChecked([])
  }

  const dataBoolean = [
    { value: 'No', name: 'No' },
    { value: 'Yes', name: 'Yes' }
  ]

  const mappingVoucher = (arrData: []) => {
    if (arrData.length > 0) {
      return arrData.map((item: DropdownCategoryListProps) => ({
        id: item.id,
        name: item.id + ' - ' + item.name
      }))
    }
    return []
  }

  return (
    <>
      <Content
        title={`Token Import › ${GLOBAL.decodeUrl(key || '')}`}
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={tokenSubImport.data && tokenSubImport.data.recordsTotal}
        page={payload.start + 1}
        isLoading={tokenSubImport.isLoading}
        useDownloadDropdown
        useBackButton
        useCheckbox
        onCheckBoxAll={(e) => handleCheckAll(e)}
        allChecked={listChecked}
        onEditValidity={() => setDialogUpdate(true)}
        onRevokeToken={() => setDialogRevoke(true)}
        onMoveToken={() => setDialogMove(true)}
        editValidityPrefixIcon='Edit'
      >
        {tokenSubImport.isSuccess &&
          tokenSubImport.data &&
          tokenSubImport?.data?.data?.map((item, index) => (
            <TableRow key={index}>
              <TableCell>
                <Checkbox
                  value={`${item.id}-${item.code}`}
                  onChange={(e) => handleCheck(e)}
                  checked={listChecked.includes(`${item.id}-${item.code}` as never)}
                />
              </TableCell>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${item.id}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${item.id}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${item.id}`}
                  aria-labelledby={`button-${index}-${item.id}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View Detail
                  </MenuItem>
                </Menu>
                {drawerDetail && menu == index && (
                  <Detail
                    id={item.id}
                    open={drawerDetail && menu == index}
                    isFailed={item.statusRequest == 'Failed'}
                    onClose={() => setDrawerDetail(false)}
                  />
                )}
              </TableCell>
              <TableCell>{item.code || '-'}</TableCell>
              <TableCell>{item.partnerName || '-'}</TableCell>
              <TableCell>{dayjs(item.validFrom).format('DD MMMM YYYY HH:mm') || '-'}</TableCell>
              <TableCell>{dayjs(item.validUntil).format('DD MMMM YYYY HH:mm') || '-'}</TableCell>
              <TableCell>{item.notes || '-'}</TableCell>
              <TableCell>{item.statusRequest || '-'}</TableCell>
            </TableRow>
          ))}
      </Content>
      {tokenSubImport.isLoading && <Loading />}

      <Filter
        open={dialogFilter}
        title='Filter'
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        onReset={() => {
          setFilter({
            minValidFrom: null,
            maxValidFrom: null,
            minValidUntil: null,
            maxValidUntil: null,
            minCreatedDate: null,
            maxCreatedDate: null,
            minPurchasedDate: null,
            maxPurchasedDate: null,
            minClaimedDate: null,
            maxClaimedDate: null,
            minRevokedDate: null,
            maxRevokedDate: null,
            voucherId: '',
            voucherName: '',
            partner: '',
            purchasedFlag: '',
            purchasedBy: '',
            claimedFlag: '',
            claimedBy: '',
            revokedFlag: '',
            revokedBy: '',
            remarks: '',
            campaignName: '',
            campaignType: ''
          })
          setPayload({ ...payload, start: 0, ...filter })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({ ...payload, start: 0, ...filter })
          onFilter(false)
        }}
      >
        <Grid container spacing={2} columns={16}>
          {dateFilter.map((item, index) => (
            <Grid item xs={8} key={index}>
              <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                <DatePicker
                  key={item.value}
                  onChange={handleChangeDate(item.value)}
                  label={item.label}
                  value={filter[item.value as never] ? dayjs(filter[item.value as never]) : null}
                />
              </LocalizationProvider>
            </Grid>
          ))}
          <Grid item xs={8}>
            <Autocomplete
              options={mappingVoucher((voucherDropdown.data as never) || [])}
              getOptionLabel={(list) => list.name}
              isOptionEqualToValue={(option, value) =>
                option && value ? option.id == value.id : false
              }
              onOpen={() => getVoucherListDropdown()}
              onChange={(_, id) => {
                if (id) {
                  setFilter({
                    ...filter,
                    voucherId: String(id?.id)
                  })
                }
              }}
              ListboxProps={GlobalStyle.ListBox}
              value={
                voucherDropdown &&
                voucherDropdown.data &&
                voucherDropdown.data.find((e) => e.id == String(filter.voucherId))
              }
              renderOption={(props, item) => (
                <li {...props} key={item.id}>
                  {item.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='voucherId'
                  label='Voucher'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {voucherDropdown.isLoading && (
                          <CircularProgress color='inherit' size={20} />
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
              fullWidth
            />
          </Grid>
          <Grid item xs={8}>
            <Autocomplete
              options={partnerDropdown.data || []}
              getOptionLabel={(list) => list.name}
              isOptionEqualToValue={(option, value) =>
                option && value ? option.id == value.id : false
              }
              onOpen={() => getTokenPartnerDropdown()}
              onChange={(_, id) => {
                if (id) {
                  setFilter({
                    ...filter,
                    partner: String(id?.id)
                  })
                }
              }}
              value={
                partnerDropdown &&
                partnerDropdown.data &&
                partnerDropdown.data.find((e) => e.id == Number(filter.partner))
              }
              ListboxProps={GlobalStyle.ListBox}
              renderOption={(props, item) => (
                <li {...props} key={item.id}>
                  {item.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='partner'
                  label='Partner'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {partnerDropdown.isLoading && (
                          <CircularProgress color='inherit' size={20} />
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
              fullWidth
            />
          </Grid>
          <Grid item xs={8}>
            <Autocomplete
              options={tokenCategoryDropdown.data || []}
              getOptionLabel={(list) => list.name}
              isOptionEqualToValue={(option, value) =>
                option && value ? option.id == value.id : false
              }
              onOpen={() => getTokenCategoryDropdown()}
              // onChange={(_, id) => {
              //   if (id) {
              //     setFilter({
              //       ...filter,
              //       tokenCategoryId: id && String(id?.id)
              //     })
              //   }
              // }}
              // value={
              //   tokenCategoryDropdown &&
              //   tokenCategoryDropdown.data &&
              //   tokenCategoryDropdown.data.find((e) => e.id == Number(filter.tokenCategoryId))
              // }
              ListboxProps={GlobalStyle.ListBox}
              renderOption={(props, item) => (
                <li {...props} key={item.id}>
                  {item.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='tokenCategoryId'
                  label='Token Category'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {tokenCategoryDropdown.isLoading && (
                          <CircularProgress color='inherit' size={20} />
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
              fullWidth
            />
          </Grid>
          <Grid item xs={8}>
            <Autocomplete
              options={campaignTypeDropdown.data || []}
              getOptionLabel={(list) => list.name}
              isOptionEqualToValue={(option, value) =>
                option && value ? option.id == value.id : false
              }
              onOpen={() => getCampaignTypeDropdown()}
              onChange={(_, id) => {
                if (id) {
                  setFilter({
                    ...filter,
                    campaignType: id && String(id?.id)
                  })
                }
              }}
              value={
                campaignTypeDropdown &&
                campaignTypeDropdown.data &&
                campaignTypeDropdown.data.find((e) => e.id == Number(filter.campaignType))
              }
              ListboxProps={GlobalStyle.ListBox}
              renderOption={(props, item) => (
                <li {...props} key={item.id}>
                  {item.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='campaignType'
                  label='Campaign Type'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {campaignTypeDropdown.isLoading && (
                          <CircularProgress color='inherit' size={20} />
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
              fullWidth
            />
          </Grid>
          <Grid item xs={8}>
            <Autocomplete
              options={campaignDropdown.data || []}
              getOptionLabel={(list) => list.name}
              isOptionEqualToValue={(option, value) =>
                option && value ? option.id == value.id : false
              }
              onOpen={() => getCampaignDropdown()}
              onChange={(_, id) => {
                if (id) {
                  setFilter({
                    ...filter,
                    campaignName: id && String(id?.id)
                  })
                }
              }}
              value={
                campaignDropdown &&
                campaignDropdown.data &&
                campaignDropdown.data.find((e) => e.id == Number(filter.campaignName))
              }
              ListboxProps={GlobalStyle.ListBox}
              renderOption={(props, item) => (
                <li {...props} key={item.id}>
                  {item.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='campaignName'
                  label='Campaign Name'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {campaignDropdown.isLoading && (
                          <CircularProgress color='inherit' size={20} />
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
              fullWidth
            />
          </Grid>
          <Grid item xs={8}>
            <Autocomplete
              options={dataBoolean || []}
              getOptionLabel={(list) => list.name}
              isOptionEqualToValue={(option, value) =>
                option && value ? option.value == value.value : false
              }
              onChange={(_, id) => {
                if (id) {
                  setFilter({
                    ...filter,
                    purchasedFlag: id?.value
                  })
                }
              }}
              ListboxProps={GlobalStyle.ListBox}
              value={dataBoolean && dataBoolean.find((e) => e.value == filter.purchasedFlag)}
              renderOption={(props, item) => (
                <li {...props} key={item.value}>
                  {item.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='status'
                  label='Purchased (Yes/No)'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{params.InputProps.endAdornment}</>
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={8}>
            <Autocomplete
              options={dataBoolean || []}
              getOptionLabel={(list) => list.name}
              isOptionEqualToValue={(option, value) =>
                option && value ? option.value == value.value : false
              }
              onChange={(_, id) => {
                if (id) {
                  setFilter({
                    ...filter,
                    revokedFlag: id?.value
                  })
                }
              }}
              ListboxProps={GlobalStyle.ListBox}
              value={dataBoolean && dataBoolean.find((e) => e.value == filter.revokedFlag)}
              renderOption={(props, item) => (
                <li {...props} key={item.value}>
                  {item.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='status'
                  label='Revoked (Yes/No)'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{params.InputProps.endAdornment}</>
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={8}>
            <Autocomplete
              options={dataBoolean || []}
              getOptionLabel={(list) => list.name}
              isOptionEqualToValue={(option, value) =>
                option && value ? option.value == value.value : false
              }
              onChange={(_, id) => {
                if (id) {
                  setFilter({
                    ...filter,
                    claimedFlag: id?.value
                  })
                }
              }}
              ListboxProps={GlobalStyle.ListBox}
              value={dataBoolean && dataBoolean.find((e) => e.value == filter.claimedFlag)}
              renderOption={(props, item) => (
                <li {...props} key={item.value}>
                  {item.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='status'
                  label='Claimed (Yes/No)'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{params.InputProps.endAdornment}</>
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={8}>
            <TextField
              id='purchasedBy'
              variant='outlined'
              label='Purchased By'
              type='text'
              value={filter.purchasedBy}
              onChange={(event) => setFilter({ ...filter, purchasedBy: event.target.value })}
              fullWidth
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              id='claimedBy'
              variant='outlined'
              label='Claimed By'
              type='text'
              value={filter.claimedBy}
              onChange={(event) => setFilter({ ...filter, claimedBy: event.target.value })}
              fullWidth
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              id='remarks'
              variant='outlined'
              label='Remarks'
              type='text'
              value={filter.remarks}
              onChange={(event) => setFilter({ ...filter, remarks: event.target.value })}
              fullWidth
            />
          </Grid>
        </Grid>
      </Filter>

      {dialogUpdate && (
        <Edit
          id={listChecked as never}
          open={dialogUpdate}
          onClose={() => setDialogUpdate(false)}
        />
      )}

      {dialogRevoke && (
        <RevokeToken
          id={listChecked as never}
          open={dialogRevoke}
          onClose={() => setDialogRevoke(false)}
        />
      )}

      {dialogMove && (
        <MoveToken
          id={listChecked as never}
          open={dialogMove}
          onClose={() => setDialogMove(false)}
        />
      )}

      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />
    </>
  )
}

export default TokenImportSub
