import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import {
  useDeleteChargingListMutation,
  useDownloadChargingListMutation,
  useGetChargingListMutation
} from 'store/evChargingUrl'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { ErrorProps } from 'modules/types'
import type { PartialEvChargingUrlFilterProps, PartialFormDataCapsProps } from 'modules/partial'
import CONSTANT from 'modules/constant'
import Loading from 'components/loading/loading'
import GlobalStyle from 'modules/styles'
import MenuModalItem from 'components/menuItem/menuItem'
import Icon from 'components/icon'
import Detail from './detail/detail'
import Create from './create/create'
import Edit from './edit/edit'

const EVCharging = () => {
  const [getChargingList, charging] = useGetChargingListMutation()
  const [downloadChargingList, download] = useDownloadChargingListMutation()
  const [deleteChargingList, deleteCharging] = useDeleteChargingListMutation()

  const [filter, setFilter] = useState<PartialEvChargingUrlFilterProps>({
    Code: '',
    MinCreatedAt: '',
    MaxCreatedAt: ''
  })

  const [payload, setPayload] = useState<
    PartialEvChargingUrlFilterProps & PartialFormDataCapsProps
  >({
    Start: 0,
    Length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    Search: { value: '' },
    Code: '',
    MinCreatedAt: '',
    MaxCreatedAt: ''
  })

  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [menu, setMenu] = useState<null | number>(null)
  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [dialogCreate, setDialogCreate] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [drawerUpdate, setDrawerUpdate] = useState<boolean>(false)

  const onSearch = (value: string) => setPayload({ ...payload, Start: 0, Search: { value } })
  const onChangePage = (value: number) => setPayload({ ...payload, Start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, Length: value })

  const onResync = () => {
    const formData = new FormData()

    formData.append('Start', payload.Start.toString())
    formData.append('Length', payload.Length.toString())

    if (!isEmpty(payload.Search)) formData.append('Search', payload.Search.value)
    if (!isEmpty(payload.Code)) formData.append('Code', payload.Code)
    if (!isEmpty(payload.MinCreatedAt)) formData.append('MinCreatedAt', payload.MinCreatedAt)
    if (!isEmpty(payload.MaxCreatedAt)) formData.append('MaxCreatedAt', payload.MaxCreatedAt)

    getChargingList(formData)
  }

  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('Start', payload.Start.toString())
    formData.append('Length', payload.Length.toString())

    if (!isEmpty(payload.Search)) formData.append('Search', payload.Search.value)
    if (!isEmpty(payload.Code)) formData.append('Code', payload.Code)
    if (!isEmpty(payload.MinCreatedAt)) formData.append('MinCreatedAt', payload.MinCreatedAt)
    if (!isEmpty(payload.MaxCreatedAt)) formData.append('MaxCreatedAt', payload.MaxCreatedAt)
    if (type && !isEmpty(type)) formData.append('Content-Type', type)

    downloadChargingList(formData)
  }

  const onFilter = (state: boolean) => setDialogFilter(state)
  const onCreate = () => setDialogCreate(true)

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const tableHeadTitles = ['QR Value', 'Notes', 'Charge Box ID', 'Created At']

  const openAnchor = Boolean(anchor)

  const totalPage = charging.data && GLOBAL.tableTotalPage(charging.data.recordsTotal)

  const numbers = GLOBAL.tableNumber(payload.Start)

  useEffect(() => {
    onResync()
  }, [payload])

  return (
    <>
      <Content
        title='EV Charging URL'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        onAdditional={onCreate}
        additionalTitle='Create New EV Charging URL'
        additionalPrefixIcon='Create'
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={charging.data && charging.data.recordsTotal}
        page={payload.Start + 1}
        isLoading={charging.isLoading}
        useDownloadDropdown
      >
        {charging.isSuccess &&
          charging.data &&
          charging.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.id}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.id}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.id}`}
                  aria-labelledby={`button-${index}-${table.id}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View
                  </MenuItem>
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerUpdate(true))}>
                    Edit
                  </MenuItem>
                  <MenuModalItem
                    title='Delete'
                    loading={deleteCharging.isLoading}
                    onClick={() => onRemoveAnchor(() => deleteChargingList(table.id))}
                  >
                    <Typography {...GlobalStyle.ErrorTypography}>Delete</Typography>
                  </MenuModalItem>
                </Menu>
                <Detail
                  id={table.id}
                  open={drawerDetail && menu == index}
                  onClose={() => setDrawerDetail(false)}
                />
                <Edit
                  id={table.id}
                  open={drawerUpdate && menu == index}
                  onClose={() => setDrawerUpdate(false)}
                />
              </TableCell>
              <TableCell>{table.code || '-'}</TableCell>
              <TableCell>{table.remark || '-'}</TableCell>
              <TableCell>{table.url || '-'}</TableCell>
              <TableCell>{table.createdAt || '-'}</TableCell>
            </TableRow>
          ))}
      </Content>

      {charging.isLoading && <Loading />}

      <Create open={dialogCreate} onClose={() => setDialogCreate(false)} />

      <Filter
        open={dialogFilter}
        title='Filter'
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
        onReset={() => {
          setFilter({ Code: '', MinCreatedAt: '', MaxCreatedAt: '' })
          setPayload({
            Start: 0,
            Length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
            Search: { value: '' },
            Code: '',
            MinCreatedAt: '',
            MaxCreatedAt: ''
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({ ...payload, ...filter })
          onFilter(false)
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
          <DatePicker
            value={filter.MinCreatedAt ? dayjs(filter.MinCreatedAt) : null}
            onChange={(event: Dayjs | null) =>
              setFilter({ ...filter, MinCreatedAt: (event && event.format('YYYY-MM-DD')) || '' })
            }
            label='Min Created Date'
            format='DD-MM-YYYY'
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
          <DatePicker
            value={filter.MaxCreatedAt ? dayjs(filter.MaxCreatedAt) : null}
            minDate={dayjs(filter.MinCreatedAt)}
            onChange={(event: Dayjs | null) =>
              setFilter({ ...filter, MaxCreatedAt: (event && event.format('YYYY-MM-DD')) || '' })
            }
            label='Max Created Date'
            format='DD-MM-YYYY'
          />
        </LocalizationProvider>
        <TextField
          id='Code'
          variant='outlined'
          label='Code'
          type='text'
          value={filter.Code}
          onChange={(event) => setFilter({ ...filter, Code: event.target.value })}
          fullWidth
        />
      </Filter>

      <Notification
        open={!charging.isLoading && !charging.isUninitialized && !charging.isSuccess}
        onClose={() => (charging.isError ? charging.reset() : location.reload())}
        isError={Boolean(charging.error) && charging.isError}
        message={GLOBAL.returnExceptionMessage(charging.isError, charging.error as ErrorProps)}
      />

      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />

      <Notification
        open={!deleteCharging.isLoading && !deleteCharging.isUninitialized}
        onClose={() => (deleteCharging.isError ? deleteCharging.reset() : location.reload())}
        isError={Boolean(deleteCharging.error) && deleteCharging.isError}
        message={GLOBAL.returnExceptionMessage(
          deleteCharging.isError,
          deleteCharging.error as ErrorProps
        )}
      />
    </>
  )
}

export default EVCharging
