/* eslint-disable @typescript-eslint/no-explicit-any */

import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { Container, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import Dialog from 'components/dialog/dialog'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import type { PartialUpdateAccountStatusProps } from 'modules/partial'
import { useUpdateAccountStatusMutation } from 'store/carInformation'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import DialogStyle from './updateAccountStatusStyle'

const UpdateAccountStatus = ({
  open,
  id,
  vin,
  vehicleId,
  accountStatus,
  onClose
}: DetailStateProps & {
  id: string
  vin: string
  vehicleId: number
  accountStatus: string
}) => {
  if (!open) return <></>

  const [updateAccountStatus, update] = useUpdateAccountStatusMutation()
  const scheme = yup.object<PartialUpdateAccountStatusProps>({
    Status: yup.string().required('Account Status is required')
  })

  const formik = useFormik<PartialUpdateAccountStatusProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      RegistrationCode: id,
      Vin: vin,
      VehicleId: vehicleId,
      Status: accountStatus
    },
    onSubmit: (values: PartialUpdateAccountStatusProps) => handleSubmit(values)
  })

  const handleSubmit = (e: PartialUpdateAccountStatusProps) => {
    const payload: any = {
      RegistrationCode: id,
      Vin: vin,
      VehicleId: vehicleId,
      Status: e.Status
    }

    updateAccountStatus(payload)
  }

  return (
    <>
      <Dialog
        open={open}
        title='Update Account Status'
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={false}
        isDisabled={!formik.isValid}
        submitText='Update'
        cancelText='Cancel'
      >
        <Container {...DialogStyle.Container}>
          {id && (
            <>
              <FormikProvider value={formik}>
                <Typography sx={{ textAlign: 'center' }}>
                  Do you want to Update Account Status?
                </Typography>
                <FormControl fullWidth>
                  <InputLabel id='Status'>Account Status</InputLabel>
                  <Select
                    labelId='Status'
                    id='Status'
                    value={formik.values.Status}
                    label='Account Status'
                    onChange={(event) => formik.setFieldValue('Status', event.target.value)}
                  >
                    <MenuItem value='Enabled'>Enabled</MenuItem>
                    <MenuItem value='Disabled'>Disabled</MenuItem>
                  </Select>
                </FormControl>
              </FormikProvider>
            </>
          )}
        </Container>
      </Dialog>

      <Notification
        open={!update.isLoading && !update.isUninitialized}
        onClose={() => (update.isError ? update.reset() : location.reload())}
        isError={update.isError}
        message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
      />
    </>
  )
}

export default UpdateAccountStatus
