import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { Chip, IconButton, Menu, MenuItem, TableCell, TableRow, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import type { Dayjs } from 'dayjs'
import { useNavigate } from 'react-router-dom'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import {
  useDeleteVoucherCategoryMutation,
  useDownloadVoucherCategoryMutation,
  useEnableVoucherCategoryMutation,
  useGetListVoucherCategoryMutation
} from 'store/voucherCategory'
import CONSTANT from 'modules/constant'
import type { PartialVoucherCategoryProps } from 'modules/partial'
import Icon from 'components/icon'
import MenuModalItem from 'components/menuItem/menuItem'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { ErrorProps } from 'modules/types'
import GlobalStyle from 'modules/styles'
import IconMoon from 'components/iconmoon'
import Create from './create/create'
import Detail from './detail/detail'
import Edit from './edit/edit'

const VoucherCategory = () => {
  const navigate = useNavigate()
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [dialogCreate, setDialogCreate] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [dialogUpdate, setDialogUpdate] = useState<boolean>(false)
  const [menu, setMenu] = useState<null | number>(null)
  const [getListVoucherCategory, voucherCategory] = useGetListVoucherCategoryMutation()
  const [downloadVoucherCategory, download] = useDownloadVoucherCategoryMutation()
  const [deleteVoucherCategory, deleteCategory] = useDeleteVoucherCategoryMutation()
  const [enableVoucherCategory, enableCategory] = useEnableVoucherCategoryMutation()
  const [startDate, setStartDate] = useState<Dayjs | null>(null)
  const [endDate, setEndDate] = useState<Dayjs | null>(null)

  const [payload, setPayload] = useState<PartialVoucherCategoryProps>({
    start: 0,
    length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    field: CONSTANT.DEFAULT_VOUCHER_CATEGORY_FIELD.join(','),
    search: { value: '' },
    status: ''
  })

  const [filter, setFilter] = useState({
    MinStartTime: '',
    MaxStartTime: ''
  })

  const onSearch = (value: string) => setPayload({ ...payload, start: 0, search: { value } })
  const onResync = () => {
    const formData = new FormData()
    formData.append('start', payload.start.toString())
    formData.append('length', payload.length.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(payload.status)) formData.append('status', payload.status)
    if (!isEmpty(filter.MinStartTime)) formData.append('min-created-date', filter.MinStartTime)
    if (!isEmpty(filter.MaxStartTime)) formData.append('max-created-date', filter.MaxStartTime)

    getListVoucherCategory(formData)
  }
  const onFilter = (state: boolean) => setDialogFilter(state)
  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('start', String(0))
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(payload.status)) formData.append('status', payload.status)
    if (!isEmpty(filter.MinStartTime)) formData.append('min-created-date', filter.MinStartTime)
    if (!isEmpty(filter.MaxStartTime)) formData.append('max-created-date', filter.MaxStartTime)
    if (type && !isEmpty(type)) formData.append('Download-Type', type)

    downloadVoucherCategory(formData)
  }
  const onCreate = () => setDialogCreate(true)

  const onChangePage = (value: number) => setPayload({ ...payload, start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, length: value })

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const getNameIcon = (str: string) => {
    if (typeof str != 'undefined' && str) {
      const piece = str?.split(/[\s/]+/)
      return piece[piece.length - 1]
    }
    return ''
  }

  const tableHeadTitles = [
    'Voucher Category',
    'Description',
    'Created Date',
    'Sequence',
    'Voucher Icon',
    'Voucher Image',
    'Status'
  ]

  const openAnchor = Boolean(anchor)

  const totalPage = voucherCategory.data && GLOBAL.tableTotalPage(voucherCategory.data.recordsTotal)

  const numbers = GLOBAL.tableNumber(payload.start)

  useEffect(() => {
    onResync()
  }, [payload])

  const handleChangeStartDate = (date: Dayjs | null) => {
    setStartDate(date)
    const newDate = (!isEmpty(date) && date?.format('YYYY-MM-DD')) || ''
    setFilter({ ...filter, MinStartTime: newDate, MaxStartTime: '' })
    setEndDate(null)
  }

  const handleChangeEndDate = (date: Dayjs | null) => {
    setEndDate(date)
    const newDate = (!isEmpty(date) && date?.format('YYYY-MM-DD')) || ''
    setFilter({ ...filter, MaxStartTime: newDate })
  }

  return (
    <>
      <Content
        title='Voucher Category'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        onAdditional={onCreate}
        additionalTitle='Create New Voucher Category'
        additionalPrefixIcon='Create'
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={voucherCategory.data && voucherCategory.data.recordsTotal}
        page={payload.start + 1}
        isLoading={voucherCategory.isLoading}
        useDownloadDropdown
      >
        {voucherCategory.isSuccess &&
          voucherCategory.data &&
          voucherCategory.data.data.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${item.id}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${item.id}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${item.id}`}
                  aria-labelledby={`button-${index}-${item.id}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem
                    onClick={() =>
                      onRemoveAnchor(() =>
                        navigate(item.nameEn, { state: { id: item.id, name: item.nameEn } })
                      )
                    }
                  >
                    Open
                  </MenuItem>
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View Detail
                  </MenuItem>
                  <MenuItem onClick={() => onRemoveAnchor(() => setDialogUpdate(true))}>
                    Edit
                  </MenuItem>
                  <MenuModalItem
                    title={item.status == 'Enabled' ? 'Disable' : 'Enable'}
                    onClick={() =>
                      onRemoveAnchor(() =>
                        enableVoucherCategory({
                          Id: item.id,
                          Status: item.status == 'Enabled' ? 'Disabled' : 'Enabled'
                        })
                      )
                    }
                  >
                    {item.status == 'Enabled' ? 'Disable' : 'Enable'}
                  </MenuModalItem>
                  <MenuModalItem
                    title='Delete'
                    loading={deleteCategory.isLoading}
                    onClick={() => onRemoveAnchor(() => deleteVoucherCategory({ Id: item.id }))}
                  >
                    <Typography {...GlobalStyle.ErrorTypography}>Delete</Typography>
                  </MenuModalItem>
                </Menu>
                {drawerDetail && menu == index && (
                  <Detail
                    id={item.id}
                    open={drawerDetail && menu == index}
                    onClose={() => setDrawerDetail(false)}
                  />
                )}
                {dialogUpdate && menu == index && (
                  <Edit
                    id={item.id}
                    open={dialogUpdate && menu == index}
                    onClose={() => setDialogUpdate(false)}
                  />
                )}
              </TableCell>
              <TableCell>{item.nameEn || '-'}</TableCell>
              <TableCell>{item.description || '-'}</TableCell>
              <TableCell>{dayjs(item.createdAt).format('DD MMMM YYYY HH:mm') || '-'}</TableCell>
              <TableCell>{item.seq || '-'}</TableCell>
              <TableCell style={{ textAlign: 'center' }}>
                <IconMoon icon={item.icon} size={25} />
              </TableCell>
              <TableCell style={{ textAlign: 'center' }}>
                {getNameIcon(item.image)}
                <br />
                {getNameIcon(item.image) ? <a href={item.image}>Download</a> : ''}
              </TableCell>
              <TableCell>
                <Chip label={item.status} color={item.status == 'Enabled' ? 'success' : 'error'} />
              </TableCell>
            </TableRow>
          ))}
      </Content>
      {voucherCategory.isLoading && <Loading />}

      <Filter
        open={dialogFilter}
        title='Filter'
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
        onReset={() => {
          setFilter({
            MinStartTime: '',
            MaxStartTime: ''
          })
          setStartDate(null)
          setEndDate(null)
          setPayload({ ...payload, start: 0, ...filter })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({ ...payload, start: 0, ...filter })
          onFilter(false)
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
          <DatePicker
            value={startDate}
            onChange={handleChangeStartDate}
            label='Start Date'
            format='DD-MM-YYYY'
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
          <DatePicker
            value={endDate}
            minDate={dayjs(startDate)}
            onChange={handleChangeEndDate}
            label='End Date'
            format='DD-MM-YYYY'
          />
        </LocalizationProvider>
      </Filter>
      {dialogCreate && <Create open={dialogCreate} onClose={() => setDialogCreate(false)} />}

      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />

      <Notification
        open={!deleteCategory.isLoading && !deleteCategory.isUninitialized}
        onClose={() => (deleteCategory.isError ? deleteCategory.reset() : location.reload())}
        isError={Boolean(deleteCategory.error) && deleteCategory.isError}
        message={GLOBAL.returnExceptionMessage(
          deleteCategory.isError,
          deleteCategory.error as ErrorProps
        )}
      />
      <Notification
        open={!enableCategory.isLoading && !enableCategory.isUninitialized}
        onClose={() => (enableCategory.isError ? enableCategory.reset() : location.reload())}
        isError={Boolean(enableCategory.error) && enableCategory.isError}
        message={GLOBAL.returnExceptionMessage(
          enableCategory.isError,
          enableCategory.error as ErrorProps
        )}
      />
    </>
  )
}

export default VoucherCategory
