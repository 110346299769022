import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Autocomplete,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  TextField
} from '@mui/material'
import moment from 'moment'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import {
  useDownloadTopupListMutation,
  useGetTopupListMutation,
  useGetTopupStatusDropdownMutation
} from 'store/evTopup'
import type { PartialEvTopupFilterProps, PartialFormDataProps } from 'modules/partial'
import CONSTANT from 'modules/constant'
import GLOBAL from 'modules/global'
import Notification from 'components/notification'
import type { ErrorProps } from 'modules/types'
import Loading from 'components/loading/loading'
import Icon from 'components/icon'
import GlobalStyle from 'modules/styles'
import Detail from './detail/detail'

const EVTopup = () => {
  const [getTopupList, topupList] = useGetTopupListMutation()
  const [downloadTopupList, download] = useDownloadTopupListMutation()
  const [getStatusDropdown, statusDropdown] = useGetTopupStatusDropdownMutation()

  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [menu, setMenu] = useState<null | number>(null)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)

  const [filter, setFilter] = useState<PartialEvTopupFilterProps>({
    status: ''
  })

  const [payload, setPayload] = useState<PartialFormDataProps & PartialEvTopupFilterProps>({
    start: 0,
    length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    field: CONSTANT.DEFAULT_EV_TOPUP_FIELD.join(','),
    search: { value: '' },
    status: ''
  })

  const onSearch = (value: string) => setPayload({ ...payload, start: 0, search: { value } })
  const onChangePage = (value: number) => setPayload({ ...payload, start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, length: value })

  const onFilter = (state: boolean) => setDialogFilter(state)

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const onResync = () => {
    const formData = new FormData()

    formData.append('start', payload.start.toString())
    formData.append('length', payload.length.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.status)) formData.append('status', payload.status)
    if (!isEmpty(payload.search) && !isEmpty(payload.search.value))
      formData.append('search[value]', payload.search.value)

    getTopupList(formData)
  }

  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('start', String(0))
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.status)) formData.append('status', payload.status)
    if (!isEmpty(payload.search) && !isEmpty(payload.search.value))
      formData.append('search[value]', payload.search.value)
    if (type && !isEmpty(type)) formData.append('Download-Type', type)

    downloadTopupList(formData)
  }

  const tableHeadTitles = [
    'Top Up Transaction ID (Hyundai)',
    'Date Created',
    'Payment Method',
    'Top Up Transaction Status',
    'User Member ID',
    'Total Top Up Payment'
  ]

  const openAnchor = Boolean(anchor)
  const totalPage = topupList.data && GLOBAL.tableTotalPage(topupList.data.recordsTotal)
  const numbers = GLOBAL.tableNumber(payload.start)

  useEffect(() => {
    onResync()
  }, [payload])

  return (
    <>
      <Content
        title='Topup Transaction'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={topupList.data && topupList.data.recordsTotal}
        page={payload.start + 1}
        isLoading={topupList.isLoading}
        useDownloadDropdown
      >
        {topupList.isSuccess &&
          topupList.data &&
          topupList.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.id}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.id}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.id}`}
                  aria-labelledby={`button-${index}-${table.id}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View
                  </MenuItem>
                </Menu>
                <Detail
                  id={table.key}
                  open={drawerDetail && menu == index}
                  onClose={() => setDrawerDetail(false)}
                />
              </TableCell>
              <TableCell>{table.key || '-'}</TableCell>
              <TableCell>
                {(table.createdAt && moment(table.createdAt).format('DD-MM-YYYY HH:mm')) || '-'}
              </TableCell>
              <TableCell>{table.paymentMethodName || '-'}</TableCell>
              <TableCell>{table.status || '-'}</TableCell>
              <TableCell>{table.memberId || '-'}</TableCell>
              <TableCell>
                {(table.grandTotal && GLOBAL.numberFormat(table.grandTotal)) || '-'}
              </TableCell>
            </TableRow>
          ))}
      </Content>

      {topupList.isLoading && <Loading />}

      <Filter
        open={dialogFilter}
        title='Filter'
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
        onReset={() => {
          setFilter({
            status: ''
          })
          setPayload({
            ...payload,
            start: 0,
            status: ''
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({
            ...payload,
            ...filter,
            start: 0
          })
          onFilter(false)
        }}
      >
        <Autocomplete
          options={(statusDropdown && statusDropdown.data) || []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.id == value.id : false
          }
          onOpen={() => getStatusDropdown()}
          onChange={(_, status) =>
            setFilter({
              ...filter,
              status: (status && status.name) || ''
            })
          }
          value={
            (statusDropdown &&
              statusDropdown.data &&
              statusDropdown.data.find((e) => String(e.id) == String(filter.status))) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.id}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='status'
              label='Status'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {statusDropdown.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
      </Filter>

      <Notification
        open={!topupList.isLoading && !topupList.isUninitialized && !topupList.isSuccess}
        onClose={() => topupList.reset()}
        isError={Boolean(topupList.error) && topupList.isError}
        message={GLOBAL.returnExceptionMessage(topupList.isError, topupList.error as ErrorProps)}
      />

      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />
    </>
  )
}

export default EVTopup
