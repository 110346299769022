import { useFormik } from 'formik'
import * as yup from 'yup'
import {
  Autocomplete,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography
} from '@mui/material'
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import Dialog from 'components/dialog/dialog'
import type { DetailStateProps, ErrorProps, EvChargePointDetailProps } from 'modules/types'
import { useGetChargePointDetailQuery, useUpdateChargePointMutation } from 'store/evChargePoint'
import { useGetPartnerDropdownListByQuery, useGetPartnerDropdownListMutation } from 'store/partner'
import { useGetOwnerDropdownListByQuery, useGetOwnerDropdownListMutation } from 'store/owner'
import { useGetCityDropdownListByQuery, useGetCityDropdownListMutation } from 'store/dropdown'
import { useGetPolicyDropdownListByQuery, useGetPolicyDropdownListMutation } from 'store/policy'
import {
  useGetProviderDropdownListByQuery,
  useGetProviderDropdownListMutation
} from 'store/provider'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import Loading from 'components/loading/loading'
import CONSTANT from 'modules/constant'
import GlobalStyle from 'modules/styles'
import DialogStyle from './style'

const Edit = ({ open, id, onClose }: DetailStateProps & { id: number }) => {
  if (!open) return <></>

  const { data, isFetching, isLoading } = useGetChargePointDetailQuery(id)

  const [getPartnerDropdownList, partnerDropdown] = useGetPartnerDropdownListMutation()
  const [getOwnerDropdownList, ownerDropdown] = useGetOwnerDropdownListMutation()
  const [getCityDropdownList, cityDropdown] = useGetCityDropdownListMutation()
  const [getPolicyDropdownList, policyDropdown] = useGetPolicyDropdownListMutation()
  const [getProviderDropdownList, providerDropdown] = useGetProviderDropdownListMutation()

  const { data: partnerDropdownQuery, isLoading: partnerDropdownQueryLoading } =
    useGetPartnerDropdownListByQuery(CONSTANT.DEFAULT_DROPDOWN_PAYLOAD)

  const { data: ownerDropdownQuery, isLoading: ownerDropdownQueryLoading } =
    useGetOwnerDropdownListByQuery(CONSTANT.DEFAULT_DROPDOWN_PAYLOAD)

  const { data: cityDropdownQuery, isLoading: cityDropdownQueryLoading } =
    useGetCityDropdownListByQuery()

  const { data: policyDropdownQuery, isLoading: policyDropdownQueryLoading } =
    useGetPolicyDropdownListByQuery(CONSTANT.DEFAULT_DROPDOWN_PAYLOAD)

  const { data: providerDropdownQuery, isLoading: providerDropdownQueryLoading } =
    useGetProviderDropdownListByQuery(CONSTANT.DEFAULT_DROPDOWN_PAYLOAD)

  const [updateChargePoint, update] = useUpdateChargePointMutation()

  const selectedProviderName =
    data &&
    !providerDropdownQueryLoading &&
    providerDropdownQuery &&
    providerDropdownQuery.find((e) => e.value == data.providerId)

  const selectedPartnerName =
    data &&
    !partnerDropdownQueryLoading &&
    partnerDropdownQuery &&
    partnerDropdownQuery.find((e) => e.value == data.partnerId)

  const selectedOwnerName =
    data &&
    !ownerDropdownQueryLoading &&
    ownerDropdownQuery &&
    ownerDropdownQuery.find((e) => e.value == data.ownerId)

  const selectedCityName =
    data &&
    !cityDropdownQueryLoading &&
    cityDropdownQuery &&
    cityDropdownQuery.find((e) => e.id == data.cityId)

  const selectedPolicyName =
    data &&
    !policyDropdownQueryLoading &&
    policyDropdownQuery &&
    policyDropdownQuery.find((e) => e.value == data.policyId)

  const scheme = yup.object<EvChargePointDetailProps>({
    code: yup.string(),
    name: yup.string().required('Charge Point Name is required'),
    ownerId: yup.number().positive('Owner Id is required').required('Owner Id is required'),
    ownerName: yup.string(),
    providerId: yup
      .number()
      .positive('Provider Id is required')
      .required('Provider Id is required'),
    providerName: yup.string(),
    partnerId: yup.number().nullable(),
    partnerName: yup.string(),
    policyId: yup.number().positive('Policy Id is required').required('Policy Id is required'),
    policyName: yup.string(),
    kwhLimit: yup.number().positive('kWh Limit is required').required('kWh Limit is required'),
    quota: yup.object({
      quota: yup.number().positive('Quota is required').required('Quota is required'),
      startDate: yup.string(),
      endDate: yup.string(),
      frequency: yup.string(),
      interval: yup.string().required('Interval is required')
    }),
    floors: yup.string().required('Floor is required'),
    place: yup.string().required('Place is required'),
    country: yup.string().required('Country is required'),
    provinceId: yup.number().nullable(),
    cityId: yup.number().positive('City Id is required').required('City is required'),
    city: yup.string(),
    postalCode: yup
      .number()
      .positive('Postal Code is required')
      .required('Postal Code is required'),
    streetAddress: yup.string().required('Street Address is required'),
    type: yup.string().required('Type is required'),
    coordinates: yup.array().of(yup.number()),
    longitude: yup.number().required('Longitude is required'),
    latitude: yup.number().required('Latitude is required'),
    operative: yup.boolean(),
    voucherId: yup.string(),
    requireAuthorization: yup.boolean(),
    operationStartTime: yup.string().required('Operation Start Time is required'),
    operationEndTime: yup.string().required('Operation End Time is required'),
    operationHours: yup.string(),
    operationalHours: yup.string(),
    connectivity: yup.string().nullable(),
    remark: yup.string(),
    imageUrl: yup.string(),
    visibility: yup.string().required('Access is required'),
    payment: yup.string().required('Payment is required'),
    active: yup.boolean().required('Status is required')
  })

  const formik = useFormik<EvChargePointDetailProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      code: (data && data.code) || '',
      name: (data && data.name) || '',
      ownerId: (data && data.ownerId) || (selectedOwnerName && selectedOwnerName.value) || null,
      providerId:
        (data && data.providerId) || (selectedProviderName && selectedProviderName.value) || null,
      partnerId:
        (data && data.partnerId) || (selectedPartnerName && selectedPartnerName.value) || null,
      policyId: (data && data.policyId) || (selectedPolicyName && selectedPolicyName.value) || null,
      kwhLimit: (data && data.kwhLimit) || null,
      quota: {
        quota: (data && data.quota && data.quota.quota) || null,
        startDate: (data && data.quota && data.quota.startDate) || '',
        endDate: (data && data.quota && data.quota.endDate) || '',
        frequency: (data && data.quota && data.quota.frequency) || 'daily',
        interval: (data && data.quota && data.quota.interval) || null
      },
      floors: (data && data.floors) || '',
      place: (data && data.place) || '',
      country: (data && data.country) || '',
      provinceId: (data && data.provinceId) || null,
      cityId: (data && data.cityId) || (selectedCityName && selectedCityName.id) || null,
      city: (data && data.city) || (selectedCityName && selectedCityName.name) || '',
      postalCode: (data && data.postalCode) || null,
      streetAddress: (data && data.streetAddress) || '',
      type: (data && data.type) || '',
      coordinates: (data && [data.longitude, data.latitude]) || [],
      longitude: (data && data.longitude) || null,
      latitude: (data && data.latitude) || null,
      operative: (data && data.operative) || false,
      voucherId: (data && data.voucherId) || '',
      requireAuthorization: (data && data.requireAuthorization) || false,
      operationStartTime: (data && data.operationStartTime) || null,
      operationEndTime: (data && data.operationEndTime) || null,
      operationHours: (data && data.operationHours) || '',
      operationalHours: (data && data.operationHours) || '',
      remark: (data && data.remark) || '',
      imageUrl: (data && data.imageUrl) || '',
      visibility: (data && data.visibility) || '',
      payment: (data && data.payment) || '',
      active: Boolean(data && data.active)
    },
    onSubmit: (values: EvChargePointDetailProps) =>
      updateChargePoint({
        body: {
          ...values,
          quota: { ...values.quota, startDate: new Date().toUTCString() }
        },
        id: (data && data.id) || 0
      })
  })

  const errorData = formik.errors

  const selectedProviderDropdown =
    (!providerDropdownQueryLoading &&
      providerDropdownQuery &&
      providerDropdownQuery.find((e) => e.value == formik.values.providerId)) ||
    null

  const selectedPartnerDropdown =
    (!partnerDropdownQueryLoading &&
      partnerDropdownQuery &&
      partnerDropdownQuery.find((e) => e.value == formik.values.partnerId)) ||
    null

  const selectedOwnerDropdown =
    (!ownerDropdownQueryLoading &&
      ownerDropdownQuery &&
      ownerDropdownQuery.find((e) => e.value == formik.values.ownerId)) ||
    null

  const selectedCityDropdown =
    (!cityDropdownQueryLoading &&
      cityDropdownQuery &&
      cityDropdownQuery.find((e) => e.id == formik.values.cityId)) ||
    null

  const selectedPolicyDropdown =
    (!policyDropdownQueryLoading &&
      policyDropdownQuery &&
      policyDropdownQuery.find((e) => e.value == formik.values.policyId)) ||
    null

  const currentOperationStartTime = dayjs(formik.values.operationStartTime, 'HH:mm')
  const currentOperationEndTime = dayjs(formik.values.operationEndTime, 'HH:mm')

  const loading =
    isLoading ||
    isFetching ||
    partnerDropdownQueryLoading ||
    ownerDropdownQueryLoading ||
    cityDropdownQueryLoading ||
    policyDropdownQueryLoading ||
    providerDropdownQueryLoading

  return (
    <>
      <Dialog
        title='Update EV Charge Point'
        open={open}
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={update.isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          {loading && <Loading />}
          {!loading && (
            <>
              <FormControlLabel
                control={
                  <Switch
                    checked={formik.values.operative}
                    onChange={(e) => formik.setFieldValue('operative', e.target.checked)}
                    name='operative'
                  />
                }
                label='Connectivity'
              />
              <Autocomplete
                options={providerDropdownQuery || (providerDropdown && providerDropdown.data) || []}
                getOptionLabel={(option) => option.text}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.value == value.value : false
                }
                onOpen={() => getProviderDropdownList(CONSTANT.DEFAULT_DROPDOWN_PAYLOAD)}
                onChange={(_, provider) =>
                  formik.setFieldValue('providerId', (provider && provider.value) || null)
                }
                value={selectedProviderDropdown}
                ListboxProps={GlobalStyle.ListBox}
                renderOption={(props, item) => (
                  <li {...props} key={item.value}>
                    {item.text}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='providerId'
                    label='* Provider'
                    error={
                      formik.touched && formik.touched.providerId && Boolean(errorData.providerId)
                    }
                    helperText={
                      formik.touched &&
                      formik.touched.providerId &&
                      errorData &&
                      errorData.providerId
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {providerDropdown.isLoading && (
                            <CircularProgress color='inherit' size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
              />
              <TextField
                id='name'
                variant='outlined'
                label='* Charge Point Name'
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                inputProps={{ autoComplete: 'off' }}
                error={formik.touched && formik.touched.name && Boolean(errorData.name)}
                helperText={formik.touched && formik.touched.name && errorData && errorData.name}
                fullWidth
              />
              <Typography>Quota</Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    id='quota.quota'
                    variant='outlined'
                    label='* Quota'
                    value={formik.values.quota.quota}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched &&
                      formik.touched.quota &&
                      formik.touched.quota.quota &&
                      errorData.quota &&
                      Boolean(errorData.quota.quota)
                    }
                    helperText={
                      formik.touched &&
                      formik.touched.quota &&
                      formik.touched.quota.quota &&
                      errorData &&
                      errorData.quota &&
                      errorData.quota.quota
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id='quota.interval'
                    variant='outlined'
                    label='Days'
                    value={formik.values.quota.interval}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched &&
                      formik.touched.quota &&
                      formik.touched.quota.interval &&
                      errorData.quota &&
                      Boolean(errorData.quota.interval)
                    }
                    helperText={
                      formik.touched &&
                      formik.touched.quota &&
                      formik.touched.quota.interval &&
                      errorData &&
                      errorData.quota &&
                      errorData.quota.interval
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Typography sx={{ fontSize: '.75rem' }}>
                * Quota Field applicable for Policy A only.
              </Typography>
              <Typography>kWh Limit per Charging</Typography>
              <TextField
                id='kwhLimit'
                variant='outlined'
                label='* kWh Limit'
                type='number'
                value={formik.values.kwhLimit && formik.values.kwhLimit.toString()}
                onKeyDown={(event) => {
                  if (/^[.,\-\b]+$/.test(event.key)) event.preventDefault()
                }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                inputProps={{ autoComplete: 'off' }}
                error={
                  formik.touched &&
                  formik.touched.kwhLimit &&
                  errorData &&
                  Boolean(errorData.kwhLimit)
                }
                helperText={
                  formik.touched && formik.touched.kwhLimit && errorData && errorData.kwhLimit
                }
                fullWidth
              />
              <Typography sx={{ fontSize: '.75rem' }}>
                * kWh Limit per Charging Field applicable for Policy A and Policy C only.
              </Typography>
              <Autocomplete
                options={partnerDropdownQuery || (partnerDropdown && partnerDropdown.data) || []}
                getOptionLabel={(option) => option.text}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.value == value.value : false
                }
                onOpen={() => getPartnerDropdownList(CONSTANT.DEFAULT_DROPDOWN_PAYLOAD)}
                onChange={(_, partner) =>
                  formik.setFieldValue('partnerId', (partner && partner.value) || null)
                }
                value={selectedPartnerDropdown}
                ListboxProps={GlobalStyle.ListBox}
                renderOption={(props, item) => (
                  <li {...props} key={item.value}>
                    {item.text}
                  </li>
                )}
                disabled
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='partnerId'
                    label='Partner'
                    error={
                      formik.touched && formik.touched.partnerId && Boolean(errorData.partnerId)
                    }
                    helperText={
                      formik.touched && formik.touched.partnerId && errorData && errorData.partnerId
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {partnerDropdown.isLoading && (
                            <CircularProgress color='inherit' size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
              />
              <Autocomplete
                options={ownerDropdownQuery || (ownerDropdown && ownerDropdown.data) || []}
                getOptionLabel={(option) => option.text}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.value == value.value : false
                }
                onOpen={() => getOwnerDropdownList(CONSTANT.DEFAULT_DROPDOWN_PAYLOAD)}
                onChange={(_, owner) =>
                  formik.setFieldValue('ownerId', (owner && owner.value) || null)
                }
                value={selectedOwnerDropdown}
                ListboxProps={GlobalStyle.ListBox}
                renderOption={(props, item) => (
                  <li {...props} key={item.value}>
                    {item.text}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='ownerId'
                    label='* Owner'
                    error={formik.touched && formik.touched.ownerId && Boolean(errorData.ownerId)}
                    helperText={
                      formik.touched && formik.touched.ownerId && errorData && errorData.ownerId
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {ownerDropdown.isLoading && (
                            <CircularProgress color='inherit' size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
              />
              <Typography>Coordinates</Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    id='latitude'
                    variant='outlined'
                    label='Latitude'
                    value={formik.values.latitude}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched &&
                      formik.touched.latitude &&
                      errorData &&
                      Boolean(errorData.latitude)
                    }
                    helperText={
                      formik.touched && formik.touched.latitude && errorData && errorData.latitude
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id='longitude'
                    variant='outlined'
                    label='Longitude'
                    value={formik.values.longitude}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched &&
                      formik.touched.longitude &&
                      errorData &&
                      Boolean(errorData.longitude)
                    }
                    helperText={
                      formik.touched && formik.touched.longitude && errorData && errorData.longitude
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>
              <TextField
                id='streetAddress'
                variant='outlined'
                label='* Street Address'
                value={formik.values.streetAddress}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched && formik.touched.streetAddress && Boolean(errorData.streetAddress)
                }
                helperText={
                  formik.touched &&
                  formik.touched.streetAddress &&
                  errorData &&
                  errorData.streetAddress
                }
                fullWidth
              />
              <TextField
                id='country'
                variant='outlined'
                label='* Country'
                value={formik.values.country}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched && formik.touched.country && Boolean(errorData.country)}
                helperText={
                  formik.touched && formik.touched.country && errorData && errorData.country
                }
                fullWidth
              />
              <Autocomplete
                options={cityDropdownQuery || (cityDropdown && cityDropdown.data) || []}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.name == value.name : false
                }
                onOpen={() => getCityDropdownList()}
                onChange={(_, region) => {
                  formik.setFieldValue('provinceId', (region && region.provinceId) || null)
                  formik.setFieldValue('cityId', (region && region.id) || null)
                  formik.setFieldValue('city', (region && region.name) || null)
                }}
                value={selectedCityDropdown}
                ListboxProps={GlobalStyle.ListBox}
                renderOption={(props, item) => (
                  <li {...props} key={item.id}>
                    {item.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='cityId'
                    label='* City'
                    error={formik.touched && formik.touched.cityId && Boolean(errorData.cityId)}
                    helperText={
                      formik.touched && formik.touched.cityId && errorData && errorData.cityId
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {cityDropdown.isLoading && <CircularProgress color='inherit' size={20} />}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
              />
              <TextField
                id='postalCode'
                variant='outlined'
                label='* Postal Code'
                type='number'
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                value={formik.values.postalCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched && formik.touched.postalCode && Boolean(errorData.postalCode)}
                helperText={
                  formik.touched && formik.touched.postalCode && errorData && errorData.postalCode
                }
                fullWidth
              />
              <Typography>Operational Hour</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TimePicker
                      ampm={false}
                      value={currentOperationStartTime}
                      onChange={(e) =>
                        formik.setFieldValue('operationStartTime', (e && e.format('HH:mm')) || '')
                      }
                      slotProps={{
                        textField: {
                          sx: { width: '100%' },
                          helperText:
                            formik.touched &&
                            formik.touched.operationStartTime &&
                            errorData &&
                            errorData.operationStartTime
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TimePicker
                      ampm={false}
                      value={currentOperationEndTime}
                      minTime={currentOperationStartTime}
                      onChange={(e) =>
                        formik.setFieldValue('operationEndTime', (e && e.format('HH:mm')) || null)
                      }
                      slotProps={{
                        textField: {
                          sx: { width: '100%' },
                          helperText:
                            formik.touched &&
                            formik.touched.operationEndTime &&
                            errorData &&
                            errorData.operationEndTime
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </LocalizationProvider>
              <TextField
                id='place'
                variant='outlined'
                label='* Place'
                value={formik.values.place}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched && formik.touched.place && Boolean(errorData.place)}
                helperText={formik.touched && formik.touched.place && errorData && errorData.place}
                fullWidth
              />
              <TextField
                id='floors'
                variant='outlined'
                label='* Floors'
                value={formik.values.floors}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched && formik.touched.floors && Boolean(errorData.floors)}
                helperText={
                  formik.touched && formik.touched.floors && errorData && errorData.floors
                }
                fullWidth
              />
              <Autocomplete
                options={policyDropdownQuery || (policyDropdown && policyDropdown.data) || []}
                getOptionLabel={(option) => option.text}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.value == value.value : false
                }
                onOpen={() => getPolicyDropdownList(CONSTANT.DEFAULT_DROPDOWN_PAYLOAD)}
                onChange={(_, policy) =>
                  formik.setFieldValue('policyId', (policy && policy.value) || null)
                }
                value={selectedPolicyDropdown}
                ListboxProps={GlobalStyle.ListBox}
                renderOption={(props, item) => (
                  <li {...props} key={item.value}>
                    {item.text}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='policyId'
                    label='* Policy'
                    error={formik.touched && formik.touched.policyId && Boolean(errorData.policyId)}
                    helperText={
                      formik.touched && formik.touched.policyId && errorData && errorData.policyId
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {policyDropdown.isLoading && (
                            <CircularProgress color='inherit' size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
              />
              <TextField
                id='voucherId'
                variant='outlined'
                label='Voucher ID'
                value={formik.values.voucherId}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched && formik.touched.voucherId && Boolean(errorData.voucherId)}
                helperText={
                  formik.touched && formik.touched.voucherId && errorData && errorData.voucherId
                }
                fullWidth
              />
              <FormControl fullWidth>
                <InputLabel id='visibility'>* Access</InputLabel>
                <Select
                  labelId='visibility'
                  id='visibility'
                  value={formik.values.visibility}
                  label='* Access'
                  onChange={(event) => formik.setFieldValue('visibility', event.target.value)}
                  error={
                    formik.touched && formik.touched.visibility && Boolean(errorData.visibility)
                  }
                >
                  <MenuItem value='Private'>Private</MenuItem>
                  <MenuItem value='Public'>Public</MenuItem>
                </Select>
                {formik.touched && formik.touched.visibility && errorData && (
                  <FormHelperText error id='visibility'>
                    {errorData.visibility}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id='payment'>* Payment</InputLabel>
                <Select
                  labelId='payment'
                  id='payment'
                  value={formik.values.payment}
                  label='* Payment'
                  onChange={(event) => formik.setFieldValue('payment', event.target.value)}
                  error={formik.touched && formik.touched.payment && Boolean(errorData.payment)}
                >
                  <MenuItem value='Free'>Free</MenuItem>
                  <MenuItem value='Paid'>Paid</MenuItem>
                </Select>
                {formik.touched && formik.touched.payment && errorData && (
                  <FormHelperText error id='payment'>
                    {errorData.payment}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id='active'>* Status</InputLabel>
                <Select
                  labelId='Status'
                  id='active'
                  value={formik.values.active}
                  label='* Status'
                  onChange={(event) => formik.setFieldValue('active', event.target.value == 'true')}
                  error={formik.touched && formik.touched.active && Boolean(errorData.active)}
                >
                  <MenuItem value='true'>Enable</MenuItem>
                  <MenuItem value='false'>Disable</MenuItem>
                </Select>
                {formik.touched && formik.touched.active && errorData && (
                  <FormHelperText error id='active'>
                    {errorData.active}
                  </FormHelperText>
                )}
              </FormControl>
            </>
          )}
        </Container>
      </Dialog>

      <Notification
        open={!update.isLoading && !update.isUninitialized}
        onClose={() => (update.isError ? update.reset() : location.reload())}
        isError={Boolean(update.error) && update.isError}
        message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
      />
    </>
  )
}

export default Edit
