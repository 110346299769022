import CONSTANT from 'modules/constant'
import type { PartialDropdownListProps } from 'modules/partial'
import type { DropdownListProps } from 'modules/types'
import { API, headers } from './api'

const PartnerAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getPartnerDropdownList: builder.mutation<DropdownListProps[], PartialDropdownListProps>({
      query: (query) => ({
        url: CONSTANT.URL_PARTNER_DROPDOWN + `?start=${query.start}&length=${query.length}`,
        method: 'GET',
        headers: headers()
      })
    }),
    getPartnerDropdownListBy: builder.query<DropdownListProps[], PartialDropdownListProps>({
      query: (query) => ({
        url: CONSTANT.URL_PARTNER_DROPDOWN + `?start=${query.start}&length=${query.length}`,
        method: 'GET',
        headers: headers()
      })
    })
  })
})

export const { useGetPartnerDropdownListMutation, useGetPartnerDropdownListByQuery } = PartnerAPI
