import { FormikProvider, useFormik } from 'formik'
import { Checkbox, Container, FormControlLabel, TextField, Typography } from '@mui/material'
import * as yup from 'yup'
import Dialog from 'components/dialog/dialog'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { ChargerPayloadTempProps, DetailStateProps, ErrorProps } from 'modules/types'
import { useGetPolicyDetailQuery, useUpdatePolicyMutation } from 'store/catalogPolicy'
import type { PartialCreatePolicyChargerProps } from 'modules/partial'
import DialogStyle from './style'

const Edit = ({ open, id, onClose }: DetailStateProps & { id: number }) => {
  if (!open) return <></>

  const { data, isFetching, isLoading } = useGetPolicyDetailQuery(id)
  const [updatePolicy, update] = useUpdatePolicyMutation()

  const scheme = yup.object<ChargerPayloadTempProps>({
    policyName: yup.string().required('Policy Name is required'),
    // isQuota: yup.string().required('Qouta is required'),
    // isVoucher: yup.string().required('Voucher is required'),
    // isRequestByPartner: yup.string().required('Request by Partner is required'),
    // isKwhLimit: yup.string().required('Kwh Limit is required'),
    remark: yup.string().required('remarks is required')
  })

  const formik = useFormik<ChargerPayloadTempProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      policyName: (data && data.policyName) || '-',
      isQuota: (data && data.isQuota) || false,
      isToken: (data && data.isToken) || false,
      isRequestByPartner: (data && data.isRequestByPartner) || false,
      isKwhLimit: (data && data.isKwhLimit) || false,
      remark: (data && data.remark) || ''
    },
    onSubmit: (values: ChargerPayloadTempProps) => handleSubmit(values)
  })

  const handleSubmit = (e: ChargerPayloadTempProps) => {
    const payload: PartialCreatePolicyChargerProps = {
      policyName: e.policyName,
      isQuota: e.isQuota,
      isToken: e.isToken,
      isRequestByPartner: e.isRequestByPartner,
      isKwhLimit: e.isKwhLimit,
      remark: e.remark || null,
      active: true
    }
    updatePolicy({ body: payload, id })
  }

  return (
    <>
      <Dialog
        open={open}
        title='Update Policy Charger'
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          {(isLoading || isFetching) && !data && <Loading />}
          {data && (
            <>
              <FormikProvider value={formik}>
                <FormikProvider value={formik}>
                  <TextField
                    id='policyName'
                    variant='outlined'
                    label='Policy Name'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.policyName}
                    error={
                      formik.touched &&
                      formik.touched.policyName &&
                      Boolean(formik.errors.policyName)
                    }
                    helperText={
                      formik.touched &&
                      formik.touched.policyName &&
                      formik.errors &&
                      formik.errors.policyName
                    }
                    fullWidth
                  />
                  <Typography>Condition</Typography>

                  <FormControlLabel
                    required
                    control={
                      <Checkbox
                        id='isQuota'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={true}
                        defaultChecked={data.isQuota}
                      />
                    }
                    label='Quota'
                  />
                  <FormControlLabel
                    required
                    control={
                      <Checkbox
                        id='isToken'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={true}
                        defaultChecked={data.isToken}
                      />
                    }
                    label='Pay As You Go'
                  />
                  <FormControlLabel
                    required
                    control={
                      <Checkbox
                        id='isRequestByPartner'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={true}
                        defaultChecked={data.isRequestByPartner}
                      />
                    }
                    label='Request/Authorization by Partner'
                  />
                  <FormControlLabel
                    required
                    control={
                      <Checkbox
                        id='isKwhLimit'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={true}
                        defaultChecked={data.isKwhLimit}
                      />
                    }
                    label='Kwh Limit'
                  />
                  <TextField
                    id='remark'
                    variant='outlined'
                    label='Charger Policy Description'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.remark}
                    error={formik.touched && formik.touched.remark && Boolean(formik.errors.remark)}
                    helperText={
                      formik.touched &&
                      formik.touched.remark &&
                      formik.errors &&
                      formik.errors.remark
                    }
                    fullWidth
                  />
                </FormikProvider>
              </FormikProvider>
            </>
          )}
        </Container>
      </Dialog>

      <Notification
        open={!update.isLoading && !update.isUninitialized}
        onClose={() => (update.isError ? update.reset() : location.reload())}
        isError={Boolean(update.error) && update.isError}
        message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
      />
    </>
  )
}

export default Edit
