import { useEffect } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Autocomplete, CircularProgress, Container, TextField } from '@mui/material'
import Dialog from 'components/dialog/dialog'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import {
  useUpdateRolesListMutation,
  useGetRolesListDetailQuery,
  useGetRolesListTypeDropdownMutation
} from 'store/roles'
import type { PartialRolesEditProps } from 'modules/partial'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import GlobalStyle from 'modules/styles'
import DialogStyle from './style'

const Edit = ({ open, id, onClose }: DetailStateProps & { id: number }) => {
  if (!open) <></>

  const [getRolesListTypesDropdown, rolesTypeDropdown] = useGetRolesListTypeDropdownMutation()
  const [updateRolesList, update] = useUpdateRolesListMutation()

  const { data, isFetching, isLoading } = useGetRolesListDetailQuery(id)

  const onSubmit = (values: PartialRolesEditProps) => updateRolesList(values)

  const selectedRole =
    rolesTypeDropdown &&
    rolesTypeDropdown.data &&
    rolesTypeDropdown.data.find((el) => el.id == (data && data.roleTypeId))

  const scheme = yup.object<PartialRolesEditProps>({
    Name: yup.string().required('Name is required'),
    Description: yup.string().required('Description is required'),
    RoleTypeId: yup.number().min(1, 'RoleType is required').required('RoleType is required')
  })

  const formik = useFormik<PartialRolesEditProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      Id: (data && data.id) || 0,
      Name: (data && data.name) || '',
      Description: (data && data.description) || '',
      RoleTypeId: (data && data.roleTypeId) || 0,
      Status: data && data.status
    },
    onSubmit: onSubmit
  })

  useEffect(() => {
    getRolesListTypesDropdown()
  }, [])

  return (
    <>
      <Dialog
        title='Create New Role'
        open={open}
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          {(isLoading || isFetching) && !data && <Loading />}
          {data && (
            <>
              <TextField
                id='Name'
                variant='outlined'
                label='Name'
                value={formik.values.Name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched && formik.touched.Name && Boolean(formik.errors.Name)}
                helperText={
                  formik.touched && formik.touched.Name && formik.errors && formik.errors.Name
                }
                fullWidth
              />
              <TextField
                id='Description'
                variant='outlined'
                label='Description'
                value={formik.values.Description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched && formik.touched.Description && Boolean(formik.errors.Description)
                }
                helperText={
                  formik.touched &&
                  formik.touched.Description &&
                  formik.errors &&
                  formik.errors.Description
                }
                fullWidth
              />
              <Autocomplete
                options={rolesTypeDropdown.data || []}
                getOptionLabel={(list) => list.name}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.id == value.id : false
                }
                onOpen={() => getRolesListTypesDropdown()}
                value={selectedRole}
                onChange={(_, id) => formik.setFieldValue('RoleTypeId', id && id.id)}
                ListboxProps={GlobalStyle.ListBox}
                renderOption={(props, item) => (
                  <li {...props} key={item.id}>
                    {item.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='roleId'
                    label='Select A Role'
                    value={formik.values.RoleTypeId}
                    error={
                      formik.touched &&
                      formik.touched.RoleTypeId &&
                      Boolean(formik.errors.RoleTypeId)
                    }
                    helperText={
                      formik.touched &&
                      formik.touched.RoleTypeId &&
                      formik.errors &&
                      formik.errors.RoleTypeId
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {rolesTypeDropdown.isLoading && (
                            <CircularProgress color='inherit' size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
              />
            </>
          )}
        </Container>
      </Dialog>

      <Notification
        open={!update.isLoading && !update.isUninitialized}
        onClose={() => (update.isError ? update.reset() : location.reload())}
        isError={Boolean(update.error) && update.isError}
        message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
      />
    </>
  )
}

export default Edit
