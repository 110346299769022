import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import * as yup from 'yup'
import {
  Autocomplete,
  Box,
  CircularProgress,
  Container,
  FormControlLabel,
  Switch,
  TextField,
  Typography
} from '@mui/material'
import Dialog from 'components/dialog/dialog'
import type { PartialEVPriceChargeBoxGroupProps } from 'modules/partial'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import {
  useGetChargePointDropdownListByQuery,
  useGetChargePointDropdownListMutation
} from 'store/evChargePoint'
import {
  useGetChargeBoxGroupDetailQuery,
  useGetChargeBoxGroupDropdownListMutation
} from 'store/evChargeBoxGroup'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import Loading from 'components/loading/loading'
import CONSTANT from 'modules/constant'
import GlobalStyle from 'modules/styles'
import { useUpdatePriceChargeBoxGroupListMutation } from 'store/evPriceChargeBoxGroup'
import FORMIK from 'modules/formik'
import DialogStyle from './style'

const Edit = ({
  open,
  priceId,
  id,
  onClose
}: DetailStateProps & { priceId: string; id: string }) => {
  if (!open) return <></>

  const { data: chargeBoxDetail, isLoading: chargeBoxLoading } = useGetChargeBoxGroupDetailQuery(id)
  const { data: chargePointDropdownQuery, isLoading: chargePointDropdownQueryLoading } =
    useGetChargePointDropdownListByQuery(CONSTANT.DEFAULT_DROPDOWN_PAYLOAD)

  const [updatePriceChargeBoxGroup, update] = useUpdatePriceChargeBoxGroupListMutation()
  const [getChargePointDropdownList, chargePointDropdown] = useGetChargePointDropdownListMutation()
  const [getChargeBoxGroupDropdownList, chargeBoxGroupDropdown] =
    useGetChargeBoxGroupDropdownListMutation()

  const [chargePointId, setChargePointId] = useState<string>('')
  const [chargeBoxGroupId, setChargeBoxGroupId] = useState<string>('')
  const [isManual, setIsManual] = useState<boolean>(false)

  const scheme = yup.object<PartialEVPriceChargeBoxGroupProps>({
    id: yup.string().required('ID is required'),
    chargeBoxGroupIdentity: yup.string().required('Charge Box Group Identity is required')
  })

  const initialValues: PartialEVPriceChargeBoxGroupProps = {
    id,
    chargePointId: (chargeBoxDetail && String(chargeBoxDetail.chargePointId)) || '',
    chargeBoxGroupIdentity: (chargeBoxDetail && chargeBoxDetail.chargeBoxIdentity) || ''
  }

  const formik = FORMIK.useFormFormik<PartialEVPriceChargeBoxGroupProps>(
    scheme,
    initialValues,
    (values) => updatePriceChargeBoxGroup({ id: priceId, body: values })
  )

  const loading = chargeBoxLoading && chargePointDropdown.isLoading

  const selectedChargePoint =
    (!chargePointDropdownQueryLoading &&
      chargePointDropdownQuery &&
      chargePointDropdownQuery.find(
        (e) =>
          String(e.value) == chargePointId ||
          (chargeBoxDetail && String(chargeBoxDetail.chargePointId))
      )) ||
    null

  const selectedChargeBoxGroup =
    (chargeBoxGroupDropdown &&
      chargeBoxGroupDropdown.data &&
      chargeBoxGroupDropdown.data.find(
        (e) =>
          String(e.text) == chargeBoxGroupId ||
          (chargeBoxDetail && String(chargeBoxDetail.chargeBoxIdentity))
      )) ||
    null

  useEffect(() => {
    if (chargePointId)
      getChargeBoxGroupDropdownList({
        ...CONSTANT.DEFAULT_DROPDOWN_PAYLOAD,
        id: Number(chargePointId)
      })
  }, [chargePointId])

  useEffect(() => {
    if (chargeBoxDetail) {
      setChargePointId(String(chargeBoxDetail.chargePointId))
      setChargeBoxGroupId(String(chargeBoxDetail.chargeBoxIdentity))
    }
  }, [chargeBoxDetail])

  return (
    <>
      <Dialog
        title='Update New EV Charge Box Group'
        open={open}
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={update.isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          {loading && <Loading />}
          {!loading && (
            <>
              {selectedChargePoint && (
                <Autocomplete
                  options={chargePointDropdown.data || []}
                  getOptionLabel={(option) => option.text}
                  isOptionEqualToValue={(option, value) =>
                    option && value ? option.value == value.value : false
                  }
                  onOpen={() => getChargePointDropdownList(CONSTANT.DEFAULT_DROPDOWN_PAYLOAD)}
                  onChange={(_, chargePoint) => {
                    const point = (chargePoint && String(chargePoint.value)) || ''

                    setChargePointId(point)
                    formik.setFieldValue('chargePointId', point)
                  }}
                  value={selectedChargePoint}
                  ListboxProps={GlobalStyle.ListBox}
                  renderOption={(props, item) => (
                    <li {...props} key={item.value}>
                      {item.text}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id='ChargePointId'
                      name='ChargePointId'
                      label='EV Charge Point'
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {chargePointDropdown.isLoading && (
                              <CircularProgress color='inherit' size={20} />
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                />
              )}
              <TextField
                id='chargePointId'
                variant='outlined'
                label='EV Charge Point ID'
                value={formik.values.chargePointId}
                inputProps={{ readOnly: true }}
                fullWidth
              />
              <Box {...DialogStyle.TitleSwitch}>
                <Typography>Charge Box Group ID</Typography>
                <FormControlLabel
                  labelPlacement='start'
                  control={
                    <Switch
                      checked={isManual}
                      name='manualInput'
                      onChange={(e) => setIsManual(e.target.checked)}
                    />
                  }
                  label='Manual Input'
                />
              </Box>
              {isManual && (
                <TextField
                  id='chargeBoxGroupIdentity'
                  variant='outlined'
                  label='* EV Charge Box Group ID'
                  value={formik.values.chargeBoxGroupIdentity}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  fullWidth
                />
              )}
              {!isManual && (
                <Autocomplete
                  options={(chargeBoxGroupDropdown && chargeBoxGroupDropdown.data) || []}
                  getOptionLabel={(option) => option.text}
                  isOptionEqualToValue={(option, value) =>
                    option && value ? option.value == value.value : false
                  }
                  onOpen={() =>
                    getChargeBoxGroupDropdownList({
                      ...CONSTANT.DEFAULT_DROPDOWN_PAYLOAD,
                      id: Number(formik.values.chargePointId)
                    })
                  }
                  onChange={(_, chargeBoxGroup) => {
                    const point = (chargeBoxGroup && String(chargeBoxGroup.text)) || ''

                    setChargeBoxGroupId(point)
                    formik.setFieldValue('chargeBoxGroupIdentity', point)
                  }}
                  value={selectedChargeBoxGroup}
                  disabled={isEmpty(formik.values.chargePointId)}
                  ListboxProps={GlobalStyle.ListBox}
                  renderOption={(props, item) => (
                    <li {...props} key={item.value}>
                      {item.text}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name='ChargeBoxGroupId'
                      label='* EV Charge Box Group ID'
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {chargeBoxGroupDropdown.isLoading && (
                              <CircularProgress color='inherit' size={20} />
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                />
              )}
            </>
          )}
        </Container>
      </Dialog>

      <Notification
        open={!update.isLoading && !update.isUninitialized}
        onClose={() => (update.isError ? update.reset() : location.reload())}
        isError={Boolean(update.error) && update.isError}
        message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
      />
    </>
  )
}

export default Edit
