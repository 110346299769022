import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import moment from 'moment'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography
} from '@mui/material'
import { ExpandMoreOutlined } from '@mui/icons-material'
import { useGetCarInformationDetailQuery, useGetCarSharingMutation } from 'store/carInformation'
import type { DetailWithButtonStateProps } from 'modules/types'
import DrawerWithButton from 'components/drawerWithButton/detail'
import GLOBAL from 'modules/global'
import Loading from 'components/loading/loading'
import BackdropImage from 'components/backdropImage/backdropImage'
import Notification from 'components/notification'
import DetailStyle from './style'

const Detail = ({
  open,
  id,
  vin,
  onClose,
  onPress
}: DetailWithButtonStateProps & { id: string; vin: string }) => {
  if (!open)
    return <DrawerWithButton hasButton={false} open={open} onClose={onClose} onPress={onPress} />

  const { data, isFetching, isLoading, isSuccess } = useGetCarInformationDetailQuery(id)

  const [getCarSharing, carSharing] = useGetCarSharingMutation()
  const formatDate = (e: string) => (!isEmpty(e) ? moment(e).format('DD-MM-YYYY HH:mm:ss') : '-')

  const [nikBackdrop, setNikBackdrop] = useState<boolean>(false)
  const [stnkBackdrop, setStnkBackdrop] = useState<boolean>(false)
  const [otherBackdrop, setOtherBackdrop] = useState<boolean>(false)
  const [other2Backdrop, setOther2Backdrop] = useState<boolean>(false)
  const [ownershipBackdrop, setOwnershipBackdrop] = useState<boolean>(false)
  const [errFromDMS, setErrFromDMS] = useState<boolean>(false)

  const memberId = (data && data.memberId) || '-'
  const fullName = (data && data.fullName) || '-'
  const email = (data && data.email) || '-'
  const nik = (data && data.nik) || '-'
  const phoneNumber = (data && data.phoneNumber) || '-'
  const salutationName = (data && data.salutationName) || '-'
  const dob = data && data.dob ? GLOBAL.formatDateddMMYY(data && data.dob) : '-'
  const streetaddress = (data && data.address) || '-'
  const distric = (data && data.districtName) || '-'
  const city = (data && data.cityName) || '-'
  const province = (data && data.provinceName) || '-'
  const modelName = (data && data.modelName) || '-'
  const carModelYear = (data && data.carModelYear) || '-'
  const trim = (data && data.trim) || '-'
  const colorCode = (data && data.colorCode) || '-'
  const licensePlateNumber = (data && data.licensePlateNumber) || '-'
  const stnkName = (data && data.stnkName) || '-'
  const stnkValidUntil = (data && data.stnkValidUntil) || '-'

  const nikImage = (data && data.nikImage) || null
  const stnkImage = (data && data.stnkImage) || null
  const otherImage1 = (data && data.employeeCardImage) || null
  const otherImage2 = (data && data.businessCardImage) || null
  const ownershipEvidenceImage = (data && data.ownershipImage) || null

  const statusName = (data && data.statusName) || '-'
  const statusId = (data && data.statusId) || null
  const identityStatus = (data && data.identityStatus) || '-'
  const source = (data && data.source) || '-'
  const notes = (data && data.notes) || '-'
  const approvedOn = (data && data.approvalDateFormated) || '-'

  const nikDms = (data && data.vehicleInfoDMS.nik) || '-'
  const personId = (data && data.vehicleInfoDMS.personId) || '-'
  const ownerName = (data && data.vehicleInfoDMS.ownerName) || '-'
  const phone = (data && data.vehicleInfoDMS.phone) || '-'
  const transmission = (data && data.vehicleInfoDMS.transmission) || '-'
  const engineNo = (data && data.vehicleInfoDMS.engineNo) || '-'
  const price = (data && GLOBAL.numberFormat(data.vehicleInfoDMS.price)) || '-'
  const warrantyStartDate = (data && formatDate(data.vehicleInfoDMS.warrantyStartDate)) || '-'
  const warrantyEndtDate = (data && formatDate(data.vehicleInfoDMS.warrantyEndDate)) || '-'
  const accountStatus = (data && data.accountStatus) || '-'
  const invoiceNumber = (data && data.vehicleInfoDMS.fakturNo) || '-'
  const dealerName = (data && data.vehicleInfoDMS.dealerName) || '-'
  const dealerCode = (data && data.vehicleInfoDMS.dealerCode) || '-'
  const dealerAddress = (data && data.vehicleInfoDMS.dealerAddress) || '-'
  const bluelinkStatusName = (data && data.dmsStatusBluellink) || '-'
  const bluelinkStatusCode = (data && data.bluelinkStatus) || '0'
  const bluelinkType = (data && data.vehicleInfoDMS.blueLinkType) || '-'
  const bluelinkTypeCode = (data && data.vehicleInfoDMS.blueLinkTypeCode) || '-'
  const licensePlateDMS = (data && data.vehicleInfoDMS.licensePlate) || '-'


  const createdBy = (data && data.createdBy) || '-'
  const createdAt = (data && formatDate(data.createdAt)) || '-'
  const modifiedBy = (data && data.modifiedBy) || '-'
  const modifiedAt = (data && formatDate(data.modifiedAt)) || '-'

  const gridDataLabel = [
    'Member ID',
    'Full Name',
    'Email',
    'NIK',
    'Phone Number',
    'Salutation',
    'Date of Birth',
    'Street Address',
    'District',
    'City',
    'Province',
    'VIN',
    'Car Model',
    'Model Year',
    'Trim',
    'Color Code',
    'Plat Number',
    'STNK Name',
    'STNK Duration'
  ]
  const gridDataValue = [
    memberId,
    fullName,
    email,
    nik,
    phoneNumber,
    salutationName,
    dob,
    streetaddress,
    distric,
    city,
    province,
    vin,
    modelName,
    carModelYear,
    trim,
    colorCode,
    licensePlateNumber,
    stnkName,
    stnkValidUntil
  ]

  const statusLabel = [
    'Registration Status',
    'Identity Status',
    'Vehicle Source',
    'Notes',
    'Approved On'
  ]
  const statusValue = [statusName, identityStatus, source, notes, approvedOn]

  const VehicleInfoLabel = [
    'NIK',
    'Person ID',
    'Owner Name',
    'Full Name',
    'Email',
    'Phone Number',
    'VIN',
    'Car Model',
    'Model Year',
    'Transmission',
    'Engine Number',
    'Plat Number'
  ]
  const VehicleInfoValue = [
    nikDms,
    personId,
    ownerName,
    fullName,
    email,
    phone,
    vin,
    modelName,
    carModelYear,
    transmission,
    engineNo,
    licensePlateDMS
  ]
  const VehicleInfoLabelSecond = ['Price']
  const VehicleInfoValueSecond = [price]
  const VehicleInfoLabelThird = [
    'Warranty Start Date',
    'Warranty End Date',
    'Account Status',
    'STNK Name',
    'STNK Duration',
    'Invoice Number',
    'Dealer Name',
    'Dealer Code',
    'Dealer Address',
    'Bluelink Status',
    'Bluelink Status Code',
    'Bluelink Type',
    'Bluelink Type Code'
  ]
  const VehicleInfoValueThird = [
    warrantyStartDate,
    warrantyEndtDate,
    accountStatus,
    stnkName,
    stnkValidUntil,
    invoiceNumber,
    dealerName,
    dealerCode,
    dealerAddress,
    bluelinkStatusName,
    bluelinkStatusCode,
    bluelinkType,
    bluelinkTypeCode
  ]

  const gridBasicLabel = ['Created By', 'Created At', 'Modified By', 'Modified At']
  const gridBasicValue = [createdBy, createdAt, modifiedBy, modifiedAt]

  useEffect(() => {
    getCarSharing(vin)
    // getCarSharing('MF3PADICKPJ100037')
  }, [vin])

  useEffect(() => {
    if (isSuccess && data.errorMessage?.length) {
      setErrFromDMS(true)
    }
  }, [isSuccess, data])

  return (
    <DrawerWithButton
      hasButton={statusName !== 'Cancel' && statusId !== 6}
      open={open}
      title='View Car Information'
      onClose={onClose}
      onPress={onPress}
    >
      <Container {...DetailStyle.Container}>
        {(isLoading || isFetching) && !data && <Loading />}
        <>
          <Grid container spacing={2} sx={{ marginBottom: '10px', marginTop: '20px' }}>
            {gridDataValue.map((item, index) => (
              <Grid key={index} item xs={12}>
                <TextField
                  variant='outlined'
                  label={gridDataLabel[index]}
                  value={item}
                  inputProps={{ readOnly: true }}
                  fullWidth
                />
              </Grid>
            ))}
          </Grid>
          <Accordion elevation={0} disableGutters>
            <AccordionSummary expandIcon={<ExpandMoreOutlined />} aria-controls='basic-information'>
              <Typography>Image</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <Typography>Identity Card Photo (KTP/KITAS)</Typography>
                <Box {...DetailStyle.wrapperImage}>
                  {!nikImage && <Typography style={{ color: '#cfcfcf' }}>No Image</Typography>}
                  {nikImage && (
                    <BackdropImage
                      open={nikBackdrop}
                      onOpen={() => setNikBackdrop(true)}
                      onClose={() => setNikBackdrop(false)}
                    >
                      <img
                        src={nikImage}
                        alt={nikImage}
                        style={{ height: '300px', width: '394px', objectFit: 'contain' }}
                      />
                    </BackdropImage>
                  )}
                </Box>
                <Typography>Car Registration Letter Photo</Typography>
                <Box {...DetailStyle.wrapperImage}>
                  {!stnkImage && <Typography style={{ color: '#cfcfcf' }}>No Image</Typography>}
                  {stnkImage && (
                    <BackdropImage
                      open={stnkBackdrop}
                      onOpen={() => setStnkBackdrop(true)}
                      onClose={() => setStnkBackdrop(false)}
                    >
                      <img
                        src={stnkImage}
                        alt={stnkImage}
                        style={{ height: '300px', width: '394px', objectFit: 'contain' }}
                      />
                    </BackdropImage>
                  )}
                </Box>
                <Typography>Employee Id</Typography>
                <Box {...DetailStyle.wrapperImage}>
                  {!otherImage1 && <Typography style={{ color: '#cfcfcf' }}>No Image</Typography>}
                  {otherImage1 && (
                    <BackdropImage
                      open={otherBackdrop}
                      onOpen={() => setOtherBackdrop(true)}
                      onClose={() => setOtherBackdrop(false)}
                    >
                      <img
                        src={otherImage1}
                        alt={otherImage1}
                        style={{ height: '300px', width: '394px', objectFit: 'contain' }}
                      />
                    </BackdropImage>
                  )}
                </Box>
                <Typography>Bussiness Card Photo</Typography>
                <Box {...DetailStyle.wrapperImage}>
                  {!otherImage2 && <Typography style={{ color: '#cfcfcf' }}>No Image</Typography>}
                  {otherImage2 && (
                    <BackdropImage
                      open={other2Backdrop}
                      onOpen={() => setOther2Backdrop(true)}
                      onClose={() => setOther2Backdrop(false)}
                    >
                      <img
                        src={otherImage2}
                        alt={otherImage2}
                        style={{ height: '300px', width: '394px', objectFit: 'contain' }}
                      />
                    </BackdropImage>
                  )}
                </Box>
              </div>

              <Typography>Ownership Proof</Typography>
              <Box {...DetailStyle.wrapperImage}>
                {!ownershipEvidenceImage && (
                  <Typography style={{ color: '#cfcfcf' }}>No Image</Typography>
                )}
                {ownershipEvidenceImage && (
                  <BackdropImage
                    open={ownershipBackdrop}
                    onOpen={() => setOwnershipBackdrop(true)}
                    onClose={() => setOwnershipBackdrop(false)}
                  >
                    <img
                      src={ownershipEvidenceImage}
                      alt={ownershipEvidenceImage}
                      style={{ height: '300px', width: '394px', objectFit: 'contain' }}
                    />
                  </BackdropImage>
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} disableGutters>
            <AccordionSummary expandIcon={<ExpandMoreOutlined />} aria-controls='basic-information'>
              <Typography>Status</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {statusValue.map((item, index) => (
                  <Grid key={index} item xs={12}>
                    <TextField
                      variant='outlined'
                      label={statusLabel[index]}
                      value={item}
                      inputProps={{ readOnly: true }}
                      fullWidth
                    />
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion elevation={0} disableGutters>
            <AccordionSummary expandIcon={<ExpandMoreOutlined />} aria-controls='basic-information'>
              <Typography>Vehicle Information</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container spacing={2}>
                {VehicleInfoValue.map((item, index) => (
                  <Grid key={index} item xs={12}>
                    <TextField
                      variant='outlined'
                      label={VehicleInfoLabel[index]}
                      value={item}
                      inputProps={{ readOnly: true }}
                      fullWidth
                    />
                  </Grid>
                ))}
                {VehicleInfoValueSecond.map((item, index) => (
                  <Grid key={index} item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor={VehicleInfoLabelSecond[index]}>
                        {VehicleInfoLabelSecond[index]}
                      </InputLabel>
                      <OutlinedInput
                        id={VehicleInfoLabelSecond[index]}
                        label={VehicleInfoLabelSecond[index]}
                        value={item}
                        inputProps={{ readOnly: true }}
                        startAdornment={<InputAdornment position='start'>Rp</InputAdornment>}
                      />
                    </FormControl>
                  </Grid>
                ))}
                {VehicleInfoValueThird.map((item, index) => (
                  <Grid key={index} item xs={12}>
                    <TextField
                      variant='outlined'
                      label={VehicleInfoLabelThird[index]}
                      value={item}
                      inputProps={{ readOnly: true }}
                      fullWidth
                    />
                  </Grid>
                ))}
                <Typography
                  style={{
                    display: 'flex',
                    padding: 15,
                    marginTop: 5,
                    flexDirection: 'row'
                  }}
                >
                  Car Sharing Status
                </Typography>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    padding: '1rem',
                    width: '100%',
                    marginTop: -17
                  }}
                >
                  {carSharing.data ? (
                    <table
                      style={{
                        borderCollapse: 'collapse'
                        // width: '100%'
                      }}
                    >
                      <thead
                        style={{
                          backgroundColor: '#ccc',
                          color: '#222'
                        }}
                      >
                        <tr>
                          <th
                            style={{
                              border: '1px solid #222'
                            }}
                          >
                            Member ID
                          </th>
                          <th
                            style={{
                              border: '1px solid #222'
                            }}
                          >
                            Full Name
                          </th>
                          <th
                            style={{
                              border: '1px solid #222'
                            }}
                          >
                            Ownership Status
                          </th>
                          <th
                            style={{
                              border: '1px solid #222'
                            }}
                          >
                            Sharing Type
                          </th>
                          <th
                            style={{
                              border: '1px solid #222'
                            }}
                          >
                            Start Date
                          </th>
                          <th
                            style={{
                              border: '1px solid #222'
                            }}
                          >
                            End Date
                          </th>
                        </tr>
                      </thead>
                      {carSharing.isSuccess &&
                        carSharing &&
                        carSharing.data.map((table, index) => (
                          <tbody
                            key={index}
                            style={{
                              backgroundColor: 'white'
                            }}
                          >
                            <tr>
                              <td
                                style={{
                                  border: '1px solid #ccc',
                                  textAlign: 'center',
                                  padding: '8px',
                                  fontSize: '15px'
                                }}
                              >
                                {table.memberCode}
                              </td>
                              <td
                                style={{
                                  border: '1px solid #ccc',
                                  textAlign: 'center',
                                  padding: '8px',
                                  fontSize: '15px'
                                }}
                              >
                                {table.fullname}
                              </td>
                              <td
                                style={{
                                  border: '1px solid #ccc',
                                  textAlign: 'center',
                                  padding: '8px',
                                  fontSize: '15px'
                                }}
                              >
                                {table.ownershipStatus}
                              </td>
                              <td
                                style={{
                                  border: '1px solid #ccc',
                                  textAlign: 'center',
                                  padding: '8px',
                                  fontSize: '15px'
                                }}
                              >
                                {table.sharingType ? table.sharingType : '-'}
                              </td>
                              <td
                                style={{
                                  border: '1px solid #ccc',
                                  textAlign: 'center',
                                  padding: '8px',
                                  fontSize: '13px'
                                }}
                              >
                                {GLOBAL.formatDateddMMYYLongDate(table.startDate)}
                              </td>
                              <td
                                style={{
                                  border: '1px solid #ccc',
                                  textAlign: 'center',
                                  padding: '8px',
                                  fontSize: '13px'
                                }}
                              >
                                {GLOBAL.formatDateddMMYYLongDate(table.endDate)}
                              </td>
                            </tr>
                          </tbody>
                        ))}
                    </table>
                  ) : (
                    '-'
                  )}
                </div>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </>
      </Container>
      <Accordion elevation={0} disableGutters>
        <AccordionSummary expandIcon={<ExpandMoreOutlined />} aria-controls='basic-information'>
          <Typography>Basic Information</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {gridBasicValue.map((item, index) => (
              <Grid key={index} item xs={12}>
                <TextField
                  variant='outlined'
                  label={gridBasicLabel[index]}
                  value={item}
                  inputProps={{ readOnly: true }}
                  fullWidth
                />
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Notification
        open={errFromDMS}
        onClose={() => setErrFromDMS(false)}
        isError={true}
        message={data?.errorMessage}
      />
    </DrawerWithButton>
  )
}

export default Detail
