import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { IconButton, Menu, MenuItem, TableCell, TableRow } from '@mui/material'
import Content from 'components/content/content'
import Icon from 'components/icon'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import CONSTANT from 'modules/constant'
import GLOBAL from 'modules/global'
import type { PartialEvLocation } from 'modules/partial'
import type { ErrorProps } from 'modules/types'
import { useDownloadEvLocationMutation, useGetEvLocationMutation } from 'store/evLocation'
import Detail from './detail/detail'

const EvLocation = () => {
  const [getEvLocation, evLocation] = useGetEvLocationMutation()
  const [downloadEvLocation, download] = useDownloadEvLocationMutation()
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [menu, setMenu] = useState<null | number>(null)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [payload, setPayload] = useState<PartialEvLocation>({
    Start: 0,
    Length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    Search: '',
    Filter: 'name.Contains("q") or partnerName.Contains("z")'
  })

  const onResync = () => {
    const formData = new FormData()

    formData.append('start', payload.Start.toString())
    formData.append('length', payload.Length.toString())
    formData.append('filter', payload.Filter)

    if (!isEmpty(payload.Search)) formData.append('search', payload.Search)

    getEvLocation(formData)
  }

  useEffect(() => {
    onResync()
  }, [payload])

  const onSearch = (value: string) => setPayload({ ...payload, Start: 0, Search: value })

  const onChangePage = (value: number) => setPayload({ ...payload, Start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, Length: value })

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('Start', String(0))
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())
    formData.append('filter', payload.Filter)

    if (!isEmpty(payload.Search)) formData.append('search[value]', payload.Search)
    if (type && !isEmpty(type)) formData.append('Content-Type', type)

    downloadEvLocation(formData)
  }

  const tableHeadTitles = [
    'EV Location ID',
    'EV Location Name',
    'Owner',
    'Partner Name',
    'Address',
    'State',
    'City',
    'Latitude',
    'Longitude',
    'Place and Floor',
    'Timings/Operational Hour',
    'Access',
    'Payment'
  ]

  const openAnchor = Boolean(anchor)

  const totalPage = evLocation.data && GLOBAL.tableTotalPage(evLocation.data.recordsTotal)

  const numbers = GLOBAL.tableNumber(payload.Start)

  return (
    <>
      <Content
        title='EV Location'
        onSearch={onSearch}
        onResync={onResync}
        tableHeadTitles={tableHeadTitles}
        onDownload={onDownload}
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={evLocation.data && evLocation.data.recordsTotal}
        useDownloadDropdown
      >
        {evLocation.isSuccess &&
          evLocation.data &&
          evLocation.data.data.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${item.code}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${item.code}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${item.code}`}
                  aria-labelledby={`button-${index}-${item.code}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View Detail
                  </MenuItem>
                </Menu>
                {drawerDetail && menu == index && (
                  <Detail
                    id={item.id}
                    open={drawerDetail && menu == index}
                    onClose={() => setDrawerDetail(false)}
                  />
                )}
              </TableCell>
              <TableCell sx={{ cursor: 'pointer' }}>{item.code}</TableCell>
              <TableCell>{item.name}</TableCell>
              <TableCell>{item.companyName || '-'}</TableCell>
              <TableCell>{item.partnerName || '-'}</TableCell>
              <TableCell>{item.streetAddress || '-'}</TableCell>
              <TableCell>{item.country || '-'}</TableCell>
              <TableCell>{item.city || '-'}</TableCell>
              <TableCell>{item.latitude || '-'}</TableCell>
              <TableCell>{item.longitude || '-'}</TableCell>
              <TableCell>{item.place || '-'}</TableCell>
              <TableCell>{item.connectivity || '-'}</TableCell>
              <TableCell>{item.type || '-'}</TableCell>
              <TableCell>{item.quotaLimit || '-'}</TableCell>
            </TableRow>
          ))}
      </Content>

      <Notification
        open={!evLocation.isLoading && !evLocation.isUninitialized && !evLocation.isSuccess}
        onClose={() => (evLocation.isError ? evLocation.reset() : location.reload())}
        isError={evLocation.isError}
        message={GLOBAL.returnExceptionMessage(evLocation.isError, evLocation.error as ErrorProps)}
      />

      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />
      {(evLocation.isLoading || download.isLoading) && <Loading />}
    </>
  )
}

export default EvLocation
