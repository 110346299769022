import CONSTANT from 'modules/constant'
import type { PartialRegionActivateProps } from 'modules/partial'
import type {
  PaginationProps,
  RegionProps,
  RegionDetailProps,
  RegionActivateProps,
  RegionListDropdownProps
} from 'modules/types'
import { API, download, headers } from './api'

const RegionAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getProvince: builder.mutation<PaginationProps<RegionProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_REGION_DATA_AUTH_PROVINCE,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    getCity: builder.mutation<PaginationProps<RegionProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_REGION_DATA_AUTH_CITY,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    getDistrict: builder.mutation<PaginationProps<RegionProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_REGION_DATA_AUTH_DISTRICT,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    getSubDistrict: builder.mutation<PaginationProps<RegionProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_REGION_DATA_AUTH_SUBDISTRICT,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    getProvinceDropdown: builder.mutation<RegionListDropdownProps[], void>({
      query: () => ({
        url: CONSTANT.URL_REGION_DATA_AUTH_PROVINCE_DROPDOWN,
        method: 'GET'
      })
    }),
    getCityDropdown: builder.mutation<RegionListDropdownProps[], number | string>({
      query: (id) => ({
        url: CONSTANT.URL_REGION_DATA_AUTH_CITY_DROPDOWN + id,
        method: 'GET'
      })
    }),
    getDistrictDropdown: builder.mutation<RegionListDropdownProps[], number | string>({
      query: (id) => ({
        url: CONSTANT.URL_REGION_DATA_AUTH_DISTRICT_DROPDOWN + id,
        method: 'GET'
      })
    }),
    getSubDistrictDropdown: builder.mutation<RegionListDropdownProps[], number | string>({
      query: (id) => ({
        url: CONSTANT.URL_REGION_DATA_AUTH_SUBDISTRICT_DROPDOWN + id,
        method: 'GET'
      })
    }),
    getRegion: builder.mutation<PaginationProps<RegionProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_REGION_DATA_AUTH,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    getRegionDetail: builder.query<RegionDetailProps, number>({
      query: (id) => ({
        url: CONSTANT.URL_REGION_DATA_AUTH_DETAIL + id,
        method: 'GET',
        headers: headers()
      })
    }),
    activateRegion: builder.mutation<RegionActivateProps, Partial<PartialRegionActivateProps>>({
      query: (body) => ({
        url: CONSTANT.URL_REGION_DATA_AUTH_ACTIVATE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    downloadRegion: builder.mutation<PaginationProps<RegionProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_REGION_DATA_AUTH_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        responseHandler: download,
        body: body,
        formData: true
      })
    }),

    getProvinceDMS: builder.mutation<RegionListDropdownProps[], void>({
      query: (body) => ({
        url: CONSTANT.URL_REGION_DATA_DMS_PROVINCE,
        method: 'POST',
        body: body
      })
    }),
    getCityDMS: builder.mutation<RegionListDropdownProps[], { province: string }>({
      query: (body) => ({
        url: CONSTANT.URL_REGION_DATA_DMS_CITY,
        method: 'POST',
        body: body
      })
    }),
    getRegionDMS: builder.mutation<PaginationProps<RegionProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_REGION_DATA_DMS,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    getRegionDetailDMS: builder.query<RegionDetailProps, number>({
      query: (id) => ({
        url: CONSTANT.URL_REGION_DATA_DMS_DETAIL + id,
        method: 'GET',
        headers: headers()
      })
    }),
    activateRegionDMS: builder.mutation<RegionActivateProps, Partial<PartialRegionActivateProps>>({
      query: (body) => ({
        url: CONSTANT.URL_REGION_DATA_DMS_ACTIVATE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    downloadRegionDMS: builder.mutation<PaginationProps<RegionProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_REGION_DATA_DMS_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        responseHandler: download,
        body: body,
        formData: true
      })
    })
  })
})

export const {
  useGetProvinceMutation,
  useGetCityMutation,
  useGetDistrictMutation,
  useGetSubDistrictMutation,
  useGetProvinceDropdownMutation,
  useGetCityDropdownMutation,
  useGetDistrictDropdownMutation,
  useGetSubDistrictDropdownMutation,
  useGetRegionMutation,
  useGetRegionDetailDMSQuery,
  useDownloadRegionMutation,
  useActivateRegionMutation,

  useGetProvinceDMSMutation,
  useGetCityDMSMutation,
  useGetRegionDMSMutation,
  useGetRegionDetailQuery,
  useDownloadRegionDMSMutation,
  useActivateRegionDMSMutation
} = RegionAPI
