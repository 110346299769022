import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { Chip, FormControl, IconButton, InputLabel, Menu, MenuItem, Select, TableCell, TableRow, Typography } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import { useDeleteBannerMutation, useGetListBannerEventMutation } from 'store/serviceBookingEventBanner'
import Icon from 'components/icon'
import CONSTANT from 'modules/constant'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { ErrorProps } from 'modules/types'
import type { PartialBanner } from 'modules/partial'
import MenuModalItem from 'components/menuItem/menuItem'
import GlobalStyle from 'modules/styles'
import Create from './create/create'
import Edit from './edit/page'





const Banner = () => {
    const [getBannerEventList, bannerEventList] = useGetListBannerEventMutation()
    const onCreate = () => setDialogCreate(true)

    const [filter, setFilter] = useState({
        Status: '',
        MaxValidFrom: '',
        MaxValidUntil: '',

    })

    const [payload, setPayload] = useState<PartialBanner>({
        start: 0,
        length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
        field: CONSTANT.DEFAULT_CAR_INFORMATION_FIELD.join(','),
        Search: '',
        Event: '',
        BannerImage: '',
        Vin: '',
        MaxValidFrom: '',
        MaxValidUntil: '',
        Status: ''
    })

    const [dialogFilter, setDialogFilter] = useState<boolean>(false)
    const [anchor, setAnchor] = useState<null | HTMLElement>(null)
    const [menu, setMenu] = useState<null | number>(null)
    // const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
    // const [isUpdateAccountStatus, SetIsUpdateAccountStatus] = useState<boolean>(false)
    const [dialogCreate, setDialogCreate] = useState<boolean>(false)
    const [dialogUpdate, setDialogUpdate] = useState<boolean>(false)
    const [setDeleteBanner, deleteBanner] = useDeleteBannerMutation()

    const formatDate = (e: string) => (!isEmpty(e) ? moment(e).format('DD-MM-YYYY HH:mm') : '-')
    const onSearch = (value: string) => setPayload({ ...payload, start: 0, Search: value })
    const onChangePage = (value: number) => setPayload({ ...payload, start: value - 1 })
    const onChangePerPage = (value: number) => setPayload({ ...payload, length: value })

    const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
        setAnchor(event.currentTarget)
        setMenu(index)
    }

    const onRemoveAnchor = async (callback?: CallableFunction) => {
        setAnchor(null)
        callback && callback(callback)
    }

    const onResync = () => {
        const formData = new FormData()

        formData.append('start', payload.start.toString())
        formData.append('length', payload.length.toString())
        formData.append('field', payload.field)

        if (!isEmpty(payload.Search)) formData.append('Search', payload.Search)
        if (!isEmpty(payload.Event)) formData.append('Event', payload.Event)
        if (!isEmpty(payload.BannerImage)) formData.append('BannerImage', payload.BannerImage)
        if (!isEmpty(payload.MaxValidFrom)) formData.append('MaxValidFrom', payload.MaxValidFrom)
        if (!isEmpty(payload.MaxValidUntil)) formData.append('MaxValidUntil', payload.MaxValidUntil)
        if (!isEmpty(payload.Status)) formData.append('Status', payload.Status)
        getBannerEventList(formData)
    }

    const onFilter = (state: boolean) => setDialogFilter(state)

    // const onDownload = () => console.log('onDownload')

    const tableHeadTitles = [
        'Event',
        'Banner Image',
        'Valid From',
        'Valid Until',
        'Status'
    ]

    const totalPage =
        bannerEventList.data && GLOBAL.tableTotalPage(bannerEventList.data.recordsTotal)
    const numbers = GLOBAL.tableNumber(payload.start)
    const openAnchor = Boolean(anchor)


    useEffect(() => {
        onResync()
    }, [payload])

    return (
        <>
            <Content
                title='Banner'
                tableHeadTitles={tableHeadTitles}
                onSearch={onSearch}
                onResync={onResync}
                onFilter={() => onFilter(true)}
                // onDownload={onDownload}
                // downloadLoading={download.isLoading}
                onAdditional={onCreate}
                additionalTitle='Banner'
                additionalPrefixIcon='Create'
                pagination={onChangePage}
                onChangePerPage={onChangePerPage}
                totalPage={totalPage}
                totalRecords={bannerEventList.data && bannerEventList.data.recordsTotal}
                page={payload.start + 1}
                isLoading={bannerEventList.isLoading}
            >
                {bannerEventList.isSuccess &&
                    bannerEventList.data &&
                    bannerEventList.data.data?.map((table, index) => (
                        <TableRow key={index}>
                            <TableCell>{numbers[index] + 1}</TableCell>
                            <TableCell>
                                <IconButton
                                    id={`button-${index}-${table.id}`}
                                    aria-haspopup='true'
                                    aria-controls={openAnchor ? `menu-${index}-${table.id}` : undefined}
                                    aria-expanded={openAnchor ? 'true' : undefined}
                                    onClick={(event) => onAction(event, index)}
                                >
                                    <Icon icon='MoreHoriz' />
                                </IconButton>
                                <Menu
                                    id={`menu-${index}-${table.id}`}
                                    aria-labelledby={`button-${index}-${table.id}`}
                                    anchorEl={anchor}
                                    open={openAnchor && menu == index}
                                    onClose={() => onRemoveAnchor(() => setMenu(null))}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left'
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left'
                                    }}
                                >
                                    <MenuItem onClick={() => onRemoveAnchor(() => setDialogUpdate(true))}>
                                        Edit
                                    </MenuItem>
                                    <MenuModalItem
                                        title='Delete'
                                        loading={deleteBanner.isLoading}
                                        onClick={() => onRemoveAnchor(() => setDeleteBanner(table.id))}
                                    >
                                        <Typography {...GlobalStyle.ErrorTypography}>Delete</Typography>
                                    </MenuModalItem>
                                </Menu>
                                <Edit
                                    id={table.id}
                                    title={'Edit'}
                                    open={dialogUpdate && menu == index}
                                    onClose={() => setDialogUpdate(false)}
                                />
                            </TableCell>
                            <TableCell>{table.eventName || '-'}</TableCell>
                            <TableCell>{table.bannerImage || '-'}</TableCell>
                            <TableCell>{formatDate(table.validFrom) || '-'}</TableCell>
                            <TableCell>{formatDate(table.validUntil) || '-'}</TableCell>
                            <TableCell>
                                <Chip
                                    label={table.status}
                                    color={table.status == 'Enabled' ? 'success' : 'error'}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
            </Content>

            <Create open={dialogCreate} onClose={() => setDialogCreate(false)} />
            <Filter
                open={dialogFilter}
                title='Filter'
                onClose={() => onFilter(false)}
                onCancel={() => onFilter(false)}
                onSubmit={() => {
                    setPayload({
                        ...payload,
                        ...filter,
                        start: 0
                    })
                    onFilter(false)
                }}
                isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
                onReset={() => {
                    setFilter({
                        Status: '',
                        MaxValidFrom: '',
                        MaxValidUntil: ''
                    })
                    setPayload({
                        ...payload,
                        start: 0,
                        Search: '',
                        Event: '',
                        BannerImage: '',
                        Vin: '',
                        MaxValidFrom: '',
                        MaxValidUntil: '',
                        Status: ''
                    })
                    onFilter(false)
                }}
            >
                <FormControl fullWidth>
                    <InputLabel id='select-label'>Status</InputLabel>
                    <Select
                        labelId='select-label'
                        id='id-select-label'
                        value={filter.Status}
                        label='Status'
                        onChange={(event) => setFilter({ ...filter, Status: event.target.value })}
                    >
                        <MenuItem value='Enabled'>Enabled</MenuItem>
                        <MenuItem value='Disabled'>Disabled</MenuItem>
                    </Select>
                </FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                    <DatePicker
                        value={filter.MaxValidFrom ? dayjs(filter.MaxValidFrom) : null}
                        onChange={(date: Dayjs | null) =>
                            date && setFilter({ ...filter, MaxValidFrom: date.format('YYYY-MM-DD') })
                        }
                        label='Max Valid From'
                        format='DD-MM-YYYY'
                    />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                    <DatePicker
                        value={filter.MaxValidUntil ? dayjs(filter.MaxValidUntil) : null}
                        minDate={dayjs(filter.MaxValidFrom)}
                        onChange={(date: Dayjs | null) =>
                            date && setFilter({ ...filter, MaxValidUntil: date.format('YYYY-MM-DD') })
                        }
                        label='Max Valid Until'
                        format='DD-MM-YYYY'
                    />
                </LocalizationProvider>
            </Filter>
            <Notification
                open={!deleteBanner.isLoading && !deleteBanner.isUninitialized}
                onClose={() => (deleteBanner.isError ? deleteBanner.reset() : location.reload())}
                isError={Boolean(deleteBanner.error) && deleteBanner.isError}
                message={GLOBAL.returnExceptionMessage(
                    deleteBanner.isError,
                    deleteBanner.error as ErrorProps
                )}
            />
        </>
    )
}

export default Banner
