import * as yup from 'yup'
import {
  Container,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField
} from '@mui/material'
import Dialog from 'components/dialog/dialog'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import { useCreatePriceMutation, useGenerateCodePriceMutation } from 'store/evPrice'
import type { PartialEVPriceDetailProps } from 'modules/partial'
import FORMIK from 'modules/formik'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import DialogStyle from './style'

const Create = ({ open, onClose }: DetailStateProps) => {
  if (!open) return <></>

  const [createPrice, create] = useCreatePriceMutation()
  const [generateCode] = useGenerateCodePriceMutation()

  const scheme = yup.object<PartialEVPriceDetailProps>({
    code: yup.string(),
    name: yup.string().required('Name is Required'),
    remark: yup.string().required('Description is Required'),
    electricityFee: yup.string().required('Electricity Fee is Required'),
    electricityFeePerUnit: yup.string().required('Electricity Fee per Unit is Required'),
    serviceFee: yup.string().required('Service Fee is Required'),
    serviceFeePerUnit: yup.string().required('Service Fee per Unit is Required'),
    adminFee: yup.string().required('Admin Fee is Required'),
    adminFeePerUnit: yup.string().required('Admin Fee per Unit is Required'),
    minimumKwhForAdminFee: yup.string().required('Minimum kWh for Admin Fee is Required'),
    minimumBalance: yup.string().required('Minimum Balance is Required'),
    estimateMinIncomingDuration: yup.number().nullable(),
    estimateMaxIncomingDuration: yup.number().nullable(),
    estimateIncomingPowerInKwh: yup.number().nullable()
  })

  const initialValues: PartialEVPriceDetailProps = {
    code: '',
    name: '',
    remark: '',
    electricityFee: null,
    electricityFeePerUnit: 'kWh',
    serviceFee: null,
    serviceFeePerUnit: 'Hour',
    adminFee: null,
    adminFeePerUnit: 'Session',
    minimumKwhForAdminFee: null,
    minimumBalance: null,
    estimateMinIncomingDuration: null,
    estimateMaxIncomingDuration: null,
    estimateIncomingPowerInKwh: null
  }

  const formik = FORMIK.useFormFormik<PartialEVPriceDetailProps>(scheme, initialValues, (values) =>
    generateCode()
      .unwrap()
      .then((result) => createPrice({ ...values, code: result.code || '' }))
  )

  const error = formik.errors

  return (
    <>
      <Dialog
        title='Create New Charging Price Management'
        open={open}
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={create.isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          <TextField
            id='name'
            variant='outlined'
            label='Price Table Name'
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched && formik.touched.name && Boolean(error.name)}
            helperText={formik.touched && formik.touched.name && error && error.name}
            fullWidth
          />
          <TextField
            id='remark'
            variant='outlined'
            label='Price Table Description'
            value={formik.values.remark}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched && formik.touched.remark && Boolean(error.remark)}
            helperText={formik.touched && formik.touched.remark && error && error.remark}
            fullWidth
          />
          <FormControl fullWidth>
            <InputLabel htmlFor='electricityFee'>Electricity Fee</InputLabel>
            <OutlinedInput
              id='electricityFee'
              label='Electricity Fee'
              type='number'
              value={formik.values.electricityFee && formik.values.electricityFee.toString()}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched &&
                formik.touched.electricityFee &&
                Boolean(formik.errors.electricityFee)
              }
              inputProps={{ autoComplete: 'off' }}
              onKeyDown={(event) => {
                if (/^[.,\-\b]+$/.test(event.key)) event.preventDefault()
              }}
              startAdornment={<InputAdornment position='start'>Rp</InputAdornment>}
              endAdornment={
                <InputAdornment position='end'>
                  / {formik.values.electricityFeePerUnit}
                </InputAdornment>
              }
            />
            {formik.touched && formik.touched.electricityFee && formik.errors && (
              <FormHelperText error id='electricityFee'>
                {formik.errors.electricityFee}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth>
            <InputLabel htmlFor='serviceFee'>Service Fee</InputLabel>
            <OutlinedInput
              id='serviceFee'
              label='Service Fee'
              type='number'
              value={formik.values.serviceFee && formik.values.serviceFee.toString()}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched && formik.touched.serviceFee && Boolean(formik.errors.serviceFee)
              }
              inputProps={{ autoComplete: 'off' }}
              onKeyDown={(event) => {
                if (/^[.,\-\b]+$/.test(event.key)) event.preventDefault()
              }}
              startAdornment={<InputAdornment position='start'>Rp</InputAdornment>}
              endAdornment={
                <InputAdornment position='end'>/ {formik.values.serviceFeePerUnit}</InputAdornment>
              }
            />
            {formik.touched && formik.touched.serviceFee && formik.errors && (
              <FormHelperText error id='serviceFee'>
                {formik.errors.serviceFee}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth>
            <InputLabel htmlFor='adminFee'>Admin Fee</InputLabel>
            <OutlinedInput
              id='adminFee'
              label='Admin Fee'
              type='number'
              value={formik.values.adminFee && formik.values.adminFee.toString()}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched && formik.touched.adminFee && Boolean(formik.errors.adminFee)}
              inputProps={{ autoComplete: 'off' }}
              onKeyDown={(event) => {
                if (/^[.,\-\b]+$/.test(event.key)) event.preventDefault()
              }}
              startAdornment={<InputAdornment position='start'>Rp</InputAdornment>}
              endAdornment={
                <InputAdornment position='end'>/ {formik.values.adminFeePerUnit}</InputAdornment>
              }
            />
            {formik.touched && formik.touched.adminFee && formik.errors && (
              <FormHelperText error id='adminFee'>
                {formik.errors.adminFee}
              </FormHelperText>
            )}
          </FormControl>
          <TextField
            id='minimumKwhForAdminFee'
            variant='outlined'
            label='Minimum kWh to Apply Admin Fee'
            type='number'
            value={
              formik.values.minimumKwhForAdminFee && formik.values.minimumKwhForAdminFee.toString()
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onKeyDown={(event) => {
              if (/^[.,\-\b]+$/.test(event.key)) event.preventDefault()
            }}
            error={
              formik.touched &&
              formik.touched.minimumKwhForAdminFee &&
              Boolean(error.minimumKwhForAdminFee)
            }
            helperText={
              formik.touched &&
              formik.touched.minimumKwhForAdminFee &&
              error &&
              error.minimumKwhForAdminFee
            }
            fullWidth
          />
          <FormControl fullWidth>
            <InputLabel htmlFor='minimumBalance'>Minimum Balance Required</InputLabel>
            <OutlinedInput
              id='minimumBalance'
              label='Minimum Balance Required'
              type='number'
              value={formik.values.minimumBalance && formik.values.minimumBalance.toString()}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched &&
                formik.touched.minimumBalance &&
                Boolean(formik.errors.minimumBalance)
              }
              inputProps={{ autoComplete: 'off' }}
              onKeyDown={(event) => {
                if (/^[.,\-\b]+$/.test(event.key)) event.preventDefault()
              }}
              startAdornment={<InputAdornment position='start'>Rp</InputAdornment>}
            />
            {formik.touched && formik.touched.minimumBalance && formik.errors && (
              <FormHelperText error id='adminFee'>
                {formik.errors.minimumBalance}
              </FormHelperText>
            )}
          </FormControl>
          <TextField
            id='estimateMinIncomingDuration'
            variant='outlined'
            label='Estimate Min Incoming Duration'
            type='number'
            value={
              formik.values.estimateMinIncomingDuration &&
              formik.values.estimateMinIncomingDuration.toString()
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onKeyDown={(event) => {
              if (/^[.,\-\b]+$/.test(event.key)) event.preventDefault()
            }}
            error={
              formik.touched &&
              formik.touched.estimateMinIncomingDuration &&
              Boolean(error.estimateMinIncomingDuration)
            }
            helperText={
              formik.touched &&
              formik.touched.estimateMinIncomingDuration &&
              error &&
              error.estimateMinIncomingDuration
            }
            fullWidth
          />
          <TextField
            id='estimateMaxIncomingDuration'
            variant='outlined'
            label='Estimate Max Incoming Duration'
            type='number'
            value={
              formik.values.estimateMaxIncomingDuration &&
              formik.values.estimateMaxIncomingDuration.toString()
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onKeyDown={(event) => {
              if (/^[.,\-\b]+$/.test(event.key)) event.preventDefault()
            }}
            error={
              formik.touched &&
              formik.touched.estimateMaxIncomingDuration &&
              Boolean(error.estimateMaxIncomingDuration)
            }
            helperText={
              formik.touched &&
              formik.touched.estimateMaxIncomingDuration &&
              error &&
              error.estimateMaxIncomingDuration
            }
            fullWidth
          />
          <TextField
            id='estimateIncomingPowerInKwh'
            variant='outlined'
            label='Estimate Incoming Power In Kwh'
            type='number'
            value={
              formik.values.estimateIncomingPowerInKwh &&
              formik.values.estimateIncomingPowerInKwh.toString()
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onKeyDown={(event) => {
              if (/^[.,\-\b]+$/.test(event.key)) event.preventDefault()
            }}
            error={
              formik.touched &&
              formik.touched.estimateIncomingPowerInKwh &&
              Boolean(error.estimateIncomingPowerInKwh)
            }
            helperText={
              formik.touched &&
              formik.touched.estimateIncomingPowerInKwh &&
              error &&
              error.estimateIncomingPowerInKwh
            }
            fullWidth
          />
        </Container>
      </Dialog>

      <Notification
        open={!create.isLoading && !create.isUninitialized}
        onClose={() => (create.isError ? create.reset() : location.reload())}
        isError={Boolean(create.error) && create.isError}
        message={GLOBAL.returnExceptionMessage(create.isError, create.error as ErrorProps)}
      />
    </>
  )
}

export default Create
