import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
  Chip,
  Autocomplete,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  TextField
} from '@mui/material'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import Icon from 'components/icon'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import CONSTANT from 'modules/constant'
import GLOBAL from 'modules/global'
import {
  useDownloadRolesListMutation,
  useGetRolesListMutation,
  useGetRolesListTypeDropdownMutation,
  useUpdateRolesListMutation
} from 'store/roles'
import type { PartialRolesProps } from 'modules/partial'
import type { ErrorProps } from 'modules/types'
import MenuModalItem from 'components/menuItem/menuItem'
import GlobalStyle from 'modules/styles'
import Create from './create/create'
import Detail from './detail/detail'
import Edit from './edit/edit'

const Roles = () => {
  const [getRolesList, roles] = useGetRolesListMutation()
  const [updateRolesList, update] = useUpdateRolesListMutation()
  const [getRolesListTypesDropdown, rolesTypeDropdown] = useGetRolesListTypeDropdownMutation()
  const [downloadRolesList, download] = useDownloadRolesListMutation()

  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [dialogCreate, setDialogCreate] = useState<boolean>(false)
  const [dialogUpdate, setDialogUpdate] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [menu, setMenu] = useState<null | number>(null)

  const [filter, setFilter] = useState({
    status: '',
    RoleType: ''
  })

  const [payload, setPayload] = useState<PartialRolesProps>({
    start: 0,
    length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    field: CONSTANT.DEFAULT_ROLES_LIST_FIELD.join(','),
    search: { value: '' },
    status: '',
    RoleType: ''
  })

  const onSearch = (value: string) => setPayload({ ...payload, start: 0, search: { value } })
  const onFilter = (state: boolean) => setDialogFilter(state)

  const onResync = () => {
    const formData = new FormData()

    formData.append('start', payload.start.toString())
    formData.append('length', payload.length.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(payload.status)) formData.append('status', payload.status)
    if (!isEmpty(payload.RoleType)) formData.append('RoleType', payload.RoleType)

    getRolesList(formData)
  }

  const onCreate = () => setDialogCreate(true)

  const onChangePage = (value: number) => setPayload({ ...payload, start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, length: value })

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('start', String(0))
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(payload.status)) formData.append('status', payload.status)
    if (!isEmpty(payload.RoleType)) formData.append('RoleType', payload.RoleType)
    if (type && !isEmpty(type)) formData.append('Download-Type', type)

    downloadRolesList(formData)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const tableHeadTitles = ['Name', 'Type', 'Description', 'status']

  const openAnchor = Boolean(anchor)

  const totalPage = roles.data && GLOBAL.tableTotalPage(roles.data.recordsTotal)

  const numbers = GLOBAL.tableNumber(payload.start)

  useEffect(() => {
    onResync()
  }, [payload])

  return (
    <>
      <Content
        title='Roles'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        onAdditional={onCreate}
        additionalTitle='Create New Role'
        additionalPrefixIcon='Create'
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={roles.data && roles.data.recordsTotal}
        page={payload.start + 1}
        isLoading={roles.isLoading}
        useDownloadDropdown
      >
        {roles.isSuccess &&
          roles.data &&
          roles.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.id}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.id}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.id}`}
                  aria-labelledby={`button-${index}-${table.id}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View
                  </MenuItem>
                  <MenuItem onClick={() => onRemoveAnchor(() => setDialogUpdate(true))}>
                    Edit
                  </MenuItem>
                  <MenuModalItem
                    title={table.status == 'Enabled' ? 'Disable' : 'Enable'}
                    onClick={() =>
                      onRemoveAnchor(() =>
                        updateRolesList({
                          Id: table.id,
                          Name: table.name,
                          Description: table.description,
                          Status: table.status == 'Enabled' ? 'Disabled' : 'Enabled'
                        })
                      )
                    }
                  >
                    {table.status == 'Enabled' ? 'Disable' : 'Enable'}
                  </MenuModalItem>
                </Menu>
                <Detail
                  id={table.id}
                  open={drawerDetail && menu == index}
                  onClose={() => setDrawerDetail(false)}
                />
                <Edit
                  id={table.id}
                  open={dialogUpdate && menu == index}
                  onClose={() => setDialogUpdate(false)}
                />
              </TableCell>
              <TableCell>{table.name || '-'}</TableCell>
              <TableCell>{table.type || '-'}</TableCell>
              <TableCell>{table.description || '-'}</TableCell>
              <TableCell>
                <Chip
                  label={table.status}
                  color={table.status == 'Enabled' ? 'success' : 'error'}
                />
              </TableCell>
            </TableRow>
          ))}
      </Content>

      {roles.isLoading && <Loading />}

      <Create open={dialogCreate} onClose={() => setDialogCreate(false)} />

      <Filter
        open={dialogFilter}
        title='Filter'
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
        onReset={() => {
          setFilter({
            status: '',
            RoleType: ''
          })
          setPayload({
            ...payload,
            start: 0,
            status: '',
            RoleType: ''
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({ ...payload, start: 0, ...filter })
          onFilter(false)
        }}
      >
        <FormControl fullWidth>
          <InputLabel id='select-label'>Status</InputLabel>
          <Select
            labelId='select-label'
            id='id-select-label'
            value={filter.status}
            label='Status'
            onChange={(event) => setFilter({ ...filter, status: event.target.value })}
          >
            <MenuItem value='Enabled'>Enabled</MenuItem>
            <MenuItem value='Disabled'>Disabled</MenuItem>
          </Select>
        </FormControl>
        <Autocomplete
          options={rolesTypeDropdown.data || []}
          getOptionLabel={(list) => list.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.name == value.name : false
          }
          onOpen={() => getRolesListTypesDropdown()}
          onInputChange={(_, RoleType) => setFilter({ ...filter, RoleType })}
          value={
            rolesTypeDropdown &&
            rolesTypeDropdown.data &&
            rolesTypeDropdown.data.find((e) => e.name == filter.RoleType)
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.id}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='Role'
              label='Select A Role'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {rolesTypeDropdown.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
      </Filter>

      <Notification
        open={!roles.isLoading && !roles.isUninitialized && !roles.isSuccess}
        onClose={() => (roles.isError ? roles.reset() : location.reload())}
        isError={Boolean(roles.error) && roles.isError}
        message={GLOBAL.returnExceptionMessage(roles.isError, roles.error as ErrorProps)}
      />

      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />

      <Notification
        open={!update.isLoading && !update.isUninitialized}
        onClose={() => (update.isError ? update.reset() : location.reload())}
        isError={Boolean(update.error) && update.isError}
        message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
      />
    </>
  )
}

export default Roles
