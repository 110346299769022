import CONSTANT from 'modules/constant'
import type { PartialStatusProps, PartialTermsDetailProps } from 'modules/partial'
import type {
  CompanyListDropdownProps,
  HTMLProps,
  PaginationProps,
  ResultProps
} from 'modules/types'
import { API, download, headers } from './api'

const TermsAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    createTermsAndCondition: builder.mutation<ResultProps, PartialTermsDetailProps>({
      query: (body) => ({
        url: CONSTANT.URL_TERMS_AND_CONDITION_CREATE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    getTermsAndCondition: builder.mutation<PaginationProps<HTMLProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_TERMS_AND_CONDITION,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    getTermsAndConditionDropdown: builder.mutation<CompanyListDropdownProps[], void>({
      query: () => ({
        url: CONSTANT.URL_TERMS_AND_CONDITION_DROPDOWN,
        method: 'GET',
        headers: headers()
      })
    }),
    getTermsAndConditionDetail: builder.query<PartialTermsDetailProps, { key: string }>({
      query: (body) => ({
        url: CONSTANT.URL_TERMS_AND_CONDITION_DETAIL,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    enableTermsAndCondition: builder.mutation<ResultProps, PartialStatusProps>({
      query: (body) => ({
        url: CONSTANT.URL_TERMS_AND_CONDITION_STATUS,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    updateTermsAndCondition: builder.mutation<ResultProps, PartialTermsDetailProps>({
      query: (body) => ({
        url: CONSTANT.URL_TERMS_AND_CONDITION_UPDATE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    deleteTermsAndCondition: builder.mutation<ResultProps, { key: string }>({
      query: (body) => ({
        url: CONSTANT.URL_TERMS_AND_CONDITION_DELETE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    downloadTermsAndCondition: builder.mutation<PaginationProps<HTMLProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_TERMS_AND_CONDITION_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        responseHandler: download,
        body: body,
        formData: true
      })
    })
  })
})

export const {
  useCreateTermsAndConditionMutation,
  useGetTermsAndConditionMutation,
  useGetTermsAndConditionDropdownMutation,
  useGetTermsAndConditionDetailQuery,
  useEnableTermsAndConditionMutation,
  useUpdateTermsAndConditionMutation,
  useDeleteTermsAndConditionMutation,
  useDownloadTermsAndConditionMutation
} = TermsAPI
