import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { useLocation, useParams } from 'react-router-dom'
import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import type { PartialHyundaiCarCatalog } from 'modules/partial'
import CONSTANT from 'modules/constant'
import type { ErrorProps, ParamProps } from 'modules/types'
import {
  useDeleteHyundaiCatalogMutation,
  useDownloadHyundaiCatalogMutation,
  useGetHyundaiCatalogListMutation
} from 'store/hyundaiModel'
import Loading from 'components/loading/loading'
import Icon from 'components/icon'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import {
  useGetCarModelDropdownListMutation,
  useGetYearsDropdownListByMutation
} from 'store/dropdown'
import MenuModalItem from 'components/menuItem/menuItem'
import GlobalStyle from 'modules/styles'
import Detail from './detail/detail'
import Create from './create/create'
import Edit from './edit/edit'

const HyundaiCarCatalog = () => {
  const params = useParams<ParamProps>()
  const locationState = useLocation()
  const [getHyundaiCatalogList, catalogList] = useGetHyundaiCatalogListMutation()
  const [downloadHyundaiCatalog, download] = useDownloadHyundaiCatalogMutation()
  const [deleteHyundaiCatalog, deleteCatalog] = useDeleteHyundaiCatalogMutation()
  const [getCarModelDropdownList, model] = useGetCarModelDropdownListMutation()
  const [getYearsDropdownListBy, years] = useGetYearsDropdownListByMutation()

  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [drawerCreate, setDrawerCreate] = useState<boolean>(false)
  const [dialogUpdate, setDialogUpdate] = useState<boolean>(false)

  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [menu, setMenu] = useState<null | number>(null)
  const [filter, setFilter] = useState({
    year: '',
    carModel: locationState.state.id || ''
  })
  const [payload, setPayload] = useState<PartialHyundaiCarCatalog>({
    start: 0,
    length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    field: 'name,discontinued',
    search: { value: '' },
    discontinued: '',
    year: '',
    carModel: locationState.state.id || ''
  })

  const onResync = () => {
    const formData = new FormData()
    formData.append('start', payload.start.toString())
    formData.append('length', payload.length.toString())
    formData.append('field', payload.field)
    formData.append('car-model', payload.carModel)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(payload.year)) formData.append('year', payload.year)
    if (!isEmpty(payload.discontinued)) formData.append('discontinued', payload.discontinued)

    getHyundaiCatalogList(formData)
  }

  const onChangePage = (value: number) => setPayload({ ...payload, start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, length: value })

  const onSearch = (value: string) => setPayload({ ...payload, start: 0, search: { value } })
  const onFilter = (state: boolean) => setDialogFilter(state)

  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('start', String(0))
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())
    formData.append('field', payload.field)
    formData.append('car-model', payload.carModel)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(payload.year)) formData.append('year', payload.year)
    if (!isEmpty(payload.discontinued)) formData.append('discontinued', payload.discontinued)
    if (type && !isEmpty(type)) formData.append('Download-Type', type)

    downloadHyundaiCatalog(formData)
  }
  const onCreate = () => setDrawerCreate(true)

  useEffect(() => {
    onResync()
  }, [payload])

  const tableHeadTitles = ['Trim', 'Year', 'Color']

  const numbers = GLOBAL.tableNumber(payload.start)

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }
  const openAnchor = Boolean(anchor)

  const totalPage = catalogList.data && GLOBAL.tableTotalPage(catalogList.data.recordsTotal)

  const carName = GLOBAL.decodeUrl(params.key || '')

  return (
    <>
      <Content
        title={`Catalog › ${carName}`}
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onFilter={() => {
          getYearsDropdownListBy()
          onFilter(true)
        }}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        onAdditional={onCreate}
        additionalTitle='Create New Catalog'
        additionalPrefixIcon='Create'
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={catalogList.data && catalogList.data.recordsTotal}
        page={payload.start + 1}
        isLoading={catalogList.isLoading}
        useBackButton
        useDownloadDropdown
      >
        {catalogList.isSuccess &&
          catalogList.data &&
          catalogList.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.id}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.id}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.id}`}
                  aria-labelledby={`button-${index}-${table.id}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View
                  </MenuItem>
                  <MenuItem onClick={() => onRemoveAnchor(() => setDialogUpdate(true))}>
                    Edit
                  </MenuItem>
                  <MenuModalItem
                    title={table.name}
                    onClick={() => onRemoveAnchor(() => deleteHyundaiCatalog({ id: table.id }))}
                  >
                    <Typography {...GlobalStyle.ErrorTypography}>Delete</Typography>
                  </MenuModalItem>
                </Menu>
                <Detail
                  id={table.id}
                  open={drawerDetail && menu == index}
                  onClose={() => setDrawerDetail(false)}
                />
                {dialogUpdate && menu == index && (
                  <Edit
                    id={table.id}
                    open={dialogUpdate && menu == index}
                    onClose={() => setDialogUpdate(false)}
                  />
                )}
              </TableCell>
              <TableCell>{table.trim || '-'}</TableCell>
              <TableCell>{table.year || '-'}</TableCell>
              <TableCell>{table.color || '-'}</TableCell>
            </TableRow>
          ))}
      </Content>

      {catalogList.isLoading && <Loading />}
      {drawerCreate && (
        <Create open={drawerCreate} carName={carName} onClose={() => setDrawerCreate(false)} />
      )}

      <Notification
        open={!catalogList.isLoading && !catalogList.isUninitialized && !catalogList.isSuccess}
        onClose={() => (catalogList.isError ? catalogList.reset() : location.reload())}
        isError={Boolean(catalogList.error) && catalogList.isError}
        message={GLOBAL.returnExceptionMessage(
          catalogList.isError,
          catalogList.error as ErrorProps
        )}
      />
      <Notification
        open={!deleteCatalog.isLoading && !deleteCatalog.isUninitialized}
        onClose={() => (deleteCatalog.isError ? deleteCatalog.reset() : location.reload())}
        isError={Boolean(deleteCatalog.error) && deleteCatalog.isError}
        message={GLOBAL.returnExceptionMessage(
          deleteCatalog.isError,
          deleteCatalog.error as ErrorProps
        )}
      />
      <Filter
        open={dialogFilter}
        title='Filter'
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
        onReset={() => {
          setFilter({
            year: '',
            carModel: ''
          })
          setPayload({
            start: 0,
            length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
            field: 'name,discontinued',
            search: { value: '' },
            discontinued: '',
            year: '',
            carModel: params.key || ''
          })
        }}
        onSubmit={() => {
          setPayload({
            ...payload,
            ...filter
          })
          onFilter(false)
        }}
      >
        <Box
          sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 4 }}
        >
          <Autocomplete
            sx={{ flex: 1 }}
            options={(model && model.data) || []}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) =>
              option && value ? option.name == value.name : false
            }
            onOpen={() => getCarModelDropdownList()}
            onChange={(_, model) => setFilter({ ...filter, carModel: model && String(model.id) })}
            value={
              (model &&
                model.data &&
                model.data.find((e) => String(e.id) == String(filter.carModel))) ||
              undefined
            }
            ListboxProps={GlobalStyle.ListBox}
            renderOption={(props, item) => (
              <li {...props} key={item.id}>
                {item.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                name='Mdel'
                label='Model'
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {model.isLoading && <CircularProgress color='inherit' size={20} />}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />

          <FormControl sx={{ flex: 1 }}>
            <InputLabel id='Year'>Year</InputLabel>
            <Select
              labelId='Year'
              id='Year'
              value={filter.year}
              label='Visibility'
              onChange={(event) => setFilter({ ...filter, year: event.target.value.toString() })}
            >
              {years.data &&
                years.isSuccess &&
                years.data.map((item, index) => (
                  <MenuItem value={item} key={index}>
                    {item}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
      </Filter>
    </>
  )
}

export default HyundaiCarCatalog
