import CONSTANT from 'modules/constant'
import type {
  DropdownListProps,
  PaginationProps,
  PromoCodeRedemptionDetailProps,
  PromoCodeRedemptionProps
} from 'modules/types'
import { API, download, headers } from './api'

const PromoCodeREDEMPTIONAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getListPromoCodeRedemption: builder.mutation<
      PaginationProps<PromoCodeRedemptionProps>,
      FormData
    >({
      query: (body) => ({
        url: CONSTANT.URL_PROMO_CODE_REDEMPTION_LIST,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    downloadPromoCodeRedemption: builder.mutation<
      PaginationProps<PromoCodeRedemptionProps>,
      FormData
    >({
      query: (body) => ({
        url: CONSTANT.URL_PROMO_CODE_REDEMPTION_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        responseHandler: download,
        body: body,
        formData: true
      })
    }),
    getDetailPromoCodeRedemption: builder.query<PromoCodeRedemptionDetailProps, number>({
      query: (id) => ({
        url: CONSTANT.URL_PROMO_CODE_REDEMPTION_DETAIL + id,
        method: 'GET',
        headers: headers()
      })
    }),
    getRedemptionStatus: builder.mutation<DropdownListProps[], void>({
      query: () => ({
        url: CONSTANT.URL_PROMO_CODE_REDEMPTION_DROPDOWN,
        method: 'GET',
        headers: headers()
      })
    })
  })
})

export const {
  useGetListPromoCodeRedemptionMutation,
  useDownloadPromoCodeRedemptionMutation,
  useGetRedemptionStatusMutation,

  useGetDetailPromoCodeRedemptionQuery
} = PromoCodeREDEMPTIONAPI
