import type { LoadingButtonProps } from '@mui/lab'
import type { ContainerProps, StackProps } from '@mui/material'

class DrawerStyle {
  static Container: ContainerProps = {
    maxWidth: false,
    disableGutters: true,
    sx: {
      display: 'flex',
      padding: 2,
      gap: '1rem',
      flexDirection: 'column'
    }
  }

  static Stack: StackProps = {
    direction: 'column',
    spacing: 2
  }

  static Submit: LoadingButtonProps = {
    sx: {
      alignSelf: 'end'
    }
  }
}

export default DrawerStyle
