import { isEmpty } from 'lodash'
import { Container, FormControlLabel, Grid, Switch, TextField, Typography } from '@mui/material'
import Drawer from 'components/drawer/detail'
import type { DetailStateProps } from 'modules/types'
import { useGetChargePointDetailQuery } from 'store/evChargePoint'
import Loading from 'components/loading/loading'
import DrawerStyle from './style'

const Detail = ({ open, id, onClose }: DetailStateProps & { id: number }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const { data, isFetching, isLoading } = useGetChargePointDetailQuery(id)

  const providerName = (data && data.providerName) || '-'
  const chargePointIdByPlugIt = (data && data.code) || '-'
  const chargePointIdByMyHyundai = (data && data.id) || '-'
  const chargePointName = (data && data.name) || '-'

  const gridDataLabelFirst = [
    'Provider Name',
    'Charge Point ID by PlugIt',
    'Charge Point ID by myHyundai',
    'Charge Point Name'
  ]
  const gridDataValueFirst = [
    providerName,
    chargePointIdByPlugIt,
    chargePointIdByMyHyundai,
    chargePointName
  ]

  const quota = (data && data.quota && data.quota.quota) || '-'
  const days = data && data.quota && data.quota.interval

  const gridDataLabelSecond = ['Quota', 'Days']
  const gridDataValueSecond = [quota, days]

  const kwhLimit = (data && data.kwhLimit) || '-'
  const partnerName = (data && data.partnerName) || '-'
  const ownerName = (data && data.ownerName) || '-'

  const gridDataLabelThird = ['kwh Limit', 'Partner', 'Owner Name']
  const gridDataValueThird = [kwhLimit, partnerName, ownerName]

  const latitude = (data && data.latitude) || '-'
  const longitude = (data && data.longitude) || '-'

  const gridDataLabelFourth = ['Latitude', 'Longitude']
  const gridDataValueFourth = [latitude, longitude]

  const streetAddress = (data && data.streetAddress) || '-'
  const country = (data && data.country) || '-'
  const city = (data && data.city) || '-'
  const postalCode = (data && data.postalCode) || '-'

  const gridDataLabelFifth = ['Street Address', 'Country', 'City', 'Postal Code']
  const gridDataValueFifth = [streetAddress, country, city, postalCode]

  const operationStartTime = (data && data.operationStartTime) || '-'
  const operationEndTime = (data && data.operationEndTime) || '-'

  const gridDataLabelSixth = ['Time From', 'Time Until']
  const gridDataValueSixth = [operationStartTime, operationEndTime]

  const place = (data && data.place) || '-'
  const floors = (data && data.floors) || '-'
  const policyName = (data && data.policyName) || '-'
  const voucherId = (data && data.voucherId) || '-'
  const access = (data && data.visibility) || '-'
  const payment = (data && data.payment) || '-'
  const status = data && !isEmpty(data.active) && data.active !== null ? data.active : '-'

  const gridDataLabelSeventh = [
    'place',
    'floors',
    'Policy Name',
    'Voucher ID',
    'access',
    'payment',
    'status'
  ]

  const gridDataValueSeventh = [place, floors, policyName, voucherId, access, payment, status]

  return (
    <Drawer open={open} title='EV Charge Point Detail' onClose={onClose}>
      <Container {...DrawerStyle.Container}>
        {(isLoading || isFetching) && !data && <Loading />}
        {data && (
          <>
            <FormControlLabel
              control={<Switch checked={data.operative} name='operative' />}
              label='Connectivity'
            />
            <Grid container spacing={2}>
              {gridDataValueFirst.map((item, index) => (
                <Grid key={index} item xs={12}>
                  <TextField
                    variant='outlined'
                    label={gridDataLabelFirst[index]}
                    value={item}
                    inputProps={{ readOnly: true }}
                    fullWidth
                  />
                </Grid>
              ))}
            </Grid>

            <Typography>Quota</Typography>

            <Grid container spacing={2}>
              {gridDataValueSecond.map((item, index) => (
                <Grid key={index} item xs={6}>
                  <TextField
                    variant='outlined'
                    label={gridDataLabelSecond[index]}
                    value={item}
                    inputProps={{ readOnly: true }}
                    fullWidth
                  />
                </Grid>
              ))}
            </Grid>

            <Typography sx={{ fontSize: '.75rem' }}>
              * Quota Field applicable for Policy A only.
            </Typography>

            <Typography>kWh Limit per Charging</Typography>

            <Grid container spacing={2}>
              {gridDataValueThird.map((item, index) => (
                <Grid key={index} item xs={12}>
                  <TextField
                    variant='outlined'
                    label={gridDataLabelThird[index]}
                    value={item}
                    inputProps={{ readOnly: true }}
                    fullWidth
                  />
                  {gridDataLabelThird[index] == 'kwh Limit' && (
                    <Typography sx={{ fontSize: '.75rem', marginY: 1 }}>
                      * kWh Limit per Charging Field applicable for Policy A and Policy C only.
                    </Typography>
                  )}
                </Grid>
              ))}
            </Grid>

            <Typography>Coordinates</Typography>

            <Grid container spacing={2}>
              {gridDataValueFourth.map((item, index) => (
                <Grid key={index} item xs={6}>
                  <TextField
                    variant='outlined'
                    label={gridDataLabelFourth[index]}
                    value={item}
                    inputProps={{ readOnly: true }}
                    fullWidth
                  />
                </Grid>
              ))}
            </Grid>

            <Grid container spacing={2}>
              {gridDataValueFifth.map((item, index) => (
                <Grid key={index} item xs={12}>
                  <TextField
                    variant='outlined'
                    label={gridDataLabelFifth[index]}
                    value={item}
                    inputProps={{ readOnly: true }}
                    fullWidth
                  />
                </Grid>
              ))}
            </Grid>

            <Typography>Operational Hour</Typography>

            <Grid container spacing={2}>
              {gridDataValueSixth.map((item, index) => (
                <Grid key={index} item xs={6}>
                  <TextField
                    variant='outlined'
                    label={gridDataLabelSixth[index]}
                    value={item}
                    inputProps={{ readOnly: true }}
                    fullWidth
                  />
                </Grid>
              ))}
            </Grid>

            <Grid container spacing={2}>
              {gridDataValueSeventh.map((item, index) => (
                <Grid key={index} item xs={12}>
                  <TextField
                    variant='outlined'
                    label={gridDataLabelSeventh[index]}
                    value={item}
                    inputProps={{ readOnly: true }}
                    fullWidth
                  />
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </Container>
    </Drawer>
  )
}

export default Detail
