import { useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
// import type { GridColDef } from '@mui/x-data-grid'
import Content from 'components/content/content'
import CONSTANT from 'modules/constant'
// import { useFilterEvPartnerMutation } from 'store/evPartner'
import type { PartialEvHistoryOverview } from 'modules/partial'
import { useGetEvHistoryOverviewMutation } from 'store/evHistory'
import Loading from 'components/loading/loading'
import { useGetChargePointDropdownListMutation } from 'store/evChargePoint'
import { useGetChargeBoxGroupDropdownListMutation } from 'store/evChargeBoxGroup'
// import Notification from 'components/notification'
// import GLOBAL from 'modules/global'
// import type { ErrorProps } from 'modules/types'
// import { useGetCityDropdownListMutation } from 'store/dropdown'
import GlobalStyle from 'modules/styles'
import AccessStyle from './style'

const EVHistoryOverview = () => {
  // City dan partner di hide permintaan user
  // const [getCityDropdownList, cityDropdown] = useGetCityDropdownListMutation()
  // const [getFilterEvPartner, filterEvPartner] = useFilterEvPartnerMutation()
  const [getChargePointDropdownList, chargePointDropdown] = useGetChargePointDropdownListMutation()
  const [getChargeBoxGroupDropdownList, chargeBoxGroupDropdown] =
    useGetChargeBoxGroupDropdownListMutation()
  const [getEvHistoryOverview, historyOverview] = useGetEvHistoryOverviewMutation()
  const [payload, setPayload] = useState<PartialEvHistoryOverview>({
    cityId: null,
    chargePointId: null,
    chargeBoxGroupId: null,
    partnerId: null,
    startDate: '',
    endDate: '',
    IsLiveStatus: 'true'
  })

  const [randomKey, setRandomKey] = useState<number>(0)
  const [startDate, setStartDate] = useState<Dayjs | null>(null)
  const [endDate, setEndDate] = useState<Dayjs | null>(null)

  const handleChangeStartDate = (date: Dayjs | null) => {
    const newDate = (!isEmpty(date) && date?.format('YYYY-MM-DD')) || ''
    setPayload({ ...payload, startDate: newDate, endDate: '' })
    setEndDate(null)

    setStartDate(date)
  }

  const handleChangeEndDate = (date: Dayjs | null) => {
    const newDate = (!isEmpty(date) && date?.format('YYYY-MM-DD')) || ''
    setPayload({ ...payload, endDate: newDate })
    setEndDate(date)
  }

  const handleButtonClick = () => {
    if (startDate && endDate) {
      const body: PartialEvHistoryOverview = {
        cityId: payload.cityId,
        chargePointId: payload.chargePointId,
        chargeBoxGroupId: payload.chargeBoxGroupId,
        partnerId: payload.partnerId,
        startDate: payload.startDate,
        endDate: payload.endDate,
        IsLiveStatus: 'true'
      }
      getEvHistoryOverview(body)
    }
  }

  const resetHandler = () => {
    setRandomKey(randomKey + 1)
    setPayload({
      cityId: null,
      chargePointId: null,
      chargeBoxGroupId: null,
      partnerId: null,
      startDate: '',
      endDate: '',
      IsLiveStatus: 'true'
    })
    setStartDate(null)
    setEndDate(null)
  }

  const disableBtn = () =>
    !(
      payload.chargePointId &&
      payload.chargePointId !== null &&
      payload.endDate &&
      payload.startDate
    )

  const totalTransaction =
    historyOverview.data &&
    historyOverview.data.transactionSummary &&
    historyOverview.data.transactionSummary.total

  const totalTimeTransaction =
    historyOverview.data &&
    historyOverview.data.transactionSummary &&
    historyOverview.data.transactionSummary.totalTime

  const totalKwhTransaction =
    historyOverview.data &&
    historyOverview.data.transactionSummary &&
    historyOverview.data.transactionSummary.totalKwh

  const busiestDayTransaction =
    historyOverview.data &&
    historyOverview.data.transactionSummary &&
    historyOverview.data.transactionSummary.busiestDay

  const totalReqTransaction =
    historyOverview.data &&
    historyOverview.data.transactionSummary &&
    historyOverview.data.transactionSummary.totalRequest

  const finishTransaction =
    historyOverview.data &&
    historyOverview.data.transactionSummary &&
    historyOverview.data.transactionSummary.finishedRequest

  const totalUsers =
    historyOverview.data &&
    historyOverview.data.userSummary &&
    historyOverview.data.userSummary.totalUsers

  const averageTimeUsers =
    historyOverview.data &&
    historyOverview.data.userSummary &&
    historyOverview.data.userSummary.averageTime

  const averageKwhUsers =
    historyOverview.data &&
    historyOverview.data.userSummary &&
    historyOverview.data.userSummary.averageKwh

  const busiestHourUsers =
    historyOverview.data &&
    historyOverview.data.userSummary &&
    historyOverview.data.userSummary.busiestHour

  const cancelledRequestUsers =
    historyOverview.data &&
    historyOverview.data.userSummary &&
    historyOverview.data.userSummary.cancelledRequest

  const rejectedRequestUsers =
    historyOverview.data &&
    historyOverview.data.userSummary &&
    historyOverview.data.userSummary.rejectedRequest

  const totalCharge =
    historyOverview.data &&
    historyOverview.data.chargeBoxSummary &&
    historyOverview.data.chargeBoxSummary.total

  const occupiedCharge =
    historyOverview.data &&
    historyOverview.data.chargeBoxSummary &&
    historyOverview.data.chargeBoxSummary.occupied

  const availableCharge =
    historyOverview.data &&
    historyOverview.data.chargeBoxSummary &&
    historyOverview.data.chargeBoxSummary.available

  const unavailableCharge =
    historyOverview.data &&
    historyOverview.data.chargeBoxSummary &&
    historyOverview.data.chargeBoxSummary.unavailable

  // const selectedColumns: GridColDef[] = [
  //   { field: 'id', headerName: 'Total', width: 96 },
  //   { field: 'totalTime', headerName: 'Total Time', width: 192 },
  //   { field: 'totalKwh', headerName: 'Total kWh', flex: 1 },
  //   { field: 'busiestDay', headerName: 'Busiest Day', flex: 1 },
  //   { field: 'req', headerName: 'Total Request', flex: 1 },
  //   { field: 'finish', headerName: 'Finish Request', flex: 1 }
  // ]

  const gridDataValueTransaction = [
    {
      id: Math.random(),
      totalTransaction: totalTransaction,
      totalTime: totalTimeTransaction,
      totalKwh: totalKwhTransaction,
      busiestDay: busiestDayTransaction,
      req: totalReqTransaction,
      finish: finishTransaction
    }
  ]

  // const selectedColumnUser: GridColDef[] = [
  //   { field: 'id', headerName: 'Total', width: 96 },
  //   { field: 'averageTime', headerName: 'Average Time', width: 192 },
  //   { field: 'averageKwh', headerName: 'Average kWh', flex: 1 },
  //   { field: 'busiestHour', headerName: 'Busiest Hour', flex: 1 },
  //   { field: 'cancel', headerName: 'Cancelled  Request', flex: 1 },
  //   { field: 'reject', headerName: 'Rejected Request', flex: 1 }
  // ]

  const gridDataValueUsers = [
    {
      id: Math.random(),
      totalUsers: totalUsers,
      averageTime: averageTimeUsers,
      averageKwh: averageKwhUsers,
      busiestHour: busiestHourUsers,
      cancel: cancelledRequestUsers,
      reject: rejectedRequestUsers
    }
  ]

  return (
    <>
      <Content title='Overview' useTable={false} isLoading={false}>
        <Box
          sx={{
            display: 'flex',
            gap: 10,
            flexDirection: 'row',
            justifyContent: 'space-between',
            flex: 1,
            overflowX: 'auto'
          }}
        >
          <Box sx={{ display: 'flex', width: '30%', gap: 2, flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', width: '100%', gap: 2, flexDirection: 'row' }}>
              {/* <Autocomplete
                key={'cityId-' + randomKey}
                sx={{ width: '100%' }}
                options={(cityDropdown && cityDropdown.data) || []}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.name == value.name : false
                }
                onOpen={() => getCityDropdownList()}
                onChange={(_, city) =>
                  setPayload({ ...payload, cityId: (city && city.id) || null })
                }
                value={
                  (cityDropdown &&
                    cityDropdown.data &&
                    cityDropdown.data.find((e) => e.id == payload.cityId)) ||
                  undefined
                }
                ListboxProps={GlobalStyle.ListBox}
                renderOption={(props, item) => (
                  <li {...props} key={item.id}>
                    {item.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='cityId'
                    label='City'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {cityDropdown.isLoading && <CircularProgress color='inherit' size={20} />}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
              /> */}

              <Autocomplete
                key={'ChargePointId-' + randomKey}
                sx={{ width: '100%' }}
                options={(chargePointDropdown && chargePointDropdown.data) || []}
                getOptionLabel={(option) => option.text}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.value == value.value : false
                }
                onOpen={() => getChargePointDropdownList(CONSTANT.DEFAULT_DROPDOWN_PAYLOAD)}
                onChange={(_, chargePoint) =>
                  setPayload({
                    ...payload,
                    chargePointId: (chargePoint && chargePoint.value) || null
                  })
                }
                value={
                  (chargePointDropdown &&
                    chargePointDropdown.data &&
                    chargePointDropdown.data.find(
                      (e) => String(e.value) == String(payload.chargePointId)
                    )) ||
                  undefined
                }
                ListboxProps={GlobalStyle.ListBox}
                renderOption={(props, item) => (
                  <li {...props} key={item.value}>
                    {item.text}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='ChargePointId'
                    label='Charge Point'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {chargePointDropdown.isLoading && (
                            <CircularProgress color='inherit' size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                <DatePicker
                  value={startDate}
                  sx={{ width: '100%' }}
                  onChange={handleChangeStartDate}
                  label='Start Date'
                  format='DD-MM-YYYY'
                />
              </LocalizationProvider>
            </Box>

            <Box sx={{ display: 'flex', gap: 2, flexDirection: 'row' }}>
              {/* <Autocomplete
                key={'partnerId-' + randomKey}
                sx={{ width: '100%' }}
                options={(filterEvPartner && filterEvPartner.data) || []}
                getOptionLabel={(option) => option.text}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.value == value.value : false
                }
                onOpen={() => getFilterEvPartner()}
                onChange={(_, partnerId) =>
                  setPayload({ ...payload, partnerId: (partnerId && partnerId.value) || null })
                }
                value={
                  (filterEvPartner &&
                    filterEvPartner.data &&
                    filterEvPartner.data.find(
                      (e) => String(e.value) == String(payload.partnerId)
                    )) ||
                  undefined
                }
                ListboxProps={GlobalStyle.ListBox}
                renderOption={(props, item) => (
                  <li {...props} key={item.value}>
                    {item.text}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='partnerId'
                    label='Partner'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {filterEvPartner.isLoading && (
                            <CircularProgress color='inherit' size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
              /> */}

              <Autocomplete
                key={payload.chargePointId}
                sx={{ width: '100%' }}
                options={(chargeBoxGroupDropdown && chargeBoxGroupDropdown.data) || []}
                getOptionLabel={(option) => option.text}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.value == value.value : false
                }
                onOpen={() =>
                  getChargeBoxGroupDropdownList({
                    ...CONSTANT.DEFAULT_DROPDOWN_PAYLOAD,
                    id: Number(payload.chargePointId)
                  })
                }
                onChange={(_, chargeBoxGroup) => {
                  if (chargeBoxGroup) {
                    setPayload({ ...payload, chargeBoxGroupId: chargeBoxGroup.value })
                  }
                }}
                value={
                  (chargeBoxGroupDropdown &&
                    chargeBoxGroupDropdown.data &&
                    chargeBoxGroupDropdown.data.find(
                      (e) => String(e.value) == String(payload.chargeBoxGroupId)
                    )) ||
                  undefined
                }
                ListboxProps={GlobalStyle.ListBox}
                renderOption={(props, item) => (
                  <li {...props} key={item.value}>
                    {item.text}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='ChargeBoxGroupId'
                    label='Charge Box Group ID'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {chargeBoxGroupDropdown.isLoading && (
                            <CircularProgress color='inherit' size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                <DatePicker
                  value={endDate}
                  minDate={dayjs(payload.startDate)}
                  onChange={handleChangeEndDate}
                  sx={{ width: '100%' }}
                  label='End Date'
                  format='DD-MM-YYYY'
                  disabled={isEmpty(payload.startDate)}
                />
              </LocalizationProvider>
            </Box>
            {/*
            <Box sx={{ display: 'flex', gap: 2, flexDirection: 'row' }}>
              <Autocomplete
                key={'ChargePointId-' + randomKey}
                sx={{ width: '100%' }}
                options={(chargePointDropdown && chargePointDropdown.data) || []}
                getOptionLabel={(option) => option.text}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.value == value.value : false
                }
                onOpen={() => getChargePointDropdownList(CONSTANT.DEFAULT_DROPDOWN_PAYLOAD)}
                onChange={(_, chargePoint) =>
                  setPayload({
                    ...payload,
                    chargePointId: (chargePoint && chargePoint.value) || null
                  })
                }
                value={
                  (chargePointDropdown &&
                    chargePointDropdown.data &&
                    chargePointDropdown.data.find(
                      (e) => String(e.value) == String(payload.chargePointId)
                    )) ||
                  undefined
                }
                ListboxProps={GlobalStyle.ListBox}
                renderOption={(props, item) => (
                  <li {...props} key={item.value}>
                    {item.text}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='ChargePointId'
                    label='Charge Point'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {chargePointDropdown.isLoading && (
                            <CircularProgress color='inherit' size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
              />
              <Autocomplete
                key={payload.chargePointId}
                sx={{ width: '100%' }}
                options={(chargeBoxGroupDropdown && chargeBoxGroupDropdown.data) || []}
                getOptionLabel={(option) => option.text}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.value == value.value : false
                }
                onOpen={() =>
                  getChargeBoxGroupDropdownList({
                    ...CONSTANT.DEFAULT_DROPDOWN_PAYLOAD,
                    id: Number(payload.chargePointId)
                  })
                }
                onChange={(_, chargeBoxGroup) => {
                  if (chargeBoxGroup) {
                    setPayload({ ...payload, chargeBoxGroupId: chargeBoxGroup.value })
                  }
                }}
                value={
                  (chargeBoxGroupDropdown &&
                    chargeBoxGroupDropdown.data &&
                    chargeBoxGroupDropdown.data.find(
                      (e) => String(e.value) == String(payload.chargeBoxGroupId)
                    )) ||
                  undefined
                }
                ListboxProps={GlobalStyle.ListBox}
                renderOption={(props, item) => (
                  <li {...props} key={item.value}>
                    {item.text}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='ChargeBoxGroupId'
                    label='Charge Box Group ID'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {chargeBoxGroupDropdown.isLoading && (
                            <CircularProgress color='inherit' size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
              />
            </Box> */}
            <Box sx={{ display: 'flex', gap: 2, flexDirection: 'row' }}>
              <Button variant='contained' sx={{ width: '50%' }} onClick={resetHandler}>
                Reset
              </Button>
              <Button
                variant='contained'
                onClick={handleButtonClick}
                sx={{ width: '50%' }}
                disabled={disableBtn()}
              >
                Apply
              </Button>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: 2, flexDirection: 'row' }}>
            <Box {...AccessStyle.Box}>
              <Typography color='white' variant='h4'>
                {totalCharge ?? '-'}
              </Typography>
              <Typography color='white'>Total Charge Box</Typography>
            </Box>

            <Box {...AccessStyle.Box}>
              <Typography color='white' variant='h4'>
                {occupiedCharge ?? '-'}
              </Typography>
              <Typography color='white'>Charge Box Occupied</Typography>
            </Box>
            <Box {...AccessStyle.Box}>
              <Typography color='white' variant='h4'>
                {availableCharge ?? '-'}
              </Typography>
              <Typography color='white'>Available Charge Box</Typography>
            </Box>
            <Box {...AccessStyle.Box}>
              <Typography color='white' variant='h4'>
                {unavailableCharge ?? '-'}
              </Typography>
              <Typography color='white'>Unavailable Charge</Typography>
            </Box>
          </Box>
        </Box>
        {historyOverview.isLoading && <Loading />}
        {historyOverview.isSuccess && historyOverview.data && (
          <Container {...AccessStyle.Container}>
            <Accordion elevation={0} disableGutters expanded>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                <Typography>Transaction Summary</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Container {...AccessStyle.Basic}>
                  <TableContainer component={Paper} sx={{ maxHeight: 512 }} variant='outlined'>
                    <Table aria-label='Data Table' stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell>Total</TableCell>
                          <TableCell>Total Time</TableCell>
                          <TableCell>Total kWh</TableCell>
                          <TableCell>Busiest Day</TableCell>
                          <TableCell>Total Request Partner</TableCell>
                          <TableCell>Finish Request Partner</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {gridDataValueTransaction.map((item) => (
                          <TableRow key={item.id}>
                            <TableCell>{item.totalTransaction}</TableCell>
                            <TableCell>{item.totalTime}</TableCell>
                            <TableCell>{item.totalKwh}</TableCell>
                            <TableCell>{item.busiestDay || '-'}</TableCell>
                            <TableCell>{item.req}</TableCell>
                            <TableCell>{item.finish}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Container>
              </AccordionDetails>
            </Accordion>
            <Accordion elevation={0} disableGutters expanded>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                <Typography>User Summary</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Container {...AccessStyle.Basic}>
                  <TableContainer component={Paper} sx={{ maxHeight: 512 }} variant='outlined'>
                    <Table aria-label='Data Table' stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell>Total</TableCell>
                          <TableCell>Average Time</TableCell>
                          <TableCell>Average kWh</TableCell>
                          <TableCell>Busiest Hour</TableCell>
                          <TableCell>Cancelled Request from User</TableCell>
                          <TableCell>Rejected Request Partner</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {gridDataValueUsers.map((item) => (
                          <TableRow key={item.id}>
                            <TableCell>{item.totalUsers}</TableCell>
                            <TableCell>{item.averageTime}</TableCell>
                            <TableCell>{item.averageKwh}</TableCell>
                            <TableCell>{item.busiestHour}</TableCell>
                            <TableCell>{item.cancel}</TableCell>
                            <TableCell>{item.reject}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Container>
              </AccordionDetails>
            </Accordion>
          </Container>
        )}
      </Content>
      {/* <Notification
        open={!historyOverview.isLoading && !historyOverview.isUninitialized}
        onClose={() => (historyOverview.isError ? historyOverview.reset() : null)}
        isError={Boolean(historyOverview.error) && historyOverview.isError}
        message={GLOBAL.returnExceptionMessage(
          historyOverview.isError,
          historyOverview.error as ErrorProps
        )}
      /> */}
    </>
  )
}

export default EVHistoryOverview
