import { Container, Grid, TextField } from '@mui/material'
import dayjs from 'dayjs'
import Drawer from 'components/drawer/detail'
import Loading from 'components/loading/loading'
import type { DetailStateProps } from 'modules/types'
import { useGetGiftRedemptionDetailQuery } from 'store/voucherGift'
import DrawerStyle from './style'

const Detail = ({ open, id, onClose }: DetailStateProps & { id: string | number }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const { data, isFetching, isLoading } = useGetGiftRedemptionDetailQuery(id)

  const arrDataLeft = [
    { value: 'voucherGiftName', label: 'Gift Voucher Campaign Name' },
    { value: 'voucherId', label: 'Voucher ID' },
    { value: 'voucherName', label: 'Voucher Name' },
    { value: 'partner', label: 'Partner' },
    { value: 'redeemedByName', label: 'Redeemed by Name' },
    { value: 'redeemedById', label: 'Redeemed by ID' },
    { value: 'tokenId', label: 'Token ID' },
    { value: 'tokenCode', label: 'Token Code' },
    { value: 'sendDate', label: 'Send Date', type: 'date' }
  ]

  const checkDate = (str: string) =>
    str.length > 0 ? dayjs(str).format('DD MMMM YYYY HH:mm') : '-'

  return (
    <>
      <Drawer open={open} title='Detail Gift Voucher Redemption' onClose={onClose}>
        <Container {...DrawerStyle.Container} maxWidth='lg'>
          {(isLoading || isFetching) && !data && <Loading />}
          {data && (
            <>
              {arrDataLeft.map((item, index) => (
                <Grid item xs={16} key={index}>
                  <TextField
                    key={index}
                    variant='outlined'
                    label={item.label}
                    value={
                      data && item?.type === 'date'
                        ? checkDate(data[item.value as never] || '')
                        : data[item.value as never]
                    }
                    inputProps={{ readOnly: true }}
                    fullWidth
                  />
                </Grid>
              ))}
            </>
          )}
        </Container>
      </Drawer>
    </>
  )
}

export default Detail
