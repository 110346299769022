import type { ReactNode} from 'react';
import { useState } from 'react'
import { MenuItem as Menu, Typography } from '@mui/material'
import type { MenuItemProps } from '@mui/material'
import Dialog from 'components/dialog/dialog'

type MenuModalItemProps = MenuItemProps & {
  title: string
  desc?: ReactNode
  loading?: boolean
  onClick?: () => void
}

const MenuModalItem = ({ title, loading, onClick, desc, ...props }: MenuModalItemProps) => {
  const text = title || 'change'
  const [open, setOpen] = useState<boolean>(false)


  return (
    <>
      <Menu {...props} onClick={() => onClick && setOpen(true)}>
        {props.children}
      </Menu>
      <Dialog
        title={text}
        submitText='Yes'
        open={open}
        loading={loading !== undefined ? loading : false}
        onCancel={() => setOpen(false)}
        onSubmit={onClick}
      >
       {!desc && <Typography>{`Are you sure want to ${text} this item?`}</Typography>}
       {desc && desc}
      </Dialog>
    </>
  )
}

export default MenuModalItem
