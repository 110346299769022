import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Box, Collapse, Fade, Typography } from '@mui/material'
import Menu from 'assets/menu.json'
import Icon from 'components/icon'
import SidebarStyle from './style'

const Sidebar = () => {
  const [hover, setHover] = useState<boolean>(false)

  const onMouseEvent = (state: boolean) => setHover(state)

  return (
    <Box {...SidebarStyle.Container}>
      <Collapse
        orientation='horizontal'
        in={hover}
        collapsedSize={80}
        onMouseEnter={() => onMouseEvent(true)}
        onMouseLeave={() => onMouseEvent(false)}
      >
        <Box {...SidebarStyle.Wrapper}>
          {Menu.map(({ icon, name, path, menu }, index) => (
            <Link {...SidebarStyle.Link} key={index} to={menu.length ? menu[0].path : path}>
              <Box {...SidebarStyle.Button}>
                <Box {...SidebarStyle.Icon}>
                  <Icon icon={icon} />
                </Box>
                <Fade in={hover}>
                  <Typography {...SidebarStyle.Title}>{name}</Typography>
                </Fade>
              </Box>
            </Link>
          ))}
        </Box>
      </Collapse>
    </Box>
  )
}

export default Sidebar
