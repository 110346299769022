import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Autocomplete,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import {
  useDeleteEvPartnerMutation,
  useDownloadEvPartnerMutation,
  useGetEvPartnerMutation
} from 'store/evPartner'
import type { PartialEvPartner } from 'modules/partial'
import CONSTANT from 'modules/constant'
import Icon from 'components/icon'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { ErrorProps } from 'modules/types'
import { useGetCityMutation } from 'store/region'
import MenuModalItem from 'components/menuItem/menuItem'
import GlobalStyle from 'modules/styles'
import Detail from './detail/detail'
import Create from './create/create'
import Edit from './edit/edit'

const EVPartner = () => {
  const [getEvPartner, evPartner] = useGetEvPartnerMutation()
  const [downloadEvPartner, download] = useDownloadEvPartnerMutation()
  const [deleteEvPartner, deletePart] = useDeleteEvPartnerMutation()
  const [getCity, city] = useGetCityMutation()
  const [dialogCreate, setDialogCreate] = useState<boolean>(false)
  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [dialogUpdate, setDialogUpdate] = useState<boolean>(false)
  const [filter, setFilter] = useState({
    CityId: ''
  })

  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [menu, setMenu] = useState<null | number>(null)
  const [payload, setPayload] = useState<PartialEvPartner>({
    Start: 0,
    Length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    Filter: '',
    Search: '',
    Sorting: '',
    CityId: ''
  })

  const onSearch = (value: string) => setPayload({ ...payload, Start: 0, Search: value })
  const onResync = () => {
    const formData = new FormData()

    formData.append('Start', payload.Start.toString())
    formData.append('Length', payload.Length.toString())

    if (!isEmpty(payload.Search)) formData.append('Search', payload.Search)
    if (!isEmpty(payload.Filter)) formData.append('Filter', payload.Filter)
    if (!isEmpty(payload.Sorting)) formData.append('Sorting', payload.Sorting)
    getEvPartner(formData)
  }

  const getFilterCity = () => {
    const formData = new FormData()
    const payloadGetCity = {
      start: 0,
      length: 539,
      field: CONSTANT.DEFAULT_LOCATION_DEALER_FIELD.join(',')
    }
    formData.append('start', payloadGetCity.start.toString())
    formData.append('length', payloadGetCity.length.toString())
    formData.append('field', payloadGetCity.field)
    getCity(formData)
  }

  useEffect(() => {
    onResync()
  }, [payload])

  const onChangePage = (value: number) => setPayload({ ...payload, Start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, Length: value })

  const onFilter = (state: boolean) => setDialogFilter(state)

  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('start', String(0))
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())

    if (!isEmpty(payload.Search)) formData.append('Search', payload.Search)
    if (!isEmpty(payload.Filter)) formData.append('Filter', payload.Filter)
    if (!isEmpty(payload.Sorting)) formData.append('Sorting', payload.Sorting)
    if (type && !isEmpty(type)) formData.append('Content-Type', type)

    downloadEvPartner(formData)
  }
  const onCreate = () => setDialogCreate(true)

  const tableHeadTitles = [
    'EV Charging Partner',
    'Partner Short Name',
    'Phone Number',
    'Email',
    'Charge Point',
    'City'
  ]

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const openAnchor = Boolean(anchor)

  const totalPage = evPartner.data && GLOBAL.tableTotalPage(evPartner.data.recordsTotal)

  const numbers = GLOBAL.tableNumber(payload.Start)

  return (
    <>
      <Content
        title='EV Partner Management System'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        onAdditional={onCreate}
        additionalTitle='Create New EV Partner'
        additionalPrefixIcon='Create'
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        page={payload.Start + 1}
        isLoading={evPartner.isLoading}
        totalRecords={evPartner.data && evPartner.data.recordsTotal}
        useDownloadDropdown
      >
        {evPartner.isSuccess &&
          evPartner.data &&
          evPartner.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.id}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.id}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.id}`}
                  aria-labelledby={`button-${index}-${table.id}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View
                  </MenuItem>
                  <MenuItem onClick={() => onRemoveAnchor(() => setDialogUpdate(true))}>
                    Edit
                  </MenuItem>

                  <MenuModalItem
                    title={table.name}
                    onClick={() => onRemoveAnchor(() => deleteEvPartner(table.id))}
                  >
                    <Typography {...GlobalStyle.ErrorTypography}>Delete</Typography>
                  </MenuModalItem>
                </Menu>
                <Detail
                  id={table.id}
                  open={drawerDetail && menu == index}
                  onClose={() => setDrawerDetail(false)}
                />
                {dialogUpdate && menu == index && (
                  <Edit
                    id={table.id}
                    open={dialogUpdate && menu == index}
                    onClose={() => setDialogUpdate(false)}
                  />
                )}
              </TableCell>
              <TableCell>{table.name || '-'}</TableCell>
              <TableCell>{table.shortName || '-'}</TableCell>
              <TableCell>{table.phoneNumber || '-'}</TableCell>
              <TableCell>{table.email || '-'}</TableCell>
              <TableCell>{table.streetAddress || '-'}</TableCell>
              <TableCell>{table.city || '-'}</TableCell>
            </TableRow>
          ))}
      </Content>

      {evPartner.isLoading && <Loading />}

      {dialogCreate && <Create open={dialogCreate} onClose={() => setDialogCreate(false)} />}

      <Notification
        open={!evPartner.isLoading && !evPartner.isUninitialized && !evPartner.isSuccess}
        onClose={() => (evPartner.isError ? evPartner.reset() : location.reload())}
        isError={Boolean(evPartner.error) && evPartner.isError}
        message={GLOBAL.returnExceptionMessage(evPartner.isError, evPartner.error as ErrorProps)}
      />
      <Notification
        open={!deletePart.isLoading && !deletePart.isUninitialized}
        onClose={() => (deletePart.isError ? deletePart.reset() : location.reload())}
        isError={Boolean(deletePart.error) && deletePart.isError}
        message={GLOBAL.returnExceptionMessage(deletePart.isError, deletePart.error as ErrorProps)}
      />
      <Filter
        open={dialogFilter}
        title='Filter'
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
        onReset={() => {
          setFilter({ CityId: '' })
          setPayload({
            Start: 0,
            Length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
            Filter: '',
            Search: '',
            Sorting: '',
            CityId: ''
          })
        }}
        onSubmit={() => {
          setPayload({ ...payload, CityId: filter.CityId })
          onFilter(false)
        }}
      >
        <Autocomplete
          options={(city.data && city.data.data) || []}
          getOptionLabel={(option) => option.city}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.cityId == value.cityId : false
          }
          onOpen={() => getFilterCity()}
          onChange={(_, city) => {
            if (city) {
              setFilter({ ...filter, CityId: city.cityId.toString() })
            }
          }}
          value={
            (city &&
              city.data &&
              city.data.data.find((e) => String(e.cityId) == String(filter.CityId))) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.cityId}>
              {item.city}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='City'
              label='City'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {city.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
      </Filter>
    </>
  )
}

export default EVPartner
