import type { LoadingButtonProps } from '@mui/lab'
import type { BoxProps, ContainerProps, StackProps, TypographyProps } from '@mui/material'

class DrawerStyle {
  static Container: ContainerProps = {
    maxWidth: false,
    disableGutters: true,
    sx: {
      display: 'flex',
      padding: 2,
      gap: '1rem',
      flexDirection: 'column'
    }
  }

  static Stack: StackProps = {
    direction: 'column',
    spacing: 2
  }

  static Title: TypographyProps = {
    fontWeight: 'bold',
    marginBottom: '.5rem'
  }

  static Image: BoxProps = {
    display: 'flex',
    width: '100%',
    minHeight: '4rem',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    borderRadius: 1,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    sx: {
      ':before': {
        content: '"Image Not Available"',
        position: 'absolute',
        zIndex: -1
      }
    }
  }

  static Submit: LoadingButtonProps = {
    sx: {
      alignSelf: 'end'
    }
  }
}

export default DrawerStyle
