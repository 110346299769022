import type { ContainerProps } from '@mui/material'

class DialogStyle {
  static Container: ContainerProps = {
    maxWidth: false,
    disableGutters: true,
    sx: {
      display: 'flex',
      gap: '1rem',
      paddingY: 1,
      flexDirection: 'column'
    }
  }
}

export default DialogStyle
