import type { ReturnProps } from 'modules/types'
import type { PartialChangePasswordProps } from 'modules/partial'
import CONSTANT from 'modules/constant'
import { API, headers } from './api'

const PasswordAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    updateChangePassword: builder.mutation<ReturnProps, PartialChangePasswordProps>({
      query: (body) => ({
        url: CONSTANT.URL_MENU_CHANGE_PASSWORD,
        method: 'POST',
        headers: headers(),
        body: body
      })
    })
  })
})

export const { useUpdateChangePasswordMutation } = PasswordAPI
