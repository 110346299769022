import CONSTANT from 'modules/constant'
import type {
  DropdownListProps,
  PaginationProps,
  ResultProps,
  VoucherUsageDetailProps,
  VoucherUsageListProps,
  VoucherUsageTokenDetailProps,
  VoucherUsageTokenListProps
} from 'modules/types'
import type { PartialVoucherUsageTokenStateProps } from 'modules/partial'
import { API, download, headers } from './api'

const voucherUsageAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getVoucherUsageList: builder.mutation<PaginationProps<VoucherUsageListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_USAGE_LIST,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    downloadVoucherUsageList: builder.mutation<PaginationProps<VoucherUsageListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_USAGE_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        responseHandler: download,
        body: body,
        formData: true
      })
    }),
    getVoucherUsageDetail: builder.query<VoucherUsageDetailProps, number>({
      query: (id) => ({
        url: CONSTANT.URL_VOUCHER_USAGE_DETAIL + id,
        method: 'GET',
        headers: headers()
      })
    }),
    getVoucherUsageStatusDropdown: builder.mutation<DropdownListProps[], void>({
      query: () => ({
        url: CONSTANT.URL_VOUCHER_USAGE_STATUS_DROPDOWN,
        method: 'GET',
        headers: headers()
      })
    }),
    getVoucherUsageDateTimeRangeDropdown: builder.mutation<DropdownListProps[], void>({
      query: () => ({
        url: CONSTANT.URL_VOUCHER_USAGE_DATE_TIME_RANGE_DROPDOWN,
        method: 'GET',
        headers: headers()
      })
    }),
    getVoucherUsageTokenList: builder.mutation<
      PaginationProps<VoucherUsageTokenListProps>,
      FormData
    >({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_USAGE_TOKEN_LIST,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    downloadVoucherUsageTokenList: builder.mutation<
      PaginationProps<VoucherUsageTokenListProps>,
      FormData
    >({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_USAGE_TOKEN_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        responseHandler: download,
        body: body,
        formData: true
      })
    }),
    getVoucherUsageTokenDetail: builder.query<VoucherUsageTokenDetailProps, number>({
      query: (id) => ({
        url: CONSTANT.URL_VOUCHER_USAGE_TOKEN_DETAIL + id,
        method: 'GET',
        headers: headers()
      })
    }),
    moveVoucherUsageToken: builder.mutation<ResultProps, PartialVoucherUsageTokenStateProps>({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_USAGE_TOKEN_MOVE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    revokeVoucherUsageToken: builder.mutation<ResultProps, PartialVoucherUsageTokenStateProps>({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_USAGE_TOKEN_REVOKE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    })
  })
})

export const {
  useGetVoucherUsageListMutation,
  useDownloadVoucherUsageListMutation,
  useGetVoucherUsageDetailQuery,
  useGetVoucherUsageStatusDropdownMutation,
  useGetVoucherUsageDateTimeRangeDropdownMutation,
  useGetVoucherUsageTokenListMutation,
  useDownloadVoucherUsageTokenListMutation,
  useGetVoucherUsageTokenDetailQuery,
  useMoveVoucherUsageTokenMutation,
  useRevokeVoucherUsageTokenMutation
} = voucherUsageAPI
