import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import Drawer from 'components/drawer/detail'
import Icon from 'components/icon'
import type { DetailStateProps } from 'modules/types'
import { useGetDetailTransactionEventQuery, useGetDetailTransactionLaborAccQuery } from 'store/serviceBookingEventTransaction'
import Loading from 'components/loading/loading'
import GLOBAL from 'modules/global'
import DialogStyle from './style'

const Detail = ({ open, id, onClose }: DetailStateProps & { id: number }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />
  const { data, isLoading } = useGetDetailTransactionEventQuery(id)
  const { data: dataLabor, isLoading: loadingLabor } = useGetDetailTransactionLaborAccQuery(id)

  const eventName = (data && data.eventName) || '-'
  const memberCode = (data && data.memberCode) || '-'
  const userPhone = (data && data.userPhone) || '-'
  const bookingCode = (data && data.bookingCode) || '-'
  const userNIK = (data && data.userNIK) || '-'
  const vin = (data && data.vin) || '-'
  const dealerName = (data && data.dealerName) || '-'
  const serviceFinishTimeEstimation = data && GLOBAL.formatDate(data.serviceFinishTimeEstimation)
  const contactPhone = (data && data.contactPhone) || '-'

  const repairType = (data && data.repairType) || '-'
  const userFullName = (data && data.userFullName) || '-'
  const dmsRepairId = (data && data.dmsRepairId) || '-'
  const vehicleModelName = (data && data.vehicleModelName) || '-'
  const vehicleLicensePlate = (data && data.vehicleLicensePlate) || '-'
  const serviceDate = data && GLOBAL.formatDate(data.serviceDate)
  const createdAt = data && GLOBAL.formatDateWithTime(data.createdAt)
  const contactName = (data && data.contactName) || '-'

  const subTotal = (data && data.subTotal) || 0
  const tax = (data && data.tax) || 0
  const grandTotal = (data && data.grandTotal) || 0
  const serviceStatus = (data && data.serviceStatus) || '-'

  const totalDiscountPrice = (data && data.totalDiscountPrice) || 0
  const appServiceFee = (data && data.appServiceFee) || 0
  const appointmentConfirmation = (data && data.appointmentConfirmation) || 'Not Confirmed'
  const dmsRepairStatus = (data && data.dmsRepairStatus) || '-'

  const gridDataLabel = [
    'Event',
    'Member ID',
    'Member Phone',
    'Booking code',
    'NIK',
    'VIN',
    'Dealer',
    'Booking Complete Estimation',
    'Contact Person - Phone',
  ]
  const gridDataValue = [
    eventName,
    memberCode,
    userPhone,
    bookingCode,
    userNIK,
    vin,
    dealerName,
    serviceFinishTimeEstimation,
    contactPhone
  ]

  const gridDataLabelRight = [
    'Service Package Name',
    'Member Name',
    'Repair ID',
    'Vehicle',
    'License Number',
    'Booking Service Schedule',
    'Created Date & Time',
    'Contact Person - Name'
  ]

  const gridDataValueRight = [
    repairType,
    userFullName,
    dmsRepairId,
    vehicleModelName,
    vehicleLicensePlate,
    serviceDate,
    createdAt,
    contactName,
  ]

  const gridDataLabelBottomLeft = [
    'Total Service Price',
    'Total Service Tax',
    'Grand Total Price',
    'Booking Status'
  ]
  const gridDataValueBottomLeft = [
    subTotal ? 'Rp' + GLOBAL.formatCurrency(Number(subTotal)) : 0,
    tax ? 'Rp' + GLOBAL.formatCurrency(Number(tax)) : 0,
    grandTotal ? 'Rp' + GLOBAL.formatCurrency(Number(grandTotal)) : 0,
    serviceStatus
  ]

  const gridDataLabelBottomRight = [
    'Total Promo Applied',
    'App Service Fee',
    'Appointment Confirmation',
    'DMS Repair Status'
  ]
  const gridDataValueBottomRight = [
    totalDiscountPrice ? 'Rp' + GLOBAL.formatCurrency(Number(totalDiscountPrice)) : 0,
    appServiceFee ? 'Rp' + GLOBAL.formatCurrency(Number(appServiceFee)) : 0,
    appointmentConfirmation,
    dmsRepairStatus
  ]

  return (
    <Dialog open={open} fullWidth maxWidth='md' onClose={onClose}>
      <DialogContent>
        <DialogTitle
          sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
        >
          <Typography variant='h6'>Details</Typography>
          <Box>
            <IconButton onClick={onClose}>
              <Icon icon='Close' />
            </IconButton>
          </Box>
        </DialogTitle>
        {isLoading && loadingLabor && <Loading />}
        {data && (
          <>
            <Box {...DialogStyle.wrapperBox}>
              <Box {...DialogStyle.wrapperLeftContent} sx={{ width: '50%', marginRight: '5px' }}>
                <Grid container spacing={2} sx={{ marginBottom: '10px' }}>
                  {gridDataValue.map((item, index) => (
                    <Grid key={index} item xs={12}>
                      <TextField
                        variant='outlined'
                        label={gridDataLabel[index]}
                        value={item}
                        inputProps={{ readOnly: true }}
                        fullWidth
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
              <Box {...DialogStyle.wrapperLeftContent} sx={{ width: '50%', marginLeft: '5px' }}>
                <Grid container spacing={2} sx={{ marginBottom: '10px' }}>
                  {gridDataValueRight.map((item, index) => (
                    <Grid key={index} item xs={12}>
                      {gridDataLabelRight[index] === 'Message Title' &&
                        gridDataLabelRight[index] === 'Message Body' ? (
                        <TextField
                          multiline
                          rows={2}
                          maxRows={4}
                          sx={{ width: '421px', height: '70px' }}
                          label={gridDataLabelRight[index]}
                          inputProps={{ readOnly: true }}
                          value={item}
                        />
                      ) : (
                        <TextField
                          variant='outlined'
                          label={gridDataLabelRight[index]}
                          value={item}
                          inputProps={{ readOnly: true }}
                          fullWidth
                        />
                      )}
                    </Grid>
                  ))}
                </Grid>
                <TableContainer
                  component={Paper}
                  sx={{ maxHeight: 512, marginBottom: '10px' }}
                  variant='outlined'
                >
                  <Table aria-label='Data Table' stickyHeader>
                    <TableHead>
                      <Typography {...{
                        marginTop: 1,
                        marginLeft: 2,
                        color: '#00000099',
                        fontSize: 12
                      }}
                      >Promo Applied</Typography>
                      <TableRow>
                        <TableCell style={{color: '#00000099', fontSize: 12}}>Accesssories</TableCell>
                        <TableCell style={{fontWeight: '500', fontSize: 15}}>{data.accessoryDiscountPercent ? 'Discount ' + data.accessoryDiscountPercent + '%' : '-'}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{color: '#00000099', fontSize: 12}}>Labor</TableCell>
                        <TableCell style={{fontWeight: '500', fontSize: 15}}>{data.labourDiscountPercent ? 'Discount ' + data.labourDiscountPercent + '%' : '-'}</TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
            <Typography style={{ marginTop: '25px', marginLeft: '80px' }}>Service List</Typography>
            <TableContainer
              component={Paper}
              sx={{ maxHeight: 512, width: '75%', marginBottom: '10px', marginLeft: '80px' }}
              variant='outlined'
            >
              <Table aria-label='Data Table' stickyHeader>
                <TableHead>
                  <Typography {...{
                    marginTop: 2,
                    marginLeft: 2,
                    fontWeight: 'bold',
                    color: '#002C5F'
                  }}>Labor Detail</Typography>
                  <TableRow>
                    <TableCell style={{ color: '#737373', fontWeight: 'bold' }}>Labor</TableCell>
                    <TableCell style={{ color: '#737373', fontWeight: 'bold' }}>Hour</TableCell>
                    <TableCell style={{ color: '#737373', fontWeight: 'bold' }}>Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataLabor && dataLabor.laborList &&
                    dataLabor.laborList.items.map((vale, index) => (
                      <TableRow key={index}>
                        <TableCell>{vale.itemName}</TableCell>
                        <TableCell>{vale.duration ? GLOBAL.timeConvert(vale.duration) : GLOBAL.timeConvert(0)}</TableCell>
                        <TableCell>{vale.price !== 0 ? 'Rp' + GLOBAL.formatCurrency(Number(vale.price)) : 'Rp0'}</TableCell>
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
              <Table aria-label='Data Table' stickyHeader>
                <TableHead>
                  <Typography {...{
                    marginTop: 2,
                    marginLeft: 2,
                    fontWeight: 'bold',
                    color: '#002C5F'
                  }}>Part Detail</Typography>
                  <TableRow>
                    <TableCell style={{ color: '#737373', fontWeight: 'bold' }}>Part</TableCell>
                    <TableCell style={{ color: '#737373', fontWeight: 'bold' }}>Qty</TableCell>
                    <TableCell style={{ color: '#737373', fontWeight: 'bold' }}>Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataLabor && dataLabor.partList &&
                    dataLabor.partList.items.map((vale, index) => (
                      <TableRow key={index}>
                        <TableCell>{vale.itemName}</TableCell>
                        <TableCell>{vale.quantity !== 0 ? vale.quantity + 'pcs' : '0pcs'}</TableCell>
                        <TableCell>{vale.price !== 0 ? 'Rp' + GLOBAL.formatCurrency(Number(vale.price)) : 'Rp0'}</TableCell>
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
              <Table aria-label='Data Table' stickyHeader>
                <TableHead>
                  <Typography {...{
                    marginTop: 2,
                    marginLeft: 2,
                    fontWeight: 'bold',
                    color: '#002C5F'
                  }}>Accessories Detail</Typography>
                  <TableRow>
                    <TableCell style={{ color: '#737373', fontWeight: 'bold' }}>Part</TableCell>
                    <TableCell style={{ color: '#737373', fontWeight: 'bold' }}>Qty</TableCell>
                    <TableCell style={{ color: '#737373', fontWeight: 'bold' }}>Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataLabor && dataLabor.accessoryList &&
                    dataLabor.accessoryList.items.map((vale, index) => (
                      <TableRow key={index}>
                        <TableCell>{vale.itemName}</TableCell>
                        <TableCell>{vale.quantity !== 0 ? vale.quantity + 'pcs' : '0pcs'}</TableCell>
                        <TableCell>{vale.price !== 0 ? 'Rp' + GLOBAL.formatCurrency(Number(vale.price)) : 'Rp0'}</TableCell>
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{ marginTop: 60 }}>
              <Box {...DialogStyle.wrapperBox2}>
                <Box {...DialogStyle.wrapperLeftContent} sx={{ width: '50%', marginRight: '5px' }}>
                  <Grid container spacing={2} sx={{ marginBottom: '10px' }}>
                    {gridDataValueBottomLeft.map((item, index) => (
                      <Grid key={index} item xs={12}>
                        <TextField
                          variant='outlined'
                          label={gridDataLabelBottomLeft[index]}
                          value={item}
                          inputProps={{ readOnly: true }}
                          fullWidth
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
                <Box {...DialogStyle.wrapperLeftContent} sx={{ width: '50%', marginLeft: '5px' }}>
                  <Grid container spacing={2} sx={{ marginBottom: '10px' }}>
                    {gridDataValueBottomRight.map((item, index) => (
                      <Grid key={index} item xs={12}>
                        {gridDataLabelBottomRight[index] === 'Message Title' &&
                          gridDataLabelBottomRight[index] === 'Message Body' ? (
                          <TextField
                            multiline
                            rows={2}
                            maxRows={4}
                            sx={{ width: '421px', height: '70px' }}
                            label={gridDataLabelBottomRight[index]}
                            inputProps={{ readOnly: true }}
                            value={item}
                          />
                        ) : (
                          <TextField
                            variant='outlined'
                            label={gridDataLabelBottomRight[index]}
                            value={item}
                            inputProps={{ readOnly: true }}
                            fullWidth
                          />
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
            </div>

          </>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default Detail
