import CONSTANT from 'modules/constant'
import type {
  DropdownCategoryListProps,
  DropdownGiftAssignProps,
  GiftRedemptionDetailProps,
  PaginationProps,
  ResultProps,
  VoucherGiftDetailProps,
  VoucherGiftListProps,
  VoucherGiftRedemptionListProps
} from 'modules/types'
import type { PayloadVoucherGiftProps } from 'modules/partial'
import { API, download, headers } from './api'

const VoucherGiftAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getListVoucherGift: builder.mutation<PaginationProps<VoucherGiftListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_GIFT,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body
      })
    }),
    getVoucherGiftDetail: builder.query<VoucherGiftDetailProps, number | string>({
      query: (Id) => ({
        url: CONSTANT.URL_VOUCHER_GIFT_DETAIL + Id,
        method: 'GET',
        headers: headers()
      })
    }),
    createVoucherGift: builder.mutation<ResultProps, PayloadVoucherGiftProps>({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_GIFT_CREATE,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body
      })
    }),
    deleteVoucherGift: builder.mutation<ResultProps, { Id: number | string }>({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_GIFT_DELETE + body.Id,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    downloadVoucherGift: builder.mutation<PaginationProps<VoucherGiftListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_GIFT_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        responseHandler: download,
        body: body,
        formData: true
      })
    }),
    updateVoucherGift: builder.mutation<ResultProps, PayloadVoucherGiftProps>({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_GIFT_UPDATE,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body
      })
    }),
    downloadTemplateGift: builder.mutation<PaginationProps<VoucherGiftListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_GIFT_TEMPLATE,
        method: 'POST',
        headers: headers({ useContentType: false }),
        responseHandler: download,
        body: body
      })
    }),
    uploadVoucherGift: builder.mutation<ResultProps, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_GIFT_UPLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body
      })
    }),
    getVoucherGiftDropdown: builder.mutation<DropdownCategoryListProps[], void>({
      query: () => ({
        url: CONSTANT.URL_VOUCHER_GIFT_DROPDOWN,
        method: 'GET',
        headers: headers()
      })
    }),
    getGiftAssignToDropdown: builder.mutation<DropdownGiftAssignProps[], void>({
      query: () => ({
        url: CONSTANT.URL_VOUCHER_ASSIGNTO_DROPDOWN,
        method: 'GET',
        headers: headers()
      })
    }),
    getGiftAssignByDropdown: builder.mutation<DropdownGiftAssignProps[], void>({
      query: () => ({
        url: CONSTANT.URL_VOUCHER_ASSIGNBY_DROPDOWN,
        method: 'GET',
        headers: headers()
      })
    }),
    sendVoucherGift: builder.mutation<ResultProps, { data: [] }>({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_GIFT_SEND,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    getListVoucherGiftRedemption: builder.mutation<
      PaginationProps<VoucherGiftRedemptionListProps>,
      FormData
    >({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_GIFT_REDEMPTION,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body
      })
    }),
    downloadGiftRedemption: builder.mutation<
      PaginationProps<VoucherGiftRedemptionListProps>,
      FormData
    >({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_REDEMPTION_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        responseHandler: download,
        body: body,
        formData: true
      })
    }),
    getVoucherRedemptionDropdown: builder.mutation<DropdownCategoryListProps[], void>({
      query: () => ({
        url: CONSTANT.URL_VOUCHER_REDEMPTION_DROPDOWN,
        method: 'GET',
        headers: headers()
      })
    }),
    getGiftRedemptionDropdown: builder.mutation<DropdownCategoryListProps[], void>({
      query: () => ({
        url: CONSTANT.URL_REDEMPTION_GIFT_DROPDOWN,
        method: 'GET',
        headers: headers()
      })
    }),
    getRedemptionRedeemDropdown: builder.mutation<DropdownGiftAssignProps[], void>({
      query: () => ({
        url: CONSTANT.URL_REDEMPTION_REDEEMED_DROPDOWN,
        method: 'GET',
        headers: headers()
      })
    }),
    getGiftRedemptionDetail: builder.query<GiftRedemptionDetailProps, number | string>({
      query: (Id) => ({
        url: CONSTANT.URL_GIFT_REDEMPTION_DETAIL + Id,
        method: 'GET',
        headers: headers()
      })
    })
  })
})

export const {
  useGetListVoucherGiftMutation,
  useGetVoucherGiftDetailQuery,
  useCreateVoucherGiftMutation,
  useDownloadVoucherGiftMutation,
  useDownloadTemplateGiftMutation,
  useUploadVoucherGiftMutation,
  useUpdateVoucherGiftMutation,
  useDeleteVoucherGiftMutation,
  useGetVoucherGiftDropdownMutation,
  useGetGiftAssignToDropdownMutation,
  useGetGiftAssignByDropdownMutation,
  useSendVoucherGiftMutation,
  useGetListVoucherGiftRedemptionMutation,
  useDownloadGiftRedemptionMutation,
  useGetGiftRedemptionDropdownMutation,
  useGetRedemptionRedeemDropdownMutation,
  useGetVoucherRedemptionDropdownMutation,
  useGetGiftRedemptionDetailQuery
} = VoucherGiftAPI
