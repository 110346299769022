import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
  Chip,
  FormControl,
  InputLabel,
  Select,
  Typography,
  Autocomplete,
  TextField,
  CircularProgress
} from '@mui/material'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import Icon from 'components/icon'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import CONSTANT from 'modules/constant'
import GLOBAL from 'modules/global'
import GlobalStyle from 'modules/styles'
import {
  useDeleteTermsAndConditionMutation,
  useDownloadTermsAndConditionMutation,
  useEnableTermsAndConditionMutation,
  useGetTermsAndConditionDropdownMutation,
  useGetTermsAndConditionMutation
} from 'store/terms'
import type { PartialTermsProps } from 'modules/partial'
import type { ErrorProps } from 'modules/types'
import MenuModalItem from 'components/menuItem/menuItem'
import Create from './create/create'
import Detail from './detail/detail'
import Edit from './edit/edit'

const TermsAndCondition = () => {
  const [getTermsAndCondition, termsCondition] = useGetTermsAndConditionMutation()
  const [getTermsAndConditionDropdown, dropdown] = useGetTermsAndConditionDropdownMutation()
  const [deleteTermsAndCondition, deleteTerms] = useDeleteTermsAndConditionMutation()
  const [enableTermsAndCondition, enableTerms] = useEnableTermsAndConditionMutation()
  const [downloadTermsAndCondition, download] = useDownloadTermsAndConditionMutation()

  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [dialogCreate, setDialogCreate] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [dialogUpdate, setDialogUpdate] = useState<boolean>(false)
  const [menu, setMenu] = useState<null | number>(null)

  const [filter, setFilter] = useState({
    key: '',
    language: '',
    status: ''
  })

  const [payload, setPayload] = useState<PartialTermsProps>({
    start: 0,
    length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    field: CONSTANT.DEFAULT_HTML_FIELD.join(','),
    search: { value: '' },
    language: '',
    status: '',
    key: ''
  })

  const onFilter = (state: boolean) => setDialogFilter(state)
  const onSearch = (value: string) => setPayload({ ...payload, start: 0, search: { value } })

  const onResync = () => {
    const formData = new FormData()

    formData.append('start', payload.start.toString())
    formData.append('length', payload.length.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(payload.language)) formData.append('language', payload.language)
    if (!isEmpty(payload.status)) formData.append('status', payload.status)
    if (!isEmpty(payload.key)) formData.append('key', payload.key)

    getTermsAndCondition(formData)
  }

  const onCreate = () => setDialogCreate(true)

  const onChangePage = (value: number) => setPayload({ ...payload, start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, length: value })

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('start', String(0))
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(payload.language)) formData.append('language', payload.language)
    if (!isEmpty(payload.status)) formData.append('status', payload.status)
    if (!isEmpty(payload.key)) formData.append('key', payload.key)
    if (type && !isEmpty(type)) formData.append('Download-Type', type)

    downloadTermsAndCondition(formData)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const tableHeadTitles = ['Language', 'Terms and Condition Name', 'Status']

  const openAnchor = Boolean(anchor)

  const totalPage = termsCondition.data && GLOBAL.tableTotalPage(termsCondition.data.recordsTotal)

  const numbers = GLOBAL.tableNumber(payload.start)

  useEffect(() => {
    onResync()
  }, [payload])

  return (
    <>
      <Content
        title='Terms And Condition'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        onAdditional={onCreate}
        additionalTitle='Create New Terms And Condition'
        additionalPrefixIcon='Create'
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={termsCondition.data && termsCondition.data.recordsTotal}
        page={payload.start + 1}
        isLoading={termsCondition.isLoading}
        useDownloadDropdown
      >
        {termsCondition.isSuccess &&
          termsCondition.data &&
          termsCondition.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.key}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.key}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.key}`}
                  aria-labelledby={`button-${index}-${table.key}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View
                  </MenuItem>
                  <MenuItem onClick={() => onRemoveAnchor(() => setDialogUpdate(true))}>
                    Edit
                  </MenuItem>
                  <MenuModalItem
                    title={table.status == 'Enabled' ? 'Disable' : 'Enable'}
                    onClick={() =>
                      onRemoveAnchor(() =>
                        enableTermsAndCondition({
                          key: table.key || '',
                          status: table.status == 'Enabled' ? 'Disabled' : 'Enabled'
                        })
                      )
                    }
                  >
                    {table.status == 'Enabled' ? 'Disable' : 'Enable'}
                  </MenuModalItem>
                  <MenuModalItem
                    title='Delete'
                    loading={deleteTerms.isLoading}
                    onClick={() =>
                      onRemoveAnchor(() => deleteTermsAndCondition({ key: table.key }))
                    }
                  >
                    <Typography {...GlobalStyle.ErrorTypography}>Delete</Typography>
                  </MenuModalItem>
                </Menu>
                <Detail
                  id={table.key}
                  open={drawerDetail && menu == index}
                  onClose={() => setDrawerDetail(false)}
                />
                <Edit
                  id={table.key}
                  open={dialogUpdate && menu == index}
                  onClose={() => setDialogUpdate(false)}
                />
              </TableCell>
              <TableCell>{table.language || '-'}</TableCell>
              <TableCell {...GlobalStyle.EllipsisTable}>{table.key || '-'}</TableCell>
              <TableCell>
                <Chip
                  label={table.status}
                  color={table.status == 'Enabled' ? 'success' : 'error'}
                />
              </TableCell>
            </TableRow>
          ))}
      </Content>

      {termsCondition.isLoading && <Loading />}

      <Create open={dialogCreate} onClose={() => setDialogCreate(false)} />

      <Filter
        open={dialogFilter}
        title='Filter'
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        onReset={() => {
          setFilter({
            key: '',
            status: '',
            language: ''
          })
          setPayload({
            ...payload,
            start: 0,
            key: '',
            status: '',
            language: ''
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({ ...payload, start: 0, ...filter })
          onFilter(false)
        }}
      >
        <Autocomplete
          options={(dropdown && dropdown.data) || []}
          getOptionLabel={(option) => option.key}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.id == value.id : false
          }
          onOpen={() => getTermsAndConditionDropdown()}
          onChange={(_, dropdown) =>
            setFilter({
              ...filter,
              key: (dropdown && dropdown.key) || ''
            })
          }
          value={
            (dropdown && dropdown.data && dropdown.data.find((e) => e.key == filter.key)) || null
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.id}>
              {item.key}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='key'
              label='Terms and Condition Name'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {dropdown.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <FormControl fullWidth>
          <InputLabel id='select-label'>Status</InputLabel>
          <Select
            labelId='select-label'
            id='id-select-label'
            value={filter.status}
            label='Status'
            onChange={(event) => setFilter({ ...filter, status: event.target.value })}
          >
            <MenuItem value='Enabled'>Enabled</MenuItem>
            <MenuItem value='Disabled'>Disabled</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id='select-label'>Language</InputLabel>
          <Select
            labelId='select-label'
            id='id-select-label'
            value={filter.language}
            label='Language'
            onChange={(event) => setFilter({ ...filter, language: event.target.value })}
          >
            <MenuItem value='id'>ID</MenuItem>
            <MenuItem value='en-us'>EN-US</MenuItem>
          </Select>
        </FormControl>
      </Filter>

      <Notification
        open={
          !termsCondition.isLoading && !termsCondition.isUninitialized && !termsCondition.isSuccess
        }
        onClose={() => (termsCondition.isError ? termsCondition.reset() : location.reload())}
        isError={Boolean(termsCondition.error) && termsCondition.isError}
        message={GLOBAL.returnExceptionMessage(
          termsCondition.isError,
          termsCondition.error as ErrorProps
        )}
      />

      <Notification
        open={!deleteTerms.isLoading && !deleteTerms.isUninitialized}
        onClose={() => (deleteTerms.isError ? deleteTerms.reset() : location.reload())}
        isError={Boolean(deleteTerms.error) && deleteTerms.isError}
        message={GLOBAL.returnExceptionMessage(
          deleteTerms.isError,
          deleteTerms.error as ErrorProps
        )}
      />

      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />

      <Notification
        open={!enableTerms.isLoading && !enableTerms.isUninitialized}
        onClose={() => (enableTerms.isError ? enableTerms.reset() : location.reload())}
        isError={Boolean(enableTerms.error) && enableTerms.isError}
        message={GLOBAL.returnExceptionMessage(
          enableTerms.isError,
          enableTerms.error as ErrorProps
        )}
      />
    </>
  )
}

export default TermsAndCondition
