import { Checkbox, Container, FormControlLabel, TextField, Typography } from '@mui/material'
import Drawer from 'components/drawer/detail'
import Loading from 'components/loading/loading'
import type { DetailStateProps } from 'modules/types'
import { useGetPolicyDetailQuery } from 'store/catalogPolicy'
import DrawerStyle from './style'

const Detail = ({ open, id, onClose }: DetailStateProps & { id: number }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const { data, isFetching, isLoading } = useGetPolicyDetailQuery(id)
  const policyName = (data && data.policyName) || '-'
  const isQuota = data && data.isQuota
  const isToken = data && data.isToken
  const isRequestByPartner = data && data.isRequestByPartner
  const isKwhLimit = data && data.isKwhLimit
  const remarks = (data && data.remark) || '-'

  return (
    <>
      <Drawer open={open} title='Detail Charger Policy' onClose={onClose}>
        <Container {...DrawerStyle.Container}>
          {(isLoading || isFetching) && !data && <Loading />}
          {data && (
            <>
              <TextField
                id='policyName'
                variant='outlined'
                label='Policy Name'
                value={policyName}
                inputProps={{ readOnly: true }}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
              <Typography>Condition</Typography>

              <FormControlLabel
                required
                control={<Checkbox id='isQuota' defaultChecked={isQuota} checked={isQuota} />}
                label='Quota'
              />
              <FormControlLabel
                required
                control={<Checkbox id='isToken' defaultChecked={isToken} checked={isToken} />}
                label='Pay As You Go'
              />
              <FormControlLabel
                required
                control={
                  <Checkbox
                    id='isRequestByPartner'
                    defaultChecked={isRequestByPartner}
                    checked={isRequestByPartner}
                  />
                }
                label='Request/Authorization by Partner'
              />
              <FormControlLabel
                required
                control={
                  <Checkbox id='isKwhLimit' defaultChecked={isKwhLimit} checked={isKwhLimit} />
                }
                label='Kwh Limit'
              />
              <TextField
                id='remarks'
                variant='outlined'
                label='Charger Policy Description'
                value={remarks}
                inputProps={{ readOnly: true }}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
            </>
          )}
        </Container>
      </Drawer>
    </>
  )
}

export default Detail
