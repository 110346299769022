import { useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { Alert, Button, Container, TextField } from '@mui/material'
import TagsInput from 'react-tagsinput'
import Dialog from 'components/dialog/dialog'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import { useGetListUserByMemberCodeMutation, useSubmitCoinSubmissionMutation } from 'store/coin'
import type { PartialNewSubmissionCoinProps, PartialNewSubmissionProps } from 'modules/partial'
import type { DetailStateProps, ErrorProps, MemberCoinProps } from 'modules/types'
import DialogStyle from './style'

import 'react-tagsinput/react-tagsinput.css'
import 'react-quill/dist/quill.snow.css'

const Create = ({ open, onClose }: DetailStateProps) => {
  const [tags, setTags] = useState<string[]>([])
  const [isValidated, setIsValidated] = useState(false)
  const [validMember, setValidMember] = useState<MemberCoinProps[]>([])
  const [invalidMember, setInValidMember] = useState<MemberCoinProps[]>([])

  const [validateMember, validate] = useGetListUserByMemberCodeMutation()
  const [submitCoin, create] = useSubmitCoinSubmissionMutation()

  const handleTagChange = (tags: Array<string>) => {
    const tag = tags
    const dTags = tag.map((d: string)=> d.replace('\n', ''))
    console.log('tags',dTags)
    setTags(dTags)
  }

  const defaultPasteSplit = (data: string) => {
    const enterCharCode = String.fromCharCode(13);

    if (data.includes(enterCharCode)) {
      return data.split(enterCharCode);
    }

    const separators = [',', ';', '\\(', '\\)', '\\*', '/', ':', '\\?', '\n'];
    return data.split(new RegExp(separators.join('|'), 'g')).map(d => d.trim());
  }
  
  const scheme = yup.object({
    amount: yup
          .number()
          .positive()
          .typeError('Amount must be a number')
          .required('Amount is required'),
    label: yup.string().required()
  })

  const formik = useFormik<PartialNewSubmissionProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      amount: 0,
      label: ''
    },
    onSubmit: (values: PartialNewSubmissionProps) => {
      const payload: PartialNewSubmissionCoinProps = {
        "Amount" : values.amount,
        "ValidUntil" : null,
        "Label" : values.label,
        "SendNotification": true,
        "Users" : validMember
      }
      submitCoin(payload)
    }
  })

  const errorData = formik.errors

  return (
    <>
      <Dialog
        title='Create Coin Submission'
        open={open}
        onCancel={() => {
          onClose()
          setIsValidated(false)
          setValidMember([])
          setInValidMember([])
          setTags([])
        }}
        onSubmit={() => formik.handleSubmit()}
        loading={create.isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          <label>Member Code</label>
          <TagsInput disabled={isValidated} inputProps={{placeholder: 'Add New'}} pasteSplit={defaultPasteSplit} value={tags} onChange={handleTagChange} addOnPaste={true} />

          <Button disabled={isValidated} variant='contained' onClick={() => {
            validateMember(tags)
              .unwrap()
              .then((res) => {
                setValidMember(res.filter(data => data.isValid))
                setInValidMember(res.filter(data => !data.isValid))
                setIsValidated(true)
              })
              .catch(() => setIsValidated(false))
          }}>
            Validate User
          </Button>

          {isValidated && (
            <>
              <Alert severity={'success'}>{validMember.length} Valid Member Will be Added</Alert>
              <Alert severity={'error'}>{invalidMember.length} Invalid Member Will be Ignored</Alert>
            </>
          )}
          
          <FormikProvider value={formik}>
            <TextField
              id={`amount`}
              variant='outlined'
              label='Amount'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.amount &&
                errorData &&
                Boolean(errorData.amount)
              }
              helperText={
                formik.touched.amount &&
                errorData &&
                errorData.amount
              }
              fullWidth
              disabled={!isValidated || !validMember.length}
            />
            <TextField
              disabled={!isValidated || !validMember.length}
              id={`label`}
              variant='outlined'
              label='Label'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.label &&
                errorData &&
                Boolean(errorData.label)
              }
              helperText={
                formik.touched.label &&
                errorData &&
                errorData.label
              }
              fullWidth
            />
          </FormikProvider>
        </Container>
      </Dialog>

      <Notification
        open={!create.isLoading && !create.isUninitialized}
        onClose={() => (create.isError ? create.reset() : location.reload())}
        isError={Boolean(create.error) && create.isError}
        message={GLOBAL.returnExceptionMessage(create.isError, create.error as ErrorProps)}
      />

      <Notification
        open={!validate.isLoading && !validate.isUninitialized}
        onClose={() => validate.reset()}
        isError={Boolean(validate.error) && validate.isError}
        message={GLOBAL.returnExceptionMessage(validate.isError, validate.error as ErrorProps)}
      />


    </>
  )
}

export default Create
