import CONSTANT from 'modules/constant'
import type { MenuPermissionProps, MenuTreeProps } from 'modules/types'
import { API, headers } from './api'

const menuAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getMenuTree: builder.query<MenuTreeProps, void>({
      query: () => ({
        url: CONSTANT.URL_MENU_TREE,
        method: 'GET',
        headers: headers()
      })
    }),
    getMenuPermission: builder.query<MenuPermissionProps, void>({
      query: () => ({
        url: CONSTANT.URL_MENU_PERMISSION,
        method: 'GET',
        headers: headers()
      })
    })
  })
})

export const { useGetMenuTreeQuery, useGetMenuPermissionQuery } = menuAPI
