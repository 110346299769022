/* eslint-disable @typescript-eslint/no-explicit-any */

import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { Autocomplete, CircularProgress, Container, TextField } from '@mui/material'
import Dialog from 'components/dialog/dialog'
import Loading from 'components/loading/loading'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import type { PayloadTokenCategoryProps, PayloadVoucherCategoryProps } from 'modules/partial'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import GlobalStyle from 'modules/styles'
import {
  useGetTokenCategoryDetailQuery,
  useGetTokenPartnerDropdownMutation,
  useUpdateTokenCategoryMutation
} from 'store/token'
import DialogStyle from './style'

const Edit = ({ open, id, onClose }: DetailStateProps & { id: string | number }) => {
  if (!open) return <></>

  const { data, isFetching, isLoading } = useGetTokenCategoryDetailQuery(id)
  const [updateTokenCategory, update] = useUpdateTokenCategoryMutation()
  const [getTokenPartnerDropdown, dropdownPartner] = useGetTokenPartnerDropdownMutation()

  const scheme = yup.object<PayloadVoucherCategoryProps>({
    nameEn: yup.string().required('Token Category Name English is required'),
    companyId: yup.string().required('Partner Category  is required'),
    monetaryValue: yup.number().required('Monetary Value  is required')
  })

  const formik = useFormik<PayloadTokenCategoryProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      companyId: (data && data?.companyId) || '',
      nameEn: (data && data?.nameEn) || '',
      monetaryValue: (data && data?.monetaryValue) || 0
    },
    onSubmit: (values: PayloadTokenCategoryProps) => handleSubmit(values)
  })

  const handleSubmit = (e: PayloadTokenCategoryProps) => {
    const payload: any = {
      Id: id,
      companyId: e.companyId,
      nameEn: e.nameEn,
      description: '',
      monetaryValue: e.monetaryValue
    }
    updateTokenCategory(payload)
  }

  return (
    <>
      <Dialog
        open={open}
        title='Update Token Category'
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          {(isLoading || isFetching) && !data && <Loading />}
          {data && (
            <>
              <FormikProvider value={formik}>
                <TextField
                  id='nameEn'
                  variant='outlined'
                  label='Token Category Name EN-US'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.nameEn}
                  error={formik.touched && formik.touched.nameEn && Boolean(formik.errors.nameEn)}
                  helperText={
                    formik.touched && formik.touched.nameEn && formik.errors && formik.errors.nameEn
                  }
                  fullWidth
                />

                <Autocomplete
                  options={dropdownPartner.data || []}
                  getOptionLabel={(list) => list.name}
                  isOptionEqualToValue={(option, value) =>
                    option && value ? option.id == value.id : false
                  }
                  onOpen={() => getTokenPartnerDropdown()}
                  onChange={(_, id) => formik.setFieldValue('companyId', id && id.id)}
                  ListboxProps={GlobalStyle.ListBox}
                  defaultValue={{
                    id: data.companyId,
                    name: data.companyName,
                    key: '',
                    language: ''
                  }}
                  renderOption={(props, item) => (
                    <li {...props} key={item.id}>
                      {item.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name='companyId'
                      label='Select A Partner'
                      error={
                        formik.touched &&
                        formik.touched.companyId &&
                        Boolean(formik.errors.companyId)
                      }
                      helperText={
                        formik.touched &&
                        formik.touched.companyId &&
                        formik.errors &&
                        formik.errors.companyId
                      }
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {dropdownPartner.isLoading && (
                              <CircularProgress color='inherit' size={20} />
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                />

                <TextField
                  id='monetaryValue'
                  variant='outlined'
                  label='Monetary Value'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.monetaryValue}
                  error={
                    formik.touched &&
                    formik.touched.monetaryValue &&
                    Boolean(formik.errors.monetaryValue)
                  }
                  helperText={
                    formik.touched &&
                    formik.touched.monetaryValue &&
                    formik.errors &&
                    formik.errors.monetaryValue
                  }
                  fullWidth
                />
              </FormikProvider>
            </>
          )}
        </Container>
      </Dialog>

      <Notification
        open={!update.isLoading && !update.isUninitialized}
        onClose={() => (update.isError ? update.reset() : location.reload())}
        isError={Boolean(update.error) && update.isError}
        message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
      />
    </>
  )
}

export default Edit
