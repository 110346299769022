import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Autocomplete,
  Chip,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField
} from '@mui/material'
import { useLocation, useParams } from 'react-router-dom'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import Icon from 'components/icon'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import CONSTANT from 'modules/constant'
import GLOBAL from 'modules/global'
import type { PartialBranchPartnerProps } from 'modules/partial'
import type { ErrorProps, ParamProps } from 'modules/types'
import {
  useDeletePartnerBranchMutation,
  useDownloadBranchPartnerMutation,
  useDownloadTemplatePartnerBranchMutation,
  useGetListBranchPartnerMutation,
  useUpdateStatusBranchPartnerMutation
} from 'store/locationPartner'
import MenuModalItem from 'components/menuItem/menuItem'
import { useGetCityDropdownListMutation } from 'store/dropdown'
import GlobalStyle from 'modules/styles'
import DetailList from './detailList/detailList'
import Edit from './edit/edit'
import Create from './create/create'
import Upload from './upload/upload'

const PartnerList = () => {
  const params = useParams<ParamProps>()
  const locationState = useLocation()
  const [getListBranchPartner, branchPartner] = useGetListBranchPartnerMutation()
  const [downloadBranchPartner, download] = useDownloadBranchPartnerMutation()
  const [updateStatusBranchPartner, status] = useUpdateStatusBranchPartnerMutation()
  const [downloadTemplatePartnerBranch, template] = useDownloadTemplatePartnerBranchMutation()
  const [deletePartnerBranch, deleteBranch] = useDeletePartnerBranchMutation()
  const [getCityDropdownList, cityDropdown] = useGetCityDropdownListMutation()

  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [openDetail, setOpenDetail] = useState<boolean>(false)
  const [menu, setMenu] = useState<null | number>(null)
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [dialogUpdate, setDialogUpdate] = useState<boolean>(false)
  const [dialogCreate, setDialogCreate] = useState<boolean>(false)
  const [dialogUpload, setDialogUpload] = useState<boolean>(false)
  const [number, setNumber] = useState<number>(0)
  const [filter, setFilter] = useState({
    status: '',
    city: ''
  })

  const [payload, setPayload] = useState<PartialBranchPartnerProps>({
    start: 0,
    length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    field: CONSTANT.DEFAULT_PARTNER_LIST_FIELD.join(','),
    search: { value: '' },
    partner: GLOBAL.decodeUrl(params.key || ''),
    city: '',
    status: ''
  })

  const onCreate = () => setDialogCreate(true)

  const onResync = () => {
    const formData = new FormData()
    formData.append('start', payload.start.toString())
    formData.append('length', payload.length.toString())
    formData.append('field', payload.field)
    formData.append('partner', payload.partner)
    if (!isEmpty(payload.status)) formData.append('status', payload.status)
    if (!isEmpty(payload.status)) formData.append('city', payload.city)
    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    getListBranchPartner(formData)
  }

  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('start', String(0))
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())
    formData.append('field', payload.field)
    formData.append('partner', payload.partner)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (type && !isEmpty(type)) formData.append('Download-Type', type)

    downloadBranchPartner(formData)
  }

  useEffect(() => {
    onResync()
  }, [payload])
  const onSearch = (value: string) => setPayload({ ...payload, start: 0, search: { value } })
  const onFilter = (state: boolean) => setDialogFilter(state)
  const tableHeadTitles = [
    'Branch ID',
    'Phone Number',
    'Partner Name',
    'Address',
    'Postal Code',
    'Latitude',
    'Longitude',
    'Google Map URL',
    'Status'
  ]
  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }
  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }
  const onUpload = () => setDialogUpload(true)

  const onDownloadTemplate = (type?: string) => {
    const formData = new FormData()
    if (type && !isEmpty(type)) formData.append('Download-Type', type)
    downloadTemplatePartnerBranch(formData)
  }

  const onChangePage = (value: number) => setPayload({ ...payload, start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, length: value })

  const openAnchorList = Boolean(anchor)

  const totalPage = branchPartner.data && GLOBAL.tableTotalPage(branchPartner.data.recordsTotal)

  const numbers = GLOBAL.tableNumber(payload.start)

  return (
    <>
      <Content
        title='Partner Branch'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        page={payload.start + 1}
        isLoading={branchPartner.isLoading}
        totalPage={totalPage}
        totalRecords={branchPartner.data && branchPartner.data.recordsTotal}
        useBackButton
        useDownloadDropdown
        onAdditional={onCreate}
        additionalTitle='Create New Partner Branch'
        additionalPrefixIcon='Create'
        onTemplateDownload={onDownloadTemplate}
        templateDownloadLoading={template.isLoading}
        templateTitle='Template Partner'
        onUpload={onUpload}
        uploadTitle='Upload csv'
        uploadPrefixIcon='FileUpload'
        useTemplateDownloadDropdown
      >
        {branchPartner.isSuccess &&
          branchPartner.data &&
          branchPartner.data.data.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${item.branchId}`}
                  aria-haspopup='true'
                  aria-controls={openAnchorList ? `menu-${index}-${item.branchId}` : undefined}
                  aria-expanded={openAnchorList ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${item.branchId}`}
                  aria-labelledby={`button-${index}-${item.branchId}`}
                  anchorEl={anchor}
                  open={openAnchorList && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setOpenDetail(true))}>
                    View Detail
                  </MenuItem>
                  <MenuModalItem
                    title={item.status == 'Enabled' ? 'Disable' : 'Enable'}
                    onClick={() =>
                      onRemoveAnchor(() =>
                        updateStatusBranchPartner({
                          id: item.branchId,
                          status: item.status == 'Enabled' ? 'Disabled' : 'Enabled'
                        })
                      )
                    }
                  >
                    {item.status == 'Enabled' ? 'Disable' : 'Enable'}
                  </MenuModalItem>
                  <MenuItem onClick={() => onRemoveAnchor(() => setDialogUpdate(true))}>
                    Update
                  </MenuItem>
                  <MenuModalItem
                    title={item.status == 'Enabled' ? 'Disable' : 'Enable'}
                    onClick={() => onRemoveAnchor(() => deletePartnerBranch(item.branchId))}
                    sx={{ color: 'red' }}
                  >
                    Delete
                  </MenuModalItem>
                </Menu>
                <DetailList
                  id={item.branchId.toString()}
                  onClose={() => setOpenDetail(false)}
                  open={openDetail && menu == index}
                />
                {dialogUpdate && menu == index && (
                  <Edit
                    partnerKey={params.key || ''}
                    companyId={locationState.state.id}
                    id={item.branchId}
                    open={dialogUpdate && menu == index}
                    onClose={() => setDialogUpdate(false)}
                  />
                )}
              </TableCell>
              <TableCell>{item.branchId || '-'}</TableCell>
              <TableCell>{item.phoneNumber || '-'}</TableCell>
              <TableCell>{item.partnerName || '-'}</TableCell>
              <TableCell>{item.address || '-'}</TableCell>
              <TableCell>{item.postalCode || '-'}</TableCell>
              <TableCell>{item.latitude || '-'}</TableCell>
              <TableCell>{item.longitude || '-'}</TableCell>
              <TableCell>{item.googleMapUrl || '-'}</TableCell>
              <TableCell>
                <Chip label={item.status} color={item.status == 'Enabled' ? 'success' : 'error'} />
              </TableCell>
            </TableRow>
          ))}
      </Content>

      {branchPartner.isLoading && <Loading />}

      {dialogCreate && (
        <Create
          open={dialogCreate}
          companyId={locationState.state.id}
          partnerKey={params.key || ''}
          onClose={() => setDialogCreate(false)}
        />
      )}

      {dialogUpload && <Upload open={dialogUpload} onClose={() => setDialogUpload(false)} />}

      <Filter
        open={dialogFilter}
        title='Filter'
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
        onReset={() => {
          setNumber(number + 1)
          setFilter({
            status: '',
            city: ''
          })
          setPayload({
            ...payload,
            start: 0,
            status: '',
            city: ''
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({ ...payload, start: 0, ...filter })
          onFilter(false)
        }}
      >
        <FormControl fullWidth>
          <InputLabel id='select-label'>Status</InputLabel>
          <Select
            labelId='select-label'
            id='id-select-label'
            value={filter.status}
            label='Status'
            onChange={(event) => setFilter({ ...filter, status: event.target.value })}
          >
            <MenuItem value='Enabled'>Enabled</MenuItem>
            <MenuItem value='Disabled'>Disabled</MenuItem>
          </Select>
        </FormControl>
        <Autocomplete
          key={'cityId' + number}
          options={(cityDropdown && cityDropdown.data) || []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.name == value.name : false
          }
          onOpen={() => getCityDropdownList()}
          onChange={(_, city) => {
            if (city) {
              setFilter({ ...filter, city: city.name })
            }
          }}
          value={
            (cityDropdown &&
              cityDropdown.data &&
              cityDropdown.data.find((e) => e.name == filter.city)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.id}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='cityId'
              label='City'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {cityDropdown.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
      </Filter>

      <Notification
        open={
          !branchPartner.isLoading && !branchPartner.isUninitialized && !branchPartner.isSuccess
        }
        onClose={() => (branchPartner.isError ? branchPartner.reset() : location.reload())}
        isError={branchPartner.isError}
        message={GLOBAL.returnExceptionMessage(
          branchPartner.isError,
          branchPartner.error as ErrorProps
        )}
      />

      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />

      <Notification
        open={!deleteBranch.isLoading && !deleteBranch.isUninitialized}
        onClose={() => (deleteBranch.isError ? deleteBranch.reset() : location.reload())}
        isError={Boolean(deleteBranch.error) && deleteBranch.isError}
        message={GLOBAL.returnExceptionMessage(
          deleteBranch.isError,
          deleteBranch.error as ErrorProps
        )}
      />

      <Notification
        open={!status.isLoading && !status.isUninitialized}
        onClose={() => (status.isError ? status.reset() : location.reload())}
        isError={Boolean(status.error) && status.isError}
        message={GLOBAL.returnExceptionMessage(status.isError, status.error as ErrorProps)}
      />
    </>
  )
}

export default PartnerList
