import { FieldArray, FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { Container, Stack, TextField } from '@mui/material'
import Dialog from 'components/dialog/dialog'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import { useCreateFaqCategoryMutation } from 'store/faq'
import type {
  PartialFaqCategoryDetailDataProps,
  PartialFaqCategoryDetailProps
} from 'modules/partial'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import DialogStyle from './style'

const Create = ({ open, onClose }: DetailStateProps) => {
  const [createFaqCategory, create] = useCreateFaqCategoryMutation()

  const scheme = yup.object<PartialFaqCategoryDetailDataProps>({
    key: yup.string().required('Key is required'),
    data: yup.array().of(
      yup.object({
        tags: yup.string().required('Tags is required'),
        name: yup.string().required('Name is required'),
        remark: yup.string()
      })
    )
  })

  const formik = useFormik<PartialFaqCategoryDetailProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      key: '',
      data: [
        { tags: 'ID', name: '', remark: '' },
        { tags: 'EN-US', name: '', remark: '' }
      ]
    },
    onSubmit: (values: PartialFaqCategoryDetailProps) => createFaqCategory(values)
  })

  const errorData = formik.errors.data as PartialFaqCategoryDetailDataProps[]

  return (
    <>
      <Dialog
        open={open}
        title='Create New FAQ Category'
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={create.isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          <TextField
            id='key'
            variant='outlined'
            label='Category Name'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched && formik.touched.key && Boolean(formik.errors.key)}
            helperText={formik.touched && formik.touched.key && formik.errors && formik.errors.key}
            fullWidth
          />
          <FormikProvider value={formik}>
            {formik.values.data.map((_, index) => (
              <FieldArray
                key={index}
                name='data'
                render={() => (
                  <Stack {...DialogStyle.Stack}>
                    <TextField
                      id={`data[${index}].tags`}
                      variant='outlined'
                      label='Tag'
                      value={formik.values.data[index].tags}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      inputProps={{ readOnly: true }}
                      fullWidth
                    />
                    <TextField
                      id={`data[${index}].name`}
                      variant='outlined'
                      label='name'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.data &&
                        formik.touched.data[index] &&
                        formik.touched.data[index].name &&
                        errorData &&
                        errorData[index] &&
                        Boolean(errorData[index].name)
                      }
                      helperText={
                        formik.touched.data &&
                        formik.touched.data[index] &&
                        formik.touched.data[index].name &&
                        errorData &&
                        errorData[index] &&
                        errorData[index].name
                      }
                      fullWidth
                    />
                    <TextField
                      id={`data[${index}].remark`}
                      variant='outlined'
                      label='remark'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.data &&
                        formik.touched.data[index] &&
                        formik.touched.data[index].remark &&
                        errorData &&
                        errorData[index] &&
                        Boolean(errorData[index].remark)
                      }
                      helperText={
                        formik.touched.data &&
                        formik.touched.data[index] &&
                        formik.touched.data[index].remark &&
                        errorData &&
                        errorData[index] &&
                        errorData[index].remark
                      }
                      fullWidth
                    />
                  </Stack>
                )}
              />
            ))}
          </FormikProvider>
        </Container>
      </Dialog>

      <Notification
        open={!create.isLoading && !create.isUninitialized}
        onClose={() => (create.isError ? create.reset() : location.reload())}
        isError={Boolean(create.error) && create.isError}
        message={GLOBAL.returnExceptionMessage(create.isError, create.error as ErrorProps)}
      />
    </>
  )
}

export default Create
