import CONSTANT from 'modules/constant'
import type {
  ResultProps
} from 'modules/types'
import type { PartialPushNotifProps } from 'modules/partial'
import { API, headers } from './api'

const HyundaiModelAPI = API.injectEndpoints({
    endpoints: (builder) => ({createBlastPush: builder.mutation<ResultProps, PartialPushNotifProps>({
        query: (body) => ({
          url: CONSTANT.URL_BLAST_PUSH_NOTIF_CREATE,
          method: 'POST',
          headers: headers(),
          body: body
        })
      })
    })
  })

  export const {
    useCreateBlastPushMutation
  } = HyundaiModelAPI