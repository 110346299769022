/* eslint-disable @typescript-eslint/no-explicit-any */

import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { Autocomplete, CircularProgress, Container, TextField, Typography } from '@mui/material'
import Dialog from 'components/dialog/dialog'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import type { PartialTokenStateProps } from 'modules/partial'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import { useGetVoucherListDropdownMutation } from 'store/tokenRequest'
import GlobalStyle from 'modules/styles'
import { useMoveTokenMutation } from 'store/token'
import DialogStyle from './style'

const MoveToken = ({ open, id, onClose }: DetailStateProps & { id: [] }) => {
  if (!open) return <></>

  const [moveToken, update] = useMoveTokenMutation()
  const [getVoucherListDropdown, voucherDropdown] = useGetVoucherListDropdownMutation()

  const scheme = yup.object<PartialTokenStateProps>({
    VoucherId: yup.string().required('Voucher Name is required')
  })

  const customCode = (arrStr: string, type: string) => {
    const customItems = arrStr.split(',')

    for (let i = 0; i < customItems.length; i++) {
      customItems[i] =
        type === 'id' ? customItems[i].split('-')[0] : customItems[i].split('-')[1] + '\n'
    }

    return type === 'id' ? customItems.map(Number) : customItems.join('')
  }

  const formik = useFormik<PartialTokenStateProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      VoucherTokenId: '',
      VoucherId: ''
    },
    onSubmit: (values: PartialTokenStateProps) => handleSubmit(values)
  })

  const handleSubmit = (e: PartialTokenStateProps) => {
    const payload: any = {
      VoucherTokenId: customCode(String(id), 'id'),
      VoucherId: e.VoucherId
    }

    moveToken(payload)
  }

  return (
    <>
      <Dialog
        open={open}
        title='Move Token'
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={update.isLoading}
        isDisabled={!formik.isValid}
        submitText='Yes'
        cancelText='No'
      >
        <Container {...DialogStyle.Container}>
          {id && (
            <>
              <FormikProvider value={formik}>
                <Typography sx={{ textAlign: 'center' }}>
                  Are you sure want to move all selected tokens?
                </Typography>
                <Typography>{id.length} Token(s) found</Typography>
                <TextField
                  id='outlined-multiline-static'
                  multiline
                  rows={4}
                  defaultValue={customCode(String(id), 'code')}
                  inputProps={{ readOnly: true }}
                />
                <Autocomplete
                  options={voucherDropdown.data || []}
                  getOptionLabel={(list) => list.name}
                  isOptionEqualToValue={(option, value) =>
                    option && value ? option.id == value.id : false
                  }
                  onOpen={() => getVoucherListDropdown()}
                  onChange={(_, id) => formik.setFieldValue('VoucherId', id && id.id)}
                  ListboxProps={GlobalStyle.ListBox}
                  renderOption={(props, item) => (
                    <li {...props} key={item.id}>
                      {item.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name='VoucherId'
                      label='Voucher Name'
                      error={
                        formik.touched &&
                        formik.touched.VoucherId &&
                        Boolean(formik.errors.VoucherId)
                      }
                      helperText={
                        formik.touched &&
                        formik.touched.VoucherId &&
                        formik.errors &&
                        formik.errors.VoucherId
                      }
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {voucherDropdown.isLoading && (
                              <CircularProgress color='inherit' size={20} />
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                />
              </FormikProvider>
            </>
          )}
        </Container>
      </Dialog>

      <Notification
        open={!update.isLoading && !update.isUninitialized}
        onClose={() => (update.isError ? update.reset() : location.reload())}
        isError={update.isError}
        message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
      />
    </>
  )
}

export default MoveToken
