import CONSTANT from 'modules/constant'
import type { EvTransactionLogProps } from 'modules/types'
import { API, headers } from './api'

const EvTransactionLogAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getEvTransactionLog: builder.mutation<EvTransactionLogProps, string>({
      query: (id) => ({
        url: CONSTANT.URL_EV_TRANSACTION_LOG_LIST + id,
        method: 'GET',
        headers: headers()
      })
    })
  })
})

export const { useGetEvTransactionLogMutation } = EvTransactionLogAPI
