import { Outlet } from 'react-router-dom'
import { Container } from '@mui/material'
import Breadcrumb from 'components/breadcrumb/breadcrumb'
import Header from 'components/header/header'
import Sidebar from 'components/sidebar/sidebar'
import LayoutStyle from './style'

const Layout = () => (
  <Container {...LayoutStyle.Container}>
    <Sidebar />
    <Container {...LayoutStyle.Wrapper}>
      <Header />
      <Container {...LayoutStyle.Content}>
        <Breadcrumb />
        <Outlet />
      </Container>
    </Container>
  </Container>
)

export default Layout
