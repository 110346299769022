import CONSTANT from 'modules/constant'
import type {
  EventDetailLocationProps,
  EventLocationProps,
  PaginationProps,
  ResultProps
} from 'modules/types'
import type { PartialCreateEventLocationProps } from 'modules/partial'
import { API, download, headers } from './api'

export const LocationAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getEventLocation: builder.mutation<PaginationProps<EventLocationProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_LOCATION_EVENT,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body
      })
    }),
    downloadEventLocation: builder.mutation<PaginationProps<EventLocationProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_LOCATION_EVENT_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        responseHandler: download,
        body: body
      })
    }),
    updateEventLocation: builder.mutation<ResultProps, { id: string | number; status: string }>({
      query: (body) => ({
        url: CONSTANT.URL_LOCATION_EVENT_UPDATE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    getDetailEventLocation: builder.query<EventDetailLocationProps, { id: number | string }>({
      query: (body) => ({
        url: CONSTANT.URL_LOCATION_EVENT_DETAIL,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    uploadEventLocation: builder.mutation<ResultProps, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_LOCATION_EVENT_UPLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body
      })
    }),
    createEventLocation: builder.mutation<ResultProps, PartialCreateEventLocationProps>({
      query: (body) => ({
        url: CONSTANT.URL_LOCATION_EVENT_CREATE,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body
      })
    }),
    editEventLocation: builder.mutation<ResultProps, PartialCreateEventLocationProps>({
      query: (body) => ({
        url: CONSTANT.URL_LOCATION_EVENT_EDIT,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body
      })
    }),
    downloadTemplateEventLocation: builder.mutation<PaginationProps<EventLocationProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_LOCATION_EVENT_DOWNLOAD_TEMPLATE,
        method: 'POST',
        headers: headers({ useContentType: false }),
        responseHandler: download,
        body: body
      })
    }),
    deleteEventLocation: builder.mutation<ResultProps, { id: number; status: string }>({
      query: (body) => ({
        url: CONSTANT.URL_LOCATION_EVENT_DELETE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    })
  })
})

export const {
  useGetEventLocationMutation,
  useDownloadEventLocationMutation,
  useUpdateEventLocationMutation,
  useUploadEventLocationMutation,
  useCreateEventLocationMutation,
  useEditEventLocationMutation,
  useDownloadTemplateEventLocationMutation,
  useDeleteEventLocationMutation,

  useGetDetailEventLocationQuery
} = LocationAPI
