import { Container, Grid, TextField } from '@mui/material'
import Drawer from 'components/drawer/detail'
import Loading from 'components/loading/loading'
import type { DetailStateProps } from 'modules/types'
import { useGetTokenCategoryDetailQuery } from 'store/token'
import DrawerStyle from './style'

const Detail = ({ open, id, onClose }: DetailStateProps & { id: string | number }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const { data, isFetching, isLoading } = useGetTokenCategoryDetailQuery(id)

  const name = (data && data.nameEn) || '-'
  const companyName = (data && data.companyName) || '-'
  const monetaryValue = (data && data.monetaryValue.toLocaleString('ID')) || '-'

  const loading = !data && (isLoading || isFetching)

  const label = ['Token Category Name', 'Partner', 'Monetary Value']
  const value = [name, companyName, monetaryValue]

  return (
    <>
      <Drawer open={open} title='Detail Token Category' onClose={onClose}>
        <Container {...DrawerStyle.Container}>
          {loading && <Loading />}
          {!loading && (
            <Grid container spacing={2}>
              {value.map((item, index) => (
                <Grid key={index} item xs={12}>
                  <TextField
                    variant='outlined'
                    label={label[index]}
                    value={item}
                    inputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Container>
      </Drawer>
    </>
  )
}

export default Detail
