import type { LoadingButtonProps } from '@mui/lab'
import type { BoxProps, StackProps } from '@mui/material'

class PageStyle {
  static Container: BoxProps = {
    sx: {
      display: 'flex',
      gap: 2
    }
  }

  static Box: StackProps = {
    width: 500,
    sx: {
      display: 'flex',
      gap: 2,
      flexDirection: 'column'
    }
  }

  static Button: LoadingButtonProps = {
    variant: 'contained',
    sx: {
      width: '100%'
    }
  }
}

export default PageStyle
