import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { Autocomplete, CircularProgress, IconButton, Menu, MenuItem, TableCell, TableRow, TextField } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import type { Dayjs } from 'dayjs'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import { useDownloadTransactionEventMutation, useGetAppointmentConfirmationDropdownListMutation, useGetBookingStatusDropdownListMutation, useGetDealerDropdownListMutation, useGetDMSRepairStatusDropdownListMutation, useGetEventNameDropdownListMutation, useGetListTransactionEventMutation } from 'store/serviceBookingEventTransaction'
import Icon from 'components/icon'
import CONSTANT from 'modules/constant'
import GLOBAL from 'modules/global'
import GlobalStyle from 'modules/styles'
import type { PartialTransaction } from 'modules/partial'
import Detail from './detail/detail'
import ArrivalConfirmation from './arrivalConfirmation/arrivalConfirmation'
import "dayjs/locale/id"

const Transaction = () => {
  const [getTransactionEventList, transactionEventList] = useGetListTransactionEventMutation()
  const [getDealerDropdownList, dealerDropdown] =
    useGetDealerDropdownListMutation()
  const [getBookingStatusDropdownList, bookingStatusDropdown] =
    useGetBookingStatusDropdownListMutation()
  const [getDmsRepairStatusDropdownList, dmsRepairStatusDropdown] =
    useGetDMSRepairStatusDropdownListMutation()
  const [getAppointmentConfirmationDropdownList, appointmentConfirmationDropdown] =
    useGetAppointmentConfirmationDropdownListMutation()
  const [getEventNameDropdownList, eventNameDropdown] = useGetEventNameDropdownListMutation()

  const [downloadCarInformation, download] = useDownloadTransactionEventMutation()

  const [filter, setFilter] = useState({
    memberCode: '',
    userFullName: '',
    dmsRepairId: '',
    dealerId: '',
    eventName: '',
    serviceDateStart: '',
    serviceDateEnd: '',
    serviceStatus: '',
    dmsRepairStatus: '',
    appointmentConfirmation: ''
  })

  const [payload, setPayload] = useState<PartialTransaction>({
    start: 0,
    length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    field: CONSTANT.DEFAULT_TRANSACTION_FIELD.join(','),
    Search: '',
    Event: '',
    memberCode: '',
    userFullName: '',
    dmsRepairId: '',
    dealerId: '',
    Dealer: '',
    eventName: '',
    serviceDateStart: '',
    serviceDateEnd: '',
    BookingServiceSchedule: '',
    ContactPerson: '',
    serviceStatus: '',
    dmsRepairStatus: '',
    appointmentConfirmation: ''
  })

  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [menu, setMenu] = useState<null | number>(null)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [isUpdateAccountStatus, SetIsUpdateAccountStatus] = useState<boolean>(false)

  const formatDate = (e: string) => (!isEmpty(e) ? moment(e).format('DD-MM-YYYY HH:mm') : '-')
  const onSearch = (value: string) => setPayload({ ...payload, start: 0, Search: value })
  const onChangePage = (value: number) => setPayload({ ...payload, start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, length: value })

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const onResync = () => {
    const formData = new FormData()

    formData.append('start', payload.start.toString())
    formData.append('length', payload.length.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.Search)) formData.append('Search', payload.Search)
    if (!isEmpty(payload.Event)) formData.append('Event', payload.Event)
    if (!isEmpty(payload.memberCode)) formData.append('memberCode', payload.memberCode)
    if (!isEmpty(payload.userFullName)) formData.append('userFullName', payload.userFullName)
    if (!isEmpty(payload.dmsRepairId)) formData.append('dmsRepairId', payload.dmsRepairId)
    if (!isEmpty(payload.dealerId)) formData.append('dealerId', payload.dealerId)
    if (!isEmpty(payload.Dealer)) formData.append('Dealer', payload.Dealer)
    if (!isEmpty(payload.BookingServiceSchedule)) formData.append('BookingServiceSchedule', payload.BookingServiceSchedule)
    if (!isEmpty(payload.ContactPerson)) formData.append('ContactPerson', payload.ContactPerson)
    if (!isEmpty(payload.serviceStatus)) formData.append('serviceStatus', payload.serviceStatus)
    if (!isEmpty(payload.dmsRepairStatus)) formData.append('dmsRepairStatus', payload.dmsRepairStatus)
    if (!isEmpty(payload.appointmentConfirmation)) formData.append('appointmentConfirmation', payload.appointmentConfirmation)
    if (!isEmpty(payload.eventName)) formData.append('eventName', payload.eventName)
    if (!isEmpty(payload.serviceDateStart)) formData.append('serviceDateStart', payload.serviceDateStart)
    if (!isEmpty(payload.serviceDateEnd)) formData.append('serviceDateEnd', payload.serviceDateEnd)
    getTransactionEventList(formData)
  }

  const onFilter = (state: boolean) => setDialogFilter(state)

  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('start', String(0))
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.Search)) formData.append('Search', payload.Search)
    if (!isEmpty(payload.Event)) formData.append('Event', payload.Event)
    if (!isEmpty(payload.memberCode)) formData.append('memberCode', payload.memberCode)
    if (!isEmpty(payload.userFullName)) formData.append('userFullName', payload.userFullName)
    if (!isEmpty(payload.dmsRepairId)) formData.append('dmsRepairId', payload.dmsRepairId)
    if (!isEmpty(payload.dealerId)) formData.append('dealerId', payload.dealerId)
    if (!isEmpty(payload.Dealer)) formData.append('Dealer', payload.Dealer)
    if (!isEmpty(payload.BookingServiceSchedule)) formData.append('BookingServiceSchedule', payload.BookingServiceSchedule)
    if (!isEmpty(payload.ContactPerson))
      formData.append('ContactPerson', payload.ContactPerson)
    if (!isEmpty(payload.serviceStatus)) formData.append('serviceStatus', payload.serviceStatus)
    if (!isEmpty(payload.dmsRepairStatus)) formData.append('dmsRepairStatus', payload.dmsRepairStatus)
    if (!isEmpty(payload.appointmentConfirmation))
      formData.append('appointmentConfirmation', payload.appointmentConfirmation)
    if (type && !isEmpty(type)) formData.append('Content-Type', type)
    console.log('formData', formData);

    downloadCarInformation(formData)
  }

  const tableHeadTitles = [
    'Event',
    'VIN',
    'Member ID',
    'Member Name',
    'Member Phone',
    'Contact Person Name',
    'Contact Person Phone',
    'Booking code',
    'Repair ID',
    'Dealer',
    'Booking Service Schedule',
    'Created Date & Time',
    'Booking Status',
    'DMS Repair Status',
    'Appointment Confirmation',
  ]

  const openAnchor = Boolean(anchor)
  const totalPage =
    transactionEventList.data && GLOBAL.tableTotalPage(transactionEventList.data.recordsTotal)
  const numbers = GLOBAL.tableNumber(payload.start)

  const onUpdateAccountStatus = () => {
    // if (status === 'Approved') {
    SetIsUpdateAccountStatus(true)
    // } else {
    //   SetIsUpdateAccountStatus(false)
    // }
  }

  useEffect(() => {
    onResync()
  }, [payload])



  return (
    <>
      <Content
        title='Booking History'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={transactionEventList.data && transactionEventList.data.recordsTotal}
        page={payload.start + 1}
        isLoading={transactionEventList.isLoading}
        useDownloadDropdown
      >
        {transactionEventList.isSuccess &&
          transactionEventList.data &&
          transactionEventList.data.data?.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.id}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.id}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.id}`}
                  aria-labelledby={`button-${index}-${table.id}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View Detail
                  </MenuItem>
                  <MenuItem
                    onClick={() => onRemoveAnchor(() => onUpdateAccountStatus())}>
                    Arrival Confirmation
                  </MenuItem>
                </Menu>
                <Detail
                  id={table.id}
                  open={drawerDetail && menu == index}
                  onClose={() => setDrawerDetail(false)}
                />
                {/* {isUpdateAccountStatus && ( */}
                {/* )} */}
                <ArrivalConfirmation
                  id={table.id}
                  vin={table.memberCode}
                  vehicleId={table.contactName}
                  accountStatus={table.status}
                  open={isUpdateAccountStatus && menu == index}
                  onClose={() => SetIsUpdateAccountStatus(false)}
                />
              </TableCell>
              <TableCell>{table.eventName || '-'}</TableCell>
              <TableCell>{table.vin || '-'}</TableCell>
              <TableCell>{table.memberCode || '-'}</TableCell>
              <TableCell>{table.userFullName || '-'}</TableCell>
              <TableCell>{table.userPhone || '-'}</TableCell>
              <TableCell>{table.contactName || '-'}</TableCell>
              <TableCell>{table.contactPhone || '-'}</TableCell>
              <TableCell>{table.bookingCode || '-'}</TableCell>
              <TableCell>{table.dmsRepairId || '-'}</TableCell>
              <TableCell>{table.dealerName || '-'}</TableCell>
              <TableCell>{formatDate(table.serviceDate) || '-'}</TableCell>
              <TableCell>{formatDate(table.createdAt) || '-'}</TableCell>
              <TableCell>{table.serviceStatus || '-'}</TableCell>
              <TableCell>{table.dmsRepairStatus || '-'}</TableCell>
              <TableCell>{table.appointmentConfirmation || 'Not Confirmed'}</TableCell>
            </TableRow>
          ))}
      </Content>

      <Filter
        open={dialogFilter}
        title='Filter'
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        onSubmit={() => {
          setPayload({
            ...payload,
            ...filter,
            start: 0
          })
          onFilter(false)
        }}
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
        onReset={() => {
          setFilter({
            memberCode: '',
            userFullName: '',
            dmsRepairId: '',
            dealerId: '',
            eventName: '',
            serviceDateStart: '',
            serviceDateEnd: '',
            serviceStatus: '',
            dmsRepairStatus: '',
            appointmentConfirmation: ''
          })
          setPayload({
            ...payload,
            start: 0,
            Search: '',
            Event: '',
            memberCode: '',
            userFullName: '',
            dmsRepairId: '',
            dealerId: '',
            Dealer: '',
            BookingServiceSchedule: '',
            ContactPerson: '',
            serviceStatus: '',
            dmsRepairStatus: '',
            appointmentConfirmation: '',
            eventName: '',
            serviceDateEnd: '',
            serviceDateStart: ''
          })
          onFilter(false)
        }}
      >
        <Autocomplete
          options={(eventNameDropdown && eventNameDropdown.data) || []}
          getOptionLabel={(list) => list.text}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.value == value.value : false
          }
          onOpen={() => getEventNameDropdownList(CONSTANT.DEFAULT_DROPDOWN_PAYLOAD)}
          onChange={(_, eventName) =>
            setFilter({
              ...filter,
              eventName: (eventName && eventName.text.toString()) || ''
            })
          }
          value={
            (
              eventNameDropdown && eventNameDropdown.data
              && eventNameDropdown.data.find((e) => String(e.value) == filter.eventName)
            ) || undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.value}>
              {item.text}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='EventName'
              label='Event Name'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {eventNameDropdown.isLoading && (
                      <CircularProgress color='inherit' size={20} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <TextField
          id='MemberID'
          variant='outlined'
          label='Member Id'
          value={filter.memberCode}
          onChange={(event) => setFilter({ ...filter, memberCode: event.target.value })}
          fullWidth
        />
        <TextField
          id='MemberName'
          variant='outlined'
          label='Member Name'
          value={filter.userFullName}
          onChange={(event) => setFilter({ ...filter, userFullName: event.target.value })}
          fullWidth
        />
        <TextField
          id='dmsRepairId'
          variant='outlined'
          label='Repair ID'
          value={filter.dmsRepairId}
          onChange={(event) => setFilter({ ...filter, dmsRepairId: event.target.value })}
          fullWidth
        />
        <Autocomplete
          options={(dealerDropdown && dealerDropdown.data) || []}
          getOptionLabel={(list) => list.text}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.value == value.value : false
          }
          onOpen={() => getDealerDropdownList(CONSTANT.DEFAULT_DROPDOWN_PAYLOAD)}
          onChange={(_, dealerId) =>
            setFilter({
              ...filter,
              dealerId: (dealerId && dealerId.value.toString()) || ''
            })
          }
          // onInputChange={(_, dealerName) => setFilter({ ...filter, dealerName })}
          value={
            (dealerDropdown &&
              dealerDropdown.data &&
              dealerDropdown.data.find((e) => String(e.value) == filter.dealerId)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.value}>
              {item.text}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='BookingStatus'
              label='Dealer'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {dealerDropdown.isLoading && (
                      <CircularProgress color='inherit' size={20} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs} adapterLocale='id'>
          <DatePicker
            label="Booking Service Date Start"
            format='DD-MM-YYYY'
            value={filter.serviceDateStart ? dayjs(filter.serviceDateStart) : null}
            onChange={(val: Dayjs | null) => {
              let dateEnd = filter.serviceDateEnd ? dayjs(filter.serviceDateEnd) : null
              if (val)
                if (
                  (dateEnd && dateEnd.isBefore(val))
                  || !dateEnd
                ) dateEnd = val

              setFilter({
                ...filter,
                serviceDateStart: val ? val.format('YYYY-MM-DD') : '',
                serviceDateEnd: dateEnd ? dateEnd.format('YYYY-MM-DD') : ''
              })}
            }
          />
          <DatePicker
            label="Booking Service Date End"
            format='DD-MM-YYYY'
            value={filter.serviceDateEnd ? dayjs(filter.serviceDateEnd) : null}
            onChange={(val: Dayjs | null) => setFilter({ ...filter, serviceDateEnd: val ? val.format('YYYY-MM-DD') : ''})}
            minDate={dayjs(filter.serviceDateStart)}
            readOnly={!filter.serviceDateStart}
          />
        </LocalizationProvider>
        <Autocomplete
          options={(bookingStatusDropdown && bookingStatusDropdown.data) || []}
          getOptionLabel={(list) => list.text}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.value == value.value : false
          }
          onOpen={() => getBookingStatusDropdownList(CONSTANT.DEFAULT_DROPDOWN_PAYLOAD)}
          onChange={(_, serviceStatus) =>
            setFilter({
              ...filter,
              serviceStatus: (serviceStatus && serviceStatus.value.toString()) || ''
            })
          }
          // onInputChange={(_, serviceStatus) => setFilter({ ...filter, serviceStatus })}
          value={
            (bookingStatusDropdown &&
              bookingStatusDropdown.data &&
              bookingStatusDropdown.data.find((e) => String(e.value) == filter.serviceStatus)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.value}>
              {item.text}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='BookingStatus'
              label='Booking Status'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {bookingStatusDropdown.isLoading && (
                      <CircularProgress color='inherit' size={20} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <Autocomplete
          options={(dmsRepairStatusDropdown && dmsRepairStatusDropdown.data) || []}
          getOptionLabel={(list) => list.text}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.value == value.value : false
          }
          onOpen={() => getDmsRepairStatusDropdownList(CONSTANT.DEFAULT_DROPDOWN_PAYLOAD)}
          onChange={(_, dmsRepairStatus) =>
            setFilter({
              ...filter,
              dmsRepairStatus: (dmsRepairStatus && dmsRepairStatus.value.toString()) || ''
            })
          }
          // onInputChange={(_, dmsRepairStatus) => setFilter({ ...filter, dmsRepairStatus })}
          value={
            (dmsRepairStatusDropdown &&
              dmsRepairStatusDropdown.data &&
              dmsRepairStatusDropdown.data.find((e) => String(e.value) == filter.dmsRepairStatus)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.value}>
              {item.text}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='dmsRepairStatus'
              label='DMS Repair Status'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {dmsRepairStatusDropdown.isLoading && (
                      <CircularProgress color='inherit' size={20} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <Autocomplete
          options={(appointmentConfirmationDropdown && appointmentConfirmationDropdown.data) || []}
          getOptionLabel={(list) => list.value}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.value == value.value : false
          }
          onOpen={() => getAppointmentConfirmationDropdownList(CONSTANT.DEFAULT_DROPDOWN_PAYLOAD)}
          // onChange={(_, chargePoint) =>
          //   chargePoint &&
          //   setFilter({
          //     ...filter,
          //     StatusName: String(chargePoint.name)
          //   })
          // }
          onInputChange={(_, appointmentConfirmation) => setFilter({ ...filter, appointmentConfirmation })}
          value={
            (appointmentConfirmationDropdown &&
              appointmentConfirmationDropdown.data &&
              appointmentConfirmationDropdown.data.find((e) => String(e.value) == filter.appointmentConfirmation)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.value}>
              {item.value}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='AppointmentConfirmtion'
              label='Appointment Confirmation'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {appointmentConfirmationDropdown.isLoading && (
                      <CircularProgress color='inherit' size={20} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
      </Filter>
    </>
  )
}

export default Transaction
