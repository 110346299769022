import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Autocomplete,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  TextField
} from '@mui/material'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import Icon from 'components/icon'
import type { PartialPromoCodeRedemptionProps } from 'modules/partial'
import CONSTANT from 'modules/constant'
import {
  useDownloadPromoCodeRedemptionMutation,
  useGetListPromoCodeRedemptionMutation,
  useGetRedemptionStatusMutation
} from 'store/promoCodeRedemption'
import GLOBAL from 'modules/global'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import type { ErrorProps } from 'modules/types'
import { useDropDownPromoCodeBatchRewardMutation } from 'store/promoCodeBatch'
import GlobalStyle from 'modules/styles'
import { useGetUsernameDropdownMutation } from 'store/dropdown'
import Detail from './detail/detail'

const PromoRedemption = () => {
  const [getListPromoCodeRedemption, promoCodeRedemption] = useGetListPromoCodeRedemptionMutation()
  const [downloadPromoCodeRedemption, download] = useDownloadPromoCodeRedemptionMutation()
  const [getRedemptionStatus, redemptionStatus] = useGetRedemptionStatusMutation()
  const [dropDownPromoCodeBatchReward, dropdownReward] = useDropDownPromoCodeBatchRewardMutation()
  const [getUsernameDropdown, username] = useGetUsernameDropdownMutation()
  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [menu, setMenu] = useState<null | number>(null)
  const [payload, setPayload] = useState<PartialPromoCodeRedemptionProps>({
    Start: 0,
    Length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    Search: '',
    Prefix: '',
    PromoCode: '',
    PromoName: '',
    RedemptionStatus: '',
    RewardType: '',
    UserId: ''
  })
  const [randomNumber, setRandomNumber] = useState<number>(0)

  const [filter, setFilter] = useState({
    PromoCode: '',
    PromoName: '',
    RedemptionStatus: '',
    RewardType: '',
    UserId: ''
  })

  const onSearch = (value: string) => setPayload({ ...payload, Start: 0, Search: value })
  const onChangePage = (value: number) => setPayload({ ...payload, Start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, Length: value })
  const onResync = () => {
    const formData = new FormData()
    formData.append('Start', payload.Start.toString())
    formData.append('Length', payload.Length.toString())

    if (!isEmpty(payload.Search)) formData.append('Search', payload.Search)
    if (!isEmpty(payload.Prefix)) formData.append('Prefix', payload.Prefix)
    if (!isEmpty(payload.Prefix)) formData.append('Prefix', payload.Prefix)
    if (!isEmpty(payload.PromoCode)) formData.append('PromoCode', payload.PromoCode)
    if (!isEmpty(payload.PromoName)) formData.append('PromoName', payload.PromoName)
    if (!isEmpty(payload.RedemptionStatus))
      formData.append('RedemptionStatus', payload.RedemptionStatus)
    if (!isEmpty(payload.RewardType)) formData.append('RewardType', payload.RewardType)
    if (!isEmpty(payload.UserId)) formData.append('UserId', payload.UserId)
    getListPromoCodeRedemption(formData)
  }
  const onFilter = (state: boolean) => setDialogFilter(state)
  const onDownload = (type?: string) => {
    const formData = new FormData()
    formData.append('Start', String(0))
    formData.append('Length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())
    if (!isEmpty(payload.Search)) formData.append('Search', payload.Search)
    if (!isEmpty(payload.Prefix)) formData.append('Prefix', payload.Prefix)
    if (!isEmpty(payload.Prefix)) formData.append('Prefix', payload.Prefix)
    if (!isEmpty(payload.PromoCode)) formData.append('PromoCode', payload.PromoCode)
    if (!isEmpty(payload.PromoName)) formData.append('PromoName', payload.PromoName)
    if (!isEmpty(payload.RedemptionStatus))
      formData.append('RedemptionStatus', payload.RedemptionStatus)
    if (!isEmpty(payload.RewardType)) formData.append('RewardType', payload.RewardType)
    if (!isEmpty(payload.UserId)) formData.append('UserId', payload.UserId)
    if (type && !isEmpty(type)) formData.append('Content-Type', type)

    downloadPromoCodeRedemption(formData)
  }
  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }
  useEffect(() => {
    onResync()
  }, [payload])

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const tableHeadTitles = [
    'Username',
    'Redemption Status',
    'Promo Code',
    'Campaign Name',
    'Reward',
    'Voucher Name',
    'Coin Amount',
    'Redeemed At',
    'Created Date'
  ]
  const numbers = GLOBAL.tableNumber(payload.Start)
  const openAnchor = Boolean(anchor)
  const totalPage =
    promoCodeRedemption.data && GLOBAL.tableTotalPage(promoCodeRedemption.data.recordsTotal)

  return (
    <>
      <Content
        title='Promo Code Redemption'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={promoCodeRedemption.data && promoCodeRedemption.data.recordsTotal}
        page={payload.Start + 1}
        isLoading={promoCodeRedemption.isLoading}
        useDownloadDropdown
      >
        {promoCodeRedemption.isSuccess &&
          promoCodeRedemption.data &&
          promoCodeRedemption.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table}`}
                  aria-labelledby={`button-${index}-${table}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View
                  </MenuItem>
                </Menu>
                <Detail
                  id={table.id}
                  open={drawerDetail && menu == index}
                  onClose={() => setDrawerDetail(false)}
                />
              </TableCell>
              <TableCell>{table.userFullName || '-'}</TableCell>
              <TableCell>{table.status || '-'}</TableCell>
              <TableCell>{table.promoCode || '-'}</TableCell>
              <TableCell>{table.promoName || '-'}</TableCell>
              <TableCell>{table.rewardType || '-'}</TableCell>
              <TableCell {...GlobalStyle.EllipsisTable}>{table.voucherName || '-'}</TableCell>
              <TableCell>{GLOBAL.formatCurrency(table.coinsAmount) || '-'}</TableCell>
              <TableCell>{GLOBAL.formatDateWithTime(table.redeemedAt)}</TableCell>
              <TableCell>{GLOBAL.formatDateWithTime(table.createdAt)}</TableCell>

            </TableRow>
          ))}
      </Content>
      {promoCodeRedemption.isLoading && <Loading />}
      <Notification
        open={
          !promoCodeRedemption.isLoading &&
          !promoCodeRedemption.isUninitialized &&
          !promoCodeRedemption.isSuccess
        }
        onClose={() =>
          promoCodeRedemption.isError ? promoCodeRedemption.reset() : location.reload()
        }
        isError={Boolean(promoCodeRedemption.error) && promoCodeRedemption.isError}
        message={GLOBAL.returnExceptionMessage(
          promoCodeRedemption.isError,
          promoCodeRedemption.error as ErrorProps
        )}
      />

      <Filter
        open={dialogFilter}
        title='Filter'
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
        onReset={() => {
          setRandomNumber(randomNumber + 1)
          setFilter({
            PromoCode: '',
            PromoName: '',
            RedemptionStatus: '',
            RewardType: '',
            UserId: ''
          })
          setPayload({
            Start: 0,
            Length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
            Search: '',
            Prefix: '',
            PromoCode: '',
            PromoName: '',
            RedemptionStatus: '',
            RewardType: '',
            UserId: ''
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({
            ...payload,
            ...filter
          })
          onFilter(false)
        }}
      >
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={2} sm={4} md={4}>
            <Autocomplete
              key={`username` + randomNumber}
              options={(username && username.data) || []}
              getOptionLabel={(option) => option.fullName}
              isOptionEqualToValue={(option, value) =>
                option && value ? option.userId == value.userId : false
              }
              onOpen={() => getUsernameDropdown()}
              onChange={(_, user) => {
                if (!isEmpty(user)) setFilter({ ...filter, UserId: user.userId })
              }}
              value={
                (username &&
                  username.data &&
                  username.data.find((e) => e.userId == filter.UserId)) ||
                undefined
              }
              ListboxProps={GlobalStyle.ListBox}
              renderOption={(props, item) => (
                <li {...props} key={item.userId}>
                  {item.fullName}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='username'
                  label='Username'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {username.isLoading && <CircularProgress color='inherit' size={20} />}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <Autocomplete
              key={`RedemptionStatus` + randomNumber}
              options={(redemptionStatus && redemptionStatus.data) || []}
              getOptionLabel={(option) => option.text}
              isOptionEqualToValue={(option, value) =>
                option && value ? option.value == value.value : false
              }
              onOpen={() => getRedemptionStatus()}
              onChange={(_, reward) => {
                if (!isEmpty(reward)) setFilter({ ...filter, RedemptionStatus: reward?.text })
              }}
              value={
                (redemptionStatus &&
                  redemptionStatus.data &&
                  redemptionStatus.data.find((e) => e.text == filter.RedemptionStatus)) ||
                undefined
              }
              ListboxProps={GlobalStyle.ListBox}
              renderOption={(props, item) => (
                <li {...props} key={item.value}>
                  {item.text}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='RedemptionStatus'
                  label='Redemption Status'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {redemptionStatus.isLoading && (
                          <CircularProgress color='inherit' size={20} />
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <TextField
              label='Promo Code'
              value={filter.PromoCode}
              onChange={(e) => setFilter({ ...filter, PromoCode: e.target.value })}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <Autocomplete
              key={`Status` + randomNumber}
              options={(dropdownReward && dropdownReward.data) || []}
              getOptionLabel={(option) => option.text}
              isOptionEqualToValue={(option, value) =>
                option && value ? option.value == value.value : false
              }
              onOpen={() => dropDownPromoCodeBatchReward()}
              onChange={(_, reward) => {
                if (!isEmpty(reward)) setFilter({ ...filter, RewardType: reward?.text })
              }}
              value={
                (dropdownReward &&
                  dropdownReward.data &&
                  dropdownReward.data.find((e) => e.text == filter.RewardType)) ||
                undefined
              }
              ListboxProps={GlobalStyle.ListBox}
              renderOption={(props, item) => (
                <li {...props} key={item.value}>
                  {item.text}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='Reward'
                  label='Reward'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {dropdownReward.isLoading && <CircularProgress color='inherit' size={20} />}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <TextField
              label='Campaign Name'
              value={filter.PromoName}
              onChange={(e) => setFilter({ ...filter, PromoName: e.target.value })}
            />
          </Grid>
        </Grid>
      </Filter>
    </>
  )
}

export default PromoRedemption
