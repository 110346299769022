import type { LoadingButtonProps } from '@mui/lab'
import type { ContainerProps, StackProps, TypographyProps } from '@mui/material'

class DialogStyle {
  static Container: ContainerProps = {
    maxWidth: false,
    disableGutters: true,
    sx: {
      display: 'flex',
      gap: '1rem',
      flexDirection: 'column'
    }
  }

  static Stack: StackProps = {
    direction: 'column',
    spacing: 2
  }

  static Permission: TypographyProps = {
    fontWeight: '500',
    fontSize: 18
  }

  static Submit: LoadingButtonProps = {
    sx: {
      alignSelf: 'end'
    }
  }
}

export default DialogStyle
