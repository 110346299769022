import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Grid,
  TextField,
  Typography
} from '@mui/material'
import { ExpandMoreOutlined } from '@mui/icons-material'
import Drawer from 'components/drawer/detail'
import type { DetailStateProps } from 'modules/types'
import Loading from 'components/loading/loading'
import { useGetDetailBranchPartnerQuery } from 'store/locationPartner'
import DetailStyle from './style'

const DetailList = ({ open, id, onClose }: DetailStateProps & { id: number | string }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const { data, isFetching, isLoading } = useGetDetailBranchPartnerQuery(id)

  const addres = data && (data.address || '-')
  const city = data && (data.city || '-')
  const postalCode = data && (data.postalCode || '-')
  const googleMapUrl = data && (data.googleMapUrl || '-')
  const partnerName = data && (data.partnerName || '-')
  const phoneNumber = data && (data.phoneNumber || '-')
  const branchName = data && (data.branchName || '-')

  const gridDataLabel = [
    'Address',
    'City',
    'Postal Code ',
    'Google Map Url',
    'Partner Name',
    'Phone Number',
    'Branch Name'
  ]
  const gridDataValue = [
    addres,
    city,
    postalCode,
    googleMapUrl,
    partnerName,
    phoneNumber,
    branchName
  ]

  const createdBy = data && (data.createdBy || '-')
  const createdAt = data && (data.createdAt || '-')
  const modifiedBy = data && (data.modifiedBy || '-')
  const modifiedAt = data && (data.modifiedAt || '-')

  const gridBasicLabel = ['Created By', 'Created At', 'Modified By', 'Modified At']
  const gridBasicValue = [createdBy, createdAt, modifiedBy, modifiedAt]

  return (
    <Drawer open={open} title='Partner Branch Data Detail' onClose={onClose}>
      <Container {...DetailStyle.Container}>
        {(isLoading || isFetching) && !data && <Loading />}
        {data && (
          <Grid container spacing={2}>
            {gridDataValue.map((item, index) => (
              <Grid key={index} item xs={6}>
                <TextField
                  variant='outlined'
                  label={gridDataLabel[index]}
                  value={item}
                  inputProps={{ readOnly: true }}
                  fullWidth
                />
              </Grid>
            ))}
          </Grid>
        )}
        {data && (
          <Accordion elevation={0} disableGutters>
            <AccordionSummary expandIcon={<ExpandMoreOutlined />} aria-controls='basic-information'>
              <Typography>Basic Information</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {gridBasicValue.map((item, index) => (
                  <Grid key={index} item xs={12}>
                    <TextField
                      variant='outlined'
                      label={gridBasicLabel[index]}
                      value={item}
                      inputProps={{ readOnly: true }}
                      fullWidth
                    />
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
      </Container>
    </Drawer>
  )
}

export default DetailList
