import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import {
  Autocomplete,
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import type { PartialPartnerListProps } from 'modules/partial'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import Dialog from 'components/dialog/dialog'
import { useCreatePartnerBranchMutation } from 'store/locationPartner'
import GlobalStyle from 'modules/styles'
import { useGetCityDropdownListMutation } from 'store/dropdown'
import DialogStyle from './style'

const Create = ({
  open,
  onClose,
  partnerKey,
  companyId
}: DetailStateProps & { partnerKey: string; companyId: number }) => {
  const [createPartnerBranch, create] = useCreatePartnerBranchMutation()
  const [getCityDropdownList, cityDropdown] = useGetCityDropdownListMutation()

  const scheme = yup.object<PartialPartnerListProps>({
    BranchName: yup.string().required('Branch Name is required'),
    PhoneNumber: yup.string().required('PhoneNumber is required'),
    Address: yup.string().required('Address is required'),
    Description: yup.string().required('Description is required'),
    Latitude: yup.number().required('Latitude is required'),
    Longitude: yup.number().required('Longitude is required'),
    GoogleMapUrl: yup.string().required('GoogleMapUrl is required'),
    CityId: yup.number().required('CityId is required'),
    CompanyId: yup.number().required('CompanyId is required'),
    PostalCode: yup.string().required('Postal Code is required'),
    Status: yup.string().required('Status is required')
  })

  const formik = useFormik<PartialPartnerListProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      BranchName: '',
      PhoneNumber: '',
      Address: '',
      Description: '',
      Latitude: null,
      Longitude: null,
      GoogleMapUrl: '',
      CityId: null,
      CompanyId: companyId,
      PostalCode: '',
      Status: ''
    },
    onSubmit: (values: PartialPartnerListProps) => createPartnerBranch(values)
  })

  return (
    <>
      <Dialog
        open={open}
        title='Create Partner Branch'
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={create.isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          <FormikProvider value={formik}>
            <TextField
              id='BranchName'
              variant='outlined'
              label='Branch Name'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched && formik.touched.BranchName && Boolean(formik.errors.BranchName)
              }
              helperText={
                formik.touched &&
                formik.touched.BranchName &&
                formik.errors &&
                formik.errors.BranchName
              }
              fullWidth
            />
            <TextField
              id='PhoneNumber'
              variant='outlined'
              label='Phone Number'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched && formik.touched.PhoneNumber && Boolean(formik.errors.PhoneNumber)
              }
              helperText={
                formik.touched &&
                formik.touched.PhoneNumber &&
                formik.errors &&
                formik.errors.PhoneNumber
              }
              fullWidth
            />
            <TextField
              variant='outlined'
              label='Partner Name'
              value={partnerKey || ''}
              disabled
              fullWidth
            />
                        <TextField
              id='Address'
              variant='outlined'
              label='Address'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched && formik.touched.Address && Boolean(formik.errors.Address)}
              helperText={
                formik.touched && formik.touched.Address && formik.errors && formik.errors.Address
              }
              fullWidth
            />
            <Autocomplete
              key={'cityId'}
              options={(cityDropdown && cityDropdown.data) || []}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) =>
                option && value ? option.name == value.name : false
              }
              onOpen={() => getCityDropdownList()}
              onChange={(_, city) => formik.setFieldValue('CityId', city?.id)}
              value={
                (cityDropdown &&
                  cityDropdown.data &&
                  cityDropdown.data.find((e) => e.id == formik.values.CityId)) ||
                undefined
              }
              ListboxProps={GlobalStyle.ListBox}
              renderOption={(props, item) => (
                <li {...props} key={item.id}>
                  {item.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='cityId'
                  label='City'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {cityDropdown.isLoading && <CircularProgress color='inherit' size={20} />}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
            />


            <TextField
              id='PostalCode'
              variant='outlined'
              label='PostalCode'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched && formik.touched.PostalCode && Boolean(formik.errors.PostalCode)
              }
              helperText={
                formik.touched &&
                formik.touched.PostalCode &&
                formik.errors &&
                formik.errors.PostalCode
              }
              fullWidth
            />
            <TextField
              type='number'
              id='Latitude'
              variant='outlined'
              label='Latitude'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched && formik.touched.Latitude && Boolean(formik.errors.Latitude)}
              helperText={
                formik.touched && formik.touched.Latitude && formik.errors && formik.errors.Latitude
              }
              fullWidth
            />
            <TextField
              type='number'
              id='Longitude'
              variant='outlined'
              label='Longitude'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched && formik.touched.Longitude && Boolean(formik.errors.Longitude)}
              helperText={
                formik.touched &&
                formik.touched.Longitude &&
                formik.errors &&
                formik.errors.Longitude
              }
              fullWidth
            />
            <TextField
              id='GoogleMapUrl'
              variant='outlined'
              label='GoogleMapUrl'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched && formik.touched.GoogleMapUrl && Boolean(formik.errors.GoogleMapUrl)
              }
              helperText={
                formik.touched &&
                formik.touched.GoogleMapUrl &&
                formik.errors &&
                formik.errors.GoogleMapUrl
              }
              fullWidth
            />
            <FormControl fullWidth>
              <InputLabel id='active'>Status</InputLabel>
              <Select
                labelId='Status'
                id='Status'
                value={formik.values.Status}
                label='* Status'
                onChange={(event) => formik.setFieldValue('Status', event.target.value)}
              >
                <MenuItem value='Enabled'>Enabled</MenuItem>
                <MenuItem value='Disabled'>Disabled</MenuItem>
              </Select>
            </FormControl>
            <TextField
              id='Description'
              variant='outlined'
              label='Description'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched && formik.touched.Description && Boolean(formik.errors.Description)
              }
              helperText={
                formik.touched &&
                formik.touched.Description &&
                formik.errors &&
                formik.errors.Description
              }
              fullWidth
            />
          </FormikProvider>
        </Container>
      </Dialog>
      <Notification
        open={!create.isLoading && !create.isUninitialized}
        onClose={() => (create.isError ? create.reset() : location.reload())}
        isError={Boolean(create.error) && create.isError}
        message={GLOBAL.returnExceptionMessage(create.isError, create.error as ErrorProps)}
      />
    </>
  )
}

export default Create
