import { Snackbar, Alert } from '@mui/material'
import type { SnackbarProps } from '@mui/material'
import CONSTANT from 'modules/constant'

const Notification = ({
  open,
  onClose,
  message,
  isError = false
}: SnackbarProps & { isError: boolean }) => (
  <Snackbar
    transitionDuration={0}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    open={open}
    onClose={onClose}
    autoHideDuration={CONSTANT.NOTIFICATION_DURATION}
  >
    <Alert severity={isError ? 'error' : 'success'}>{message}</Alert>
  </Snackbar>
)

export default Notification
