import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import {
  useDeleteQrCodeMutation,
  useDownloadQrCodeMutation,
  useGetQrCodeListMutation
} from 'store/qrcode'
import CONSTANT from 'modules/constant'
import type { PartialEvChargerBoxDropdownProps, PartialQrCodeList } from 'modules/partial'
import Icon from 'components/icon'
import MenuModalItem from 'components/menuItem/menuItem'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { ErrorProps } from 'modules/types'
import { useGetChargerPointDropdownMutation } from 'store/dropdown'
import GlobalStyle from 'modules/styles'
import Detail from './detail/detail'
import Create from './create/create'
import Edit from './edit/edit'

const EVQrCode = () => {
  const [getQrCodeList, qrcode] = useGetQrCodeListMutation()
  const [deleteQrCode, deleteQr] = useDeleteQrCodeMutation()
  const [getChargerPointDropdown, chargerPoint] = useGetChargerPointDropdownMutation()
  const [downloadQrCode, download] = useDownloadQrCodeMutation()
  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [dialogCreate, setDialogCreate] = useState<boolean>(false)
  const [dialogUpdate, setDialogUpdate] = useState<boolean>(false)
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [menu, setMenu] = useState<null | number>(null)

  const [filter, setFilter] = useState<string>('')
  const [payload, setPayload] = useState<PartialQrCodeList>({
    start: 0,
    length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    search: '',
    filter: '',
    sorting: '',
    field: '',
    ChargePointId: ''
  })

  const filterPayload: PartialEvChargerBoxDropdownProps = {
    Start: 0,
    Length: 100
  }

  const onSearch = (value: string) => setPayload({ ...payload, start: 0, search: value })
  const onResync = () => {
    const formData = new FormData()
    formData.append('start', payload.start.toString())
    formData.append('length', payload.length.toString())

    if (!isEmpty(payload.search)) formData.append('search', payload.search)
    if (!isEmpty(payload.field)) formData.append('field', payload.field)
    if (!isEmpty(payload.sorting)) formData.append('sorting', payload.sorting)
    if (!isEmpty(payload.filter)) formData.append('filter', payload.filter)
    if (!isEmpty(payload.ChargePointId)) formData.append('ChargePointId', payload.ChargePointId)

    getQrCodeList(formData)
  }
  useEffect(() => {
    onResync()
  }, [payload])
  const onFilter = (state: boolean) => setDialogFilter(state)
  const onDownload = (type?: string) => {
    const formData = new FormData()
    formData.append('start', payload.start.toString())
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())
    if (!isEmpty(payload.search)) formData.append('search', payload.search)
    if (!isEmpty(payload.field)) formData.append('field', payload.field)
    if (!isEmpty(payload.sorting)) formData.append('sorting', payload.sorting)
    if (!isEmpty(payload.filter)) formData.append('filter', payload.filter)
    if (!isEmpty(payload.ChargePointId)) formData.append('ChargePointId', payload.ChargePointId)
    if (type && !isEmpty(type)) formData.append('Content-Type', type)
    downloadQrCode(formData)
  }
  const onCreate = () => setDialogCreate(true)
  const onChangePage = (value: number) => setPayload({ ...payload, start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, length: value })

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }
  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const formatImg = (img: string) => img.split('/')[6]

  const tableHeadTitles = ['Charge Point', 'Charger Box Group', 'Charger Box', 'QR ID', 'Image']

  const openAnchor = Boolean(anchor)

  const totalPage = qrcode.data && GLOBAL.tableTotalPage(qrcode.data.recordsTotal)

  const numbers = GLOBAL.tableNumber(payload.start)

  return (
    <>
      <Content
        title='EV QR Code'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        onAdditional={onCreate}
        additionalTitle='Create New EV QR Code'
        additionalPrefixIcon='Create'
        totalRecords={qrcode.data && qrcode.data.recordsTotal}
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        page={payload.start + 1}
        isLoading={qrcode.isLoading}
        useDownloadDropdown
      >
        {qrcode.isSuccess &&
          qrcode.data &&
          qrcode.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.id}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.id}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.id}`}
                  aria-labelledby={`button-${index}-${table.id}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View Detail
                  </MenuItem>
                  <MenuItem onClick={() => onRemoveAnchor(() => setDialogUpdate(true))}>
                    Edit
                  </MenuItem>
                  <MenuModalItem
                    title={table.chargePointName}
                    onClick={() => onRemoveAnchor(() => deleteQrCode(table.id))}
                  >
                    <Typography {...GlobalStyle.ErrorTypography}>Delete</Typography>
                  </MenuModalItem>
                </Menu>
                {drawerDetail && menu == index && (
                  <Detail
                    id={table.id}
                    open={drawerDetail && menu == index}
                    onClose={() => setDrawerDetail(false)}
                  />
                )}
                {dialogUpdate && menu == index && (
                  <Edit
                    id={table.id}
                    open={dialogUpdate && menu == index}
                    onClose={() => setDialogUpdate(false)}
                  />
                )}
              </TableCell>
              <TableCell>{table.chargePointName || '-'}</TableCell>
              <TableCell>{table.chargeBoxGroupIdentity || '-'}</TableCell>
              <TableCell>{table.id || '-'}</TableCell>
              <TableCell>{table.qrCode || '-'}</TableCell>
              <TableCell>
                {(table.imageUrlQrCode && (
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography mr='10px'>{formatImg(table.imageUrlQrCode)}</Typography>
                    <Button href={table.imageUrlQrCode} variant='contained'>
                      Download QR
                    </Button>
                  </Box>
                )) ||
                  '-'}
              </TableCell>
            </TableRow>
          ))}
      </Content>
      {dialogCreate && <Create open={dialogCreate} onClose={() => setDialogCreate(false)} />}

      <Notification
        open={!deleteQr.isLoading && !deleteQr.isUninitialized}
        onClose={() => (deleteQr.isError ? deleteQr.reset() : location.reload())}
        isError={Boolean(deleteQr.error) && deleteQr.isError}
        message={GLOBAL.returnExceptionMessage(deleteQr.isError, deleteQr.error as ErrorProps)}
      />

      <Notification
        open={!qrcode.isLoading && !qrcode.isUninitialized && !qrcode.isSuccess}
        onClose={() => (qrcode.isError ? qrcode.reset() : location.reload())}
        isError={Boolean(qrcode.error) && qrcode.isError}
        message={GLOBAL.returnExceptionMessage(qrcode.isError, qrcode.error as ErrorProps)}
      />

      <Notification
        open={!download.isLoading && !download.isUninitialized && !download.isSuccess}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />
      <Filter
        open={dialogFilter}
        title='Filter'
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        isSubmitDisabled={isEmpty(filter)}
        onReset={() => {
          setFilter('')
          setPayload({
            start: 0,
            length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
            search: '',
            filter: '',
            sorting: '',
            field: '',
            ChargePointId: ''
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({
            ...payload,
            ChargePointId: filter,
            start: 0
          })
          onFilter(false)
        }}
      >
        <Autocomplete
          options={(chargerPoint && chargerPoint.data) || []}
          getOptionLabel={(option) => option.text}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.value == value.value : false
          }
          onOpen={() => getChargerPointDropdown(filterPayload)}
          onChange={(_, chargerPoint) => {
            if (chargerPoint) {
              setFilter(chargerPoint.value.toString())
            }
          }}
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.value}>
              {item.text}
            </li>
          )}
          value={
            (chargerPoint &&
              chargerPoint.data &&
              chargerPoint.data.find((e) => String(e.value) == String(filter))) ||
            undefined
          }
          renderInput={(params) => (
            <TextField
              {...params}
              name='Charger Point'
              label='Charger Point'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {chargerPoint.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
      </Filter>
    </>
  )
}

export default EVQrCode
