import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Autocomplete,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import type { Dayjs } from 'dayjs'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import CONSTANT from 'modules/constant'
import Icon from 'components/icon'
import MenuModalItem from 'components/menuItem/menuItem'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { ErrorProps } from 'modules/types'
import GlobalStyle from 'modules/styles'
import type { PartialTokenCategoryProps } from 'modules/partial'
import {
  useDeleteVoucherPackageMutation,
  useDownloadVoucherPackageMutation,
  useEnableVoucherPackageMutation,
  useGetCarModelPackageDropdownMutation,
  useGetListVoucherPackageMutation,
  useGetVoucherFilterDropdownMutation
} from 'store/voucherPackage'
import { useGetVoucherCategoryDropdownMutation } from 'store/voucherCategory'
import Create from './create/create'
import Detail from './detail/detail'
import Edit from './edit/edit'

const VoucherPackage = () => {
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [dialogCreate, setDialogCreate] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [dialogUpdate, setDialogUpdate] = useState<boolean>(false)

  const [menu, setMenu] = useState<null | number>(null)
  const [startDate, setStartDate] = useState<Dayjs | null>(null)
  const [endDate, setEndDate] = useState<Dayjs | null>(null)
  const [getListVoucherPackage, tokenPackage] = useGetListVoucherPackageMutation()
  const [downloadVoucherPackage, download] = useDownloadVoucherPackageMutation()
  const [deleteVoucherPackage, deletePackage] = useDeleteVoucherPackageMutation()

  const [getVoucherCategoryDropdown, dropdownCategory] = useGetVoucherCategoryDropdownMutation()
  const [getCarModelPackageDropdown, dropdownCarModel] = useGetCarModelPackageDropdownMutation()
  const [getVoucherFilterDropdown, dropdownVoucher] = useGetVoucherFilterDropdownMutation()
  const [enableVoucherPackage, enablePackage] = useEnableVoucherPackageMutation()

  const [payload, setPayload] = useState<PartialTokenCategoryProps>({
    start: 0,
    length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    field: CONSTANT.DEFAULT_VOUCHER_PACKAGE_FIELD.join(','),
    search: { value: '' },
    status: '',
    monetaryValue: '',
    companyId: ''
  })

  const [filter, setFilter] = useState({
    packagecategoryid: '',
    carmodelid: '',
    voucherid: '',
    MinStartTime: '',
    MaxStartTime: '',
    status: '',
    isbestchoice: ''
  })

  const onSearch = (value: string) => setPayload({ ...payload, start: 0, search: { value } })
  const onResync = () => {
    const formData = new FormData()
    formData.append('start', payload.start.toString())
    formData.append('length', payload.length.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(filter.packagecategoryid))
      formData.append('packagecategoryid', filter.packagecategoryid)
    if (!isEmpty(filter.carmodelid)) formData.append('carmodelid', filter.carmodelid)
    if (!isEmpty(filter.voucherid)) formData.append('voucherid', filter.voucherid)
    if (!isEmpty(filter.MinStartTime)) formData.append('min-period-from', filter.MinStartTime)
    if (!isEmpty(filter.MaxStartTime)) formData.append('max-period-from', filter.MaxStartTime)
    if (!isEmpty(filter.isbestchoice)) formData.append('isbestchoice', filter.isbestchoice)
    if (!isEmpty(filter.status)) formData.append('status', filter.status)

    getListVoucherPackage(formData)
  }
  const onFilter = (state: boolean) => setDialogFilter(state)
  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('start', String(0))
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(filter.packagecategoryid))
      formData.append('packagecategoryid', filter.packagecategoryid)
    if (!isEmpty(filter.carmodelid)) formData.append('carmodelid', filter.carmodelid)
    if (!isEmpty(filter.voucherid)) formData.append('voucherid', filter.voucherid)
    if (!isEmpty(filter.MinStartTime)) formData.append('min-period-from', filter.MinStartTime)
    if (!isEmpty(filter.MaxStartTime)) formData.append('max-period-from', filter.MaxStartTime)
    if (!isEmpty(filter.status)) formData.append('status', filter.status)
    if (!isEmpty(filter.isbestchoice)) formData.append('isbestchoice', filter.isbestchoice)
    if (type && !isEmpty(type)) formData.append('Download-Type', type)

    downloadVoucherPackage(formData)
  }
  const onCreate = () => setDialogCreate(true)

  const onChangePage = (value: number) => setPayload({ ...payload, start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, length: value })

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const dataStatus = [
    { value: 'Enabled', name: 'Enabled' },
    { value: 'Disabled', name: 'Disabled' }
  ]

  const dataBoolean = [
    { value: '1', name: 'Yes' },
    { value: '0', name: 'No' }
  ]

  const tableHeadTitles = [
    'Package Name',
    'Package Category',
    'Voucher List',
    'Car Model',
    'Period From',
    'Period Until',
    'Best Choice',
    'Status'
  ]

  const openAnchor = Boolean(anchor)

  const totalPage = tokenPackage.data && GLOBAL.tableTotalPage(tokenPackage.data.recordsTotal)

  const numbers = GLOBAL.tableNumber(payload.start)

  useEffect(() => {
    onResync()
  }, [payload])

  const handleChangeStartDate = (date: Dayjs | null) => {
    setStartDate(date)
    const newDate = (!isEmpty(date) && date?.format('YYYY-MM-DD')) || ''
    setFilter({ ...filter, MinStartTime: newDate, MaxStartTime: '' })
    setEndDate(null)
  }

  const handleChangeEndDate = (date: Dayjs | null) => {
    setEndDate(date)
    const newDate = (!isEmpty(date) && date?.format('YYYY-MM-DD')) || ''
    setFilter({ ...filter, MaxStartTime: newDate })
  }

  return (
    <>
      <Content
        title='Voucher Package'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        onAdditional={onCreate}
        additionalTitle='New Voucher Package'
        additionalPrefixIcon='Add'
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={tokenPackage.data && tokenPackage.data.recordsTotal}
        page={payload.start + 1}
        isLoading={tokenPackage.isLoading}
        useDownloadDropdown
      >
        {tokenPackage.isSuccess &&
          tokenPackage.data &&
          tokenPackage.data.data.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${item.id}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${item.id}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${item.id}`}
                  aria-labelledby={`button-${index}-${item.id}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View Detail
                  </MenuItem>
                  <MenuItem onClick={() => onRemoveAnchor(() => setDialogUpdate(true))}>
                    Edit
                  </MenuItem>
                  <MenuModalItem
                    title={item.status == 'Enabled' ? 'Disable' : 'Enable'}
                    onClick={() =>
                      onRemoveAnchor(() =>
                        enableVoucherPackage({
                          Id: item.id,
                          Status: item.status == 'Enabled' ? 'Disabled' : 'Enabled'
                        })
                      )
                    }
                  >
                    {item.status == 'Enabled' ? 'Disable' : 'Enable'}
                  </MenuModalItem>
                  <MenuModalItem
                    title='Delete'
                    onClick={() =>
                      onRemoveAnchor(() => deleteVoucherPackage({ Id: item.id || '' }))
                    }
                  >
                    <Typography {...GlobalStyle.ErrorTypography}>Delete</Typography>
                  </MenuModalItem>
                </Menu>
                {drawerDetail && menu == index && (
                  <Detail
                    id={item.id || ''}
                    open={drawerDetail && menu == index}
                    onClose={() => setDrawerDetail(false)}
                  />
                )}
                {dialogUpdate && menu == index && (
                  <Edit
                    id={item.id || ''}
                    open={dialogUpdate && menu == index}
                    onClose={() => setDialogUpdate(false)}
                  />
                )}
              </TableCell>
              <TableCell>{item.packageName || '-'}</TableCell>
              <TableCell>{item.packageCategoryName || '-'}</TableCell>
              <TableCell>{item.voucherList || '-'}</TableCell>
              <TableCell>{item.carModel || '-'}</TableCell>
              <TableCell>{dayjs(item.periodFrom).format('DD MMMM YYYY') || '-'}</TableCell>
              <TableCell>{dayjs(item.periodUntil).format('DD MMMM YYYY') || '-'}</TableCell>
              <TableCell>{item.bestChoice.toString() || '-'}</TableCell>
              <TableCell>
                <Chip
                  label={item.status == 'Enabled' ? 'Enabled' : 'Disabled'}
                  color={item.status == 'Enabled' ? 'success' : 'error'}
                />
              </TableCell>
            </TableRow>
          ))}
      </Content>
      {tokenPackage.isLoading && <Loading />}

      <Filter
        open={dialogFilter}
        title='Filter Voucher Package'
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        onReset={() => {
          setFilter({
            packagecategoryid: '',
            carmodelid: '',
            voucherid: '',
            MinStartTime: '',
            MaxStartTime: '',
            status: '',
            isbestchoice: ''
          })
          setStartDate(null)
          setEndDate(null)
          setPayload({ ...payload, start: 0, ...filter })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({ ...payload, start: 0, ...filter })
          onFilter(false)
        }}
      >
        <Grid container spacing={2} columns={16}>
          <Grid xs={8} marginTop={1}>
            <Grid xs={16} margin={1}>
              <Autocomplete
                options={dropdownCategory.data || []}
                getOptionLabel={(list) => list.name}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.id == value.id : false
                }
                onOpen={() => getVoucherCategoryDropdown()}
                onChange={(_, id) => {
                  if (id) {
                    setFilter({
                      ...filter,
                      packagecategoryid: id && id?.id.toString()
                    })
                  }
                }}
                ListboxProps={GlobalStyle.ListBox}
                value={
                  dropdownCategory &&
                  dropdownCategory.data &&
                  dropdownCategory.data.find((e) => e.id == Number(filter.packagecategoryid))
                }
                renderOption={(props, item) => (
                  <li {...props} key={item.id}>
                    {item.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='packagecategoryid'
                    label='Package Category'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {dropdownCategory.isLoading && (
                            <CircularProgress color='inherit' size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
              />
            </Grid>
            <Grid xs={16} margin={1}>
              <Autocomplete
                options={dropdownCarModel.data || []}
                getOptionLabel={(list) => list.name}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.id == value.id : false
                }
                onOpen={() => getCarModelPackageDropdown()}
                onChange={(_, id) => {
                  if (id) {
                    setFilter({
                      ...filter,
                      carmodelid: id?.id.toString()
                    })
                  }
                }}
                ListboxProps={GlobalStyle.ListBox}
                value={
                  dropdownCarModel &&
                  dropdownCarModel.data &&
                  dropdownCarModel.data.find((e) => e.id == filter.carmodelid)
                }
                renderOption={(props, item) => (
                  <li {...props} key={item.id}>
                    {item.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='carmodelid'
                    label='Car Model'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {dropdownCarModel.isLoading && (
                            <CircularProgress color='inherit' size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
              />
            </Grid>
            <Grid xs={16} margin={1}>
              <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                <DatePicker
                  value={startDate}
                  onChange={handleChangeStartDate}
                  label='Min Period From'
                  format='DD-MM-YYYY'
                  sx={{ width: '100%' }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid xs={16} margin={1}>
              <Autocomplete
                options={dataStatus || []}
                getOptionLabel={(list) => list.name}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.value == value.value : false
                }
                onChange={(_, id) => {
                  if (id) {
                    setFilter({
                      ...filter,
                      status: id?.value
                    })
                  }
                }}
                ListboxProps={GlobalStyle.ListBox}
                value={dataStatus && dataStatus.find((e) => e.value == filter.status)}
                renderOption={(props, item) => (
                  <li {...props} key={item.value}>
                    {item.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='status'
                    label='Status'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: <>{params.InputProps.endAdornment}</>
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          {/* ============== Righ Filter ================== */}
          <Grid xs={8} marginTop={1}>
            <Grid xs={16} margin={1}>
              <Autocomplete
                options={dropdownVoucher.data || []}
                getOptionLabel={(list) => list.name}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.id == value.id : false
                }
                onOpen={() => getVoucherFilterDropdown()}
                onChange={(_, id) => {
                  if (id) {
                    setFilter({
                      ...filter,
                      voucherid: id && id?.id.toString()
                    })
                  }
                }}
                ListboxProps={GlobalStyle.ListBox}
                value={
                  dropdownVoucher &&
                  dropdownVoucher.data &&
                  dropdownVoucher.data.find((e) => e.id == Number(filter.voucherid))
                }
                renderOption={(props, item) => (
                  <li {...props} key={item.id}>
                    {item.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='voucherid'
                    label='Voucher'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {dropdownVoucher.isLoading && (
                            <CircularProgress color='inherit' size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
              />
            </Grid>
            <Grid xs={16} margin={1}>
              <Autocomplete
                options={dataBoolean || []}
                getOptionLabel={(list) => list.name}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.value == value.value : false
                }
                onChange={(_, id) => {
                  if (id) {
                    setFilter({
                      ...filter,
                      isbestchoice: id?.value
                    })
                  }
                }}
                ListboxProps={GlobalStyle.ListBox}
                value={dataBoolean && dataBoolean.find((e) => e.value == filter.isbestchoice)}
                renderOption={(props, item) => (
                  <li {...props} key={item.value}>
                    {item.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='isbestchoice'
                    label='Is Best Choice'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: <>{params.InputProps.endAdornment}</>
                    }}
                  />
                )}
              />
            </Grid>
            <Grid xs={16} margin={1}>
              <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                <DatePicker
                  value={endDate}
                  minDate={dayjs(startDate)}
                  onChange={handleChangeEndDate}
                  label='Max Period From'
                  format='DD-MM-YYYY'
                  sx={{ width: '100%' }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Grid>
      </Filter>
      {dialogCreate && <Create open={dialogCreate} onClose={() => setDialogCreate(false)} />}

      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />

      <Notification
        open={!deletePackage.isLoading && !deletePackage.isUninitialized}
        onClose={() => (deletePackage.isError ? deletePackage.reset() : location.reload())}
        isError={Boolean(deletePackage.error) && deletePackage.isError}
        message={GLOBAL.returnExceptionMessage(
          deletePackage.isError,
          deletePackage.error as ErrorProps
        )}
      />
      <Notification
        open={!enablePackage.isLoading && !enablePackage.isUninitialized}
        onClose={() => (enablePackage.isError ? enablePackage.reset() : location.reload())}
        isError={Boolean(enablePackage.error) && enablePackage.isError}
        message={GLOBAL.returnExceptionMessage(
          enablePackage.isError,
          enablePackage.error as ErrorProps
        )}
      />
    </>
  )
}

export default VoucherPackage
