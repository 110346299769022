import { CircularProgress, Container } from '@mui/material'
import LoadingStyle from './style'

const Loading = () => (
  <Container {...LoadingStyle.Container}>
    <CircularProgress />
  </Container>
)

export default Loading
