import type { BoxProps } from '@mui/material'

class DetailStyle {
  static Container: BoxProps = {
    display: 'flex',
    width: '100%',
    padding: 2,
    fontWeight: 500,
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}

export default DetailStyle
