import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { Chip, IconButton, Menu, MenuItem, TableCell, TableRow, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import type { Dayjs } from 'dayjs'
import { useLocation, useParams } from 'react-router-dom'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import {
  useDeleteVoucherSubCategoryMutation,
  useDownloadVoucherSubCategoryMutation,
  useGetListVoucherSubCategoryMutation
} from 'store/voucherCategory'
import CONSTANT from 'modules/constant'
import type { PartialVoucherSubCategoryProps } from 'modules/partial'
import Icon from 'components/icon'
import MenuModalItem from 'components/menuItem/menuItem'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { ErrorProps, ParamProps } from 'modules/types'
import GlobalStyle from 'modules/styles'
import Create from './create/create'
import Detail from './detail/detail'
import Edit from './edit/edit'

const VoucherSubCategory = () => {
  const params = useParams<ParamProps>()
  const locationState = useLocation()
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [dialogCreate, setDialogCreate] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [dialogUpdate, setDialogUpdate] = useState<boolean>(false)
  const [menu, setMenu] = useState<null | number>(null)
  const [getListVoucherSubCategory, voucherSubCategory] = useGetListVoucherSubCategoryMutation()
  const [downloadVoucherSubCategory, download] = useDownloadVoucherSubCategoryMutation()
  const [deleteVoucherSubCategory, deleteCategory] = useDeleteVoucherSubCategoryMutation()
  // const [getVoucherCategoryDropdown, dropdownCategory] = useGetVoucherCategoryDropdownMutation()

  const [startDate, setStartDate] = useState<Dayjs | null>(null)
  const [endDate, setEndDate] = useState<Dayjs | null>(null)

  const [payload, setPayload] = useState<PartialVoucherSubCategoryProps>({
    start: 0,
    length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    field: CONSTANT.DEFAULT_VOUCHER_SUBCATEGORY_FIELD.join(','),
    search: { value: '' },
    status: '',
    categoryId: locationState.state.id
  })

  const [filter, setFilter] = useState({
    MinStartTime: '',
    MaxStartTime: '',
    categoryId: locationState.state.id
  })

  const onSearch = (value: string) => setPayload({ ...payload, start: 0, search: { value } })
  const onResync = () => {
    const formData = new FormData()
    formData.append('start', payload.start.toString())
    formData.append('length', payload.length.toString())
    formData.append('field', payload.field)
    formData.append('category-id', payload.categoryId.toString())

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(payload.status)) formData.append('status', payload.status)
    if (!isEmpty(filter.MinStartTime)) formData.append('min-created-date', filter.MinStartTime)
    if (!isEmpty(filter.MaxStartTime)) formData.append('max-created-date', filter.MaxStartTime)
    // if (!isEmpty(filter.categoryId)) formData.append('category-id', filter.categoryId)

    getListVoucherSubCategory(formData)
  }
  const onFilter = (state: boolean) => setDialogFilter(state)
  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('start', String(0))
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())
    formData.append('field', payload.field)
    formData.append('category-id', payload.categoryId.toString())

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(payload.status)) formData.append('status', payload.status)
    if (!isEmpty(filter.MinStartTime)) formData.append('min-created-date', filter.MinStartTime)
    if (!isEmpty(filter.MaxStartTime)) formData.append('max-created-date', filter.MaxStartTime)
    // if (!isEmpty(filter.categoryId)) formData.append('category-id', filter.categoryId)
    if (type && !isEmpty(type)) formData.append('Download-Type', type)

    downloadVoucherSubCategory(formData)
  }
  const onCreate = () => setDialogCreate(true)

  const onChangePage = (value: number) => setPayload({ ...payload, start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, length: value })

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const tableHeadTitles = [
    'Voucher Sub Category Name',
    'Voucher Category',
    'Description',
    'Created Date',
    'Sequence',
    'Status'
  ]

  const openAnchor = Boolean(anchor)
  const totalPage =
    voucherSubCategory.data &&
    Math.ceil(voucherSubCategory.data.recordsTotal / CONSTANT.DEFAULT_PAGINATION_PER_PAGE)

  const numbers = Array.from(
    Array(CONSTANT.DEFAULT_PAGINATION_PER_PAGE * (payload.start + 1)).keys()
  ).slice(-CONSTANT.DEFAULT_PAGINATION_PER_PAGE)

  useEffect(() => {
    onResync()
  }, [payload])

  const handleChangeStartDate = (date: Dayjs | null) => {
    setStartDate(date)
    const newDate = (!isEmpty(date) && date?.format('YYYY-MM-DD')) || ''
    setFilter({ ...filter, MinStartTime: newDate, MaxStartTime: '' })
    setEndDate(null)
  }

  const handleChangeEndDate = (date: Dayjs | null) => {
    setEndDate(date)
    const newDate = (!isEmpty(date) && date?.format('YYYY-MM-DD')) || ''
    setFilter({ ...filter, MaxStartTime: newDate })
  }

  return (
    <>
      <Content
        title={`Voucher Sub Category › ${params.key}`}
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        onAdditional={onCreate}
        additionalTitle='Create New Voucher Sub Category'
        additionalPrefixIcon='Create'
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={voucherSubCategory.data && voucherSubCategory.data.recordsTotal}
        page={payload.start + 1}
        isLoading={voucherSubCategory.isLoading}
        useDownloadDropdown
        useBackButton
      >
        {voucherSubCategory.isSuccess &&
          voucherSubCategory.data &&
          voucherSubCategory.data.data.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${item.id}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${item.id}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${item.id}`}
                  aria-labelledby={`button-${index}-${item.id}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View Detail
                  </MenuItem>
                  <MenuItem onClick={() => onRemoveAnchor(() => setDialogUpdate(true))}>
                    Edit
                  </MenuItem>

                  <MenuModalItem
                    title='Delete'
                    loading={deleteCategory.isLoading}
                    onClick={() => onRemoveAnchor(() => deleteVoucherSubCategory({ Id: item.id }))}
                  >
                    <Typography {...GlobalStyle.ErrorTypography}>Delete</Typography>
                  </MenuModalItem>
                </Menu>
                {drawerDetail && menu == index && (
                  <Detail
                    id={item.id}
                    open={drawerDetail && menu == index}
                    onClose={() => setDrawerDetail(false)}
                  />
                )}
                {dialogUpdate && menu == index && (
                  <Edit
                    id={item.id}
                    open={dialogUpdate && menu == index}
                    onClose={() => setDialogUpdate(false)}
                  />
                )}
              </TableCell>
              <TableCell>{item.nameEn || '-'}</TableCell>
              <TableCell>{item.category || '-'}</TableCell>
              <TableCell>{item.description || '-'}</TableCell>
              <TableCell>{dayjs(item.createdAt).format('DD MMMM YYYY HH:mm') || '-'}</TableCell>
              <TableCell>{item.seq || '-'}</TableCell>

              <TableCell>
                <Chip label={item.status} color={item.status == 'Enabled' ? 'success' : 'error'} />
              </TableCell>
            </TableRow>
          ))}
      </Content>
      {voucherSubCategory.isLoading && <Loading />}

      <Filter
        open={dialogFilter}
        title='Filter'
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
        onReset={() => {
          setFilter({
            MinStartTime: '',
            MaxStartTime: '',
            categoryId: locationState.state.id
          })
          setStartDate(null)
          setEndDate(null)
          setPayload({ ...payload, start: 0, ...filter })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({ ...payload, start: 0, ...filter })
          onFilter(false)
        }}
      >
        {/* <Autocomplete
          options={dropdownCategory.data || []}
          getOptionLabel={(list) => list.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.id == value.id : false
          }
          onOpen={() => getVoucherCategoryDropdown()}
          onChange={(_, id) => {
            if (id) {
              setFilter({
                ...filter,
                categoryId: id && id?.id.toString()
              })
            }
          }}
          ListboxProps={GlobalStyle.ListBox}
          value={
            dropdownCategory &&
            dropdownCategory.data &&
            dropdownCategory.data.find((e) => e.id == Number(filter.categoryId))
          }
          renderOption={(props, item) => (
            <li {...props} key={item.id}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='categoryId'
              label='Select A Category'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {dropdownCategory.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        /> */}
        <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
          <DatePicker
            value={startDate}
            onChange={handleChangeStartDate}
            label='Start Date'
            format='DD-MM-YYYY'
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
          <DatePicker
            value={endDate}
            minDate={dayjs(startDate)}
            onChange={handleChangeEndDate}
            label='End Date'
            format='DD-MM-YYYY'
          />
        </LocalizationProvider>
      </Filter>
      {dialogCreate && (
        <Create
          open={dialogCreate}
          onClose={() => setDialogCreate(false)}
          paramData={locationState.state}
        />
      )}

      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />

      <Notification
        open={!deleteCategory.isLoading && !deleteCategory.isUninitialized}
        onClose={() => (deleteCategory.isError ? deleteCategory.reset() : location.reload())}
        isError={Boolean(deleteCategory.error) && deleteCategory.isError}
        message={GLOBAL.returnExceptionMessage(
          deleteCategory.isError,
          deleteCategory.error as ErrorProps
        )}
      />
    </>
  )
}

export default VoucherSubCategory
