import { useState } from 'react'
import * as yup from 'yup'
import { Autocomplete, CircularProgress, Container, TextField } from '@mui/material'
import Dialog from 'components/dialog/dialog'
import type { PartialChargeBoxGroupProps } from 'modules/partial'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import { useGetChargePointDropdownListMutation } from 'store/evChargePoint'
import {
  useCreateChargeBoxGroupMutation,
  useGenerateCodeChargeBoxGroupQuery
} from 'store/evChargeBoxGroup'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import CONSTANT from 'modules/constant'
import GlobalStyle from 'modules/styles'
import FORMIK from 'modules/formik'
import DialogStyle from './style'

const Create = ({ open, onClose }: DetailStateProps) => {
  if (!open) return <></>

  const [createChargeBoxGroup, create] = useCreateChargeBoxGroupMutation()

  const [getChargePointDropdownList, chargePointDropdown] = useGetChargePointDropdownListMutation()

  const [chargePointId, setChargePointId] = useState<string>('')

  const { data: generateCodeChargeBoxGroup } = useGenerateCodeChargeBoxGroupQuery()

  const scheme = yup.object<PartialChargeBoxGroupProps>({
    chargePointId: yup.number(),
    code: yup.string().required('Code is required'),
    chargeBoxIdentity: yup.string().required('Charge Box Identity is required'),
    operative: yup.boolean(),
    online: yup.boolean(),
    chargerBrand: yup.string().required('Charger Brand is required'),
    connectorAmount: yup.number().required('Connector Amount is required'),
    vendor: yup.string().required('Provider is required'),
    remark: yup.string()
  })

  const initialValues: PartialChargeBoxGroupProps = {
    chargePointId: null,
    code: (generateCodeChargeBoxGroup && generateCodeChargeBoxGroup.code) || '',
    chargeBoxIdentity: '',
    operative: true,
    online: true,
    chargerBrand: '',
    connectorAmount: 0,
    vendor: '',
    remark: ''
  }

  const formik = FORMIK.useFormFormik<PartialChargeBoxGroupProps>(scheme, initialValues, (values) =>
    createChargeBoxGroup({
      body: { ...values, chargePointId: Number(chargePointId) },
      id: Number(chargePointId)
    })
  )

  const errorData = formik.errors

  return (
    <>
      <Dialog
        title='Create New EV Charge Box Group'
        open={open}
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={create.isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          <Autocomplete
            options={(chargePointDropdown && chargePointDropdown.data) || []}
            getOptionLabel={(option) => option.text}
            isOptionEqualToValue={(option, value) =>
              option && value ? option.value == value.value : false
            }
            onOpen={() => getChargePointDropdownList(CONSTANT.DEFAULT_DROPDOWN_PAYLOAD)}
            onChange={(_, chargePoint) => {
              const point = (chargePoint && String(chargePoint.value)) || ''

              setChargePointId(point)
              formik.setFieldValue('chargePointId', point)
            }}
            value={
              (chargePointDropdown &&
                chargePointDropdown.data &&
                chargePointDropdown.data.find((e) => String(e.value) == chargePointId)) ||
              undefined
            }
            ListboxProps={GlobalStyle.ListBox}
            renderOption={(props, item) => (
              <li {...props} key={item.value}>
                {item.text}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                name='ChargePointId'
                label='EV Charge Point'
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {chargePointDropdown.isLoading && (
                        <CircularProgress color='inherit' size={20} />
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
          <TextField
            id='chargeBoxIdentity'
            variant='outlined'
            label='Charge Box Identity'
            value={formik.values.chargeBoxIdentity}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched &&
              formik.touched.chargeBoxIdentity &&
              Boolean(errorData.chargeBoxIdentity)
            }
            helperText={
              formik.touched &&
              formik.touched.chargeBoxIdentity &&
              errorData &&
              errorData.chargeBoxIdentity
            }
            fullWidth
          />
          <TextField
            id='chargerBrand'
            variant='outlined'
            label='Charger Brand'
            value={formik.values.chargerBrand}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched && formik.touched.chargerBrand && Boolean(errorData.chargerBrand)}
            helperText={
              formik.touched && formik.touched.chargerBrand && errorData && errorData.chargerBrand
            }
            fullWidth
          />
          <TextField
            id='connectorAmount'
            variant='outlined'
            label='Connector Amount'
            value={formik.values.connectorAmount}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched && formik.touched.connectorAmount && Boolean(errorData.chargerBrand)
            }
            helperText={
              formik.touched &&
              formik.touched.connectorAmount &&
              errorData &&
              errorData.connectorAmount
            }
            fullWidth
          />
          <TextField
            id='vendor'
            variant='outlined'
            label='Provider'
            value={formik.values.vendor}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched && formik.touched.vendor && Boolean(errorData.vendor)}
            helperText={formik.touched && formik.touched.vendor && errorData && errorData.vendor}
            fullWidth
          />
        </Container>
      </Dialog>

      <Notification
        open={!create.isLoading && !create.isUninitialized}
        onClose={() => (create.isError ? create.reset() : location.reload())}
        isError={Boolean(create.error) && create.isError}
        message={GLOBAL.returnExceptionMessage(create.isError, create.error as ErrorProps)}
      />
    </>
  )
}

export default Create
