import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Autocomplete,
  Chip,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import type { PartialFormDataProps, PartialVoucherProps } from 'modules/partial'
import CONSTANT from 'modules/constant'
import {
  useDeleteListVoucherMutation,
  useDownloadListVoucherMutation,
  useEnableListVoucherMutation,
  useGetListVoucherMutation
} from 'store/voucher'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { ErrorProps } from 'modules/types'
import Icon from 'components/icon'
import MenuModalItem from 'components/menuItem/menuItem'
import GlobalStyle from 'modules/styles'
import Loading from 'components/loading/loading'
import {
  useGetVoucherCategoryDropdownMutation,
  useGetVoucherSubCategoryDropdownMutation
} from 'store/voucherCategory'
import { useGetTokenCategoryDropdownMutation } from 'store/tokenRequest'
import { useGetListPartnerLocationDropdownMutation } from 'store/locationPartner'
import Create from './create/create'
import Detail from './detail/detail'
import Edit from './edit/edit'
import Duplicate from './duplicate/duplicate'

const VoucherList = () => {
  const [getListVoucher, listVoucher] = useGetListVoucherMutation()
  const [downloadListVoucher, download] = useDownloadListVoucherMutation()
  const [enableListVoucher, enable] = useEnableListVoucherMutation()
  const [deleteListVoucher, deleteVoucher] = useDeleteListVoucherMutation()

  const [getVoucherCategoryDropdown, dropdownCategory] = useGetVoucherCategoryDropdownMutation()
  const [getVoucherSubCategoryDropdown, dropdownSubCategory] =
    useGetVoucherSubCategoryDropdownMutation()
  const [getPartnerDropdownList, partnerDropdown] = useGetListPartnerLocationDropdownMutation()
  const [getTokenCategoryDropdown, tokenCategoryDropdown] = useGetTokenCategoryDropdownMutation()

  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [menu, setMenu] = useState<null | number>(null)
  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [dialogCreate, setDialogCreate] = useState<boolean>(false)
  const [dialogUpdate, setDialogUpdate] = useState<boolean>(false)
  const [dialogDuplicate, setDialogDuplicate] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)

  const [randomKey, setRandomKey] = useState<number>(0)

  const [filter, setFilter] = useState<PartialVoucherProps>({
    status: '',
    applicableAreaMode: '',
    voucherType: '',
    partnerId: '',
    categoryId: '',
    subCategoryId: '',
    tokenCategoryId: '',
    minMonetaryValue: '',
    maxMonetaryValue: ''
  })

  const [payload, setPayload] = useState<PartialVoucherProps & PartialFormDataProps>({
    start: 0,
    length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    field: CONSTANT.DEFAULT_VOUCHER_LIST_FIELD.join(','),
    search: { value: '' },
    status: '',
    applicableAreaMode: '',
    voucherType: '',
    partnerId: '',
    categoryId: '',
    subCategoryId: '',
    tokenCategoryId: '',
    minMonetaryValue: '',
    maxMonetaryValue: ''
  })

  const onSearch = (value: string) => setPayload({ ...payload, start: 0, search: { value } })

  const onChangePage = (value: number) => setPayload({ ...payload, start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, length: value })

  const onResync = () => {
    const formData = new FormData()

    formData.append('start', payload.start.toString())
    formData.append('length', payload.length.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(payload.status) && payload.status) formData.append('status', payload.status)

    if (!isEmpty(payload.applicableAreaMode) && payload.applicableAreaMode)
      formData.append('applicable-area-mode', payload.applicableAreaMode)

    if (!isEmpty(payload.voucherType) && payload.voucherType)
      formData.append('voucher-type', payload.voucherType)

    if (!isEmpty(payload.partnerId) && payload.partnerId)
      formData.append('partner-id', payload.partnerId)

    if (!isEmpty(payload.categoryId) && payload.categoryId)
      formData.append('category-id', payload.categoryId)

    if (!isEmpty(payload.subCategoryId) && payload.subCategoryId)
      formData.append('sub-category-id', payload.subCategoryId)

    if (!isEmpty(payload.tokenCategoryId) && payload.tokenCategoryId)
      formData.append('token-category-id', payload.tokenCategoryId)

    if (!isEmpty(payload.minMonetaryValue) && payload.minMonetaryValue)
      formData.append('min-monetary-value', payload.minMonetaryValue)

    if (!isEmpty(payload.maxMonetaryValue) && payload.maxMonetaryValue)
      formData.append('max-monetary-value', payload.maxMonetaryValue)

    getListVoucher(formData)
  }

  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('start', String(0))
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(payload.status) && payload.status) formData.append('status', payload.status)

    if (!isEmpty(payload.applicableAreaMode) && payload.applicableAreaMode)
      formData.append('applicable-area-mode', payload.applicableAreaMode)

    if (!isEmpty(payload.voucherType) && payload.voucherType)
      formData.append('voucher-type', payload.voucherType)

    if (!isEmpty(payload.partnerId) && payload.partnerId)
      formData.append('partner-id', payload.partnerId)

    if (!isEmpty(payload.categoryId) && payload.categoryId)
      formData.append('category-id', payload.categoryId)

    if (!isEmpty(payload.subCategoryId) && payload.subCategoryId)
      formData.append('sub-category-id', payload.subCategoryId)

    if (!isEmpty(payload.tokenCategoryId) && payload.tokenCategoryId)
      formData.append('token-category-id', payload.tokenCategoryId)

    if (!isEmpty(payload.minMonetaryValue) && payload.minMonetaryValue)
      formData.append('min-monetary-value', payload.minMonetaryValue)

    if (!isEmpty(payload.maxMonetaryValue) && payload.maxMonetaryValue)
      formData.append('max-monetary-value', payload.maxMonetaryValue)

    if (type && !isEmpty(type)) formData.append('Download-Type', type)

    downloadListVoucher(formData)
  }

  const onFilter = (state: boolean) => setDialogFilter(state)
  const onCreate = () => setDialogCreate(true)

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback: CallableFunction) => {
    setAnchor(null)
    callback(callback)
  }

  const tableHeadTitles = [
    'ID',
    'Voucher Name',
    'Token Category',
    'Monetary Value',
    'Partner',
    'Applicable Area Mode',
    'Status'
  ]

  const openAnchor = Boolean(anchor)

  const totalPage = listVoucher.data && GLOBAL.tableTotalPage(listVoucher.data.recordsTotal)

  const numbers = GLOBAL.tableNumber(payload.start)

  useEffect(() => {
    onResync()
  }, [payload])

  return (
    <>
      <Content
        title='Voucher List'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        onAdditional={onCreate}
        additionalTitle='Create New Voucher'
        additionalPrefixIcon='Create'
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={listVoucher.data && listVoucher.data.recordsTotal}
        page={payload.start + 1}
        isLoading={listVoucher.isLoading}
        useDownloadDropdown
      >
        {listVoucher.isSuccess &&
          listVoucher.data &&
          listVoucher.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.id}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.id}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.id}`}
                  aria-labelledby={`button-${index}-${table.id}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View
                  </MenuItem>
                  <MenuItem onClick={() => onRemoveAnchor(() => setDialogUpdate(true))}>
                    Edit
                  </MenuItem>
                  <MenuItem onClick={() => onRemoveAnchor(() => setDialogDuplicate(true))}>
                    Duplicate
                  </MenuItem>
                  <MenuModalItem
                    title={table.status == 'Enabled' ? 'Disable' : 'Enable'}
                    onClick={() =>
                      onRemoveAnchor(() =>
                        enableListVoucher({
                          Id: table.id,
                          Status: table.status == 'Enabled' ? 'Disabled' : 'Enabled'
                        })
                      )
                    }
                  >
                    {table.status == 'Enabled' ? 'Disable' : 'Enable'}
                  </MenuModalItem>
                  <MenuModalItem
                    title='Delete'
                    loading={deleteVoucher.isLoading}
                    onClick={() =>
                      onRemoveAnchor(() =>
                        deleteListVoucher({
                          Id: table.id
                        })
                      )
                    }
                  >
                    <Typography {...GlobalStyle.ErrorTypography}>Delete</Typography>
                  </MenuModalItem>
                </Menu>
                <Detail
                  id={table.id}
                  open={drawerDetail && menu == index}
                  onClose={() => setDrawerDetail(false)}
                />
                <Edit
                  id={table.id}
                  title={table.title}
                  open={dialogUpdate && menu == index}
                  onClose={() => setDialogUpdate(false)}
                />
                <Duplicate
                  id={table.id}
                  title={table.title}
                  open={dialogDuplicate && menu == index}
                  onClose={() => setDialogDuplicate(false)}
                />
              </TableCell>
              <TableCell>{table.id}</TableCell>
              <TableCell>{table.title || '-'}</TableCell>
              <TableCell>{table.tokenCategory || '-'}</TableCell>
              <TableCell>{table.monetaryValue || '-'}</TableCell>
              <TableCell>{table.partnerName || '-'}</TableCell>
              <TableCell>{table.applicableAreaMode || '-'}</TableCell>
              <TableCell>
                <Chip
                  label={table.status}
                  color={table.status == 'Enabled' ? 'success' : 'error'}
                />
              </TableCell>
            </TableRow>
          ))}
      </Content>

      {listVoucher.isLoading && <Loading />}

      <Create open={dialogCreate} onClose={() => setDialogCreate(false)} />

      <Filter
        open={dialogFilter}
        title='Filter'
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        onReset={() => {
          setRandomKey(randomKey + 1)
          setFilter({
            status: '',
            applicableAreaMode: '',
            voucherType: '',
            partnerId: '',
            categoryId: '',
            subCategoryId: '',
            tokenCategoryId: '',
            minMonetaryValue: '',
            maxMonetaryValue: ''
          })
          setPayload({
            ...payload,
            start: 0,
            status: '',
            applicableAreaMode: '',
            voucherType: '',
            partnerId: '',
            categoryId: '',
            subCategoryId: '',
            tokenCategoryId: '',
            minMonetaryValue: '',
            maxMonetaryValue: ''
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({ ...payload, start: 0, ...filter })
          onFilter(false)
        }}
      >
        <Autocomplete
          key={'categoryId-' + randomKey}
          options={(dropdownCategory && dropdownCategory.data) || []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.id == value.id : false
          }
          onOpen={() => getVoucherCategoryDropdown()}
          onChange={(_, voucherCategory) => {
            dropdownSubCategory.reset()
            setFilter({
              ...filter,
              categoryId: String((voucherCategory && voucherCategory.id) || ''),
              subCategoryId: ''
            })
          }}
          value={
            (dropdownCategory &&
              dropdownCategory.data &&
              dropdownCategory.data.find((e) => String(e.id) == filter.categoryId)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.id}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='categoryId'
              label='Category ID'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {dropdownCategory.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <Autocomplete
          key={'subCategoryId-' + randomKey}
          options={(dropdownSubCategory && dropdownSubCategory.data) || []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.id == value.id : false
          }
          onOpen={() => getVoucherSubCategoryDropdown({ id: filter.categoryId })}
          onChange={(_, voucherSubCategory) =>
            setFilter({
              ...filter,
              subCategoryId: String((voucherSubCategory && voucherSubCategory.id) || '')
            })
          }
          value={
            (dropdownSubCategory &&
              dropdownSubCategory.data &&
              dropdownSubCategory.data.find((e) => String(e.id) == filter.subCategoryId)) ||
            undefined
          }
          disabled={!filter.categoryId}
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.id}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='subCategoryId'
              label='Sub Category ID'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {dropdownSubCategory.isLoading && (
                      <CircularProgress color='inherit' size={20} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <Autocomplete
          options={(partnerDropdown && partnerDropdown.data) || []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.id == value.id : false
          }
          onOpen={() => getPartnerDropdownList()}
          onChange={(_, partner) =>
            setFilter({
              ...filter,
              partnerId: (partner && String(partner.id)) || ''
            })
          }
          value={
            (partnerDropdown &&
              partnerDropdown.data &&
              partnerDropdown.data.find((e) => String(e.id) == filter.partnerId)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.id}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='partnerId'
              label='Partner'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {partnerDropdown.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <Autocomplete
          options={(tokenCategoryDropdown && tokenCategoryDropdown.data) || []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.id == value.id : false
          }
          onOpen={() => getTokenCategoryDropdown()}
          onChange={(_, tokenCategory) =>
            setFilter({
              ...filter,
              tokenCategoryId: String((tokenCategory && tokenCategory.id) || '')
            })
          }
          value={
            (tokenCategoryDropdown &&
              tokenCategoryDropdown.data &&
              tokenCategoryDropdown.data.find((e) => String(e.id) == filter.tokenCategoryId)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.id}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='tokenCategoryId'
              label='Token Category'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {tokenCategoryDropdown.isLoading && (
                      <CircularProgress color='inherit' size={20} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <TextField
          id='minMonetaryValue'
          variant='outlined'
          label='Min Monetary Value'
          type='number'
          InputProps={{ inputProps: { min: 0 } }}
          value={filter.minMonetaryValue && filter.minMonetaryValue.toString()}
          onChange={(event) => setFilter({ ...filter, minMonetaryValue: event.target.value })}
          onKeyDown={(event) => {
            if (/^[.,\-\b]+$/.test(event.key)) event.preventDefault()
          }}
          fullWidth
        />
        <TextField
          id='maxMonetaryValue'
          variant='outlined'
          label='Max Monetary Value'
          type='number'
          InputProps={{ inputProps: { min: 0 } }}
          value={filter.maxMonetaryValue && filter.maxMonetaryValue.toString()}
          onChange={(event) => setFilter({ ...filter, maxMonetaryValue: event.target.value })}
          onKeyDown={(event) => {
            if (/^[.,\-\b]+$/.test(event.key)) event.preventDefault()
          }}
          fullWidth
        />
      </Filter>

      <Notification
        open={!listVoucher.isLoading && !listVoucher.isUninitialized && !listVoucher.isSuccess}
        onClose={() => (listVoucher.isError ? listVoucher.reset() : location.reload())}
        isError={Boolean(listVoucher.error) && listVoucher.isError}
        message={GLOBAL.returnExceptionMessage(
          listVoucher.isError,
          listVoucher.error as ErrorProps
        )}
      />

      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />

      <Notification
        open={!enable.isLoading && !enable.isUninitialized}
        onClose={() => (enable.isError ? enable.reset() : location.reload())}
        isError={Boolean(enable.error) && enable.isError}
        message={GLOBAL.returnExceptionMessage(enable.isError, enable.error as ErrorProps)}
      />

      <Notification
        open={!deleteVoucher.isLoading && !deleteVoucher.isUninitialized}
        onClose={() => (deleteVoucher.isError ? deleteVoucher.reset() : location.reload())}
        isError={Boolean(deleteVoucher.error) && deleteVoucher.isError}
        message={GLOBAL.returnExceptionMessage(
          deleteVoucher.isError,
          deleteVoucher.error as ErrorProps
        )}
      />
    </>
  )
}

export default VoucherList
