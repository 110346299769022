import type { HTMLAttributes } from 'react'
import type { SxProps, TableCellProps, Theme, TypographyProps } from '@mui/material'

class GlobalStyle {
  static EllipsisTable: TableCellProps = {
    sx: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: 'full',
      maxWidth: 208
    }
  }

  static keepTextTable: TableCellProps = {
    sx: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: 'full'
    }
  }

  static ErrorTypography: TypographyProps = {
    color: 'red'
  }

  static ListBox: HTMLAttributes<HTMLUListElement> & { sx?: SxProps<Theme> | undefined } = {
    style: {
      maxHeight: '12rem'
    }
  }
}

export default GlobalStyle
