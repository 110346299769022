import CONSTANT from 'modules/constant'
import type {
  PaginationProps,
  QrCodeDetailProps,
  QrCodeListProps,
  ResultProps
} from 'modules/types'
import { API, download, headers } from './api'

const QrCodeAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getQrCodeList: builder.mutation<PaginationProps<QrCodeListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_EV_QRCODE_LIST,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    deleteQrCode: builder.mutation<ResultProps, number>({
      query: (id) => ({
        url: CONSTANT.URL_EV_QRCODE_DELETE + `${id}/qr`,
        method: 'DELETE',
        headers: headers()
      })
    }),
    getDetailQrCode: builder.query<QrCodeDetailProps, number>({
      query: (id) => ({
        url: CONSTANT.URL_EV_QRCODE_DETAIL + `${id}/qr`,
        method: 'GET',
        headers: headers()
      })
    }),
    downloadQrCode: builder.mutation<PaginationProps<QrCodeListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_EV_QRCODE_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        responseHandler: download,
        body: body,
        formData: true
      })
    }),
    createQrCode: builder.mutation<ResultProps, { id: string; body: FormData }>({
      query: (params) => ({
        url: CONSTANT.URL_EV_QRCODE_CREATE + `${params.id}/qr`,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: params.body,
        formData: true
      })
    })
  })
})

export const {
  useGetQrCodeListMutation,
  useDeleteQrCodeMutation,
  useDownloadQrCodeMutation,
  useCreateQrCodeMutation,
  useGetDetailQrCodeQuery
} = QrCodeAPI
