import type {
  EVPriceDetailProps,
  EVPriceProps,
  GenerateCodeProps,
  PaginationProps,
  ResultProps
} from 'modules/types'
import CONSTANT from 'modules/constant'
import type { PartialEVPriceDetailProps } from 'modules/partial'
import { API, download, headers } from './api'

const evPriceAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getPriceList: builder.mutation<PaginationProps<EVPriceProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_EV_PRICE_LIST,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    getPriceListDetail: builder.query<EVPriceDetailProps, number>({
      query: (id) => ({
        url: CONSTANT.URL_EV_PRICE_DETAIL + id,
        method: 'GET',
        headers: headers()
      })
    }),
    downloadPriceList: builder.mutation<PaginationProps<EVPriceProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_EV_PRICE_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true,
        responseHandler: download
      })
    }),
    createPrice: builder.mutation<ResultProps, PartialEVPriceDetailProps>({
      query: (body) => ({
        url: CONSTANT.URL_EV_PRICE_CREATE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    updatePrice: builder.mutation<ResultProps, { id: number; body: PartialEVPriceDetailProps }>({
      query: (query) => ({
        url: CONSTANT.URL_EV_PRICE_UPDATE + query.id,
        method: 'PUT',
        headers: headers(),
        body: query.body
      })
    }),
    deletePrice: builder.mutation<ResultProps, number>({
      query: (id) => ({
        url: CONSTANT.URL_EV_PRICE_DELETE + id,
        method: 'DELETE',
        headers: headers()
      })
    }),
    generateCodePrice: builder.mutation<GenerateCodeProps, void>({
      query: () => ({
        url: CONSTANT.URL_EV_PRICE_GENERATE,
        method: 'GET',
        headers: headers()
      })
    })
  })
})

export const {
  useGetPriceListMutation,
  useGetPriceListDetailQuery,
  useDownloadPriceListMutation,
  useCreatePriceMutation,
  useUpdatePriceMutation,
  useDeletePriceMutation,
  useGenerateCodePriceMutation
} = evPriceAPI
