import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TableCell,
  TableRow
} from '@mui/material'
import moment from 'moment'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import { useGetNotificationCustomerListMutation } from 'store/notificationCustomer'
import type { PartialFormDataProps, PartialNotificationCustomerFilterProps } from 'modules/partial'
import CONSTANT from 'modules/constant'
import GLOBAL from 'modules/global'
import Icon from 'components/icon'
import Loading from 'components/loading/loading'
import type { ErrorProps } from 'modules/types'
import Notification from 'components/notification'
import Detail from './detail/detail'
import Create from './create/create'

const NotificationCustomerPhone = () => {
  const [getListNotification, listNotification] = useGetNotificationCustomerListMutation()

  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [menu, setMenu] = useState<null | number>(null)
  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [dialogCreate, setDialogCreate] = useState<boolean>(false)

  const defaultField = 'phone'

  const [filter, setFilter] = useState<PartialNotificationCustomerFilterProps>({
    otpUsing: defaultField,
    status: '',
    minCreatedDate: '',
    maxCreatedDate: ''
  })

  const [payload, setPayload] = useState<
    PartialNotificationCustomerFilterProps & PartialFormDataProps
  >({
    start: 0,
    length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    field: [defaultField, ...CONSTANT.DEFAULT_NOTIFICATION_CUSTOMER_FIELD].join(','),
    search: { value: '' },
    otpUsing: defaultField,
    status: '',
    minCreatedDate: '',
    maxCreatedDate: ''
  })

  const onSearch = (value: string) => setPayload({ ...payload, start: 0, search: { value } })

  const onResync = () => {
    const formData = new FormData()

    formData.append('start', payload.start.toString())
    formData.append('length', payload.length.toString())
    formData.append('field', payload.field)
    formData.append('otpUsing', payload.otpUsing)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(payload.status) && payload.status) formData.append('status', payload.status)
    if (!isEmpty(payload.minCreatedDate) && payload.minCreatedDate)
      formData.append('minCreatedDate', payload.minCreatedDate)
    if (!isEmpty(payload.maxCreatedDate) && payload.maxCreatedDate)
      formData.append('maxCreatedDate', payload.maxCreatedDate)

    getListNotification(formData)
  }

  const onFilter = (state: boolean) => setDialogFilter(state)

  const onChangePage = (value: number) => setPayload({ ...payload, start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, length: value })

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback: CallableFunction) => {
    setAnchor(null)
    callback(callback)
  }

  const tableHeadTitles = ['Assigned To', 'Title', 'Status', 'Scheduled Date', 'Notes']

  const openAnchor = Boolean(anchor)

  const totalPage =
    listNotification.data && GLOBAL.tableTotalPage(listNotification.data.recordsTotal)

  const numbers = GLOBAL.tableNumber(payload.start)
  const onCreate = () => setDialogCreate(true)

  useEffect(() => {
    onResync()
  }, [payload])

  return (
    <>
      <Content
        title='Push Notification'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onAdditional={onCreate}
        additionalTitle='Create New Push Notification'
        additionalPrefixIcon='Create'
        onFilter={() => onFilter(true)}
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={listNotification.data && listNotification.data.recordsTotal}
        page={payload.start + 1}
        isLoading={listNotification.isLoading}
      >
        {listNotification.isSuccess &&
          listNotification.data &&
          listNotification.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.id}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.id}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.id}`}
                  aria-labelledby={`button-${index}-${table.id}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View
                  </MenuItem>
                </Menu>
                <Detail
                  type='phone'
                  unique={table.key}
                  open={drawerDetail && menu == index}
                  onClose={() => setDrawerDetail(false)}
                />
              </TableCell>
              <TableCell>{table.phone || '-'}</TableCell>
              <TableCell>{table.description || '-'}</TableCell>
              <TableCell> {table.pin || '-'}</TableCell>
              <TableCell>{table.status || '-'}</TableCell>
              <TableCell>
                {table.createdAt ? moment(table.createdAt).format('DD-MM-YYYY HH:mm:ss') : '-'}
              </TableCell>
            </TableRow>
          ))}
      </Content>

      {dialogCreate && <Create open={dialogCreate} onClose={() => setDialogCreate(false)} />}
      {listNotification.isLoading && <Loading />}

      <Filter
        open={dialogFilter}
        title='Filter'
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        onReset={() => {
          setFilter({
            otpUsing: defaultField,
            status: '',
            minCreatedDate: '',
            maxCreatedDate: ''
          })
          setPayload({
            ...payload,
            start: 0,
            otpUsing: defaultField,
            status: '',
            minCreatedDate: '',
            maxCreatedDate: ''
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({ ...payload, start: 0, ...filter })
          onFilter(false)
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
          <DatePicker
            value={filter.minCreatedDate ? dayjs(filter.minCreatedDate) : null}
            minDate={dayjs(filter.minCreatedDate)}
            onChange={(event: Dayjs | null) =>
              setFilter({ ...filter, minCreatedDate: (event && event.format('YYYY-MM-DD')) || '' })
            }
            label='Min Created Date'
            format='DD-MM-YYYY'
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
          <DatePicker
            value={filter.maxCreatedDate ? dayjs(filter.maxCreatedDate) : null}
            minDate={dayjs(filter.minCreatedDate)}
            onChange={(event: Dayjs | null) =>
              setFilter({ ...filter, maxCreatedDate: (event && event.format('YYYY-MM-DD')) || '' })
            }
            label='Max Created Date'
            format='DD-MM-YYYY'
          />
        </LocalizationProvider>
        <FormControl fullWidth>
          <InputLabel id='Status'>Status</InputLabel>
          <Select
            labelId='Status'
            id='Status'
            value={filter.status}
            label='Status'
            onChange={(event) => setFilter({ ...filter, status: event.target.value })}
          >
            <MenuItem value='Pending'>Pending</MenuItem>
            <MenuItem value='Processing'>Processing</MenuItem>
            <MenuItem value='Sent'>Sent</MenuItem>
            <MenuItem value='Error'>Error</MenuItem>
          </Select>
        </FormControl>
      </Filter>

      <Notification
        open={
          !listNotification.isLoading &&
          !listNotification.isUninitialized &&
          !listNotification.isSuccess
        }
        onClose={() => (listNotification.isError ? listNotification.reset() : location.reload())}
        isError={Boolean(listNotification.error) && listNotification.isError}
        message={GLOBAL.returnExceptionMessage(
          listNotification.isError,
          listNotification.error as ErrorProps
        )}
      />
    </>
  )
}

export default NotificationCustomerPhone
