import CONSTANT from 'modules/constant'
import type { NotificationCustomerProps, PaginationProps } from 'modules/types'
import { API, headers } from './api'

const NotificationCustomerAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getNotificationCustomerList: builder.mutation<
      PaginationProps<NotificationCustomerProps>,
      FormData
    >({
      query: (body) => ({
        url: CONSTANT.URL_NOTIFICATION_CUSTOMER_LIST,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    getNotificationCustomerDetail: builder.query<
      NotificationCustomerProps,
      { type: string; key: string }
    >({
      query: (query) => ({
        url: CONSTANT.URL_NOTIFICATION_CUSTOMER_DETAIL + query.type + '/' + query.key,
        method: 'GET',
        headers: headers()
      })
    })
  })
})

export const { useGetNotificationCustomerListMutation, useGetNotificationCustomerDetailQuery } =
  NotificationCustomerAPI
