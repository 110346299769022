import type { ContainerProps, TabProps } from '@mui/material'

class TabStyle {
  static Container: ContainerProps = {
    maxWidth: false,
    disableGutters: true,
    sx: {
      padding: 2
    }
  }

  static Tab: TabProps = {
    sx: {
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: 'divider'
    }
  }
}

export default TabStyle
