import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Autocomplete,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import CONSTANT from 'modules/constant'
import type { PartialChargeBoxGroupFilterProps, PartialEVChargeProps } from 'modules/partial'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { ErrorProps } from 'modules/types'
import MenuModalItem from 'components/menuItem/menuItem'
import GlobalStyle from 'modules/styles'
import Icon from 'components/icon'
import {
  useDeleteChargeBoxGroupMutation,
  useDownloadChargeBoxGroupListMutation,
  useGetChargeBoxGroupListMutation
} from 'store/evChargeBoxGroup'
import Loading from 'components/loading/loading'
import { useGetChargePointDropdownListMutation } from 'store/evChargePoint'
import { useGetStatusChargingDropdownListMutation } from 'store/dropdown'
import Detail from './detail/detail'
import Create from './create/create'
import Edit from './edit/edit'

const EVChargeBoxGroup = () => {
  const [getChargeBoxGroup, chargeBoxGroup] = useGetChargeBoxGroupListMutation()
  const [downloadChargeBoxGroup, download] = useDownloadChargeBoxGroupListMutation()
  const [deleteChargeBoxGroup, deleteBoxGroup] = useDeleteChargeBoxGroupMutation()

  const [getChargePointDropdownList, chargePointDropdown] = useGetChargePointDropdownListMutation()
  const [getStatusChargingDropdownList, statusChargingDropdown] =
    useGetStatusChargingDropdownListMutation()

  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [menu, setMenu] = useState<null | number>(null)
  const [dialogCreate, setDialogCreate] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [drawerUpdate, setDrawerUpdate] = useState<boolean>(false)

  const [filter, setFilter] = useState<PartialChargeBoxGroupFilterProps>({
    ChargePointId: '',
    Online: '',
    ChargerBrand: '',
    ConnectorAmount: '',
    Vendor: '',
    Status: ''
  })

  const [payload, setPayload] = useState<PartialEVChargeProps & PartialChargeBoxGroupFilterProps>({
    start: 0,
    length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    search: '',
    filter: '',
    sorting: 'id asc',
    ChargePointId: '',
    Online: '',
    ChargerBrand: '',
    ConnectorAmount: '',
    Vendor: '',
    Status: ''
  })

  const onResync = () => {
    const formData = new FormData()

    formData.append('start', payload.start.toString())
    formData.append('length', payload.length.toString())
    formData.append('sorting', payload.sorting)

    if (!isEmpty(payload.search)) formData.append('search', payload.search)
    if (!isEmpty(payload.filter)) formData.append('filter', payload.filter)
    if (!isEmpty(payload.ChargePointId)) formData.append('ChargePointId', payload.ChargePointId)
    if (!isEmpty(payload.Online)) formData.append('Online', String(payload.Online))
    if (!isEmpty(payload.ChargerBrand)) formData.append('ChargerBrand', payload.ChargerBrand)
    if (!isEmpty(payload.ConnectorAmount))
      formData.append('ConnectorAmount', payload.ConnectorAmount)
    if (!isEmpty(payload.Vendor)) formData.append('Vendor', payload.Vendor)

    getChargeBoxGroup(formData)
  }

  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('start', String(0))
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())
    formData.append('sorting', payload.sorting)

    if (!isEmpty(payload.search)) formData.append('search', payload.search)
    if (!isEmpty(payload.filter)) formData.append('filter', payload.filter)
    if (!isEmpty(payload.ChargePointId)) formData.append('ChargePointId', payload.ChargePointId)
    if (!isEmpty(payload.Online)) formData.append('Online', String(payload.Online))
    if (!isEmpty(payload.ChargerBrand)) formData.append('ChargerBrand', payload.ChargerBrand)
    if (!isEmpty(payload.ConnectorAmount))
      formData.append('ConnectorAmount', payload.ConnectorAmount)
    if (!isEmpty(payload.Vendor)) formData.append('Vendor', payload.Vendor)
    if (type && !isEmpty(type)) formData.append('Content-Type', type)

    downloadChargeBoxGroup(formData)
  }

  const onSearch = (value: string) => setPayload({ ...payload, start: 0, search: value })
  const onChangePage = (value: number) => setPayload({ ...payload, start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, length: value })

  const onFilter = (state: boolean) => setDialogFilter(state)
  const onCreate = () => setDialogCreate(true)

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const tableHeadTitles = [
    'Charge Box Group ID',
    'EV Charge Point',
    'Online',
    'Charger Brand',
    'Connector Amount'
  ]

  const openAnchor = Boolean(anchor)

  const totalPage = chargeBoxGroup.data && GLOBAL.tableTotalPage(chargeBoxGroup.data.recordsTotal)

  const numbers = GLOBAL.tableNumber(payload.start)

  useEffect(() => {
    onResync()
  }, [payload])

  return (
    <>
      <Content
        title='EV Charge Box Group'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        onAdditional={onCreate}
        additionalTitle='Create New EV Charge Box Group'
        additionalPrefixIcon='Create'
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={chargeBoxGroup.data && chargeBoxGroup.data.recordsTotal}
        page={payload.start + 1}
        isLoading={chargeBoxGroup.isLoading}
        useDownloadDropdown
      >
        {chargeBoxGroup.isSuccess &&
          chargeBoxGroup.data &&
          chargeBoxGroup.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.id}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.id}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.id}`}
                  aria-labelledby={`button-${index}-${table.id}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View
                  </MenuItem>
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerUpdate(true))}>
                    Edit
                  </MenuItem>
                  <MenuModalItem
                    title='Delete'
                    loading={deleteBoxGroup.isLoading}
                    onClick={() => onRemoveAnchor(() => deleteChargeBoxGroup(table.id))}
                  >
                    <Typography {...GlobalStyle.ErrorTypography}>Delete</Typography>
                  </MenuModalItem>
                </Menu>
                <Detail
                  id={table.id}
                  open={drawerDetail && menu == index}
                  onClose={() => setDrawerDetail(false)}
                />
                <Edit
                  id={table.id}
                  open={drawerUpdate && menu == index}
                  onClose={() => setDrawerUpdate(false)}
                />
              </TableCell>
              <TableCell>{table.code || '-'}</TableCell>
              <TableCell>{table.chargePointName || '-'}</TableCell>
              <TableCell>{table.online.toString()}</TableCell>
              <TableCell>{table.chargerBrand || '-'}</TableCell>
              <TableCell>{table.connectorAmount || '-'}</TableCell>
            </TableRow>
          ))}
      </Content>

      {chargeBoxGroup.isLoading && <Loading />}

      <Create open={dialogCreate} onClose={() => setDialogCreate(false)} />

      <Filter
        open={dialogFilter}
        title='Filter'
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
        onReset={() => {
          setFilter({
            ChargePointId: '',
            Online: '',
            ChargerBrand: '',
            ConnectorAmount: '',
            Vendor: '',
            Status: ''
          })
          setPayload({
            ...payload,
            ChargePointId: '',
            Online: '',
            ChargerBrand: '',
            ConnectorAmount: '',
            Vendor: '',
            Status: '',
            start: 0
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({
            ...payload,
            ...filter,
            start: 0
          })
          onFilter(false)
        }}
      >
        <Autocomplete
          options={(chargePointDropdown && chargePointDropdown.data) || []}
          getOptionLabel={(option) => option.text}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.value == value.value : false
          }
          onOpen={() => getChargePointDropdownList(CONSTANT.DEFAULT_DROPDOWN_PAYLOAD)}
          onChange={(_, chargePoint) =>
            chargePoint &&
            setFilter({
              ...filter,
              ChargePointId: String(chargePoint.value)
            })
          }
          value={
            (chargePointDropdown &&
              chargePointDropdown.data &&
              chargePointDropdown.data.find((e) => String(e.value) == filter.ChargePointId)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.value}>
              {item.text}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='ChargePointId'
              label='EV Charge Point'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {chargePointDropdown.isLoading && (
                      <CircularProgress color='inherit' size={20} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <Autocomplete
          options={(statusChargingDropdown && statusChargingDropdown.data) || []}
          getOptionLabel={(option) => option.text}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.value == value.value : false
          }
          onOpen={() => getStatusChargingDropdownList(CONSTANT.DEFAULT_DROPDOWN_PAYLOAD)}
          onChange={(_, chargePoint) =>
            chargePoint &&
            setFilter({
              ...filter,
              Status: String(chargePoint.value)
            })
          }
          value={
            (statusChargingDropdown &&
              statusChargingDropdown.data &&
              statusChargingDropdown.data.find((e) => String(e.value) == filter.Status)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.value}>
              {item.text}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='status'
              label='Charge Box Status'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {statusChargingDropdown.isLoading && (
                      <CircularProgress color='inherit' size={20} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <TextField
          id='ChargerBrand'
          variant='outlined'
          label='Charger Brand'
          type='text'
          value={filter.ChargerBrand}
          onChange={(event) => setFilter({ ...filter, ChargerBrand: event.target.value })}
          fullWidth
        />
        <TextField
          id='ConnectorAmount'
          variant='outlined'
          label='Connector Amount'
          type='number'
          value={filter.ConnectorAmount}
          onChange={(event) => setFilter({ ...filter, ConnectorAmount: event.target.value })}
          fullWidth
        />
        <TextField
          id='Vendor'
          variant='outlined'
          label='Vendor'
          type='text'
          value={filter.Vendor}
          onChange={(event) => setFilter({ ...filter, Vendor: event.target.value })}
          fullWidth
        />
      </Filter>

      <Notification
        open={
          !chargeBoxGroup.isLoading && !chargeBoxGroup.isUninitialized && !chargeBoxGroup.isSuccess
        }
        onClose={() => (chargeBoxGroup.isError ? chargeBoxGroup.reset() : location.reload())}
        isError={Boolean(chargeBoxGroup.error) && chargeBoxGroup.isError}
        message={GLOBAL.returnExceptionMessage(
          chargeBoxGroup.isError,
          chargeBoxGroup.error as ErrorProps
        )}
      />

      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />

      <Notification
        open={!deleteBoxGroup.isLoading && !deleteBoxGroup.isUninitialized}
        onClose={() => (deleteBoxGroup.isError ? deleteBoxGroup.reset() : location.reload())}
        isError={Boolean(deleteBoxGroup.error) && deleteBoxGroup.isError}
        message={GLOBAL.returnExceptionMessage(
          deleteBoxGroup.isError,
          deleteBoxGroup.error as ErrorProps
        )}
      />
    </>
  )
}

export default EVChargeBoxGroup
