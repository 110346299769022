import CONSTANT from 'modules/constant'
import type { EvChargingUrlProps, PaginationProps, ResultProps } from 'modules/types'
import type { PartialEvChargingUrlProps } from 'modules/partial'
import { API, download, headers } from './api'

const evChargingUrlAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getChargingList: builder.mutation<PaginationProps<EvChargingUrlProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_EV_CHARGING_URL_LIST,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    downloadChargingList: builder.mutation<PaginationProps<EvChargingUrlProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_EV_CHARGING_URL_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        responseHandler: download
      })
    }),
    getChargingDetail: builder.query<EvChargingUrlProps, number>({
      query: (id) => ({
        url: CONSTANT.URL_EV_CHARGING_URL_DETAIL + id,
        method: 'GET',
        headers: headers()
      })
    }),
    createChargingUrl: builder.mutation<ResultProps, PartialEvChargingUrlProps>({
      query: (body) => ({
        url: CONSTANT.URL_EV_CHARGING_URL_CREATE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    updateChargingUrl: builder.mutation<
      ResultProps,
      { body: PartialEvChargingUrlProps; id?: number }
    >({
      query: (query) => ({
        url: CONSTANT.URL_EV_CHARGING_URL_UPDATE + query.id,
        method: 'PUT',
        headers: headers(),
        body: query.body
      })
    }),
    deleteChargingList: builder.mutation<ResultProps, number>({
      query: (id) => ({
        url: CONSTANT.URL_EV_CHARGING_URL_DELETE + id,
        method: 'DELETE',
        headers: headers()
      })
    })
  })
})

export const {
  useGetChargingListMutation,
  useDownloadChargingListMutation,
  useGetChargingDetailQuery,
  useCreateChargingUrlMutation,
  useUpdateChargingUrlMutation,
  useDeleteChargingListMutation
} = evChargingUrlAPI
