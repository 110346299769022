// import { useState } from 'react'
import { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { TableCell, TableRow } from '@mui/material'
import Content from 'components/content/content'
import type { ErrorProps, ParamProps } from 'modules/types'
import { useGetEvTransactionLogMutation } from 'store/evTransactionLog'
import Loading from 'components/loading/loading'
import GLOBAL from 'modules/global'
import Notification from 'components/notification'

const EVTransactionLog = () => {
  const params = useParams<ParamProps>()
  const location = useLocation()

  const [getEvTransactionLog, transaction] = useGetEvTransactionLogMutation()

  const id = params && params.id
  const pathArray = location.pathname.split('/')
  const title = `${pathArray[pathArray.length - 2]} › Transaction Log`

  const eventStartsHeadTable = [
    'ID Tag',
    'Meter Start',
    'Charge Point Code',
    'Charge Box Group Code',
    'Charge Box Code',
    'Status',
    'Timestamp Start',
    'Created At'
  ]

  const eventMeterValuesHeadTable = [
    'Transaction ID',
    'Value',
    'Charge Point Code',
    'Charge Box Group Code',
    'Charge Box Code',
    'Measurand',
    'unit',
    'Created At'
  ]

  const eventStopsHeadTable = [
    'ID Tag',
    'Meter Start',
    'Meter Stop',
    'Charge Point Code',
    'Charge Box Group Code',
    'Charge Box Code',
    'Energy',
    'Duration',
    'Status',
    'Timestamp Start',
    'Timestamp Stop',
    'Created At'
  ]

  useEffect(() => {
    if (id) getEvTransactionLog(id)
  }, [id])

  const records = transaction.data && {
    eventStarts: transaction.data.eventStarts && transaction.data.eventStarts.length,
    eventMeterValues: transaction.data.eventMeterValues && transaction.data.eventMeterValues.length,
    eventStops: transaction.data.eventStops && transaction.data.eventStops.length
  }

  return (
    <>
      <Content
        title={title}
        isLoading={transaction.isLoading}
        usePagination={false}
        useAction={false}
        useTable={false}
        useBackButton
      >
        {transaction.isSuccess && transaction.data && (
          <>
            <Content
              title='Event Starts'
              tableHeadTitles={eventStartsHeadTable}
              totalRecords={records && records.eventStarts}
              usePagination={false}
              useAction={false}
            >
              {transaction.data.eventStarts &&
                transaction.data.eventStarts.map((table, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{table.idTagStart || '-'}</TableCell>
                    <TableCell>{table.meterStart || '-'}</TableCell>
                    <TableCell>{table.chargePointId || '-'}</TableCell>
                    <TableCell>{table.chargeBoxGroupId || '-'}</TableCell>
                    <TableCell>{table.chargeBoxId || '-'}</TableCell>
                    <TableCell>{table.state || '-'}</TableCell>
                    <TableCell>
                      {(table.timestampStart && GLOBAL.formatDateWithTime(table.timestampStart)) ||
                        '-'}
                    </TableCell>
                    <TableCell>
                      {(table.createdAt && GLOBAL.formatDateWithTime(table.createdAt)) || '-'}
                    </TableCell>
                  </TableRow>
                ))}
            </Content>

            <Content
              title='Event Meter Values'
              tableHeadTitles={eventMeterValuesHeadTable}
              totalRecords={records && records.eventMeterValues}
              usePagination={false}
              useAction={false}
            >
              {transaction.data.eventMeterValues &&
                transaction.data.eventMeterValues.map((table, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{table.transactionId || '-'}</TableCell>
                    <TableCell>{table.value || '-'}</TableCell>
                    <TableCell>{table.chargePointId || '-'}</TableCell>
                    <TableCell>{table.chargeBoxGroupId || '-'}</TableCell>
                    <TableCell>{table.chargeBoxId || '-'}</TableCell>
                    <TableCell>{table.measurand || '-'}</TableCell>
                    <TableCell>{table.unit || '-'}</TableCell>
                    <TableCell>
                      {(table.createdAt && GLOBAL.formatDateWithTime(table.createdAt)) || '-'}
                    </TableCell>
                  </TableRow>
                ))}
            </Content>

            <Content
              title='Event Stops'
              tableHeadTitles={eventStopsHeadTable}
              totalRecords={records && records.eventStops}
              usePagination={false}
              useAction={false}
            >
              {transaction.data.eventStops &&
                transaction.data.eventStops.map((table, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{table.idTagStart || '-'}</TableCell>
                    <TableCell>{table.meterStart || '-'}</TableCell>
                    <TableCell>{table.meterStop || '-'}</TableCell>
                    <TableCell>{table.chargePointId || '-'}</TableCell>
                    <TableCell>{table.chargeBoxGroupId || '-'}</TableCell>
                    <TableCell>{table.chargeBoxId || '-'}</TableCell>
                    <TableCell>{table.energy || '-'}</TableCell>
                    <TableCell>{table.duration || '-'}</TableCell>
                    <TableCell>{table.state || '-'}</TableCell>
                    <TableCell>
                      {(table.timestampStart && GLOBAL.formatDateWithTime(table.timestampStart)) ||
                        '-'}
                    </TableCell>
                    <TableCell>
                      {(table.timestampStop && GLOBAL.formatDateWithTime(table.timestampStop)) ||
                        '-'}
                    </TableCell>
                    <TableCell>
                      {(table.createdAt && GLOBAL.formatDateWithTime(table.createdAt)) || '-'}
                    </TableCell>
                  </TableRow>
                ))}
            </Content>
          </>
        )}
      </Content>

      {transaction.isLoading && <Loading />}

      <Notification
        open={!transaction.isLoading && !transaction.isUninitialized && !transaction.isSuccess}
        onClose={() => transaction.reset()}
        isError={Boolean(transaction.error) && transaction.isError}
        message={GLOBAL.returnExceptionMessage(
          transaction.isError,
          transaction.error as ErrorProps
        )}
      />
    </>
  )
}

export default EVTransactionLog
