/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import { isEmpty } from 'lodash'
import {
  Autocomplete,
  Box,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography
} from '@mui/material'
import * as yup from 'yup'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import type { Dayjs } from 'dayjs'
import ReactQuill from 'react-quill'
import { decode, encode } from 'html-entities'
import Dialog from 'components/dialog/dialog'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { PayloadVoucherBenefitsProps } from 'modules/partial'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import GlobalStyle from 'modules/styles'
import {
  useGetBenefitsCategoryDropdownMutation,
  useGetContentVoucherMutation,
  useGetVoucherBenefitsDetailQuery,
  useGetVoucherBenefitsDropdownMutation,
  useGetVoucherMemberDropdownMutation,
  useUpdateVoucherBenefitsMutation
} from 'store/voucherBenefits'
import { useGetTokenPartnerDropdownMutation } from 'store/token'
import Loading from 'components/loading/loading'
import BackdropImage from 'components/backdropImage/backdropImage'
import DialogStyle from './style'

const Edit = ({ open, id, onClose }: DetailStateProps & { id: string | number }) => {
  if (!open) return <></>

  const { data, isFetching, isLoading } = useGetVoucherBenefitsDetailQuery(id)
  const [getVoucherBenefitsDropdown, voucherDropdown] = useGetVoucherBenefitsDropdownMutation()
  const [getVoucherMemberDropdown, voucherMemberId] = useGetVoucherMemberDropdownMutation()
  const [getBenefitsCategoryDropdown, benefitsCategory] = useGetBenefitsCategoryDropdownMutation()
  const [getContentVoucher, contentVoucher] = useGetContentVoucherMutation()
  const [getTokenPartnerDropdown, partnerDropdown] = useGetTokenPartnerDropdownMutation()
  const [updateVoucherBenefits, update] = useUpdateVoucherBenefitsMutation()
  const [startDate, setStartDate] = useState<Dayjs | null>(null)
  const [endDate, setEndDate] = useState<Dayjs | null>(null)
  const [previewBanner, setPreviewBanner] = useState<string>('')
  const [previewImage, setPreviewImage] = useState<string>('')
  const [previewBackdrop, setPreviewBackdrop] = useState<boolean>(false)
  const [bannerBackdrop, setBannerBackdrop] = useState<boolean>(false)

  const scheme = yup.object<PayloadVoucherBenefitsProps>({
    CampaignNameID: yup.string().required('Campaign Name ID is required'),
    CampaignNameEN: yup.string().required('Campaign Name EN-US is required'),
    VoucherId: yup.string().required('Voucher Name is required'),
    BenefitsId: yup.string().required('Benefits Category is required'),
    BenefitsName: yup.string(),
    UserType: yup.string().when('BenefitsName', {
      is: (val: string) => val && val.toLocaleLowerCase().includes('benefit'),
      then: () => yup.array().min(1).required('User Type is required'),
      otherwise: () => yup.array().notRequired()
    }),
    Coins: yup.string().when('BenefitsName', {
      is: (val: string) => val && val.toLocaleLowerCase().includes('redemption'),
      then: () => yup.number().required('Coins Amount is required'),
      otherwise: () => yup.number().notRequired()
    }),
    MaxPurchase: yup.number().min(1).required('Max Purchase Per User is required'),
    MinCoins: yup.number().required('Min Coins Achievement is required'),
    MaxCoins: yup.number().required('Max Coins Achievement is required'),
    ValidFrom: yup.string().required('Valid From is required'),
    ValidUntil: yup.string().required('Valid Until is required'),
    PartnerId: yup.string().required('Partner is required'),
    UsageLimit: yup.number().required('Usage Limit is required'),
    DescriptionId: yup.string().required('Description ID is required'),
    DescriptionEn: yup.string().required('Description EN-US is required'),
    TnCID: yup.string().required('Terms and Condition ID is required'),
    TnCEN: yup.string().required('Terms and Condition EN-US is required'),
    UseContent: yup.boolean(),
    image: yup.string().when('UseContent', {
      is: (val: boolean) => !val,
      then: () => yup.string().required('Image Voucher is required'),
      otherwise: () => yup.string().notRequired()
    }),
    banner: yup.string().when('UseContent', {
      is: (val: boolean) => !val,
      then: () => yup.string().required('Banner Voucher is required'),
      otherwise: () => yup.string().notRequired()
    })
  })

  const formik = useFormik<PayloadVoucherBenefitsProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      CampaignNameID: (data && data.voucherCampaignNameIND) || '',
      CampaignNameEN: (data && data.voucherCampaignNameENG) || '',
      VoucherId: (data && data.voucherId) || '',
      BenefitsId: (data && data.benefitCategoryId) || '',
      UserType:
        ((data && data.memberTier)?.map((item: any) => ({
          id: item.memberId,
          name: item.memberName,
          key: '',
          language: ''
        })) as never) || [],
      Coins: (data && data.coins) || 0,
      MaxPurchase: (data && data.maxPurchaseperUser) || 0,
      MinCoins: (data && data.minCoinsAchievement) || 0,
      MaxCoins: (data && data.maxCoinsAchievement) || 0,
      ValidFrom: (data && data.validFrom) || '',
      ValidUntil: (data && data.validUntil) || '',
      PartnerId: (data && data.partnerId) || '',
      UsageLimit: (data && data.usageLimit) || '',

      VoucherTnCId: (data && data.voucherTnCid) || '',
      DescriptionId: decode(data && data.descriptionId) || '',
      DescriptionEn: decode(data && data.descriptionEn) || '',
      TnCID: decode(data && data.termsAndConditionsId) || '',
      TnCEN: decode(data && data.termsAndConditionsEn) || '',
      image: (data && data.image) || '',
      banner: (data && data.banner) || '',
      UseContent: Boolean(data && data.useContentFromVoucher),
      BenefitsName: ''
    },
    onSubmit: (values: PayloadVoucherBenefitsProps) => handleSubmit(values)
  })

  const handleSubmit = (e: PayloadVoucherBenefitsProps) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const payload: any = {
      id: id,
      voucherCampaignNameIND: e.CampaignNameID,
      voucherCampaignNameENG: e.CampaignNameEN,
      voucherid: e.VoucherId,
      benefitCategoryid: e.BenefitsId,
      memberTier: e.UserType.map((item: any) => ({
        memberId: item.id ?? item.memberId,
        memberName: item.name ?? item.memberName
      })) as never,
      coins: e.Coins,
      maxPurchaseperUser: e.MaxPurchase,
      minCoinsAchievement: e.MinCoins,
      maxCoinsAchievement: e.MinCoins, // e.MaxCoins
      validFrom: dayjs(e.ValidFrom).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      validUntil: dayjs(e.ValidUntil).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      usageLimit: e.UsageLimit,
      Partner: e.PartnerId,
      UseContentFromVoucher: e.UseContent,
      vouchertncid: e.UseContent
        ? data && data.voucherTnCid
        : contentVoucher.data?.voucherTnCId || (data && data.voucherTnCid),
      descriptionEn: encode(e.DescriptionEn),
      descriptionId: encode(e.DescriptionId),
      termsAndConditionsEn: encode(e.TnCEN),
      termsAndConditionsId: encode(e.TnCID)
    }

    const formData = new FormData()
    formData.append('Image', e.image)
    formData.append('Banner', e.banner)
    formData.append('data', JSON.stringify(payload))
    updateVoucherBenefits(formData).then()
  }

  const handleChangeStartDate = (date: Dayjs | null) => {
    setStartDate(date)
    const newDate = (!isEmpty(date) && date.format('YYYY-MM-DD')) || ''
    formik.setFieldValue('ValidFrom', newDate)
  }

  const handleChangeEndDate = (date: Dayjs | null) => {
    setEndDate(date)
    const newDate = (!isEmpty(date) && date.format('YYYY-MM-DD')) || ''
    formik.setFieldValue('ValidUntil', newDate)
  }

  const handleUserType = (val: any[]) => {
    const temp: any = []
    val.map((item) => {
      temp.push({ memberId: item.id, memberName: item.name })
    })
    formik.setFieldValue('UserType', temp)
  }

  const setUseContent = (isChecked: boolean) => {
    if (
      (contentVoucher && contentVoucher.data && contentVoucher.data?.details) ||
      (data && data.voucherTnCid)
    ) {
      formik.setFieldValue(
        'DescriptionId',
        isChecked
          ? decode(
              contentVoucher.data?.details.filter((x) => x.language === 'ID')[0].description
            ) || decode((data && data.descriptionId) || '')
          : ''
      )

      formik.setFieldValue(
        'DescriptionEn',
        isChecked
          ? decode(
              contentVoucher.data?.details.filter((x) => x.language === 'en-US')[0].description
            ) || decode((data && data.descriptionEn) || '')
          : ''
      )

      formik.setFieldValue(
        'TnCID',
        isChecked
          ? decode(
              contentVoucher.data?.details.filter((x) => x.language === 'ID')[0].termsAndCondition
            ) || decode((data && data.termsAndConditionsId) || '')
          : ''
      )

      formik.setFieldValue(
        'TnCEN',
        isChecked
          ? decode(
              contentVoucher.data?.details.filter((x) => x.language === 'en-US')[0]
                .termsAndCondition
            ) || decode((data && data.termsAndConditionsEn) || '')
          : ''
      )

      formik.setFieldValue('image', isChecked ? contentVoucher.data?.image || data?.image : '')

      formik.setFieldValue('banner', isChecked ? contentVoucher.data?.banner || data?.banner : '')

      formik.setFieldValue(
        'VoucherTnCId',
        isChecked ? contentVoucher.data?.voucherTnCId || (data && data.voucherTnCid) : ''
      )
    }

    if (!isChecked) {
      setPreviewImage('')
      setPreviewBanner('')
    }
  }

  useEffect(() => {
    formik.setFieldValue('BenefitsName', data && data.benefitCategoryName)
  }, [data])

  useEffect(() => {
    if (data) {
      if (data.image) setPreviewImage(data.image)
      if (data.banner) setPreviewBanner(data.banner)
      if (data.partnerId) getTokenPartnerDropdown()
      if (data.voucherId) getVoucherBenefitsDropdown(data.partnerId)
    }
  }, [data])

  return (
    <>
      <Dialog
        open={open}
        title='Edit Voucher Benefits'
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={isLoading}
        isDisabled={!formik.isValid}
        maxWidth='md'
      >
        <Container {...DialogStyle.Container}>
          {(isLoading || isFetching) && !data && <Loading />}
          {data && (
            <>
              <FormikProvider value={formik}>
                <Grid container spacing={2} columns={16}>
                  <Grid xs={8} marginTop={1}>
                    <Grid xs={16} margin={1}>
                      <TextField
                        id='CampaignNameID'
                        variant='outlined'
                        label='Campaign Name ID'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched &&
                          formik.touched.CampaignNameID &&
                          Boolean(formik.errors.CampaignNameID)
                        }
                        helperText={
                          formik.touched &&
                          formik.touched.CampaignNameID &&
                          formik.errors &&
                          formik.errors.CampaignNameID
                        }
                        fullWidth
                        value={formik.values.CampaignNameID}
                        required
                      />
                    </Grid>
                    <Grid xs={16} margin={1}>
                      <TextField
                        id='CampaignNameEN'
                        variant='outlined'
                        label='Campaign Name EN-US'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched &&
                          formik.touched.CampaignNameEN &&
                          Boolean(formik.errors.CampaignNameEN)
                        }
                        helperText={
                          formik.touched &&
                          formik.touched.CampaignNameEN &&
                          formik.errors &&
                          formik.errors.CampaignNameEN
                        }
                        fullWidth
                        value={formik.values.CampaignNameEN}
                        required
                      />
                    </Grid>
                    <Grid xs={16} margin={1}>
                      <Autocomplete
                        options={partnerDropdown.data || []}
                        getOptionLabel={(list) => list.name}
                        isOptionEqualToValue={(option, value) =>
                          option && value ? option.id == value.id : false
                        }
                        onOpen={() => getTokenPartnerDropdown()}
                        onChange={(_, id) => {
                          formik.setFieldValue('PartnerId', id && id.id)
                          formik.setFieldValue('VoucherId', '')
                          formik.setFieldValue('UseContent', false)
                          setUseContent(false)
                        }}
                        value={
                          (partnerDropdown &&
                            partnerDropdown.data &&
                            partnerDropdown.data.find((e) => e.id == formik.values.PartnerId)) ||
                          null
                        }
                        ListboxProps={GlobalStyle.ListBox}
                        renderOption={(props, item) => (
                          <li {...props} key={item.id}>
                            {item.name}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name='PartnerId'
                            label='Partner'
                            required
                            error={
                              formik.touched &&
                              formik.touched.PartnerId &&
                              Boolean(formik.errors.PartnerId)
                            }
                            helperText={
                              formik.touched &&
                              formik.touched.PartnerId &&
                              formik.errors &&
                              formik.errors.PartnerId
                            }
                            value={formik.values.PartnerId}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {partnerDropdown.isLoading && (
                                    <CircularProgress color='inherit' size={20} />
                                  )}
                                  {params.InputProps.endAdornment}
                                </>
                              )
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid xs={16} margin={1}>
                      <Autocomplete
                        key={formik.values.PartnerId}
                        options={voucherDropdown.data || []}
                        getOptionLabel={(list) => list.voucherName}
                        isOptionEqualToValue={(option, value) =>
                          option && value ? option.id == value.id : false
                        }
                        onOpen={() =>
                          getVoucherBenefitsDropdown(formik.values && formik.values.PartnerId)
                        }
                        onChange={(_, id) => {
                          formik.setFieldValue('VoucherId', id && id.id)
                          formik
                            .setFieldValue('UseContent', false)
                            .then(() => getContentVoucher((id && id.id) || ''))
                          setUseContent(false)
                        }}
                        value={
                          (voucherDropdown &&
                            voucherDropdown.data &&
                            voucherDropdown.data.find((e) => e.id == formik.values.VoucherId)) ||
                          null
                        }
                        ListboxProps={GlobalStyle.ListBox}
                        renderOption={(props, item) => (
                          <li {...props} key={item.id}>
                            {item.voucherName}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name='VoucherId'
                            label='Voucher Name'
                            required
                            value={formik.values.VoucherId}
                            error={
                              formik.touched &&
                              formik.touched.VoucherId &&
                              Boolean(formik.errors.VoucherId)
                            }
                            helperText={
                              formik.touched &&
                              formik.touched.VoucherId &&
                              formik.errors &&
                              formik.errors.VoucherId
                            }
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {voucherDropdown.isLoading && (
                                    <CircularProgress color='inherit' size={20} />
                                  )}
                                  {params.InputProps.endAdornment}
                                </>
                              )
                            }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid xs={16} margin={1}>
                      <Autocomplete
                        options={benefitsCategory.data || []}
                        getOptionLabel={(list) => list.name}
                        isOptionEqualToValue={(option, value) =>
                          option && value ? option.id == value.id : false
                        }
                        onOpen={() => getBenefitsCategoryDropdown()}
                        onChange={(_, id) => {
                          formik.setFieldValue('BenefitsId', id && id.id)
                          formik.setFieldValue('BenefitsName', id && id.name)
                          formik.setFieldValue('Coins', 0)
                          formik.setFieldValue('UserType', [])
                        }}
                        ListboxProps={GlobalStyle.ListBox}
                        renderOption={(props, item) => (
                          <li {...props} key={item.id}>
                            {item.name}
                          </li>
                        )}
                        defaultValue={{
                          id: data && data.benefitCategoryId,
                          name: data && data.benefitCategoryName,
                          key: '',
                          language: ''
                        }}
                        readOnly
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name='BenefitsId'
                            label='Benefits Category'
                            value={formik.values.BenefitsId}
                            required
                            error={
                              formik.touched &&
                              formik.touched.BenefitsId &&
                              Boolean(formik.errors.BenefitsId)
                            }
                            helperText={
                              formik.touched &&
                              formik.touched.BenefitsId &&
                              formik.errors &&
                              formik.errors.BenefitsId
                            }
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {benefitsCategory.isLoading && (
                                    <CircularProgress color='inherit' size={20} />
                                  )}
                                  {params.InputProps.endAdornment}
                                </>
                              )
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      xs={16}
                      margin={1}
                      display={
                        formik.values.BenefitsName &&
                        formik.values.BenefitsName.toLocaleLowerCase().includes('redemption')
                          ? 'none'
                          : ''
                      }
                    >
                      <Autocomplete
                        multiple
                        key={formik.values.BenefitsId}
                        options={voucherMemberId.data || []}
                        getOptionLabel={(list) => list.name}
                        isOptionEqualToValue={(option, value) =>
                          option && value ? option.id == value.id : false
                        }
                        onOpen={() => getVoucherMemberDropdown()}
                        onChange={(_, data) => handleUserType(data)}
                        ListboxProps={GlobalStyle.ListBox}
                        renderOption={(props, item) => (
                          <li {...props} key={item.id}>
                            {item.name}
                          </li>
                        )}
                        defaultValue={
                          (data && data.memberTier)?.map((item: any) => ({
                            id: item.memberId,
                            name: item.memberName,
                            key: '',
                            language: ''
                          })) as never
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name='UserType'
                            label='User Type'
                            value={formik.values.UserType}
                            required
                            error={
                              formik.touched &&
                              formik.touched.UserType &&
                              Boolean(formik.errors.UserType)
                            }
                            helperText={
                              formik.touched &&
                              formik.touched.UserType &&
                              formik.errors &&
                              formik.errors.UserType
                            }
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {voucherMemberId.isLoading && (
                                    <CircularProgress color='inherit' size={20} />
                                  )}
                                  {params.InputProps.endAdornment}
                                </>
                              )
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      xs={16}
                      margin={1}
                      display={
                        formik.values.BenefitsName &&
                        formik.values.BenefitsName.toLocaleLowerCase().includes('benefit')
                          ? 'none'
                          : ''
                      }
                    >
                      <TextField
                        id='Coins'
                        variant='outlined'
                        label='Coins Amount'
                        value={formik.values.Coins && formik.values.Coins.toString()}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched && formik.touched.Coins && Boolean(formik.errors.Coins)
                        }
                        helperText={
                          formik.touched &&
                          formik.touched.Coins &&
                          formik.errors &&
                          formik.errors.Coins
                        }
                        type='number'
                        fullWidth
                        inputProps={{ min: 0 }}
                        onKeyDown={(event) => {
                          if (/^[.,\-\b]+$/.test(event.key)) event.preventDefault()
                        }}
                        required
                      />
                    </Grid>
                    <Grid xs={16} margin={1}>
                      <TextField
                        id='MaxPurchase'
                        variant='outlined'
                        label='Max Purchase Per User'
                        value={formik.values.MaxPurchase}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched &&
                          formik.touched.MaxPurchase &&
                          Boolean(formik.errors.MaxPurchase)
                        }
                        helperText={
                          formik.touched &&
                          formik.touched.MaxPurchase &&
                          formik.errors &&
                          formik.errors.MaxPurchase
                        }
                        type='number'
                        fullWidth
                        inputProps={{ min: 1 }}
                        required
                      />
                    </Grid>
                    <Grid xs={16} margin={1}>
                      <TextField
                        id='MinCoins'
                        variant='outlined'
                        label='Min Coins Achievement'
                        value={formik.values.MinCoins}
                        onChange={(e) => {
                          formik.setFieldValue('MinCoins', e.target.value)
                          formik.setFieldValue('MaxCoins', e.target.value)
                        }}
                        onBlur={(e) => {
                          formik.setFieldValue('MinCoins', e.target.value)
                          formik.setFieldValue('MaxCoins', e.target.value)
                        }}
                        error={
                          formik.touched &&
                          formik.touched.MinCoins &&
                          Boolean(formik.errors.MinCoins)
                        }
                        helperText={
                          formik.touched &&
                          formik.touched.MinCoins &&
                          formik.errors &&
                          formik.errors.MinCoins
                        }
                        type='number'
                        fullWidth
                        inputProps={{ min: 0 }}
                        required
                      />
                    </Grid>
                    {/* <Grid xs={16} margin={1}>
                      <TextField
                        id='MaxCoins'
                        variant='outlined'
                        label='Max Coins Achievement'
                        value={formik.values.MaxCoins}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched &&
                          formik.touched.MaxCoins &&
                          Boolean(formik.errors.MaxCoins)
                        }
                        helperText={
                          formik.touched &&
                          formik.touched.MaxCoins &&
                          formik.errors &&
                          formik.errors.MaxCoins
                        }
                        type='number'
                        fullWidth
                        inputProps={{ min: 0 }}
                        required
                      />
                    </Grid> */}
                    <Grid xs={16} margin={1}>
                      <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                        <DatePicker
                          value={dayjs(data && data.validFrom) || startDate}
                          onChange={handleChangeStartDate}
                          label='Valid From *'
                          format='DD-MM-YYYY'
                          sx={{ width: '100%' }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid xs={16} margin={1}>
                      <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                        <DatePicker
                          value={dayjs(data && data.validUntil) || endDate}
                          minDate={dayjs(startDate)}
                          onChange={handleChangeEndDate}
                          label='Valid Until *'
                          format='DD-MM-YYYY'
                          sx={{ width: '100%' }}
                        />
                      </LocalizationProvider>
                    </Grid>

                    <Grid xs={16} margin={1}>
                      <TextField
                        id='UsageLimit'
                        variant='outlined'
                        label='Usage Limit'
                        value={formik.values.UsageLimit}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched &&
                          formik.touched.UsageLimit &&
                          Boolean(formik.errors.UsageLimit)
                        }
                        helperText={
                          formik.touched &&
                          formik.touched.UsageLimit &&
                          formik.errors &&
                          formik.errors.UsageLimit
                        }
                        type='number'
                        fullWidth
                        inputProps={{ min: 0 }}
                        required
                      />
                    </Grid>
                  </Grid>
                  {/* Right Column */}
                  <Grid xs={8}>
                    <Grid xs={16} margin={1}>
                      <FormControlLabel
                        id='UseContent'
                        control={
                          <Checkbox
                            required
                            id='UseContent'
                            name='UseContent'
                            value={formik.values.UseContent}
                            onChange={() => {
                              formik
                                .setFieldValue('UseContent', !formik.values.UseContent)
                                .then(() => {
                                  if (contentVoucher.data) {
                                    setPreviewImage(contentVoucher.data.image)
                                    setPreviewBanner(contentVoucher.data.banner)
                                  }
                                })
                                .finally(() => setUseContent(!formik.values.UseContent))
                            }}
                            onBlur={formik.handleBlur}
                            checked={formik.values.UseContent}
                          />
                        }
                        label='Use Content from Voucher'
                      />
                    </Grid>
                    <Grid xs={16} margin={1}>
                      <Typography variant='button' gutterBottom>
                        Description - ID
                      </Typography>
                      <ReactQuill
                        theme='snow'
                        id='DescriptionId'
                        onChange={(e) =>
                          formik.setFieldValue('DescriptionId', GLOBAL.emptyQuill(e))
                        }
                        value={formik.values.DescriptionId}
                        readOnly={formik.values.UseContent}
                      />
                    </Grid>
                    <Grid xs={16} margin={1}>
                      <Typography variant='button' gutterBottom>
                        Description - EN-US
                      </Typography>
                      <ReactQuill
                        theme='snow'
                        id='DescriptionEn'
                        onChange={(e) =>
                          formik.setFieldValue('DescriptionEn', GLOBAL.emptyQuill(e))
                        }
                        value={formik.values.DescriptionEn}
                        readOnly={formik.values.UseContent}
                      />
                    </Grid>
                    <Grid xs={16} margin={1}>
                      <Typography variant='button' gutterBottom>
                        Terms and Condition - ID
                      </Typography>
                      <ReactQuill
                        theme='snow'
                        id='TnCID'
                        onChange={(e) => formik.setFieldValue('TnCID', GLOBAL.emptyQuill(e))}
                        value={formik.values.TnCID}
                        readOnly={formik.values.UseContent}
                      />
                    </Grid>
                    <Grid xs={16} margin={1}>
                      <Typography variant='button' gutterBottom>
                        Terms and Condition - EN-US
                      </Typography>
                      <ReactQuill
                        theme='snow'
                        id='TnCEN'
                        onChange={(e) => formik.setFieldValue('TnCEN', GLOBAL.emptyQuill(e))}
                        value={formik.values.TnCEN}
                        readOnly={formik.values.UseContent}
                      />
                    </Grid>
                    <Grid xs={16} margin={1}>
                      <Box
                        sx={{
                          height: '300px',
                          border: '1px solid #eee',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        {!previewImage && !formik.values.UseContent && !(data && data.image) && (
                          <Typography style={{ color: '#cfcfcf' }}>
                            Voucher Image Cannot be Empty
                          </Typography>
                        )}
                        {previewImage && (
                          <BackdropImage
                            open={previewBackdrop}
                            onOpen={() => setPreviewBackdrop(true)}
                            onClose={() => setPreviewBackdrop(false)}
                          >
                            <img
                              src={previewImage}
                              alt=''
                              style={{ height: '300px', width: '400px', objectFit: 'contain' }}
                            />
                          </BackdropImage>
                        )}
                      </Box>
                      <TextField
                        required
                        id='image'
                        label='Voucher Image'
                        type='file'
                        placeholder='Voucher Image'
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ accept: 'image/png, image/gif, image/jpeg', shrink: 'true' }}
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const files = e && e.target && e.target.files
                          if (files && files.length > 0 && !isEmpty(files)) {
                            formik.setFieldValue('image', files[0])
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            const reader: any = new FileReader()
                            reader.onload = () => {
                              setPreviewImage(reader.result)
                            }
                            reader.readAsDataURL(files[0])
                          } else {
                            setPreviewImage('')
                          }
                        }}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched && formik.touched.image && Boolean(formik.errors.image)
                        }
                        helperText={
                          formik.touched &&
                          formik.touched.image &&
                          formik.errors &&
                          formik.errors.image
                        }
                        fullWidth
                        disabled={formik.values.UseContent}
                      />
                    </Grid>
                    <Grid xs={16} margin={1}>
                      <Box
                        sx={{
                          height: '300px',
                          border: '1px solid #eee',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        {!previewBanner && !formik.values.UseContent && !(data && data.banner) && (
                          <Typography style={{ color: '#cfcfcf' }}>
                            Voucher Banner Cannot be Empty
                          </Typography>
                        )}
                        {previewBanner && (
                          <BackdropImage
                            open={bannerBackdrop}
                            onOpen={() => setBannerBackdrop(true)}
                            onClose={() => setBannerBackdrop(false)}
                          >
                            <img
                              src={previewBanner}
                              alt=''
                              style={{ height: '300px', width: '400px', objectFit: 'contain' }}
                            />
                          </BackdropImage>
                        )}
                      </Box>
                      <TextField
                        required
                        id='banner'
                        label='Voucher Banner'
                        type='file'
                        placeholder='Voucher Banner'
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ accept: 'image/png, image/gif, image/jpeg', shrink: 'true' }}
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const files = e && e.target && e.target.files
                          if (files && files.length > 0 && !isEmpty(files)) {
                            formik.setFieldValue('banner', files[0])
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            const reader: any = new FileReader()
                            reader.onload = () => {
                              setPreviewBanner(reader.result)
                            }
                            reader.readAsDataURL(files[0])
                          } else {
                            setPreviewBanner('')
                          }
                        }}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched && formik.touched.banner && Boolean(formik.errors.banner)
                        }
                        helperText={
                          formik.touched &&
                          formik.touched.banner &&
                          formik.errors &&
                          formik.errors.banner
                        }
                        fullWidth
                        disabled={formik.values.UseContent}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </FormikProvider>
            </>
          )}
        </Container>
      </Dialog>

      <Notification
        open={!update.isLoading && !update.isUninitialized}
        onClose={() => (update.isError ? update.reset() : location.reload())}
        isError={Boolean(update.error) && update.isError}
        message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
      />
    </>
  )
}

export default Edit
