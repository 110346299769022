import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Autocomplete, CircularProgress, Container, TextField } from '@mui/material'
import Dialog from 'components/dialog/dialog'
import type { PartialChargeBoxGroupProps } from 'modules/partial'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import {
  useGetChargePointDropdownListByQuery,
  useGetChargePointDropdownListMutation
} from 'store/evChargePoint'
import {
  useGetChargeBoxGroupDetailQuery,
  useUpdateChargeBoxGroupMutation
} from 'store/evChargeBoxGroup'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import Loading from 'components/loading/loading'
import CONSTANT from 'modules/constant'
import GlobalStyle from 'modules/styles'
import DialogStyle from './style'

const Edit = ({ open, id, onClose }: DetailStateProps & { id: number }) => {
  if (!open) return <></>

  const { data: chargeBoxDetail, isLoading: chargeBoxLoading } = useGetChargeBoxGroupDetailQuery(id)

  const { data: chargePointDropdownQuery, isLoading: chargePointDropdownQueryLoading } =
    useGetChargePointDropdownListByQuery(CONSTANT.DEFAULT_DROPDOWN_PAYLOAD)

  const [updateChargeBoxGroup, update] = useUpdateChargeBoxGroupMutation()

  const [getChargePointDropdownList, chargePointDropdown] = useGetChargePointDropdownListMutation()

  const [chargePointId, setChargePointId] = useState<string>('')

  const scheme = yup.object<PartialChargeBoxGroupProps>({
    chargePointId: yup.number(),
    code: yup.string().required('Code is required'),
    chargeBoxIdentity: yup.string().required('Charge Box Identity is required'),
    operative: yup.boolean(),
    online: yup.boolean(),
    chargerBrand: yup.string().required('Charger Brand is required'),
    connectorAmount: yup.number().required('Connector Amount is required'),
    vendor: yup.string().required('Provider is required'),
    remark: yup.string()
  })

  const formik = useFormik<PartialChargeBoxGroupProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      chargePointId: (chargeBoxDetail && chargeBoxDetail.chargePointId) || null,
      code: (chargeBoxDetail && chargeBoxDetail.code) || '',
      chargeBoxIdentity: (chargeBoxDetail && chargeBoxDetail.chargeBoxIdentity) || '',
      operative: (chargeBoxDetail && chargeBoxDetail.operative) || false,
      online: chargeBoxDetail && chargeBoxDetail.online,
      chargerBrand: (chargeBoxDetail && chargeBoxDetail.chargerBrand) || '',
      connectorAmount: (chargeBoxDetail && chargeBoxDetail.connectorAmount) || 0,
      vendor: (chargeBoxDetail && chargeBoxDetail.vendor) || '',
      remark: (chargeBoxDetail && chargeBoxDetail.remark) || ''
    },
    onSubmit: (values: PartialChargeBoxGroupProps) =>
      chargeBoxDetail &&
      updateChargeBoxGroup({
        body: { ...values, chargePointId: Number(chargePointId) },
        id: Number(chargeBoxDetail.id)
      })
  })

  const errorData = formik.errors

  const loading = chargeBoxLoading && chargePointDropdown.isLoading

  const selectedChargePoint =
    (!chargePointDropdownQueryLoading &&
      chargePointDropdownQuery &&
      chargePointDropdownQuery.find((e) => String(e.value) == chargePointId)) ||
    null

  useEffect(() => {
    if (chargeBoxDetail && !chargePointId) setChargePointId(String(chargeBoxDetail.chargePointId))
  }, [chargeBoxDetail, chargePointId])

  return (
    <>
      <Dialog
        title='Update New EV Charge Box Group'
        open={open}
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={update.isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          {loading && <Loading />}
          {!loading && (
            <>
              {selectedChargePoint && (
                <Autocomplete
                  options={chargePointDropdown.data || []}
                  getOptionLabel={(option) => option.text}
                  isOptionEqualToValue={(option, value) =>
                    option && value ? option.value == value.value : false
                  }
                  onOpen={() => getChargePointDropdownList(CONSTANT.DEFAULT_DROPDOWN_PAYLOAD)}
                  onChange={(_, chargePoint) => {
                    const point = (chargePoint && String(chargePoint.value)) || ''

                    setChargePointId(point)
                    formik.setFieldValue('chargePointId', point)
                  }}
                  value={selectedChargePoint}
                  ListboxProps={GlobalStyle.ListBox}
                  renderOption={(props, item) => (
                    <li {...props} key={item.value}>
                      {item.text}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id='ChargePointId'
                      name='ChargePointId'
                      label='EV Charge Point'
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {chargePointDropdown.isLoading && (
                              <CircularProgress color='inherit' size={20} />
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                />
              )}
              <TextField
                id='chargeBoxIdentity'
                variant='outlined'
                label='Charge Box Identity'
                value={formik.values.chargeBoxIdentity}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched &&
                  formik.touched.chargeBoxIdentity &&
                  Boolean(errorData.chargeBoxIdentity)
                }
                helperText={
                  formik.touched &&
                  formik.touched.chargeBoxIdentity &&
                  errorData &&
                  errorData.chargeBoxIdentity
                }
                fullWidth
              />
              <TextField
                id='chargerBrand'
                variant='outlined'
                label='Charger Brand'
                value={formik.values.chargerBrand}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched && formik.touched.chargerBrand && Boolean(errorData.chargerBrand)
                }
                helperText={
                  formik.touched &&
                  formik.touched.chargerBrand &&
                  errorData &&
                  errorData.chargerBrand
                }
                fullWidth
              />
              <TextField
                id='connectorAmount'
                variant='outlined'
                label='Connector Amount'
                value={formik.values.connectorAmount}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched &&
                  formik.touched.connectorAmount &&
                  Boolean(errorData.chargerBrand)
                }
                helperText={
                  formik.touched &&
                  formik.touched.connectorAmount &&
                  errorData &&
                  errorData.connectorAmount
                }
                fullWidth
              />
              <TextField
                id='vendor'
                variant='outlined'
                label='Provider'
                value={formik.values.vendor}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched && formik.touched.vendor && Boolean(errorData.vendor)}
                helperText={
                  formik.touched && formik.touched.vendor && errorData && errorData.vendor
                }
                fullWidth
              />
            </>
          )}
        </Container>
      </Dialog>

      <Notification
        open={!update.isLoading && !update.isUninitialized}
        onClose={() => (update.isError ? update.reset() : location.reload())}
        isError={Boolean(update.error) && update.isError}
        message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
      />
    </>
  )
}

export default Edit
