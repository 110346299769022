import CONSTANT from 'modules/constant'
import type {
  DropdownCategoryListProps,
  DropdownVoucherListProps,
  PaginationProps,
  ResultProps,
  TokenRequestListProps,
  TokenSubRequestListProps
} from 'modules/types'
import { API, download, headers } from './api'

const TokenRequestAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getListTokenRequest: builder.mutation<PaginationProps<TokenRequestListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_TOKEN_REQUEST,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body
      })
    }),
    getTokenRequestDetail: builder.query<TokenRequestListProps, number | string>({
      query: (Id) => ({
        url: CONSTANT.URL_TOKEN_REQUEST_DETAIL + Id,
        method: 'GET',
        headers: headers()
      })
    }),
    getTokenCategoryDropdown: builder.mutation<DropdownCategoryListProps[], void>({
      query: () => ({
        url: CONSTANT.URL_TOKEN_CATEGORY_DROPDOWN,
        method: 'GET',
        headers: headers()
      })
    }),
    getTokenStatusDropdown: builder.mutation<DropdownCategoryListProps[], void>({
      query: () => ({
        url: CONSTANT.URL_TOKEN_STATUS_DROPDOWN,
        method: 'GET',
        headers: headers()
      })
    }),
    getVoucherListDropdown: builder.mutation<DropdownCategoryListProps[], void>({
      query: () => ({
        url: CONSTANT.URL_VOUCHER_DROPDOWN,
        method: 'GET',
        headers: headers()
      })
    }),
    getVoucherListDropdownById: builder.mutation<DropdownVoucherListProps[], number>({
      query: (id) => ({
        url: CONSTANT.URL_VOUCHER_DROPDOWN_BY_ID + id,
        method: 'GET',
        headers: headers()
      })
    }),
    downloadTokenRequest: builder.mutation<PaginationProps<TokenRequestListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_TOKEN_REQUEST_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        responseHandler: download,
        body: body,
        formData: true
      })
    }),
    getListTokenSubRequest: builder.mutation<PaginationProps<TokenSubRequestListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_TOKEN_SUB_REQUEST,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body
      })
    }),
    createTokenRequest: builder.mutation<ResultProps, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_TOKEN_REQUEST_CREATE,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body
      })
    }),
    updateTokenValidity: builder.mutation<ResultProps, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_TOKEN_REQUEST_UPDATE,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body
      })
    }),
    downloadTokenSubRequest: builder.mutation<PaginationProps<TokenSubRequestListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_TOKEN_SUBREQUEST_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        responseHandler: download,
        body: body,
        formData: true
      })
    })
  })
})

export const {
  useGetListTokenRequestMutation,
  useGetTokenRequestDetailQuery,
  useGetTokenCategoryDropdownMutation,
  useGetTokenStatusDropdownMutation,
  useGetVoucherListDropdownMutation,
  useGetVoucherListDropdownByIdMutation,
  useDownloadTokenRequestMutation,
  useGetListTokenSubRequestMutation,
  useCreateTokenRequestMutation,
  useUpdateTokenValidityMutation,
  useDownloadTokenSubRequestMutation
} = TokenRequestAPI
