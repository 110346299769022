import CONSTANT from 'modules/constant'
import type {
  DropdownListProps,
  EvChargePointDetailProps,
  EvChargePointProps,
  GenerateCodeProps,
  PaginationProps,
  ResultProps
} from 'modules/types'
import type { PartialDropdownListProps } from 'modules/partial'
import { API, download, headers } from './api'

const EvChargePointAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getChargePointList: builder.mutation<PaginationProps<EvChargePointProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_EV_CHARGE_POINT_LIST,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    resyncChargePointList: builder.mutation<ResultProps, void>({
      query: () => ({
        url: CONSTANT.URL_EV_CHARGE_POINT_RESYNC,
        method: 'POST',
        headers: headers()
      })
    }),
    getChargePointDropdownList: builder.mutation<DropdownListProps[], PartialDropdownListProps>({
      query: (query) => ({
        url:
          CONSTANT.URL_EV_CHARGE_POINT_DROPDOWN +
          `?start=${query.start}&length=${query.length}${
            query.cityId ? `&cityId=${query.cityId}` : ''
          }${query.Excludes ? `&Excludes=${query.Excludes}` : ''}${
            query.HasPartner ? `&HasPartner=${query.HasPartner}` : ''
          }${query.PartnerId ? `&PartnerId=${query.PartnerId}` : ''}${
            query.IsPartner ? `&IsPartner=${query.IsPartner}` : ''
          }`,
        method: 'GET',
        headers: headers()
      })
    }),
    getChargePointDropdownListBy: builder.query<DropdownListProps[], PartialDropdownListProps>({
      query: (query) => ({
        url: CONSTANT.URL_EV_CHARGE_POINT_DROPDOWN + `?start=${query.start}&length=${query.length}`,
        method: 'GET',
        headers: headers()
      })
    }),
    downloadChargePointList: builder.mutation<PaginationProps<EvChargePointProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_EV_CHARGE_POINT_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true,
        responseHandler: download
      })
    }),
    getChargePointDetail: builder.query<EvChargePointDetailProps, number>({
      query: (id) => ({
        url: CONSTANT.URL_EV_CHARGE_POINT_DETAIL + id,
        method: 'GET',
        headers: headers()
      })
    }),
    createChargePoint: builder.mutation<ResultProps, EvChargePointDetailProps>({
      query: (body) => ({
        url: CONSTANT.URL_EV_CHARGE_POINT_CREATE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    updateChargePoint: builder.mutation<
      ResultProps,
      { body: EvChargePointDetailProps; id: number }
    >({
      query: (query) => ({
        url: CONSTANT.URL_EV_CHARGE_POINT_UPDATE + query.id,
        method: 'PUT',
        headers: headers(),
        body: query.body
      })
    }),
    deleteChargePoint: builder.mutation<ResultProps, number>({
      query: (id) => ({
        url: CONSTANT.URL_EV_CHARGE_POINT_DELETE + id,
        method: 'DELETE',
        headers: headers()
      })
    }),
    generateCodeChargePoint: builder.query<GenerateCodeProps, void>({
      query: () => ({
        url: CONSTANT.URL_EV_CHARGE_POINT_CODE,
        method: 'GET',
        headers: headers()
      })
    })
  })
})

export const {
  useGetChargePointListMutation,
  useResyncChargePointListMutation,
  useGetChargePointDropdownListMutation,
  useGetChargePointDropdownListByQuery,
  useDownloadChargePointListMutation,
  useCreateChargePointMutation,
  useGetChargePointDetailQuery,
  useUpdateChargePointMutation,
  useDeleteChargePointMutation,
  useGenerateCodeChargePointQuery
} = EvChargePointAPI
