import CONSTANT from 'modules/constant'
import type { EvChargeBoxProps, PaginationProps } from 'modules/types'
import { API, download, headers } from './api'

const EvChargePointBoxAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getChargePointBoxList: builder.mutation<
      PaginationProps<EvChargeBoxProps>,
      { body: FormData; id: string; key: string }
    >({
      query: (request) => ({
        url:
          CONSTANT.URL_EV_CHARGE_POINT_BOX_LIST +
          request.id +
          '/group/' +
          request.key +
          '/box/data',
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: request.body,
        formData: true
      })
    }),
    downloadChargePointBoxList: builder.mutation<
      PaginationProps<EvChargeBoxProps>,
      { body: FormData; id: string; key: string }
    >({
      query: (request) => ({
        url:
          CONSTANT.URL_EV_CHARGE_POINT_BOX_DOWNLOAD +
          request.id +
          '/group/' +
          request.key +
          '/box/data/export',
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: request.body,
        formData: true,
        responseHandler: download
      })
    })
  })
})

export const { useGetChargePointBoxListMutation, useDownloadChargePointBoxListMutation } =
  EvChargePointBoxAPI
