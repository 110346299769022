/* eslint-disable @typescript-eslint/no-explicit-any */

import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { Container, TextField } from '@mui/material'
import Dialog from 'components/dialog/dialog'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import type { PartialAddNotesProps } from 'modules/partial'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import { useAddNotesMutation } from 'store/carInformation'
import DialogStyle from './addNotesStyle'

const AddNotes = ({ open, id, onClose }: DetailStateProps & { id: string }) => {
  if (!open) return <></>

  const [addNotes, update] = useAddNotesMutation()

  const scheme = yup.object<PartialAddNotesProps>({
    Notes: yup.string().required('Notes is required')
  })

  const formik = useFormik<PartialAddNotesProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      RegistrationCode: '',
      Notes: ''
    },
    onSubmit: (values: PartialAddNotesProps) => handleSubmit(values)
  })

  const handleSubmit = (e: PartialAddNotesProps) => {
    const payload: any = {
      RegistrationCode: id,
      Notes: e.Notes
    }
    addNotes(payload)
  }

  return (
    <>
      <Dialog
        open={open}
        title='Add Notes'
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={false}
        isDisabled={!formik.isValid}
        submitText='Add'
        cancelText='Cancel'
      >
        <Container {...DialogStyle.Container}>
          {id && (
            <>
              <FormikProvider value={formik}>
                <TextField
                  id='Notes'
                  value={formik.values.Notes}
                  multiline
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched && formik.touched.Notes && Boolean(formik.errors.Notes)}
                  helperText={
                    formik.touched && formik.touched.Notes && formik.errors && formik.errors.Notes
                  }
                />
              </FormikProvider>
            </>
          )}
        </Container>
      </Dialog>

      <Notification
        open={!update.isLoading && !update.isUninitialized}
        onClose={() => (update.isError ? update.reset() : location.reload())}
        isError={update.isError}
        message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
      />
    </>
  )
}

export default AddNotes
