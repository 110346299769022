import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { IconButton, Menu, MenuItem, TableCell, TableRow } from '@mui/material'
import moment from 'moment'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import { useGetPriceListMutation, useDownloadPriceListMutation } from 'store/evPrice'
import type { PartialEVPriceFilterProps, PartialFormDataCapsProps } from 'modules/partial'
import CONSTANT from 'modules/constant'
import GLOBAL from 'modules/global'
import Icon from 'components/icon'
import Notification from 'components/notification'
import type { CallbackHandler, ErrorProps } from 'modules/types'
import Loading from 'components/loading/loading'
import Create from './create/create'
import Detail from './detail/detail'

const EVPriceList = () => {
  const navigate = useNavigate()

  const [getPriceList, priceList] = useGetPriceListMutation()
  const [downloadPrice, download] = useDownloadPriceListMutation()

  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [createdFrom, setCreatedFrom] = useState<Dayjs | null>(null)
  const [createdTo, setCreatedTo] = useState<Dayjs | null>(null)
  const [menu, setMenu] = useState<null | number>(null)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [dialogCreate, setDialogCreate] = useState<boolean>(false)

  const [filter, setFilter] = useState<PartialEVPriceFilterProps>({
    createdFrom: '',
    createdTo: ''
  })

  const [payload, setPayload] = useState<PartialFormDataCapsProps & PartialEVPriceFilterProps>({
    Start: 0,
    Length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    Search: { value: '' },
    createdFrom: '',
    createdTo: ''
  })

  const onSearch = (value: string) => setPayload({ ...payload, Start: 0, Search: { value } })
  const onChangePage = (value: number) => setPayload({ ...payload, Start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, Length: value })

  const onFilter = (state: boolean) => setDialogFilter(state)
  const onCreate = () => setDialogCreate(true)

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const onResync = () => {
    const formData = new FormData()

    formData.append('Start', payload.Start.toString())
    formData.append('Length', payload.Length.toString())

    if (!isEmpty(payload.Search) && !isEmpty(payload.Search.value))
      formData.append('Search', payload.Search.value)
    if (!isEmpty(payload.createdFrom)) formData.append('createdFrom', payload.createdFrom)
    if (!isEmpty(payload.createdTo)) formData.append('createdTo', payload.createdTo)

    getPriceList(formData)
  }

  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('Start', String(0))
    formData.append('Length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())

    if (!isEmpty(payload.Search) && !isEmpty(payload.Search.value))
      formData.append('Search', payload.Search.value)
    if (!isEmpty(payload.createdFrom)) formData.append('createdFrom', payload.createdFrom)
    if (!isEmpty(payload.createdTo)) formData.append('createdTo', payload.createdTo)
    if (type && !isEmpty(type)) formData.append('Content-Type', type)

    downloadPrice(formData)
  }

  const handleChangeDate = (
    date: Dayjs | null,
    callback: CallbackHandler<{ date: Dayjs | null; newDate: string }>
  ) => {
    const newDate = (!isEmpty(date) && date.format('YYYY-MM-DD')) || ''
    callback({ date, newDate })
  }

  const tableHeadTitles = [
    'Date Created',
    'Price Table ID',
    'Price Table Name',
    'Number of Charge Box Group',
    'Electricity Fee',
    'Service Fee',
    'Admin Fee',
    'Minimum kWh',
    'Minimum Balance',
    'Estimate Min Incoming Duration',
    'Estimate Max Incoming Duration',
    'Estimate Incoming Power In kWh'
  ]

  const openAnchor = Boolean(anchor)
  const totalPage = priceList.data && GLOBAL.tableTotalPage(priceList.data.recordsTotal)
  const numbers = GLOBAL.tableNumber(payload.Start)

  useEffect(() => {
    onResync()
  }, [payload])

  return (
    <>
      <Content
        title='Price Management'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        onAdditional={onCreate}
        additionalTitle='Create New Price Table'
        additionalPrefixIcon='Create'
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={priceList.data && priceList.data.recordsTotal}
        page={payload.Start + 1}
        isLoading={priceList.isLoading}
        useDownloadDropdown
      >
        {priceList.isSuccess &&
          priceList.data &&
          priceList.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.id}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.id}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.id}`}
                  aria-labelledby={`button-${index}-${table.id}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      onRemoveAnchor(() => navigate(GLOBAL.encodeUrl(String(table.id))))
                    }
                  >
                    View Charger Box Group
                  </MenuItem>
                </Menu>
                <Detail
                  open={drawerDetail && menu == index}
                  id={table.id}
                  onClose={() => setDrawerDetail(false)}
                />
              </TableCell>
              <TableCell>
                {(table.createdAt && moment(table.createdAt).format('DD-MM-YYYY HH:mm')) || '-'}
              </TableCell>
              <TableCell>{table.code || '-'}</TableCell>
              <TableCell>{table.name || '-'}</TableCell>
              <TableCell>{table.totalChargeBoxGroups || '-'}</TableCell>
              <TableCell>
                {table.electricityFee && GLOBAL.numberFormat(table.electricityFee)}
              </TableCell>
              <TableCell>{table.serviceFee && GLOBAL.numberFormat(table.serviceFee)}</TableCell>
              <TableCell>{table.adminFee && GLOBAL.numberFormat(table.adminFee)}</TableCell>
              <TableCell>{table.minimumKwhForAdminFee || '-'}</TableCell>
              <TableCell>
                {table.minimumBalance && GLOBAL.numberFormat(table.minimumBalance)}
              </TableCell>
              <TableCell>{table.estimateMinIncomingDuration || '-'}</TableCell>
              <TableCell>{table.estimateMaxIncomingDuration || '-'}</TableCell>
              <TableCell>{table.estimateIncomingPowerInKwh || '-'}</TableCell>
            </TableRow>
          ))}
      </Content>

      {priceList.isLoading && <Loading />}

      <Create open={dialogCreate} onClose={() => setDialogCreate(false)} />

      <Filter
        open={dialogFilter}
        title='Filter'
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
        onReset={() => {
          setCreatedFrom(null)
          setCreatedTo(null)
          setFilter({
            createdFrom: '',
            createdTo: ''
          })
          setPayload({
            ...payload,
            Start: 0,
            createdFrom: '',
            createdTo: ''
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({
            ...payload,
            ...filter,
            Start: 0
          })
          onFilter(false)
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
          <DatePicker
            value={createdFrom}
            onChange={(e) =>
              handleChangeDate(e, (v) => {
                setFilter({ ...filter, createdFrom: v.newDate })
                setCreatedFrom(v.date)
              })
            }
            label='Created From'
            format='DD-MM-YYYY'
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
          <DatePicker
            value={createdTo}
            onChange={(e) =>
              handleChangeDate(e, (v) => {
                setFilter({ ...filter, createdTo: v.newDate })
                setCreatedTo(v.date)
              })
            }
            minDate={createdFrom}
            label='Created Until'
            format='DD-MM-YYYY'
          />
        </LocalizationProvider>
      </Filter>

      <Notification
        open={!priceList.isLoading && !priceList.isUninitialized && !priceList.isSuccess}
        onClose={() => priceList.reset()}
        isError={Boolean(priceList.error) && priceList.isError}
        message={GLOBAL.returnExceptionMessage(priceList.isError, priceList.error as ErrorProps)}
      />

      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />
    </>
  )
}

export default EVPriceList
