import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Grid,
  TextField,
  Typography
} from '@mui/material'
import { ExpandMoreOutlined } from '@mui/icons-material'
import Drawer from 'components/drawer/detail'
import Loading from 'components/loading/loading'
import GLOBAL from 'modules/global'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import { useGetTopupDetailQuery } from 'store/evTopup'
import Notification from 'components/notification'
import DetailStyle from './style'

const Detail = ({ open, id, onClose }: DetailStateProps & { id: string }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const { data, isLoading, isUninitialized, isSuccess, isError, error } = useGetTopupDetailQuery(id)

  const transactionIdByHyundai = (data && data.key) || '-'
  const transactionIdByMidTrans = (data && data.transactionId) || '-'
  const dateCreatedAt = (data && data.createdAtFormated) || '-'
  const paymentMethod = (data && data.paymentMethodName) || '-'
  const status = (data && data.status) || '-'
  const memberId = data && data.memberId
  const tokenBeginningBalance = data && data.beforeToken && GLOBAL.numberFormat(data.beforeToken)
  const topupAmount = data && data.amount && GLOBAL.numberFormat(data.amount)
  const adminFee = data && data.fee && GLOBAL.numberFormat(data.fee)
  const grandTotal = data && data.grandTotal && GLOBAL.numberFormat(data.grandTotal)
  const tokenEndingBalance = data && data.beforeToken && GLOBAL.numberFormat(data.afterToken)
  const errorCheck = data && data.errorCheck

  const gridDataLabel = [
    'Topup Transaction ID (Hyundai)',
    'Topup Transaction ID (Midtrans)',
    'Date Created',
    'Payment Method',
    'Topup Transaction Status',
    'User Member ID',
    'EV Token Beginning Balance',
    'Topup Amount',
    'Topup Admin Fee',
    'Total Topup Payment',
    'EV Token Ending Balance',
    'Error Check'
  ]

  const gridDataValue = [
    transactionIdByHyundai,
    transactionIdByMidTrans,
    dateCreatedAt,
    paymentMethod,
    status,
    memberId,
    tokenBeginningBalance,
    topupAmount,
    adminFee,
    grandTotal,
    tokenEndingBalance,
    errorCheck
  ]

  const createdBy = (data && data.createdBy) || '-'
  const createdAt = (data && data.createdAt) || '-'
  const modifiedBy = (data && data.modifiedBy) || '-'
  const modifiedAt = (data && data.modifiedAt) || '-'

  const gridBasicLabel = ['Created By', 'Created At', 'Modified By', 'Modified At']
  const gridBasicValue = [createdBy, createdAt, modifiedBy, modifiedAt]

  return (
    <>
      <Drawer open={open} title='Topup Transaction Detail' onClose={onClose}>
        <Container {...DetailStyle.Container}>
          {isLoading && <Loading />}
          {!isLoading && (
            <Grid container spacing={2}>
              {gridDataValue.map((item, index) => (
                <Grid key={index} item xs={12}>
                  <TextField
                    variant='outlined'
                    label={gridDataLabel[index]}
                    value={item}
                    inputProps={{ readOnly: true }}
                    fullWidth
                  />
                </Grid>
              ))}
            </Grid>
          )}
          {!isLoading && (
            <Accordion elevation={0} disableGutters>
              <AccordionSummary
                expandIcon={<ExpandMoreOutlined />}
                aria-controls='basic-information'
              >
                <Typography>Basic Information</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {gridBasicValue.map((item, index) => (
                    <Grid key={index} item xs={12}>
                      <TextField
                        variant='outlined'
                        label={gridBasicLabel[index]}
                        value={item}
                        inputProps={{ readOnly: true }}
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          )}
        </Container>
      </Drawer>

      <Notification
        open={!isLoading && !isUninitialized && !isSuccess}
        onClose={() => null}
        isError={isError}
        message={GLOBAL.returnExceptionMessage(isError, error as ErrorProps)}
      />
    </>
  )
}

export default Detail
