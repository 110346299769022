/* eslint-disable @typescript-eslint/no-explicit-any */
// import { useState } from 'react'
import { useEffect, useState } from 'react'
import {
  Box,
  Container,
  Dialog,
  // DialogTitle,
  IconButton,
  Menu,
  // MenuItem,
  TableCell,
  TableRow,
  Typography
} from '@mui/material'
import Content from 'components/content/content'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import Icon from 'components/icon'
// import Loading from 'components/loading/loading'
import { useDeleteCarSharingMutation, useGetCarSharingMutation } from 'store/carInformation'
// import ModalConfirm from 'components/modalConfirm/modalConfirm'
import Notification from 'components/notification'
import MenuModalItem from 'components/menuItem/menuItem'
import GLOBAL from 'modules/global'
import GlobalStyle from 'modules/styles'
import DialogStyle from './changeCarSharingStyle'

const ChangeCarSharing = ({ open, id, onClose }: DetailStateProps & { id: string }) => {
  if (!open) return <></>

  const [getCarSharing, carSharing] = useGetCarSharingMutation()
  const [deleteCarSharing, deletePromoSub] = useDeleteCarSharingMutation()

  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [menu, setMenu] = useState<null | number>(null)

  const tableHeadTitles = [
    'Member ID',
    'Full Name',
    'Ownership Status',
    'Sharing Type',
    'Start Date',
    'End Date'
  ]

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const openAnchor = Boolean(anchor)

  useEffect(() => {
    getCarSharing(id)
    // getCarSharing('MF3PADICKPJ100037')
  }, [])

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth='md'>
        {/* <DialogTitle> */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            rowGap: '10px'
          }}
        >
          {/* <div style={{ alignItems: 'center', marginLeft: 250 }}> */}
          <div style={{ width: '100%' }}>
            <Typography variant='h4' sx={{ textAlign: 'center' }}>
              Change Car Sharing
            </Typography>
          </div>

          <IconButton onClick={onClose}>
            <Icon icon='Close' />
          </IconButton>
        </Box>
        {/* </DialogTitle> */}

        <Container {...DialogStyle.Container}>
          {/* {(isLoading) && !data && <Loading />} */}
          <div style={{ width: '100%', marginTop: 30, marginBottom: -50 }}>
            <Typography variant='h6' sx={{ textAlign: 'center', marginLeft: -5 }}>
              VIN: {id}
            </Typography>
          </div>

          <Content title='' tableHeadTitles={tableHeadTitles}>
            {carSharing.isSuccess &&
              carSharing &&
              carSharing.data.map((table, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <IconButton
                      id={`button-${index}-${table.id}`}
                      aria-haspopup='true'
                      aria-controls={openAnchor ? `menu-${index}-${table.id}` : undefined}
                      aria-expanded={openAnchor ? 'true' : undefined}
                      onClick={(event) => {
                        onAction(event, index)
                      }}
                    >
                      <Icon icon='MoreHoriz' />
                    </IconButton>
                    <Menu
                      id={`menu-${index}-${table.id}`}
                      aria-labelledby={`button-${index}-${table.id}`}
                      anchorEl={anchor}
                      open={openAnchor && menu == index}
                      onClose={() => onRemoveAnchor(() => setMenu(null))}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                      }}
                    >
                      <MenuModalItem
                        title='Delete'
                        loading={deletePromoSub.isLoading}
                        onClick={() =>
                          onRemoveAnchor(() =>
                            deleteCarSharing({
                              Id: table.id,
                              OwnershipStatus: table.ownershipStatus
                            })
                          )
                        }
                      >
                        <Typography {...GlobalStyle.ErrorTypography}>Delete</Typography>
                      </MenuModalItem>
                    </Menu>
                  </TableCell>
                  <TableCell>{table.memberCode || '-'}</TableCell>
                  <TableCell>{table.fullname || '-'}</TableCell>
                  <TableCell>{table.ownershipStatus || '-'}</TableCell>
                  <TableCell>{table.sharingType ? table.sharingType : '-'}</TableCell>
                  <TableCell>{GLOBAL.formatDateddMMYYLongDate(table.startDate) || '-'}</TableCell>
                  <TableCell>{GLOBAL.formatDateddMMYYLongDate(table.endDate) || '-'}</TableCell>
                </TableRow>
              ))}
          </Content>
          <Notification
            open={!deletePromoSub.isLoading && !deletePromoSub.isUninitialized}
            onClose={() => (deletePromoSub.isError ? deletePromoSub.reset() : location.reload())}
            isError={Boolean(deletePromoSub.error) && deletePromoSub.isError}
            message={GLOBAL.returnExceptionMessage(
              deletePromoSub.isError,
              deletePromoSub.error as ErrorProps
            )}
          />
        </Container>
      </Dialog>
    </>
  )
}

export default ChangeCarSharing
