import { useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import {
  Container,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  OutlinedInput,
  TextField
} from '@mui/material'
import { VisibilityOff, Visibility } from '@mui/icons-material'
import Dialog from 'components/dialog/dialog'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import { useCreateUsersAccountMutation } from 'store/control'
import type { PartialUsersAccountCreateProps } from 'modules/partial'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import DialogStyle from './style'

const Create = ({ open, onClose }: DetailStateProps) => {
  if (!open) return <></>

  const [createUsersAccount, create] = useCreateUsersAccountMutation()
  const [visibility, setVisibility] = useState<boolean>(false)

  const onSubmit = (values: PartialUsersAccountCreateProps) => createUsersAccount(values)

  const scheme = yup.object<PartialUsersAccountCreateProps>({
    FirstName: yup.string().required('First Name is required'),
    LastName: yup.string(),
    Email: yup.string().email('Email is not valid').required('Email is required'),
    Phone: yup
      .string()
      .matches(GLOBAL.phoneNumberRegex(), 'Phone Number is not valid')
      .min(8, 'Minimum Length is 8')
      .max(15, 'Maximum Length is 15')
      .required('Phone is required'),
    UserName: yup.string().required('UserName is required'),
    Password: yup
      .string()
      .matches(
        GLOBAL.passwordRegex(),
        'Password must contain capital, small letters, number and special characters'
      )
      .min(8, 'Minimum Length is 8')
      .required('Password is required')
  })

  const formik = useFormik<PartialUsersAccountCreateProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      FirstName: '',
      LastName: '',
      Email: '',
      Phone: '',
      UserName: '',
      Password: ''
    },
    onSubmit: onSubmit
  })

  return (
    <>
      <Dialog
        title='Create New User Account'
        open={open}
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={create.isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          <TextField
            id='FirstName'
            variant='outlined'
            label='First Name'
            value={formik.values.FirstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched && formik.touched.FirstName && Boolean(formik.errors.FirstName)}
            helperText={
              formik.touched && formik.touched.FirstName && formik.errors && formik.errors.FirstName
            }
            fullWidth
          />
          <TextField
            id='LastName'
            variant='outlined'
            label='Last Name'
            value={formik.values.LastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched && formik.touched.LastName && Boolean(formik.errors.LastName)}
            helperText={
              formik.touched && formik.touched.LastName && formik.errors && formik.errors.LastName
            }
            fullWidth
          />
          <TextField
            id='Email'
            variant='outlined'
            label='Email'
            value={formik.values.Email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched && formik.touched.Email && Boolean(formik.errors.Email)}
            helperText={
              formik.touched && formik.touched.Email && formik.errors && formik.errors.Email
            }
            fullWidth
          />
          <TextField
            id='Phone'
            variant='outlined'
            label='Phone'
            value={formik.values.Phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched && formik.touched.Phone && Boolean(formik.errors.Phone)}
            helperText={
              formik.touched && formik.touched.Phone && formik.errors && formik.errors.Phone
            }
            fullWidth
          />
          <TextField
            id='UserName'
            variant='outlined'
            label='User Name'
            value={formik.values.UserName}
            onKeyDown={(event) => {
              if (!/[A-Z0-9a-z@\\._]/.test(event.key)) event.preventDefault()
            }}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            inputProps={{ autoComplete: 'off' }}
            error={formik.touched && formik.touched.UserName && Boolean(formik.errors.UserName)}
            helperText={
              formik.touched && formik.touched.UserName && formik.errors && formik.errors.UserName
            }
            fullWidth
          />
          <FormControl variant='outlined'>
            <InputLabel htmlFor='Password'>Password</InputLabel>
            <OutlinedInput
              id='Password'
              label='Password'
              type={visibility ? 'text' : 'password'}
              value={formik.values.Password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              autoComplete='new-password'
              error={formik.touched && formik.touched.Password && Boolean(formik.errors.Password)}
              endAdornment={
                <IconButton
                  aria-label='password visibility'
                  onClick={() => setVisibility(!visibility)}
                  edge='end'
                >
                  {visibility ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              }
              fullWidth
            />
            {formik.touched && formik.touched.Password && formik.errors && (
              <FormHelperText error id='Password'>
                {formik.errors.Password}
              </FormHelperText>
            )}
          </FormControl>
        </Container>
      </Dialog>

      <Notification
        open={!create.isLoading && !create.isUninitialized}
        onClose={() => (create.isError ? create.reset() : location.reload())}
        isError={Boolean(create.error) && create.isError}
        message={GLOBAL.returnExceptionMessage(create.isError, create.error as ErrorProps)}
      />
    </>
  )
}

export default Create
