/* eslint-disable @typescript-eslint/no-explicit-any */

import { useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import { isEmpty } from 'lodash'
import * as yup from 'yup'
import { Container, TextField, Typography } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import type { Dayjs } from 'dayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import Dialog from 'components/dialog/dialog'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import type { PartialTokenStateProps } from 'modules/partial'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import { useUpdateTokenValidityMutation } from 'store/tokenRequest'
import DialogStyle from './style'

const Edit = ({ open, id, onClose }: DetailStateProps & { id: [] }) => {
  if (!open) return <></>

  const [updateTokenValidity, update] = useUpdateTokenValidityMutation()

  const [startDate, setStartDate] = useState<Dayjs | null>(null)
  const [endDate, setEndDate] = useState<Dayjs | null>(null)

  const scheme = yup.object<PartialTokenStateProps>({
    ValidFrom: yup.string().required('Valid From is required'),
    ValidUntil: yup.string().required('Valid Until is required')
  })

  const formik = useFormik<PartialTokenStateProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      VoucherTokenId: '',
      ValidFrom: '',
      ValidUntil: ''
    },
    onSubmit: (values: PartialTokenStateProps) => handleSubmit(values)
  })

  const customCode = (arrStr: string, type: string) => {
    const customItems = arrStr.split(',')

    for (let i = 0; i < customItems.length; i++) {
      customItems[i] =
        type === 'id' ? customItems[i].split('-')[0] : customItems[i].split('-')[1] + '\n'
    }

    return type === 'id' ? customItems.map(Number) : customItems.join('')
  }

  const handleSubmit = (e: PartialTokenStateProps) => {
    const payload: any = {
      VoucherTokenId: customCode(String(id), 'id'),
      ValidFrom: e.ValidFrom,
      ValidUntil: e.ValidUntil
    }

    updateTokenValidity(payload)
  }

  const handleChangeStartDate = (date: Dayjs | null) => {
    setStartDate(date)
    const newDate = (!isEmpty(date) && date.format('YYYY-MM-DD')) || ''
    formik.setFieldValue('ValidFrom', newDate)
  }

  const handleChangeEndDate = (date: Dayjs | null) => {
    setEndDate(date)
    const newDate = (!isEmpty(date) && date.format('YYYY-MM-DD')) || ''
    formik.setFieldValue('ValidUntil', newDate)
  }

  return (
    <>
      <Dialog
        open={open}
        title='Edit Validity'
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={update.isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          {id && (
            <>
              <FormikProvider value={formik}>
                <Typography>{id.length} Token(s) found</Typography>
                <TextField
                  id='outlined-multiline-static'
                  multiline
                  rows={4}
                  defaultValue={customCode(String(id), 'code')}
                  inputProps={{ readOnly: true }}
                />
                <Typography>Change Validity</Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                  <DatePicker
                    value={startDate}
                    onChange={handleChangeStartDate}
                    label='Valid From'
                    format='DD-MM-YYYY'
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                  <DatePicker
                    value={endDate}
                    minDate={dayjs(startDate)}
                    onChange={handleChangeEndDate}
                    label='Valid Until'
                    format='DD-MM-YYYY'
                  />
                </LocalizationProvider>
              </FormikProvider>
            </>
          )}
        </Container>
      </Dialog>

      <Notification
        open={!update.isLoading && !update.isUninitialized}
        onClose={() => (update.isError ? update.reset() : location.reload())}
        isError={Boolean(update.error) && update.isError}
        message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
      />
    </>
  )
}

export default Edit
