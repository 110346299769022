import { useEffect } from 'react'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import {
  Autocomplete,
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material'
import Dialog from 'components/dialog/dialog'
import type { PartialHyundaiCatalogCreateProps } from 'modules/partial'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import { useCreateHyundaiCatalogMutation } from 'store/hyundaiModel'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import {
  useGetCarModelDropdownListByQuery,
  useGetCarModelDropdownListMutation,
  useGetYearsDropdownListByMutation
} from 'store/dropdown'
import GlobalStyle from 'modules/styles'
import DialogStyle from './style'

const Create = ({ open, carName, onClose }: DetailStateProps & { carName: string }) => {
  const [createHyundaiCatalog, create] = useCreateHyundaiCatalogMutation()
  const [getYearsDropdownListBy, years] = useGetYearsDropdownListByMutation()
  const [getCarModelDropdownList, model] = useGetCarModelDropdownListMutation()

  const { data: carModel, isLoading: carModelIsLoading } = useGetCarModelDropdownListByQuery()

  useEffect(() => {
    getYearsDropdownListBy()
  }, [])

  const selectedCar =
    (carModel && carModel.find((car) => car.name.toLowerCase() == carName.toLowerCase())) || null

  const scheme = yup.object<PartialHyundaiCatalogCreateProps>({
    Trim: yup.string().required('Trim is required'),
    Color: yup.string().required('Color is required'),
    ColorCode: yup.string().required('ColorCode is required'),
    Year: yup.string().required('Year is required'),
    Description: yup.string().required('Default is required'),
    Remark: yup.string().required('Remark is required'),
    CarModelId: yup.string().required('Car Model Type is required'),
    LtmId: yup.string().required('LtmId is required'),
    LtmName: yup.string().required('LtmName is required')
  })

  const formik = useFormik<PartialHyundaiCatalogCreateProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      Trim: '',
      Color: '',
      ColorCode: '',
      Year: null,
      IsDefault: '',
      Description: '',
      Remark: '',
      CarModelId: (selectedCar && selectedCar.id) || null,
      LtmId: '',
      LtmName: ''
    },
    onSubmit: (values: PartialHyundaiCatalogCreateProps) => createHyundaiCatalog(values)
  })

  return (
    <>
      <Dialog
        open={open}
        title='Create New Hyundai Catalog'
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={false}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          <FormikProvider value={formik}>
            <TextField
              id='Trim'
              variant='outlined'
              label='Trim'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched && formik.touched.Trim && Boolean(formik.errors.Trim)}
              helperText={
                formik.touched && formik.touched.Trim && formik.errors && formik.errors.Trim
              }
              fullWidth
            />
            <FormControl>
              <InputLabel id='Year'>Year</InputLabel>
              <Select
                labelId='Year'
                id='Year'
                value={formik.values.Year}
                label='Visibility'
                onChange={(event) => formik.setFieldValue('Year', event.target.value)}
              >
                {years.data &&
                  years.isSuccess &&
                  years.data.map((item, index) => (
                    <MenuItem value={item} key={index}>
                      {item}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <TextField
              id='Color'
              variant='outlined'
              label='Color'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched && formik.touched.Color && Boolean(formik.errors.Color)}
              helperText={
                formik.touched && formik.touched.Color && formik.errors && formik.errors.Color
              }
              fullWidth
            />

            <TextField
              id='ColorCode'
              variant='outlined'
              label='Color Code'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched && formik.touched.ColorCode && Boolean(formik.errors.ColorCode)}
              helperText={
                formik.touched &&
                formik.touched.ColorCode &&
                formik.errors &&
                formik.errors.ColorCode
              }
              fullWidth
            />
            <Autocomplete
              sx={{ flex: 1 }}
              options={carModel || (model && model.data) || []}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) =>
                option && value ? option.name == value.name : false
              }
              onOpen={() => getCarModelDropdownList()}
              onChange={(_, model) => formik.setFieldValue('CarModelId', model && model.id)}
              value={selectedCar}
              ListboxProps={GlobalStyle.ListBox}
              readOnly
              renderOption={(props, item) => (
                <li {...props} key={item.id}>
                  {item.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='Model'
                  label='Model'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {(model.isLoading || carModelIsLoading) && (
                          <CircularProgress color='inherit' size={20} />
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
            />

            <FormControl fullWidth>
              <InputLabel id='IsDefault'>Default</InputLabel>
              <Select
                labelId='IsDefault'
                id='IsDefault'
                value={formik.values.IsDefault}
                label='Visibility'
                onChange={(event) => formik.setFieldValue('IsDefault', event.target.value)}
              >
                <MenuItem value='true'>Yes</MenuItem>
                <MenuItem value='false'>No</MenuItem>
              </Select>
            </FormControl>
            <TextField
              id='LtmId'
              variant='outlined'
              label='LtmId'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched && formik.touched.LtmId && Boolean(formik.errors.LtmId)}
              helperText={
                formik.touched && formik.touched.LtmId && formik.errors && formik.errors.LtmId
              }
              fullWidth
            />
            <TextField
              id='LtmName'
              variant='outlined'
              label='LtmName'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched && formik.touched.LtmName && Boolean(formik.errors.LtmName)}
              helperText={
                formik.touched && formik.touched.LtmName && formik.errors && formik.errors.LtmName
              }
              fullWidth
            />

            <TextField
              id='Remark'
              variant='outlined'
              label='Remark'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched && formik.touched.Remark && Boolean(formik.errors.Remark)}
              helperText={
                formik.touched && formik.touched.Remark && formik.errors && formik.errors.Remark
              }
              fullWidth
            />
            <TextField
              id='Description'
              variant='outlined'
              label='Description'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched && formik.touched.Description && Boolean(formik.errors.Description)
              }
              helperText={
                formik.touched &&
                formik.touched.Description &&
                formik.errors &&
                formik.errors.Description
              }
              fullWidth
            />
          </FormikProvider>
        </Container>
      </Dialog>
      <Notification
        open={!create.isLoading && !create.isUninitialized}
        onClose={() => (create.isError ? create.reset() : location.reload())}
        isError={Boolean(create.error) && create.isError}
        message={GLOBAL.returnExceptionMessage(create.isError, create.error as ErrorProps)}
      />
    </>
  )
}

export default Create
