import { isEmpty } from 'lodash'
import { Box, Container, TextField } from '@mui/material'
import moment from 'moment'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import Drawer from 'components/drawer/detail'
import { useGetEvTransactionFinishedDetailQuery } from 'store/evTransaction'
import DetailStyle from './style'

const Detail = ({ open, id, onClose }: DetailStateProps & { id: string }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const { data, isLoading, isUninitialized, isSuccess, isError, error } =
    useGetEvTransactionFinishedDetailQuery(id)

  const formatDate = (e: string) => (!isEmpty(e) ? moment(e).format('DD-MM-YYYY HH:mm:ss') : '-')

  const transactionId = (data && data.transactionCode) || '-'
  const status = (data && data.status) || '-'
  const chargePointId = (data && data.chargePointCode) || '-'
  const chargeBoxGroupId = (data && data.chargeBoxGroupCode) || '-'
  const chargeBoxId = (data && data.chargeBoxCode) || '-'
  const EVModels = (data && data.carName) || '-'
  const startTrigger = (data && data.startTrigger) || '-'
  const stopTrigger = (data && data.stopTrigger) || '-'
  const kwhElapsed = (data && data.kwhElapsed) || '-'
  const tokenBeginningBalance = (data && GLOBAL.numberFormat(data.tokenBeginningBalance)) || '-'
  const electricityFee = (data && GLOBAL.numberFormat(data.electricityFee)) || '-'
  const adminFee = (data && GLOBAL.numberFormat(data.adminFee)) || '-'
  const totalChargingPayment = (data && GLOBAL.numberFormat(data.totalChargingPayment)) || '-'

  const gridDataLeft = [
    'Charging Transaction ID (Hyundai)',
    'Charging Transaction Status',
    'Charge Point ID',
    'Charger Box Group ID',
    'Charge Box ID',
    'EV Models',
    'Start Trigger',
    'Stop Trigger',
    'kWh Elapsed',
    'EV Token Beginning Balance',
    'Electricity Fee',
    'Admin Fee',
    'Total Charging Payment'
  ]

  const gridValueLeft = [
    transactionId,
    status,
    chargePointId,
    chargeBoxGroupId,
    chargeBoxId,
    EVModels,
    startTrigger,
    stopTrigger,
    kwhElapsed,
    tokenBeginningBalance,
    electricityFee,
    adminFee,
    totalChargingPayment
  ]

  const idTag = (data && data.idTag) || '-'
  const transactionCodeByPlugIt = (data && data.referenceId) || '-'
  const chargingTransactionRemark = (data && data.remark) || '-'
  const chargePointName = (data && data.chargePointName) || '-'
  const chargerCapacity = (data && data.chargeCapacity) || '-'
  const memberId = (data && data.memberCode) || '-'
  const startTime = (data && formatDate(data.chargingStartTime)) || '-'
  const stopTime = (data && formatDate(data.chargingEndTime)) || '-'
  const timeElapsed = (data && data.timeElapsed) || '-'
  const kwhLimit = (data && data.kwhLimit) || '-'
  const priceTableId = (data && data.priceId) || '-'
  const serviceFee = (data && GLOBAL.numberFormat(data.serviceFee)) || '-'
  const minimumKWh = (data && data.minimumKwhForAdminFee) || '-'
  const tokenEndingBalance = (data && GLOBAL.numberFormat(data.tokenEndingBalance)) || '-'

  const gridLabelRight = [
    'ID Tag',
    'Charging Transaction ID (PlugIt)',
    'Charging Transaction Remark',
    'Charge Point Name',
    'Charger Capacity (kW)',
    'User Member ID',
    'Start Time',
    'Stop Time',
    'Time Elapsed',
    'kWh Limit',
    'Price Table ID',
    'Service Fee',
    'Minimum kWh',
    'EV Token Ending Balance'
  ]

  const gridValueRight = [
    idTag,
    transactionCodeByPlugIt,
    chargingTransactionRemark,
    chargePointName,
    chargerCapacity,
    memberId,
    startTime,
    stopTime,
    timeElapsed,
    kwhLimit,
    priceTableId,
    serviceFee,
    minimumKWh,
    tokenEndingBalance
  ]

  return (
    <>
      <Drawer
        open={open}
        title='Charging Transaction Finished Detail'
        onClose={onClose}
        maxWidth='md'
      >
        <Container {...DetailStyle.Container}>
          {isLoading && <Loading />}
          {!isLoading && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: 2
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  gap: 2
                }}
              >
                {gridValueLeft.map((item, index) => (
                  <TextField
                    key={index}
                    variant='outlined'
                    label={gridDataLeft[index]}
                    value={item}
                    inputProps={{ readOnly: true }}
                    fullWidth
                  />
                ))}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  gap: 2
                }}
              >
                {gridValueRight.map((item, index) => (
                  <TextField
                    key={index}
                    variant='outlined'
                    label={gridLabelRight[index]}
                    value={item}
                    inputProps={{ readOnly: true }}
                    fullWidth
                  />
                ))}
              </Box>
            </Box>
          )}
        </Container>
      </Drawer>

      <Notification
        open={!isLoading && !isUninitialized && !isSuccess}
        onClose={() => (isError ? '' : null)}
        isError={isError}
        message={GLOBAL.returnExceptionMessage(isError, error as ErrorProps)}
      />
    </>
  )
}

export default Detail
