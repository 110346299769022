import CONSTANT from 'modules/constant'
import type { PaginationProps, PromoCodeDetailProps, PromoCodeListProps, ResultProps } from 'modules/types'
import type { PartialPromoCodeProps, PartialUpdatePromoCodeProps } from 'modules/partial'
import { API, download, headers } from './api'

const PromoCodeAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getListPromoCode: builder.mutation<PaginationProps<PromoCodeListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_PROMO_CODE_LIST,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    downloadPromoCode: builder.mutation<PaginationProps<PromoCodeListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_PROMO_CODE_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        responseHandler: download,
        body: body,
        formData: true
      })
    }),
    deletePromoCode: builder.mutation<ResultProps, number>({
      query: (id) => ({
        url: CONSTANT.URL_PROMO_CODE_DELETE + id,
        method: 'DELETE',
        headers: headers()
      })
    }),
    createPromoCode: builder.mutation<ResultProps, PartialPromoCodeProps>({
      query: (body) => ({
        url: CONSTANT.URL_PROMO_CODE_CREATE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    getDetailPromoCode: builder.query<PromoCodeDetailProps, number>({
      query: (id) => ({
        url: CONSTANT.URL_PROMO_CODE_DETAIL+ id,
        method: 'GET',
        headers: headers()
      })
    }),
    updatePromoCode: builder.mutation<ResultProps, {body:PartialUpdatePromoCodeProps, id: number }>({
      query: (param) => ({
        url: CONSTANT.URL_PROMO_CODE_UPDATE + param.id,
        method: 'PUT',
        headers: headers(),
        body: param.body,
      })
    }),
  })
})

export const {
  useGetListPromoCodeMutation,
  useDownloadPromoCodeMutation,
  useDeletePromoCodeMutation,
  useCreatePromoCodeMutation,
  useUpdatePromoCodeMutation,

  useGetDetailPromoCodeQuery
} = PromoCodeAPI
