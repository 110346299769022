import { useEffect } from 'react'
import { isEmpty } from 'lodash'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import type { UserProps } from './modules/types'

const Guard = ({ component }: { component: JSX.Element }) => {
  const navigate = useNavigate()
  const state = useSelector((state: { user: UserProps }) => state.user)

  useEffect(() => {
    if (isEmpty(state.token)) return navigate('/login')
  })

  if (isEmpty(state.token)) return <></>

  return <>{component}</>
}

export default Guard
