import CONSTANT from 'modules/constant'
import type {
  ChargerDetailProps,
  ChargerFilterProps,
  ChargerPolicyListProps,
  PaginationProps,
  ResultProps
} from 'modules/types'
import type { PartialCreatePolicyChargerProps } from 'modules/partial'
import { API, download, headers } from './api'

const CatalogPolicyAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getPolicy: builder.mutation<PaginationProps<ChargerPolicyListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_CATALOG_POLICY_LIST,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    getPolicyDetail: builder.query<ChargerDetailProps, number | string>({
      query: (id) => ({
        url: CONSTANT.URL_CATALOG_POLICY + id,
        method: 'GET',
        headers: headers()
      })
    }),

    createPolicy: builder.mutation<ResultProps, PartialCreatePolicyChargerProps>({
      query: (body) => ({
        url: CONSTANT.URL_CATALOG_POLICY_CREATE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    updatePolicy: builder.mutation<
      ResultProps,
      { body: PartialCreatePolicyChargerProps; id: number }
    >({
      query: ({ body, id }) => ({
        url: CONSTANT.URL_CATALOG_POLICY + id,
        method: 'PUT',
        headers: headers(),
        body: body
      })
    }),
    deletePolicy: builder.mutation<ResultProps, number>({
      query: (id) => ({
        url: CONSTANT.URL_CATALOG_POLICY + id,
        method: 'DELETE',
        headers: headers()
      })
    }),
    getPolicyFilter: builder.mutation<ChargerFilterProps[], void>({
      query: () => ({
        url: CONSTANT.URL_CATALOG_POLICY + 'ddl?start=0&length=100',
        method: 'GET',
        headers: headers()
      })
    }),
    downloadEvPolicy: builder.mutation<PaginationProps<ChargerPolicyListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_CATALOG_POLICY_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true,
        responseHandler: download
      })
    })
  })
})

export const {
  useGetPolicyMutation,
  useDeletePolicyMutation,
  useCreatePolicyMutation,
  useUpdatePolicyMutation,
  useGetPolicyFilterMutation,
  useDownloadEvPolicyMutation,

  useGetPolicyDetailQuery
} = CatalogPolicyAPI
