import CONSTANT from 'modules/constant'
import type {
  HyundaiCatalogDetailProps,
  HyundaiCatalogListProps,
  HyundaiModelDetailProps,
  HyundaiModelListProps,
  PaginationProps,
  ResultProps
} from 'modules/types'
import type { PartialHyundaiCatalogCreateProps } from 'modules/partial'
import { API, download, headers } from './api'

const HyundaiModelAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getHyundaiModelList: builder.mutation<PaginationProps<HyundaiModelListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_CATALOG_HYUNDAI_LIST,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    downloadHyundaiModel: builder.mutation<PaginationProps<HyundaiModelListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_CATALOG_HYUNDAI_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        responseHandler: download,
        body: body,
        formData: true
      })
    }),
    createHyundaiModel: builder.mutation<ResultProps, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_CATALOG_HYUNDAI_CREATE,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    updateHyundaiModel: builder.mutation<ResultProps, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_CATALOG_HYUNDAI_UPDATE,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    getDetailHyundaiModel: builder.query<HyundaiModelDetailProps, number | string>({
      query: (id) => ({
        url: CONSTANT.URL_CATALOG_HYUNDAI_DETAIL + id,
        method: 'GET',
        headers: headers()
      })
    }),
    deleteHyundaiModel: builder.mutation<ResultProps, { id: number }>({
      query: (body) => ({
        url: CONSTANT.URL_CATALOG_HYUNDAI_DELETE,
        method: 'POST',
        headers: headers(),
        body: JSON.stringify(body)
      })
    }),
    getHyundaiCatalogList: builder.mutation<PaginationProps<HyundaiCatalogListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_CATALOG_HYUNDAI_CATALOG_LIST,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    downloadHyundaiCatalog: builder.mutation<PaginationProps<HyundaiCatalogListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_CATALOG_HYUNDAI_CATALOG_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        responseHandler: download,
        body: body,
        formData: true
      })
    }),
    deleteHyundaiCatalog: builder.mutation<ResultProps, { id: number }>({
      query: (body) => ({
        url: CONSTANT.URL_CATALOG_HYUNDAI_CATALOG_DELETE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    getDetailHyundaiCatalog: builder.query<HyundaiCatalogDetailProps, number | string>({
      query: (id) => ({
        url: CONSTANT.URL_CATALOG_HYUNDAI_CATALOG_DETAIL + id,
        method: 'GET',
        headers: headers()
      })
    }),
    createHyundaiCatalog: builder.mutation<ResultProps, PartialHyundaiCatalogCreateProps>({
      query: (body) => ({
        url: CONSTANT.URL_CATALOG_HYUNDAI_CATALOG_CREATE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    updateHyundaiCatalog: builder.mutation<ResultProps, PartialHyundaiCatalogCreateProps>({
      query: (body) => ({
        url: CONSTANT.URL_CATALOG_HYUNDAI_UPDATE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    })
  })
})

export const {
  useGetHyundaiModelListMutation,
  useCreateHyundaiModelMutation,
  useDeleteHyundaiModelMutation,
  useDownloadHyundaiModelMutation,
  useUpdateHyundaiModelMutation,
  useGetHyundaiCatalogListMutation,
  useDownloadHyundaiCatalogMutation,
  useDeleteHyundaiCatalogMutation,
  useCreateHyundaiCatalogMutation,
  useUpdateHyundaiCatalogMutation,

  useGetDetailHyundaiCatalogQuery,
  useGetDetailHyundaiModelQuery
} = HyundaiModelAPI
