import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { isEmpty } from 'lodash'
import * as yup from 'yup'
import { Autocomplete, CircularProgress, Container, TextField } from '@mui/material'
import Dialog from 'components/dialog/dialog'
import type { PartialChargeBoxProps } from 'modules/partial'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import {
  useGetSocketTypeDropdownListByQuery,
  useGetSocketTypeDropdownListMutation,
  useGetStatusChargingDropdownListByQuery,
  useGetStatusChargingDropdownListMutation
} from 'store/dropdown'
import {
  useGetChargePointDropdownListByQuery,
  useGetChargePointDropdownListMutation
} from 'store/evChargePoint'
import { useGetChargeBoxGroupDropdownListMutation } from 'store/evChargeBoxGroup'
import { useGetChargeBoxDetailQuery, useUpdateChargeBoxMutation } from 'store/evChargeBox'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import Loading from 'components/loading/loading'
import CONSTANT from 'modules/constant'
import GlobalStyle from 'modules/styles'
import DialogStyle from './style'

const Edit = ({ open, id, onClose }: DetailStateProps & { id: number }) => {
  if (!open) return <></>

  const { data: chargeBoxDetail, isLoading: chargeBoxLoading } = useGetChargeBoxDetailQuery(id)

  const { data: chargePointDropdownQuery, isLoading: chargePointDropdownQueryLoading } =
    useGetChargePointDropdownListByQuery(CONSTANT.DEFAULT_DROPDOWN_PAYLOAD)

  const { data: socketTypeDropdownQuery, isLoading: socketTypeDropdownQueryLoading } =
    useGetSocketTypeDropdownListByQuery(CONSTANT.DEFAULT_DROPDOWN_PAYLOAD)

  const { data: statusChargingDropdownQuery, isLoading: statusChargingDropdownQueryLoading } =
    useGetStatusChargingDropdownListByQuery(CONSTANT.DEFAULT_DROPDOWN_PAYLOAD)

  const [updateChargeBox, update] = useUpdateChargeBoxMutation()

  const [getChargePointDropdownList, chargePointDropdown] = useGetChargePointDropdownListMutation()
  const [getSocketTypeDropdownList, socketTypeDropdown] = useGetSocketTypeDropdownListMutation()
  const [_, statusChargingDropdown] = useGetStatusChargingDropdownListMutation()
  const [getChargeBoxGroupDropdownList, chargeBoxGroupDropdown] =
    useGetChargeBoxGroupDropdownListMutation()

  const scheme = yup.object<PartialChargeBoxProps>({
    chargeBoxGroupId: yup.number(),
    code: yup.string().required('Code is required'),
    status: yup.string().required('Status is required'),
    uniqueId: yup.string(),
    label: yup.string().required('Charge Box Name is required'),
    info: yup.string(),
    socketType: yup.string().required('Connector Type is required'),
    power: yup
      .number()
      .positive('Charging Capacity must be more than 0')
      .required('Charging Capacity is required'),
    chargeLevel: yup.number().nullable(),
    remark: yup.string()
  })

  const formik = useFormik<PartialChargeBoxProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      chargeBoxGroupId: (chargeBoxDetail && chargeBoxDetail.chargeBoxGroupId) || null,
      code: (chargeBoxDetail && chargeBoxDetail.code) || '',
      status: (chargeBoxDetail && chargeBoxDetail.status) || '',
      uniqueId: (chargeBoxDetail && chargeBoxDetail.uniqueId) || '',
      label: (chargeBoxDetail && chargeBoxDetail.label) || '',
      info: (chargeBoxDetail && chargeBoxDetail.info) || '',
      socketType: (chargeBoxDetail && chargeBoxDetail.socketType) || '',
      power: (chargeBoxDetail && chargeBoxDetail.power) || 0,
      chargeLevel: (chargeBoxDetail && chargeBoxDetail.chargeLevel) || null,
      remark: (chargeBoxDetail && chargeBoxDetail.remark) || ''
    },
    onSubmit: (values: PartialChargeBoxProps) =>
      chargeBoxDetail &&
      updateChargeBox({
        body: { ...values, chargeBoxGroupId: Number(chargeBoxGroupId) },
        id: Number(chargeBoxDetail.id)
      })
  })

  const [chargePointId, setChargePointId] = useState<string>('')
  const [chargeBoxGroupId, setChargeBoxGroupId] = useState<string>('')

  const errorData = formik.errors

  const loading =
    chargeBoxLoading ||
    chargePointDropdownQueryLoading ||
    socketTypeDropdownQueryLoading ||
    statusChargingDropdownQueryLoading

  const selectedChargePoint =
    (!chargePointDropdownQueryLoading &&
      chargePointDropdownQuery &&
      chargePointDropdownQuery.find((e) => String(e.value) == chargePointId)) ||
    null

  const selectedChargeBoxGroup =
    (chargeBoxGroupDropdown &&
      chargeBoxGroupDropdown.data &&
      chargeBoxGroupDropdown.data.find((e) => String(e.value) == chargeBoxGroupId)) ||
    null

  const selectedSocketType =
    (!socketTypeDropdownQueryLoading &&
      socketTypeDropdownQuery &&
      socketTypeDropdownQuery.find((e) => String(e.value) == formik.values.socketType)) ||
    null

  const selectedStatusCharging =
    (!statusChargingDropdownQueryLoading &&
      statusChargingDropdownQuery &&
      statusChargingDropdownQuery.find((e) => e.text == formik.values.status)) ||
    null

  useEffect(() => {
    if (chargePointId)
      getChargeBoxGroupDropdownList({
        ...CONSTANT.DEFAULT_DROPDOWN_PAYLOAD,
        id: Number(chargePointId)
      })
  }, [chargePointId])

  useEffect(() => {
    if (chargeBoxDetail) {
      setChargePointId(String(chargeBoxDetail.chargePointId))
      setChargeBoxGroupId(String(chargeBoxDetail.chargeBoxGroupId))
    }
  }, [chargeBoxDetail])

  return (
    <>
      <Dialog
        title='Update EV Charge Box'
        open={open}
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={update.isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          {loading && <Loading />}
          {!loading && (
            <>
              <TextField
                id='label'
                variant='outlined'
                label='Charge Box Name'
                value={formik.values.label}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched && formik.touched.label && Boolean(errorData.label)}
                helperText={formik.touched && formik.touched.label && errorData && errorData.label}
                fullWidth
              />
              <Autocomplete
                options={chargePointDropdownQuery || chargePointDropdown.data || []}
                getOptionLabel={(option) => option.text}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.value == value.value : false
                }
                onOpen={() => getChargePointDropdownList(CONSTANT.DEFAULT_DROPDOWN_PAYLOAD)}
                onChange={(_, chargePoint) => {
                  const point = (chargePoint && String(chargePoint.value)) || ''
                  setChargePointId(point)
                }}
                value={selectedChargePoint}
                ListboxProps={GlobalStyle.ListBox}
                renderOption={(props, item) => (
                  <li {...props} key={item.value}>
                    {item.text}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id='ChargePointId'
                    name='ChargePointId'
                    label='EV Charge Point'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {chargePointDropdown.isLoading && (
                            <CircularProgress color='inherit' size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
              />
              <Autocomplete
                key={chargePointId}
                options={chargeBoxGroupDropdown.data || []}
                getOptionLabel={(option) => option.text}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.value == value.value : false
                }
                onOpen={() =>
                  getChargeBoxGroupDropdownList({
                    ...CONSTANT.DEFAULT_DROPDOWN_PAYLOAD,
                    id: Number(chargePointId)
                  })
                }
                onChange={(_, chargeBoxGroup) => {
                  const point = (chargeBoxGroup && String(chargeBoxGroup.value)) || ''

                  setChargeBoxGroupId(point)
                  formik.setFieldValue('chargeBoxGroupId', point)
                }}
                value={selectedChargeBoxGroup}
                disabled={isEmpty(chargePointId)}
                ListboxProps={GlobalStyle.ListBox}
                renderOption={(props, item) => (
                  <li {...props} key={item.value}>
                    {item.text}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='ChargeBoxGroupId'
                    label='EV Charge Box Group ID'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {chargeBoxGroupDropdown.isLoading && (
                            <CircularProgress color='inherit' size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
              />
              <Autocomplete
                options={socketTypeDropdown.data || []}
                getOptionLabel={(option) => option.text}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.value == value.value : false
                }
                onOpen={() => getSocketTypeDropdownList(CONSTANT.DEFAULT_DROPDOWN_PAYLOAD)}
                onChange={(_, socketType) =>
                  formik.setFieldValue('socketType', String((socketType && socketType.value) || ''))
                }
                value={selectedSocketType}
                ListboxProps={GlobalStyle.ListBox}
                renderOption={(props, item) => (
                  <li {...props} key={item.value}>
                    {item.text}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='SocketType'
                    label='Connector Type'
                    error={
                      formik.touched && formik.touched.socketType && Boolean(errorData.socketType)
                    }
                    helperText={
                      formik.touched &&
                      formik.touched.socketType &&
                      errorData &&
                      errorData.socketType
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {socketTypeDropdown.isLoading && (
                            <CircularProgress color='inherit' size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
              />
              <TextField
                id='chargeLevel'
                variant='outlined'
                label='Charge Level'
                type='number'
                value={formik.values.chargeLevel}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched && formik.touched.chargeLevel && Boolean(errorData.chargeLevel)
                }
                helperText={
                  formik.touched && formik.touched.chargeLevel && errorData && errorData.chargeLevel
                }
                fullWidth
              />
              <TextField
                id='power'
                variant='outlined'
                label='Charging Capacity'
                type='number'
                value={formik.values.power}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched && formik.touched.power && Boolean(errorData.power)}
                helperText={formik.touched && formik.touched.power && errorData && errorData.power}
                fullWidth
              />
              <Autocomplete
                options={statusChargingDropdownQuery || statusChargingDropdown.data || []}
                getOptionLabel={(option) => option.text}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.value == value.value : false
                }
                onOpen={() => null}
                onChange={(_, status) =>
                  formik.setFieldValue('status', (status && status.text) || '')
                }
                value={selectedStatusCharging}
                ListboxProps={GlobalStyle.ListBox}
                readOnly
                renderOption={(props, item) => (
                  <li {...props} key={item.value}>
                    {item.text}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='status'
                    label='Charge Box Status'
                    error={formik.touched && formik.touched.status && Boolean(errorData.status)}
                    helperText={
                      formik.touched && formik.touched.status && errorData && errorData.status
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: <></>
                    }}
                  />
                )}
              />
            </>
          )}
        </Container>
      </Dialog>

      <Notification
        open={!update.isLoading && !update.isUninitialized}
        onClose={() => (update.isError ? update.reset() : location.reload())}
        isError={Boolean(update.error) && update.isError}
        message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
      />
    </>
  )
}

export default Edit
