import CONSTANT from 'modules/constant'
import type {
  VoucherCategoryListProps,
  PaginationProps,
  ResultProps,
  VoucherSubCategoryListProps,
  DropdownCategoryListProps
} from 'modules/types'
import type { PayloadVoucherSubCategoryProps } from 'modules/partial'
import { API, download, headers } from './api'

const VoucherCategoryAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getListVoucherCategory: builder.mutation<PaginationProps<VoucherCategoryListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_CATEGORY,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body
      })
    }),
    downloadVoucherCategory: builder.mutation<PaginationProps<VoucherCategoryListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_CATEGORY_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        responseHandler: download,
        body: body,
        formData: true
      })
    }),
    createVoucherCategory: builder.mutation<ResultProps, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_CATEGORY_CREATE,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body
      })
    }),
    deleteVoucherCategory: builder.mutation<ResultProps, { Id: number | string }>({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_CATEGORY_DELETE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    getVoucherCategoryDetail: builder.query<VoucherCategoryListProps, number | string>({
      query: (Id) => ({
        url: CONSTANT.URL_VOUCHER_CATEGORY_DETAIL + Id,
        method: 'GET',
        headers: headers()
      })
    }),
    enableVoucherCategory: builder.mutation<ResultProps, { Id: number | string; Status: string }>({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_CATEGORY_ENABLE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    updateVoucherCategory: builder.mutation<ResultProps, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_CATEGORY_UPDATE,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body
      })
    }),
    getListVoucherSubCategory: builder.mutation<
      PaginationProps<VoucherSubCategoryListProps>,
      FormData
    >({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_SUBCATEGORY,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body
      })
    }),
    downloadVoucherSubCategory: builder.mutation<
      PaginationProps<VoucherSubCategoryListProps>,
      FormData
    >({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_SUBCATEGORY_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        responseHandler: download,
        body: body,
        formData: true
      })
    }),
    createVoucherSubCategory: builder.mutation<ResultProps, PayloadVoucherSubCategoryProps>({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_SUBCATEGORY_CREATE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    getVoucherSubCategoryDetail: builder.query<VoucherSubCategoryListProps, number | string>({
      query: (Id) => ({
        url: CONSTANT.URL_VOUCHER_SUBCATEGORY_DETAIL + Id,
        method: 'GET',
        headers: headers()
      })
    }),
    deleteVoucherSubCategory: builder.mutation<ResultProps, { Id: number | string }>({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_SUBCATEGORY_DELETE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    getVoucherCategoryDropdown: builder.mutation<DropdownCategoryListProps[], void>({
      query: () => ({
        url: CONSTANT.URL_VOUCHER_CATEGORY_DROPDOWN,
        method: 'GET',
        headers: headers()
      })
    }),
    getVoucherSubCategoryDropdown: builder.mutation<
      DropdownCategoryListProps[],
      { id: string | number }
    >({
      query: (query) => ({
        url: CONSTANT.URL_VOUCHER_SUBCATEGORY_DROPDOWN + query.id,
        method: 'GET',
        headers: headers()
      })
    }),
    updateVoucherSubCategory: builder.mutation<ResultProps, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_SUBCATEGORY_UPDATE,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body
      })
    })
  })
})

export const {
  useGetListVoucherCategoryMutation,
  useDownloadVoucherCategoryMutation,
  useCreateVoucherCategoryMutation,
  useDeleteVoucherCategoryMutation,
  useGetVoucherCategoryDetailQuery,
  useEnableVoucherCategoryMutation,
  useUpdateVoucherCategoryMutation,
  useGetListVoucherSubCategoryMutation,
  useDownloadVoucherSubCategoryMutation,
  useCreateVoucherSubCategoryMutation,
  useGetVoucherSubCategoryDetailQuery,
  useDeleteVoucherSubCategoryMutation,
  useGetVoucherCategoryDropdownMutation,
  useGetVoucherSubCategoryDropdownMutation,
  useUpdateVoucherSubCategoryMutation
} = VoucherCategoryAPI
