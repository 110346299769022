import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  Typography
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import {
  useDeleteEvBrandMutation,
  useDownloadEvBrandMutation,
  useGetEvBrandMutation,
  useUpdateEvBrandMutation
} from 'store/evBrand'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { ErrorProps } from 'modules/types'
import CONSTANT from 'modules/constant'
import type { PartialEvBrandProps } from 'modules/partial'
import Loading from 'components/loading/loading'
import Icon from 'components/icon'
import MenuModalItem from 'components/menuItem/menuItem'
import GlobalStyle from 'modules/styles'
import Detail from './detail/detail'
import Create from './create/create'
import Edit from './edit/edit'

const EVCarBrand = () => {
  const navigate = useNavigate()

  const [getEvBrand, evBrand] = useGetEvBrandMutation()
  const [downloadEvBrand, download] = useDownloadEvBrandMutation()
  const [updateEvBrand, update] = useUpdateEvBrandMutation()
  const [deleteEvBrand, deleteBrand] = useDeleteEvBrandMutation()

  const [filter, setFilter] = useState({
    status: ''
  })

  const [payload, setPayload] = useState<PartialEvBrandProps>({
    start: 0,
    length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    field: CONSTANT.DEFAULT_FAQ_CATEGORY_FIELD.join(','),
    search: { value: '' },
    status: ''
  })

  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [dialogUpdate, setDialogUpdate] = useState<boolean>(false)
  const [dialogCreate, setDialogCreate] = useState<boolean>(false)
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [menu, setMenu] = useState<null | number>(null)

  const onSearch = (value: string) => setPayload({ ...payload, start: 0, search: { value } })

  const onChangePage = (value: number) => setPayload({ ...payload, start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, length: value })

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const onResync = () => {
    const formData = new FormData()

    formData.append('start', payload.start.toString())
    formData.append('length', payload.length.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(payload.status)) formData.append('status', payload.status)

    getEvBrand(formData)
  }

  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('start', String(0))
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(payload.status)) formData.append('status', payload.status)
    if (type && !isEmpty(type)) formData.append('Download-Type', type)

    downloadEvBrand(formData)
  }

  const onFilter = (state: boolean) => setDialogFilter(state)
  const onCreate = () => setDialogCreate(true)

  const tableHeadTitles = ['Name', 'status']

  const openAnchor = Boolean(anchor)

  const totalPage = evBrand.data && GLOBAL.tableTotalPage(evBrand.data.recordsTotal)

  const numbers = GLOBAL.tableNumber(payload.start)

  useEffect(() => {
    onResync()
  }, [payload])

  return (
    <>
      <Content
        title='EV Car Brand'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        onAdditional={onCreate}
        additionalTitle='Create New EV Car Brand'
        additionalPrefixIcon='Create'
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={evBrand.data && evBrand.data.recordsTotal}
        page={payload.start + 1}
        isLoading={evBrand.isLoading}
        useDownloadDropdown
      >
        {evBrand.isSuccess &&
          evBrand.data &&
          evBrand.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.id}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.id}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.id}`}
                  aria-labelledby={`button-${index}-${table.id}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem
                    onClick={() => onRemoveAnchor(() => navigate(GLOBAL.encodeUrl(table.name)))}
                  >
                    Open
                  </MenuItem>
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View
                  </MenuItem>
                  <MenuItem onClick={() => onRemoveAnchor(() => setDialogUpdate(true))}>
                    Edit
                  </MenuItem>
                  <MenuModalItem
                    title={table.status == 'Enabled' ? 'Disable' : 'Enable'}
                    onClick={() =>
                      onRemoveAnchor(() =>
                        updateEvBrand({
                          id: table.id,
                          name: table.name,
                          status: table.status == 'Enabled' ? 'Disabled' : 'Enabled'
                        })
                      )
                    }
                  >
                    {table.status == 'Enabled' ? 'Disable' : 'Enable'}
                  </MenuModalItem>
                  <MenuModalItem
                    title='Delete'
                    loading={deleteBrand.isLoading}
                    onClick={() => onRemoveAnchor(() => deleteEvBrand({ id: table.id }))}
                  >
                    <Typography {...GlobalStyle.ErrorTypography}>Delete</Typography>
                  </MenuModalItem>
                </Menu>
                <Detail
                  id={table.id}
                  open={drawerDetail && menu == index}
                  onClose={() => setDrawerDetail(false)}
                />
                <Edit
                  id={table.id}
                  open={dialogUpdate && menu == index}
                  onClose={() => setDialogUpdate(false)}
                />
              </TableCell>
              <TableCell>{table.name || '-'}</TableCell>
              <TableCell>
                <Chip
                  label={table.status}
                  color={table.status == 'Enabled' ? 'success' : 'error'}
                />
              </TableCell>
            </TableRow>
          ))}
      </Content>

      {evBrand.isLoading && <Loading />}

      <Create open={dialogCreate} onClose={() => setDialogCreate(false)} />

      <Filter
        open={dialogFilter}
        title='Filter'
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        onReset={() => {
          setFilter({
            status: ''
          })
          setPayload({
            ...payload,
            start: 0,
            status: ''
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({ ...payload, start: 0, ...filter })
          onFilter(false)
        }}
      >
        <FormControl fullWidth>
          <InputLabel id='select-label'>Status</InputLabel>
          <Select
            labelId='select-label'
            id='id-select-label'
            value={filter.status}
            label='Status'
            onChange={(event) => setFilter({ ...filter, status: event.target.value })}
          >
            <MenuItem value='Enabled'>Enabled</MenuItem>
            <MenuItem value='Disabled'>Disabled</MenuItem>
          </Select>
        </FormControl>
      </Filter>

      <Notification
        open={!evBrand.isLoading && !evBrand.isUninitialized && !evBrand.isSuccess}
        onClose={() => evBrand.reset()}
        isError={Boolean(evBrand.error) && evBrand.isError}
        message={GLOBAL.returnExceptionMessage(evBrand.isError, evBrand.error as ErrorProps)}
      />

      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />

      <Notification
        open={!update.isLoading && !update.isUninitialized}
        onClose={() => (update.isError ? update.reset() : location.reload())}
        isError={Boolean(update.error) && update.isError}
        message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
      />

      <Notification
        open={!deleteBrand.isLoading && !deleteBrand.isUninitialized}
        onClose={() => (deleteBrand.isError ? deleteBrand.reset() : location.reload())}
        isError={Boolean(deleteBrand.error) && deleteBrand.isError}
        message={GLOBAL.returnExceptionMessage(
          deleteBrand.isError,
          deleteBrand.error as ErrorProps
        )}
      />
    </>
  )
}

export default EVCarBrand
