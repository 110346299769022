import ReactQuill from 'react-quill'
import { Container, Grid, TextField, Typography } from '@mui/material'
import * as yup from 'yup'
import { decode, encode } from 'html-entities'
import Loading from 'components/loading/loading'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import { useGetVoucherFaqDetailQuery, useUpdateVoucherFaqMutation } from 'store/voucherFAQ'
import Dialog from 'components/dialog/dialog'
import type { PartialVoucherFAQProps } from 'modules/partial'
import FORMIK from 'modules/formik'
import 'react-quill/dist/quill.snow.css'
import GLOBAL from 'modules/global'
import Notification from 'components/notification'
import DrawerStyle from './style'

const Edit = ({ open, id, onClose }: DetailStateProps & { id: number }) => {
  if (!open) return <></>

  const { data, isFetching, isLoading } = useGetVoucherFaqDetailQuery(id)
  const [updateVoucherFaq, update] = useUpdateVoucherFaqMutation()

  const scheme = yup.object<PartialVoucherFAQProps>({
    id: yup.string().required('ID is required'),
    idVoucherDetailEn: yup.number().required('Voucher ID is required'),
    shortDescEn: yup.string().required('Short Description is required'),
    informationEn: yup.string().required('Information is required'),
    termsAndConditionsEn: yup.string().required('Terms and Condition is required'),
    idVoucherDetailId: yup.number().required('Voucher ID is required'),
    shortDescId: yup.string().required('Short Description is required'),
    informationId: yup.string().required('Information is required'),
    termsAndConditionsId: yup.string().required('Terms and Condition is required')
  })

  const initialValues: PartialVoucherFAQProps = {
    id: (data && data.id) || null,
    idVoucherDetailEn: (data && data.idVoucherDetailEn) || null,
    shortDescEn: (data && data.shortDescEn) || '',
    informationEn: (data && data.informationEn && decode(data.informationEn)) || '',
    termsAndConditionsEn:
      (data && data.termsAndConditionsEn && decode(data.termsAndConditionsEn)) || '',
    idVoucherDetailId: (data && data.idVoucherDetailId) || null,
    shortDescId: (data && data.shortDescId) || '',
    informationId: (data && data.informationId && decode(data.informationId)) || '',
    termsAndConditionsId:
      (data && data.termsAndConditionsId && decode(data.termsAndConditionsId)) || ''
  }

  const formik = FORMIK.useFormFormik<PartialVoucherFAQProps>(scheme, initialValues, (values) =>
    updateVoucherFaq({
      ...values,
      informationEn: encode(values.informationEn),
      termsAndConditionsEn: encode(values.termsAndConditionsEn),
      informationId: encode(values.informationId),
      termsAndConditionsId: encode(values.termsAndConditionsId)
    })
  )

  return (
    <>
      <Dialog
        open={open}
        title='Edit Voucher FAQ'
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={update.isLoading}
      >
        <Container {...DrawerStyle.Container}>
          {(isLoading || isFetching) && !data && <Loading />}
          {data && (
            <>
              <TextField
                id='id'
                variant='outlined'
                label='Voucher ID'
                value={formik.values.id}
                inputProps={{ readOnly: true }}
                fullWidth
              />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    label='Language'
                    value='EN-US'
                    inputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id='shortDescEn'
                    variant='outlined'
                    label='Short Description *'
                    value={formik.values.shortDescEn}
                    onChange={(e) => formik.setFieldValue('shortDescEn', e)}
                    InputLabelProps={{ shrink: true }}
                    error={
                      formik.touched &&
                      formik.touched.shortDescEn &&
                      Boolean(formik.errors.shortDescEn)
                    }
                    helperText={
                      formik.touched &&
                      formik.touched.shortDescEn &&
                      formik.errors &&
                      formik.errors.shortDescEn
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Typography {...DrawerStyle.Title}>Information *</Typography>
              <ReactQuill
                theme='snow'
                id='informationEn'
                value={formik.values.informationEn}
                onChange={(e) => formik.setFieldValue('informationEn', GLOBAL.emptyQuill(e))}
              />
              <Typography {...DrawerStyle.Title}>Terms and Condition *</Typography>
              <ReactQuill
                theme='snow'
                id='termsAndConditionsEn'
                value={formik.values.termsAndConditionsEn}
                onChange={(e) => formik.setFieldValue('termsAndConditionsEn', GLOBAL.emptyQuill(e))}
              />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    label='Language'
                    value='ID'
                    inputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id='shortDescId'
                    variant='outlined'
                    label='Short Description *'
                    value={formik.values.shortDescId}
                    onChange={(e) => formik.setFieldValue('shortDescId', e)}
                    InputLabelProps={{ shrink: true }}
                    error={
                      formik.touched &&
                      formik.touched.shortDescId &&
                      Boolean(formik.errors.shortDescId)
                    }
                    helperText={
                      formik.touched &&
                      formik.touched.shortDescId &&
                      formik.errors &&
                      formik.errors.shortDescId
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Typography {...DrawerStyle.Title}>Information *</Typography>
              <ReactQuill
                theme='snow'
                id='informationId'
                value={formik.values.informationId}
                onChange={(e) => formik.setFieldValue('informationId', GLOBAL.emptyQuill(e))}
              />
              <Typography {...DrawerStyle.Title}>Terms and Condition *</Typography>
              <ReactQuill
                theme='snow'
                id='termsAndConditionsId'
                value={formik.values.termsAndConditionsId}
                onChange={(e) => formik.setFieldValue('termsAndConditionsId', GLOBAL.emptyQuill(e))}
              />
            </>
          )}
        </Container>
      </Dialog>

      <Notification
        open={!update.isLoading && !update.isUninitialized}
        onClose={() => (update.isError ? update.reset() : location.reload())}
        isError={Boolean(update.error) && update.isError}
        message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
      />
    </>
  )
}

export default Edit
