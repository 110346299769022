import { BrowserRouter, Routes } from 'react-router-dom'
import './App.scss'
import { Route } from 'use-react-router-breadcrumbs'
import EVCharge from 'pages/ev/charge/page'
import EVChargeBoxGroup from 'pages/ev/charge/box/group/page'
import EVChargeBoxList from 'pages/ev/charge/box/list/page'
import EVChargeBox from 'pages/ev/charge/box/page'
import EVPolicy from 'pages/ev/policy/page'
import EVPolicyCharger from 'pages/ev/policy/charger/page'
import EVPartnerVoucher from 'pages/ev/policy/partner/page'
import EVQrCode from 'pages/ev/policy/qrcode/page'
import EVCarModel from 'pages/catalog/evmodel/page'
import EVChargePoint from 'pages/ev/charge/point/page'
import EVChargePointList from 'pages/ev/charge/point/list/page'
import EVChargePointListGroup from 'pages/ev/charge/point/group/page'
import EVChargePointListBoxGroup from 'pages/ev/charge/point/group/box/page'
import Voucher from 'pages/voucher/page'
import VoucherCategory from 'pages/voucher/category/category/page'
import EVPartner from 'pages/ev/partner/page'
import EVHistory from 'pages/ev/history/page'
import EVHistoryOverview from 'pages/ev/history/overview/page'
import Category from 'pages/voucher/category/page'
import VoucherSubCategory from 'pages/voucher/category/subcategory/page'
import EVPartnerDashboard from 'pages/ev/dashboard/page'
import EVTransactionHistory from 'pages/ev/dashboard/transaction/page'
import EVOverview from 'pages/ev/dashboard/overview/page'
import HyundaiCarModel from 'pages/catalog/hyundaimodel/page'
import HyundaiCarModelList from 'pages/catalog/hyundaimodel/carModel/page'
import HyundaiCarCatalog from 'pages/catalog/hyundaimodel/carCatalog/page'
import EVCarBrandCatalog from 'pages/catalog/evmodel/carCatalog/page'
import EVCarBrand from 'pages/catalog/evmodel/carBrand/page'
import EVRequest from 'pages/ev/dashboard/request/page'
import Token from 'pages/voucher/token/page'
import TokenCategory from 'pages/voucher/token/category/page'
import TokenRequestMain from 'pages/voucher/token/request/main/page'
import TokenRequestSub from 'pages/voucher/token/request/sub/page'
import TokenRequest from 'pages/voucher/token/request/page'
import TokenList from 'pages/voucher/token/list/page'
import TokenImport from 'pages/voucher/token/import/page'
import TokenImportList from 'pages/voucher/token/import/main/page'
import TokenImportSub from 'pages/voucher/token/import/sub/page'
import VoucherList from 'pages/voucher/voucher/page'
import EVCharging from 'pages/ev/charging/page'
import Promo from 'pages/promo/page'
import PromoCode from 'pages/promo/code/page'
import Campaign from 'pages/campaign/page'
import CampaignStory from 'pages/campaign/story/page'
import CampaignEvent from 'pages/campaign/event/page'
// import EventCampaign from 'pages/campaign/event/campaign/page'
// import EventChallenge from 'pages/campaign/event/challenge/page'
// import EventTransaction from 'pages/campaign/event/transaction/page'
// import EventRegistration from 'pages/campaign/event/registration/page'
// import ChallengeList from 'pages/campaign/event/challenge/challenge/page'
// import ChallengeActivity from 'pages/campaign/event/challenge/activity/page'
// import ChallengeSubActivity from 'pages/campaign/event/challenge/subActivity/page'
// import RegistrationTestDrive from 'pages/campaign/event/registration/testDrive/page'
// import RegistrationTransaction from 'pages/campaign/event/registration/transaction/page'
import PromoRedemption from 'pages/promo/redemption/page'
// import RegistrationSingleGame from 'pages/campaign/event/registration/singleGame/page'
import PromoBatch from 'pages/promo/batch/page'
import PromoBatchCategory from 'pages/promo/batch/category/page'
// import EventPrizeList from 'pages/campaign/event/prize/prize/page'
// import EventPrize from 'pages/campaign/event/prize/page'
// import EventPrizeRedemption from 'pages/campaign/event/prize/redemption/page'
import PromoBatchList from 'pages/promo/batch/list/page'
import ServiceBooking from 'pages/campaign/event/serviceBooking/page'
import Banner from 'pages/campaign/event/serviceBooking/banner/page'
import Transaction from 'pages/campaign/event/serviceBooking/transaction/page'
import VoucherFAQ from 'pages/voucher/faq/page'
import VoucherBenefits from 'pages/voucher/benefits/page'
import Notification from 'pages/notification/page'
import NotificationCustomer from 'pages/notification/customer/page'
import NotificationCustomerEmail from 'pages/notification/customer/email/page'
import NotificationCustomerPhone from 'pages/notification/customer/phone/page'
import NotificationCustomerPush from 'pages/notification/customer/push-notif/page'
import VoucherGift from 'pages/voucher/gift/page'
import VoucherPackage from 'pages/voucher/package/page'
import EVTopup from 'pages/ev/topup/page'
import EVPrice from 'pages/ev/price/page'
import EVTransaction from 'pages/ev/transaction/page'
import EVTransactionOngoing from 'pages/ev/transaction/ongoing/page'
import EVTransactionFinished from 'pages/ev/transaction/finished/page'
import EVPriceChargerBoxGroup from 'pages/ev/price/chargeBoxGroup/page'
import EVPriceList from 'pages/ev/price/list/page'
import GiftRedemption from 'pages/voucher/redemption/page'
import EVTransactionLog from 'pages/ev/transaction/log/page'
import EVTransactionFinishedList from 'pages/ev/transaction/finished/list/page'
import EVTransactionOngoingList from 'pages/ev/transaction/ongoing/list/page'
import EVHistoryOngoing from 'pages/ev/transaction/ongoing/page'
import EVHistoryOngoingList from 'pages/ev/transaction/ongoing/list/page'
import EVHistoryFinished from 'pages/ev/history/finished/page'
import EVHistoryFinishedList from 'pages/ev/history/finished/list/page'
import Coin from 'pages/coin/page'
import CoinSubmission from 'pages/coin/submission/page'
import VoucherUsageList from 'pages/voucher/usage/list/page'
import VoucherUsage from 'pages/voucher/usage/page'
import VoucherUsageTokenList from 'pages/voucher/usage/token/page'
import Guard from './Guard'
import NoRoute from './pages/404/404'
import EvLocation from './pages/ev/location/page'
import EV from './pages/ev/page'
import EvType from './pages/ev/type/page'
import Assignment from './pages/identity/assignment/page'
import Access from './pages/identity/control/access/page'
import Account from './pages/identity/control/account/page'
import Check from './pages/identity/control/check/page'
import Control from './pages/identity/control/page'
import Identity from './pages/identity/page'
import Roles from './pages/identity/roles/page'
import Layout from './pages/layout/page'
import Login from './pages/login/page'
import FaqCategory from './pages/master/faq/category/page'
import FAQList from './pages/master/faq/list/page'
import FAQ from './pages/master/faq/page'
import Dealer from './pages/master/location/dealer/page'
import Event from './pages/master/location/event/page'
import Location from './pages/master/location/page'
import PartnerCategory from './pages/master/location/partner/category/page'
import PartnerList from './pages/master/location/partner/list/page'
import Partner from './pages/master/location/partner/page'
import Master from './pages/master/page'
import PrivacyPolicy from './pages/master/privacy/page'
import RegionData from './pages/master/region/data/page'
import RegionDMS from './pages/master/region/dms/page'
import Region from './pages/master/region/page'
import TermsAndCondition from './pages/master/terms/page'
import Catalog from './pages/catalog/page'
import Profiling from './pages/profiling/page'
import CarInformation from './pages/profiling/carinformation/page'
import VoucherBooking from './pages/voucher-booking/page'
import Garuda from './pages/voucher-booking/garuda/page'
import CityLounge from './pages/voucher-booking/garuda/city-lounge/page'
import LoginOtp from './pages/loginOtp/page'

export const GenerateAppRoutes = () => (
  <Routes>
    <Route path='/' element={<Guard component={<Layout />} />}>
      <Route path='master' element={<Guard component={<Master />} />}>
        <Route path='region' element={<Guard component={<Region />} />}>
          <Route path='data' element={<Guard component={<RegionData />} />} />
          <Route path='dms' element={<Guard component={<RegionDMS />} />} />
        </Route>
        <Route path='faq' element={<Guard component={<FAQ />} />}>
          <Route index path=':language' element={<Guard component={<FaqCategory />} />} />
          <Route path=':language/:key' element={<Guard component={<FAQList />} />} />
        </Route>
        <Route path='faq' element={<Guard component={<FAQ />} />}>
          <Route index path='' element={<Guard component={<FaqCategory />} />} />
          <Route path=':key' element={<Guard component={<FAQList />} />} />
        </Route>
        <Route path='terms' element={<Guard component={<TermsAndCondition />} />} />
        <Route path='privacy' element={<Guard component={<PrivacyPolicy />} />} />
        <Route path='location' element={<Guard component={<Location />} />}>
          <Route path='dealer' element={<Guard component={<Dealer />} />} />
          <Route path='partner' element={<Guard component={<Partner />} />}>
            <Route index path='' element={<Guard component={<PartnerCategory />} />} />
            <Route path=':key' element={<Guard component={<PartnerList />} />} />
          </Route>
          <Route path='event' element={<Guard component={<Event />} />} />
        </Route>
      </Route>
      <Route path='ev' element={<Guard component={<EV />} />}>
        <Route path='charge' element={<Guard component={<EVCharge />} />}>
          <Route path='point' element={<Guard component={<EVChargePoint />} />}>
            <Route index path='' element={<Guard component={<EVChargePointList />} />} />
            <Route path=':id' element={<Guard component={<EVChargePointListGroup />} />} />
            <Route path=':id/:key' element={<Guard component={<EVChargePointListBoxGroup />} />} />
          </Route>
          <Route path='box' element={<Guard component={<EVChargeBox />} />}>
            <Route index path='' element={<Guard component={<EVChargeBoxList />} />} />
            <Route path=':key' element={<Guard component={<EVChargeBoxGroup />} />} />
          </Route>
        </Route>
        <Route path='topup' element={<Guard component={<EVTopup />} />} />
        <Route path='price' element={<Guard component={<EVPrice />} />}>
          <Route index path='' element={<Guard component={<EVPriceList />} />} />
          <Route path=':id' element={<Guard component={<EVPriceChargerBoxGroup />} />} />
        </Route>
        <Route path='transaction' element={<Guard component={<EVTransaction />} />}>
          <Route path='ongoing' element={<Guard component={<EVTransactionOngoing />} />}>
            <Route index path='' element={<Guard component={<EVTransactionOngoingList />} />} />
            <Route path=':id' element={<Guard component={<EVTransactionLog />} />} />
          </Route>
          <Route path='finished' element={<Guard component={<EVTransactionFinished />} />}>
            <Route index path='' element={<Guard component={<EVTransactionFinishedList />} />} />
            <Route path=':id' element={<Guard component={<EVTransactionLog />} />} />
          </Route>
        </Route>
        <Route path='policy' element={<Guard component={<EVPolicy />} />}>
          <Route path='charger' element={<Guard component={<EVPolicyCharger />} />} />
          <Route path='partner' element={<Guard component={<EVPartnerVoucher />} />} />
          <Route path='qrcode' element={<Guard component={<EVQrCode />} />} />R
        </Route>
        <Route path='location' element={<Guard component={<EvLocation />} />} />
        <Route path='charger' element={<Guard component={<EvType />} />} />
        <Route path='partner' element={<Guard component={<EVPartner />} />} />
        <Route path='dashboard' element={<Guard component={<EVPartnerDashboard />} />}>
          <Route path='history' element={<Guard component={<EVTransactionHistory />} />} />
          <Route path='overview' element={<Guard component={<EVOverview />} />} />
          <Route path='request' element={<Guard component={<EVRequest />} />} />
        </Route>
        <Route path='history' element={<Guard component={<EVHistory />} />}>
          <Route path='overview' element={<Guard component={<EVHistoryOverview />} />} />
          <Route path='ongoing' element={<Guard component={<EVHistoryOngoing />} />}>
            <Route index path='' element={<Guard component={<EVHistoryOngoingList />} />} />
            <Route path=':id' element={<Guard component={<EVTransactionLog />} />} />
          </Route>
          <Route path='finished' element={<Guard component={<EVHistoryFinished />} />}>
            <Route index path='' element={<Guard component={<EVHistoryFinishedList />} />} />
            <Route path=':id' element={<Guard component={<EVTransactionLog />} />} />
          </Route>
        </Route>
        <Route path='charging' element={<Guard component={<EVCharging />} />} />
      </Route>
      <Route path='catalog' element={<Guard component={<Catalog />} />}>
        <Route path='hyundai' element={<Guard component={<HyundaiCarModel />} />}>
          <Route index path='' element={<Guard component={<HyundaiCarModelList />} />} />
          <Route path=':key' element={<Guard component={<HyundaiCarCatalog />} />} />
        </Route>
        <Route path='brand' element={<Guard component={<EVCarModel />} />}>
          <Route index path='' element={<Guard component={<EVCarBrand />} />} />
          <Route path=':name' element={<Guard component={<EVCarBrandCatalog />} />} />
        </Route>
      </Route>
      <Route path='profiling' element={<Guard component={<Profiling />} />}>
        <Route path='carinformation' element={<Guard component={<CarInformation />} />} />
      </Route>
      <Route path='identity' element={<Guard component={<Identity />} />}>
        <Route path='control' element={<Guard component={<Control />} />}>
          <Route path='account' element={<Guard component={<Account />} />} />
          <Route path='access' element={<Guard component={<Access />} />} />
          <Route path='check' element={<Guard component={<Check />} />} />
        </Route>
        <Route path='roles' element={<Guard component={<Roles />} />} />
        <Route path='assignment' element={<Guard component={<Assignment />} />} />
      </Route>
      <Route path='voucher' element={<Guard component={<Voucher />} />}>
        <Route path='list' element={<Guard component={<VoucherList />} />} />
        <Route path='faq' element={<Guard component={<VoucherFAQ />} />} />
        <Route path='usage' element={<Guard component={<VoucherUsage />} />}>
          <Route index path='' element={<Guard component={<VoucherUsageList />} />} />
          <Route index path=':id' element={<Guard component={<VoucherUsageTokenList />} />} />
        </Route>
        <Route path='category' element={<Guard component={<Category />} />}>
          <Route index path='' element={<Guard component={<VoucherCategory />} />} />
          <Route path=':key' element={<Guard component={<VoucherSubCategory />} />} />
        </Route>
        <Route path='gift' element={<Guard component={<VoucherGift />} />} />
        <Route path='redemption' element={<Guard component={<GiftRedemption />} />} />
        <Route path='token' element={<Guard component={<Token />} />}>
          <Route index path='list' element={<Guard component={<TokenList />} />} />
          <Route path='category' element={<Guard component={<TokenCategory />} />} />
          <Route path='request' element={<Guard component={<TokenRequest />} />}>
            <Route index path='' element={<Guard component={<TokenRequestMain />} />} />
            <Route path=':key' element={<Guard component={<TokenRequestSub />} />} />
          </Route>
          <Route path='import' element={<Guard component={<TokenImport />} />}>
            <Route index path='' element={<Guard component={<TokenImportList />} />} />
            <Route path=':key' element={<Guard component={<TokenImportSub />} />} />
          </Route>
        </Route>
        <Route path='benefits' element={<Guard component={<VoucherBenefits />} />} />
        <Route path='package' element={<Guard component={<VoucherPackage />} />} />
      </Route>
      <Route path='promo' element={<Guard component={<Promo />} />}>
        <Route index path='code' element={<Guard component={<PromoCode />} />} />
        <Route index path='redemption' element={<Guard component={<PromoRedemption />} />} />
        <Route path='batch' element={<Guard component={<PromoBatch />} />}>
          <Route index path='' element={<Guard component={<PromoBatchCategory />} />} />
          <Route path=':key' element={<Guard component={<PromoBatchList />} />} />
        </Route>
      </Route>
      <Route path='campaign' element={<Guard component={<Campaign />} />}>
        <Route path='event' element={<Guard component={<CampaignEvent />} />}>
          {/* <Route path='campaign' element={<Guard component={<EventCampaign />} />} /> */}
          {/* <Route path='challenge' element={<Guard component={<EventChallenge />} />}>
            <Route path='list' element={<Guard component={<ChallengeList />} />} />
            <Route path='activity' element={<Guard component={<ChallengeActivity />} />} />
            <Route path='subactivity' element={<Guard component={<ChallengeSubActivity />} />} />
          </Route>
          <Route path='transaction' element={<Guard component={<EventTransaction />} />} />
          <Route path='registration' element={<Guard component={<EventRegistration />} />}>
            <Route path='test' element={<Guard component={<RegistrationTestDrive />} />} />
            <Route path='game' element={<Guard component={<RegistrationSingleGame />} />} />
            <Route path='transaction' element={<Guard component={<RegistrationTransaction />} />} />
          </Route>
          <Route path='prize' element={<Guard component={<EventPrize />} />}>
            <Route path='list' element={<Guard component={<EventPrizeList />} />} />
            <Route path='redemption' element={<Guard component={<EventPrizeRedemption />} />} />
          </Route> */}
          <Route path='service-booking' element={<Guard component={<ServiceBooking />} />}>
            <Route path='banner' element={<Guard component={<Banner />} />} />
            <Route path='booking-history' element={<Guard component={<Transaction />} />} />
          </Route>
        </Route>
        <Route path='story' element={<Guard component={<CampaignStory />} />} />
      </Route>
      <Route path='coin' element={<Guard component={<Coin />} />}>
        <Route path='submission' element={<Guard component={<CoinSubmission />} />} />
      </Route>
      <Route path='notification' element={<Guard component={<Notification />} />}>
        <Route path='customer' element={<Guard component={<NotificationCustomer />} />}>
          <Route path='email' element={<Guard component={<NotificationCustomerEmail />} />} />
          <Route path='phone' element={<Guard component={<NotificationCustomerPhone />} />} />
          <Route path='push-notif' element={<Guard component={<NotificationCustomerPush />} />} />
        </Route>
      </Route>
      <Route path='voucher-booking' element={<Guard component={<VoucherBooking />} />}>
        <Route path='garuda' element={<Guard component={<Garuda />} />}>
          <Route path='city-lounge' element={<Guard component={<CityLounge />} />} />
        </Route>
      </Route>
      <Route path='*' element={<NoRoute />} />
    </Route>
    <Route path='/login' element={<Login />} />
    <Route path='/login-otp' element={<LoginOtp />} />
  </Routes>
)

const App = () => (
  <BrowserRouter>
    <GenerateAppRoutes />
  </BrowserRouter>
)

export default App
