import { useEffect, useState } from 'react'
import {
  Autocomplete,
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import Icon from 'components/icon'
import type { DetailStateProps } from 'modules/types'
import {
  useGetDetailPromoCodeBatchSubQuery,
  useGetPromoCodeQrCodeMutation
} from 'store/promoCodeBatch'
import Loading from 'components/loading/loading'
import GLOBAL from 'modules/global'
import BackdropImage from 'components/backdropImage/backdropImage'
import DialogStyle from './style'

const Detail = ({ open, id, onClose }: DetailStateProps & { id: number }) => {
  if (!open) return <></>

  const { data, isLoading, isFetching } = useGetDetailPromoCodeBatchSubQuery(id)
  const [generateQR, QR] = useGetPromoCodeQrCodeMutation()
  const [previewBackdrop, setPreviewBackdrop] = useState<boolean>(false)

  const promoCodeQr = QR && QR.data && QR.data.value

  const campaignName = (data && data.name) || '-'
  const prefix = (data && data.prefix) || '-'
  const rewardType = (data && data.rewardType) || '-'
  const quantity = (data && data.quantity) || '-'
  const codeLength = (data && data.codeLength) || '-'
  const maxRedemption = (data && data.maxRedemption) || '-'
  const validFrom = (data && GLOBAL.formatDate(data.validFrom)) || '-'
  const validTo = (data && GLOBAL.formatDate(data.validTo)) || '-'
  const messageTitle = (data && data.messageTitle) || '-'
  const messageBody = (data && data.messageBody) || '-'
  const minSignUpDate = (data && GLOBAL.formatDate(data.minSignUpDate)) || '-'
  const maxSignUpDate = (data && GLOBAL.formatDate(data.maxSignUpDate)) || '-'
  const status = (data && data.status) || '-'
  const progress = (data && data.progress) || '-'
  const notes = (data && data.notes) || '-'

  const gridDataLabelFirst = ['Campign Name', 'Prefix', 'Reward']
  const gridDataValueFirst = [campaignName, prefix, rewardType]
  const gridDataLabelSecond = [
    'Quantity',
    'Code Length',
    'Maximum Redemption (max token distributed for this campaign)',
    'Start Time',
    'End Time',
    'Progress',
    'Notes',
    'Task Status'
  ]

  const gridDataValueSecond = [
    quantity,
    codeLength,
    maxRedemption,
    validFrom,
    validTo,
    progress,
    notes,
    status
  ]

  const gridDataLabelRight = [
    'Maximum Sign Up Date',
    'Minimum Sign Up Date',
    'Valid From',
    'Valid Until'
  ]
  const gridDataValueRight = [maxSignUpDate, minSignUpDate, validFrom, validTo]
  const DescMessage = () => (
    <Box sx={{ marginBottom: '10px' }}>
      <Typography variant='caption' display='block' gutterBottom color='#525252'>
        *You can use these placeholder in the field above
      </Typography>
      <Typography variant='caption' display='block' gutterBottom color='#525252'>
        <span
          style={{ fontSize: '14px', fontWeight: '500', color: '#242423' }}
        >{`{userFullName} `}</span>
        Name of the recipient
      </Typography>
      <Typography variant='caption' display='block' gutterBottom color='#525252'>
        <span
          style={{ fontSize: '14px', fontWeight: '500', color: '#242423' }}
        >{`{voucherName} `}</span>
        Voucher Title
      </Typography>
      <Typography variant='caption' display='block' gutterBottom color='#525252'>
        <span
          style={{ fontSize: '14px', fontWeight: '500', color: '#242423' }}
        >{`{coinsAmount} `}</span>
        Coin amount given to user
      </Typography>
    </Box>
  )
  useEffect(() => {
    if (data && data.url && QR.isUninitialized)
      generateQR({
        text: data.url,
        options: {
          size: 10,
          eccLevel: 'l'
        }
      })
  })
  return (
    <Dialog open={open} fullWidth maxWidth='md' onClose={onClose}>
      <DialogContent>
        <DialogTitle
          sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
        >
          <Typography variant='h6'>Detail Promo Code Batch List</Typography>
          <Box>
            <IconButton onClick={onClose}>
              <Icon icon='Close' />
            </IconButton>
          </Box>
        </DialogTitle>
        {(isLoading || isFetching) && !data && <Loading />}

        {data && (
          <Box {...DialogStyle.wrapperBox}>
            <Box {...DialogStyle.wrapperLeftContent}>
              {gridDataValueFirst.map((item, index) => (
                <TextField
                  key={index}
                  {...DialogStyle.textField}
                  value={item}
                  disabled
                  variant='outlined'
                  label={gridDataLabelFirst[index]}
                  fullWidth
                />
              ))}
              <TableContainer
                component={Paper}
                sx={{ maxHeight: 512, marginBottom: '10px' }}
                variant='outlined'
              >
                <Table aria-label='Data Table' stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Voucher</TableCell>
                      <TableCell>Qty</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data &&
                      data.vouchers &&
                      data.vouchers.map((table, index) => (
                        <TableRow key={index}>
                          <TableCell>{table.voucherName}</TableCell>
                          <TableCell>{table.quantity}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {gridDataValueSecond.map((item, index) => (
                <TextField
                  key={index}
                  {...DialogStyle.textField}
                  value={item}
                  disabled
                  variant='outlined'
                  label={gridDataLabelSecond[index]}
                  fullWidth
                />
              ))}
            </Box>
            <Box
              sx={{
                flex: 1
              }}
            >
              <Box sx={{ padding: '10px 24px' }}>
                <Autocomplete
                  sx={{ maxWidth: '409px', marginBottom: '10px' }}
                  multiple
                  id='fixed-tags-demo'
                  defaultValue={(data && data.members) || []}
                  // value={userType}
                  // onOpen={() => getMemberTypeDropdown()}
                  isOptionEqualToValue={(option, value) =>
                    option && value ? option.memberName == value.memberName : false
                  }
                  disabled
                  // onChange={(event, newValue) => {
                  //   setUserType(newValue)
                  // }}
                  options={(data && data.members) || []}
                  getOptionLabel={(option) => option.memberName}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip label={option.memberName} {...getTagProps({ index })} key={index} />
                    ))
                  }
                  style={{ width: 500 }}
                  renderInput={(params) => (
                    <TextField sx={{ maxWidth: '409px' }} {...params} label='User Type' />
                  )}
                />
                {gridDataValueRight.map((item, index) => (
                  <TextField
                    key={index}
                    {...DialogStyle.textField}
                    value={item}
                    disabled
                    variant='outlined'
                    label={gridDataLabelRight[index]}
                    fullWidth
                  />
                ))}
                <TextField
                  value={messageTitle}
                  disabled
                  id='subDistrict'
                  variant='outlined'
                  label='Message Title'
                  {...DialogStyle.textField}
                  fullWidth
                />
                <DescMessage />
                <TextField
                  value={messageBody}
                  disabled
                  id='postalCode'
                  variant='outlined'
                  label='Message Body'
                  sx={{ marginTop: '5px' }}
                  {...DialogStyle.textField}
                  fullWidth
                />
                <DescMessage />
                <Box {...DialogStyle.wrapperImage}>
                  {!promoCodeQr && <Typography style={{ color: '#cfcfcf' }}>No Image</Typography>}
                  {promoCodeQr && (
                    <BackdropImage
                      open={previewBackdrop}
                      onOpen={() => setPreviewBackdrop(true)}
                      onClose={() => setPreviewBackdrop(false)}
                    >
                      <img
                        src={`data:image/jpeg;base64,${promoCodeQr}`}
                        alt={promoCodeQr}
                        style={{ height: '300px', width: '394px', objectFit: 'contain' }}
                      />
                    </BackdropImage>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default Detail
