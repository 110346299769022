import { useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import { isEmpty } from 'lodash'
import * as yup from 'yup'
import {
  Autocomplete,
  Box,
  CircularProgress,
  Container,
  Grid,
  TextField,
  Typography
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import type { Dayjs } from 'dayjs'
import { decode } from 'html-entities'
import Dialog from 'components/dialog/dialog'
import Loading from 'components/loading/loading'
import type { DetailStateProps, ErrorProps, voucherGiftProps } from 'modules/types'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import GlobalStyle from 'modules/styles'
import { useGetVoucherGiftDetailQuery, useUpdateVoucherGiftMutation } from 'store/voucherGift'
import type { PayloadVoucherGiftProps } from 'modules/partial'
import { useGetVoucherListDropdownMutation } from 'store/tokenRequest'
import { useGetUsernameDropdownMutation } from 'store/dropdown'
import DialogStyle from './style'

const Edit = ({ open, id, onClose }: DetailStateProps & { id: string | number }) => {
  if (!open) return <></>

  const { data, isFetching, isLoading } = useGetVoucherGiftDetailQuery(id)
  const [getVoucherListDropdown, voucherDropdown] = useGetVoucherListDropdownMutation()
  const [getUsernameDropdown, dropdownUser] = useGetUsernameDropdownMutation()
  const [updateVoucherGift, update] = useUpdateVoucherGiftMutation()

  const [startDate, setStartDate] = useState<Dayjs | null>(null)
  const [endDate, setEndDate] = useState<Dayjs | null>(null)
  const [validFrom, setValidFrom] = useState<Dayjs | null>(null)
  const [validUntil, setValidUntil] = useState<Dayjs | null>(null)

  const scheme = yup.object<voucherGiftProps>({
    voucherGiftName: yup.string().required('Voucher Gift Name is required'),
    assignmentMethode: yup.string().required('Assignment Methode is required'),
    assignValidFrom: yup.string().when('assignmentMethode', {
      is: (val: string) => val && val.toLocaleLowerCase().includes('url'),
      then: () => yup.string().required('Assignment Valid From is required'),
      otherwise: () => yup.array().notRequired()
    }),
    assignValidUntil: yup.string().when('assignmentMethode', {
      is: (val: string) => val && val.toLocaleLowerCase().includes('url'),
      then: () => yup.string().required('Assignment Valid Until is required'),
      otherwise: () => yup.array().notRequired()
    }),
    voucherId: yup.string().required('Voucher is required'),
    assignmentMode: yup.string().required('Assignment Mode is required'),
    token: yup.string().when('assignmentMode', {
      is: (val: string) => val == 'AssignCustomToken',
      then: () => yup.string().required('Token is required'),
      otherwise: () => yup.array().notRequired()
    }),
    validFrom: yup.string().when('assignmentMode', {
      is: (val: string) => val != 'UseAvailableToken',
      then: () => yup.string().required('Token Valid From is required'),
      otherwise: () => yup.array().notRequired()
    }),
    validUntil: yup.string().when('assignmentMode', {
      is: (val: string) => val != 'UseAvailableToken',
      then: () => yup.string().required('Token Valid Until is required'),
      otherwise: () => yup.array().notRequired()
    }),
    notes: yup.string().required('Notes is required'),
    notiftitle: yup.string().required('Notification Title is required'),
    notifdata: yup.string().required('Notification Body is required')
  })

  const formik = useFormik<voucherGiftProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      voucherGiftName: (data && data.voucherGiftName) || '',
      assignmentMethode: (data && data.assignmentMethode) || '',
      assignValidFrom: (data && data.assignValidFrom) || '',
      assignValidUntil: (data && data.assignValidUntil) || '',
      voucherId: (data && String(data.voucherId)) || '',
      assignmentMode: (data && data.assignmentMode) || '',
      token: (data && data.token) || '',
      validFrom: (data && data.validFrom) || '',
      validUntil: (data && data.validUntil) || '',
      notes: (data && data.notes) || '',
      userId: (data && data.userId) || '',
      notiftitle: decode(data && data.dataObjectNotif.notiftitle) || '',
      notifdata: decode(data && data.dataObjectNotif.notifdata) || '',
      notifurl: '',
      notifImage: ''
    },
    onSubmit: (values: voucherGiftProps) => handleSubmit(values)
  })

  const handleSubmit = (e: voucherGiftProps) => {
    const notification = {
      notiftitle: e.notiftitle,
      notifdata: e.notifdata,
      notifurl: '',
      notifImage: ''
    }

    const data: PayloadVoucherGiftProps = {
      voucherGiftId: id,
      voucherGiftName: e.voucherGiftName,
      assignmentMethode: e.assignmentMethode,
      assignValidFrom: e.assignValidFrom
        ? dayjs(e.assignValidFrom).startOf('day').format('YYYY-MM-DD HH:mm:ss')
        : '',
      assignValidUntil: e.assignValidUntil
        ? dayjs(e.assignValidUntil).endOf('day').format('YYYY-MM-DD HH:mm:ss')
        : '',
      voucherId: e.voucherId,
      assignmentMode: e.assignmentMode,
      token: e.token,
      validFrom: e.validFrom ? dayjs(e.validFrom).startOf('day').format('YYYY-MM-DD HH:mm:ss') : '',
      validUntil: e.validUntil
        ? dayjs(e.validUntil).endOf('day').format('YYYY-MM-DD HH:mm:ss')
        : '',
      notes: e.notes,
      userId: e.userId,
      dataObjectNotif: notification
    }

    updateVoucherGift(data)
  }

  const handleChangeStartDate = (date: Dayjs | null) => {
    setStartDate(date)
    const newDate = (!isEmpty(date) && date.format('YYYY-MM-DD')) || ''
    formik.setFieldValue('assignValidFrom', newDate)
  }

  const handleChangeEndDate = (date: Dayjs | null) => {
    setEndDate(date)
    const newDate = (!isEmpty(date) && date.format('YYYY-MM-DD')) || ''
    formik.setFieldValue('assignValidUntil', newDate)
  }

  const handleChangeValidFrom = (date: Dayjs | null) => {
    setValidFrom(date)
    const newDate = (!isEmpty(date) && date.format('YYYY-MM-DD')) || ''
    formik.setFieldValue('validFrom', newDate)
  }

  const handleChangeValidUntil = (date: Dayjs | null) => {
    setValidUntil(date)
    const newDate = (!isEmpty(date) && date.format('YYYY-MM-DD')) || ''
    formik.setFieldValue('validUntil', newDate)
  }

  const masterAssigmentMethod = [
    { value: 'url', name: 'URL' },
    { value: 'immediately', name: 'Immediately' }
  ]

  const masterAssignmentMode = [
    { value: 'GenerateNewToken', name: 'GenerateNewToken' },
    { value: 'UseAvailableToken', name: 'UseAvailableToken' },
    { value: 'AssignCustomToken', name: 'AssignCustomToken' }
  ]

  return (
    <>
      <Dialog
        open={open}
        title='Update Gift Voucher'
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={isLoading}
        isDisabled={!formik.isValid}
        maxWidth={'md'}
      >
        <Container {...DialogStyle.Container}>
          {(isLoading || isFetching) && !data && <Loading />}
          {data && (
            <>
              <FormikProvider value={formik}>
                <Grid container spacing={2} columns={16}>
                  <Grid xs={8} marginTop={1}>
                    <Grid xs={16} margin={1}>
                      <TextField
                        id='voucherGiftName'
                        variant='outlined'
                        label='Gift Voucher Campaign Name'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched &&
                          formik.touched.voucherGiftName &&
                          Boolean(formik.errors.voucherGiftName)
                        }
                        helperText={
                          formik.touched &&
                          formik.touched.voucherGiftName &&
                          formik.errors &&
                          formik.errors.voucherGiftName
                        }
                        fullWidth
                        value={formik.values.voucherGiftName}
                      />
                    </Grid>
                    <Grid xs={16} margin={1}>
                      <Autocomplete
                        options={masterAssigmentMethod || []}
                        getOptionLabel={(list) => list.name}
                        isOptionEqualToValue={(option, value) =>
                          option && value ? option.value == value.value : false
                        }
                        onChange={(_, id) =>
                          formik.setFieldValue('assignmentMethode', id && id.value)
                        }
                        ListboxProps={GlobalStyle.ListBox}
                        renderOption={(props, item) => (
                          <li {...props} key={item.name}>
                            {item.name}
                          </li>
                        )}
                        value={
                          masterAssigmentMethod &&
                          masterAssigmentMethod.find(
                            (e) => e.value == (data && data.assignmentMethode)
                          )
                        }
                        readOnly
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name='assignmentMethode'
                            label='Assigment Method'
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: <>{params.InputProps.endAdornment}</>
                            }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid
                      xs={16}
                      margin={1}
                      display={
                        formik.values.assignmentMethode.toLocaleLowerCase().includes('immediately')
                          ? 'none'
                          : ''
                      }
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                        <DatePicker
                          value={startDate || dayjs(data && data.assignValidFrom)}
                          onChange={handleChangeStartDate}
                          label='Assignment Valid From (for the URL)'
                          format='DD-MM-YYYY'
                          sx={{ width: '100%' }}
                          readOnly
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid
                      xs={16}
                      margin={1}
                      display={
                        formik.values.assignmentMethode.toLocaleLowerCase().includes('immediately')
                          ? 'none'
                          : ''
                      }
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                        <DatePicker
                          value={endDate || dayjs(data && data.assignValidUntil)}
                          minDate={dayjs(startDate)}
                          onChange={handleChangeEndDate}
                          label='Assignment Valid Until (for the URL)'
                          format='DD-MM-YYYY'
                          sx={{ width: '100%' }}
                          readOnly
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid xs={16} margin={1}>
                      <Autocomplete
                        options={voucherDropdown.data || []}
                        getOptionLabel={(list) => list.name}
                        isOptionEqualToValue={(option, value) =>
                          option && value ? option.id == value.id : false
                        }
                        onOpen={() => getVoucherListDropdown()}
                        onChange={(_, id) => formik.setFieldValue('voucherId', id && id.id)}
                        ListboxProps={GlobalStyle.ListBox}
                        renderOption={(props, item) => (
                          <li {...props} key={item.id}>
                            {item.name}
                          </li>
                        )}
                        defaultValue={{
                          id: data && data.voucherId,
                          name: data && data.voucherName,
                          key: '',
                          language: ''
                        }}
                        readOnly
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name='voucherId'
                            label='Voucher'
                            error={
                              formik.touched &&
                              formik.touched.voucherId &&
                              Boolean(formik.errors.voucherId)
                            }
                            helperText={
                              formik.touched &&
                              formik.touched.voucherId &&
                              formik.errors &&
                              formik.errors.voucherId
                            }
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {voucherDropdown.isLoading && (
                                    <CircularProgress color='inherit' size={20} />
                                  )}
                                  {params.InputProps.endAdornment}
                                </>
                              )
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid xs={16} margin={1}>
                      <Autocomplete
                        options={masterAssignmentMode || []}
                        getOptionLabel={(list) => list.name}
                        isOptionEqualToValue={(option, value) =>
                          option && value ? option.value == value.value : false
                        }
                        onChange={(_, id) => formik.setFieldValue('assignmentMode', id && id.value)}
                        ListboxProps={GlobalStyle.ListBox}
                        renderOption={(props, item) => (
                          <li {...props} key={item.name}>
                            {item.name}
                          </li>
                        )}
                        value={
                          masterAssignmentMode &&
                          masterAssignmentMode.find((e) => e.value == (data && data.assignmentMode))
                        }
                        readOnly
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name='assignmentMode'
                            label='Assigment Mode'
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: <>{params.InputProps.endAdornment}</>
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      xs={16}
                      margin={1}
                      display={
                        ['GenerateNewToken', 'UseAvailableToken'].includes(
                          formik.values.assignmentMode
                        )
                          ? 'none'
                          : ''
                      }
                    >
                      <TextField
                        id='token'
                        variant='outlined'
                        label='Token'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched && formik.touched.token && Boolean(formik.errors.token)
                        }
                        helperText={
                          formik.touched &&
                          formik.touched.token &&
                          formik.errors &&
                          formik.errors.token
                        }
                        fullWidth
                        value={formik.values.token}
                        inputProps={{ readOnly: true }}
                      />
                    </Grid>
                    <Grid
                      xs={16}
                      margin={1}
                      display={
                        ['UseAvailableToken'].includes(formik.values.assignmentMode) ? 'none' : ''
                      }
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                        <DatePicker
                          value={validFrom || dayjs(data && data.validFrom)}
                          onChange={handleChangeValidFrom}
                          label='Valid From'
                          format='DD-MM-YYYY'
                          sx={{ width: '100%' }}
                          readOnly
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid
                      xs={16}
                      margin={1}
                      display={
                        ['UseAvailableToken'].includes(formik.values.assignmentMode) ? 'none' : ''
                      }
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                        <DatePicker
                          value={validUntil || dayjs(data && data.validUntil)}
                          minDate={dayjs(validFrom)}
                          onChange={handleChangeValidUntil}
                          label='Valid Until'
                          format='DD-MM-YYYY'
                          sx={{ width: '100%' }}
                          readOnly
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid xs={16} margin={1}>
                      <TextField
                        multiline
                        id='notes'
                        label='Notes'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched && formik.touched.notes && Boolean(formik.errors.notes)
                        }
                        helperText={
                          formik.touched &&
                          formik.touched.notes &&
                          formik.errors &&
                          formik.errors.notes
                        }
                        fullWidth
                        rows={2}
                        value={formik.values.notes}
                      />
                    </Grid>
                  </Grid>

                  {/* Right Column */}
                  <Grid xs={8} marginTop={1}>
                    <Grid xs={16} margin={1}>
                      <Autocomplete
                        options={dropdownUser.data || []}
                        getOptionLabel={(list) => list.fullName}
                        isOptionEqualToValue={(option, value) =>
                          option && value ? option.userId == value.userId : false
                        }
                        onOpen={() => getUsernameDropdown()}
                        onChange={(_, id) => formik.setFieldValue('userId', id && id.userId)}
                        ListboxProps={GlobalStyle.ListBox}
                        renderOption={(props, item) => (
                          <li {...props} key={item.userId}>
                            {item.fullName}
                          </li>
                        )}
                        defaultValue={{
                          userId: data && data.userId,
                          fullName: data && data.fullNameUser,
                          email: ''
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name='userId'
                            label='User'
                            error={
                              formik.touched &&
                              formik.touched.userId &&
                              Boolean(formik.errors.userId)
                            }
                            helperText={
                              formik.touched &&
                              formik.touched.userId &&
                              formik.errors &&
                              formik.errors.userId
                            }
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {dropdownUser.isLoading && (
                                    <CircularProgress color='inherit' size={20} />
                                  )}
                                  {params.InputProps.endAdornment}
                                </>
                              )
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Box sx={{ border: 0.5, margin: 1, borderColor: 'lightgray' }}>
                      <Grid xs={16} margin={1}>
                        <TextField
                          id='notiftitle'
                          variant='outlined'
                          label='Notification Title'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched &&
                            formik.touched.notiftitle &&
                            Boolean(formik.errors.notiftitle)
                          }
                          helperText={
                            formik.touched &&
                            formik.touched.notiftitle &&
                            formik.errors &&
                            formik.errors.notiftitle
                          }
                          fullWidth
                          value={formik.values.notiftitle}
                        />
                      </Grid>

                      <Grid xs={16} margin={1}>
                        <TextField
                          multiline
                          id='notifdata'
                          label='Notification Message Body'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched &&
                            formik.touched.notifdata &&
                            Boolean(formik.errors.notifdata)
                          }
                          helperText={
                            formik.touched &&
                            formik.touched.notifdata &&
                            formik.errors &&
                            formik.errors.notifdata
                          }
                          fullWidth
                          rows={4}
                          value={formik.values.notifdata}
                        />
                      </Grid>
                      <Grid xs={16} margin={1}>
                        <Typography
                          sx={{
                            fontStyle: 'italic',
                            color: 'GrayText',
                            fontSize: 12,
                            flexWrap: 'nowrap'
                          }}
                        >
                          * Override default notification body. You can use these placeholders:
                          <br />
                          <span style={{ fontWeight: 'bold', color: 'black' }}>
                            - {`{Fullname}`}{' '}
                          </span>
                          : Recipient&#39;s Full Name
                          <br />
                          <span style={{ fontWeight: 'bold', color: 'black' }}>
                            - {`{VoucherTitle}`}{' '}
                          </span>
                          : The Voucher Name
                          <br />
                          <span style={{ fontWeight: 'bold', color: 'black' }}>
                            - {`{MerchantName}`}{' '}
                          </span>
                          : The Merchant Name
                          <br />
                          <span style={{ fontWeight: 'bold', color: 'black' }}>
                            - {`{tokenValidUntil}`}{' '}
                          </span>
                          : The Voucher Token expired on date &#40;in UTC timezone&#41;
                        </Typography>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </FormikProvider>
            </>
          )}
        </Container>
      </Dialog>

      <Notification
        open={!update.isLoading && !update.isUninitialized}
        onClose={() => (update.isError ? update.reset() : location.reload())}
        isError={Boolean(update.error) && update.isError}
        message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
      />
    </>
  )
}

export default Edit
