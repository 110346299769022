import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Autocomplete,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  TextField
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import type { Dayjs } from 'dayjs'
import { useNavigate } from 'react-router-dom'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import CONSTANT from 'modules/constant'
import type { PartialVoucherCategoryProps } from 'modules/partial'
import Icon from 'components/icon'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { ErrorProps } from 'modules/types'
import GlobalStyle from 'modules/styles'
import {
  useDownloadTokenRequestMutation,
  useGetListTokenRequestMutation,
  useGetTokenCategoryDropdownMutation,
  useGetTokenStatusDropdownMutation,
  useGetVoucherListDropdownMutation
} from 'store/tokenRequest'
import { useGetTokenPartnerDropdownMutation } from 'store/token'
import Create from './create/create'
import Detail from './detail/detail'

const TokenRequestMain = () => {
  const navigate = useNavigate()
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [dialogCreate, setDialogCreate] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [menu, setMenu] = useState<null | number>(null)
  const [getListTokenRequest, tokenRequest] = useGetListTokenRequestMutation()
  const [downloadTokenRequest, download] = useDownloadTokenRequestMutation()
  const [getTokenCategoryDropdown, tokenCategoryDropdown] = useGetTokenCategoryDropdownMutation()
  const [getTokenPartnerDropdown, partnerDropdown] = useGetTokenPartnerDropdownMutation()
  const [getTokenStatusDropdown, statusDropdown] = useGetTokenStatusDropdownMutation()
  const [getVoucherListDropdown, voucherDropdown] = useGetVoucherListDropdownMutation()

  const [startDate, setStartDate] = useState<Dayjs | null>(null)
  const [endDate, setEndDate] = useState<Dayjs | null>(null)
  // const [dialogUpload, setDialogUpload] = useState<boolean>(false)

  const [payload, setPayload] = useState<PartialVoucherCategoryProps>({
    start: 0,
    length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    field: CONSTANT.DEFAULT_TOKEN_REQUEST_FIELD.join(','),
    search: { value: '' },
    status: ''
  })

  const [filter, setFilter] = useState({
    MinStartTime: '',
    MaxStartTime: '',
    tokenCategoryId: '',
    partner: '',
    status: '',
    voucherId: ''
  })

  const onSearch = (value: string) => setPayload({ ...payload, start: 0, search: { value } })
  const onResync = () => {
    const formData = new FormData()
    formData.append('start', payload.start.toString())
    formData.append('length', payload.length.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(filter.MinStartTime)) formData.append('min-created-date', filter.MinStartTime)
    if (!isEmpty(filter.MaxStartTime)) formData.append('max-created-date', filter.MaxStartTime)
    if (!isEmpty(filter.status)) formData.append('task-status', filter.status)
    if (!isEmpty(filter.partner)) formData.append('partner', filter.partner)
    if (!isEmpty(filter.voucherId)) formData.append('voucher-id', filter.voucherId)
    if (!isEmpty(filter.tokenCategoryId))
      formData.append('token-category-id', filter.tokenCategoryId)

    getListTokenRequest(formData)
  }
  const onFilter = (state: boolean) => setDialogFilter(state)
  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('start', String(0))
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(filter.MinStartTime)) formData.append('min-created-date', filter.MinStartTime)
    if (!isEmpty(filter.MaxStartTime)) formData.append('max-created-date', filter.MaxStartTime)
    if (!isEmpty(filter.status)) formData.append('task-status', filter.status)
    if (!isEmpty(filter.partner)) formData.append('partner', filter.partner)
    if (!isEmpty(filter.voucherId)) formData.append('voucher-id', filter.voucherId)
    if (!isEmpty(filter.tokenCategoryId))
      formData.append('token-category-id', filter.tokenCategoryId)
    if (type && !isEmpty(type)) formData.append('Download-Type', type)

    downloadTokenRequest(formData)
  }

  const onCreate = () => setDialogCreate(true)

  const onChangePage = (value: number) => setPayload({ ...payload, start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, length: value })

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const tableHeadTitles = [
    'Request Name',
    'Voucher Name',
    'Token Category',
    'Partner',
    'Start Time',
    'End Time',
    'Progress',
    'Notes',
    'Task Status',
    'Created At'
  ]

  const openAnchor = Boolean(anchor)

  const totalPage = tokenRequest.data && GLOBAL.tableTotalPage(tokenRequest.data.recordsTotal)

  const numbers = GLOBAL.tableNumber(payload.start)

  useEffect(() => {
    onResync()
  }, [payload])

  const handleChangeStartDate = (date: Dayjs | null) => {
    setStartDate(date)
    const newDate = (!isEmpty(date) && date?.format('YYYY-MM-DD')) || ''
    setFilter({ ...filter, MinStartTime: newDate, MaxStartTime: '' })
  }

  const handleChangeEndDate = (date: Dayjs | null) => {
    setEndDate(date)
    const newDate = (!isEmpty(date) && date?.format('YYYY-MM-DD')) || ''
    setFilter({ ...filter, MaxStartTime: newDate })
  }

  const statusData = (data: []) => data.map((item) => ({ id: item, name: item }))

  return (
    <>
      <Content
        title='Token Request'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        onAdditional={onCreate}
        additionalTitle='Create New Token Request'
        additionalPrefixIcon='Add'
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={tokenRequest.data && tokenRequest.data.recordsTotal}
        page={payload.start + 1}
        isLoading={tokenRequest.isLoading}
        useDownloadDropdown
      >
        {tokenRequest.isSuccess &&
          tokenRequest.data &&
          tokenRequest.data.data.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${item.id}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${item.id}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${item.id}`}
                  aria-labelledby={`button-${index}-${item.id}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem
                    onClick={() =>
                      onRemoveAnchor(() =>
                        navigate(GLOBAL.encodeUrl(item.requestName), {
                          state: { id: item.id, name: item.requestName }
                        })
                      )
                    }
                  >
                    Open
                  </MenuItem>
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View Detail
                  </MenuItem>
                </Menu>
                {drawerDetail && menu == index && (
                  <Detail
                    id={item.id}
                    open={drawerDetail && menu == index}
                    onClose={() => setDrawerDetail(false)}
                  />
                )}
              </TableCell>
              <TableCell>{item.requestName || '-'}</TableCell>
              <TableCell>{item.voucherName || '-'}</TableCell>
              <TableCell>{item.tokenCategory || '-'}</TableCell>
              <TableCell>{item.partner || '-'}</TableCell>
              <TableCell>{dayjs(item.startTime).format('DD MMMM YYYY HH:mm') || '-'}</TableCell>
              <TableCell>{dayjs(item.endTime).format('DD MMMM YYYY HH:mm') || '-'}</TableCell>
              <TableCell>{item.progress ? `${item.progress}%` : '-'}</TableCell>
              <TableCell>{item.progressNotes || '-'}</TableCell>
              <TableCell>{item.taskStatus || '-'}</TableCell>
              <TableCell>
                {(item.createdAt && dayjs(item.createdAt).format('DD MMMM YYYY HH:mm')) || '-'}
              </TableCell>
            </TableRow>
          ))}
      </Content>
      {tokenRequest.isLoading && <Loading />}

      <Filter
        open={dialogFilter}
        title='Filter'
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        onReset={() => {
          setFilter({
            MinStartTime: '',
            MaxStartTime: '',
            tokenCategoryId: '',
            partner: '',
            status: '',
            voucherId: ''
          })
          setStartDate(null)
          setEndDate(null)
          setPayload({ ...payload, start: 0, ...filter })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({ ...payload, start: 0, ...filter })
          onFilter(false)
        }}
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
          <DatePicker
            value={startDate}
            onChange={handleChangeStartDate}
            label='Min Created Date'
            format='DD-MM-YYYY'
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
          <DatePicker
            value={endDate}
            minDate={dayjs(startDate)}
            onChange={handleChangeEndDate}
            label='Max Created Date'
            format='DD-MM-YYYY'
          />
        </LocalizationProvider>
        <Autocomplete
          options={(voucherDropdown && voucherDropdown.data) || []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.id == value.id : false
          }
          onOpen={() => getVoucherListDropdown()}
          onChange={(_, voucherId) =>
            setFilter({
              ...filter,
              voucherId: (voucherId && String(voucherId.id)) || ''
            })
          }
          ListboxProps={GlobalStyle.ListBox}
          value={
            voucherDropdown &&
            voucherDropdown.data &&
            voucherDropdown.data.find((e) => e.id == String(filter.voucherId))
          }
          renderOption={(props, item) => (
            <li {...props} key={item.id}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='voucherId'
              label='Voucher'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {voucherDropdown.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <Autocomplete
          options={(statusDropdown.data && statusData(statusDropdown.data as never)) || []}
          getOptionLabel={(list) => list.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.id == value.id : false
          }
          onOpen={() => getTokenStatusDropdown()}
          onInputChange={(_, status) => setFilter({ ...filter, status })}
          ListboxProps={GlobalStyle.ListBox}
          value={
            statusDropdown &&
            statusDropdown.data &&
            statusData(statusDropdown.data as never).find((e) => e.id == filter.status)
          }
          renderOption={(props, item) => (
            <li {...props} key={item.id}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='status'
              label='Task Status'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {statusDropdown.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <Autocomplete
          options={(partnerDropdown && partnerDropdown.data) || []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.id == value.id : false
          }
          onOpen={() => getTokenPartnerDropdown()}
          onChange={(_, partner) =>
            setFilter({
              ...filter,
              partner: (partner && String(partner.id)) || ''
            })
          }
          value={
            partnerDropdown &&
            partnerDropdown.data &&
            partnerDropdown.data.find((e) => e.id == Number(filter.partner))
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.id}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='partner'
              label='Partner'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {partnerDropdown.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <Autocomplete
          options={(tokenCategoryDropdown && tokenCategoryDropdown.data) || []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.id == value.id : false
          }
          onOpen={() => getTokenCategoryDropdown()}
          onChange={(_, tokenCategoryId) =>
            setFilter({
              ...filter,
              tokenCategoryId: (tokenCategoryId && String(tokenCategoryId.id)) || ''
            })
          }
          value={
            tokenCategoryDropdown &&
            tokenCategoryDropdown.data &&
            tokenCategoryDropdown.data.find((e) => e.id == Number(filter.tokenCategoryId))
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.id}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='tokenCategoryId'
              label='Token Category'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {tokenCategoryDropdown.isLoading && (
                      <CircularProgress color='inherit' size={20} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
      </Filter>
      {dialogCreate && <Create open={dialogCreate} onClose={() => setDialogCreate(false)} />}

      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />
    </>
  )
}

export default TokenRequestMain
