import { useFormik } from 'formik'
import ReactQuill from 'react-quill'
import * as yup from 'yup'
import { Container, Grid, TextField } from '@mui/material'
import { decode, encode } from 'html-entities'
import Dialog from 'components/dialog/dialog'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import { useGetFaqListDetailQuery, useUpdateFaqListMutation } from 'store/faq'
import type { PartialFaqListDetailDataProps, PartialFaqListDetailProps } from 'modules/partial'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import DialogStyle from './style'

import 'react-quill/dist/quill.snow.css'

const Edit = ({ open, id, onClose }: DetailStateProps & { id: number }) => {
  if (!open) return <></>

  const { data, isFetching, isLoading } = useGetFaqListDetailQuery({ id: id })
  const [updateFaqList, update] = useUpdateFaqListMutation()

  const scheme = yup.object<PartialFaqListDetailDataProps>({
    key: yup.string().required('Category Name is required'),
    data: yup.array().of(
      yup.object({
        language: yup.string().required('Language is required'),
        order: yup
          .number()
          .positive()
          .typeError('Order must be a number')
          .required('Order is required'),
        question: yup.string().required('Question is required'),
        ShortAnswer: yup.string().required('Short Answer is required'),
        AnswerDetails: yup.string()
      })
    )
  })

  const formik = useFormik<PartialFaqListDetailProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      key: (data && data.key) || '',
      data: [
        {
          id: (data && data.data && data.data[1].id) || '',
          language: (data && data.data && data.data[1].language) || '',
          order: (data && data.data && data.data[1].order) || 0,
          question: (data && data.data && data.data[1].question) || '',
          ShortAnswer: (data && data.data && data.data[1].shortAnswer) || '',
          AnswerDetails: (data && data.data && decode(data.data[1].answerDetails)) || '',
          Status: (data && data.data && data.data[0].Status) || ''
        },
        {
          id: (data && data.data && data.data[0].id) || '',
          language: (data && data.data && data.data[0].language) || '',
          order: (data && data.data && data.data[0].order) || 0,
          question: (data && data.data && data.data[0].question) || '',
          ShortAnswer: (data && data.data && data.data[0].shortAnswer) || '',
          AnswerDetails: (data && data.data && decode(data.data[0].answerDetails)) || '',
          Status: (data && data.data && data.data[0].Status) || ''
        }
      ]
    },
    onSubmit: (values: PartialFaqListDetailProps) =>
      updateFaqList({
        ...values,
        data: values.data.map((el) => ({ ...el, AnswerDetails: encode(el.AnswerDetails) }))
      })
  })

  const errorData = formik.errors.data as PartialFaqListDetailDataProps[]

  const loading = (isLoading || isFetching) && !data

  const lang = ['EN-US', 'ID']

  const gridDataLabel = ['Language', 'Order', 'Question', 'Short Answer']
  const gridDataValue = ['language', 'order', 'question', 'ShortAnswer']

  return (
    <>
      <Dialog
        title='Edit Faq List'
        open={open}
        onCancel={onClose}
        loading={isLoading}
        onSubmit={() => formik.handleSubmit()}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          {loading && <Loading />}
          {!loading && (
            <>
              <TextField
                id='key'
                variant='outlined'
                label='Category Name'
                value={formik.values.key}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                inputProps={{ readOnly: true }}
                error={formik.touched && formik.touched.key && Boolean(formik.errors.key)}
                helperText={
                  formik.touched && formik.touched.key && formik.errors && formik.errors.key
                }
                fullWidth
              />
              {lang.map((_, index) => (
                <>
                  <Grid container spacing={2}>
                    {gridDataLabel.map((title, id) => (
                      <Grid key={id} item xs={12}>
                        <TextField
                          variant='outlined'
                          label={title}
                          value={
                            (formik.values.data &&
                              formik.values.data[index][
                                gridDataValue[id] as keyof PartialFaqListDetailDataProps
                              ]) ||
                            '-'
                          }
                          error={
                            formik.touched.data &&
                            formik.touched.data[index] &&
                            formik.touched.data[index][
                              gridDataValue[id] as keyof PartialFaqListDetailDataProps
                            ] &&
                            errorData &&
                            errorData[index] &&
                            Boolean(
                              errorData[index][
                                gridDataValue[id] as keyof PartialFaqListDetailDataProps
                              ]
                            )
                          }
                          helperText={
                            formik.touched.data &&
                            formik.touched.data[index] &&
                            formik.touched.data[index][
                              gridDataValue[id] as keyof PartialFaqListDetailDataProps
                            ] &&
                            errorData &&
                            errorData[index] &&
                            errorData[index][
                              gridDataValue[id] as keyof PartialFaqListDetailDataProps
                            ]
                          }
                          onChange={(e) =>
                            formik.setFieldValue(
                              `data[${index}][${
                                gridDataValue[id] as keyof PartialFaqListDetailDataProps
                              }]`,
                              e.target.value
                            )
                          }
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                    ))}
                  </Grid>
                  <ReactQuill
                    theme='snow'
                    value={formik.values.data && formik.values.data[index].AnswerDetails}
                    onChange={(e) =>
                      formik.setFieldValue(`data[${index}].AnswerDetails`, GLOBAL.emptyQuill(e))
                    }
                  />
                </>
              ))}
            </>
          )}
        </Container>
      </Dialog>

      <Notification
        open={!update.isLoading && !update.isUninitialized}
        onClose={() => (update.isError ? update.reset() : location.reload())}
        isError={Boolean(update.error) && update.isError}
        message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
      />
    </>
  )
}

export default Edit
