import { useState } from 'react'
import { useFormik } from 'formik'
import { isEmpty } from 'lodash'
import * as yup from 'yup'
import { Autocomplete, CircularProgress, Container, TextField } from '@mui/material'
import Dialog from 'components/dialog/dialog'
import type { PartialChargeBoxProps } from 'modules/partial'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import {
  useGetSocketTypeDropdownListMutation,
  useGetStatusChargingDropdownListMutation
} from 'store/dropdown'
import { useGetChargePointDropdownListMutation } from 'store/evChargePoint'
import { useGetChargeBoxGroupDropdownListMutation } from 'store/evChargeBoxGroup'
import { useCreateChargeBoxMutation, useGenerateCodeChargeBoxQuery } from 'store/evChargeBox'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import CONSTANT from 'modules/constant'
import GlobalStyle from 'modules/styles'
import DialogStyle from './style'

const Create = ({ open, onClose }: DetailStateProps) => {
  if (!open) return <></>

  const [createChargeBox, create] = useCreateChargeBoxMutation()

  const [getChargePointDropdownList, chargePointDropdown] = useGetChargePointDropdownListMutation()
  const [getSocketTypeDropdownList, socketTypeDropdown] = useGetSocketTypeDropdownListMutation()
  const [getStatusChargingDropdownList, statusChargingDropdown] =
    useGetStatusChargingDropdownListMutation()
  const [getChargeBoxGroupDropdownList, chargeBoxGroupDropdown] =
    useGetChargeBoxGroupDropdownListMutation()

  const [chargePointId, setChargePointId] = useState<string>('')
  const [chargeBoxGroupId, setChargeBoxGroupId] = useState<string>('')

  const { data: generateCodeChargeBox } = useGenerateCodeChargeBoxQuery()

  const scheme = yup.object<PartialChargeBoxProps>({
    chargeBoxGroupId: yup.number(),
    code: yup.string().required('Code is required'),
    status: yup.string().required('Status is required'),
    uniqueId: yup.string(),
    label: yup.string().required('Charge Box Name is required'),
    info: yup.string(),
    socketType: yup.string().required('Connector Type is required'),
    power: yup
      .number()
      .positive('Charging Capacity must be more than 0')
      .required('Charging Capacity is required'),
    chargeLevel: yup.string().required('Charge Level is required'),
    remark: yup.string()
  })

  const formik = useFormik<PartialChargeBoxProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      chargeBoxGroupId: null,
      code: (generateCodeChargeBox && generateCodeChargeBox.code) || '',
      status: '',
      uniqueId: '',
      label: '',
      info: '',
      socketType: '',
      power: 0,
      chargeLevel: null,
      remark: ''
    },
    onSubmit: (values: PartialChargeBoxProps) =>
      createChargeBox({
        body: { ...values, chargeBoxGroupId: Number(chargeBoxGroupId) },
        id: Number(chargeBoxGroupId)
      })
  })

  const errorData = formik.errors

  return (
    <>
      <Dialog
        title='Create New EV Charge Box'
        open={open}
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={create.isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          <TextField
            id='label'
            variant='outlined'
            label='Charge Box Name'
            value={formik.values.label}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched && formik.touched.label && Boolean(errorData.label)}
            helperText={formik.touched && formik.touched.label && errorData && errorData.label}
            fullWidth
          />
          <Autocomplete
            options={(chargePointDropdown && chargePointDropdown.data) || []}
            getOptionLabel={(option) => option.text}
            isOptionEqualToValue={(option, value) =>
              option && value ? option.value == value.value : false
            }
            onOpen={() => getChargePointDropdownList(CONSTANT.DEFAULT_DROPDOWN_PAYLOAD)}
            onChange={(_, chargePoint) => {
              const point = (chargePoint && String(chargePoint.value)) || ''
              setChargePointId(point)
            }}
            value={
              (chargePointDropdown &&
                chargePointDropdown.data &&
                chargePointDropdown.data.find((e) => String(e.value) == chargePointId)) ||
              undefined
            }
            ListboxProps={GlobalStyle.ListBox}
            renderOption={(props, item) => (
              <li {...props} key={item.value}>
                {item.text}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                name='ChargePointId'
                label='EV Charge Point'
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {chargePointDropdown.isLoading && (
                        <CircularProgress color='inherit' size={20} />
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
          <Autocomplete
            key={chargePointId}
            options={(chargeBoxGroupDropdown && chargeBoxGroupDropdown.data) || []}
            getOptionLabel={(option) => option.text}
            isOptionEqualToValue={(option, value) =>
              option && value ? option.value == value.value : false
            }
            onOpen={() =>
              getChargeBoxGroupDropdownList({
                ...CONSTANT.DEFAULT_DROPDOWN_PAYLOAD,
                id: Number(chargePointId)
              })
            }
            onChange={(_, chargeBoxGroup) => {
              const point = (chargeBoxGroup && String(chargeBoxGroup.value)) || ''

              setChargeBoxGroupId(point)
              formik.setFieldValue('chargeBoxGroupId', point)
            }}
            value={
              (chargeBoxGroupDropdown &&
                chargeBoxGroupDropdown.data &&
                chargeBoxGroupDropdown.data.find((e) => String(e.value) == chargeBoxGroupId)) ||
              undefined
            }
            disabled={isEmpty(chargePointId)}
            ListboxProps={GlobalStyle.ListBox}
            renderOption={(props, item) => (
              <li {...props} key={item.value}>
                {item.text}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                name='ChargeBoxGroupId'
                label='EV Charge Box Group ID'
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {chargeBoxGroupDropdown.isLoading && (
                        <CircularProgress color='inherit' size={20} />
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
          <Autocomplete
            options={(socketTypeDropdown && socketTypeDropdown.data) || []}
            getOptionLabel={(option) => option.text}
            isOptionEqualToValue={(option, value) =>
              option && value ? option.value == value.value : false
            }
            onOpen={() => getSocketTypeDropdownList(CONSTANT.DEFAULT_DROPDOWN_PAYLOAD)}
            onChange={(_, socketType) =>
              socketType && formik.setFieldValue('socketType', String(socketType.value))
            }
            value={
              (socketTypeDropdown &&
                socketTypeDropdown.data &&
                socketTypeDropdown.data.find((e) => String(e.value) == formik.values.socketType)) ||
              undefined
            }
            ListboxProps={GlobalStyle.ListBox}
            renderOption={(props, item) => (
              <li {...props} key={item.value}>
                {item.text}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                name='SocketType'
                label='Connector Type'
                error={formik.touched && formik.touched.socketType && Boolean(errorData.socketType)}
                helperText={
                  formik.touched && formik.touched.socketType && errorData && errorData.socketType
                }
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {socketTypeDropdown.isLoading && (
                        <CircularProgress color='inherit' size={20} />
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
          <TextField
            id='chargeLevel'
            variant='outlined'
            label='Charge Level'
            type='number'
            value={formik.values.chargeLevel}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched && formik.touched.chargeLevel && Boolean(errorData.chargeLevel)}
            helperText={
              formik.touched && formik.touched.chargeLevel && errorData && errorData.chargeLevel
            }
            fullWidth
          />
          <TextField
            id='power'
            variant='outlined'
            label='Charging Capacity'
            type='number'
            value={formik.values.power}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched && formik.touched.power && Boolean(errorData.power)}
            helperText={formik.touched && formik.touched.power && errorData && errorData.power}
            fullWidth
          />
          <Autocomplete
            options={(statusChargingDropdown && statusChargingDropdown.data) || []}
            getOptionLabel={(option) => option.text}
            isOptionEqualToValue={(option, value) =>
              option && value ? option.value == value.value : false
            }
            onOpen={() => getStatusChargingDropdownList(CONSTANT.DEFAULT_DROPDOWN_PAYLOAD)}
            onChange={(_, status) => status && formik.setFieldValue('status', String(status.text))}
            value={
              (statusChargingDropdown &&
                statusChargingDropdown.data &&
                statusChargingDropdown.data.find((e) => String(e.value) == formik.values.status)) ||
              undefined
            }
            ListboxProps={GlobalStyle.ListBox}
            renderOption={(props, item) => (
              <li {...props} key={item.value}>
                {item.text}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                name='status'
                label='Charge Box Status'
                error={formik.touched && formik.touched.status && Boolean(errorData.status)}
                helperText={
                  formik.touched && formik.touched.status && errorData && errorData.status
                }
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {statusChargingDropdown.isLoading && (
                        <CircularProgress color='inherit' size={20} />
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
        </Container>
      </Dialog>

      <Notification
        open={!create.isLoading && !create.isUninitialized}
        onClose={() => (create.isError ? create.reset() : location.reload())}
        isError={Boolean(create.error) && create.isError}
        message={GLOBAL.returnExceptionMessage(create.isError, create.error as ErrorProps)}
      />
    </>
  )
}

export default Create
