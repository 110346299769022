import CONSTANT from 'modules/constant'
import type { EvTransactionDetail, EVTransactionListProps, PaginationProps } from 'modules/types'
import { API, download, headers } from './api'

const EvTransactionAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getEvTransactionOngoing: builder.mutation<PaginationProps<EVTransactionListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_EV_TRANSACTION_ONGOING_LIST,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    downloadEvTransactionOngoing: builder.mutation<
      PaginationProps<EVTransactionListProps>,
      FormData
    >({
      query: (body) => ({
        url: CONSTANT.URL_EV_TRANSACTION_ONGOING_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true,
        responseHandler: download
      })
    }),
    getEvTransactionOngoingDetail: builder.query<EvTransactionDetail, string>({
      query: (id) => ({
        url: CONSTANT.URL_EV_TRANSACTION_ONGOING_DETAIL + id,
        method: 'GET',
        headers: headers()
      })
    }),
    getEvTransactionFinished: builder.mutation<PaginationProps<EVTransactionListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_EV_TRANSACTION_FINISHED_LIST,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    downloadEvTransactionFinished: builder.mutation<
      PaginationProps<EVTransactionListProps>,
      FormData
    >({
      query: (body) => ({
        url: CONSTANT.URL_EV_TRANSACTION_FINISHED_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true,
        responseHandler: download
      })
    }),
    getEvTransactionFinishedDetail: builder.query<EvTransactionDetail, string>({
      query: (id) => ({
        url: CONSTANT.URL_EV_TRANSACTION_FINISHED_DETAIL + id,
        method: 'GET',
        headers: headers()
      })
    })
  })
})

export const {
  useGetEvTransactionOngoingMutation,
  useDownloadEvTransactionOngoingMutation,
  useGetEvTransactionOngoingDetailQuery,
  useGetEvTransactionFinishedMutation,
  useDownloadEvTransactionFinishedMutation,
  useGetEvTransactionFinishedDetailQuery
} = EvTransactionAPI
