import { LoadingButton } from '@mui/lab'
import {
  Button,
  Container,
  Dialog as DialogContainer,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material'
import type { DialogProps, Breakpoint } from '@mui/material'
import type { Children } from 'modules/types'
import ModalStyle from './style'

type FilterProps = DialogProps &
  Children & {
    title?: string
    loading: boolean
    isDisabled?: boolean
    maxWidth?: Breakpoint | false
    submitText?: string
    cancelText?: string
    onCancel?: () => void
    onSubmit?: () => void
  }

const Dialog = ({
  title,
  loading,
  maxWidth,
  isDisabled = false,
  submitText = 'Submit',
  cancelText = 'Cancel',
  children,
  onCancel,
  onSubmit,
  ...props
}: FilterProps) => {
  const properties = props as DialogProps

  return (
    <DialogContainer {...properties} fullWidth maxWidth={maxWidth || 'sm'}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Container {...ModalStyle.Container}>{children}</Container>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{cancelText}</Button>
        <LoadingButton
          disabled={isDisabled}
          loading={loading}
          variant='contained'
          onClick={onSubmit}
        >
          {submitText}
        </LoadingButton>
      </DialogActions>
    </DialogContainer>
  )
}

export default Dialog
