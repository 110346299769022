/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import { isEmpty } from 'lodash'
import * as yup from 'yup'
import { Container, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import Dialog from 'components/dialog/dialog'
import Loading from 'components/loading/loading'
import type { DetailStateProps, ErrorProps, VoucherCategoryListProps } from 'modules/types'
import {
  useGetVoucherCategoryDetailQuery,
  useUpdateVoucherCategoryMutation
} from 'store/voucherCategory'
import type { PayloadVoucherCategoryProps } from 'modules/partial'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import IconMoon from 'components/iconmoon'
import IconMoons from 'assets/selection.json'
import BackdropImage from 'components/backdropImage/backdropImage'
import DialogStyle from './style'

const Edit = ({ open, id, onClose }: DetailStateProps & { id: string | number }) => {
  if (!open) return <></>

  const { data, isFetching, isLoading } = useGetVoucherCategoryDetailQuery(id)
  const [updateVoucherCategory, update] = useUpdateVoucherCategoryMutation()
  const [previewBanner, setPreviewBanner] = useState<string>('')
  const [iconName, setIconName] = useState(data && data.icon)
  const [bannerBackdrop, setBannerBackdrop] = useState<boolean>(false)

  const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    setIconName(newAlignment)
    formik.setFieldValue('icon', newAlignment)
  }

  const scheme = yup.object<PayloadVoucherCategoryProps>({
    image: yup.mixed().required('Image Voucher Category is required'),
    icon: yup.mixed().required('Icon Voucher Category is required'),
    nameEn: yup.string().required('Voucher Category Name English is required'),
    nameId: yup.string().required('Voucher Category Name ID is required'),
    description: yup.string().required('Voucher Category Description is required'),
    seq: yup.number().required('Voucher Category Sequence is required')
  })

  const formik = useFormik<VoucherCategoryListProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      id: (data && data?.id) || '',
      nameEn: (data && data?.nameEn) || '',
      nameId: (data && data?.nameId) || '',
      description: (data && data?.description) || '',
      seq: (data && data?.seq) || '',
      image: (data && data.image) || '',
      icon: (data && data.icon) || '',
      createdAt: '',
      status: ''
    },
    onSubmit: (values: VoucherCategoryListProps) => handleSubmit(values)
  })

  const handleSubmit = (e: VoucherCategoryListProps) => {
    const payload: any = {
      nameEn: e.nameEn,
      nameId: e.nameId,
      Description: e.description,
      Seq: Number(e.seq),
      Id: e.id,
      icon: iconName || e.icon
    }
    const formData = new FormData()
    formData.append('Image', e.image)
    formData.append('data', JSON.stringify(payload))
    updateVoucherCategory(formData)
  }

  return (
    <>
      <Dialog
        open={open}
        title='Update Voucher Category'
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          {(isLoading || isFetching) && !data && <Loading />}
          {data && (
            <>
              <FormikProvider value={formik}>
                <TextField
                  id='nameEn'
                  variant='outlined'
                  label='Voucher Category Name EN-US'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.nameEn}
                  error={formik.touched && formik.touched.nameEn && Boolean(formik.errors.nameEn)}
                  helperText={
                    formik.touched && formik.touched.nameEn && formik.errors && formik.errors.nameEn
                  }
                  fullWidth
                />
                <TextField
                  id='nameId'
                  variant='outlined'
                  label='Voucher Category Name ID'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.nameId}
                  error={formik.touched && formik.touched.nameId && Boolean(formik.errors.nameId)}
                  helperText={
                    formik.touched && formik.touched.nameId && formik.errors && formik.errors.nameId
                  }
                  fullWidth
                />
                <TextField
                  id='description'
                  variant='outlined'
                  label='Description'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                  error={
                    formik.touched &&
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched &&
                    formik.touched.description &&
                    formik.errors &&
                    formik.errors.description
                  }
                  fullWidth
                />
                <TextField
                  id='seq'
                  variant='outlined'
                  label='Sequence'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.seq}
                  error={formik.touched && formik.touched.seq && Boolean(formik.errors.seq)}
                  helperText={
                    formik.touched && formik.touched.seq && formik.errors && formik.errors.seq
                  }
                  fullWidth
                />
                <TextField
                  id='icon'
                  variant='outlined'
                  label='Icon'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched && formik.touched.icon && Boolean(formik.errors.icon)}
                  helperText={
                    formik.touched && formik.touched.icon && formik.errors && formik.errors.icon
                  }
                  sx={{ display: { xs: 'none', sm: 'none' } }}
                />
                <div
                  style={{
                    height: '300px',
                    border: '1px solid #eee',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  {!previewBanner && typeof data.image == 'undefined' && (
                    <Typography style={{ color: '#cfcfcf' }}>
                      Voucher Category Image Cannot be Empty
                    </Typography>
                  )}

                  <BackdropImage
                    open={bannerBackdrop}
                    onOpen={() => setBannerBackdrop(true)}
                    onClose={() => setBannerBackdrop(false)}
                  >
                    <img
                      src={previewBanner || data.image}
                      alt=''
                      style={{ height: '300px', width: '537px', objectFit: 'contain' }}
                    />
                  </BackdropImage>
                </div>
                <TextField
                  id='image'
                  label='Voucher Category Image'
                  type='file'
                  placeholder='Voucher Category Image'
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ accept: 'image/png, image/gif, image/jpeg', shrink: 'true' }}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const files = e && e.target && e.target.files
                    if (files && files.length > 0 && !isEmpty(files)) {
                      formik.setFieldValue('image', files[0])
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      const reader: any = new FileReader()
                      reader.onload = () => {
                        setPreviewBanner(reader.result)
                      }
                      reader.readAsDataURL(files[0])
                    } else {
                      setPreviewBanner('')
                    }
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched && formik.touched.image && Boolean(formik.errors.image)}
                  helperText={
                    formik.touched && formik.touched.image && formik.errors && formik.errors.image
                  }
                  fullWidth
                />
                <div
                  style={{
                    height: '160px',
                    border: '1px solid #eee',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    padding: 8,
                    position: 'relative'
                  }}
                >
                  <div
                    style={{
                      position: 'absolute',
                      top: '-12px',
                      left: '8px',
                      height: '20px',
                      backgroundColor: 'white',
                      textAlign: 'center',
                      zIndex: 100,
                      display: 'flex',
                      flex: 1,
                      fontSize: 12,
                      color: 'gray',
                      padding: 4
                    }}
                  >
                    Voucher Category Icon *
                  </div>
                  <div
                    style={{
                      height: '150px',
                      overflow: 'auto',
                      padding: '8px 8px 8px 8px',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    {IconMoons.icons.map((item, idx) => (
                      <ToggleButtonGroup
                        color='error'
                        value={iconName || formik.values.icon}
                        exclusive
                        onChange={handleChange}
                        aria-label='Platform'
                        key={idx}
                      >
                        <ToggleButton value={item.properties.name}>
                          <IconMoon
                            icon={item.properties.name}
                            size={25}
                            style={{ marginRight: 4 }}
                            color={
                              (iconName || data.icon) == item.properties.name ? 'red' : '#002C5F'
                            }
                          />
                        </ToggleButton>
                      </ToggleButtonGroup>
                    ))}
                  </div>
                </div>
              </FormikProvider>
            </>
          )}
        </Container>
      </Dialog>

      <Notification
        open={!update.isLoading && !update.isUninitialized}
        onClose={() => (update.isError ? update.reset() : location.reload())}
        isError={Boolean(update.error) && update.isError}
        message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
      />
    </>
  )
}

export default Edit
