import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { IconButton, Menu, MenuItem, TableCell, TableRow } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import Content from 'components/content/content'
import CONSTANT from 'modules/constant'
import type { PartialEVChargeProps } from 'modules/partial'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { ErrorProps, ParamProps } from 'modules/types'
import Icon from 'components/icon'
import {
  useDownloadChargePointBoxGroupListMutation,
  useGetChargePointBoxGroupListMutation
} from 'store/evChargePointBoxGroup'
import Loading from 'components/loading/loading'
import Detail from './detail/detail'

const EVChargePointBoxGroup = () => {
  const navigate = useNavigate()
  const params = useParams<ParamProps>()

  const [getChargePointBoxGroup, chargePointBoxGroup] = useGetChargePointBoxGroupListMutation()
  const [downloadChargePointBoxGroup, download] = useDownloadChargePointBoxGroupListMutation()

  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [menu, setMenu] = useState<null | number>(null)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)

  const [payload, setPayload] = useState<PartialEVChargeProps>({
    start: 0,
    length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    search: '',
    filter: '',
    sorting: 'id asc'
  })

  const onResync = () => {
    const formData = new FormData()

    formData.append('start', payload.start.toString())
    formData.append('length', payload.length.toString())
    formData.append('sorting', payload.sorting)

    if (!isEmpty(payload.search)) formData.append('search', payload.search)
    if (!isEmpty(payload.filter)) formData.append('filter', payload.filter)

    getChargePointBoxGroup({ body: formData, id: String(params.id) })
  }

  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('start', String(0))
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())
    formData.append('sorting', payload.sorting)

    if (!isEmpty(payload.search)) formData.append('search', payload.search)
    if (!isEmpty(payload.filter)) formData.append('filter', payload.filter)
    if (type && !isEmpty(type)) formData.append('Content-Type', type)

    downloadChargePointBoxGroup({ body: formData, id: String(params.id) })
  }

  const onSearch = (value: string) => setPayload({ ...payload, start: 0, search: value })
  const onChangePage = (value: number) => setPayload({ ...payload, start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, length: value })

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const tableHeadTitles = ['ID', 'EV Charge Point', 'Online', 'Charger Brand', 'Connector Amount']

  const openAnchor = Boolean(anchor)

  const totalPage =
    chargePointBoxGroup.data && GLOBAL.tableTotalPage(chargePointBoxGroup.data.recordsTotal)

  const numbers = GLOBAL.tableNumber(payload.start)

  useEffect(() => {
    onResync()
  }, [payload])

  return (
    <>
      <Content
        title='EV Charge Point Box Group'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={chargePointBoxGroup.data && chargePointBoxGroup.data.recordsTotal}
        page={payload.start + 1}
        isLoading={chargePointBoxGroup.isLoading}
        useDownloadDropdown
        useBackButton
      >
        {chargePointBoxGroup.isSuccess &&
          chargePointBoxGroup.data &&
          chargePointBoxGroup.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.id}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.id}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.id}`}
                  aria-labelledby={`button-${index}-${table.id}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View
                  </MenuItem>
                  <MenuItem onClick={() => onRemoveAnchor(() => navigate(String(table.id)))}>
                    View Box
                  </MenuItem>
                </Menu>
                <Detail
                  id={table.id}
                  open={drawerDetail && menu == index}
                  onClose={() => setDrawerDetail(false)}
                />
              </TableCell>
              <TableCell>{table.code || '-'}</TableCell>
              <TableCell>{table.chargePointName || '-'}</TableCell>
              <TableCell>{table.online.toString()}</TableCell>
              <TableCell>{table.chargerBrand || '-'}</TableCell>
              <TableCell>{table.connectorAmount || '-'}</TableCell>
            </TableRow>
          ))}
      </Content>

      {chargePointBoxGroup.isLoading && <Loading />}

      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />
    </>
  )
}

export default EVChargePointBoxGroup
