import { isEmpty } from 'lodash'
import { Link } from 'react-router-dom'
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs'
import { Button, Container, Stack } from '@mui/material'
import type { SidebarMenuProps } from 'modules/types'
import Tabs from 'components/tabs/tabs'
import MenuStyle from './style'

const Menu = ({ menu }: { menu: SidebarMenuProps[] }) => {
  const breadcrumbs = useReactRouterBreadcrumbs()
  const current = menu.find((list) => breadcrumbs.some((bread) => list.path == bread.key))

  return (
    <>
      <Container {...MenuStyle.Container}>
        <Stack {...MenuStyle.Wrapper}>
          {menu.map((link, index) =>
            current && current.path == link.path ? (
              <Button key={index} variant='text' sx={{ borderBottom: 2, borderRadius: 0 }}>
                {link.name}
              </Button>
            ) : (
              <Link key={index} {...MenuStyle.Link} to={link.path}>
                <Button key={index} variant='text'>
                  {link.name}
                </Button>
              </Link>
            )
          )}
        </Stack>
      </Container>
      {!isEmpty(current) && !isEmpty(current.menu) && (
        <Tabs key={current.path} tabs={current.menu} />
      )}
    </>
  )
}

export default Menu
