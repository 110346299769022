import CONSTANT from 'modules/constant'
import type {
  DropdownListProps,
  EvChargeBoxProps,
  GenerateCodeProps,
  PaginationProps,
  ResultProps
} from 'modules/types'
import type { PartialChargeBoxProps, PartialDropdownListProps } from 'modules/partial'
import { API, download, headers } from './api'

const EvChargeBoxAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getChargeBoxList: builder.mutation<PaginationProps<EvChargeBoxProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_EV_CHARGE_BOX_LIST,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    resyncChargeBoxList: builder.mutation<ResultProps, void>({
      query: () => ({
        url: CONSTANT.URL_EV_CHARGE_BOX_RESYNC,
        method: 'POST',
        headers: headers()
      })
    }),
    downloadChargeBoxList: builder.mutation<PaginationProps<EvChargeBoxProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_EV_CHARGE_BOX_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true,
        responseHandler: download
      })
    }),
    getChargeBoxDropdownList: builder.mutation<DropdownListProps[], PartialDropdownListProps>({
      query: (query) => ({
        url:
          CONSTANT.URL_EV_CHARGE_BOX_DROPDOWN +
          `${query.id}/ddl` +
          `?start=${query.start}&length=${query.length}`,
        method: 'GET',
        headers: headers()
      })
    }),
    getChargeBoxDetail: builder.query<EvChargeBoxProps, number>({
      query: (id) => ({
        url: CONSTANT.URL_EV_CHARGE_BOX_DETAIL + id,
        method: 'GET',
        headers: headers()
      })
    }),
    createChargeBox: builder.mutation<ResultProps, { body: PartialChargeBoxProps; id: number }>({
      query: (query) => ({
        url: CONSTANT.URL_EV_CHARGE_BOX_CREATE + query.id,
        method: 'POST',
        headers: headers(),
        body: query.body
      })
    }),
    updateChargeBox: builder.mutation<ResultProps, { body: PartialChargeBoxProps; id: number }>({
      query: (query) => ({
        url: CONSTANT.URL_EV_CHARGE_BOX_UPDATE + query.id,
        method: 'PUT',
        headers: headers(),
        body: query.body
      })
    }),
    deleteChargeBox: builder.mutation<ResultProps, number>({
      query: (id) => ({
        url: CONSTANT.URL_EV_CHARGE_BOX_DELETE + id,
        method: 'DELETE',
        headers: headers()
      })
    }),
    generateCodeChargeBox: builder.query<GenerateCodeProps, void>({
      query: () => ({
        url: CONSTANT.URL_EV_CHARGE_BOX_CODE,
        method: 'GET',
        headers: headers()
      })
    })
  })
})

export const {
  useGetChargeBoxListMutation,
  useResyncChargeBoxListMutation,
  useDownloadChargeBoxListMutation,
  useGetChargeBoxDropdownListMutation,
  useCreateChargeBoxMutation,
  useGetChargeBoxDetailQuery,
  useUpdateChargeBoxMutation,
  useDeleteChargeBoxMutation,
  useGenerateCodeChargeBoxQuery
} = EvChargeBoxAPI
