/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react'
import { useFormik, FormikProvider } from 'formik'
import { isEmpty } from 'lodash'
import {
  Autocomplete,
  Box,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography
} from '@mui/material'
import * as yup from 'yup'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import type { Dayjs } from 'dayjs'
import ReactQuill from 'react-quill'
import { decode, encode } from 'html-entities'
import Dialog from 'components/dialog/dialog'
import GLOBAL from 'modules/global'
import type { PayloadVoucherBenefitsProps } from 'modules/partial'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import GlobalStyle from 'modules/styles'
import {
  useCreateVoucherBenefitsMutation,
  useGetBenefitsCategoryDropdownMutation,
  useGetContentVoucherMutation,
  useGetVoucherBenefitsDropdownMutation,
  useGetVoucherMemberDropdownMutation
} from 'store/voucherBenefits'
import { useGetTokenPartnerDropdownMutation } from 'store/token'
import Notification from 'components/notification'
import BackdropImage from 'components/backdropImage/backdropImage'
import DialogStyle from './style'

const Create = ({ open, onClose }: DetailStateProps) => {
  const [createVoucherBenefits, create] = useCreateVoucherBenefitsMutation()
  const [getVoucherBenefitsDropdown, voucherDropdown] = useGetVoucherBenefitsDropdownMutation()
  const [getVoucherMemberDropdown, voucherMemberId] = useGetVoucherMemberDropdownMutation()
  const [getBenefitsCategoryDropdown, benefitsCategory] = useGetBenefitsCategoryDropdownMutation()
  const [getContentVoucher, contentVoucher] = useGetContentVoucherMutation()
  const [getTokenPartnerDropdown, partnerDropdown] = useGetTokenPartnerDropdownMutation()
  const [startDate, setStartDate] = useState<Dayjs | null>(null)
  const [endDate, setEndDate] = useState<Dayjs | null>(null)
  const [previewBanner, setPreviewBanner] = useState<string>('')
  const [isChecked, setChecked] = useState<boolean>(false)
  const [previewImage, setPreviewImage] = useState<string>('')
  const [previewBackdrop, setPreviewBackdrop] = useState<boolean>(false)
  const [bannerBackdrop, setBannerBackdrop] = useState<boolean>(false)

  const scheme = yup.object<PayloadVoucherBenefitsProps>({
    CampaignNameID: yup.string().required('Campaign Name ID is required'),
    CampaignNameEN: yup.string().required('Campaign Name EN-US is required'),
    VoucherId: yup.string().required('Voucher Name is required'),
    BenefitsId: yup.string().required('Benefits Category is required'),
    BenefitsName: yup.string(),
    UserType: yup.string().when('BenefitsName', {
      is: (val: string) => val && val.toLocaleLowerCase().includes('benefit'),
      then: () => yup.array().min(1).required('User Type is required'),
      otherwise: () => yup.array().notRequired()
    }),
    Coins: yup.string().when('BenefitsName', {
      is: (val: string) => val && val.toLocaleLowerCase().includes('redemption'),
      then: () => yup.number().required('Coins Amount is required'),
      otherwise: () => yup.number().notRequired()
    }),

    MaxPurchase: yup.number().min(1).required('Max Purchase Per User is required'),
    MinCoins: yup.number().required('Min Coins Achievement is required'),
    MaxCoins: yup.number().required('Max Coins Achievement is required'),
    ValidFrom: yup.string().required('Valid From is required'),
    ValidUntil: yup.string().required('Valid Until is required'),
    PartnerId: yup.string().required('Partner is required'),
    UsageLimit: yup.number().required('Usage Limit is required'),
    DescriptionId: yup.string().required('Description ID is required'),
    DescriptionEn: yup.string().required('Description EN-US is required'),
    TnCID: yup.string().required('Terms and Condition ID is required'),
    TnCEN: yup.string().required('Terms and Condition EN-US is required'),
    image: isChecked
      ? yup.string().notRequired()
      : yup.string().required('Image Voucher is required'),
    banner: isChecked
      ? yup.string().notRequired()
      : yup.string().required('Banner Voucher is required')
  })

  const formik = useFormik<PayloadVoucherBenefitsProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      CampaignNameID: '',
      CampaignNameEN: '',
      VoucherId: '',
      BenefitsId: '',
      UserType: [],
      Coins: 0,
      MaxPurchase: '',
      MinCoins: '',
      MaxCoins: '',
      ValidFrom: '',
      ValidUntil: '',
      PartnerId: '',
      UsageLimit: '',
      VoucherTnCId: '',
      DescriptionId: '',
      DescriptionEn: '',
      TnCID: '',
      TnCEN: '',
      image: '',
      banner: '',
      UseContent: false,
      BenefitsName: ''
    },
    onSubmit: (values: PayloadVoucherBenefitsProps) => handleSubmit(values)
  })

  const handleSubmit = (e: PayloadVoucherBenefitsProps) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const payload: any = {
      voucherCampaignNameIND: e.CampaignNameID,
      voucherCampaignNameENG: e.CampaignNameEN,
      voucherid: e.VoucherId,
      benefitCategoryid: e.BenefitsId,
      memberTier: e.UserType,
      coins: e.Coins,
      maxPurchaseperUser: e.MaxPurchase,
      minCoinsAchievement: e.MinCoins,
      maxCoinsAchievement: e.MinCoins, // e.MaxCoins
      validFrom: dayjs(e.ValidFrom).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      validUntil: dayjs(e.ValidUntil).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      usageLimit: e.UsageLimit,
      Partner: e.PartnerId,
      deepLink: null,
      UseContentFromVoucher: isChecked,
      vouchertncid: isChecked ? contentVoucher.data?.voucherTnCId : null,
      descriptionEn: encode(e.DescriptionEn),
      descriptionId: encode(e.DescriptionId),
      termsAndConditionsEn: encode(e.TnCEN),
      termsAndConditionsId: encode(e.TnCID)
    }

    const formData = new FormData()

    formData.append('Image', e.image)
    formData.append('Banner', e.banner)
    formData.append('data', JSON.stringify(payload))

    createVoucherBenefits(formData)
  }

  const handleChangeStartDate = (date: Dayjs | null) => {
    setStartDate(date)
    const newDate = (!isEmpty(date) && date.format('YYYY-MM-DD')) || ''
    formik.setFieldValue('ValidFrom', newDate)
  }

  const handleChangeEndDate = (date: Dayjs | null) => {
    setEndDate(date)
    const newDate = (!isEmpty(date) && date.format('YYYY-MM-DD')) || ''
    formik.setFieldValue('ValidUntil', newDate)
  }

  const handleUserType = (val: any[]) => {
    const temp: any = []
    val.map((item) => {
      temp.push({ memberId: item.id, memberName: item.name })
    })
    formik.setFieldValue('UserType', temp)
  }

  const setUseContent = () => {
    if (contentVoucher && contentVoucher.data && contentVoucher.data?.details) {
      formik.setFieldValue(
        'DescriptionId',
        !isChecked
          ? decode(contentVoucher.data?.details.filter((x) => x.language === 'ID')[0].description)
          : ''
      )
      formik.setFieldValue(
        'DescriptionEn',
        !isChecked
          ? decode(
              contentVoucher.data?.details.filter((x) => x.language === 'en-US')[0].description
            )
          : ''
      )
      formik.setFieldValue(
        'TnCID',
        !isChecked
          ? decode(
              contentVoucher.data?.details.filter((x) => x.language === 'ID')[0].termsAndCondition
            )
          : ''
      )
      formik.setFieldValue(
        'TnCEN',
        !isChecked
          ? decode(
              contentVoucher.data?.details.filter((x) => x.language === 'en-US')[0]
                .termsAndCondition
            )
          : ''
      )
      formik.setFieldValue('image', !isChecked ? contentVoucher.data?.image : '')
      formik.setFieldValue('banner', !isChecked ? contentVoucher.data?.banner : '')
      formik.setFieldValue('VoucherTnCId', !isChecked ? contentVoucher.data?.voucherTnCId : null)
    }
  }

  return (
    <>
      <Dialog
        open={open}
        title='Add Voucher Benefits'
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={create.isLoading}
        isDisabled={!formik.isValid}
        maxWidth='md'
      >
        <Container {...DialogStyle.Container}>
          <FormikProvider value={formik}>
            <Grid container spacing={2} columns={16}>
              <Grid xs={8} marginTop={1}>
                <Grid xs={16} margin={1}>
                  <TextField
                    id='CampaignNameID'
                    variant='outlined'
                    label='Campaign Name ID'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched &&
                      formik.touched.CampaignNameID &&
                      Boolean(formik.errors.CampaignNameID)
                    }
                    helperText={
                      formik.touched &&
                      formik.touched.CampaignNameID &&
                      formik.errors &&
                      formik.errors.CampaignNameID
                    }
                    fullWidth
                    value={formik.values.CampaignNameID}
                    required
                  />
                </Grid>
                <Grid xs={16} margin={1}>
                  <TextField
                    id='CampaignNameEN'
                    variant='outlined'
                    label='Campaign Name EN-US'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched &&
                      formik.touched.CampaignNameEN &&
                      Boolean(formik.errors.CampaignNameEN)
                    }
                    helperText={
                      formik.touched &&
                      formik.touched.CampaignNameEN &&
                      formik.errors &&
                      formik.errors.CampaignNameEN
                    }
                    fullWidth
                    value={formik.values.CampaignNameEN}
                    required
                  />
                </Grid>
                <Grid xs={16} margin={1}>
                  <Autocomplete
                    options={partnerDropdown.data || []}
                    getOptionLabel={(list) => list.name}
                    isOptionEqualToValue={(option, value) =>
                      option && value ? option.id == value.id : false
                    }
                    onOpen={() => getTokenPartnerDropdown()}
                    onChange={(_, id) => {
                      formik.setFieldValue('PartnerId', id && id.id)
                    }}
                    ListboxProps={GlobalStyle.ListBox}
                    renderOption={(props, item) => (
                      <li {...props} key={item.id}>
                        {item.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name='PartnerId'
                        label='Partner'
                        required
                        error={
                          formik.touched &&
                          formik.touched.PartnerId &&
                          Boolean(formik.errors.PartnerId)
                        }
                        helperText={
                          formik.touched &&
                          formik.touched.PartnerId &&
                          formik.errors &&
                          formik.errors.PartnerId
                        }
                        value={formik.values.PartnerId}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {partnerDropdown.isLoading && (
                                <CircularProgress color='inherit' size={20} />
                              )}
                              {params.InputProps.endAdornment}
                            </>
                          )
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid xs={16} margin={1}>
                  <Autocomplete
                    key={formik.values.PartnerId}
                    options={voucherDropdown.data || []}
                    getOptionLabel={(list) => list.voucherName}
                    isOptionEqualToValue={(option, value) =>
                      option && value ? option.id == value.id : false
                    }
                    onOpen={() =>
                      getVoucherBenefitsDropdown(formik.values && formik.values.PartnerId)
                    }
                    onChange={(_, id) => {
                      formik.setFieldValue('VoucherId', id && id.id)
                      getContentVoucher((id && id.id) || '')
                    }}
                    ListboxProps={GlobalStyle.ListBox}
                    renderOption={(props, item) => (
                      <li {...props} key={item.id}>
                        {item.voucherName}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name='VoucherId'
                        label='Voucher Name'
                        value={formik.values.VoucherId}
                        required
                        error={
                          formik.touched &&
                          formik.touched.VoucherId &&
                          Boolean(formik.errors.VoucherId)
                        }
                        helperText={
                          formik.touched &&
                          formik.touched.VoucherId &&
                          formik.errors &&
                          formik.errors.VoucherId
                        }
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {voucherDropdown.isLoading && (
                                <CircularProgress color='inherit' size={20} />
                              )}
                              {params.InputProps.endAdornment}
                            </>
                          )
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid xs={16} margin={1}>
                  <Autocomplete
                    options={benefitsCategory.data || []}
                    getOptionLabel={(list) => list.name}
                    isOptionEqualToValue={(option, value) =>
                      option && value ? option.id == value.id : false
                    }
                    onOpen={() => getBenefitsCategoryDropdown()}
                    onChange={(_, id) => {
                      formik.setFieldValue('BenefitsId', id && id.id)
                      formik.setFieldValue('BenefitsName', id && id.name)
                      formik.setFieldValue('Coins', 0)
                      formik.setFieldValue('UserType', [])
                    }}
                    ListboxProps={GlobalStyle.ListBox}
                    renderOption={(props, item) => (
                      <li {...props} key={item.id}>
                        {item.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name='BenefitsId'
                        label='Benefits Category'
                        value={formik.values.BenefitsId}
                        required
                        error={
                          formik.touched &&
                          formik.touched.BenefitsId &&
                          Boolean(formik.errors.BenefitsId)
                        }
                        helperText={
                          formik.touched &&
                          formik.touched.BenefitsId &&
                          formik.errors &&
                          formik.errors.BenefitsId
                        }
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {benefitsCategory.isLoading && (
                                <CircularProgress color='inherit' size={20} />
                              )}
                              {params.InputProps.endAdornment}
                            </>
                          )
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  xs={16}
                  margin={1}
                  display={
                    formik.values.BenefitsName &&
                    formik.values.BenefitsName.toLocaleLowerCase().includes('redemption')
                      ? 'none'
                      : ''
                  }
                >
                  <Autocomplete
                    multiple
                    key={formik.values.BenefitsId}
                    options={voucherMemberId.data || []}
                    getOptionLabel={(list) => list.name}
                    isOptionEqualToValue={(option, value) =>
                      option && value ? option.id == value.id : false
                    }
                    onOpen={() => getVoucherMemberDropdown()}
                    onChange={(_, data) => handleUserType(data)}
                    ListboxProps={GlobalStyle.ListBox}
                    renderOption={(props, item) => (
                      <li {...props} key={item.id}>
                        {item.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name='UserType'
                        label='User Type'
                        value={formik.values.UserType}
                        required
                        error={
                          formik.touched &&
                          formik.touched.UserType &&
                          Boolean(formik.errors.UserType)
                        }
                        helperText={
                          formik.touched &&
                          formik.touched.UserType &&
                          formik.errors &&
                          formik.errors.UserType
                        }
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {voucherMemberId.isLoading && (
                                <CircularProgress color='inherit' size={20} />
                              )}
                              {params.InputProps.endAdornment}
                            </>
                          )
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  xs={16}
                  margin={1}
                  display={
                    formik.values.BenefitsName &&
                    formik.values.BenefitsName.toLocaleLowerCase().includes('benefit')
                      ? 'none'
                      : ''
                  }
                >
                  <TextField
                    id='Coins'
                    variant='outlined'
                    label='Coins Amount'
                    value={formik.values.Coins && formik.values.Coins.toString()}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched && formik.touched.Coins && Boolean(formik.errors.Coins)}
                    helperText={
                      formik.touched && formik.touched.Coins && formik.errors && formik.errors.Coins
                    }
                    type='number'
                    fullWidth
                    inputProps={{ min: 0 }}
                    onKeyDown={(event) => {
                      if (/^[.,\-\b]+$/.test(event.key)) event.preventDefault()
                    }}
                    required
                  />
                </Grid>
                <Grid xs={16} margin={1}>
                  <TextField
                    id='MaxPurchase'
                    variant='outlined'
                    label='Max Purchase Per User'
                    value={formik.values.MaxPurchase}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched &&
                      formik.touched.MaxPurchase &&
                      Boolean(formik.errors.MaxPurchase)
                    }
                    helperText={
                      formik.touched &&
                      formik.touched.MaxPurchase &&
                      formik.errors &&
                      formik.errors.MaxPurchase
                    }
                    type='number'
                    fullWidth
                    inputProps={{ min: 1 }}
                    required
                  />
                </Grid>
                <Grid xs={16} margin={1}>
                  <TextField
                    id='MinCoins'
                    variant='outlined'
                    label='Min Coins Achievement'
                    value={formik.values.MinCoins}
                    onChange={(e) => {
                      formik.setFieldValue('MinCoins', e.target.value)
                      formik.setFieldValue('MaxCoins', e.target.value)
                    }}
                    onBlur={(e) => {
                      formik.setFieldValue('MinCoins', e.target.value)
                      formik.setFieldValue('MaxCoins', e.target.value)
                    }}
                    error={
                      formik.touched && formik.touched.MinCoins && Boolean(formik.errors.MinCoins)
                    }
                    helperText={
                      formik.touched &&
                      formik.touched.MinCoins &&
                      formik.errors &&
                      formik.errors.MinCoins
                    }
                    type='number'
                    fullWidth
                    inputProps={{ min: 0 }}
                    required
                  />
                </Grid>
                {/* <Grid xs={16} margin={1}>
                  <TextField
                    id='MaxCoins'
                    variant='outlined'
                    label='Max Coins Achievement'
                    value={formik.values.MaxCoins}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched && formik.touched.MaxCoins && Boolean(formik.errors.MaxCoins)
                    }
                    helperText={
                      formik.touched &&
                      formik.touched.MaxCoins &&
                      formik.errors &&
                      formik.errors.MaxCoins
                    }
                    type='number'
                    fullWidth
                    inputProps={{ min: 0 }}
                    required
                  />
                </Grid> */}
                <Grid xs={16} margin={1}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                    <DatePicker
                      value={startDate}
                      onChange={handleChangeStartDate}
                      label='Valid From *'
                      format='DD-MM-YYYY'
                      sx={{ width: '100%' }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid xs={16} margin={1}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                    <DatePicker
                      value={endDate}
                      minDate={dayjs(startDate)}
                      onChange={handleChangeEndDate}
                      label='Valid Until *'
                      format='DD-MM-YYYY'
                      sx={{ width: '100%' }}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid xs={16} margin={1}>
                  <TextField
                    id='UsageLimit'
                    variant='outlined'
                    label='Usage Limit'
                    value={formik.values.UsageLimit}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched &&
                      formik.touched.UsageLimit &&
                      Boolean(formik.errors.UsageLimit)
                    }
                    helperText={
                      formik.touched &&
                      formik.touched.UsageLimit &&
                      formik.errors &&
                      formik.errors.UsageLimit
                    }
                    type='number'
                    fullWidth
                    inputProps={{ min: 0 }}
                    required
                  />
                </Grid>
              </Grid>
              {/* Right Column */}
              <Grid xs={8}>
                <Grid xs={16} margin={1}>
                  <FormControlLabel
                    id='UseContent'
                    control={
                      <Checkbox
                        required
                        id='UseContent'
                        name='UseContent'
                        value={isChecked}
                        onChange={() => {
                          setChecked(!isChecked)
                          setUseContent()
                        }}
                        onBlur={formik.handleBlur}
                      />
                    }
                    label='Use Content from Voucher'
                  />
                </Grid>
                <Grid xs={16} margin={1}>
                  <Typography variant='button' gutterBottom>
                    Description - ID
                  </Typography>
                  <ReactQuill
                    theme='snow'
                    id='DescriptionId'
                    onChange={(e) => formik.setFieldValue('DescriptionId', GLOBAL.emptyQuill(e))}
                    value={formik.values.DescriptionId}
                    readOnly={isChecked}
                  />
                </Grid>
                <Grid xs={16} margin={1}>
                  <Typography variant='button' gutterBottom>
                    Description - EN-US
                  </Typography>
                  <ReactQuill
                    theme='snow'
                    id='DescriptionEn'
                    onChange={(e) => formik.setFieldValue('DescriptionEn', GLOBAL.emptyQuill(e))}
                    value={formik.values.DescriptionEn}
                    readOnly={isChecked}
                  />
                </Grid>
                <Grid xs={16} margin={1}>
                  <Typography variant='button' gutterBottom>
                    Terms and Condition - ID
                  </Typography>
                  <ReactQuill
                    theme='snow'
                    id='TnCID'
                    onChange={(e) => formik.setFieldValue('TnCID', GLOBAL.emptyQuill(e))}
                    value={formik.values.TnCID}
                    readOnly={isChecked}
                  />
                </Grid>
                <Grid xs={16} margin={1}>
                  <Typography variant='button' gutterBottom>
                    Terms and Condition - EN-US
                  </Typography>
                  <ReactQuill
                    theme='snow'
                    id='TnCEN'
                    onChange={(e) => formik.setFieldValue('TnCEN', GLOBAL.emptyQuill(e))}
                    value={formik.values.TnCEN}
                    readOnly={isChecked}
                  />
                </Grid>
                <Grid xs={16} margin={1}>
                  <Box
                    sx={{
                      height: '300px',
                      border: '1px solid #eee',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    {!previewImage && !isChecked && (
                      <Typography style={{ color: '#cfcfcf' }}>
                        Voucher Image Cannot be Empty
                      </Typography>
                    )}
                    {contentVoucher.data?.image && isChecked && (
                      <BackdropImage
                        open={previewBackdrop}
                        onOpen={() => setPreviewBackdrop(true)}
                        onClose={() => setPreviewBackdrop(false)}
                      >
                        <img
                          src={contentVoucher.data?.image}
                          alt=''
                          style={{ height: '300px', width: '400px', objectFit: 'contain' }}
                        />
                      </BackdropImage>
                    )}
                    {previewImage && !isChecked && (
                      <BackdropImage
                        open={previewBackdrop}
                        onOpen={() => setPreviewBackdrop(true)}
                        onClose={() => setPreviewBackdrop(false)}
                      >
                        <img
                          src={previewImage}
                          alt=''
                          style={{ height: '300px', width: '400px', objectFit: 'contain' }}
                        />
                      </BackdropImage>
                    )}
                  </Box>
                  <TextField
                    required
                    id='image'
                    label='Voucher Image'
                    type='file'
                    placeholder='Voucher Image'
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ accept: 'image/png, image/gif, image/jpeg', shrink: 'true' }}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const files = e && e.target && e.target.files
                      if (files && files.length > 0 && !isEmpty(files)) {
                        formik.setFieldValue('image', files[0])
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const reader: any = new FileReader()
                        reader.onload = () => {
                          setPreviewImage(reader.result)
                        }
                        reader.readAsDataURL(files[0])
                      } else {
                        setPreviewImage('')
                      }
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched && formik.touched.image && Boolean(formik.errors.image)}
                    helperText={
                      formik.touched && formik.touched.image && formik.errors && formik.errors.image
                    }
                    fullWidth
                    disabled={isChecked}
                  />
                </Grid>
                <Grid xs={16} margin={1}>
                  <Box
                    sx={{
                      height: '300px',
                      border: '1px solid #eee',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    {!previewBanner && !isChecked && (
                      <Typography style={{ color: '#cfcfcf' }}>
                        Voucher Banner Cannot be Empty
                      </Typography>
                    )}
                    {contentVoucher.data?.banner && isChecked && (
                      <BackdropImage
                        open={bannerBackdrop}
                        onOpen={() => setBannerBackdrop(true)}
                        onClose={() => setBannerBackdrop(false)}
                      >
                        <img
                          src={contentVoucher.data?.banner}
                          alt=''
                          style={{ height: '300px', width: '400px', objectFit: 'contain' }}
                        />
                      </BackdropImage>
                    )}
                    {previewBanner && !isChecked && (
                      <BackdropImage
                        open={bannerBackdrop}
                        onOpen={() => setBannerBackdrop(true)}
                        onClose={() => setBannerBackdrop(false)}
                      >
                        <img
                          src={previewBanner}
                          alt=''
                          style={{ height: '300px', width: '400px', objectFit: 'contain' }}
                        />
                      </BackdropImage>
                    )}
                  </Box>
                  <TextField
                    required
                    id='banner'
                    label='Voucher Banner'
                    type='file'
                    placeholder='Voucher Banner'
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ accept: 'image/png, image/gif, image/jpeg', shrink: 'true' }}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const files = e && e.target && e.target.files
                      if (files && files.length > 0 && !isEmpty(files)) {
                        formik.setFieldValue('banner', files[0])
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const reader: any = new FileReader()
                        reader.onload = () => {
                          setPreviewBanner(reader.result)
                        }
                        reader.readAsDataURL(files[0])
                      } else {
                        setPreviewBanner('')
                      }
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched && formik.touched.banner && Boolean(formik.errors.banner)}
                    helperText={
                      formik.touched &&
                      formik.touched.banner &&
                      formik.errors &&
                      formik.errors.banner
                    }
                    fullWidth
                    disabled={isChecked}
                  />
                </Grid>
              </Grid>
            </Grid>
          </FormikProvider>
        </Container>
      </Dialog>

      <Notification
        open={!create.isLoading && !create.isUninitialized}
        onClose={() => (create.isError ? create.reset() : location.reload())}
        isError={Boolean(create.error) && create.isError}
        message={GLOBAL.returnExceptionMessage(create.isError, create.error as ErrorProps)}
      />
    </>
  )
}

export default Create
