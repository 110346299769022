import {
  Card,
  CardHeader,
  CardMedia,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@mui/material'
import dayjs from 'dayjs'
import Drawer from 'components/drawer/detail'
import Loading from 'components/loading/loading'
import type { DetailStateProps } from 'modules/types'
import { useGetVoucherPackageDetailQuery } from 'store/voucherPackage'
import DrawerStyle from './style'

const Detail = ({ open, id, onClose }: DetailStateProps & { id: string | number }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const { data, isFetching, isLoading } = useGetVoucherPackageDetailQuery(id)

  const arrDataLeft = [
    { value: 'packageNameIND', label: 'Package Name ID' },
    { value: 'packageNameENG', label: 'Package Name EN-US' },
    { value: 'packageCategoryName', label: 'Package Category' },
    { value: 'carModel', label: 'Car Model' },
    { value: 'periodFrom', label: 'Handover Period From', type: 'date' },
    { value: 'periodUntil', label: 'Handover Period Until', type: 'date' },
    { value: 'bestChoice', label: 'Best Choice' }
  ]

  const arrDataRight = [
    { value: 'shortDescIND', label: 'Short Description ID' },
    { value: 'shortDescENG', label: 'Short Description EN-US' },
    { value: 'voucherList', label: 'Voucher List', type: 'arr' },
    { value: 'image', label: 'Voucher Image', type: 'image' }
  ]

  return (
    <>
      <Drawer open={open} title='Detail Voucher Package' onClose={onClose}>
        <Container {...DrawerStyle.Container}>
          {(isLoading || isFetching) && !data && <Loading />}
          {data && (
            <>
              <Grid container spacing={2} columns={16}>
                {arrDataLeft.map((item, index) => (
                  <Grid item xs={8} key={index}>
                    <Grid xs={16} key={index}>
                      <TextField
                        key={index}
                        variant='outlined'
                        label={item.label}
                        value={
                          data && item?.type === 'date'
                            ? dayjs(data[item.value as never]).format('DD MMMM YYYY HH:mm')
                            : data[item.value as never]
                        }
                        inputProps={{ readOnly: true }}
                      />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              {arrDataRight.map((item, index) => {
                if (item.type === 'arr') {
                  return (
                    <Grid xs={16} key={index}>
                      <TableContainer
                        component={Paper}
                        sx={{ maxHeight: 512, marginTop: '10px' }}
                        variant='outlined'
                      >
                        <Table aria-label='Data Table' stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell>Voucher List</TableCell>
                              <TableCell>Sub Category</TableCell>
                              <TableCell>Qty</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data &&
                              data.voucherList?.map((item) => (
                                <TableRow key={item.id}>
                                  <TableCell>{item.nameVoucher}</TableCell>
                                  <TableCell>{item.subVoucherCategoryName}</TableCell>
                                  <TableCell>{item.quantity}</TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  )
                }
                if (item.type === 'image') {
                  return (
                    <Grid xs={16} key={index} marginTop={1}>
                      <Card key={index}>
                        <CardHeader subheader={item.label} />
                        <CardMedia
                          component='img'
                          height={240}
                          image={data[item.value as never]}
                          alt={item.label}
                          sx={{ objectFit: 'contain' }}
                        />
                      </Card>
                    </Grid>
                  )
                }

                return (
                  <Grid xs={16} key={index}>
                    <TextField
                      key={index}
                      variant='outlined'
                      label={item.label}
                      value={data[item.value as never]}
                      inputProps={{ readOnly: true }}
                      fullWidth
                    />
                  </Grid>
                )
              })}
            </>
          )}
        </Container>
      </Drawer>
    </>
  )
}

export default Detail
