import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Typography
} from '@mui/material'
import dayjs from 'dayjs'
import { useLocation } from 'react-router-dom'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import Icon from 'components/icon'
import MenuModalItem from 'components/menuItem/menuItem'
import GlobalStyle from 'modules/styles'
import type { ErrorProps, FilterDatePromoCodeProps } from 'modules/types'
import type { PartialPromoCodeBatchProps, PartialPromoCodeSubProps } from 'modules/partial'
import CONSTANT from 'modules/constant'
import {
  useDeleteMultiplesPromoCodeSubMutation,
  useDownloadPromoCodeBatchSubMutation,
  useGetPromoCodeBatchSubListMutation
} from 'store/promoCodeBatch'
import GLOBAL from 'modules/global'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import Detail from './detail/detail'
import Edit from './edit/edit'

const PromoBatchList = () => {
  const locationState = useLocation()
  const [getPromoCodeBatchSubList, promoCodeBatchSub] = useGetPromoCodeBatchSubListMutation()
  const [downloadPromoCodeBatchSub, download] = useDownloadPromoCodeBatchSubMutation()
  const [deleteMultiplesPromoCodeSub, deletePromoSub] = useDeleteMultiplesPromoCodeSubMutation()
  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [dialogUpdate, setDialogUpdate] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [menu, setMenu] = useState<null | number>(null)
  const [isSingleCode, setIsSingleCode] = useState<string>('')
  const [payload, setPayload] = useState<
    PartialPromoCodeBatchProps & {
      PromoId: string
    }
  >({
    Start: 0,
    Length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    Search: '',
    RewardType: '',
    MinValidFrom: '',
    MaxValidFrom: '',
    MinValidTo: '',
    MaxValidTo: '',
    MinCreatedAt: '',
    MaxCreatedAt: '',
    Status: '',
    PromoId: locationState.state.id || ''
  })

  const [date, setDate] = useState<FilterDatePromoCodeProps>({
    minValidFrom: null,
    maxValidFrom: null,
    minValidUntil: null,
    maxValidUntil: null,
    minCreatedDate: null,
    maxCreateDate: null
  })
  const [filter, setFilter] = useState({
    MinValidFrom: '',
    MaxValidFrom: '',
    MinValidTo: '',
    MaxValidTo: '',
    MinCreatedAt: '',
    MaxCreatedAt: ''
  })
  const [listChecked, setListChecked] = useState([])
  const [isChecked, setIsChecked] = useState<boolean>(false)

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const onChangePage = (value: number) => setPayload({ ...payload, Start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, Length: value })
  const onSearch = (value: string) => setPayload({ ...payload, Start: 0, Search: value })
  const onResync = () => {
    const formData = new FormData()
    formData.append('Start', payload.Start.toString())
    formData.append('Length', payload.Length.toString())

    if (!isEmpty(payload.Search)) formData.append('Search', payload.Search)
    if (!isEmpty(payload.RewardType)) formData.append('RewardType', payload.RewardType)
    if (!isEmpty(payload.MinValidFrom)) formData.append('MinValidFrom', payload.MinValidFrom)
    if (!isEmpty(payload.MaxValidFrom)) formData.append('MaxValidFrom', payload.MaxValidFrom)
    if (!isEmpty(payload.MinValidTo)) formData.append('MinValidTo', payload.MinValidTo)
    if (!isEmpty(payload.MaxValidTo)) formData.append('MaxValidTo', payload.MaxValidTo)
    if (!isEmpty(payload.MinCreatedAt)) formData.append('MinCreatedAt', payload.MinCreatedAt)
    if (!isEmpty(payload.MaxCreatedAt)) formData.append('MaxCreatedAt', payload.MaxCreatedAt)
    if (!isEmpty(payload.PromoId)) formData.append('PromoId', payload.PromoId)
    getPromoCodeBatchSubList(formData)
  }
  const onFilter = (state: boolean) => setDialogFilter(state)
  const onDownload = (type?: string) => {
    const formData = new FormData()
    formData.append('Start', payload.Start.toString())
    formData.append('Length', payload.Length.toString())

    if (!isEmpty(payload.Search)) formData.append('Search', payload.Search)
    if (!isEmpty(payload.RewardType)) formData.append('RewardType', payload.RewardType)
    if (!isEmpty(payload.MinValidFrom)) formData.append('MinValidFrom', payload.MinValidFrom)
    if (!isEmpty(payload.MaxValidFrom)) formData.append('MaxValidFrom', payload.MaxValidFrom)
    if (!isEmpty(payload.MinValidTo)) formData.append('MinValidTo', payload.MinValidTo)
    if (!isEmpty(payload.MinCreatedAt)) formData.append('MinCreatedAt', payload.MinCreatedAt)
    if (!isEmpty(payload.MaxCreatedAt)) formData.append('MaxCreatedAt', payload.MaxCreatedAt)
    if (!isEmpty(payload.PromoId)) formData.append('PromoId', payload.PromoId)
    if (type && !isEmpty(type)) formData.append('Content-Type', type)

    downloadPromoCodeBatchSub(formData)
  }

  const handleCheckAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(!isChecked)
    return event.target.checked
      ? setListChecked([
          ...listChecked,
          ...(((promoCodeBatchSub.data && promoCodeBatchSub.data.data.map((x) => x.code)) ||
            undefined) as never)
        ])
      : setListChecked([])
  }

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.checked
      ? setListChecked([...listChecked, event.target.value] as never)
      : setListChecked([...listChecked].filter((o) => o !== event.target.value))
  }

  const handleDelete = (e: string) => {
    const payload: PartialPromoCodeSubProps = {
      filter: {
        filterType: 'ContainsCodes',
        value: listChecked.length ? listChecked : [e]
      }
    }
    deleteMultiplesPromoCodeSub(payload)
  }

  useEffect(() => {
    onResync()
  }, [payload])

  const tableHeadTitles = [
    'Promo Code',
    'Reward',
    'Voucher Name',
    'Total Voucher',
    'Coin Amount',
    'Redeemed',
    'Valid From',
    'Valid Until',
    'Created Date'
  ]

  const handleClickSelectAll = () => {
    setListChecked([
      ...(((promoCodeBatchSub.data && promoCodeBatchSub.data.data.map((x) => x.code)) ||
        undefined) as never)
    ])
  }

  const openAnchor = Boolean(anchor)
  const totalPage =
    promoCodeBatchSub.data && GLOBAL.tableTotalPage(promoCodeBatchSub.data.recordsTotal)

  return (
    <>
      <Content
        title={`Promo Batch List > PREFIX: ${locationState.state.name || ''}`}
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        useBackButton
        useCheckbox
        withBtnSelectAll
        useDownloadDropdown
        downloadLoading={download.isLoading}
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={promoCodeBatchSub.data && promoCodeBatchSub.data.recordsTotal}
        page={payload.Start + 1}
        isLoading={promoCodeBatchSub.isLoading}
        onCheckBoxAll={(e) => handleCheckAll(e)}
        allChecked={listChecked}
        onClickCheckBoxAll={handleClickSelectAll}
        btnEditCheckBox='Edit Validity'
        onEditCheckBox={() => {
          setIsSingleCode('')
          setDialogUpdate(true)
        }}
      >
        {promoCodeBatchSub.isSuccess &&
          promoCodeBatchSub.data &&
          promoCodeBatchSub.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>
                <Checkbox
                  value={table.code}
                  onChange={(e) => handleCheck(e)}
                  checked={listChecked.includes(`${table.code}` as never)}
                />
              </TableCell>
              <TableCell>{index + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.id}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.id}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.id}`}
                  aria-labelledby={`button-${index}-${table.id}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      onRemoveAnchor(() => {
                        setIsSingleCode(table.code)
                        setDialogUpdate(true)
                      })
                    }
                  >
                    Edit Validity
                  </MenuItem>
                  <MenuModalItem
                    desc={
                      <Box>
                        <Typography>Are you sure want delete this item? </Typography>
                        <Typography>{`Deleting this item won't affect the promo code that is already redeemed by users`}</Typography>
                      </Box>
                    }
                    title={table.code}
                    onClick={() => onRemoveAnchor(() => handleDelete(table.code))}
                  >
                    <Typography {...GlobalStyle.ErrorTypography}>Delete</Typography>
                  </MenuModalItem>
                </Menu>
                {drawerDetail && menu == index && (
                  <Detail
                    id={table.id}
                    open={drawerDetail && menu == index}
                    onClose={() => setDrawerDetail(false)}
                  />
                )}
              </TableCell>
              <TableCell>{table.code || '-'}</TableCell>
              <TableCell>{table.rewardType || '-'}</TableCell>
              <TableCell {...GlobalStyle.EllipsisTable}>{table.voucherName || '-'}</TableCell>
              <TableCell>{table.totalVoucherQuantity || '-'}</TableCell>
              <TableCell>{table.coinsAmount || '-'}</TableCell>
              <TableCell>{table.redeemed || '-'}</TableCell>
              <TableCell>{GLOBAL.formatDate(table.validFrom) || '-'}</TableCell>
              <TableCell>{GLOBAL.formatDate(table.validTo) || '-'}</TableCell>
              <TableCell>{GLOBAL.formatDate(table.createdAt) || '-'}</TableCell>
            </TableRow>
          ))}
      </Content>
      {promoCodeBatchSub.isLoading && <Loading />}
      {dialogUpdate && (
        <Edit
          listChecked={listChecked?.length ? listChecked : [isSingleCode]}
          open={dialogUpdate}
          onClose={() => setDialogUpdate(false)}
        />
      )}
      <Notification
        open={
          !promoCodeBatchSub.isLoading &&
          !promoCodeBatchSub.isUninitialized &&
          !promoCodeBatchSub.isSuccess
        }
        onClose={() => (promoCodeBatchSub.isError ? promoCodeBatchSub.reset() : location.reload())}
        isError={Boolean(promoCodeBatchSub.error) && promoCodeBatchSub.isError}
        message={GLOBAL.returnExceptionMessage(
          promoCodeBatchSub.isError,
          promoCodeBatchSub.error as ErrorProps
        )}
      />

      <Notification
        open={!deletePromoSub.isLoading && !deletePromoSub.isUninitialized}
        onClose={() => (deletePromoSub.isError ? deletePromoSub.reset() : location.reload())}
        isError={Boolean(deletePromoSub.error) && deletePromoSub.isError}
        message={GLOBAL.returnExceptionMessage(
          deletePromoSub.isError,
          deletePromoSub.error as ErrorProps
        )}
      />
      <Filter
        open={dialogFilter}
        title='Filter'
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        onReset={() => {
          setPayload({
            Start: 0,
            Length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
            Search: '',
            RewardType: '',
            MinValidFrom: '',
            MaxValidFrom: '',
            MinValidTo: '',
            MaxValidTo: '',
            MinCreatedAt: '',
            MaxCreatedAt: '',
            Status: '',
            PromoId: locationState.state.id || ''
          })
          setFilter({
            MinValidFrom: '',
            MaxValidFrom: '',
            MinValidTo: '',
            MaxValidTo: '',
            MinCreatedAt: '',
            MaxCreatedAt: ''
          })
          setDate({
            minValidFrom: null,
            maxValidFrom: null,
            minValidUntil: null,
            maxValidUntil: null,
            minCreatedDate: null,
            maxCreateDate: null
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({
            ...payload,
            ...filter
          })
          onFilter(false)
        }}
      >
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
              <DatePicker
                value={date.minValidFrom}
                sx={{ width: '100%' }}
                onChange={(e) => {
                  const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                  setFilter((prevState) => ({ ...prevState, MinValidFrom: newDate }))
                  setDate((prevState) => ({ ...prevState, minValidFrom: e }))
                }}
                label='Min Valid From'
                format='DD-MM-YYYY'
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
              <DatePicker
                value={date.maxValidFrom}
                sx={{ width: '100%' }}
                minDate={dayjs(filter.MinValidFrom)}
                onChange={(e) => {
                  const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                  setFilter({ ...filter, MaxValidFrom: newDate })
                  setDate((prevState) => ({ ...prevState, maxValidFrom: e }))
                }}
                label='Max Valid From'
                format='DD-MM-YYYY'
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
              <DatePicker
                value={date.minValidUntil}
                sx={{ width: '100%' }}
                onChange={(e) => {
                  const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                  setFilter({ ...filter, MinValidTo: newDate })
                  setDate((prevState) => ({ ...prevState, minValidUntil: e }))
                }}
                label='Min Valid Until'
                format='DD-MM-YYYY'
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
              <DatePicker
                value={date.maxValidUntil}
                sx={{ width: '100%' }}
                minDate={dayjs(filter.MinValidTo)}
                onChange={(e) => {
                  const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                  setFilter({ ...filter, MaxValidTo: newDate })
                  setDate((prevState) => ({ ...prevState, maxValidUntil: e }))
                }}
                label='Max Valid Until'
                format='DD-MM-YYYY'
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
              <DatePicker
                value={date.minCreatedDate}
                sx={{ width: '100%' }}
                onChange={(e) => {
                  const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                  setFilter({ ...filter, MinCreatedAt: newDate })
                  setDate((prevState) => ({ ...prevState, minCreatedDate: e }))
                }}
                label='Min Created Date'
                format='DD-MM-YYYY'
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
              <DatePicker
                value={date.maxCreateDate}
                sx={{ width: '100%' }}
                minDate={dayjs(filter.MinCreatedAt)}
                onChange={(e) => {
                  const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                  setFilter({ ...filter, MaxCreatedAt: newDate })
                  setDate((prevState) => ({ ...prevState, maxCreateDate: e }))
                }}
                label='Max Created Date'
                format='DD-MM-YYYY'
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Filter>
    </>
  )
}

export default PromoBatchList
