import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
  Chip,
  FormControl,
  InputLabel,
  Select,
  Typography
} from '@mui/material'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import Icon from 'components/icon'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import CONSTANT from 'modules/constant'
import GLOBAL from 'modules/global'
import GlobalStyle from 'modules/styles'
import {
  useDeletePrivacyPolicyMutation,
  useDownloadPrivacyPolicyMutation,
  useEnablePrivacyPolicyMutation,
  useGetPrivacyPolicyMutation
} from 'store/privacy'
import type { PartialPrivacyProps } from 'modules/partial'
import type { ErrorProps } from 'modules/types'
import MenuModalItem from 'components/menuItem/menuItem'
import Create from './create/create'
import Detail from './detail/detail'
import Edit from './edit/edit'

const PrivacyPolicy = () => {
  const [getPrivacyPolicy, privacy] = useGetPrivacyPolicyMutation()
  const [deletePrivacyPolicy, deletePrivacy] = useDeletePrivacyPolicyMutation()
  const [enablePrivacyPolicy, enablePrivacy] = useEnablePrivacyPolicyMutation()
  const [downloadPrivacyPolicy, download] = useDownloadPrivacyPolicyMutation()

  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [dialogCreate, setDialogCreate] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [dialogUpdate, setDialogUpdate] = useState<boolean>(false)
  const [menu, setMenu] = useState<null | number>(null)

  const [filter, setFilter] = useState({
    language: '',
    status: ''
  })

  const [payload, setPayload] = useState<PartialPrivacyProps>({
    start: 0,
    length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    field: CONSTANT.DEFAULT_HTML_FIELD.join(','),
    search: { value: '' },
    language: '',
    status: ''
  })

  const onFilter = (state: boolean) => setDialogFilter(state)
  const onSearch = (value: string) => setPayload({ ...payload, start: 0, search: { value } })

  const onResync = () => {
    const formData = new FormData()

    formData.append('start', payload.start.toString())
    formData.append('length', payload.length.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(payload.language)) formData.append('language', payload.language)
    if (!isEmpty(payload.status)) formData.append('status', payload.status)

    getPrivacyPolicy(formData)
  }

  const onCreate = () => setDialogCreate(true)

  const onChangePage = (value: number) => setPayload({ ...payload, start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, length: value })

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('start', String(0))
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(payload.language)) formData.append('language', payload.language)
    if (!isEmpty(payload.status)) formData.append('status', payload.status)
    if (type && !isEmpty(type)) formData.append('Download-Type', type)

    downloadPrivacyPolicy(formData)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const tableHeadTitles = ['Language', 'Privacy Policy Name', 'Status']

  const openAnchor = Boolean(anchor)

  const totalPage = privacy.data && GLOBAL.tableTotalPage(privacy.data.recordsTotal)

  const numbers = GLOBAL.tableNumber(payload.start)

  useEffect(() => {
    onResync()
  }, [payload])

  return (
    <>
      <Content
        title='Privacy Policy'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        onAdditional={onCreate}
        additionalTitle='Create New Privacy Policy'
        additionalPrefixIcon='Create'
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={privacy.data && privacy.data.recordsTotal}
        page={payload.start + 1}
        isLoading={privacy.isLoading}
        useDownloadDropdown
      >
        {privacy.isSuccess &&
          privacy.data &&
          privacy.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.key}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.key}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.key}`}
                  aria-labelledby={`button-${index}-${table.key}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View
                  </MenuItem>
                  <MenuItem onClick={() => onRemoveAnchor(() => setDialogUpdate(true))}>
                    Edit
                  </MenuItem>
                  <MenuModalItem
                    title={table.status == 'Enabled' ? 'Disable' : 'Enable'}
                    onClick={() =>
                      onRemoveAnchor(() =>
                        enablePrivacyPolicy({
                          key: table.key || '',
                          status: table.status == 'Enabled' ? 'Disabled' : 'Enabled'
                        })
                      )
                    }
                  >
                    {table.status == 'Enabled' ? 'Disable' : 'Enable'}
                  </MenuModalItem>
                  <MenuModalItem
                    title='Delete'
                    loading={deletePrivacy.isLoading}
                    onClick={() => onRemoveAnchor(() => deletePrivacyPolicy({ key: table.key }))}
                  >
                    <Typography {...GlobalStyle.ErrorTypography}>Delete</Typography>
                  </MenuModalItem>
                </Menu>
                <Detail
                  id={table.key}
                  open={drawerDetail && menu == index}
                  onClose={() => setDrawerDetail(false)}
                />
                <Edit
                  id={table.key}
                  open={dialogUpdate && menu == index}
                  onClose={() => setDialogUpdate(false)}
                />
              </TableCell>
              <TableCell>{table.language || '-'}</TableCell>
              <TableCell {...GlobalStyle.EllipsisTable}>{table.key || '-'}</TableCell>
              <TableCell>
                <Chip
                  label={table.status}
                  color={table.status == 'Enabled' ? 'success' : 'error'}
                />
              </TableCell>
            </TableRow>
          ))}
      </Content>

      {privacy.isLoading && <Loading />}

      <Create open={dialogCreate} onClose={() => setDialogCreate(false)} />

      <Filter
        open={dialogFilter}
        title='Filter'
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
        onReset={() => {
          setFilter({
            status: '',
            language: ''
          })
          setPayload({
            ...payload,
            start: 0,
            status: '',
            language: ''
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({ ...payload, start: 0, ...filter })
          onFilter(false)
        }}
      >
        <FormControl fullWidth>
          <InputLabel id='select-label'>Status</InputLabel>
          <Select
            labelId='select-label'
            id='id-select-label'
            value={filter.status}
            label='Status'
            onChange={(event) => setFilter({ ...filter, status: event.target.value })}
          >
            <MenuItem value='Enabled'>Enabled</MenuItem>
            <MenuItem value='Disabled'>Disabled</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id='select-label'>Language</InputLabel>
          <Select
            labelId='select-label'
            id='id-select-label'
            value={filter.language}
            label='Language'
            onChange={(event) => setFilter({ ...filter, language: event.target.value })}
          >
            <MenuItem value='id'>ID</MenuItem>
            <MenuItem value='en-us'>EN-US</MenuItem>
          </Select>
        </FormControl>
      </Filter>

      <Notification
        open={!privacy.isLoading && !privacy.isUninitialized && !privacy.isSuccess}
        onClose={() => (privacy.isError ? privacy.reset() : location.reload())}
        isError={Boolean(privacy.error) && privacy.isError}
        message={GLOBAL.returnExceptionMessage(privacy.isError, privacy.error as ErrorProps)}
      />

      <Notification
        open={!deletePrivacy.isLoading && !deletePrivacy.isUninitialized}
        onClose={() => (deletePrivacy.isError ? deletePrivacy.reset() : location.reload())}
        isError={Boolean(deletePrivacy.error) && deletePrivacy.isError}
        message={GLOBAL.returnExceptionMessage(
          deletePrivacy.isError,
          deletePrivacy.error as ErrorProps
        )}
      />

      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />

      <Notification
        open={!enablePrivacy.isLoading && !enablePrivacy.isUninitialized}
        onClose={() => (enablePrivacy.isError ? enablePrivacy.reset() : location.reload())}
        isError={Boolean(enablePrivacy.error) && enablePrivacy.isError}
        message={GLOBAL.returnExceptionMessage(
          enablePrivacy.isError,
          enablePrivacy.error as ErrorProps
        )}
      />
    </>
  )
}

export default PrivacyPolicy
