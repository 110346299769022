import { useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import { isEmpty } from 'lodash'
import * as yup from 'yup'
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { PartialCreateEvPartnerProps, PartialUsersAccountProps } from 'modules/partial'
import type { DetailStateProps, DropdownListProps, ErrorProps } from 'modules/types'
import { useCreateEvPartnerMutation } from 'store/evPartner'
import { useGetCityDropdownMutation, useGetProvinceDropdownMutation } from 'store/region'
import Icon from 'components/icon'
import { useGetChargePointDropdownListMutation } from 'store/evChargePoint'
import GlobalStyle from 'modules/styles'
import { useGetUsersAccountListMutation } from 'store/control'
import CONSTANT from 'modules/constant'
import BackdropImage from 'components/backdropImage/backdropImage'
import DialogStyle from './style'

const Create = ({ open, onClose }: DetailStateProps) => {
  const [getCityDropdown, cityDropdown] = useGetCityDropdownMutation()
  const [getProvinceDropdown, provinceDropdown] = useGetProvinceDropdownMutation()
  const [getChargePointDropdownList, chargePointDropdown] = useGetChargePointDropdownListMutation()
  const [getUsersAccountList, account] = useGetUsersAccountListMutation()
  const [createEvPartner, create] = useCreateEvPartnerMutation()
  const [tableChargerPoint, setTableChargerPoint] = useState<DropdownListProps[]>([])
  const [listTempChargerPoint, setListTempChargerPoint] = useState<DropdownListProps[]>([])
  const [valChargerPoint, setValChargerPoint] = useState<string>()
  const [previewImage, setPreviewImage] = useState<string>('')
  const [randomKey, setRandomKey] = useState<number>(0)
  const [previewBackdrop, setPreviewBackdrop] = useState<boolean>(false)

  const [payload] = useState<PartialUsersAccountProps>({
    start: 0,
    length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    field: CONSTANT.DEFAULT_ACCESS_CONTROL_FIELD.join(','),
    search: { value: '' },
    status: ''
  })
  const [exclude, setExcludes] = useState<number[]>([])
  const [listTempPartnerId, setListTempPartnerId] = useState<DropdownListProps>({
    text: '',
    value: 0,
    group: ''
  })

  const dropdownPayload = {
    ...CONSTANT.DEFAULT_DROPDOWN_PAYLOAD,
    HasPartner: 'false',
    Excludes: exclude.toString()
  }

  const onGetDropdownUserList = () => {
    const formData = new FormData()
    formData.append('start', payload.start.toString())
    formData.append('length', payload.length.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(payload.status)) formData.append('status', payload.status)
    getUsersAccountList(formData)
  }

  const scheme = yup.object<PartialCreateEvPartnerProps>({
    name: yup.string().required('Partner Name is required'),
    shortName: yup.string().required('Partner Short Name is required'),
    phoneNumber: yup.string().required('Phone Number is required'),
    userId: yup.string().required('Member Id is required'),
    streetAddress: yup.string().required('Street Address is required'),
    email: yup.string().required('Email is required'),
    latitude: yup.string().required(' Latitude is required'),
    longitude: yup.string().required('Longitude is required'),
    active: yup.string().required('Active is required'),
    remark: yup.string().required('Remark is required'),
    city: yup.string().required('City is required'),
    district: yup.string().required('District is required'),
    subDistrict: yup.string().required('Sub District is required'),
    postalCode: yup.string().required('postal Code is required'),
    termsAndCondition: yup.string().required('Terms And Condition is required'),
    cityid: yup.string().required('City I is required'),
    provinceId: yup.string().required('province is required'),
    image: yup.mixed().required('image is required')
  })

  const formik = useFormik<PartialCreateEvPartnerProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      name: '',
      shortName: '',
      phoneNumber: '',
      userId: '',
      streetAddress: '',
      email: '',
      latitude: '',
      longitude: '',
      active: '',
      remark: '',
      city: '',
      district: '',
      subDistrict: '',
      postalCode: '',
      termsAndCondition: '',
      cityid: '',
      provinceId: '',
      image: ''
    },
    onSubmit: (values: PartialCreateEvPartnerProps) => handleSubmit(values)
  })

  const handleSubmit = (e: PartialCreateEvPartnerProps) => {
    const payloadTemp = !isEmpty(tableChargerPoint)
      ? tableChargerPoint.map((item) => item.value)
      : []
    const formData = new FormData()
    const payload = {
      name: e.name,
      shortName: e.shortName,
      phoneNumber: e.phoneNumber,
      userId: e.userId,
      streetAddress: e.streetAddress,
      email: e.email,
      latitude: e.latitude.toString(),
      longitude: e.longitude.toString(),
      active: e.active === 'True' ? true : false,
      remark: e.remark,
      city: e.city,
      district: e.district,
      subDistrict: e.subDistrict,
      postalCode: e.postalCode,
      termsAndCondition: e.termsAndCondition,
      cityid: e.cityid,
      provinceId: e.provinceId,
      chargerPoints: payloadTemp
    }
    formData.append('data', JSON.stringify(payload))
    formData.append('image', e.image)
    createEvPartner(formData)
  }

  const onDelTableChargerPoint = (id: string | number) => {
    const tempTable = tableChargerPoint.filter((item) => item.value !== id)
    setTableChargerPoint(tempTable)
  }

  const onAddChargerPoint = () => {
    const tempTable = tableChargerPoint.concat(listTempChargerPoint)
    setExcludes((prevState) => [...prevState, listTempPartnerId.value])
    setTableChargerPoint(tempTable)
    setValChargerPoint('')
    setRandomKey(randomKey + 1)
    setListTempChargerPoint([])
  }

  return (
    <>
      <Dialog open={open} fullWidth maxWidth='md'>
        <FormikProvider value={formik}>
          <DialogContent>
            <DialogTitle
              sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
            >
              <Typography variant='h6'>Create EV Partner Management System</Typography>
              <Box>
                <IconButton onClick={onClose}>
                  <Icon icon='Close' />
                </IconButton>
              </Box>
            </DialogTitle>
            <Box {...DialogStyle.wrapperBox}>
              <Box {...DialogStyle.wrapperLeftContent}>
                <TextField
                  {...DialogStyle.textField}
                  id='name'
                  focused
                  variant='outlined'
                  label='Partner Name'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  error={formik.touched && formik.touched.name && Boolean(formik.errors.name)}
                  helperText={
                    formik.touched && formik.touched.name && formik.errors && formik.errors.name
                  }
                  fullWidth
                />
                <TextField
                  id='shortName'
                  variant='outlined'
                  label='Partner Short Name'
                  onChange={formik.handleChange}
                  {...DialogStyle.textField}
                  onBlur={formik.handleBlur}
                  value={formik.values.shortName}
                  error={
                    formik.touched && formik.touched.shortName && Boolean(formik.errors.shortName)
                  }
                  helperText={
                    formik.touched &&
                    formik.touched.shortName &&
                    formik.errors &&
                    formik.errors.shortName
                  }
                  fullWidth
                />
                <Autocomplete
                  key={'userId'}
                  sx={{ width: '100%', marginBottom: '10px' }}
                  options={(account.isSuccess && account.data && account.data.data) || []}
                  getOptionLabel={(option) => option.userName}
                  isOptionEqualToValue={(option, value) =>
                    option && value ? option.userName == value.userName : false
                  }
                  onOpen={() => onGetDropdownUserList()}
                  onChange={(_, account) => {
                    if (account) {
                      formik.setFieldValue('userId', account.userId)
                    }
                  }}
                  value={
                    (account &&
                      account.data &&
                      account.data.data.find((e) => String(e.userId) == formik.values.userId)) ||
                    undefined
                  }
                  ListboxProps={GlobalStyle.ListBox}
                  renderOption={(props, item) => (
                    <li {...props} key={item.userId}>
                      {item.userName}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name='userId'
                      label='User ID'
                      value={formik.values.userId}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {account.isLoading && <CircularProgress color='inherit' size={20} />}
                            {params.InputProps.endAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                />
                <TextField
                  {...DialogStyle.textField}
                  id='phoneNumber'
                  variant='outlined'
                  label='Phone Number'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phoneNumber}
                  error={
                    formik.touched &&
                    formik.touched.phoneNumber &&
                    Boolean(formik.errors.phoneNumber)
                  }
                  helperText={
                    formik.touched &&
                    formik.touched.phoneNumber &&
                    formik.errors &&
                    formik.errors.phoneNumber
                  }
                  fullWidth
                />
                <TextField
                  id='email'
                  variant='outlined'
                  {...DialogStyle.textField}
                  label='Email'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  error={formik.touched && formik.touched.email && Boolean(formik.errors.email)}
                  helperText={
                    formik.touched && formik.touched.email && formik.errors && formik.errors.email
                  }
                  fullWidth
                />
                <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                  <InputLabel id='active'>Active</InputLabel>
                  <Select
                    labelId='active'
                    id='active'
                    value={formik.values.active}
                    label='Visibility'
                    onChange={(event) => formik.setFieldValue('active', event.target.value)}
                  >
                    <MenuItem value='True'>True</MenuItem>
                    <MenuItem value='False'>False</MenuItem>
                  </Select>
                </FormControl>
                <Box
                  sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                >
                  <Autocomplete
                    key={'ChargePointId-' + randomKey}
                    sx={{ width: '100%', marginRight: '10px' }}
                    options={(chargePointDropdown && chargePointDropdown.data) || []}
                    getOptionLabel={(option) => option.text}
                    isOptionEqualToValue={(option, value) =>
                      option && value ? option.value == value.value : false
                    }
                    onOpen={() => getChargePointDropdownList(dropdownPayload)}
                    onChange={(_, chargePoint) => {
                      if (chargePoint) {
                        setListTempChargerPoint([chargePoint])
                        setValChargerPoint(chargePoint.text)
                        setListTempPartnerId(chargePoint)
                      }
                    }}
                    value={
                      (chargePointDropdown &&
                        chargePointDropdown.data &&
                        chargePointDropdown.data.find((e) => String(e.text) == valChargerPoint)) ||
                      undefined
                    }
                    ListboxProps={GlobalStyle.ListBox}
                    renderOption={(props, item) => (
                      <li {...props} key={item.value}>
                        {item.text}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name='ChargePointId'
                        label='Charge Point'
                        value={valChargerPoint}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {chargePointDropdown.isLoading && (
                                <CircularProgress color='inherit' size={20} />
                              )}
                              {params.InputProps.endAdornment}
                            </>
                          )
                        }}
                      />
                    )}
                  />
                  <Button
                    variant='contained'
                    onClick={onAddChargerPoint}
                    disabled={isEmpty(listTempChargerPoint)}
                  >
                    Add
                  </Button>
                </Box>
                <TableContainer
                  component={Paper}
                  sx={{ maxHeight: 512, marginTop: '10px' }}
                  variant='outlined'
                >
                  <Table aria-label='Data Table' stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Charger Point</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableChargerPoint.map((table, index) => (
                        <TableRow key={index}>
                          <TableCell>{table.text}</TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() => onDelTableChargerPoint(table.value)}
                              sx={{ color: 'red' }}
                            >
                              <Icon icon='Delete' />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box
                sx={{
                  borderLeft: '1px solid gray',
                  flex: 1
                }}
              >
                <Box sx={{ padding: '10px 24px' }}>
                  <TextField
                    id='streetAddress'
                    variant='outlined'
                    label='Address'
                    {...DialogStyle.textField}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.streetAddress}
                    error={
                      formik.touched &&
                      formik.touched.streetAddress &&
                      Boolean(formik.errors.streetAddress)
                    }
                    helperText={
                      formik.touched &&
                      formik.touched.streetAddress &&
                      formik.errors &&
                      formik.errors.streetAddress
                    }
                    fullWidth
                  />
                  <Autocomplete
                    sx={{ marginBottom: '10px' }}
                    options={(provinceDropdown && provinceDropdown.data) || []}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option && value ? option.id == value.id : false
                    }
                    onOpen={() => getProvinceDropdown()}
                    onChange={(_, province) => {
                      if (province) {
                        formik.setFieldValue('provinceId', province.id.toString())
                      }
                    }}
                    value={
                      (provinceDropdown &&
                        provinceDropdown.data &&
                        provinceDropdown.data.find(
                          (e) => String(e.id) == formik.values.provinceId
                        )) ||
                      null
                    }
                    ListboxProps={GlobalStyle.ListBox}
                    renderOption={(props, item) => (
                      <li {...props} key={item.id}>
                        {item.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name='province'
                        label='Province'
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {provinceDropdown.isLoading && (
                                <CircularProgress color='inherit' size={20} />
                              )}
                              {params.InputProps.endAdornment}
                            </>
                          )
                        }}
                      />
                    )}
                  />
                  <Autocomplete
                    sx={{ marginBottom: '10px' }}
                    options={(cityDropdown && cityDropdown.data) || []}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option && value ? option.id == value.id : false
                    }
                    onOpen={() => getCityDropdown(formik.values.provinceId)}
                    onChange={(_, city) => {
                      if (city) {
                        formik.setFieldValue('city', city.name.toString())
                        formik.setFieldValue('cityid', city.id.toString())
                      }
                    }}
                    value={
                      (cityDropdown &&
                        cityDropdown.data &&
                        cityDropdown.data.find((e) => String(e.id) == formik.values.cityid)) ||
                      null
                    }
                    disabled={isEmpty(formik.values.provinceId)}
                    ListboxProps={GlobalStyle.ListBox}
                    renderOption={(props, item) => (
                      <li {...props} key={item.id}>
                        {item.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name='city'
                        label='City'
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {cityDropdown.isLoading && (
                                <CircularProgress color='inherit' size={20} />
                              )}
                              {params.InputProps.endAdornment}
                            </>
                          )
                        }}
                      />
                    )}
                  />
                  <TextField
                    id='district'
                    variant='outlined'
                    label='District'
                    {...DialogStyle.textField}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.district}
                    error={
                      formik.touched && formik.touched.district && Boolean(formik.errors.district)
                    }
                    helperText={
                      formik.touched &&
                      formik.touched.district &&
                      formik.errors &&
                      formik.errors.district
                    }
                    fullWidth
                  />
                  <TextField
                    id='subDistrict'
                    variant='outlined'
                    label='Sub District'
                    {...DialogStyle.textField}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.subDistrict}
                    error={
                      formik.touched &&
                      formik.touched.subDistrict &&
                      Boolean(formik.errors.subDistrict)
                    }
                    helperText={
                      formik.touched &&
                      formik.touched.subDistrict &&
                      formik.errors &&
                      formik.errors.subDistrict
                    }
                    fullWidth
                  />
                  <TextField
                    id='postalCode'
                    variant='outlined'
                    label='Postal Code'
                    {...DialogStyle.textField}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.postalCode}
                    error={
                      formik.touched &&
                      formik.touched.postalCode &&
                      Boolean(formik.errors.postalCode)
                    }
                    helperText={
                      formik.touched &&
                      formik.touched.postalCode &&
                      formik.errors &&
                      formik.errors.postalCode
                    }
                    fullWidth
                  />
                  <TextField
                    id='latitude'
                    variant='outlined'
                    label='Latitude'
                    onChange={formik.handleChange}
                    {...DialogStyle.textField}
                    type='number'
                    onBlur={formik.handleBlur}
                    value={formik.values.latitude}
                    error={
                      formik.touched && formik.touched.latitude && Boolean(formik.errors.latitude)
                    }
                    helperText={
                      formik.touched &&
                      formik.touched.latitude &&
                      formik.errors &&
                      formik.errors.latitude
                    }
                    fullWidth
                  />
                  <TextField
                    id='longitude'
                    variant='outlined'
                    label='Longitude'
                    onChange={formik.handleChange}
                    {...DialogStyle.textField}
                    type='number'
                    onBlur={formik.handleBlur}
                    value={formik.values.longitude}
                    error={
                      formik.touched && formik.touched.longitude && Boolean(formik.errors.longitude)
                    }
                    helperText={
                      formik.touched &&
                      formik.touched.longitude &&
                      formik.errors &&
                      formik.errors.longitude
                    }
                    fullWidth
                  />
                  <TextField
                    {...DialogStyle.textField}
                    id='remark'
                    variant='outlined'
                    label='Remark'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.remark}
                    error={formik.touched && formik.touched.remark && Boolean(formik.errors.remark)}
                    helperText={
                      formik.touched &&
                      formik.touched.remark &&
                      formik.errors &&
                      formik.errors.remark
                    }
                    fullWidth
                  />
                  <TextField
                    {...DialogStyle.textField}
                    id='termsAndCondition'
                    variant='outlined'
                    label='Terms And Condition'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.termsAndCondition}
                    error={
                      formik.touched &&
                      formik.touched.termsAndCondition &&
                      Boolean(formik.errors.termsAndCondition)
                    }
                    helperText={
                      formik.touched &&
                      formik.touched.termsAndCondition &&
                      formik.errors &&
                      formik.errors.termsAndCondition
                    }
                    fullWidth
                  />
                  <Box {...DialogStyle.wrapperImage}>
                    {!previewImage && (
                      <Typography style={{ color: '#cfcfcf' }}>
                        Company Image Cannot be Empty
                      </Typography>
                    )}
                    {previewImage && (
                      <BackdropImage
                        open={previewBackdrop}
                        onOpen={() => setPreviewBackdrop(true)}
                        onClose={() => setPreviewBackdrop(false)}
                      >
                        <img
                          src={previewImage}
                          alt=''
                          style={{ height: '300px', width: '394px', objectFit: 'contain' }}
                        />
                      </BackdropImage>
                    )}
                  </Box>
                  <TextField
                    required
                    id='image'
                    label='Company Image'
                    type='file'
                    placeholder='image'
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ accept: 'image/png, image/gif, image/jpeg', shrink: true }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const files = e && e.target && e.target.files
                      if (files && files.length > 0 && !isEmpty(files)) {
                        formik.setFieldValue('image', files[0])
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const reader: any = new FileReader()
                        reader.onload = () => {
                          setPreviewImage(reader.result)
                        }
                        reader.readAsDataURL(files[0])
                      } else {
                        setPreviewImage('')
                      }
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched && formik.touched.image && Boolean(formik.errors.image)}
                    helperText={
                      formik.touched && formik.touched.image && formik.errors && formik.errors.image
                    }
                    fullWidth
                  />
                </Box>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant='contained' sx={{ marginRight: '10px' }} onClick={onClose}>
              Cancel
            </Button>

            <LoadingButton
              loading={create.isLoading}
              variant='contained'
              disabled={!formik.isValid}
              onClick={() => formik.handleSubmit()}
            >
              Submit
            </LoadingButton>
          </DialogActions>
        </FormikProvider>
      </Dialog>
      <Notification
        open={!create.isLoading && !create.isUninitialized}
        onClose={() => (create.isError ? create.reset() : location.reload())}
        isError={Boolean(create.error) && create.isError}
        message={GLOBAL.returnExceptionMessage(create.isError, create.error as ErrorProps)}
      />
    </>
  )
}

export default Create
