import { useState } from 'react'
import { isEmpty } from 'lodash'
import { Autocomplete, CircularProgress, Container, TextField } from '@mui/material'
import Dialog from 'components/dialog/dialog'
import Notification from 'components/notification'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import GLOBAL from 'modules/global'
import { useUploadTokenImportMutation } from 'store/tokenImport'
import { useGetTokenPartnerDropdownMutation } from 'store/token'
import GlobalStyle from 'modules/styles'
import { useGetVoucherListDropdownByIdMutation } from 'store/tokenRequest'
import DialogStyle from './style'

const Upload = ({ open, onClose }: DetailStateProps) => {
  const [file, setFile] = useState<string | Blob>('')
  const [hasErr, setHasErr] = useState<boolean>(false)
  const [uploadTokenImport, upload] = useUploadTokenImportMutation()
  const [getTokenPartnerDropdown, dropdownPartner] = useGetTokenPartnerDropdownMutation()
  const [getVoucherListDropdown, voucherDropdown] = useGetVoucherListDropdownByIdMutation()
  const [VoucherId, setVoucherId] = useState<number>(0)
  const [CompanyId, setCompanyId] = useState<number>(0)

  const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e && e.target && e.target.files

    if (files && files.length > 0 && !isEmpty(files)) {
      setFile(files[0])
      setHasErr(false)
    }
  }

  const onSubmit = () => {
    if (!file) {
      setHasErr(true)
      return
    }
    const payload = { CompanyId, VoucherId }

    const formData = new FormData()

    formData.append('inputPayload', JSON.stringify(payload))
    formData.append('payload', file)

    uploadTokenImport(formData)
  }

  return (
    <>
      <Dialog
        open={open}
        title='Upload Token Import'
        onCancel={onClose}
        onSubmit={onSubmit}
        loading={upload.isLoading}
        isDisabled={!file.toString().length || VoucherId == 0 || CompanyId == 0}
      >
        <Container {...DialogStyle.Container}>
          <Autocomplete
            options={dropdownPartner.data || []}
            getOptionLabel={(list) => list.name}
            isOptionEqualToValue={(option, value) =>
              option && value ? option.id == value.id : false
            }
            onOpen={() => getTokenPartnerDropdown()}
            onChange={(_, id) => {
              setCompanyId(Number(id && id.id))
              setVoucherId(0)
            }}
            ListboxProps={GlobalStyle.ListBox}
            renderOption={(props, item) => (
              <li {...props} key={item.id}>
                {item.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                name='companyId'
                label='Select A Partner'
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {dropdownPartner.isLoading && <CircularProgress color='inherit' size={20} />}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
          <Autocomplete
            key={CompanyId}
            options={voucherDropdown.data || []}
            getOptionLabel={(list) => list.voucherName}
            isOptionEqualToValue={(option, value) =>
              option && value ? option.id == value.id : false
            }
            onOpen={() => getVoucherListDropdown(CompanyId)}
            onChange={(_, id) => setVoucherId(Number(id && id.id))}
            disabled={CompanyId <= 0}
            ListboxProps={GlobalStyle.ListBox}
            renderOption={(props, item) => (
              <li {...props} key={item.id}>
                {item.voucherName}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                name='VoucherId'
                label='Voucher Name'
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {voucherDropdown.isLoading && <CircularProgress color='inherit' size={20} />}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
          <TextField
            id='File'
            variant='standard'
            label='File'
            inputProps={{
              accept:
                '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
            }}
            type='file'
            onChange={onChangeFile}
            error={hasErr && !file}
            helperText={hasErr && !file && 'file Cannot be empty'}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
        </Container>
      </Dialog>

      <Notification
        open={!upload.isLoading && !upload.isUninitialized}
        onClose={() => (upload.isError ? upload.reset() : location.reload())}
        isError={Boolean(upload.error) && upload.isError}
        message={GLOBAL.returnExceptionMessage(upload.isError, upload.error as ErrorProps)}
      />
    </>
  )
}

export default Upload
