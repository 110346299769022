import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@mui/material'
import { ExpandMoreOutlined } from '@mui/icons-material'
import Drawer from 'components/drawer/detail'
import Icon from 'components/icon'
import type { DetailStateProps } from 'modules/types'
import { useGetDetailPromoCodeRedemptionQuery } from 'store/promoCodeRedemption'
import Loading from 'components/loading/loading'
import GLOBAL from 'modules/global'
import DialogStyle from './style'

const Detail = ({ open, id, onClose }: DetailStateProps & { id: number }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />
  const { data, isLoading } = useGetDetailPromoCodeRedemptionQuery(id)

  const promoName = (data && data.promoName) || '-'
  const code = (data && data.promoCode) || '-'
  const rewardType = (data && data.rewardType) || '-'
  const coinsAmount = (data && data.coinsAmount) || '-'
  const userId = (data && data.userId) || '-'
  const userFullName = (data && data.userFullName) || '-'
  const status = (data && data.status) || '-'
  const statusNotes = (data && data.statusNotes) || '-'
  const voucherName = (data && data.voucherName) || '-'

  const validFrom = data && GLOBAL.formatDate(data.validFrom)
  const validTo = data && GLOBAL.formatDate(data.validTo)
  const messageBody = (data && data.messageBody) || '-'
  const messageTitle = (data && data.messageTitle) || '-'

  const createdBy = (data && data.createdBy) || '-'
  const modifiedBy = (data && data.modifiedBy) || '-'
  const createdAt = (data && GLOBAL.formatDateWithTime(data.createdAt)) || '-'
  const modifiedAt = (data && GLOBAL.formatDateWithTime(data.modifiedAt)) || '-'
  const redeemedAt = (data && GLOBAL.formatDateWithTime(data.redeemedAt)) || '-'


  const gridDataLabel = [
    'Campaign Name',
    'Promo Code',
    'Reward = Voucher / Coins',
    'Voucher Name',
    'Coins Amount',
    'User ID',
    'User Name',
    'Redemption Status',
    'Redemption Status Notes'
  ]
  const gridDataValue = [
    promoName,
    code,
    rewardType,
    voucherName,
    coinsAmount,
    userId,
    userFullName,
    status,
    statusNotes
  ]

  const gridDataLabelRight = ['Valid From', 'Valid Until', 'Message Title', 'Message Body', 'Redeem At']

  const gridDataValueRight = [validFrom, validTo, messageTitle, messageBody, redeemedAt]
  const gridBasicLabel = ['Created By', 'Created At', 'Modified By', 'Modified At']
  const gridBasicValue = [ createdBy, createdAt, modifiedBy, modifiedAt]

  return (
    <Dialog open={open} fullWidth maxWidth='md' onClose={onClose}>
      <DialogContent>
        <DialogTitle
          sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
        >
          <Typography variant='h6'>Detail Promo Code Redemption</Typography>
          <Box>
            <IconButton onClick={onClose}>
              <Icon icon='Close' />
            </IconButton>
          </Box>
        </DialogTitle>
        {isLoading && <Loading />}
        {data && (
          <>
            <Box {...DialogStyle.wrapperBox}>
              <Box {...DialogStyle.wrapperLeftContent} sx={{ width: '50%', marginRight: '5px' }}>
                <Grid container spacing={2} sx={{ marginBottom: '10px' }}>
                  {gridDataValue.map((item, index) => (
                    <Grid key={index} item xs={12}>
                      <TextField
                        variant='outlined'
                        label={gridDataLabel[index]}
                        value={item}
                        inputProps={{ readOnly: true }}
                        fullWidth
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
              <Box {...DialogStyle.wrapperLeftContent} sx={{ width: '50%', marginLeft: '5px' }}>
                <Grid container spacing={2} sx={{ marginBottom: '10px' }}>
                  {gridDataValueRight.map((item, index) => (
                    <Grid key={index} item xs={12}>
                      {gridDataLabelRight[index] === 'Message Title' &&
                      gridDataLabelRight[index] === 'Message Body' ? (
                        <TextField
                          multiline
                          rows={2}
                          maxRows={4}
                          sx={{ width: '421px', height: '70px' }}
                          label={gridDataLabelRight[index]}
                          inputProps={{ readOnly: true }}
                          value={item}
                        />
                      ) : (
                        <TextField
                          variant='outlined'
                          label={gridDataLabelRight[index]}
                          value={item}
                          inputProps={{ readOnly: true }}
                          fullWidth
                        />
                      )}
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
            <Accordion elevation={0} disableGutters>
              <AccordionSummary
                expandIcon={<ExpandMoreOutlined />}
                aria-controls='basic-information'
              >
                <Typography>Basic Information</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {gridBasicValue.map((item, index) => (
                    <Grid key={index} item xs={12}>
                      <TextField
                        variant='outlined'
                        label={gridBasicLabel[index]}
                        value={item}
                        inputProps={{ readOnly: true }}
                        fullWidth
                      />
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default Detail
