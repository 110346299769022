import { useEffect, useState } from 'react'
import { Box, Container, Grid, TextField, Typography } from '@mui/material'
import { decode } from 'html-entities'
import Drawer from 'components/drawer/detail'
import Loading from 'components/loading/loading'
import type { DetailStateProps } from 'modules/types'
import { useGetVoucherGiftDetailQuery } from 'store/voucherGift'
import { useGetPromoCodeQrCodeMutation } from 'store/promoCodeBatch'
import GLOBAL from 'modules/global'
import BackdropImage from 'components/backdropImage/backdropImage'
import DrawerStyle from './style'

const Detail = ({ open, id, onClose }: DetailStateProps & { id: string | number }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const { data, isFetching, isLoading } = useGetVoucherGiftDetailQuery(id)
  const [generateQR, QR] = useGetPromoCodeQrCodeMutation()
  const [previewBackdrop, setPreviewBackdrop] = useState<boolean>(false)

  const voucherGiftName = (data && data.voucherGiftName) || '-'
  const assignmentMethod = (data && data.assignmentMethode) || '-'
  const assignValidFrom = (data && GLOBAL.formatDateddMMYYLongDate(data.assignValidFrom)) || '-'
  const assignValidUntil = (data && GLOBAL.formatDateddMMYYLongDate(data.assignValidUntil)) || '-'
  const voucherName = (data && data.voucherName) || '-'
  const assignmentMode = (data && data.assignmentMode) || '-'
  const token = (data && data.token) || '-'
  const validFrom = (data && GLOBAL.formatDateddMMYYLongDate(data.validFrom)) || '-'
  const validUntil = (data && GLOBAL.formatDateddMMYYLongDate(data.validUntil)) || '-'
  const notes = (data && data.notes) || '-'

  const memberId = (data && data.memberCode) || '-'
  const user = (data && data.fullNameUser) || '-'
  const notificationTitle = (data && data.dataObjectNotif && data.dataObjectNotif.notiftitle) || '-'
  const notificationDescription =
    (data && data.dataObjectNotif && decode(data.dataObjectNotif.notifdata)) || '-'
  const sendDate = (data && GLOBAL.formatDateWithTime(data.sendDate)) || '-'
  const sendStatus = (data && data.sendStatus) || '-'
  const status = (data && data.status) || '-'
  const barcode = QR && QR.data && QR.data.value
  const giftVoucherCode = data && data.giftVoucherCode

  const halfGridLabel = [
    'Gift Voucher Campaign Name',
    'Assignment Method',
    'Assign Valid From',
    'Assign Valid Until',
    'Voucher',
    'Assignment Mode',
    'Token',
    'Token Valid From',
    'Token Valid Until',
    'Notes'
  ]

  const halfGridValue = [
    voucherGiftName,
    assignmentMethod,
    assignValidFrom,
    assignValidUntil,
    voucherName,
    assignmentMode,
    token,
    validFrom,
    validUntil,
    notes
  ]

  const fullGridLabel = [
    'Member ID',
    'User',
    'Notification Title',
    'Notification Message Body',
    'Send Date',
    'Send Status',
    'Status'
  ]

  const fullGridValue = [
    memberId,
    user,
    notificationTitle,
    notificationDescription,
    sendDate,
    sendStatus,
    status
  ]

  const loading = !data && (isLoading || isFetching)

  const [label, setLabel] = useState<string[]>([])
  const [value, setValue] = useState<string[]>([])

  useEffect(() => {
    if (data && data.barcode && QR.isUninitialized)
      generateQR({
        text: data.barcode,
        options: {
          size: 10,
          eccLevel: 'l'
        }
      })
  })

  useEffect(() => {
    if (data && data.assignmentMode !== 'AssignCustomToken') {
      const tokenIndex = halfGridLabel.findIndex((el) => el == 'Token')

      halfGridLabel.splice(tokenIndex, 1)
      halfGridValue.splice(tokenIndex, 1)
    }

    if (data && data.assignmentMethode !== 'url') {
      const assignValidFromIndex = halfGridLabel.findIndex((el) => el == 'Assign Valid From')

      halfGridLabel.splice(assignValidFromIndex, 1)
      halfGridValue.splice(assignValidFromIndex, 1)
    }

    if (data && data.assignmentMethode !== 'url') {
      const assignValidUntilIndex = halfGridLabel.findIndex((el) => el == 'Assign Valid Until')

      halfGridLabel.splice(assignValidUntilIndex, 1)
      halfGridValue.splice(assignValidUntilIndex, 1)
    }

    setLabel(halfGridLabel)
    setValue(halfGridValue)
  }, [halfGridLabel, halfGridValue])

  return (
    <>
      <Drawer open={open} title='Detail Gift Voucher' onClose={onClose}>
        <Container {...DrawerStyle.Container} maxWidth='lg'>
          {loading && <Loading />}
          {!loading && (
            <>
              <Grid container spacing={2}>
                {value.map((item, index) => (
                  <Grid key={index} item xs={6}>
                    <TextField
                      variant='outlined'
                      label={label[index]}
                      value={item}
                      inputProps={{ readOnly: true }}
                      fullWidth
                    />
                  </Grid>
                ))}
                {fullGridValue.map((item, index) => (
                  <Grid key={index} item xs={12}>
                    <TextField
                      variant='outlined'
                      label={fullGridLabel[index]}
                      value={item}
                      inputProps={{ readOnly: true }}
                      rows={fullGridLabel[index] == 'Notification Message Body' ? 3 : 1}
                      fullWidth
                      multiline
                    />
                  </Grid>
                ))}
                {QR.isLoading && (
                  <Grid item xs={12}>
                    <Loading />
                  </Grid>
                )}
                {QR.data && (
                  <Grid item xs={12}>
                    <Typography {...DrawerStyle.Title}>Barcode</Typography>
                    <Box {...DrawerStyle.Barcode}>
                      <BackdropImage
                        open={previewBackdrop}
                        onOpen={() => setPreviewBackdrop(true)}
                        onClose={() => setPreviewBackdrop(false)}
                      >
                        <img
                          src={`data:image/jpeg;base64,${barcode}`}
                          alt='Voucher Image Banner'
                          width='100%'
                        />
                      </BackdropImage>
                    </Box>
                  </Grid>
                )}
                {giftVoucherCode && (
                  <Grid item xs={12}>
                    <TextField
                      variant='outlined'
                      label='Gift Voucher Code'
                      value={giftVoucherCode}
                      inputProps={{ readOnly: true }}
                      fullWidth
                    />
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </Container>
      </Drawer>
    </>
  )
}

export default Detail
