import React, { useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import { isEmpty } from 'lodash'
import * as yup from 'yup'
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type {
  PartialPromoCodeBatchCreateProps,
  PartialPromoCodeBatchCreateTempProps
} from 'modules/partial'
import type {
  DetailStateProps,
  DropdownCategoryListProps,
  ErrorProps,
  MemberTypeDropDown
} from 'modules/types'
import Icon from 'components/icon'
import GlobalStyle from 'modules/styles'
import {
  useCreatePromoCodeBatchMutation,
  useDropDownPromoCodeBatchRewardMutation
} from 'store/promoCodeBatch'
import { useGetMemberTypeDropdownMutation } from 'store/dropdown'
import { useGetVoucherListDropdownMutation } from 'store/tokenRequest'
import Dialog from 'components/dialog/dialog'
import DialogStyle from './style'

const Create = ({ open, onClose }: DetailStateProps) => {
  const [dropDownPromoCodeBatchReward, dropdownReward] = useDropDownPromoCodeBatchRewardMutation()
  const [getVoucherListDropdown, voucherDropdown] = useGetVoucherListDropdownMutation()
  const [getMemberTypeDropdown, memberType] = useGetMemberTypeDropdownMutation()
  const [createPromoCodeBatch, create] = useCreatePromoCodeBatchMutation()
  const [tableVoucher, setTableVoucher] = useState<
    {
      qty: number | string
      id: number | string
      name: string
      key: string
      language: string
    }[]
  >([])
  const [listTempVoucher, setListTempVoucher] = useState<DropdownCategoryListProps>({
    id: '',
    name: '',
    key: '',
    language: ''
  })
  // const [listTempVoucher, setListTempVoucher] = useState<DropdownCategoryListProps>()
  const [valVoucher, setValVoucher] = useState<string>('')
  const [randomKey, setRandomKey] = useState<number>(0)
  const [qty, setQty] = useState<number | string>('')
  const [rewardType, setRewardType] = useState<string>('')
  const [userType, setUserType] = useState<MemberTypeDropDown[]>([])
  const [payloadDate, setPayloadDate] = useState<{
    minSignUpDate: string
    maxSignUpDate: string
  }>({
    minSignUpDate: '',
    maxSignUpDate: ''
  })
  const [payload, setPayload] = useState<{
    coinsAmount: number | null
    maxRedemption: number | null
  }>({
    coinsAmount: null,
    maxRedemption: null
  })
  const [date, setDate] = useState<{
    minDateSignUp: Dayjs | null
    maxDateSignUp: Dayjs | null
    validFrom: Dayjs | null
    validUntil: Dayjs | null
  }>({
    minDateSignUp: null,
    maxDateSignUp: null,
    validFrom: null,
    validUntil: null
  })

  const scheme = yup.object<PartialPromoCodeBatchCreateTempProps>({
    codeLength: yup.number().required('Code Length is required').positive().integer(),
    quantity: yup.number().required('Quantity is required').positive().integer(),
    name: yup.string().required('Campaign Name is required'),
    prefix: yup.string().required('Prefix is required'),
    rewardType: yup.string().required('Reward is required'),
    validFrom: yup.string().required('Valid From is required'),
    validTo: yup.string().required('Valid Untill is required'),
    messageTitle: yup.string().required('Message Title is required'),
    messageBody: yup.string().required('Message Titleis required')
  })

  const formik = useFormik<PartialPromoCodeBatchCreateTempProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      codeLength: null,
      name: '',
      prefix: '',
      rewardType: '',
      validFrom: '',
      validTo: '',
      messageTitle: '',
      messageBody: '',
      quantity: null
    },
    onSubmit: (values: PartialPromoCodeBatchCreateTempProps) => handleSubmit(values)
  })

  const handleSubmit = (e: PartialPromoCodeBatchCreateTempProps) => {
    const formatUser =
      userType?.length > 0
        ? userType.map((item) => ({
            memberId: item.id,
            memberName: item.name
          }))
        : []

    const formatVoucher =
      tableVoucher?.length > 0
        ? tableVoucher.map((item) => ({
            quantity: item.qty,
            voucherId: item.id,
            voucherName: item.name
          }))
        : []

    const formatPayload: PartialPromoCodeBatchCreateProps = {
      ...e,
      minSignUpDate: !isEmpty(payloadDate.minSignUpDate) ? payloadDate.minSignUpDate : null,
      maxSignUpDate: !isEmpty(payloadDate.maxSignUpDate) ? payloadDate.maxSignUpDate : null,
      ...payload,
      validFrom: dayjs(e.validFrom).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      validTo: dayjs(e.validTo).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      members: formatUser,
      vouchers: formatVoucher
    }
    createPromoCodeBatch(formatPayload)
  }

  const onDelTableChargerPoint = (id: string | number) => {
    const tempTable = tableVoucher.filter((item) => item.id !== id)
    setTableVoucher(tempTable)
  }

  const onAddChargerPoint = () => {
    const combineVal = { ...listTempVoucher, qty }
    const tempTable = tableVoucher.concat(combineVal)
    setTableVoucher(tempTable)
    setValVoucher('')
    setQty('')
    setRandomKey(randomKey + 1)
    setListTempVoucher({
      id: '',
      name: '',
      key: '',
      language: ''
    })
  }

  const DescMessage = () => (
    <Box sx={{ marginBottom: '10px' }}>
      <Typography variant='caption' display='block' gutterBottom color='#525252'>
        *You can use these placeholder in the field above
      </Typography>
      <Typography variant='caption' display='block' gutterBottom color='#525252'>
        <span
          style={{ fontSize: '14px', fontWeight: '500', color: '#242423' }}
        >{`{userFullName} `}</span>
        Name of the recipient
      </Typography>
      <Typography variant='caption' display='block' gutterBottom color='#525252'>
        <span
          style={{ fontSize: '14px', fontWeight: '500', color: '#242423' }}
        >{`{voucherName} `}</span>
        Voucher Title
      </Typography>
      <Typography variant='caption' display='block' gutterBottom color='#525252'>
        <span
          style={{ fontSize: '14px', fontWeight: '500', color: '#242423' }}
        >{`{coinsAmount} `}</span>
        Coin amount given to user
      </Typography>
    </Box>
  )

  return (
    <>
      <Dialog
        isDisabled={!formik.isValid}
        title='Create Promo Code Batch'
        open={open}
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={create.isLoading}
        maxWidth='md'
      >
        <FormikProvider value={formik}>
          <Container {...DialogStyle.Container}>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Box sx={{ display: 'flex', flex: 1, gap: 2, flexDirection: 'column' }}>
                <TextField
                  {...DialogStyle.textField}
                  id='name'
                  focused
                  variant='outlined'
                  label='Campaign Name'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  error={formik.touched && formik.touched.name && Boolean(formik.errors.name)}
                  helperText={
                    formik.touched && formik.touched.name && formik.errors && formik.errors.name
                  }
                  fullWidth
                />
                <TextField
                  id='prefix'
                  variant='outlined'
                  label='Prefix'
                  onChange={formik.handleChange}
                  {...DialogStyle.textField}
                  onBlur={formik.handleBlur}
                  value={formik.values.prefix}
                  error={formik.touched && formik.touched.prefix && Boolean(formik.errors.prefix)}
                  helperText={
                    formik.touched && formik.touched.prefix && formik.errors && formik.errors.prefix
                  }
                  fullWidth
                />
                <Autocomplete
                  sx={{ marginBottom: '10px' }}
                  options={(dropdownReward && dropdownReward.data) || []}
                  getOptionLabel={(option) => option.text}
                  isOptionEqualToValue={(option, value) =>
                    option && value ? option.value == value.value : false
                  }
                  onOpen={() => dropDownPromoCodeBatchReward()}
                  onChange={(_, reward) => {
                    if (!isEmpty(reward)) {
                      formik.setFieldValue('rewardType', reward.text)
                      setRewardType(reward.text)
                    }
                  }}
                  value={
                    (dropdownReward &&
                      dropdownReward.data &&
                      dropdownReward.data.find((e) => e.text == rewardType)) ||
                    undefined
                  }
                  ListboxProps={GlobalStyle.ListBox}
                  renderOption={(props, item) => (
                    <li {...props} key={item.value}>
                      {item.text}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name='Reward'
                      label='Reward'
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {dropdownReward.isLoading && (
                              <CircularProgress color='inherit' size={20} />
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                />
                {rewardType === 'Voucher' && (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Autocomplete
                        key={'Voucher-' + randomKey}
                        sx={{ width: '70%', marginBottom: '10px', marginRight: '5px' }}
                        options={voucherDropdown.data || []}
                        getOptionLabel={(list) => list.name}
                        isOptionEqualToValue={(option, value) =>
                          option && value ? option.id == value.id : false
                        }
                        onOpen={() => getVoucherListDropdown()}
                        onChange={(_, vouc) => {
                          if (vouc) {
                            // const newVal = [vouc]
                            setListTempVoucher(vouc)
                            setValVoucher(vouc.name)
                          }
                        }}
                        ListboxProps={GlobalStyle.ListBox}
                        value={
                          voucherDropdown &&
                          voucherDropdown.data &&
                          voucherDropdown.data.find((e) => e.name == valVoucher)
                        }
                        renderOption={(props, item) => (
                          <li {...props} key={item.id}>
                            {item.name}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name='voucherId'
                            label='Voucher'
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {voucherDropdown.isLoading && (
                                    <CircularProgress color='inherit' size={20} />
                                  )}
                                  {params.InputProps.endAdornment}
                                </>
                              )
                            }}
                          />
                        )}
                      />
                      <TextField
                        inputProps={{ min: '0' }}
                        sx={{ width: '30%' }}
                        id='longitude'
                        variant='outlined'
                        label='Qty'
                        type='number'
                        value={qty}
                        onChange={(e) => {
                          setQty(parseInt(e.target.value))
                        }}
                      />
                      <Button
                        sx={{ height: '56px', marginLeft: '5px' }}
                        variant='contained'
                        onClick={onAddChargerPoint}
                        disabled={isEmpty(listTempVoucher && qty?.toString())}
                      >
                        Add
                      </Button>
                    </Box>
                    <TableContainer
                      component={Paper}
                      sx={{ maxHeight: 512, marginBottom: '10px' }}
                      variant='outlined'
                    >
                      <Table aria-label='Data Table' stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell>Voucher</TableCell>
                            <TableCell>Qty</TableCell>
                            <TableCell>Actions</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {tableVoucher.map((table, index) => (
                            <TableRow key={index}>
                              <TableCell>{table.name}</TableCell>
                              <TableCell>{table.qty}</TableCell>

                              <TableCell>
                                <IconButton
                                  onClick={() => onDelTableChargerPoint(table.id)}
                                  sx={{ color: 'red' }}
                                >
                                  <Icon icon='Delete' />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TextField
                      inputProps={{ min: '0' }}
                      id='quantity'
                      variant='outlined'
                      {...DialogStyle.textField}
                      label='Quantity'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.quantity}
                      error={
                        formik.touched && formik.touched.quantity && Boolean(formik.errors.quantity)
                      }
                      type='number'
                      helperText={
                        formik.touched &&
                        formik.touched.quantity &&
                        formik.errors &&
                        formik.errors.quantity
                      }
                      fullWidth
                    />
                    <TextField
                      inputProps={{ min: '0' }}
                      id='codeLength'
                      variant='outlined'
                      {...DialogStyle.textField}
                      label='Code Length'
                      type='number'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.codeLength}
                      error={
                        formik.touched &&
                        formik.touched.codeLength &&
                        Boolean(formik.errors.codeLength)
                      }
                      helperText={
                        formik.touched &&
                        formik.touched.codeLength &&
                        formik.errors &&
                        formik.errors.codeLength
                      }
                      fullWidth
                    />
                    <TextField
                      inputProps={{ min: '0' }}
                      id='email'
                      variant='outlined'
                      {...DialogStyle.textField}
                      label='Maximal Redemption Count(max token distributed for this campaign)'
                      fullWidth
                      value={payload.maxRedemption}
                      onChange={(e) =>
                        setPayload({ ...payload, maxRedemption: parseInt(e.target.value) })
                      }
                      type='number'
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                      <DatePicker
                        value={date.validFrom}
                        onChange={(e) => {
                          const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                          formik.setFieldValue('validFrom', newDate)
                          setDate((prevState) => ({ ...prevState, validFrom: e }))
                        }}
                        label='Valid From'
                        format='DD-MM-YYYY'
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                      <DatePicker
                        value={date.validUntil}
                        minDate={dayjs(formik.values.validFrom)}
                        onChange={(e) => {
                          const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                          formik.setFieldValue('validTo', newDate)
                          setDate((prevState) => ({ ...prevState, validUntil: e }))
                        }}
                        label='Valid Until'
                        format='DD-MM-YYYY'
                      />
                    </LocalizationProvider>
                  </>
                )}

                {rewardType === 'Coin' && (
                  <>
                    <TextField
                      inputProps={{ min: '0' }}
                      {...DialogStyle.textField}
                      id='coin'
                      variant='outlined'
                      label='Coint Amount'
                      value={payload.coinsAmount}
                      onChange={(e) =>
                        setPayload({ ...payload, coinsAmount: parseInt(e.target.value) })
                      }
                      type='number'
                      fullWidth
                    />
                    <TextField
                      inputProps={{ min: '0' }}
                      id='codeLength'
                      variant='outlined'
                      {...DialogStyle.textField}
                      label='Code Length'
                      type='number'
                      onChange={(e) => formik.setFieldValue('codeLength', e.target.value)}
                      onBlur={formik.handleBlur}
                      value={formik.values.codeLength}
                      error={
                        formik.touched &&
                        formik.touched.codeLength &&
                        Boolean(formik.errors.codeLength)
                      }
                      helperText={
                        formik.touched &&
                        formik.touched.codeLength &&
                        formik.errors &&
                        formik.errors.codeLength
                      }
                      fullWidth
                    />
                    <TextField
                      inputProps={{ min: '0' }}
                      id='quantity'
                      variant='outlined'
                      {...DialogStyle.textField}
                      label='Quantity'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.quantity}
                      error={
                        formik.touched && formik.touched.quantity && Boolean(formik.errors.quantity)
                      }
                      type='number'
                      helperText={
                        formik.touched &&
                        formik.touched.quantity &&
                        formik.errors &&
                        formik.errors.quantity
                      }
                      fullWidth
                    />
                    <Autocomplete
                      fullWidth
                      multiple
                      id='fixed-tags-demo'
                      value={userType}
                      onOpen={() => getMemberTypeDropdown()}
                      isOptionEqualToValue={(option, value) =>
                        option && value ? option.name == value.name : false
                      }
                      onChange={(event, newValue) => {
                        setUserType(newValue)
                      }}
                      options={(memberType && memberType.data) || []}
                      getOptionLabel={(option) => option.name}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip label={option.name} {...getTagProps({ index })} key={index} />
                        ))
                      }
                      renderInput={(params) => <TextField {...params} label='User Type' />}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                      <DatePicker
                        value={date.minDateSignUp}
                        onChange={(e) => {
                          const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                          setPayloadDate({ ...payloadDate, minSignUpDate: newDate })
                          setDate((prevState) => ({ ...prevState, minDateSignUp: e }))
                        }}
                        label='Minimum Sign Up Date'
                        format='DD-MM-YYYY'
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                      <DatePicker
                        value={date.maxDateSignUp}
                        minDate={dayjs(payloadDate.minSignUpDate)}
                        onChange={(e) => {
                          const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                          setPayloadDate({ ...payloadDate, maxSignUpDate: newDate })
                          setDate((prevState) => ({ ...prevState, maxDateSignUp: e }))
                        }}
                        label='Maximum Sign Up Date'
                        format='DD-MM-YYYY'
                      />
                    </LocalizationProvider>
                  </>
                )}
              </Box>
              <Box sx={{ display: 'flex', flex: 1, gap: 2, flexDirection: 'column' }}>
                {rewardType === 'Coin' && (
                  <>
                    <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                      <DatePicker
                        value={date.validFrom}
                        onChange={(e) => {
                          const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                          formik.setFieldValue('validFrom', newDate)
                          setDate((prevState) => ({ ...prevState, validFrom: e }))
                        }}
                        label='Valid From'
                        format='DD-MM-YYYY'
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                      <DatePicker
                        value={date.validUntil}
                        minDate={dayjs(formik.values.validFrom)}
                        onChange={(e) => {
                          const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                          formik.setFieldValue('validTo', newDate)
                          setDate((prevState) => ({ ...prevState, validUntil: e }))
                        }}
                        label='Valid Until'
                        format='DD-MM-YYYY'
                      />
                    </LocalizationProvider>
                  </>
                )}
                {rewardType === 'Voucher' && (
                  <>
                    <Autocomplete
                      fullWidth
                      sx={{ marginBottom: '10px' }}
                      multiple
                      id='fixed-tags-demo'
                      value={userType}
                      onOpen={() => getMemberTypeDropdown()}
                      isOptionEqualToValue={(option, value) =>
                        option && value ? option.name == value.name : false
                      }
                      onChange={(event, newValue) => {
                        setUserType(newValue)
                      }}
                      options={(memberType && memberType.data) || []}
                      getOptionLabel={(option) => option.name}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip label={option.name} {...getTagProps({ index })} key={index} />
                        ))
                      }
                      renderInput={(params) => <TextField {...params} label='User Type' />}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                      <DatePicker
                        value={date.minDateSignUp}
                        onChange={(e) => {
                          const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                          setPayloadDate({ ...payloadDate, minSignUpDate: newDate })
                          setDate((prevState) => ({ ...prevState, minDateSignUp: e }))
                        }}
                        label='Minimum Sign Up Date'
                        format='DD-MM-YYYY'
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                      <DatePicker
                        value={date.maxDateSignUp}
                        minDate={dayjs(payloadDate.minSignUpDate)}
                        onChange={(e) => {
                          const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                          setPayloadDate({ ...payloadDate, maxSignUpDate: newDate })
                          setDate((prevState) => ({ ...prevState, maxDateSignUp: e }))
                        }}
                        label='Maximum Sign Up Date'
                        format='DD-MM-YYYY'
                      />
                    </LocalizationProvider>
                  </>
                )}
                <TextField
                  id='messageTitle'
                  variant='outlined'
                  label='Message Title'
                  {...DialogStyle.textField}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.messageTitle}
                  error={
                    formik.touched &&
                    formik.touched.messageTitle &&
                    Boolean(formik.errors.messageTitle)
                  }
                  helperText={
                    formik.touched &&
                    formik.touched.messageTitle &&
                    formik.errors &&
                    formik.errors.messageTitle
                  }
                  fullWidth
                />
                <DescMessage />
                <TextField
                  id='messageBody'
                  variant='outlined'
                  label='Message Body'
                  {...DialogStyle.textField}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.messageBody}
                  error={
                    formik.touched &&
                    formik.touched.messageBody &&
                    Boolean(formik.errors.messageBody)
                  }
                  helperText={
                    formik.touched &&
                    formik.touched.messageBody &&
                    formik.errors &&
                    formik.errors.messageBody
                  }
                  fullWidth
                />
                <DescMessage />
              </Box>
            </Box>
          </Container>
        </FormikProvider>
      </Dialog>
      <Notification
        open={!create.isLoading && !create.isUninitialized}
        onClose={() => (create.isError ? create.reset() : location.reload())}
        isError={Boolean(create.error) && create.isError}
        message={GLOBAL.returnExceptionMessage(create.isError, create.error as ErrorProps)}
      />
    </>
  )
}

export default Create
