import ReactQuill from 'react-quill'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Grid,
  TextField,
  Typography
} from '@mui/material'
import { decode } from 'html-entities'
import { ExpandMoreOutlined } from '@mui/icons-material'
import Drawer from 'components/drawer/detail'
import Loading from 'components/loading/loading'
import { useGetTermsAndConditionDetailQuery } from 'store/terms'
import type { PartialTermsDetailDataProps } from 'modules/partial'
import type { DetailStateProps } from 'modules/types'
import DrawerStyle from './style'
import 'react-quill/dist/quill.snow.css'

const Detail = ({ open, id, onClose }: DetailStateProps & { id: string }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const { data, isFetching, isLoading } = useGetTermsAndConditionDetailQuery({ key: id })

  const lang = ['EN-US', 'ID']

  const gridDataLabel = ['Language']
  const gridDataValue = ['language']

  const gridBasicLabel = ['Created By', 'Created At', 'Modified By', 'Modified At']
  const gridBasicValue = ['createdBy', 'createdAt', 'modifiedBy', 'modifiedAt']

  const list = data && data.data
  const key = data && data.key

  return (
    <Drawer open={open} title='Detail Terms and Condition' onClose={onClose}>
      <Container {...DrawerStyle.Container}>
        {(isLoading || isFetching) && !data && <Loading />}
        {data && (
          <>
            <TextField
              id='key'
              variant='outlined'
              label='Terms and Condition Name'
              value={key}
              inputProps={{ readOnly: true }}
              fullWidth
            />
            {lang.map((_, index) => (
              <>
                <Grid container spacing={2}>
                  {gridDataLabel.map((title, id) => (
                    <Grid key={id} item xs={12}>
                      <TextField
                        variant='outlined'
                        label={title}
                        value={
                          (list &&
                            list[index][gridDataValue[id] as keyof PartialTermsDetailDataProps]) ||
                          '-'
                        }
                        inputProps={{ readOnly: true }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        fullWidth
                      />
                    </Grid>
                  ))}
                </Grid>
                <ReactQuill
                  theme='snow'
                  value={list && decode(list[index].body)}
                  style={{ marginBottom: '2rem' }}
                  readOnly={true}
                />
                <Accordion elevation={0} disableGutters>
                  <AccordionSummary
                    expandIcon={<ExpandMoreOutlined />}
                    aria-controls='basic-information'
                  >
                    <Typography>Basic Information</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      {gridBasicLabel.map((title, id) => (
                        <Grid key={id} item xs={12}>
                          <TextField
                            variant='outlined'
                            label={title}
                            value={
                              (list &&
                                list[index][
                                  gridBasicValue[id] as keyof PartialTermsDetailDataProps
                                ]) ||
                              '-'
                            }
                            inputProps={{ readOnly: true }}
                            InputLabelProps={{
                              shrink: true
                            }}
                            fullWidth
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </>
            ))}
          </>
        )}
      </Container>
    </Drawer>
  )
}

export default Detail
