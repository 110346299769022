import { useEffect, useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import { isEmpty } from 'lodash'
import {
  Autocomplete,
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import * as yup from 'yup'
import Dialog from 'components/dialog/dialog'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import type { PartialEvChargerBoxDropdownProps, PartialCreateQrCode } from 'modules/partial'
import {
  useGetChargerBoxDropdownMutation,
  useGetChargerBoxGroupDropdownMutation,
  useGetChargerPointDropdownMutation
} from 'store/dropdown'
import { useCreateQrCodeMutation, useGetDetailQrCodeQuery } from 'store/qrcode'
import BackdropImage from 'components/backdropImage/backdropImage'
import DialogStyle from './style'

const Edit = ({ open, id, onClose }: DetailStateProps & { id: number }) => {
  if (!open) return <></>
  const { data, isFetching, isLoading, isSuccess } = useGetDetailQrCodeQuery(id)
  const [createQrCode, create] = useCreateQrCodeMutation()
  const [getChargerPointDropdown, chargerPoint] = useGetChargerPointDropdownMutation()
  const [getChargerBoxGroupDropdown, chargerBoxGroup] = useGetChargerBoxGroupDropdownMutation()
  const [getChargerBoxDropdown, chargerBox] = useGetChargerBoxDropdownMutation()
  const [isChoice, setIsChoice] = useState<boolean>(true)
  const [previewImage, setPreviewImage] = useState<string>('')
  const [previewBackdrop, setPreviewBackdrop] = useState<boolean>(false)
  // const [boxGroup, setBoxGroup] = useState<string>('')
  // const [box, setBox] = useState<string>('')

  const payload: PartialEvChargerBoxDropdownProps = {
    Start: 0,
    Length: 1000
  }

  const getDropdown = async () => {
    await getChargerPointDropdown(payload)
    await getChargerBoxGroupDropdown({
      id: (data && data.chargePointId.toString()) || '',
      Start: payload.Start,
      Length: payload.Length
    })
    await getChargerBoxDropdown({
      id: (data && data.chargeBoxGroupId.toString()) || '',
      Start: payload.Start,
      Length: payload.Length
    })
  }

  useEffect(() => {
    if (isSuccess) {
      setPreviewImage((data && data.imageUrlQrCode) || '')
      getDropdown()
    }
  }, [isSuccess, data, isLoading])

  const scheme = yup.object<PartialCreateQrCode>({
    chargerPointId: yup.number().required('Policy Name is required'),
    chargerBoxGroupId: yup.number().required('Policy Name is required'),
    chargerBoxId: yup.number().required('Policy Name is required'),
    file: yup.mixed().required('Policy Name is required')
  })

  const formik = useFormik<PartialCreateQrCode>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      chargerPointId: (data && data.chargePointId.toString()) || '',
      chargerBoxGroupId: (data && data.chargeBoxGroupId.toString()) || '',
      chargerBoxId: (data && data.id.toString()) || '',
      file: (data && data.imageUrlQrCode) || ''
    },
    onSubmit: (values: PartialCreateQrCode) => handleSubmit(values)
  })

  const handleSubmit = (e: PartialCreateQrCode) => {
    const formData = new FormData()
    formData.append('file', e.file)
    createQrCode({ id: e.chargerBoxId.toString(), body: formData })
  }
  return (
    <>
      <Dialog
        open={open}
        title='Update QR Code'
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          {(isLoading || isFetching) && !data && <Loading />}
          {data && (
            <>
              <FormikProvider value={formik}>
                <FormControl fullWidth>
                  <InputLabel id='chargerPointId'>Charger Point</InputLabel>
                  <Select
                    labelId='chargerPointId'
                    id='chargerPointId'
                    value={formik.values.chargerPointId ?? '-'}
                    label='Visibility'
                    onChange={(event) => {
                      formik.setFieldValue('chargerPointId', event.target.value.toString())
                      formik.setFieldValue('chargerBoxGroupId', '')
                      formik.setFieldValue('chargerBoxId', '')
                      setIsChoice(false)
                    }}
                  >
                    {chargerPoint &&
                      chargerPoint.isSuccess &&
                      chargerPoint.data?.map((item, index) => (
                        <MenuItem key={index} value={item.value}>
                          {item.text}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                {isChoice ? (
                  <FormControl fullWidth>
                    <InputLabel id='IsDefault'>Charger Box Group</InputLabel>
                    <Select
                      labelId='IsDefault'
                      id='IsDefault'
                      value={formik.values.chargerBoxGroupId ?? '-'}
                      label='Visibility'
                      onChange={(event) => {
                        formik.setFieldValue('chargerBoxGroupId', event.target.value.toString())
                      }}
                    >
                      {chargerBoxGroup &&
                        chargerBoxGroup.isSuccess &&
                        chargerBoxGroup.data?.map((item, index) => (
                          <MenuItem key={index} value={item.value}>
                            {item.text}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                ) : (
                  <Autocomplete
                    fullWidth
                    options={(chargerBoxGroup && chargerBoxGroup.data) || []}
                    getOptionLabel={(option) => option.text}
                    isOptionEqualToValue={(option, value) =>
                      option && value ? option.value == value.value : false
                    }
                    onOpen={() =>
                      getChargerBoxGroupDropdown({
                        id: formik.values.chargerPointId,
                        Start: payload.Start,
                        Length: payload.Length
                      })
                    }
                    onChange={(_, chargerBoxGroupId) => {
                      if (chargerBoxGroupId) {
                        formik.setFieldValue(
                          'chargerBoxGroupId',
                          chargerBoxGroupId.value.toString()
                        )
                      }
                    }}
                    value={
                      (chargerBoxGroup &&
                        chargerBoxGroup.data &&
                        chargerBoxGroup.data.find(
                          (e) => String(e.value) == String(formik.values.chargerBoxGroupId)
                        )) ||
                      undefined
                    }
                    disabled={isEmpty(formik.values.chargerPointId)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name='Charger Box'
                        label='Charger Box Group'
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {chargerBoxGroup.isLoading && (
                                <CircularProgress color='inherit' size={20} />
                              )}
                              {params.InputProps.endAdornment}
                            </>
                          )
                        }}
                        error={
                          formik.touched &&
                          formik.touched.chargerBoxGroupId &&
                          Boolean(formik.errors.chargerBoxGroupId)
                        }
                        helperText={
                          formik.touched &&
                          formik.touched.chargerBoxGroupId &&
                          formik.errors &&
                          formik.errors.chargerBoxGroupId
                        }
                      />
                    )}
                  />
                )}
                {isChoice ? (
                  <FormControl fullWidth>
                    <InputLabel id='chargerBoxId'>Charger Box</InputLabel>
                    <Select
                      labelId='chargerBoxId'
                      id='chargerBoxId'
                      value={formik.values.chargerBoxId ?? '-'}
                      label='Visibility'
                      onChange={(event) => {
                        formik.setFieldValue('chargerBoxId', event.target.value)
                      }}
                    >
                      {chargerBox &&
                        chargerBox.isSuccess &&
                        chargerBox.data?.map((item, index) => (
                          <MenuItem key={index} value={item.value}>
                            {item.text}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                ) : (
                  <Autocomplete
                    fullWidth
                    options={(chargerBox && chargerBox.data) || []}
                    getOptionLabel={(option) => option.text}
                    isOptionEqualToValue={(option, value) =>
                      option && value ? option.value == value.value : false
                    }
                    onOpen={() =>
                      getChargerBoxDropdown({
                        id: formik.values.chargerBoxGroupId,
                        Start: payload.Start,
                        Length: payload.Length
                      })
                    }
                    onChange={(_, chargerBox) => {
                      if (chargerBox) {
                        formik.setFieldValue('chargerBoxId', chargerBox.value.toString())
                      }
                    }}
                    value={
                      (chargerBox &&
                        chargerBox.data &&
                        chargerBox.data.find(
                          (e) => String(e.value) == String(formik.values.chargerBoxId)
                        )) ||
                      undefined
                    }
                    disabled={isEmpty(formik.values.chargerBoxGroupId)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name='Charger Box'
                        label='Charger Box'
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {chargerBox.isLoading && (
                                <CircularProgress color='inherit' size={20} />
                              )}
                              {params.InputProps.endAdornment}
                            </>
                          )
                        }}
                        error={
                          formik.touched &&
                          formik.touched.chargerBoxId &&
                          Boolean(formik.errors.chargerBoxId)
                        }
                        helperText={
                          formik.touched &&
                          formik.touched.chargerBoxId &&
                          formik.errors &&
                          formik.errors.chargerBoxId
                        }
                      />
                    )}
                  />
                )}

                <div
                  style={{
                    height: '300px',
                    border: '1px solid #eee',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  {!previewImage && (
                    <Typography style={{ color: '#cfcfcf' }}>
                      QR Code image Cannot be Empty
                    </Typography>
                  )}
                  {previewImage && (
                    <BackdropImage
                      open={previewBackdrop}
                      onOpen={() => setPreviewBackdrop(true)}
                      onClose={() => setPreviewBackdrop(false)}
                    >
                      <img
                        src={previewImage}
                        alt=''
                        style={{ height: '300px', width: '505px', objectFit: 'contain' }}
                      />
                    </BackdropImage>
                  )}
                </div>
                <TextField
                  required
                  id='file'
                  label='QR Code Image'
                  type='file'
                  placeholder='QR code Image'
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ accept: 'image/png, image/gif, image/jpeg', shrink: true }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const files = e && e.target && e.target.files
                    if (files && files.length > 0 && !isEmpty(files)) {
                      formik.setFieldValue('file', files[0])
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      const reader: any = new FileReader()
                      reader.onload = () => {
                        setPreviewImage(reader.result)
                      }
                      reader.readAsDataURL(files[0])
                    } else {
                      setPreviewImage('')
                    }
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched && formik.touched.file && Boolean(formik.errors.file)}
                  helperText={
                    formik.touched && formik.touched.file && formik.errors && formik.errors.file
                  }
                  fullWidth
                />
              </FormikProvider>
            </>
          )}
        </Container>
      </Dialog>

      <Notification
        open={!create.isLoading && !create.isUninitialized}
        onClose={() => (create.isError ? create.reset() : location.reload())}
        isError={Boolean(create.error) && create.isError}
        message={GLOBAL.returnExceptionMessage(create.isError, create.error as ErrorProps)}
      />
    </>
  )
}

export default Edit
