import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { Container, TextField } from '@mui/material'
import Dialog from 'components/dialog/dialog'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { PartialCreateEventLocationProps } from 'modules/partial'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import { useEditEventLocationMutation, useGetDetailEventLocationQuery } from 'store/event'
import DialogStyle from './style'

const Edit = ({ open, id, onClose }: DetailStateProps & { id: string }) => {
  if (!open) return <></>
  const { data, isFetching, isLoading } = useGetDetailEventLocationQuery({ id })
  const [editEventLocation, edit] = useEditEventLocationMutation()

  const scheme = yup.object<PartialCreateEventLocationProps>({
    EventName: yup.string().required('Event Name is required'),
    Address: yup.string().required('Address is required'),
    City: yup.string().required('City is required'),
    District: yup.string().required('District is required'),
    PostalCode: yup.string().required('Postal Code is required'),
    Latitude: yup.number().required('Latitude is required'),
    Longitude: yup.number().required('Longitude is required'),
    GoogleMapsUrl: yup.string().required('Google Maps URL is required')
  })

  const formik = useFormik<PartialCreateEventLocationProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      id: id,
      EventName: (data && data.eventName) || '',
      Address: (data && data.address) || '',
      City: (data && data.city) || '',
      District: (data && data.district) || '',
      PostalCode: (data && data.postalCode) || '',
      Latitude: (data && data.latitude) || null,
      Longitude: (data && data.longitude) || null,
      GoogleMapsUrl: (data && data.googleMapsUrl) || ''
    },
    onSubmit: (values: PartialCreateEventLocationProps) => editEventLocation(values)
  })

  return (
    <>
      <Dialog
        open={open}
        title='Update Event Location'
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          {(isLoading || isFetching) && !data && <Loading />}
          {data && (
            <>
              <FormikProvider value={formik}>
                <TextField
                  id='EventName'
                  variant='outlined'
                  label='Event Name'
                  onChange={formik.handleChange}
                  value={formik.values.EventName}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched && formik.touched.EventName && Boolean(formik.errors.EventName)
                  }
                  helperText={
                    formik.touched &&
                    formik.touched.EventName &&
                    formik.errors &&
                    formik.errors.EventName
                  }
                  fullWidth
                />
                <TextField
                  id='Address'
                  variant='outlined'
                  label='Address'
                  onChange={formik.handleChange}
                  value={formik.values.Address}
                  onBlur={formik.handleBlur}
                  error={formik.touched && formik.touched.Address && Boolean(formik.errors.Address)}
                  helperText={
                    formik.touched &&
                    formik.touched.Address &&
                    formik.errors &&
                    formik.errors.Address
                  }
                  fullWidth
                />
                <TextField
                  id='City'
                  variant='outlined'
                  label='City'
                  onChange={formik.handleChange}
                  value={formik.values.City}
                  onBlur={formik.handleBlur}
                  error={formik.touched && formik.touched.City && Boolean(formik.errors.City)}
                  helperText={
                    formik.touched && formik.touched.City && formik.errors && formik.errors.City
                  }
                  fullWidth
                />
                <TextField
                  id='District'
                  variant='outlined'
                  label='District'
                  onChange={formik.handleChange}
                  value={formik.values.District}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched && formik.touched.District && Boolean(formik.errors.District)
                  }
                  helperText={
                    formik.touched &&
                    formik.touched.District &&
                    formik.errors &&
                    formik.errors.District
                  }
                  fullWidth
                />
                <TextField
                  id='PostalCode'
                  variant='outlined'
                  label='Postal Code'
                  onChange={formik.handleChange}
                  value={formik.values.PostalCode}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched && formik.touched.PostalCode && Boolean(formik.errors.PostalCode)
                  }
                  helperText={
                    formik.touched &&
                    formik.touched.PostalCode &&
                    formik.errors &&
                    formik.errors.PostalCode
                  }
                  fullWidth
                />
                <TextField
                  id='Latitude'
                  variant='outlined'
                  label='Latitude'
                  onChange={formik.handleChange}
                  value={formik.values.Latitude}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched && formik.touched.Latitude && Boolean(formik.errors.Latitude)
                  }
                  helperText={
                    formik.touched &&
                    formik.touched.Latitude &&
                    formik.errors &&
                    formik.errors.Latitude
                  }
                  fullWidth
                  type='number'
                />
                <TextField
                  id='Longitude'
                  variant='outlined'
                  label='Longitude'
                  onChange={formik.handleChange}
                  value={formik.values.Longitude}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched && formik.touched.Longitude && Boolean(formik.errors.Longitude)
                  }
                  helperText={
                    formik.touched &&
                    formik.touched.Longitude &&
                    formik.errors &&
                    formik.errors.Longitude
                  }
                  fullWidth
                  type='number'
                />
                <TextField
                  id='GoogleMapsUrl'
                  variant='outlined'
                  label='GoogleMapsUrl'
                  onChange={formik.handleChange}
                  value={formik.values.GoogleMapsUrl}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched &&
                    formik.touched.GoogleMapsUrl &&
                    Boolean(formik.errors.GoogleMapsUrl)
                  }
                  helperText={
                    formik.touched &&
                    formik.touched.GoogleMapsUrl &&
                    formik.errors &&
                    formik.errors.GoogleMapsUrl
                  }
                  fullWidth
                />
              </FormikProvider>
            </>
          )}
        </Container>
      </Dialog>

      <Notification
        open={!edit.isLoading && !edit.isUninitialized}
        onClose={() => (edit.isError ? edit.reset() : location.reload())}
        isError={Boolean(edit.error) && edit.isError}
        message={GLOBAL.returnExceptionMessage(edit.isError, edit.error as ErrorProps)}
      />
    </>
  )
}

export default Edit
