import CONSTANT from 'modules/constant'
import type {
  DropdownListProps,
  PaginationProps,
  PromoCodeBatcSubListProps,
  PromoCodeBatchDetaiProps,
  PromoCodeBatchListProps,
  PromoCodeQrCode,
  ResultProps
} from 'modules/types'
import type {
  PartialPromoCodeBatchCreateProps,
  PartialPromoCodeSubProps,
  PromoCodeQrCodePartial
} from 'modules/partial'
import { API, download, headers } from './api'

const PromoCodeBatchAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getPromoCodeBatchList: builder.mutation<PaginationProps<PromoCodeBatchListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_PROMO_CODE_BATCH_LIST,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    downloadPromoCodeBatch: builder.mutation<PaginationProps<PromoCodeBatchListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_PROMO_CODE_BATCH_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        responseHandler: download,
        body: body,
        formData: true
      })
    }),
    deletePromoCodeBatch: builder.mutation<ResultProps, number>({
      query: (id) => ({
        url: CONSTANT.URL_PROMO_CODE_BATCH_DELETE + id,
        method: 'DELETE',
        headers: headers()
      })
    }),
    getDetailPromoCodeBatch: builder.query<PromoCodeBatchDetaiProps, number>({
      query: (id) => ({
        url: CONSTANT.URL_PROMO_CODE_BATCH_DETAIL + id,
        method: 'GET',
        headers: headers()
      })
    }),
    dropDownPromoCodeBatchStatus: builder.mutation<DropdownListProps[], void>({
      query: () => ({
        url: CONSTANT.URL_PROMO_CODE_BATCH_DROPDOWN_STATUS,
        method: 'GET',
        headers: headers()
      })
    }),
    dropDownPromoCodeBatchReward: builder.mutation<DropdownListProps[], void>({
      query: () => ({
        url: CONSTANT.URL_PROMO_CODE_BATCH_DROPDOWN_REWARD,
        method: 'GET',
        headers: headers()
      })
    }),
    createPromoCodeBatch: builder.mutation<ResultProps, PartialPromoCodeBatchCreateProps>({
      query: (body) => ({
        url: CONSTANT.URL_PROMO_CODE_BATCH_CREATE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    updatePromoCodeBatch: builder.mutation<
      ResultProps,
      { body: PartialPromoCodeBatchCreateProps; id: number }
    >({
      query: (param) => ({
        url: CONSTANT.URL_PROMO_CODE_BATCH_UPDATE + param.id,
        method: 'PUT',
        headers: headers(),
        body: param.body
      })
    }),
    getPromoCodeBatchSubList: builder.mutation<
      PaginationProps<PromoCodeBatcSubListProps>,
      FormData
    >({
      query: (body) => ({
        url: CONSTANT.URL_PROMO_CODE_BATCH_SUB_LIST,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    downloadPromoCodeBatchSub: builder.mutation<PaginationProps<PromoCodeBatchListProps>, FormData>(
      {
        query: (body) => ({
          url: CONSTANT.URL_PROMO_CODE_BATCH_SUB_DOWNLOAD,
          method: 'POST',
          headers: headers({ useContentType: false }),
          responseHandler: download,
          body: body,
          formData: true
        })
      }
    ),
    deleteMultiplesPromoCodeSub: builder.mutation<ResultProps, PartialPromoCodeSubProps>({
      query: (body) => ({
        url: CONSTANT.URL_PROMO_CODE_BATCH_SUB_DELETE,
        method: 'DELETE',
        headers: headers(),
        body: body
      })
    }),
    getDetailPromoCodeBatchSub: builder.query<PromoCodeBatchDetaiProps, number>({
      query: (id) => ({
        url: CONSTANT.URL_PROMO_CODE_BATCH_SUB_DETAIL + id,
        method: 'GET',
        headers: headers()
      })
    }),
    updatePromoCodeBatchSubValidity: builder.mutation<ResultProps, PartialPromoCodeSubProps>({
      query: (body) => ({
        url: CONSTANT.URL_PROMO_CODE_BATCH_SUB_VALIDITY,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    getPromoCodeQrCodeBy: builder.query<PromoCodeQrCode, PromoCodeQrCodePartial>({
      query: (body) => ({
        url: CONSTANT.URL_PROMO_CODE_QR_CODE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    getPromoCodeQrCode: builder.mutation<PromoCodeQrCode, PromoCodeQrCodePartial>({
      query: (body) => ({
        url: CONSTANT.URL_PROMO_CODE_QR_CODE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    })
  })
})

export const {
  useGetPromoCodeBatchListMutation,
  useDownloadPromoCodeBatchMutation,
  useDeletePromoCodeBatchMutation,
  useDropDownPromoCodeBatchRewardMutation,
  useDropDownPromoCodeBatchStatusMutation,
  useCreatePromoCodeBatchMutation,
  useUpdatePromoCodeBatchMutation,
  useGetPromoCodeBatchSubListMutation,
  useDownloadPromoCodeBatchSubMutation,
  useDeleteMultiplesPromoCodeSubMutation,
  useGetDetailPromoCodeBatchSubQuery,
  useUpdatePromoCodeBatchSubValidityMutation,
  useGetPromoCodeQrCodeMutation,

  useGetDetailPromoCodeBatchQuery,
  useGetPromoCodeQrCodeByQuery
} = PromoCodeBatchAPI
