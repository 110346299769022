import { useState } from 'react'
import { isEmpty } from 'lodash'
import { Container, TextField } from '@mui/material'
import Dialog from 'components/dialog/dialog'
import Notification from 'components/notification'
import { useUploadPartnerLocationMutation } from 'store/locationPartner'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import GLOBAL from 'modules/global'
import DialogStyle from './style'

const Upload = ({ open, onClose }: DetailStateProps) => {
  const [file, setFile] = useState<string | Blob>('')
  const [hasErr, setHasErr] = useState<boolean>(false)
  const [uploadPartnerLocation, upload] = useUploadPartnerLocationMutation()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e && e.target && e.target.files

    if (files && files.length > 0 && !isEmpty(files)) {
      setFile(files[0])
      setHasErr(false)
    }
  }

  const onSubmit = () => {
    if (!file) {
      setHasErr(true)
      return
    }
    const formData = new FormData()

    formData.append('payload', file)
    uploadPartnerLocation(formData).then(() =>
      setTimeout(() => {
        onClose()
      }, 1000)
    )
  }

  return (
    <>
      <Dialog
        open={open}
        title='Upload Partner Location'
        onCancel={onClose}
        onSubmit={onSubmit}
        loading={upload.isLoading}
        isDisabled={!file.toString().length}
      >
        <Container {...DialogStyle.Container}>
          <TextField
            id='File'
            variant='standard'
            label='File'
            inputProps={{
              accept:
                '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
            }}
            type='file'
            onChange={onChangeFile}
            error={hasErr && !file}
            helperText={hasErr && !file && 'file Cannot be empty'}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
        </Container>
      </Dialog>

      <Notification
        open={!upload.isLoading && !upload.isUninitialized}
        onClose={() => (upload.isError ? upload.reset() : location.reload())}
        isError={Boolean(upload.error) && upload.isError}
        message={GLOBAL.returnExceptionMessage(upload.isError, upload.error as ErrorProps)}
      />
    </>
  )
}

export default Upload
