import CONSTANT from 'modules/constant'
import type {
  DropdownCarEVListProps,
  DropdownListProps,
  DropdownUserListProps,
  EvChargeBoxGroupProps,
  GenerateCodeProps,
  PaginationProps,
  ResultProps
} from 'modules/types'
import type { PartialChargeBoxGroupProps, PartialDropdownListProps } from 'modules/partial'
import { API, download, headers } from './api'

const EvChargeBoxGroupAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getChargeBoxGroupList: builder.mutation<PaginationProps<EvChargeBoxGroupProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_EV_CHARGE_BOX_GROUP_LIST,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    getChargeBoxGroupDropdownList: builder.mutation<DropdownListProps[], PartialDropdownListProps>({
      query: (query) => ({
        url:
          CONSTANT.URL_EV_CHARGE_BOX_GROUP_DROPDOWN +
          `${query.id}/ddl` +
          `?start=${query.start}&length=${query.length}`,
        method: 'GET',
        headers: headers()
      })
    }),
    getChargeBoxGroupDropdownListBy: builder.query<DropdownListProps[], PartialDropdownListProps>({
      query: (query) => ({
        url:
          CONSTANT.URL_EV_CHARGE_BOX_GROUP_DROPDOWN +
          `${query.id}/ddl` +
          `?start=${query.start}&length=${query.length}`,
        method: 'GET',
        headers: headers()
      })
    }),
    downloadChargeBoxGroupList: builder.mutation<PaginationProps<EvChargeBoxGroupProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_EV_CHARGE_BOX_GROUP_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true,
        responseHandler: download
      })
    }),
    getChargeBoxGroupDetail: builder.query<EvChargeBoxGroupProps, string | number>({
      query: (id) => ({
        url: CONSTANT.URL_EV_CHARGE_BOX_GROUP_DETAIL + id,
        method: 'GET',
        headers: headers()
      })
    }),
    createChargeBoxGroup: builder.mutation<
      ResultProps,
      { body: PartialChargeBoxGroupProps; id: number }
    >({
      query: (query) => ({
        url: CONSTANT.URL_EV_CHARGE_BOX_GROUP_CREATE + query.id,
        method: 'POST',
        headers: headers(),
        body: query.body
      })
    }),
    updateChargeBoxGroup: builder.mutation<
      ResultProps,
      { body: PartialChargeBoxGroupProps; id: number }
    >({
      query: (query) => ({
        url: CONSTANT.URL_EV_CHARGE_BOX_GROUP_UPDATE + query.id,
        method: 'PUT',
        headers: headers(),
        body: query.body
      })
    }),
    deleteChargeBoxGroup: builder.mutation<ResultProps, number>({
      query: (id) => ({
        url: CONSTANT.URL_EV_CHARGE_BOX_GROUP_DELETE + id,
        method: 'DELETE',
        headers: headers()
      })
    }),
    generateCodeChargeBoxGroup: builder.query<GenerateCodeProps, void>({
      query: () => ({
        url: CONSTANT.URL_EV_CHARGE_BOX_GROUP_CODE,
        method: 'GET',
        headers: headers()
      })
    }),
    getUserEV: builder.mutation<DropdownUserListProps[], void>({
      query: () => ({
        url: CONSTANT.URL_EV_CHARGE_USER,
        method: 'GET',
        headers: headers()
      })
    }),
    getEVCarList: builder.mutation<DropdownCarEVListProps[], void>({
      query: () => ({
        url: CONSTANT.URL_EV_CAR_LIST,
        method: 'GET',
        headers: headers()
      })
    })
  })
})

export const {
  useGetChargeBoxGroupListMutation,
  useGetChargeBoxGroupDropdownListMutation,
  useGetChargeBoxGroupDropdownListByQuery,
  useDownloadChargeBoxGroupListMutation,
  useCreateChargeBoxGroupMutation,
  useGetChargeBoxGroupDetailQuery,
  useUpdateChargeBoxGroupMutation,
  useDeleteChargeBoxGroupMutation,
  useGenerateCodeChargeBoxGroupQuery,
  useGetUserEVMutation,
  useGetEVCarListMutation
} = EvChargeBoxGroupAPI
