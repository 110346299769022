import { useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Autocomplete,
  Box,
  CircularProgress,
  Container,
  TableCell,
  TableRow,
  TextField
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { LoadingButton } from '@mui/lab'
import Content from 'components/content/content'
import type { PartialEvHistoryPartnerOverviewProps } from 'modules/partial'
import { useGetChargeBoxGroupDropdownListMutation } from 'store/evChargeBoxGroup'
import { useGetChargePointDropdownListMutation } from 'store/evChargePoint'
import { useGetEvHistoryPartnerSummaryMutation } from 'store/evHistoryPartner'
import Loading from 'components/loading/loading'
import CONSTANT from 'modules/constant'
import GlobalStyle from 'modules/styles'
import PageStyle from './style'

const EVOverview = () => {
  const [getChargePointDropdownList, chargePointDropdown] = useGetChargePointDropdownListMutation()
  const [getChargeBoxGroupDropdownList, chargeBoxGroupDropdown] =
    useGetChargeBoxGroupDropdownListMutation()

  const [getEvHistoryPartnerSummary, summary] = useGetEvHistoryPartnerSummaryMutation()

  const [filter, setFilter] = useState<PartialEvHistoryPartnerOverviewProps>({
    chargePointId: 0,
    chargeBoxGroupId: null,
    startDate: '',
    endDate: ''
  })

  const [randomKey, setRandomKey] = useState<number>(0)

  const disable = filter.chargePointId <= 0 || isEmpty(filter.startDate) || isEmpty(filter.endDate)

  const onReset = () => {
    setRandomKey(randomKey + 1)
    setFilter({ chargePointId: 0, chargeBoxGroupId: null, startDate: '', endDate: '' })
    summary.reset()
  }

  const onSubmit = () => getEvHistoryPartnerSummary(filter)

  const transactionSummaryTableHeadTitles = [
    'total Transaction',
    'Total Time',
    'total kWh',
    'Busiest Day',
    'Total Request',
    'Finished Request'
  ]

  const userSummaryTableHeadTitles = [
    'Unique User',
    'Average Time',
    'Average kWh',
    'Busiest Hour',
    'Cancelled Request',
    'Rejected Request'
  ]

  const chargeBoxSummaryTableHeadTitles = ['Occupied', 'Total', 'Available', 'Unavailable']

  const chargePointDropdownValue =
    chargePointDropdown &&
    chargePointDropdown.data &&
    chargePointDropdown.data.find((e) => e.value == filter.chargePointId)

  const chargeBoxGroupDropdownValue =
    chargeBoxGroupDropdown &&
    chargeBoxGroupDropdown.data &&
    chargeBoxGroupDropdown.data.find((e) => e.value == filter.chargeBoxGroupId)

  return (
    <>
      <Content title='Overview' useTable={false}>
        <Box {...PageStyle.Container}>
          <Box {...PageStyle.Box}>
            <Autocomplete
              key={'chargePointId-' + randomKey}
              options={(chargePointDropdown && chargePointDropdown.data) || []}
              getOptionLabel={(option) => option.text}
              isOptionEqualToValue={(option, value) =>
                option && value ? option.value == value.value : false
              }
              onOpen={() =>
                getChargePointDropdownList({
                  ...CONSTANT.DEFAULT_DROPDOWN_PAYLOAD,
                  IsPartner: 'true'
                })
              }
              onChange={(_, chargePoint) =>
                setFilter({
                  ...filter,
                  chargePointId: (chargePoint && chargePoint.value) || 0,
                  chargeBoxGroupId: (chargePoint && filter.chargeBoxGroupId) || null
                })
              }
              value={chargePointDropdownValue}
              ListboxProps={GlobalStyle.ListBox}
              renderOption={(props, item) => (
                <li {...props} key={item.value}>
                  {item.text}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='chargePointId'
                  label='Choose Charge Point'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {chargePointDropdown.isLoading && (
                          <CircularProgress color='inherit' size={20} />
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
            />
            <Autocomplete
              key={'chargeBoxGroupId-' + randomKey}
              options={(chargeBoxGroupDropdown && chargeBoxGroupDropdown.data) || []}
              getOptionLabel={(option) => option.text}
              isOptionEqualToValue={(option, value) =>
                option && value ? option.value == value.value : false
              }
              onOpen={() =>
                getChargeBoxGroupDropdownList({
                  ...CONSTANT.DEFAULT_DROPDOWN_PAYLOAD,
                  id: filter.chargePointId
                })
              }
              onChange={(_, chargeBoxGroup) =>
                setFilter({
                  ...filter,
                  chargeBoxGroupId: (chargeBoxGroup && chargeBoxGroup.value) || null
                })
              }
              value={chargeBoxGroupDropdownValue}
              disabled={filter.chargePointId <= 0}
              ListboxProps={GlobalStyle.ListBox}
              renderOption={(props, item) => (
                <li {...props} key={item.value}>
                  {item.text}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='chargeBoxGroupId'
                  label='EV Charge Box Group ID'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {chargeBoxGroupDropdown.isLoading && (
                          <CircularProgress color='inherit' size={20} />
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
            />
          </Box>
          <Box {...PageStyle.Box}>
            <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
              <DatePicker
                value={filter.startDate ? dayjs(filter.startDate) : null}
                onChange={(date: Dayjs | null) =>
                  date &&
                  setFilter({ ...filter, startDate: date.format('YYYY-MM-DD'), endDate: '' })
                }
                label='Start Date'
                format='DD-MM-YYYY'
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
              <DatePicker
                value={filter.endDate ? dayjs(filter.endDate) : null}
                minDate={dayjs(filter.startDate)}
                onChange={(date: Dayjs | null) =>
                  date && setFilter({ ...filter, endDate: date.format('YYYY-MM-DD') })
                }
                label='End Date'
                format='DD-MM-YYYY'
              />
            </LocalizationProvider>
            <Box {...PageStyle.Container}>
              <LoadingButton {...PageStyle.Button} onClick={onReset}>
                Reset
              </LoadingButton>
              <LoadingButton
                loading={summary.isLoading}
                disabled={disable}
                {...PageStyle.Button}
                onClick={onSubmit}
              >
                Apply
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Content>

      {summary.isLoading && <Loading />}

      {summary && summary.isError && <Container {...PageStyle.NotFound}>Data Not Found</Container>}

      {summary && summary.data && summary.data.transactionSummary && (
        <Content
          title=''
          tableHeadTitles={transactionSummaryTableHeadTitles}
          useNoAction={false}
          usePagination={false}
        >
          <TableRow>
            <TableCell>{summary.data.transactionSummary.total}</TableCell>
            <TableCell>{summary.data.transactionSummary.totalTime}</TableCell>
            <TableCell>{summary.data.transactionSummary.totalKwh}</TableCell>
            <TableCell>{summary.data.transactionSummary.busiestDay || '-'}</TableCell>
            <TableCell>{summary.data.transactionSummary.totalRequest}</TableCell>
            <TableCell>{summary.data.transactionSummary.finishedRequest}</TableCell>
          </TableRow>
        </Content>
      )}

      {summary && summary.data && summary.data.userSummary && (
        <Content
          title=''
          tableHeadTitles={userSummaryTableHeadTitles}
          useNoAction={false}
          usePagination={false}
        >
          <TableRow>
            <TableCell>{summary.data.userSummary.totalUsers}</TableCell>
            <TableCell>{summary.data.userSummary.averageTime}</TableCell>
            <TableCell>{summary.data.userSummary.averageKwh}</TableCell>
            <TableCell>{summary.data.userSummary.busiestHour}</TableCell>
            <TableCell>{summary.data.userSummary.cancelledRequest}</TableCell>
            <TableCell>{summary.data.userSummary.rejectedRequest}</TableCell>
          </TableRow>
        </Content>
      )}

      {summary && summary.data && summary.data.chargeBoxSummary && (
        <Content
          title=''
          tableHeadTitles={chargeBoxSummaryTableHeadTitles}
          useNoAction={false}
          usePagination={false}
        >
          <TableRow>
            <TableCell>{summary.data.chargeBoxSummary.occupied}</TableCell>
            <TableCell>{summary.data.chargeBoxSummary.total}</TableCell>
            <TableCell>{summary.data.chargeBoxSummary.available}</TableCell>
            <TableCell>{summary.data.chargeBoxSummary.unavailable}</TableCell>
          </TableRow>
        </Content>
      )}
    </>
  )
}

export default EVOverview
