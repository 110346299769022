import CONSTANT from 'modules/constant'
import type { PartialUsersAccountCreateProps } from 'modules/partial'
import type {
  PaginationProps,
  ResultProps,
  UserResetPasswordProps,
  UsersAccountProps
} from 'modules/types'
import { API, download, headers } from './api'

const controlAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getUsersAccountList: builder.mutation<PaginationProps<UsersAccountProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_ACCESS_CONTROL_USERS_ACCOUNT,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    getUsersAccountDetail: builder.query<UsersAccountProps, string>({
      query: (userId) => ({
        url: CONSTANT.URL_ACCESS_CONTROL_USERS_ACCOUNT_DETAIL + userId,
        method: 'GET',
        headers: headers()
      })
    }),
    createUsersAccount: builder.mutation<ResultProps, PartialUsersAccountCreateProps>({
      query: (body) => ({
        url: CONSTANT.URL_ACCESS_CONTROL_USERS_ACCOUNT_CREATE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    updateUsersAccountList: builder.mutation<ResultProps, { userId: string; IsActive: boolean }>({
      query: (body) => ({
        url: CONSTANT.URL_ACCESS_CONTROL_USERS_ACCOUNT_UPDATE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    resetUsersAccountPassword: builder.mutation<ResultProps, UserResetPasswordProps>({
      query: (body) => ({
        url: CONSTANT.URL_ACCESS_CONTROL_USERS_ACCOUNT_RESET,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    downloadUsersAccountList: builder.mutation<PaginationProps<UsersAccountProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_ACCESS_CONTROL_USERS_ACCOUNT_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        responseHandler: download,
        body: body,
        formData: true
      })
    })
  })
})

export const {
  useGetUsersAccountListMutation,
  useGetUsersAccountDetailQuery,
  useCreateUsersAccountMutation,
  useUpdateUsersAccountListMutation,
  useResetUsersAccountPasswordMutation,
  useDownloadUsersAccountListMutation
} = controlAPI
