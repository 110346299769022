import { useFormik } from 'formik'
import * as yup from 'yup'
import { Container, TextField } from '@mui/material'
import Dialog from 'components/dialog/dialog'
import type {
  DetailStateProps,
  ErrorProps,
  UserResetPasswordProps,
  UsersAccountProps
} from 'modules/types'
import { useResetUsersAccountPasswordMutation } from 'store/control'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import DialogStyle from './style'

const Reset = ({ open, user, onClose }: DetailStateProps & { user: UsersAccountProps }) => {
  if (!open) return <></>

  const [resetPassword, reset] = useResetUsersAccountPasswordMutation()

  const onSubmit = (values: UserResetPasswordProps) => resetPassword(values)

  const scheme = yup.object<UserResetPasswordProps>({
    userId: yup.string().required('User ID is required')
  })

  const formik = useFormik<UserResetPasswordProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: { userId: user.userId },
    onSubmit: onSubmit
  })

  return (
    <>
      <Dialog
        title='Reset Password'
        open={open}
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={reset.isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          <TextField
            variant='outlined'
            label='Full Name'
            value={user.fullName}
            inputProps={{ readOnly: true }}
            fullWidth
          />
          <TextField
            variant='outlined'
            label='Username'
            value={user.userName}
            inputProps={{ readOnly: true }}
            fullWidth
          />
          <TextField
            variant='outlined'
            label='Email'
            value={user.email}
            inputProps={{ readOnly: true }}
            fullWidth
          />
          <TextField
            variant='outlined'
            label='Phone'
            value={user.phone}
            inputProps={{ readOnly: true }}
            fullWidth
          />
        </Container>
      </Dialog>

      <Notification
        open={!reset.isLoading && !reset.isUninitialized}
        onClose={() => (reset.isError ? reset.reset() : location.reload())}
        isError={Boolean(reset.error) && reset.isError}
        message={GLOBAL.returnExceptionMessage(reset.isError, reset.error as ErrorProps)}
      />
    </>
  )
}

export default Reset
