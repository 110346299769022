import CONSTANT from 'modules/constant'
import type {
  DropdownBenefitsListProps,
  DropdownCategoryListProps,
  PaginationProps,
  ResultProps,
  VoucherBenefitsDetailProps,
  VoucherBenefitsListProps,
  VoucherContentProps
} from 'modules/types'
import { API, download, headers } from './api'
const VoucherBenefitsAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getListVoucherBenefits: builder.mutation<PaginationProps<VoucherBenefitsListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_BENEFITS,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body
      })
    }),
    getVoucherBenefitsDetail: builder.query<VoucherBenefitsDetailProps, number | string>({
      query: (Id) => ({
        url: CONSTANT.URL_VOUCHER_BENEFITS_DETAIL + Id,
        method: 'GET',
        headers: headers()
      })
    }),
    getVoucherBenefitsDropdown: builder.mutation<DropdownBenefitsListProps[], number | string>({
      query: (Id) => ({
        url: CONSTANT.URL_VOUCHER_BENEFITS_DROPDOWN + Id,
        method: 'GET',
        headers: headers()
      })
    }),
    getVoucherMemberDropdown: builder.mutation<DropdownCategoryListProps[], void>({
      query: () => ({
        url: CONSTANT.URL_VOUCHER_MEMBER_DROPDOWN,
        method: 'GET',
        headers: headers()
      })
    }),
    getBenefitsCategoryDropdown: builder.mutation<DropdownCategoryListProps[], void>({
      query: () => ({
        url: CONSTANT.URL_BENEFITS_CATEGORY_DROPDOWN,
        method: 'GET',
        headers: headers()
      })
    }),
    getContentVoucher: builder.mutation<VoucherContentProps, number | string>({
      query: (Id) => ({
        url: CONSTANT.URL_BENEFITS_CONTENT_VOUCHER + Id,
        method: 'GET',
        headers: headers()
      })
    }),
    createVoucherBenefits: builder.mutation<ResultProps, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_BENEFITS_CREATE,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body
      })
    }),
    updateVoucherBenefits: builder.mutation<ResultProps, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_BENEFITS_UPDATE,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body
      })
    }),
    deleteVoucherBenefits: builder.mutation<ResultProps, { Id: number | string }>({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_BENEFITS_DELETE + body.Id,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    enableVoucherBenefits: builder.mutation<ResultProps, { Id: number | string; Status: string }>({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_BENEFITS_ENABLE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    getVoucherFilterDropdown: builder.mutation<DropdownCategoryListProps[], void>({
      query: () => ({
        url: CONSTANT.URL_VOUCHER_FILTER_DROPDOWN,
        method: 'GET',
        headers: headers()
      })
    }),
    getPartnerFilterDropdown: builder.mutation<DropdownCategoryListProps[], void>({
      query: () => ({
        url: CONSTANT.URL_PARTNER_FILTER_DROPDOWN,
        method: 'GET',
        headers: headers()
      })
    }),
    downloadVoucherBenefits: builder.mutation<PaginationProps<VoucherBenefitsListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_BENEFITS_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        responseHandler: download,
        body: body,
        formData: true
      })
    })
  })
})

export const {
  useGetListVoucherBenefitsMutation,
  useGetVoucherBenefitsDetailQuery,
  useGetVoucherBenefitsDropdownMutation,
  useGetVoucherMemberDropdownMutation,
  useGetBenefitsCategoryDropdownMutation,
  useGetContentVoucherMutation,
  useCreateVoucherBenefitsMutation,
  useUpdateVoucherBenefitsMutation,
  useDeleteVoucherBenefitsMutation,
  useEnableVoucherBenefitsMutation,
  useGetPartnerFilterDropdownMutation,
  useGetVoucherFilterDropdownMutation,
  useDownloadVoucherBenefitsMutation
} = VoucherBenefitsAPI
