import { FormikProvider, useFormik } from 'formik'
import { Container, TextField } from '@mui/material'
import * as yup from 'yup'
import Dialog from 'components/dialog/dialog'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { PartialCreateEventLocationProps } from 'modules/partial'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import { useCreateEventLocationMutation } from 'store/event'
import DialogStyle from './style'

const Create = ({ open, onClose }: DetailStateProps) => {
  const [createEventLocation, create] = useCreateEventLocationMutation()

  const scheme = yup.object<PartialCreateEventLocationProps>({
    EventName: yup.string().required('Event Name is required'),
    Address: yup.string().required('Address is required'),
    City: yup.string().required('City is required'),
    District: yup.string().required('District is required'),
    PostalCode: yup.string().required('Postal Code is required'),
    Latitude: yup.number().required('Latitude is required'),
    Longitude: yup.number().required('Longitude is required'),
    GoogleMapsUrl: yup.string().required('Google Maps URL is required')
  })

  const formik = useFormik<PartialCreateEventLocationProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      EventName: '',
      Address: '',
      City: '',
      District: '',
      PostalCode: '',
      Latitude: null,
      Longitude: null,
      GoogleMapsUrl: ''
    },
    onSubmit: (values: PartialCreateEventLocationProps) => handleSubmit(values)
  })

  const handleSubmit = (value: PartialCreateEventLocationProps) => {
    createEventLocation(value)
  }

  return (
    <>
      <Dialog
        open={open}
        title='Create New Event Location'
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={create.isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          <FormikProvider value={formik}>
            <TextField
              id='EventName'
              variant='outlined'
              label='Event Name'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched && formik.touched.EventName && Boolean(formik.errors.EventName)}
              helperText={
                formik.touched &&
                formik.touched.EventName &&
                formik.errors &&
                formik.errors.EventName
              }
              fullWidth
            />
            <TextField
              id='Address'
              variant='outlined'
              label='Address'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched && formik.touched.Address && Boolean(formik.errors.Address)}
              helperText={
                formik.touched && formik.touched.Address && formik.errors && formik.errors.Address
              }
              fullWidth
            />
            <TextField
              id='City'
              variant='outlined'
              label='City'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched && formik.touched.City && Boolean(formik.errors.City)}
              helperText={
                formik.touched && formik.touched.City && formik.errors && formik.errors.City
              }
              fullWidth
            />
            <TextField
              id='District'
              variant='outlined'
              label='District'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched && formik.touched.District && Boolean(formik.errors.District)}
              helperText={
                formik.touched && formik.touched.District && formik.errors && formik.errors.District
              }
              fullWidth
            />
            <TextField
              id='PostalCode'
              variant='outlined'
              label='Postal Code'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched && formik.touched.PostalCode && Boolean(formik.errors.PostalCode)
              }
              helperText={
                formik.touched &&
                formik.touched.PostalCode &&
                formik.errors &&
                formik.errors.PostalCode
              }
              fullWidth
            />
            <TextField
              id='Latitude'
              variant='outlined'
              label='Latitude'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched && formik.touched.Latitude && Boolean(formik.errors.Latitude)}
              helperText={
                formik.touched && formik.touched.Latitude && formik.errors && formik.errors.Latitude
              }
              fullWidth
              type='number'
            />
            <TextField
              id='Longitude'
              variant='outlined'
              label='Longitude'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched && formik.touched.Longitude && Boolean(formik.errors.Longitude)}
              helperText={
                formik.touched &&
                formik.touched.Longitude &&
                formik.errors &&
                formik.errors.Longitude
              }
              fullWidth
              type='number'
            />
            <TextField
              id='GoogleMapsUrl'
              variant='outlined'
              label='GoogleMapsUrl'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched &&
                formik.touched.GoogleMapsUrl &&
                Boolean(formik.errors.GoogleMapsUrl)
              }
              helperText={
                formik.touched &&
                formik.touched.GoogleMapsUrl &&
                formik.errors &&
                formik.errors.GoogleMapsUrl
              }
              fullWidth
            />
          </FormikProvider>
        </Container>
      </Dialog>

      <Notification
        open={!create.isLoading && !create.isUninitialized}
        onClose={() => (create.isError ? create.reset() : location.reload())}
        isError={Boolean(create.error) && create.isError}
        message={GLOBAL.returnExceptionMessage(create.isError, create.error as ErrorProps)}
      />
    </>
  )
}

export default Create
