import {
  Container,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField
} from '@mui/material'
import moment from 'moment'
import Drawer from 'components/drawer/detail'
import Loading from 'components/loading/loading'
import GLOBAL from 'modules/global'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import Notification from 'components/notification'
import { useGetPriceListDetailQuery } from 'store/evPrice'
import DetailStyle from './style'

const Detail = ({ open, id, onClose }: DetailStateProps & { id: number }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const { data, isLoading, isUninitialized, isSuccess, isError, error } =
    useGetPriceListDetailQuery(id)

  const createdAt = (data && moment(data.createdAt).format('DD-MM-YYYY HH:mm')) || '-'
  const code = (data && data.code) || '-'
  const name = (data && data.name) || '-'
  const remark = (data && data.remark) || '-'
  const electricityFee = (data && GLOBAL.numberFormat(data.electricityFee)) || null
  const electricityFeePerUnit = (data && data.electricityFeePerUnit) || ''
  const serviceFee = (data && GLOBAL.numberFormat(data.serviceFee)) || null
  const serviceFeePerUnit = (data && data.serviceFeePerUnit) || ''
  const adminFee = (data && GLOBAL.numberFormat(data.adminFee)) || null
  const adminFeePerUnit = (data && data.adminFeePerUnit) || ''
  const minimumKwhForAdminFee = (data && data.minimumKwhForAdminFee) || null
  const minimumBalance = (data && GLOBAL.numberFormat(data.minimumBalance)) || null
  const estimateMinIncomingDuration = (data && data.estimateMinIncomingDuration) || '-'
  const estimateMaxIncomingDuration = (data && data.estimateMaxIncomingDuration) || '-'
  const estimateIncomingPowerInKwh = (data && data.estimateIncomingPowerInKwh) || '-'

  const gridDataLabelFirst = ['Date Created', 'Price Table ID', 'Price Table Name', 'Remark']
  const gridDataLabelSecond = ['Electricity Fee', 'Service Fee', 'Admin Fee']
  const gridDataLabelThird = [
    'Minimum Kwh For Admin Fee',
    'Minimum Balance',
    'Estimate Min Incoming Duration',
    'Estimate Max Incoming Duration',
    'Estimate Incoming Power In kWh'
  ]

  const gridDataValueFirst = [createdAt, code, name, remark]
  const gridDataValueSecond = [electricityFee, serviceFee, adminFee]
  const gridDataValueAdornmentSecond = [electricityFeePerUnit, serviceFeePerUnit, adminFeePerUnit]
  const gridDataValueThird = [
    minimumKwhForAdminFee,
    minimumBalance,
    estimateMinIncomingDuration,
    estimateMaxIncomingDuration,
    estimateIncomingPowerInKwh
  ]

  return (
    <>
      <Drawer open={open} title='Price Management Detail' onClose={onClose}>
        <Container {...DetailStyle.Container}>
          {isLoading && <Loading />}
          {!isLoading && (
            <Grid container spacing={2}>
              {gridDataValueFirst.map((item, index) => (
                <Grid key={index} item xs={12}>
                  <TextField
                    variant='outlined'
                    label={gridDataLabelFirst[index]}
                    value={item}
                    inputProps={{ readOnly: true }}
                    fullWidth
                  />
                </Grid>
              ))}
              {gridDataValueSecond.map((item, index) => (
                <Grid key={index} item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor={gridDataLabelSecond[index]}>
                      {gridDataLabelSecond[index]}
                    </InputLabel>
                    <OutlinedInput
                      id={gridDataLabelSecond[index]}
                      label={gridDataLabelSecond[index]}
                      value={item}
                      inputProps={{ readOnly: true }}
                      startAdornment={<InputAdornment position='start'>Rp</InputAdornment>}
                      endAdornment={
                        <InputAdornment position='end'>
                          / {gridDataValueAdornmentSecond[index]}
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
              ))}
              {gridDataValueThird.map((item, index) => (
                <Grid key={index} item xs={12}>
                  <TextField
                    variant='outlined'
                    label={gridDataLabelThird[index]}
                    value={item}
                    inputProps={{ readOnly: true }}
                    fullWidth
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Container>
      </Drawer>

      <Notification
        open={!isLoading && !isUninitialized && !isSuccess}
        onClose={() => null}
        isError={isError}
        message={GLOBAL.returnExceptionMessage(isError, error as ErrorProps)}
      />
    </>
  )
}

export default Detail
