import type { LinkProps } from 'react-router-dom'
import type { BoxProps, TypographyProps } from '@mui/material'

class SidebarStyle {
  static Container: BoxProps = {
    position: 'fixed',
    display: 'flex',
    left: 0,
    height: '100%',
    minHeight: '100vh',
    paddingTop: '12rem',
    zIndex: 1201,
    sx: {
      borderRightWidth: 1,
      borderRightStyle: 'solid',
      borderRightColor: 'divider',
      backgroundColor: 'white'
    }
  }

  static Wrapper: BoxProps = {
    display: 'flex',
    minWidth: '10rem',
    padding: 2,
    gap: 3,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  }

  static Button: BoxProps = {
    display: 'flex',
    alignItems: 'center',
    sx: {
      cursor: 'pointer'
    }
  }

  static Icon: BoxProps = {
    display: 'flex',
    marginX: 1.2,
    alignItems: 'center'
  }

  static Title: TypographyProps = {
    fontSize: 14,
    marginRight: 2,
    whiteSpace: 'nowrap'
  }

  static Link: LinkProps = {
    to: '',
    style: {
      color: 'inherit',
      textDecoration: 'none'
    }
  }
}

export default SidebarStyle
