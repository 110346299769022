import ReactQuill from 'react-quill'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Grid,
  TextField,
  Typography
} from '@mui/material'
import { decode } from 'html-entities'
import { ExpandMoreOutlined } from '@mui/icons-material'
import Drawer from 'components/drawer/detail'
import Loading from 'components/loading/loading'
import type { DetailStateProps, VoucherFaqProps } from 'modules/types'
import { useGetVoucherFaqDetailQuery } from 'store/voucherFAQ'
import DrawerStyle from './style'
import 'react-quill/dist/quill.snow.css'

const Detail = ({ open, id, onClose }: DetailStateProps & { id: number }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const { data, isFetching, isLoading } = useGetVoucherFaqDetailQuery(id)

  const key = data && data.id

  const lang = [
    {
      language: 'EN-US',
      description: (data && data.shortDescEn) || '-',
      information: (data && data.informationEn && decode(data.informationEn)) || '-',
      tnc: (data && data.termsAndConditionsEn && decode(data.termsAndConditionsEn)) || '-'
    },
    {
      language: 'ID',
      description: (data && data.shortDescId) || '-',
      information: (data && data.informationId && decode(data.informationId)) || '-',
      tnc: (data && data.termsAndConditionsId && decode(data.termsAndConditionsId)) || '-'
    }
  ]

  const gridBasicLabel = ['Created By', 'Created At', 'Modified By', 'Modified At']
  const gridBasicValue = ['createdBy', 'createdAt', 'modifiedBy', 'modifiedAt']

  return (
    <Drawer open={open} title='Detail Voucher FAQ' onClose={onClose}>
      <Container {...DrawerStyle.Container}>
        {(isLoading || isFetching) && !data && <Loading />}
        {data && (
          <>
            <TextField
              id='key'
              variant='outlined'
              label='Voucher ID'
              value={key}
              inputProps={{ readOnly: true }}
              fullWidth
            />
            {lang.map((value, index) => (
              <>
                <Grid key={index} container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      variant='outlined'
                      label='Language'
                      value={value.language}
                      inputProps={{ readOnly: true }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant='outlined'
                      label='Short Description'
                      value={value.description}
                      inputProps={{ readOnly: true }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Typography {...DrawerStyle.Title}>Information</Typography>
                <ReactQuill
                  theme='snow'
                  value={value.information ? decode(value.information) : ''}
                  style={{ marginBottom: '3rem' }}
                  readOnly={true}
                />
                <Typography {...DrawerStyle.Title}>Terms and Condition</Typography>
                <ReactQuill
                  theme='snow'
                  value={value.tnc ? decode(value.tnc) : ''}
                  style={{ marginBottom: '3rem' }}
                  readOnly={true}
                />
              </>
            ))}
            <Accordion elevation={0} disableGutters>
              <AccordionSummary
                expandIcon={<ExpandMoreOutlined />}
                aria-controls='basic-information'
              >
                <Typography>Basic Information</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {gridBasicLabel.map((title, id) => (
                    <Grid key={id} item xs={12}>
                      <TextField
                        variant='outlined'
                        label={title}
                        value={data[gridBasicValue[id] as keyof VoucherFaqProps] || '-'}
                        inputProps={{ readOnly: true }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        fullWidth
                      />
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </>
        )}
      </Container>
    </Drawer>
  )
}

export default Detail
