import * as yup from 'yup'
import { Container, TextField } from '@mui/material'
import Dialog from 'components/dialog/dialog'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import type { PartialEvBrandUpdateProps } from 'modules/partial'
import FORMIK from 'modules/formik'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import DialogStyle from 'components/dialog/style'
import { useGetEvBrandDetailQuery, useUpdateEvBrandMutation } from 'store/evBrand'
import Loading from 'components/loading/loading'

const Edit = ({ open, id, onClose }: DetailStateProps & { id: number }) => {
  if (!open) return <></>

  const { data, isLoading, isFetching } = useGetEvBrandDetailQuery(id)

  const [updateEvBrand, update] = useUpdateEvBrandMutation()

  const scheme = yup.object<PartialEvBrandUpdateProps>({
    id: yup.number().required('ID is required'),
    name: yup.string().required('Name is required'),
    status: yup.string().required('Status is required')
  })

  const initialValues: PartialEvBrandUpdateProps = {
    id: (data && data.id) || null,
    name: (data && data.name) || '',
    status: (data && data.status) || ''
  }

  const formik = FORMIK.useFormFormik<PartialEvBrandUpdateProps>(scheme, initialValues, (values) =>
    updateEvBrand(values)
  )

  const loading = (isLoading || isFetching) && !data
  const errorData = formik.errors

  return (
    <>
      <Dialog
        title='Update New EV Car Brand'
        open={open}
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={update.isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          {loading && <Loading />}
          {!loading && (
            <>
              <TextField
                id='id'
                variant='outlined'
                label='ID'
                value={formik.values.id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                inputProps={{ readOnly: true }}
                error={formik.touched && formik.touched.id && Boolean(errorData.id)}
                helperText={formik.touched && formik.touched.id && errorData && errorData.id}
                fullWidth
              />
              <TextField
                id='name'
                variant='outlined'
                label='Car Brand Name'
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched && formik.touched.name && Boolean(errorData.name)}
                helperText={formik.touched && formik.touched.name && errorData && errorData.name}
                fullWidth
              />
            </>
          )}
        </Container>
      </Dialog>

      <Notification
        open={!update.isLoading && !update.isUninitialized}
        onClose={() => (update.isError ? update.reset() : location.reload())}
        isError={Boolean(update.error) && update.isError}
        message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
      />
    </>
  )
}

export default Edit
