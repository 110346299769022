/* eslint-disable @typescript-eslint/no-explicit-any */

import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { Container, TextField, Typography } from '@mui/material'
import Dialog from 'components/dialog/dialog'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import type { PartialTokenStateProps } from 'modules/partial'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import { useRevokeTokenMutation } from 'store/token'
import DialogStyle from './style'

const RevokeToken = ({ open, id, onClose }: DetailStateProps & { id: [] }) => {
  if (!open) return <></>

  const [revokeToken, update] = useRevokeTokenMutation()

  const scheme = yup.object<PartialTokenStateProps>({
    Reason: yup.string().required('Reason is required')
  })

  const customCode = (arrStr: string, type: string) => {
    const customItems = arrStr.split(',')

    for (let i = 0; i < customItems.length; i++) {
      customItems[i] =
        type === 'id' ? customItems[i].split('-')[0] : customItems[i].split('-')[1] + '\n'
    }

    return type === 'id' ? customItems.map(Number) : customItems.join('')
  }

  const formik = useFormik<PartialTokenStateProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      VoucherTokenId: '',
      Reason: ''
    },
    onSubmit: (values: PartialTokenStateProps) => handleSubmit(values)
  })

  const handleSubmit = (e: PartialTokenStateProps) => {
    const payload: any = {
      VoucherTokenId: customCode(String(id), 'id'),
      Reason: e.Reason
    }

    revokeToken(payload)
  }

  return (
    <>
      <Dialog
        open={open}
        title='Revoke Token'
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={update.isLoading}
        isDisabled={!formik.isValid}
        submitText='Yes'
        cancelText='No'
      >
        <Container {...DialogStyle.Container}>
          {id && (
            <>
              <FormikProvider value={formik}>
                <Typography sx={{ textAlign: 'center' }}>
                  Are you sure want to revoke all selected tokens?
                </Typography>
                <Typography>{id.length} Token(s) found</Typography>
                <TextField
                  id='outlined-multiline-static'
                  multiline
                  rows={4}
                  defaultValue={customCode(String(id), 'code')}
                  inputProps={{ readOnly: true }}
                />

                <TextField
                  label='Reason'
                  id='Reason'
                  multiline
                  rows={4}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched && formik.touched.Reason && Boolean(formik.errors.Reason)}
                  helperText={
                    formik.touched && formik.touched.Reason && formik.errors && formik.errors.Reason
                  }
                />
              </FormikProvider>
            </>
          )}
        </Container>
      </Dialog>

      <Notification
        open={!update.isLoading && !update.isUninitialized}
        onClose={() => (update.isError ? update.reset() : location.reload())}
        isError={update.isError}
        message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
      />
    </>
  )
}

export default RevokeToken
