/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react'
import { Box, Container, Grid, TextField, Typography } from '@mui/material'
import Drawer from 'components/drawer/detail'
import Loading from 'components/loading/loading'
import type { DetailStateProps } from 'modules/types'
import { useGetTokenDetailQuery } from 'store/token'
import GLOBAL from 'modules/global'
import BackdropImage from 'components/backdropImage/backdropImage'
import DrawerStyle from './style'

const Detail = ({ open, id, onClose }: DetailStateProps & { id: string | number }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const { data, isFetching, isLoading } = useGetTokenDetailQuery(id)

  const [previewBackdrop, setPreviewBackdrop] = useState<boolean>(false)

  const loading = (isLoading || isFetching) && !data

  const code = (data && data.code) || '-'
  const validFrom = (data && data.validFrom && GLOBAL.formatDateWithTime(data.validFrom)) || '-'
  const validUntil = (data && data.validUntil && GLOBAL.formatDateWithTime(data.validUntil)) || '-'
  const voucherName = (data && data.voucherName) || '-'
  const partnerName = (data && data.partnerName) || '-'
  const purchased = data && data.isPurchased ? 'Yes' : 'No'
  const claimed = data && data.isClaimed ? 'Yes' : 'No'
  const revoked = data && data.isRevoked ? 'Yes' : 'No'
  const purchasedById = (data && data.purchasedByMemberCode) || '-'
  const purchasedByName = (data && data.purchasedByName) || '-'
  const purchasedDate =
    (data && data.purchasedOn && GLOBAL.formatDateWithTime(data.purchasedOn)) || '-'
  const claimedById = (data && data.claimedByMemberCode) || '-'
  const claimedByName = (data && data.claimedByName) || '-'
  const claimedDate = (data && data.claimedOn && GLOBAL.formatDateWithTime(data.claimedOn)) || '-'
  const revokedByName = (data && data.revokedByName) || '-'
  const revokedNotes = (data && data.revocationNotes) || '-'
  const remark = (data && data.remarks) || '-'
  const expired = data && data.isExpired ? 'Yes' : 'No'
  const image = (data && data.voucherImage) || undefined

  const gridDataLabel = [
    'Code',
    'Valid From',
    'Valid Until',
    'Voucher Name',
    'Partner Name',
    'Purchased (Yes / No)',
    'Claimed (Yes / No)',
    'Revoked (Yes / No)',
    'Purchased By ID',
    'Purchased By Name',
    'Purchased Date',
    'Claimed By ID',
    'Claimed By Name',
    'Claimed Date',
    'Revoked By Name',
    'Revoked Notes',
    'Remark',
    'Expired (Yes / No)'
  ]

  const gridDataValue = [
    code,
    validFrom,
    validUntil,
    voucherName,
    partnerName,
    purchased,
    claimed,
    revoked,
    purchasedById,
    purchasedByName,
    purchasedDate,
    claimedById,
    claimedByName,
    claimedDate,
    revokedByName,
    revokedNotes,
    remark,
    expired
  ]

  return (
    <>
      <Drawer open={open} title='Detail Token' onClose={onClose}>
        <Container {...DrawerStyle.Container}>
          {loading && <Loading />}
          {!loading && (
            <>
              <Grid container spacing={2}>
                {gridDataValue.map((item, index) => (
                  <Grid key={index} item xs={6}>
                    <TextField
                      variant='outlined'
                      label={gridDataLabel[index]}
                      value={item}
                      inputProps={{ readOnly: true }}
                      fullWidth
                    />
                  </Grid>
                ))}
              </Grid>
              <Grid item xs={12}>
                <Typography {...DrawerStyle.Title}>Voucher Image</Typography>
                <Box {...DrawerStyle.Image}>
                  <BackdropImage
                    open={previewBackdrop}
                    onOpen={() => setPreviewBackdrop(true)}
                    onClose={() => setPreviewBackdrop(false)}
                  >
                    <img src={image} alt='Voucher Image' width='100%' style={{ zIndex: 10 }} />
                  </BackdropImage>
                </Box>
              </Grid>
            </>
          )}
        </Container>
      </Drawer>
    </>
  )
}

export default Detail
