import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { IconButton, Menu, MenuItem, TableCell, TableRow, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import {
  useGetEvModelMutation,
  useDownloadEvModelMutation,
  useDeleteEvModelMutation
} from 'store/evModel'
import type { PartialHyundaiModelProps } from 'modules/partial'
import Content from 'components/content/content'
import CONSTANT from 'modules/constant'
import Loading from 'components/loading/loading'
import Icon from 'components/icon'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { ErrorProps, ParamProps } from 'modules/types'
import MenuModalItem from 'components/menuItem/menuItem'
import GlobalStyle from 'modules/styles'
import Detail from './detail/detail'
import Create from './create/create'
import Edit from './edit/edit'

const EVCarBrandCatalog = () => {
  const params = useParams<ParamProps>()

  const [getEvModel, evModel] = useGetEvModelMutation()
  const [downloadEvModel, download] = useDownloadEvModelMutation()
  const [deleteEvModel, deleteEv] = useDeleteEvModelMutation()
  const [dialogCreate, setDialogCreate] = useState<boolean>(false)
  const [dialogUpdate, setDialogUpdate] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [menu, setMenu] = useState<null | number>(null)

  const [payload, setPayload] = useState<PartialHyundaiModelProps>({
    Start: 0,
    Length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    Filter: `brandName.equals("${GLOBAL.decodeUrl(params.name || '')}")`,
    Search: '',
    Sorting: ''
  })

  const onSearch = (value: string) => setPayload({ ...payload, Start: 0, Search: value })

  const onResync = () => {
    const formData = new FormData()

    formData.append('Start', payload.Start.toString())
    formData.append('Length', payload.Length.toString())

    if (!isEmpty(payload.Search)) formData.append('Search', payload.Search)
    if (!isEmpty(payload.Filter)) formData.append('Filter', payload.Filter)
    if (!isEmpty(payload.Sorting)) formData.append('Sorting', payload.Sorting)

    getEvModel(formData)
  }

  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('start', String(0))
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())

    if (!isEmpty(payload.Search)) formData.append('Search', payload.Search)
    if (!isEmpty(payload.Filter)) formData.append('Filter', payload.Filter)
    if (!isEmpty(payload.Sorting)) formData.append('Sorting', payload.Sorting)
    if (type && !isEmpty(type)) formData.append('Content-Type', type)

    downloadEvModel(formData)
  }

  const onCreate = () => setDialogCreate(true)

  const onChangePage = (value: number) => setPayload({ ...payload, Start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, Length: value })

  const tableHeadTitles = ['EV Car Brand', 'EV Car Model', 'Description']

  useEffect(() => {
    onResync()
  }, [payload])

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }
  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }
  const openAnchor = Boolean(anchor)

  const totalPage = evModel.data && GLOBAL.tableTotalPage(evModel.data.recordsTotal)

  const numbers = GLOBAL.tableNumber(payload.Start)

  return (
    <>
      <Content
        title={`EV Car Brand › ${GLOBAL.decodeUrl(params.name || '')}`}
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        onAdditional={onCreate}
        additionalTitle='Create New EV Car Model'
        additionalPrefixIcon='Create'
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={evModel.data && evModel.data.recordsFiltered}
        page={payload.Start + 1}
        isLoading={evModel.isLoading}
        useDownloadDropdown
        useBackButton
      >
        {evModel.isSuccess &&
          evModel.data &&
          evModel.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.id}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.id}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.id}`}
                  aria-labelledby={`button-${index}-${table.id}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View
                  </MenuItem>
                  <MenuItem onClick={() => onRemoveAnchor(() => setDialogUpdate(true))}>
                    Edit
                  </MenuItem>
                  <MenuModalItem
                    title={table.brandName}
                    onClick={() => onRemoveAnchor(() => deleteEvModel(table.id))}
                  >
                    <Typography {...GlobalStyle.ErrorTypography}>Delete</Typography>
                  </MenuModalItem>
                </Menu>
                <Detail
                  id={table.id}
                  open={drawerDetail && menu == index}
                  onClose={() => setDrawerDetail(false)}
                />
                {dialogUpdate && menu == index && (
                  <Edit
                    id={table.id}
                    name={GLOBAL.decodeUrl(params.name || '')}
                    open={dialogUpdate && menu == index}
                    onClose={() => setDialogUpdate(false)}
                  />
                )}
              </TableCell>
              <TableCell>{table.brandName || '-'}</TableCell>
              <TableCell>{table.carModelName || '-'}</TableCell>
              <TableCell>{table.description || '-'}</TableCell>
            </TableRow>
          ))}
      </Content>

      {evModel.isLoading && <Loading />}

      {dialogCreate && (
        <Create
          open={dialogCreate}
          name={GLOBAL.decodeUrl(params.name || '')}
          onClose={() => setDialogCreate(false)}
        />
      )}

      <Notification
        open={!deleteEv.isLoading && !deleteEv.isUninitialized}
        onClose={() => (deleteEv.isError ? deleteEv.reset() : location.reload())}
        isError={Boolean(deleteEv.error) && deleteEv.isError}
        message={GLOBAL.returnExceptionMessage(deleteEv.isError, deleteEv.error as ErrorProps)}
      />

      <Notification
        open={!evModel.isLoading && !evModel.isUninitialized && !evModel.isSuccess}
        onClose={() => (evModel.isError ? evModel.reset() : location.reload())}
        isError={Boolean(evModel.error) && evModel.isError}
        message={GLOBAL.returnExceptionMessage(evModel.isError, evModel.error as ErrorProps)}
      />
    </>
  )
}

export default EVCarBrandCatalog
