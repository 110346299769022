import { useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import { isEmpty } from 'lodash'
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import * as yup from 'yup'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import type { Dayjs } from 'dayjs'
import Dialog from 'components/dialog/dialog'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { ListVoucherPackageProps, PartialVoucherPackageProps } from 'modules/partial'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import GlobalStyle from 'modules/styles'
import { useGetVoucherCategoryDropdownMutation } from 'store/voucherCategory'
import {
  useCreateVoucherPackageMutation,
  useGetVoucherPackageDropdownMutation
} from 'store/voucherPackage'
import { useGetCarModelDropdownListMutation } from 'store/dropdown'
import Icon from 'components/icon'
import BackdropImage from 'components/backdropImage/backdropImage'
import DialogStyle from './style'

const Create = ({ open, onClose }: DetailStateProps) => {
  const [getVoucherCategoryDropdown, dropdownCategory] = useGetVoucherCategoryDropdownMutation()
  const [getVoucherPackageDropdown, dropdownVoucher] = useGetVoucherPackageDropdownMutation()
  const [getCarModelDropdownList, model] = useGetCarModelDropdownListMutation()
  const [createVoucherPackage, create] = useCreateVoucherPackageMutation()
  const [startDate, setStartDate] = useState<Dayjs | null>(null)
  const [endDate, setEndDate] = useState<Dayjs | null>(null)
  const [previewImage, setPreviewImage] = useState<string>('')
  const [tableVoucherTemp, setTableVoucherTemp] = useState<ListVoucherPackageProps>()
  const [tableVoucher, setTableVoucher] = useState<ListVoucherPackageProps[]>([])
  const [previewBackdrop, setPreviewBackdrop] = useState<boolean>(false)

  const scheme = yup.object<PartialVoucherPackageProps>({
    packageNameIND: yup.string().required('Package Name IND  is required'),
    packageNameENG: yup.string().required('Package Name ENG  is required'),
    packageCategoryId: yup.number().required('Package Category Id  is required'),
    carModelid: yup.number().required('Car Model Id is required'),
    periodFrom: yup.string().required('Period From  is required'),
    periodUntil: yup.string().required('Period Until  is required'),
    bestChoice: yup.boolean().required('Best Choice  is required'),
    image: yup.string().required('Image  is required'),
    voucherList: yup.array().min(1).required('Voucher List is required')
  })

  const formik = useFormik<PartialVoucherPackageProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      packageNameIND: '',
      packageNameENG: '',
      packageCategoryId: '',
      packageCategoryName: '',
      voucherList: [],
      carModelid: '',
      periodFrom: '',
      periodUntil: '',
      shortDescIND: '',
      shortDescENG: '',
      bestChoice: false,
      VoucherId: '',
      quantity: '',
      image: ''
    },
    onSubmit: (values: PartialVoucherPackageProps) => handleSubmit(values)
  })

  const handleSubmit = (e: PartialVoucherPackageProps) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const payload: any = {
      packageNameIND: e.packageNameIND,
      packageNameENG: e.packageNameENG,
      packageCategoryId: e.packageCategoryId,
      packageCategoryName: e.packageCategoryName,
      voucherList: tableVoucher.map((item: ListVoucherPackageProps) => ({
        VoucherId: item.VoucherId,
        quantity: item.quantity
      })),
      carModelid: e.carModelid,
      periodFrom: dayjs(e.periodFrom).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      periodUntil: dayjs(e.periodUntil).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      shortDescIND: e.shortDescIND,
      shortDescENG: e.shortDescENG,
      bestChoice: e.bestChoice,
      VoucherId: e.VoucherId,
      quantity: e.quantity
    }

    const formData = new FormData()
    formData.append('Image', e.image)
    formData.append('data', JSON.stringify(payload))
    createVoucherPackage(formData).then()
  }
  const handleChangeStartDate = (date: Dayjs | null) => {
    setStartDate(date)
    const newDate = (!isEmpty(date) && date.format('YYYY-MM-DD')) || ''
    formik.setFieldValue('periodFrom', newDate)
  }

  const handleChangeEndDate = (date: Dayjs | null) => {
    setEndDate(date)
    const newDate = (!isEmpty(date) && date.format('YYYY-MM-DD')) || ''
    formik.setFieldValue('periodUntil', newDate)
  }

  const dataBoolean = [
    { value: true, name: 'Yes' },
    { value: false, name: 'No' }
  ]

  const onDelVoucherList = (id: string | number) => {
    const tempTable = tableVoucher.filter((item) => item.VoucherId !== id)
    setTableVoucher(tempTable)
  }

  const onAddVoucherList = () => {
    const r = tableVoucher.filter((elem) => elem.VoucherId === tableVoucherTemp?.VoucherId)
    const tempTable = r.length > 0 ? tableVoucher : tableVoucher.concat(tableVoucherTemp || [])
    setTableVoucher(tempTable)
    formik.setFieldValue('voucherList', tempTable)
  }

  const enableAddVoucher = () => {
    if (formik.values.VoucherId != '' && formik.values.quantity != '') {
      return false
    }
    return true
  }

  return (
    <>
      <Dialog
        open={open}
        title='Add Voucher Package'
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={create.isLoading}
        isDisabled={!formik.isValid}
        maxWidth='md'
      >
        <Container {...DialogStyle.Container}>
          <FormikProvider value={formik}>
            <Grid container spacing={2} columns={16}>
              <Grid xs={8} marginTop={1}>
                <Grid xs={16} margin={1}>
                  <TextField
                    id='packageNameIND'
                    variant='outlined'
                    label='Package Name ID'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched &&
                      formik.touched.packageNameIND &&
                      Boolean(formik.errors.packageNameIND)
                    }
                    helperText={
                      formik.touched &&
                      formik.touched.packageNameIND &&
                      formik.errors &&
                      formik.errors.packageNameIND
                    }
                    fullWidth
                  />
                </Grid>
                <Grid xs={16} margin={1}>
                  <TextField
                    id='packageNameENG'
                    variant='outlined'
                    label='Package Name EN-US'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched &&
                      formik.touched.packageNameENG &&
                      Boolean(formik.errors.packageNameENG)
                    }
                    helperText={
                      formik.touched &&
                      formik.touched.packageNameENG &&
                      formik.errors &&
                      formik.errors.packageNameENG
                    }
                    fullWidth
                  />
                </Grid>
                <Grid xs={16} margin={1}>
                  <Autocomplete
                    options={dropdownCategory.data || []}
                    getOptionLabel={(list) => list.name}
                    isOptionEqualToValue={(option, value) =>
                      option && value ? option.id == value.id : false
                    }
                    onOpen={() => getVoucherCategoryDropdown()}
                    onChange={(_, id) => {
                      formik.setFieldValue('packageCategoryId', id && id.id)
                      formik.setFieldValue('packageCategoryName', id && id.name)
                    }}
                    ListboxProps={GlobalStyle.ListBox}
                    renderOption={(props, item) => (
                      <li {...props} key={item.id}>
                        {item.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name='packageCategoryId'
                        label='Package Category'
                        error={
                          formik.touched &&
                          formik.touched.packageCategoryId &&
                          Boolean(formik.errors.packageCategoryId)
                        }
                        helperText={
                          formik.touched &&
                          formik.touched.packageCategoryId &&
                          formik.errors &&
                          formik.errors.packageCategoryId
                        }
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {dropdownCategory.isLoading && (
                                <CircularProgress color='inherit' size={20} />
                              )}
                              {params.InputProps.endAdornment}
                            </>
                          )
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid container marginX={1}>
                  <Grid xs={6} marginRight={1}>
                    <Autocomplete
                      options={dropdownVoucher.data || []}
                      getOptionLabel={(list) => list.voucherName}
                      isOptionEqualToValue={(option, value) =>
                        option && value ? option.id == value.id : false
                      }
                      onOpen={() =>
                        getVoucherPackageDropdown(formik.values && formik.values.packageCategoryId)
                      }
                      onChange={(_, id) => {
                        formik.setFieldValue('VoucherId', id && id.id)
                        setTableVoucherTemp({
                          ...tableVoucherTemp,
                          VoucherId: Number(id && id?.id),
                          VoucherName: (id && id?.voucherName) || '',
                          SubCategory: (id && id?.subCategory) || ''
                        })
                      }}
                      ListboxProps={GlobalStyle.ListBox}
                      renderOption={(props, item) => (
                        <li {...props} key={item.id}>
                          {item.voucherName}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name='VoucherId'
                          label='Voucher'
                          error={
                            formik.touched &&
                            formik.touched.VoucherId &&
                            Boolean(formik.errors.VoucherId)
                          }
                          helperText={
                            formik.touched &&
                            formik.touched.VoucherId &&
                            formik.errors &&
                            formik.errors.VoucherId
                          }
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {dropdownVoucher.isLoading && (
                                  <CircularProgress color='inherit' size={20} />
                                )}
                                {params.InputProps.endAdornment}
                              </>
                            )
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid xs={3} marginRight={1}>
                    <TextField
                      id='quantity'
                      variant='outlined'
                      label='Qty'
                      onChange={formik.handleChange}
                      onBlur={() =>
                        setTableVoucherTemp({
                          ...tableVoucherTemp,
                          quantity: Number(formik.values.quantity)
                        })
                      }
                      error={
                        formik.touched && formik.touched.quantity && Boolean(formik.errors.quantity)
                      }
                      helperText={
                        formik.touched &&
                        formik.touched.quantity &&
                        formik.errors &&
                        formik.errors.quantity
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid xs={2}>
                    <Button
                      variant='contained'
                      onClick={() => onAddVoucherList()}
                      disabled={enableAddVoucher()}
                      sx={{ height: 56 }}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
                <Grid xs={16} margin={1}>
                  <TableContainer
                    component={Paper}
                    sx={{ maxHeight: 512, marginTop: '10px' }}
                    variant='outlined'
                  >
                    <Table aria-label='Data Table' stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell>Voucher List</TableCell>
                          <TableCell>Sub Category</TableCell>
                          <TableCell>Qty</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tableVoucher.map((table, index) => (
                          <TableRow key={index}>
                            <TableCell>{table.VoucherName}</TableCell>
                            <TableCell>{table.SubCategory}</TableCell>
                            <TableCell>{table.quantity}</TableCell>
                            <TableCell>
                              <IconButton
                                onClick={() => onDelVoucherList(Number(table.VoucherId))}
                                sx={{ color: 'red' }}
                              >
                                <Icon icon='Delete' />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid xs={16} margin={1}>
                  <Autocomplete
                    options={model.data || []}
                    getOptionLabel={(list) => list.name}
                    isOptionEqualToValue={(option, value) =>
                      option && value ? option.id == value.id : false
                    }
                    onOpen={() => getCarModelDropdownList()}
                    onChange={(_, id) => formik.setFieldValue('carModelid', id && id.id)}
                    ListboxProps={GlobalStyle.ListBox}
                    renderOption={(props, item) => (
                      <li {...props} key={item.id}>
                        {item.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name='carModelid'
                        label='Car Model'
                        error={
                          formik.touched &&
                          formik.touched.carModelid &&
                          Boolean(formik.errors.carModelid)
                        }
                        helperText={
                          formik.touched &&
                          formik.touched.carModelid &&
                          formik.errors &&
                          formik.errors.carModelid
                        }
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {model.isLoading && <CircularProgress color='inherit' size={20} />}
                              {params.InputProps.endAdornment}
                            </>
                          )
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid xs={16} margin={1}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                    <DatePicker
                      value={startDate}
                      onChange={handleChangeStartDate}
                      label='Handover Period From'
                      format='DD-MM-YYYY'
                      sx={{ width: '100%' }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid xs={16} margin={1}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                    <DatePicker
                      value={endDate}
                      minDate={dayjs(startDate)}
                      onChange={handleChangeEndDate}
                      label='Handover Period Until'
                      format='DD-MM-YYYY'
                      sx={{ width: '100%' }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              {/* ============ Right Column =========== */}
              <Grid xs={8} marginTop={1}>
                <Grid xs={16} margin={1}>
                  <TextField
                    id='shortDescIND'
                    variant='outlined'
                    label='Short Description ID'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched &&
                      formik.touched.shortDescIND &&
                      Boolean(formik.errors.shortDescIND)
                    }
                    helperText={
                      formik.touched &&
                      formik.touched.shortDescIND &&
                      formik.errors &&
                      formik.errors.shortDescIND
                    }
                    fullWidth
                  />
                </Grid>
                <Grid xs={16} margin={1}>
                  <TextField
                    id='shortDescENG'
                    variant='outlined'
                    label='Short Description EN-US'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched &&
                      formik.touched.shortDescENG &&
                      Boolean(formik.errors.shortDescENG)
                    }
                    helperText={
                      formik.touched &&
                      formik.touched.shortDescENG &&
                      formik.errors &&
                      formik.errors.shortDescENG
                    }
                    fullWidth
                  />
                </Grid>
                <Grid xs={16} margin={1}>
                  <Autocomplete
                    options={dataBoolean || []}
                    getOptionLabel={(list) => list.name}
                    isOptionEqualToValue={(option, value) =>
                      option && value ? option.value == value.value : false
                    }
                    onChange={(_, id) => formik.setFieldValue('bestChoice', id && id.value)}
                    ListboxProps={GlobalStyle.ListBox}
                    renderOption={(props, item) => (
                      <li {...props} key={item.name}>
                        {item.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name='bestChoice'
                        label='Best Choice'
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: <>{params.InputProps.endAdornment}</>
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid xs={16} margin={1}>
                  <TextField
                    required
                    id='image'
                    label='Company Image'
                    type='file'
                    placeholder='image'
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ accept: 'image/png, image/gif, image/jpeg', shrink: true }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const files = e && e.target && e.target.files
                      if (files && files.length > 0 && !isEmpty(files)) {
                        formik.setFieldValue('image', files[0])
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const reader: any = new FileReader()
                        reader.onload = () => {
                          setPreviewImage(reader.result)
                        }
                        reader.readAsDataURL(files[0])
                      } else {
                        setPreviewImage('')
                      }
                    }}
                    onBlur={formik.handleBlur}
                    fullWidth
                  />
                  <Box {...DialogStyle.wrapperImage}>
                    {!previewImage && (
                      <Typography style={{ color: '#cfcfcf' }}>
                        Company Image Cannot be Empty
                      </Typography>
                    )}
                    {previewImage && (
                      <BackdropImage
                        open={previewBackdrop}
                        onOpen={() => setPreviewBackdrop(true)}
                        onClose={() => setPreviewBackdrop(false)}
                      >
                        <img
                          src={previewImage}
                          alt='Preview Image'
                          style={{ height: '300px', width: '394px', objectFit: 'contain' }}
                        />
                      </BackdropImage>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </FormikProvider>
        </Container>
      </Dialog>

      <Notification
        open={!create.isLoading && !create.isUninitialized}
        onClose={() => (create.isError ? create.reset() : location.reload())}
        isError={Boolean(create.error) && create.isError}
        message={GLOBAL.returnExceptionMessage(create.isError, create.error as ErrorProps)}
      />
    </>
  )
}

export default Create
