import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { IconButton, Menu, MenuItem, TableCell, TableRow } from '@mui/material'
import moment from 'moment'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import type { PartialFormDataProps, PartialVoucherFAQFilterProps } from 'modules/partial'
import CONSTANT from 'modules/constant'
import { useGetListVoucherFaqMutation } from 'store/voucherFAQ'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { ErrorProps } from 'modules/types'
import Icon from 'components/icon'
import Loading from 'components/loading/loading'
import Detail from './detail/detail'
import Edit from './edit/edit'

const VoucherFAQ = () => {
  const [getListVoucher, listVoucher] = useGetListVoucherFaqMutation()

  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [menu, setMenu] = useState<null | number>(null)
  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [dialogUpdate, setDialogUpdate] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)

  const [filter, setFilter] = useState<PartialVoucherFAQFilterProps>({
    minValidFrom: '',
    maxValidFrom: '',
    minValidUntil: '',
    maxValidUntil: ''
  })

  const [payload, setPayload] = useState<PartialVoucherFAQFilterProps & PartialFormDataProps>({
    start: 0,
    length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    field: CONSTANT.DEFAULT_VOUCHER_FAQ_FIELD.join(','),
    search: { value: '' },
    minValidFrom: '',
    maxValidFrom: '',
    minValidUntil: '',
    maxValidUntil: ''
  })

  const onSearch = (value: string) => setPayload({ ...payload, start: 0, search: { value } })

  const onChangePage = (value: number) => setPayload({ ...payload, start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, length: value })

  const onResync = () => {
    const formData = new FormData()

    formData.append('start', payload.start.toString())
    formData.append('length', payload.length.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(payload.status) && payload.status) formData.append('status', payload.status)

    if (!isEmpty(payload.minValidFrom) && payload.minValidFrom)
      formData.append('min-valid-from', payload.minValidFrom)

    if (!isEmpty(payload.maxValidFrom) && payload.maxValidFrom)
      formData.append('max-valid-from', payload.maxValidFrom)

    if (!isEmpty(payload.minValidUntil) && payload.minValidUntil)
      formData.append('min-valid-until', payload.minValidUntil)

    if (!isEmpty(payload.maxValidUntil) && payload.maxValidUntil)
      formData.append('max-valid-until', payload.maxValidUntil)

    getListVoucher(formData)
  }

  const onFilter = (state: boolean) => setDialogFilter(state)

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback: CallableFunction) => {
    setAnchor(null)
    callback(callback)
  }

  const tableHeadTitles = ['Partner Name', 'Voucher Name', 'Valid From', 'Valid Until']

  const openAnchor = Boolean(anchor)

  const totalPage = listVoucher.data && GLOBAL.tableTotalPage(listVoucher.data.recordsTotal)

  const numbers = GLOBAL.tableNumber(payload.start)

  useEffect(() => {
    onResync()
  }, [payload])

  return (
    <>
      <Content
        title='Voucher FAQ'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onFilter={() => onFilter(true)}
        additionalTitle='Create New Voucher FAQ'
        additionalPrefixIcon='Create'
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={listVoucher.data && listVoucher.data.recordsTotal}
        page={payload.start + 1}
        isLoading={listVoucher.isLoading}
        useDownloadDropdown
      >
        {listVoucher.isSuccess &&
          listVoucher.data &&
          listVoucher.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.id}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.id}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.id}`}
                  aria-labelledby={`button-${index}-${table.id}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View
                  </MenuItem>
                  <MenuItem onClick={() => onRemoveAnchor(() => setDialogUpdate(true))}>
                    Edit
                  </MenuItem>
                </Menu>
                <Detail
                  id={table.id}
                  open={drawerDetail && menu == index}
                  onClose={() => setDrawerDetail(false)}
                />
                <Edit
                  id={table.id}
                  open={dialogUpdate && menu == index}
                  onClose={() => setDialogUpdate(false)}
                />
              </TableCell>
              <TableCell>{table.partnerName || '-'}</TableCell>
              <TableCell>{table.title || '-'}</TableCell>
              <TableCell>
                {table.validFrom ? moment(table.validFrom).format('DD-MM-YYYY HH:mm:ss') : '-'}
              </TableCell>
              <TableCell>
                {table.validUntil ? moment(table.validUntil).format('DD-MM-YYYY HH:mm:ss') : '-'}
              </TableCell>
            </TableRow>
          ))}
      </Content>

      {listVoucher.isLoading && <Loading />}

      <Filter
        open={dialogFilter}
        title='Filter'
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        onReset={() => {
          setFilter({
            minValidFrom: '',
            maxValidFrom: '',
            minValidUntil: '',
            maxValidUntil: ''
          })
          setPayload({
            ...payload,
            start: 0,
            minValidFrom: '',
            maxValidFrom: '',
            minValidUntil: '',
            maxValidUntil: ''
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({ ...payload, start: 0, ...filter })
          onFilter(false)
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
          <DatePicker
            value={filter.minValidFrom ? dayjs(filter.minValidFrom) : null}
            minDate={dayjs(filter.minValidFrom)}
            onChange={(event: Dayjs | null) =>
              setFilter({ ...filter, minValidFrom: (event && event.format('YYYY-MM-DD')) || '' })
            }
            label='Min Valid From'
            format='DD-MM-YYYY'
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
          <DatePicker
            value={filter.maxValidFrom ? dayjs(filter.maxValidFrom) : null}
            minDate={dayjs(filter.minValidFrom)}
            onChange={(event: Dayjs | null) =>
              setFilter({ ...filter, maxValidFrom: (event && event.format('YYYY-MM-DD')) || '' })
            }
            label='Max Valid From'
            format='DD-MM-YYYY'
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
          <DatePicker
            value={filter.minValidUntil ? dayjs(filter.minValidUntil) : null}
            minDate={dayjs(filter.minValidFrom)}
            onChange={(event: Dayjs | null) =>
              setFilter({ ...filter, minValidUntil: (event && event.format('YYYY-MM-DD')) || '' })
            }
            label='Min Valid Until'
            format='DD-MM-YYYY'
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
          <DatePicker
            value={filter.maxValidUntil ? dayjs(filter.maxValidUntil) : null}
            minDate={dayjs(filter.minValidFrom)}
            onChange={(event: Dayjs | null) =>
              setFilter({ ...filter, maxValidUntil: (event && event.format('YYYY-MM-DD')) || '' })
            }
            label='Max Valid Until'
            format='DD-MM-YYYY'
          />
        </LocalizationProvider>
      </Filter>

      <Notification
        open={!listVoucher.isLoading && !listVoucher.isUninitialized && !listVoucher.isSuccess}
        onClose={() => (listVoucher.isError ? listVoucher.reset() : location.reload())}
        isError={Boolean(listVoucher.error) && listVoucher.isError}
        message={GLOBAL.returnExceptionMessage(
          listVoucher.isError,
          listVoucher.error as ErrorProps
        )}
      />
    </>
  )
}

export default VoucherFAQ
