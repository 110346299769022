import {
  AppBar,
  Box,
  Button,
  IconButton,
  Drawer as SideDrawer,
  Toolbar,
  Typography
} from '@mui/material'
import Icon from 'components/icon'
import type { DetailWithButtonStateProps } from 'modules/types'
import DetailStyle from './style'

// const drawerWidth = 300;

const DrawerWithButton = ({
  open,
  title,
  children,
  hasButton = false,
  onClose,
  onPress
}: DetailWithButtonStateProps & { hasButton: boolean }) => (
  <SideDrawer
    anchor='right'
    open={open}
    onClose={onClose}
    sx={{ zIndex: 1202 }}
    PaperProps={{
      sx: { width: 512 }
    }}
  >
    <Box {...DetailStyle.Container}>
      <AppBar
        position='fixed'
        sx={{
          width: 512,
          backgroundColor: 'white'
        }}
      >
        <Toolbar>
          <Typography color={'black'} variant='h6'>
            {title}
          </Typography>
          {hasButton && (
            <div style={{ marginLeft: 20 }}>
              <Button variant='contained' onClick={onPress}>
                Change Car Sharing
              </Button>
            </div>
          )}
          <div style={{ position: 'absolute', right: 8 }}>
            <IconButton onClick={onClose}>
              <Icon icon='Close' />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
    {children}
  </SideDrawer>
)

export default DrawerWithButton
