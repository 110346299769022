import type { ContainerProps } from '@mui/material'

class LayoutStyle {
  static Container: ContainerProps = {
    maxWidth: false,
    disableGutters: true
  }

  static Wrapper: ContainerProps = {
    maxWidth: false,
    disableGutters: true,
    sx: {
      display: 'flex',
      flexDirection: 'column'
    }
  }

  static Content: ContainerProps = {
    maxWidth: false,
    disableGutters: true,
    sx: {
      paddingTop: '5rem',
      paddingLeft: '5rem'
    }
  }
}

export default LayoutStyle
