import type {
  BoxProps,
  ButtonProps,
  ContainerProps,
  FilledInputProps,
  TableCellProps,
  TableContainerProps,
  TypographyProps
} from '@mui/material'

class ContentStyle {
  static Container: ContainerProps = {
    maxWidth: false,
    disableGutters: true,
    sx: {
      display: 'flex',
      padding: 2,
      gap: 2,
      flexDirection: 'column'
    }
  }

  static Header: BoxProps = {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    sx: {
      overflowX: 'auto'
    }
  }

  static Navigation: BoxProps = {
    display: 'flex',
    gap: 1,
    alignItems: 'center'
  }

  static Title: TypographyProps = {
    variant: 'h6',
    fontWeight: 500
  }

  static Menu: BoxProps = {
    display: 'flex',
    gap: 2,
    alignItems: 'center'
  }

  static NoWrap: TableCellProps = {
    sx: {
      whiteSpace: 'nowrap'
    }
  }

  static Search: FilledInputProps = {
    hiddenLabel: true
  }

  static Download: ButtonProps = {
    sx: {
      whiteSpace: 'nowrap'
    }
  }

  static Additional: ButtonProps = {
    sx: {
      whiteSpace: 'nowrap'
    }
  }

  static Table: TableContainerProps = {
    sx: {
      maxHeight: 512
    }
  }

  static NotFound: ContainerProps = {
    maxWidth: false,
    disableGutters: true,
    sx: {
      display: 'flex',
      width: '100%',
      padding: '2rem',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }

  static PaginationWrapper: BoxProps = {
    display: 'flex',
    alignItems: 'center',
    gap: 4
  }

  static Pagination: ContainerProps = {
    maxWidth: false,
    disableGutters: true,
    sx: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      overflowX: 'auto'
    }
  }
}

export default ContentStyle
