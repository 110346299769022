import type { LoadingButtonProps } from '@mui/lab'
import type { ContainerProps, TypographyProps } from '@mui/material'

class DrawerStyle {
  static Container: ContainerProps = {
    maxWidth: false,
    disableGutters: true,
    sx: {
      display: 'flex',
      padding: 2,
      gap: '1rem',
      flexDirection: 'column'
    }
  }

  static Title: TypographyProps = {
    fontWeight: 'bold'
  }

  static Submit: LoadingButtonProps = {
    sx: {
      alignSelf: 'end'
    }
  }
}

export default DrawerStyle
