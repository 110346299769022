import {
  Container,
  Grid,
  TextField,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails
} from '@mui/material'
import { ExpandMoreOutlined } from '@mui/icons-material'
import Drawer from 'components/drawer/detail'
import Loading from 'components/loading/loading'
import { useGetDetailHyundaiCatalogQuery } from 'store/hyundaiModel'
import type { DetailStateProps } from 'modules/types'
import DetailStyle from './style'

const Detail = ({ open, id, onClose }: DetailStateProps & { id: number }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const { data, isLoading, isFetching } = useGetDetailHyundaiCatalogQuery(id)

  const trim = (data && data.trim) || '-'
  const isDefault = (data && data.isDefault ? 'Yes' : 'No') || '-'
  const year = (data && data.year) || '-'
  const color = (data && data.color) || '-'
  const colorCode = (data && data.colorCode) || '-'
  const carModelId = (data && data.carModelId) || '-'
  const referenceId = (data && data.referenceId) || '-'

  const description = (data && data.description) || '-'

  const gridDataLabel = [
    'Trim',
    'Year',
    'Color',
    'Color Code',
    'Model',
    'Default',
    'Reference ID',
    'Description'
  ]
  const gridDataValue = [
    trim,
    year,
    color,
    colorCode,
    carModelId,
    isDefault,
    referenceId,
    description
  ]

  const createdBy = (data && data.createdBy) || '-'
  const createdAt = (data && data.createdAt) || '-'
  const modifiedBy = (data && data.modifiedBy) || '-'
  const modifiedAt = (data && data.modifiedAt) || '-'

  const gridBasicLabel = ['Created By', 'Created At', 'Modified By', 'Modified At']
  const gridBasicValue = [createdBy, createdAt, modifiedBy, modifiedAt]

  return (
    <Drawer open={open} title='Hyundai Catalog Detail' onClose={onClose}>
      <Container {...DetailStyle.Container}>
        {(isLoading || isFetching) && !data && <Loading />}
        {data && (
          <Grid container spacing={2}>
            {gridDataValue.map((item, index) => (
              <Grid key={index} item xs={12}>
                <TextField
                  variant='outlined'
                  label={gridDataLabel[index]}
                  value={item}
                  inputProps={{ readOnly: true }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  fullWidth
                />
              </Grid>
            ))}
          </Grid>
        )}
        {data && (
          <Accordion elevation={0} disableGutters>
            <AccordionSummary expandIcon={<ExpandMoreOutlined />} aria-controls='basic-information'>
              <Typography>Basic Information</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {gridBasicValue.map((item, index) => (
                  <Grid key={index} item xs={12}>
                    <TextField
                      variant='outlined'
                      label={gridBasicLabel[index]}
                      value={item}
                      inputProps={{ readOnly: true }}
                      fullWidth
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
      </Container>
    </Drawer>
  )
}

export default Detail
