import * as yup from 'yup'
import { Container, TextField } from '@mui/material'
import Dialog from 'components/dialog/dialog'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import type { PartialEvChargingUrlProps } from 'modules/partial'
import FORMIK from 'modules/formik'
import { useCreateChargingUrlMutation } from 'store/evChargingUrl'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import DialogStyle from 'components/dialog/style'

const Create = ({ open, onClose }: DetailStateProps) => {
  if (!open) return <></>

  const [createChargingUrl, create] = useCreateChargingUrlMutation()

  const scheme = yup.object<PartialEvChargingUrlProps>({
    code: yup.string().required('Code is required'),
    url: yup.string().required('URL is required'),
    remark: yup.string().required('Notes is required'),
    active: yup.boolean()
  })

  const initialValues: PartialEvChargingUrlProps = {
    code: '',
    url: '',
    remark: '',
    active: true
  }

  const formik = FORMIK.useFormFormik<PartialEvChargingUrlProps>(scheme, initialValues, (values) =>
    createChargingUrl(values)
  )

  const errorData = formik.errors

  return (
    <>
      <Dialog
        title='Create New EV Charging URL'
        open={open}
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={create.isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          <TextField
            id='code'
            variant='outlined'
            label='QR Value'
            value={formik.values.code}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched && formik.touched.code && Boolean(errorData.code)}
            helperText={formik.touched && formik.touched.code && errorData && errorData.code}
            fullWidth
          />
          <TextField
            id='url'
            variant='outlined'
            label='Charge Box ID'
            value={formik.values.url}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched && formik.touched.url && Boolean(errorData.url)}
            helperText={formik.touched && formik.touched.url && errorData && errorData.url}
            fullWidth
          />
          <TextField
            id='remark'
            variant='outlined'
            label='Notes'
            value={formik.values.remark}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched && formik.touched.remark && Boolean(errorData.remark)}
            helperText={formik.touched && formik.touched.remark && errorData && errorData.remark}
            fullWidth
          />
        </Container>
      </Dialog>

      <Notification
        open={!create.isLoading && !create.isUninitialized}
        onClose={() => (create.isError ? create.reset() : location.reload())}
        isError={Boolean(create.error) && create.isError}
        message={GLOBAL.returnExceptionMessage(create.isError, create.error as ErrorProps)}
      />
    </>
  )
}

export default Create
