import { isEmpty } from 'lodash'
import { Link } from 'react-router-dom'
import { Box, Breadcrumbs, Stack, Typography } from '@mui/material'
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs'
import Menu from 'components/menu/menu'
import MenuLink from 'assets/menu.json'
import BreadcrumbStyle from './style'

const Breadcrumb = () => {
  const breadcrumbs = useReactRouterBreadcrumbs()
  const current = MenuLink.find((menu) => breadcrumbs.some((bread) => menu.path == bread.key))
  const title = (current && current.name) || breadcrumbs[breadcrumbs.length > 1 ? 1 : 0].breadcrumb

  return (
    <>
      <Box {...BreadcrumbStyle.Container}>
        <Typography {...BreadcrumbStyle.Title}>{title}</Typography>
        <Stack>
          <Breadcrumbs separator='›' aria-label='breadcrumb' sx={{ textTransform: 'lowercase' }}>
            {breadcrumbs.map(({ breadcrumb, key }, index) => (
              <Link key={index} {...BreadcrumbStyle.Link} to={key}>
                {breadcrumb}
              </Link>
            ))}
          </Breadcrumbs>
        </Stack>
      </Box>
      {!isEmpty(current) && !isEmpty(current.menu) && <Menu menu={current.menu} />}
    </>
  )
}

export default Breadcrumb
