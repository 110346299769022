import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Autocomplete,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import {
  useDeleteChargePointMutation,
  useDownloadChargePointListMutation,
  useGetChargePointListMutation,
  useResyncChargePointListMutation
} from 'store/evChargePoint'
import CONSTANT from 'modules/constant'
import type { PartialChargePointFilterProps, PartialEVChargeProps } from 'modules/partial'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { ErrorProps } from 'modules/types'
import MenuModalItem from 'components/menuItem/menuItem'
import GlobalStyle from 'modules/styles'
import Icon from 'components/icon'
import Loading from 'components/loading/loading'
import { useGetOwnerDropdownListMutation } from 'store/owner'
import { useGetPartnerDropdownListMutation } from 'store/partner'
import { useGetPolicyDropdownListMutation } from 'store/policy'
import { useGetCityDropdownListMutation } from 'store/dropdown'
import Detail from './detail/detail'
import Create from './create/create'
import Edit from './edit/edit'

const EVChargePointList = () => {
  const navigate = useNavigate()

  const [getChargePoint, chargePoint] = useGetChargePointListMutation()
  const [resyncChargePoint, resync] = useResyncChargePointListMutation()
  const [downloadChargePoint, download] = useDownloadChargePointListMutation()
  const [deleteChargePoint, deletePoint] = useDeleteChargePointMutation()

  const [getOwnerDropdownList, ownerDropdown] = useGetOwnerDropdownListMutation()
  const [getPartnerDropdownList, partnerDropdown] = useGetPartnerDropdownListMutation()
  const [getPolicyDropdownList, policyDropdown] = useGetPolicyDropdownListMutation()
  const [getCityDropdownList, cityDropdown] = useGetCityDropdownListMutation()

  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [menu, setMenu] = useState<null | number>(null)
  const [dialogCreate, setDialogCreate] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [drawerUpdate, setDrawerUpdate] = useState<boolean>(false)

  const [filter, setFilter] = useState<PartialChargePointFilterProps>({
    OwnerId: '',
    PartnerId: '',
    PolicyId: '',
    CityId: ''
  })

  const [payload, setPayload] = useState<PartialEVChargeProps & PartialChargePointFilterProps>({
    start: 0,
    length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    search: '',
    filter: '',
    sorting: 'name asc',
    OwnerId: '',
    PartnerId: '',
    PolicyId: '',
    CityId: ''
  })

  const onResync = () => {
    const formData = new FormData()

    formData.append('start', payload.start.toString())
    formData.append('length', payload.length.toString())
    formData.append('sorting', payload.sorting)

    if (!isEmpty(payload.search)) formData.append('search', payload.search)
    if (!isEmpty(payload.filter)) formData.append('filter', payload.filter)
    if (!isEmpty(payload.OwnerId)) formData.append('OwnerId', payload.OwnerId)
    if (!isEmpty(payload.PartnerId)) formData.append('PartnerId', payload.PartnerId)
    if (!isEmpty(payload.PolicyId)) formData.append('PolicyId', payload.PolicyId)
    if (!isEmpty(payload.CityId)) formData.append('CityId', payload.CityId)

    getChargePoint(formData)
  }

  const onResyncThirdParty = async () => await resyncChargePoint().then(() => onResync())

  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('start', String(0))
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())
    formData.append('sorting', payload.sorting)

    if (!isEmpty(payload.search)) formData.append('search', payload.search)
    if (!isEmpty(payload.filter)) formData.append('filter', payload.filter)
    if (!isEmpty(payload.OwnerId)) formData.append('OwnerId', payload.OwnerId)
    if (!isEmpty(payload.PartnerId)) formData.append('PartnerId', payload.PartnerId)
    if (!isEmpty(payload.PolicyId)) formData.append('PolicyId', payload.PolicyId)
    if (!isEmpty(payload.CityId)) formData.append('CityId', payload.CityId)
    if (type && !isEmpty(type)) formData.append('Content-Type', type)

    downloadChargePoint(formData)
  }

  const onSearch = (value: string) => setPayload({ ...payload, start: 0, search: value })
  const onChangePage = (value: number) => setPayload({ ...payload, start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, length: value })

  const onFilter = (state: boolean) => setDialogFilter(state)
  const onCreate = () => setDialogCreate(true)

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const tableHeadTitles = [
    'EV Charge Point',
    'Owner',
    'Partner',
    'Charger Policy',
    'Quota Limit',
    'kWh Limit',
    'City',
    'Connectivity'
  ]

  const openAnchor = Boolean(anchor)

  const totalPage = chargePoint.data && GLOBAL.tableTotalPage(chargePoint.data.recordsTotal)

  const numbers = GLOBAL.tableNumber(payload.start)

  useEffect(() => {
    onResync()
  }, [payload])

  return (
    <>
      <Content
        title='EV Charge Point'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResyncThirdParty}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        onAdditional={onCreate}
        additionalTitle='Create New EV Charge Point'
        additionalPrefixIcon='Create'
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={chargePoint.data && chargePoint.data.recordsTotal}
        page={payload.start + 1}
        isLoading={chargePoint.isLoading || resync.isLoading}
        useDownloadDropdown
      >
        {chargePoint.isSuccess &&
          chargePoint.data &&
          chargePoint.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.id}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.id}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.id}`}
                  aria-labelledby={`button-${index}-${table.id}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View
                  </MenuItem>
                  <MenuItem onClick={() => onRemoveAnchor(() => navigate(String(table.id)))}>
                    View Box Group
                  </MenuItem>
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerUpdate(true))}>
                    Edit
                  </MenuItem>
                  <MenuModalItem
                    title='Delete'
                    loading={deletePoint.isLoading}
                    onClick={() => onRemoveAnchor(() => deleteChargePoint(table.id))}
                  >
                    <Typography {...GlobalStyle.ErrorTypography}>Delete</Typography>
                  </MenuModalItem>
                </Menu>
                <Detail
                  id={table.id}
                  open={drawerDetail && menu == index}
                  onClose={() => setDrawerDetail(false)}
                />
                <Edit
                  id={table.id}
                  open={drawerUpdate && menu == index}
                  onClose={() => setDrawerUpdate(false)}
                />
              </TableCell>
              <TableCell>{table.name || '-'}</TableCell>
              <TableCell>{table.ownerName || '-'}</TableCell>
              <TableCell>{table.partnerName || '-'}</TableCell>
              <TableCell>{table.policyName || '-'}</TableCell>
              <TableCell>{table.quotaLimit || '-'}</TableCell>
              <TableCell>{table.kwhLimit || '-'}</TableCell>
              <TableCell>{table.city || '-'}</TableCell>
              <TableCell>{table.operative ? 'Online' : 'Offline' || '-'}</TableCell>
            </TableRow>
          ))}
      </Content>

      {(chargePoint.isLoading || resync.isLoading) && <Loading />}

      <Create open={dialogCreate} onClose={() => setDialogCreate(false)} />

      <Filter
        open={dialogFilter}
        title='Filter'
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
        onReset={() => {
          setFilter({
            OwnerId: '',
            PartnerId: '',
            PolicyId: '',
            CityId: ''
          })
          setPayload({
            ...payload,
            OwnerId: '',
            PartnerId: '',
            PolicyId: '',
            CityId: ''
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({
            ...payload,
            ...filter,
            start: 0
          })
          onFilter(false)
        }}
      >
        <Autocomplete
          options={(ownerDropdown && ownerDropdown.data) || []}
          getOptionLabel={(option) => option.text}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.value == value.value : false
          }
          onOpen={() => getOwnerDropdownList(CONSTANT.DEFAULT_DROPDOWN_PAYLOAD)}
          onChange={(_, owner) =>
            owner &&
            setFilter({
              ...filter,
              OwnerId: String(owner.value)
            })
          }
          value={
            (ownerDropdown &&
              ownerDropdown.data &&
              ownerDropdown.data.find((e) => String(e.value) == filter.OwnerId)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.value}>
              {item.text}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='OwnerId'
              label='Owner'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {ownerDropdown.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <Autocomplete
          options={(partnerDropdown && partnerDropdown.data) || []}
          getOptionLabel={(option) => option.text}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.value == value.value : false
          }
          onOpen={() => getPartnerDropdownList(CONSTANT.DEFAULT_DROPDOWN_PAYLOAD)}
          onChange={(_, partner) =>
            partner &&
            setFilter({
              ...filter,
              PartnerId: String(partner.value)
            })
          }
          value={
            (partnerDropdown &&
              partnerDropdown.data &&
              partnerDropdown.data.find((e) => String(e.value) == filter.PartnerId)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.value}>
              {item.text}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='PartnerId'
              label='Partner'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {partnerDropdown.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <Autocomplete
          options={(policyDropdown && policyDropdown.data) || []}
          getOptionLabel={(option) => option.text}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.value == value.value : false
          }
          onOpen={() => getPolicyDropdownList(CONSTANT.DEFAULT_DROPDOWN_PAYLOAD)}
          onChange={(_, policy) =>
            policy &&
            setFilter({
              ...filter,
              PolicyId: String(policy.value)
            })
          }
          value={
            (policyDropdown &&
              policyDropdown.data &&
              policyDropdown.data.find((e) => String(e.value) == filter.PolicyId)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.value}>
              {item.text}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='PolicyId'
              label='Policy'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {policyDropdown.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <Autocomplete
          options={(cityDropdown && cityDropdown.data) || []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.name == value.name : false
          }
          onOpen={() => getCityDropdownList()}
          onChange={(_, city) => city && setFilter({ ...filter, CityId: String(city.id) })}
          value={
            (cityDropdown &&
              cityDropdown.data &&
              cityDropdown.data.find((e) => String(e.id) == filter.CityId)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.id}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='CityId'
              label='City'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {cityDropdown.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
      </Filter>

      <Notification
        open={!chargePoint.isLoading && !chargePoint.isUninitialized && !chargePoint.isSuccess}
        onClose={() => (chargePoint.isError ? chargePoint.reset() : location.reload())}
        isError={Boolean(chargePoint.error) && chargePoint.isError}
        message={GLOBAL.returnExceptionMessage(
          chargePoint.isError,
          chargePoint.error as ErrorProps
        )}
      />

      <Notification
        open={!resync.isLoading && !resync.isUninitialized}
        onClose={() => resync.reset()}
        isError={Boolean(resync.error) && resync.isError}
        message={CONSTANT.DEFAULT_RESYNC_MESSAGE}
      />

      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />

      <Notification
        open={!deletePoint.isLoading && !deletePoint.isUninitialized}
        onClose={() => (deletePoint.isError ? deletePoint.reset() : location.reload())}
        isError={Boolean(deletePoint.error) && deletePoint.isError}
        message={GLOBAL.returnExceptionMessage(
          deletePoint.isError,
          deletePoint.error as ErrorProps
        )}
      />
    </>
  )
}

export default EVChargePointList
