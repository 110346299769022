import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import type { Dayjs } from 'dayjs'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import CONSTANT from 'modules/constant'
import Icon from 'components/icon'
import MenuModalItem from 'components/menuItem/menuItem'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { ErrorProps } from 'modules/types'
import GlobalStyle from 'modules/styles'
import type { PartialTokenCategoryProps } from 'modules/partial'
import ModalConfirm from 'components/modalConfirm/modalConfirm'
import {
  useDeleteVoucherGiftMutation,
  useDownloadTemplateGiftMutation,
  useDownloadVoucherGiftMutation,
  useGetGiftAssignByDropdownMutation,
  useGetGiftAssignToDropdownMutation,
  useGetListVoucherGiftMutation,
  useGetVoucherGiftDropdownMutation,
  useSendVoucherGiftMutation
} from 'store/voucherGift'
import { useGetVoucherMemberDropdownMutation } from 'store/voucherBenefits'
import Create from './create/create'
import Detail from './detail/detail'
import Edit from './edit/edit'
import Upload from './upload/upload'

const VoucherGift = () => {
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [dialogCreate, setDialogCreate] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [dialogUpdate, setDialogUpdate] = useState<boolean>(false)
  const [dialogUpload, setDialogUpload] = useState<boolean>(false)
  const [menu, setMenu] = useState<null | number>(null)
  const [listChecked, setListChecked] = useState([])
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const [action, setAction] = useState<boolean>(false)
  const [minDate, setMinDate] = useState<Dayjs | null>(null)
  const [maxDate, setMaxDate] = useState<Dayjs | null>(null)
  const [minSendDate, setMinSendDate] = useState<Dayjs | null>(null)
  const [maxSendDate, setMaxSendDate] = useState<Dayjs | null>(null)
  const [minFromDate, setMinFromDate] = useState<Dayjs | null>(null)
  const [maxFromDate, setMaxFromDate] = useState<Dayjs | null>(null)
  const [minUntilDate, setMinUntilDate] = useState<Dayjs | null>(null)
  const [maxUntilDate, setMaxUntilDate] = useState<Dayjs | null>(null)
  const [getListVoucherGift, voucherGift] = useGetListVoucherGiftMutation()
  const [downloadVoucherGift, download] = useDownloadVoucherGiftMutation()
  const [deleteVoucherGift, deleteGift] = useDeleteVoucherGiftMutation()
  const [getVoucherGiftDropdown, dropdownVoucher] = useGetVoucherGiftDropdownMutation()
  const [getGiftAssignToDropdown, dropdownAssignTo] = useGetGiftAssignToDropdownMutation()
  const [getGiftAssignByDropdown, dropdownAssignBy] = useGetGiftAssignByDropdownMutation()
  const [getVoucherMemberDropdown, voucherMemberId] = useGetVoucherMemberDropdownMutation()
  const [downloadTemplateGift, template] = useDownloadTemplateGiftMutation()
  const [sendVoucherGift, send] = useSendVoucherGiftMutation()

  const [payload, setPayload] = useState<PartialTokenCategoryProps>({
    start: 0,
    length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    field: CONSTANT.DEFAULT_VOUCHER_GIFT_FIELD.join(','),
    search: { value: '' },
    status: '',
    monetaryValue: '',
    companyId: ''
  })

  const [filter, setFilter] = useState({
    voucherId: '',
    memberTier: '',
    assignMode: '',
    assignBy: '',
    assignTo: '',
    status: '',
    MinCreated: '',
    MaxCreated: '',
    MinSend: '',
    MaxSend: '',
    MinFrom: '',
    MaxFrom: '',
    MinUntil: '',
    MaxUntil: ''
  })

  const onSearch = (value: string) => setPayload({ ...payload, start: 0, search: { value } })
  const onResync = () => {
    const formData = new FormData()
    formData.append('start', payload.start.toString())
    formData.append('length', payload.length.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(filter.MinCreated)) formData.append('min-created-date', filter.MinCreated)
    if (!isEmpty(filter.MaxCreated)) formData.append('max-created-date', filter.MaxCreated)
    if (!isEmpty(filter.MinSend)) formData.append('min-send-date', filter.MinSend)
    if (!isEmpty(filter.MaxSend)) formData.append('max-send-date', filter.MaxSend)
    if (!isEmpty(filter.MinFrom)) formData.append('min-Valid-from', filter.MinFrom)
    if (!isEmpty(filter.MaxFrom)) formData.append('max-valid-from', filter.MaxFrom)
    if (!isEmpty(filter.MinUntil)) formData.append('min-valid-until', filter.MinUntil)
    if (!isEmpty(filter.MaxUntil)) formData.append('max-valid-until', filter.MaxUntil)
    if (!isEmpty(filter.voucherId)) formData.append('voucher-id', filter.voucherId)
    if (!isEmpty(filter.assignBy)) formData.append('assignedby', filter.assignBy)
    if (!isEmpty(filter.assignTo)) formData.append('assignedto', filter.assignTo)
    if (!isEmpty(filter.assignMode)) formData.append('assign-mode', filter.assignMode)
    if (!isEmpty(filter.memberTier)) formData.append('member-tier', filter.memberTier)
    if (!isEmpty(filter.status)) formData.append('sendstatus', filter.status)

    getListVoucherGift(formData)
  }
  const onFilter = (state: boolean) => setDialogFilter(state)
  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('start', String(0))
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(filter.MinCreated)) formData.append('min-created-date', filter.MinCreated)
    if (!isEmpty(filter.MaxCreated)) formData.append('max-created-date', filter.MaxCreated)
    if (!isEmpty(filter.MinSend)) formData.append('min-send-date', filter.MinSend)
    if (!isEmpty(filter.MaxSend)) formData.append('max-send-date', filter.MaxSend)
    if (!isEmpty(filter.MinFrom)) formData.append('min-Valid-from', filter.MinFrom)
    if (!isEmpty(filter.MaxFrom)) formData.append('max-valid-from', filter.MaxFrom)
    if (!isEmpty(filter.MinUntil)) formData.append('min-valid-until', filter.MinUntil)
    if (!isEmpty(filter.MaxUntil)) formData.append('max-valid-until', filter.MaxUntil)
    if (!isEmpty(filter.voucherId)) formData.append('voucher-id', filter.voucherId)
    if (!isEmpty(filter.assignBy)) formData.append('assignedby', filter.assignBy)
    if (!isEmpty(filter.assignTo)) formData.append('assignedto', filter.assignTo)
    if (!isEmpty(filter.assignMode)) formData.append('assign-mode', filter.assignMode)
    if (!isEmpty(filter.memberTier)) formData.append('member-tier', filter.memberTier)
    if (!isEmpty(filter.status)) formData.append('sendstatus', filter.status)
    if (type && !isEmpty(type)) formData.append('Download-Type', type)

    downloadVoucherGift(formData)
  }
  const onCreate = () => setDialogCreate(true)

  const onChangePage = (value: number) => setPayload({ ...payload, start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, length: value })

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const dataStatus = [
    { value: 'Enabled', name: 'Enabled' },
    { value: 'Disabled', name: 'Disabled' }
  ]

  const tableHeadTitles = [
    'Member ID',
    'Gift Name',
    'Voucher Name',
    'Assigned To',
    'Assigned By',
    'Send Status',
    'Send Date'
  ]

  const openAnchor = Boolean(anchor)

  const totalPage = voucherGift.data && GLOBAL.tableTotalPage(voucherGift.data.recordsTotal)

  const numbers = GLOBAL.tableNumber(payload.start)

  useEffect(() => {
    onResync()
  }, [payload])

  const onDownloadTemplate = (type?: string) => {
    const formData = new FormData()
    if (type && !isEmpty(type)) formData.append('Download-Type', type)
    downloadTemplateGift(formData)
  }

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) =>
    event.target.checked
      ? setListChecked([...listChecked, Number(event.target.value)] as never)
      : setListChecked([...listChecked].filter((o) => o !== Number(event.target.value)))

  const handleCheckAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(!isChecked)

    return event.target.checked
      ? setListChecked([
          ...listChecked,
          ...(((voucherGift.data &&
            voucherGift.data.data.filter((y) => y.isSend).map((x) => x.id)) ||
            undefined) as never)
        ])
      : setListChecked([])
  }

  const handleChangeMinCreated = (date: Dayjs | null) => {
    setMinDate(date)
    const newDate = (!isEmpty(date) && date?.format('YYYY-MM-DD')) || ''
    setFilter({ ...filter, MinCreated: newDate, MaxCreated: '' })
    setMaxDate(null)
  }

  const handleChangeMaxCreated = (date: Dayjs | null) => {
    setMaxDate(date)
    const newDate = (!isEmpty(date) && date?.format('YYYY-MM-DD')) || ''
    setFilter({ ...filter, MaxCreated: newDate })
  }

  const handleChangeMinSend = (date: Dayjs | null) => {
    setMinSendDate(date)
    const newDate = (!isEmpty(date) && date?.format('YYYY-MM-DD')) || ''
    setFilter({ ...filter, MinSend: newDate, MaxSend: '' })
    setMaxSendDate(null)
  }

  const handleChangeMaxSend = (date: Dayjs | null) => {
    setMaxSendDate(date)
    const newDate = (!isEmpty(date) && date?.format('YYYY-MM-DD')) || ''
    setFilter({ ...filter, MaxSend: newDate })
  }

  const handleChangeMinFrom = (date: Dayjs | null) => {
    setMinFromDate(date)
    const newDate = (!isEmpty(date) && date?.format('YYYY-MM-DD')) || ''
    setFilter({ ...filter, MinFrom: newDate, MaxFrom: '' })
    setMaxFromDate(null)
  }

  const handleChangeMaxFrom = (date: Dayjs | null) => {
    setMaxFromDate(date)
    const newDate = (!isEmpty(date) && date?.format('YYYY-MM-DD')) || ''
    setFilter({ ...filter, MaxFrom: newDate })
  }

  const handleChangeMinUntil = (date: Dayjs | null) => {
    setMinUntilDate(date)
    const newDate = (!isEmpty(date) && date?.format('YYYY-MM-DD')) || ''
    setFilter({ ...filter, MinUntil: newDate, MaxUntil: '' })
    setMaxUntilDate(null)
  }

  const handleChangeMaxUntil = (date: Dayjs | null) => {
    setMaxUntilDate(date)
    const newDate = (!isEmpty(date) && date?.format('YYYY-MM-DD')) || ''
    setFilter({ ...filter, MaxUntil: newDate })
  }

  const masterAssignmentMode = [
    { value: 'GenerateNewToken', name: 'GenerateNewToken' },
    { value: 'UseAvailableToken', name: 'UseAvailableToken' },
    { value: 'AssignCustomToken', name: 'AssignCustomToken' }
  ]

  const masterStatus = [
    { value: 'Pending', name: 'Pending' },
    { value: 'Sent', name: 'Sent' }
  ]

  const doActionSend = () => {
    sendVoucherGift({ data: listChecked as never }).then()
  }

  return (
    <>
      <Content
        title='Gift Voucher'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        onAdditional={onCreate}
        additionalTitle='New Gift Voucher'
        additionalPrefixIcon='Add'
        onUpload={() => setDialogUpload(true)}
        uploadTitle='Upload CSV'
        uploadPrefixIcon='FileUpload'
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={voucherGift.data && voucherGift.data.recordsTotal}
        page={payload.start + 1}
        isLoading={voucherGift.isLoading}
        onTemplateDownload={onDownloadTemplate}
        templateDownloadLoading={template.isLoading}
        templateTitle='Template Gift'
        useDownloadDropdown
        useTemplateDownloadDropdown
        useCheckbox
        onCheckBoxAll={(e) => handleCheckAll(e)}
        allChecked={listChecked}
        onAction={() => setAction(true)}
        actionTitle='Send'
      >
        {voucherGift.isSuccess &&
          voucherGift.data &&
          voucherGift.data.data.map((item, index) => (
            <TableRow key={index}>
              <TableCell>
                <Checkbox
                  value={`${item.id}`}
                  onChange={(e) => handleCheck(e)}
                  checked={listChecked.includes(Number(item.id) as never)}
                  disabled={!item.isSend}
                />
              </TableCell>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${item.id}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${item.id}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${item.id}`}
                  aria-labelledby={`button-${index}-${item.id}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View Detail
                  </MenuItem>
                  {item.sendStatus !== 'Sent' && (
                    <MenuItem onClick={() => onRemoveAnchor(() => setDialogUpdate(true))}>
                      Edit
                    </MenuItem>
                  )}
                  <MenuModalItem
                    title='Delete'
                    loading={deleteGift.isLoading}
                    onClick={() => onRemoveAnchor(() => deleteVoucherGift({ Id: item.id || '' }))}
                  >
                    <Typography {...GlobalStyle.ErrorTypography}>Delete</Typography>
                  </MenuModalItem>
                </Menu>
                {drawerDetail && menu == index && (
                  <Detail
                    id={item.id || ''}
                    open={drawerDetail && menu == index}
                    onClose={() => setDrawerDetail(false)}
                  />
                )}
                {dialogUpdate && menu == index && (
                  <Edit
                    id={item.id || ''}
                    open={dialogUpdate && menu == index}
                    onClose={() => setDialogUpdate(false)}
                  />
                )}
              </TableCell>
              <TableCell>{item.memberCode || '-'}</TableCell>
              <TableCell>{item.voucherGiftName || '-'}</TableCell>
              <TableCell>{item.voucherName || '-'}</TableCell>
              <TableCell>{item.assignedTo || '-'}</TableCell>
              <TableCell>{item.assignedBy || '-'}</TableCell>
              <TableCell>{item.sendStatus || '-'}</TableCell>
              <TableCell>
                {item.sendDate ? dayjs(item.sendDate).format('DD MMMM YYYY HH:mm') : '-'}
              </TableCell>
            </TableRow>
          ))}
      </Content>

      {voucherGift.isLoading && <Loading />}

      <Filter
        open={dialogFilter}
        title='Filter Gift Voucher'
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        onReset={() => {
          setFilter({
            voucherId: '',
            memberTier: '',
            assignMode: '',
            assignBy: '',
            assignTo: '',
            status: '',
            MinCreated: '',
            MaxCreated: '',
            MinSend: '',
            MaxSend: '',
            MinFrom: '',
            MaxFrom: '',
            MinUntil: '',
            MaxUntil: ''
          })
          setMinDate(null)
          setMaxDate(null)
          setMinFromDate(null)
          setMaxFromDate(null)
          setMinSendDate(null)
          setMaxSendDate(null)
          setMinUntilDate(null)
          setMaxUntilDate(null)
          setPayload({ ...payload, start: 0, ...filter })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({ ...payload, start: 0, ...filter })
          onFilter(false)
        }}
      >
        <Grid container spacing={2} columns={16}>
          <Grid xs={8} marginTop={1}>
            <Grid xs={16} margin={1}>
              <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                <DatePicker
                  value={minDate}
                  onChange={handleChangeMinCreated}
                  label='Min Created Date'
                  format='DD-MM-YYYY'
                  sx={{ width: '100%' }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid xs={16} margin={1}>
              <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                <DatePicker
                  value={minSendDate}
                  onChange={handleChangeMinSend}
                  label='Min Send Date'
                  format='DD-MM-YYYY'
                  sx={{ width: '100%' }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid xs={16} margin={1}>
              <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                <DatePicker
                  value={minFromDate}
                  onChange={handleChangeMinFrom}
                  label='Min Valid From'
                  format='DD-MM-YYYY'
                  sx={{ width: '100%' }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid xs={16} margin={1}>
              <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                <DatePicker
                  value={minUntilDate}
                  onChange={handleChangeMinUntil}
                  label='Min Valid Until'
                  format='DD-MM-YYYY'
                  sx={{ width: '100%' }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid xs={16} margin={1}>
              <Autocomplete
                options={dropdownVoucher.data || []}
                getOptionLabel={(list) => list.name}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.id == value.id : false
                }
                onOpen={() => getVoucherGiftDropdown()}
                onChange={(_, id) => {
                  if (id) {
                    setFilter({
                      ...filter,
                      voucherId: id && id?.id.toString()
                    })
                  }
                }}
                ListboxProps={GlobalStyle.ListBox}
                value={
                  dropdownVoucher &&
                  dropdownVoucher.data &&
                  dropdownVoucher.data.find((e) => e.id == Number(filter.voucherId))
                }
                renderOption={(props, item) => (
                  <li {...props} key={item.id}>
                    {item.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='voucherId'
                    label='Voucher'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {dropdownVoucher.isLoading && (
                            <CircularProgress color='inherit' size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
              />
            </Grid>
            <Grid xs={16} margin={1}>
              <Autocomplete
                options={voucherMemberId.data || []}
                getOptionLabel={(list) => list.name}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.id == value.id : false
                }
                onOpen={() => getVoucherMemberDropdown()}
                onChange={(_, id) => {
                  if (id) {
                    setFilter({
                      ...filter,
                      memberTier: id?.id.toString()
                    })
                  }
                }}
                ListboxProps={GlobalStyle.ListBox}
                value={
                  voucherMemberId &&
                  voucherMemberId.data &&
                  voucherMemberId.data.find((e) => e.id == filter.memberTier)
                }
                renderOption={(props, item) => (
                  <li {...props} key={item.id}>
                    {item.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='memberTier'
                    label='Member Tier'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {voucherMemberId.isLoading && (
                            <CircularProgress color='inherit' size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
              />
            </Grid>
            <Grid xs={16} margin={1}>
              <Autocomplete
                options={masterAssignmentMode || []}
                getOptionLabel={(list) => list.name}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.value == value.value : false
                }
                onChange={(_, id) => {
                  if (id) {
                    setFilter({
                      ...filter,
                      assignMode: id?.value
                    })
                  }
                }}
                ListboxProps={GlobalStyle.ListBox}
                value={
                  masterAssignmentMode &&
                  masterAssignmentMode.find((e) => e.value == filter.assignMode)
                }
                renderOption={(props, item) => (
                  <li {...props} key={item.value}>
                    {item.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='assignMode'
                    label='Assignment Mode'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: <>{params.InputProps.endAdornment}</>
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          {/* ============ Right Column ============== */}
          <Grid xs={8} marginTop={1}>
            <Grid xs={16} margin={1}>
              <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                <DatePicker
                  value={maxDate}
                  onChange={handleChangeMaxCreated}
                  label='Max Created Date'
                  format='DD-MM-YYYY'
                  sx={{ width: '100%' }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid xs={16} margin={1}>
              <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                <DatePicker
                  value={maxSendDate}
                  onChange={handleChangeMaxSend}
                  label='Max Send Date'
                  format='DD-MM-YYYY'
                  sx={{ width: '100%' }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid xs={16} margin={1}>
              <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                <DatePicker
                  value={maxFromDate}
                  onChange={handleChangeMaxFrom}
                  label='Max Valid From'
                  format='DD-MM-YYYY'
                  sx={{ width: '100%' }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid xs={16} margin={1}>
              <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                <DatePicker
                  value={maxUntilDate}
                  onChange={handleChangeMaxUntil}
                  label='Max Valid Until'
                  format='DD-MM-YYYY'
                  sx={{ width: '100%' }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid xs={16} margin={1}>
              <Autocomplete
                options={dropdownAssignBy.data || []}
                getOptionLabel={(list) => list.name}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.userid == value.userid : false
                }
                onOpen={() => getGiftAssignByDropdown()}
                onChange={(_, id) => {
                  if (id) {
                    setFilter({
                      ...filter,
                      assignBy: id && id?.userid.toString()
                    })
                  }
                }}
                ListboxProps={GlobalStyle.ListBox}
                value={
                  dropdownAssignBy &&
                  dropdownAssignBy.data &&
                  dropdownAssignBy.data.find((e) => e.userid == filter.assignBy)
                }
                renderOption={(props, item) => (
                  <li {...props} key={item.userid}>
                    {item.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='assignBy'
                    label='Assigned By'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {dropdownAssignBy.isLoading && (
                            <CircularProgress color='inherit' size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
              />
            </Grid>
            <Grid xs={16} margin={1}>
              <Autocomplete
                options={dropdownAssignTo.data || []}
                getOptionLabel={(list) => list.name}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.userid == value.userid : false
                }
                onOpen={() => getGiftAssignToDropdown()}
                onChange={(_, id) => {
                  if (id) {
                    setFilter({
                      ...filter,
                      assignTo: id?.userid
                    })
                  }
                }}
                ListboxProps={GlobalStyle.ListBox}
                value={
                  dropdownAssignTo &&
                  dropdownAssignTo.data &&
                  dropdownAssignTo.data.find((e) => e.userid == filter.assignTo)
                }
                renderOption={(props, item) => (
                  <li {...props} key={item.userid}>
                    {item.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='assignTo'
                    label='Assigned To'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {dropdownAssignTo.isLoading && (
                            <CircularProgress color='inherit' size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
              />
            </Grid>

            <Grid xs={16} margin={1}>
              <Autocomplete
                options={masterStatus || []}
                getOptionLabel={(list) => list.name}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.value == value.value : false
                }
                onChange={(_, id) => {
                  if (id) {
                    setFilter({
                      ...filter,
                      status: id?.value
                    })
                  }
                }}
                ListboxProps={GlobalStyle.ListBox}
                value={dataStatus && dataStatus.find((e) => e.value == filter.status)}
                renderOption={(props, item) => (
                  <li {...props} key={item.value}>
                    {item.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='status'
                    label='Send Status'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: <>{params.InputProps.endAdornment}</>
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Filter>
      {dialogCreate && <Create open={dialogCreate} onClose={() => setDialogCreate(false)} />}
      {dialogUpload && <Upload open={dialogUpload} onClose={() => setDialogUpload(false)} />}

      <ModalConfirm
        title='Send'
        open={action}
        onClose={() => setAction(false)}
        onSubmit={doActionSend}
        loading={send.isLoading}
        text='send the gift voucher'
        text2='After Confirmed, the sending process cannot be canceled'
      />

      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />

      <Notification
        open={!deleteGift.isLoading && !deleteGift.isUninitialized}
        onClose={() => (deleteGift.isError ? deleteGift.reset() : location.reload())}
        isError={Boolean(deleteGift.error) && deleteGift.isError}
        message={GLOBAL.returnExceptionMessage(deleteGift.isError, deleteGift.error as ErrorProps)}
      />

      <Notification
        open={!send.isLoading && !send.isUninitialized}
        onClose={() => (send.isError ? send.reset() : location.reload())}
        isError={Boolean(send.error) && send.isError}
        message={GLOBAL.returnExceptionMessage(send.isError, send.error as ErrorProps)}
      />
    </>
  )
}

export default VoucherGift
