import { useState } from 'react'
import { Container, TextField, Typography } from '@mui/material'
import Drawer from 'components/drawer/detail'
import Loading from 'components/loading/loading'
import type { DetailStateProps } from 'modules/types'
import { useGetDetailQrCodeQuery } from 'store/qrcode'
import BackdropImage from 'components/backdropImage/backdropImage'
import DrawerStyle from './style'

const Detail = ({ open, id, onClose }: DetailStateProps & { id: number }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const { data, isFetching, isLoading } = useGetDetailQrCodeQuery(id)
  const [previewBackdrop, setPreviewBackdrop] = useState<boolean>(false)

  const chargePointName = (data && data.chargePointName) || '-'
  const chargeBoxGroupId = data && data.chargeBoxGroupId
  const chargeBoxGroupIdentity = data && data.chargeBoxGroupIdentity
  const imgUrlQrCode = data && data.imageUrlQrCode

  return (
    <>
      <Drawer open={open} title='Detail QR Code' onClose={onClose}>
        <Container {...DrawerStyle.Container}>
          {(isLoading || isFetching) && !data && <Loading />}
          {data && (
            <>
              <TextField
                id='chargePointName'
                variant='outlined'
                label='Charge Point'
                value={chargePointName}
                inputProps={{ readOnly: true }}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextField
                id='chargeBoxGroupId'
                variant='outlined'
                label='Charge Box Group'
                value={chargeBoxGroupId}
                inputProps={{ readOnly: true }}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextField
                id='chargeBoxGroupIdentity'
                variant='outlined'
                label='Charger Box'
                value={chargeBoxGroupIdentity}
                inputProps={{ readOnly: true }}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
              <Typography>QR Image</Typography>
              <div
                style={{
                  height: '300px',
                  border: '1px solid #eee',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                {!imgUrlQrCode && <Typography style={{ color: '#cfcfcf' }}>No Image</Typography>}
                {imgUrlQrCode && (
                  <BackdropImage
                    open={previewBackdrop}
                    onOpen={() => setPreviewBackdrop(true)}
                    onClose={() => setPreviewBackdrop(false)}
                  >
                    <img
                      src={imgUrlQrCode}
                      alt=''
                      style={{ height: '300px', width: '505px', objectFit: 'contain' }}
                    />
                  </BackdropImage>
                )}
              </div>
            </>
          )}
        </Container>
      </Drawer>
    </>
  )
}

export default Detail
