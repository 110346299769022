import { FieldArray, FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { Container, Stack, TextField } from '@mui/material'
import Dialog from 'components/dialog/dialog'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import { useGetFaqCategoryDetailQuery, useUpdateFaqCategoryMutation } from 'store/faq'
import type {
  PartialFaqCategoryDetailDataProps,
  PartialFaqCategoryDetailProps
} from 'modules/partial'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import DialogStyle from './style'

const Edit = ({ open, id, onClose }: DetailStateProps & { id: string }) => {
  if (!open) return <></>

  const { data, isFetching, isLoading } = useGetFaqCategoryDetailQuery({ key: id })
  const [updateFaqCategory, update] = useUpdateFaqCategoryMutation()

  const scheme = yup.object<PartialFaqCategoryDetailDataProps>({
    key: yup.string().required('Key is required'),
    data: yup.array().of(
      yup.object({
        tags: yup.string().required('Tags is required'),
        name: yup.string().required('Name is required'),
        remark: yup.string()
      })
    )
  })

  const formik = useFormik<PartialFaqCategoryDetailProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      id:
        (data && data.data[1] && data.data[1].id) ||
        (data && data.data[0] && data.data[0].id) ||
        null,
      key: (data && data.key) || '',
      status: 'Enabled',
      data: [
        {
          tags: 'ID',
          id: (data && data.data[1] && data.data[1].id) || null,
          name: (data && data.data[1] && data.data[1].name) || '',
          remark: (data && data.data[1] && data.data[1].remark) || ''
        },
        {
          tags: 'EN-US',
          id: (data && data.data[0] && data.data[0].id) || null,
          name: (data && data.data[0] && data.data[0].name) || '',
          remark: (data && data.data[0] && data.data[0].remark) || ''
        }
      ]
    },
    onSubmit: (values: PartialFaqCategoryDetailProps) => updateFaqCategory(values)
  })

  const errorData = formik.errors.data as PartialFaqCategoryDetailDataProps[]
  return (
    <>
      <Dialog
        open={open}
        title='Update FAQ Category'
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={update.isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          {(isLoading || isFetching) && !data && <Loading />}
          {data && (
            <>
              <TextField
                id='key'
                variant='outlined'
                label='Category Name'
                value={formik.values.key}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched && formik.touched.key && Boolean(formik.errors.key)}
                helperText={
                  formik.touched && formik.touched.key && formik.errors && formik.errors.key
                }
                fullWidth
              />
              <FormikProvider value={formik}>
                {formik.values.data.map((_, index) => (
                  <FieldArray
                    key={index}
                    name='data'
                    render={() => (
                      <Stack {...DialogStyle.Stack}>
                        <TextField
                          id={`data[${index}].tags`}
                          variant='outlined'
                          label='Tag'
                          value={formik.values.data[index].tags}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          inputProps={{ readOnly: true }}
                          fullWidth
                        />
                        <TextField
                          id={`data[${index}].name`}
                          variant='outlined'
                          label='name'
                          value={formik.values.data[index].name || ''}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.data &&
                            formik.touched.data[index] &&
                            formik.touched.data[index].name &&
                            errorData &&
                            errorData[index] &&
                            Boolean(errorData[index].name)
                          }
                          helperText={
                            formik.touched.data &&
                            formik.touched.data[index] &&
                            formik.touched.data[index].name &&
                            errorData &&
                            errorData[index] &&
                            errorData[index].name
                          }
                          fullWidth
                        />
                        <TextField
                          id={`data[${index}].remark`}
                          variant='outlined'
                          label='remark'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.data[index].remark || ''}
                          error={
                            formik.touched.data &&
                            formik.touched.data[index] &&
                            formik.touched.data[index].remark &&
                            errorData &&
                            errorData[index] &&
                            Boolean(errorData[index].remark)
                          }
                          helperText={
                            formik.touched.data &&
                            formik.touched.data[index] &&
                            formik.touched.data[index].remark &&
                            errorData &&
                            errorData[index] &&
                            errorData[index].remark
                          }
                          fullWidth
                        />
                      </Stack>
                    )}
                  />
                ))}
              </FormikProvider>
            </>
          )}
        </Container>
      </Dialog>

      <Notification
        open={!update.isLoading && !update.isUninitialized}
        onClose={() => (update.isError ? update.reset() : location.reload())}
        isError={Boolean(update.error) && update.isError}
        message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
      />
    </>
  )
}

export default Edit
