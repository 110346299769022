import CONSTANT from 'modules/constant'
import { API, headers, headersOtp } from 'store/api'
import type { PartialValidateOtpProps, PartialUserProps, PartialRequestOtpProps } from 'modules/partial'
import type { RequestOtpProps, UserProps } from 'modules/types'

const userAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    loginUser: builder.mutation<UserProps, PartialUserProps>({
      query: (body) => ({
        url: CONSTANT.URL_LOGIN,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    logoutUser: builder.mutation({
      query: () => ({
        url: CONSTANT.URL_LOGOUT,
        method: 'POST',
        headers: headers()
      })
    }),
    otpRequest: builder.mutation<RequestOtpProps, {body: PartialRequestOtpProps, token: string}>({
      query: ({body, token}) => ({
        url: CONSTANT.URL_OTP_REQUEST ? CONSTANT.URL_OTP_REQUEST : '',
        method: 'POST',
        headers: headersOtp({token: token}),
        body: body
      })
    }),
    otpValidation: builder.mutation<object, {body: PartialValidateOtpProps, token: string}>({
      query: ({body, token}) => ({
        url: CONSTANT.URL_OTP_VALIDATE ? CONSTANT.URL_OTP_VALIDATE : '',
        method: 'POST',
        headers: headersOtp({token: token}),
        body: body
      })
    })
  })
})

export const { useLoginUserMutation, useLogoutUserMutation, useOtpRequestMutation, useOtpValidationMutation } = userAPI
