import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import type { Dayjs } from 'dayjs'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import CONSTANT from 'modules/constant'
import Icon from 'components/icon'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { DropdownCategoryListProps, ErrorProps } from 'modules/types'
import GlobalStyle from 'modules/styles'
import {
  useDownloadTokenMutation,
  useGetListTokenMutation,
  useGetTokenPartnerDropdownMutation
} from 'store/token'
import type { PartialFormDataProps } from 'modules/partial'
import {
  useGetTokenCategoryDropdownMutation,
  useGetVoucherListDropdownMutation
} from 'store/tokenRequest'
import {
  useGetCampaignDropdownMutation,
  useGetCampaignTypeDropdownMutation
} from 'store/tokenImport'
import Detail from './detail/detail'
import RevokeToken from './revoke/revoke'
import MoveToken from './move/move'

const TokenList = () => {
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [dialogRevoke, setDialogRevoke] = useState<boolean>(false)
  const [dialogMove, setDialogMove] = useState<boolean>(false)
  const [menu, setMenu] = useState<null | number>(null)
  const [getListToken, tokenList] = useGetListTokenMutation()
  const [downloadToken, download] = useDownloadTokenMutation()
  const [getVoucherListDropdown, voucherDropdown] = useGetVoucherListDropdownMutation()
  const [getTokenPartnerDropdown, partnerDropdown] = useGetTokenPartnerDropdownMutation()
  const [getTokenCategoryDropdown, tokenCategoryDropdown] = useGetTokenCategoryDropdownMutation()
  const [getCampaignDropdown, campaignDropdown] = useGetCampaignDropdownMutation()
  const [getCampaignTypeDropdown, campaignTypeDropdown] = useGetCampaignTypeDropdownMutation()
  const [listChecked, setListChecked] = useState([])
  const [isChecked, setIsChecked] = useState<boolean>(false)

  const [payload, setPayload] = useState<PartialFormDataProps>({
    start: 0,
    length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    field: CONSTANT.DEFAULT_TOKEN_LIST_FIELD.join(','),
    search: { value: '' },
    status: ''
  })

  const [filter, setFilter] = useState({
    minValidFrom: null,
    maxValidFrom: null,
    minValidUntil: null,
    maxValidUntil: null,
    minCreatedDate: null,
    maxCreatedDate: null,
    minPurchasedDate: null,
    maxPurchasedDate: null,
    minClaimedDate: null,
    maxClaimedDate: null,
    minRevokedDate: null,
    maxRevokedDate: null,
    voucherId: '',
    voucherName: '',
    partner: '',
    purchasedFlag: '',
    purchasedByName: '',
    memberCode: '',
    claimedFlag: '',
    claimedBy: '',
    revokedFlag: '',
    revokedBy: '',
    remarks: '',
    campaignName: '',
    campaignType: '',
    tokenCateId: '',
  })

  const dateFilter = [
    { value: 'minValidFrom', label: 'Min Valid From', id: 'min-valid-from' },
    { value: 'maxValidFrom', label: 'Max Valid From', id: 'max-valid-from' },
    { value: 'minValidUntil', label: 'Min Valid Until', id: 'min-valid-until' },
    { value: 'maxValidUntil', label: 'Max Valid Until', id: 'max-valid-until' },
    { value: 'minCreatedDate', label: 'Min Created Date', id: 'min-created-date' },
    { value: 'maxCreatedDate', label: 'Max Created Date', id: 'max-created-date' },
    { value: 'minPurchasedDate', label: 'Min Purchased Date', id: 'min-purchased-date' },
    { value: 'maxPurchasedDate', label: 'Max Purchased Date', id: 'max-purchased-date' },
    { value: 'minClaimedDate', label: 'Min Claimed Date', id: 'min-claimed-date' },
    { value: 'maxClaimedDate', label: 'Max Claimed Date', id: 'max-claimed-date' },
    { value: 'minRevokedDate', label: 'Min Revoked Date', id: 'min-revoked-date' },
    { value: 'maxRevokedDate', label: 'Max Revoked Date', id: 'max-revoked-date' }
  ]

  const onSearch = (value: string) => setPayload({ ...payload, start: 0, search: { value } })
  const onResync = () => {
    const formData = new FormData()
    formData.append('start', payload.start.toString())
    formData.append('length', payload.length.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    dateFilter.map((item) => {
      if (!isEmpty(filter[item.value as never]))
        formData.append(item.id, filter[item.value as never])
    })
    if (!isEmpty(filter.voucherId)) formData.append('voucher-id', filter.voucherId)
    if (!isEmpty(filter.partner)) formData.append('partner', filter.partner)
    if (!isEmpty(filter.purchasedFlag)) formData.append('purchased-flag', filter.purchasedFlag)
    if (!isEmpty(filter.purchasedByName))
      formData.append('purchased-by-name', filter.purchasedByName)
    if (!isEmpty(filter.memberCode)) formData.append('member-code', filter.memberCode)
    if (!isEmpty(filter.claimedFlag)) formData.append('claimed-flag', filter.claimedFlag)
    if (!isEmpty(filter.claimedBy)) formData.append('claimed-by', filter.claimedBy)
    if (!isEmpty(filter.revokedFlag)) formData.append('revoked-flag', filter.revokedFlag)
    if (!isEmpty(filter.revokedBy)) formData.append('revoked-by', filter.revokedBy)
    if (!isEmpty(filter.campaignName)) formData.append('campaign-name', filter.campaignName)
    if (!isEmpty(filter.remarks)) formData.append('remarks', filter.remarks)
    if (!isEmpty(filter.campaignType)) formData.append('campaign-categoryId', filter.campaignType)
    if (!isEmpty(filter.tokenCateId)) formData.append('token-category', filter.tokenCateId)
    if (!isEmpty(filter.claimedBy)) formData.append('claimed-by-name', filter.claimedBy)



    getListToken(formData)
  }
  const onFilter = (state: boolean) => setDialogFilter(state)
  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('start', String(0))
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    dateFilter.map((item) => {
      if (!isEmpty(filter[item.value as never]))
        formData.append(item.id, filter[item.value as never])
    })
    if (!isEmpty(filter.voucherId)) formData.append('voucher-id', filter.voucherId)
    if (!isEmpty(filter.partner)) formData.append('partner', filter.partner)
    if (!isEmpty(filter.purchasedFlag)) formData.append('purchased-flag', filter.purchasedFlag)
    if (!isEmpty(filter.purchasedByName))
      formData.append('purchased-by-name', filter.purchasedByName)
    if (!isEmpty(filter.memberCode)) formData.append('member-code', filter.memberCode)
    if (!isEmpty(filter.claimedFlag)) formData.append('claimed-flag', filter.claimedFlag)
    if (!isEmpty(filter.claimedBy)) formData.append('claimed-by', filter.claimedBy)
    if (!isEmpty(filter.revokedFlag)) formData.append('revoked-flag', filter.revokedFlag)
    if (!isEmpty(filter.revokedBy)) formData.append('revoked-by', filter.revokedBy)
    if (!isEmpty(filter.campaignName)) formData.append('campaign-name', filter.campaignName)
    if (!isEmpty(filter.remarks)) formData.append('remarks', filter.remarks)
    if (!isEmpty(filter.campaignType)) formData.append('campaign-categoryId', filter.campaignType)
    if (!isEmpty(filter.tokenCateId)) formData.append('token-category', filter.tokenCateId)
    if (!isEmpty(filter.claimedBy)) formData.append('claimed-by-name', filter.claimedBy)

    if (type && !isEmpty(type)) formData.append('Download-Type', type)

    downloadToken(formData)
  }

  const onChangePage = (value: number) => setPayload({ ...payload, start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, length: value })

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const handleChangeDate = (key: string) => (date: Dayjs | null) => {
    const newDate = (!isEmpty(date) && date?.format('YYYY-MM-DD')) || ''
    setFilter({ ...filter, [key]: newDate })
  }

  const tableHeadTitles = [
    'Code',
    'Token Category',
    'Voucher Name',
    'Partner',
    'Purchased By',
    'Member ID',
    'Purchased',
    'Claimed',
    'Revoked',
    'Valid From',
    'Valid Until',
    'Campaign Name',
    'Expired',
    'Source'
  ]

  const openAnchor = Boolean(anchor)
  const totalPage =
    tokenList.data && Math.ceil(tokenList.data.recordsTotal / CONSTANT.DEFAULT_PAGINATION_PER_PAGE)

  const numbers = Array.from(
    Array(CONSTANT.DEFAULT_PAGINATION_PER_PAGE * (payload.start + 1)).keys()
  ).slice(-CONSTANT.DEFAULT_PAGINATION_PER_PAGE)

  useEffect(() => {
    onResync()
  }, [payload])

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) =>
    event.target.checked
      ? setListChecked([...listChecked, event.target.value] as never)
      : setListChecked([...listChecked].filter((o) => o !== event.target.value))

  const handleCheckAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(!isChecked)
    return event.target.checked
      ? setListChecked([
        ...listChecked,
        ...(((tokenList.data && tokenList.data.data.map((x) => x.id + '-' + x.code)) ||
          undefined) as never)
      ])
      : setListChecked([])
  }

  // const dataBoolean = [
  //   { value: 'No', name: 'No' },
  //   { value: 'Yes', name: 'Yes' }
  // ]

  // const dataStatus = [
  //   { value: 'Enabled', name: 'Enabled' },
  //   { value: 'Disabled', name: 'Disabled' }
  // ]

  const mappingVoucher = (arrData: []) => {
    if (arrData.length > 0) {
      return arrData.map((item: DropdownCategoryListProps) => ({
        id: item.id,
        name: item.id + ' - ' + item.name
      }))
    }
    return []
  }


  return (
    <>
      <Content
        title='Token'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={tokenList.data && tokenList.data.recordsTotal}
        page={payload.start + 1}
        isLoading={tokenList.isLoading}
        useDownloadDropdown
        useCheckbox
        onCheckBoxAll={(e) => handleCheckAll(e)}
        allChecked={listChecked}
        onRevokeToken={() => setDialogRevoke(true)}
        onMoveToken={() => setDialogMove(true)}
      >
        {tokenList.isSuccess &&
          tokenList.data &&
          tokenList.data.data.map((item, index) => (
            <TableRow key={index}>
              <TableCell>
                <Checkbox
                  value={`${item.id}-${item.code}`}
                  onChange={(e) => handleCheck(e)}
                  checked={listChecked.includes(`${item.id}-${item.code}` as never)}
                />
              </TableCell>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${item.id}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${item.id}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${item.id}`}
                  aria-labelledby={`button-${index}-${item.id}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View Detail
                  </MenuItem>
                </Menu>
                {drawerDetail && menu == index && (
                  <Detail
                    id={item.id || ''}
                    open={drawerDetail && menu == index}
                    onClose={() => setDrawerDetail(false)}
                  />
                )}
              </TableCell>
              <TableCell sx={{ cursor: 'pointer' }}>{item.code || '-'}</TableCell>
              <TableCell>{item.voucherTokenCategory || '-'}</TableCell>
              <TableCell>{item.voucherName || '-'}</TableCell>
              <TableCell>{item.partnerName || '-'}</TableCell>
              <TableCell>{item.purchasedByName || '-'}</TableCell>
              <TableCell>{item.memberCode || '-'}</TableCell>
              <TableCell>{item.purchased || '-'}</TableCell>
              <TableCell>{item.claimed || '-'}</TableCell>
              <TableCell>{item.revoked || '-'}</TableCell>
              <TableCell>{dayjs(item.validFrom).format('DD MMMM YYYY HH:mm') || '-'}</TableCell>
              <TableCell>{dayjs(item.validUntil).format('DD MMMM YYYY HH:mm') || '-'}</TableCell>
              <TableCell>{item.campaignName || '-'}</TableCell>
              <TableCell>{item.expired || '-'}</TableCell>
              <TableCell>{item.source || '-'}</TableCell>
            </TableRow>
          ))}
      </Content>
      {tokenList.isLoading && <Loading />}

      <Filter
        open={dialogFilter}
        title='Filter'
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        onSubmit={() => {
          setPayload({ ...payload, start: 0, ...filter })
          onFilter(false)
        }}
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
        onReset={() => {
          setFilter({
            minValidFrom: null,
            maxValidFrom: null,
            minValidUntil: null,
            maxValidUntil: null,
            minCreatedDate: null,
            maxCreatedDate: null,
            minPurchasedDate: null,
            maxPurchasedDate: null,
            minClaimedDate: null,
            maxClaimedDate: null,
            minRevokedDate: null,
            maxRevokedDate: null,
            voucherId: '',
            voucherName: '',
            partner: '',
            purchasedFlag: '',
            purchasedByName: '',
            memberCode: '',
            claimedFlag: '',
            claimedBy: '',
            revokedFlag: '',
            revokedBy: '',
            remarks: '',
            campaignName: '',
            campaignType: '',
            tokenCateId: '',
          })
          setPayload({ ...payload, start: 0, ...filter })
          onFilter(false)
        }}
      >
        <Grid container spacing={2} columns={12}>
          {dateFilter.map((item, index) => (
            <Grid item xs={6} key={index}>
              <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                <DatePicker
                  key={item.value}
                  onChange={handleChangeDate(item.value)}
                  label={item.label}
                  value={filter[item.value as never] ? dayjs(filter[item.value as never]) : null}
                  sx={{ width: '100%' }}
                />
              </LocalizationProvider>
            </Grid>
          ))}
          <Grid item xs={6}>
            <Autocomplete
              // options={mappingVoucher((voucherDropdown.data as never) || [])}
              options={mappingVoucher((voucherDropdown.data as never && voucherDropdown.data as never) || [])}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) =>
                option && value ? option.id == value.id : false
              }
              onOpen={() => getVoucherListDropdown()}
              onChange={(_, voucherId) =>
                setFilter({
                  ...filter,
                  voucherId: (voucherId && String(voucherId.id)) || ''
                })
              }
              ListboxProps={GlobalStyle.ListBox}
              value={
                voucherDropdown &&
                voucherDropdown.data &&
                voucherDropdown.data.find((e) => e.id == String(filter.voucherId))
              }
              renderOption={(props, item) => (
                <li {...props} key={item.id}>
                  {item.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='voucherId'
                  label='Voucher'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {voucherDropdown.isLoading && (
                          <CircularProgress color='inherit' size={20} />
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              options={(partnerDropdown && partnerDropdown.data) || []}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) =>
                option && value ? option.id == value.id : false
              }
              onOpen={() => getTokenPartnerDropdown()}
              onChange={(_, partner) =>
                setFilter({
                  ...filter,
                  partner: (partner && String(partner.id)) || ''
                })
              }
              value={
                partnerDropdown &&
                partnerDropdown.data &&
                partnerDropdown.data.find((e) => e.id == Number(filter.partner))
              }
              ListboxProps={GlobalStyle.ListBox}
              renderOption={(props, item) => (
                <li {...props} key={item.id}>
                  {item.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='partner'
                  label='Partner'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {partnerDropdown.isLoading && (
                          <CircularProgress color='inherit' size={20} />
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              options={(tokenCategoryDropdown.data && tokenCategoryDropdown.data) || []}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) =>
                option && value ? option.id == value.id : false
              }
              onOpen={() => getTokenCategoryDropdown()}
              onChange={(_, tokenCateId) =>
                setFilter({
                  ...filter,
                  tokenCateId: (tokenCateId && String(tokenCateId.id)) || ''
                })
              }
              ListboxProps={GlobalStyle.ListBox}
              renderOption={(props, item) => (
                <li {...props} key={item.id}>
                  {item.name}
                </li>
              )}
              value={
                tokenCategoryDropdown &&
                tokenCategoryDropdown.data &&
                tokenCategoryDropdown.data.find((e) => e.id == String(filter.tokenCateId))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='tokenCategoryId'
                  label='Token Category'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {tokenCategoryDropdown.isLoading && (
                          <CircularProgress color='inherit' size={20} />
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              options={(campaignTypeDropdown.data && campaignTypeDropdown.data) || []}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) =>
                option && value ? option.id == value.id : false
              }
              onOpen={() => getCampaignTypeDropdown()}
              onChange={(_, campaignType) =>
                setFilter({
                  ...filter,
                  campaignType: (campaignType && String(campaignType.id)) || ''
                })
              }
              value={
                campaignTypeDropdown &&
                campaignTypeDropdown.data &&
                campaignTypeDropdown.data.find((e) => e.id == Number(filter.campaignType))
              }
              ListboxProps={GlobalStyle.ListBox}
              renderOption={(props, item) => (
                <li {...props} key={item.id}>
                  {item.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='campaignType'
                  label='Campaign Type'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {campaignTypeDropdown.isLoading && (
                          <CircularProgress color='inherit' size={20} />
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              options={campaignDropdown.data || []}
              getOptionLabel={(list) => list.name}
              isOptionEqualToValue={(option, value) =>
                option && value ? option.id == value.id : false
              }
              onOpen={() => getCampaignDropdown()}
              // onChange={(_, id) =>
              //   setFilter({
              //     ...filter,
              //     campaignName: (id && id.name) || ''
              //   })
              // }
              onInputChange={(_, campaignName) => setFilter({ ...filter, campaignName })}
              value={
                campaignDropdown &&
                campaignDropdown.data &&
                campaignDropdown.data.find((e) => e.name == filter.campaignName)
              }
              ListboxProps={GlobalStyle.ListBox}
              renderOption={(props, item) => (
                <li {...props} key={item.id}>
                  {item.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='campaignName'
                  label='Campaign Name'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {campaignDropdown.isLoading && (
                          <CircularProgress color='inherit' size={20} />
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            {/* <Autocomplete
              options={dataBoolean || []}
              getOptionLabel={(list) => list.name}
              isOptionEqualToValue={(option, value) =>
                option && value ? option.value == value.value : false
              }
              onChange={(_, id) => {
                if (id) {
                  setFilter({
                    ...filter,
                    purchasedFlag: id?.value
                  })
                }
              }}
              ListboxProps={GlobalStyle.ListBox}
              value={dataBoolean && dataBoolean.find((e) => e.value == filter.purchasedFlag)}
              renderOption={(props, item) => (
                <li {...props} key={item.value}>
                  {item.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='status'
                  label='Purchased (Yes/No)'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{params.InputProps.endAdornment}</>
                  }}
                />
              )}
            /> */}
            <FormControl fullWidth>
              <InputLabel id='select-label'>Purchased (Yes/No)</InputLabel>
              <Select
                labelId='select-label'
                id='id-select-label'
                value={filter.purchasedFlag}
                label='Purchased (Yes/No)'
                onChange={(event) => setFilter({ ...filter, purchasedFlag: event.target.value })}
              >
                <MenuItem value='No'>No</MenuItem>
                <MenuItem value='Yes'>Yes</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            {/* <Autocomplete
              options={dataBoolean || []}
              getOptionLabel={(list) => list.name}
              isOptionEqualToValue={(option, value) =>
                option && value ? option.value == value.value : false
              }
              onChange={(_, id) => {
                if (id) {
                  setFilter({
                    ...filter,
                    revokedFlag: id?.value
                  })
                }
              }}
              ListboxProps={GlobalStyle.ListBox}
              value={dataBoolean && dataBoolean.find((e) => e.value == filter.revokedFlag)}
              renderOption={(props, item) => (
                <li {...props} key={item.value}>
                  {item.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='status'
                  label='Revoked (Yes/No)'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{params.InputProps.endAdornment}</>
                  }}
                />
              )}
            /> */}
            <FormControl fullWidth>
              <InputLabel id='select-label'>Revoked (Yes/No)</InputLabel>
              <Select
                labelId='select-label'
                id='id-select-label'
                value={filter.revokedFlag}
                label='Revoked (Yes/No)'
                onChange={(event) => setFilter({ ...filter, revokedFlag: event.target.value })}
              >
                <MenuItem value='No'>No</MenuItem>
                <MenuItem value='Yes'>Yes</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            {/* <Autocomplete
              options={dataBoolean || []}
              getOptionLabel={(list) => list.name}
              isOptionEqualToValue={(option, value) =>
                option && value ? option.value == value.value : false
              }
              onChange={(_, id) => {
                if (id) {
                  setFilter({
                    ...filter,
                    claimedFlag: id?.value
                  })
                }
              }}
              ListboxProps={GlobalStyle.ListBox}
              value={dataBoolean && dataBoolean.find((e) => e.value == filter.claimedFlag)}
              // value={dataStatus && dataStatus.find((e) => e.value == filter.claimedFlag)}
              renderOption={(props, item) => (
                <li {...props} key={item.value}>
                  {item.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='status'
                  label='Claimed (Yes/No)'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{params.InputProps.endAdornment}</>
                  }}
                />
              )}
            /> */}
            <FormControl fullWidth>
              <InputLabel id='select-label'>Claimed (Yes/No)</InputLabel>
              <Select
                labelId='select-label'
                id='id-select-label'
                value={filter.claimedFlag}
                label='Claimed (Yes/No)'
                onChange={(event) => setFilter({ ...filter, claimedFlag: event.target.value })}
              >
                <MenuItem value='No'>No</MenuItem>
                <MenuItem value='Yes'>Yes</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id='purchasedBy'
              variant='outlined'
              label='Purchased By Name'
              type='text'
              value={filter.purchasedByName}
              onChange={(event) => setFilter({ ...filter, purchasedByName: event.target.value })}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id='claimedBy'
              variant='outlined'
              label='Claimed By'
              type='text'
              value={filter.claimedBy}
              onChange={(event) => setFilter({ ...filter, claimedBy: event.target.value })}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id='memberCode'
              variant='outlined'
              label='Member Code'
              type='text'
              value={filter.memberCode}
              onChange={(event) => setFilter({ ...filter, memberCode: event.target.value })}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id='remarks'
              variant='outlined'
              label='Remarks'
              type='text'
              value={filter.remarks}
              onChange={(event) => setFilter({ ...filter, remarks: event.target.value })}
              fullWidth
            />
          </Grid>
        </Grid>
      </Filter>
      {dialogRevoke && (
        <RevokeToken
          id={listChecked as never}
          open={dialogRevoke}
          onClose={() => setDialogRevoke(false)}
        />
      )}

      {dialogMove && (
        <MoveToken
          id={listChecked as never}
          open={dialogMove}
          onClose={() => setDialogMove(false)}
        />
      )}

      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />
    </>
  )
}

export default TokenList
