import { useEffect, useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import { isEmpty } from 'lodash'
import {
  Box,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import * as yup from 'yup'
import Dialog from 'components/dialog/dialog'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { PartialUpdateHyundaiModelProps } from 'modules/partial'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import { useGetDetailHyundaiModelQuery, useUpdateHyundaiModelMutation } from 'store/hyundaiModel'
import BackdropImage from 'components/backdropImage/backdropImage'
import DialogStyle from './style'

const Edit = ({ open, onClose, id }: DetailStateProps & { id: number }) => {
  const { data, isFetching, isLoading } = useGetDetailHyundaiModelQuery(id)
  const [updateHyundaiModel, update] = useUpdateHyundaiModelMutation()
  const [previewBackdrop, setPreviewBackdrop] = useState<boolean>(false)
  const [previewImage, setPreviewImage] = useState<string>((data && data.image) || '')
  const [img, setImg] = useState<string | Blob>((data && data.image) || '')

  const scheme = yup.object<PartialUpdateHyundaiModelProps>({
    Name: yup.string().required('Name is required'),
    Description: yup.string().required('Description is required'),
    BrochureUrl: yup.string().required('BrochureUrl is required'),
    Status: yup.string().required('Status is required'),
    Remark: yup.string().required('Remark is required'),
    Discontinued: yup.string().required('Discontinued is required'),
    MobilType: yup.string(),
    ManualBookUrl: yup.string().required('Manual Book Url is required')
  })

  const formik = useFormik<PartialUpdateHyundaiModelProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      Name: (data && data.name) || '',
      Description: (data && data.description) || '',
      BrochureUrl: (data && data.brochureUrl) || '',
      Status: (data && data.status) || '',
      Remark: (data && data.remark) || '',
      Discontinued: (data && String(data.discontinued)) || '',
      ManualBookUrl: (data && data.manualBookUrl) || ''
    },
    onSubmit: (values: PartialUpdateHyundaiModelProps) => handleSubmit(values)
  })

  const handleSubmit = (e: PartialUpdateHyundaiModelProps) => {
    const data: PartialUpdateHyundaiModelProps = {
      Id: id,
      Name: e.Name,
      Description: e.Description,
      BrochureUrl: e.BrochureUrl,
      Status: e.Status,
      Remark: e.Remark,
      Discontinued: e.Discontinued,
      ManualBookUrl: e.ManualBookUrl
    }

    const formData = new FormData()
    formData.append('data', JSON.stringify(data))

    if (img !== previewImage) formData.append('carImage', img)
    if (img !== previewImage) formData.append('isImageChanged', JSON.stringify(true))

    updateHyundaiModel(formData)
  }

  useEffect(() => {
    if (data && data.image) setPreviewImage(data.image)
  }, [data])

  return (
    <>
      <Dialog
        open={open}
        title='Update Hyundai Car Model'
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          {(isLoading || isFetching) && <Loading />}
          {data && (
            <FormikProvider value={formik}>
              <TextField
                id='Name'
                variant='outlined'
                label='Hyundai Car Name'
                onChange={formik.handleChange}
                value={formik.values.Name}
                onBlur={formik.handleBlur}
                error={formik.touched && formik.touched.Name && Boolean(formik.errors.Name)}
                helperText={
                  formik.touched && formik.touched.Name && formik.errors && formik.errors.Name
                }
                fullWidth
              />
              <Box {...DialogStyle.ImageBox}>
                {!previewImage && (
                  <Typography style={{ color: '#cfcfcf' }}>Car Image Cannot be Empty</Typography>
                )}
                {previewImage && (
                  <BackdropImage
                    open={previewBackdrop}
                    onOpen={() => setPreviewBackdrop(true)}
                    onClose={() => setPreviewBackdrop(false)}
                  >
                    <img
                      src={previewImage}
                      alt=''
                      style={{ height: '300px', width: '505px', objectFit: 'contain' }}
                    />
                  </BackdropImage>
                )}
              </Box>
              <TextField
                required
                id='carImage'
                label='Car Image'
                type='file'
                placeholder='carImage'
                InputLabelProps={{ shrink: true }}
                inputProps={{ accept: 'image/png, image/gif, image/jpeg', shrink: true }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const files = e && e.target && e.target.files
                  if (files && files.length > 0 && !isEmpty(files)) {
                    setImg(files[0])
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const reader: any = new FileReader()
                    reader.onload = () => {
                      setPreviewImage(reader.result)
                    }
                    reader.readAsDataURL(files[0])
                  } else {
                    setPreviewImage('')
                  }
                }}
              />

              <FormControl fullWidth>
                <InputLabel id='Discontinued'>Discontinued</InputLabel>
                <Select
                  labelId='Discontinued'
                  id='Discontinued'
                  value={formik.values.Discontinued}
                  label='Discontinued'
                  onChange={(event) => formik.setFieldValue('Discontinued', event.target.value)}
                >
                  <MenuItem value='true'>Yes</MenuItem>
                  <MenuItem value='false'>No</MenuItem>
                </Select>
              </FormControl>
              <TextField
                id='ManualBookUrl'
                variant='outlined'
                label='Manual Book Url'
                onChange={formik.handleChange}
                value={formik.values.ManualBookUrl}
                onBlur={formik.handleBlur}
                error={
                  formik.touched &&
                  formik.touched.ManualBookUrl &&
                  Boolean(formik.errors.ManualBookUrl)
                }
                helperText={
                  formik.touched &&
                  formik.touched.ManualBookUrl &&
                  formik.errors &&
                  formik.errors.ManualBookUrl
                }
                fullWidth
              />
              <TextField
                id='BrochureUrl'
                variant='outlined'
                label='Brochure Url'
                onChange={formik.handleChange}
                value={formik.values.BrochureUrl}
                onBlur={formik.handleBlur}
                error={
                  formik.touched && formik.touched.BrochureUrl && Boolean(formik.errors.BrochureUrl)
                }
                helperText={
                  formik.touched &&
                  formik.touched.BrochureUrl &&
                  formik.errors &&
                  formik.errors.BrochureUrl
                }
                fullWidth
              />
              <FormControl fullWidth>
                <InputLabel id='Status'>Status</InputLabel>
                <Select
                  labelId='Status'
                  id='Status'
                  displayEmpty
                  value={data && data.status}
                  defaultValue={formik.values.Status}
                  label='Visibility'
                  onChange={(event) => formik.setFieldValue('Status', event.target.value)}
                >
                  <MenuItem value='Enabled'>Enabled</MenuItem>
                  <MenuItem value='Disabled'>Disabled</MenuItem>
                </Select>
              </FormControl>
              <TextField
                id='Remark'
                variant='outlined'
                label='Remark'
                onChange={formik.handleChange}
                value={formik.values.Remark}
                onBlur={formik.handleBlur}
                error={formik.touched && formik.touched.Remark && Boolean(formik.errors.Remark)}
                helperText={
                  formik.touched && formik.touched.Remark && formik.errors && formik.errors.Remark
                }
                fullWidth
              />
              <TextField
                id='Description'
                variant='outlined'
                label='Description'
                onChange={formik.handleChange}
                value={formik.values.Description}
                onBlur={formik.handleBlur}
                error={
                  formik.touched && formik.touched.Description && Boolean(formik.errors.Description)
                }
                helperText={
                  formik.touched &&
                  formik.touched.Description &&
                  formik.errors &&
                  formik.errors.Description
                }
                fullWidth
              />
            </FormikProvider>
          )}
        </Container>
      </Dialog>

      <Notification
        open={!update.isLoading && !update.isUninitialized}
        onClose={() => (update.isError ? update.reset() : location.reload())}
        isError={Boolean(update.error) && update.isError}
        message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
      />
    </>
  )
}

export default Edit
