import CONSTANT from 'modules/constant'
import type {CityLoungeProps, PaginationProps} from 'modules/types'
import {API, headers} from './api'

const GarudaAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getBookingList: builder.mutation<PaginationProps<CityLoungeProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_CITY_LOUNGE,
        method: 'POST',
        headers: headers({useContentType: false}),
        body: body,
        formData: true
      })
    }),
  })
})

export const { useGetBookingListMutation } =
  GarudaAPI