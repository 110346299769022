import { useEffect } from 'react'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { Container, TextField } from '@mui/material'
import Dialog from 'components/dialog/dialog'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { PartialEvModelProps } from 'modules/partial'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import { useCreateEvModelMutation } from 'store/evModel'
import { useGetCarBrandDropdownMutation } from 'store/dropdown'
import DialogStyle from './style'

const Create = ({ open, name, onClose }: DetailStateProps & { name: string }) => {
  const [createEvModel, create] = useCreateEvModelMutation()
  const [getCarBrandDropdown, brandDropdown] = useGetCarBrandDropdownMutation()

  useEffect(() => {
    getCarBrandDropdown()
  }, [])

  const selectedCarBrand =
    (!brandDropdown.isLoading &&
      brandDropdown &&
      brandDropdown.data &&
      brandDropdown.data.find((brand) => brand.name.toLowerCase() == name.toLowerCase())) ||
    null

  const scheme = yup.object<PartialEvModelProps>({
    BrandId: yup.number().required('EV Car Brand is required'),
    Name: yup.string().required('EV Car Model Id is required'),
    Description: yup.string().required('EV Car Model Id is required')
  })

  const formik = useFormik<PartialEvModelProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      BrandId: (selectedCarBrand && selectedCarBrand.id) || null,
      Name: '',
      Description: ''
    },
    onSubmit: (values: PartialEvModelProps) => createEvModel(values)
  })

  return (
    <>
      <Dialog
        open={open}
        title='Create New EV Car Model'
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={create.isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          <FormikProvider value={formik}>
            <TextField
              InputLabelProps={{
                shrink: true
              }}
              label='Brand Car Name'
              fullWidth
              value={selectedCarBrand?.name}
              disabled
            />
            {/* <Autocomplete
              options={(brandDropdown && brandDropdown.data) || []}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) =>
                option && value ? option.id == value.id : false
              }
              onOpen={() => null}
              value={selectedCarBrand}
              ListboxProps={GlobalStyle.ListBox}
              readOnly
              renderOption={(props, item) => (
                <li {...props} key={item.id}>
                  {item.name}
                </li>
              )}
              disabled
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='BrandId'
                  label='Brand Car Name'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {brandDropdown.isLoading && <CircularProgress color='inherit' size={20} />}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
            /> */}
            <TextField
              id='Name'
              variant='outlined'
              label='EV Car Model'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched && formik.touched.Name && Boolean(formik.errors.Name)}
              helperText={
                formik.touched && formik.touched.Name && formik.errors && formik.errors.Name
              }
              fullWidth
            />
            <TextField
              id='Description'
              variant='outlined'
              label='Description'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched && formik.touched.Description && Boolean(formik.errors.Description)
              }
              helperText={
                formik.touched &&
                formik.touched.Description &&
                formik.errors &&
                formik.errors.Description
              }
              fullWidth
            />
          </FormikProvider>
        </Container>
      </Dialog>

      <Notification
        open={!create.isLoading && !create.isUninitialized}
        onClose={() => (create.isError ? create.reset() : location.reload())}
        isError={Boolean(create.error) && create.isError}
        message={GLOBAL.returnExceptionMessage(create.isError, create.error as ErrorProps)}
      />
    </>
  )
}

export default Create
