import CONSTANT from 'modules/constant'
import type { PartialPrivacyDetailProps, PartialStatusProps } from 'modules/partial'
import type { HTMLProps, PaginationProps, ResultProps } from 'modules/types'
import { API, download, headers } from './api'

const PrivacyAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    createPrivacyPolicy: builder.mutation<ResultProps, PartialPrivacyDetailProps>({
      query: (body) => ({
        url: CONSTANT.URL_PRIVACY_POLICY_CREATE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    getPrivacyPolicy: builder.mutation<PaginationProps<HTMLProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_PRIVACY_POLICY,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    getPrivacyPolicyDetail: builder.query<PartialPrivacyDetailProps, { key: string }>({
      query: (body) => ({
        url: CONSTANT.URL_PRIVACY_POLICY_DETAIL,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    enablePrivacyPolicy: builder.mutation<ResultProps, PartialStatusProps>({
      query: (body) => ({
        url: CONSTANT.URL_PRIVACY_POLICY_STATUS,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    updatePrivacyPolicy: builder.mutation<ResultProps, PartialPrivacyDetailProps>({
      query: (body) => ({
        url: CONSTANT.URL_PRIVACY_POLICY_UPDATE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    deletePrivacyPolicy: builder.mutation<ResultProps, { key: string }>({
      query: (body) => ({
        url: CONSTANT.URL_PRIVACY_POLICY_DELETE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    downloadPrivacyPolicy: builder.mutation<PaginationProps<HTMLProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_PRIVACY_POLICY_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        responseHandler: download,
        body: body,
        formData: true
      })
    })
  })
})

export const {
  useCreatePrivacyPolicyMutation,
  useGetPrivacyPolicyMutation,
  useGetPrivacyPolicyDetailQuery,
  useEnablePrivacyPolicyMutation,
  useUpdatePrivacyPolicyMutation,
  useDeletePrivacyPolicyMutation,
  useDownloadPrivacyPolicyMutation
} = PrivacyAPI
