import type { LoadingButtonProps } from '@mui/lab'
import type { BoxProps, ContainerProps, StackProps } from '@mui/material'

class PageStyle {
  static Container: BoxProps = {
    sx: {
      display: 'flex',
      gap: 2
    }
  }

  static Box: StackProps = {
    width: 250,
    sx: {
      display: 'flex',
      gap: 2,
      flexDirection: 'column'
    }
  }

  static Button: LoadingButtonProps = {
    variant: 'contained',
    sx: {
      width: '100%'
    }
  }

  static NotFound: ContainerProps = {
    maxWidth: false,
    disableGutters: true,
    sx: {
      display: 'flex',
      width: '100%',
      padding: '2rem',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }
}

export default PageStyle
