import { useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Grid,
  TextField,
  Typography
} from '@mui/material'
import { ExpandMoreOutlined } from '@mui/icons-material'
import Drawer from 'components/drawer/detail'
import Loading from 'components/loading/loading'
import { useGetDetailPartnerLocationQuery } from 'store/locationPartner'
import type { DetailStateProps } from 'modules/types'
import BackdropImage from 'components/backdropImage/backdropImage'
import DrawerStyle from './style'

const Detail = ({ open, id, onClose }: DetailStateProps & { id: string }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const { data, isFetching, isLoading, isSuccess } = useGetDetailPartnerLocationQuery(id)
  const [previewBackdrop, setPreviewBackdrop] = useState<boolean>(false)
  const [bannerBackdrop, setBannerBackdrop] = useState<boolean>(false)

  const city = data && isSuccess && (data.city || '-')
  const email = data && isSuccess && (data.email || '-')
  const address = data && isSuccess && (data.address || '-')
  const postalCode = data && isSuccess && (data.postalCode || '-')

  const phoneNumber = data && isSuccess && (data.phoneNumber || '-')
  const description = data && isSuccess && (data.description || '-')

  const companyBanner = data && isSuccess && (data.companyBanner || '-')
  const companyLogo = data && isSuccess && (data.companyLogo || '-')
  const partnerName = data && isSuccess && (data.partnerName || '-')
  const partnerShortName = data && isSuccess && (data.partnerShortName || '-')
  const googleMapUrl = data && isSuccess && (data.googleMapUrl || '-')
  const latitude = data && isSuccess && (data.latitude || '-')
  const longitude = data && isSuccess && (data.longitude || '-')
  const playStoreUrl = data && isSuccess && (data.playStoreUrl || '-')
  const appStoreUrl = data && isSuccess && (data.appStoreUrl || '-')
  const status = data && isSuccess && (data.status || '-')
  const gridKeyPersonal = ['Email', 'Address', 'PhoneNumber', 'City', 'PostalCode', 'Description']
  const gridValuePersonal = [email, address, phoneNumber, city, postalCode, description]
  const gridKeyOther = [
    'Partner Name',
    'Partner Short Name',
    'Google Map Url',
    'Latitude',
    'Longitude',
    'PlayStore Url',
    'AppStore Url',
    'Status'
  ]
  const gridValueOther = [
    partnerName,
    partnerShortName,
    googleMapUrl,
    latitude,
    longitude,
    playStoreUrl,
    appStoreUrl,
    status
  ]
  const createdBy = data && isSuccess && (data.createdBy || '-')
  const createdAt = data && isSuccess && (data.createdAt || '-')
  const modifiedBy = data && isSuccess && (data.modifiedBy || '-')
  const modifiedAt = data && isSuccess && (data.modifiedAt || '-')
  const gridBasicLabel = ['Created By', 'Created At', 'Modified By', 'Modified At']
  const gridBasicValue = [createdBy, createdAt, modifiedBy, modifiedAt]

  return (
    <>
      <Drawer open={open} title='Partner Location Detail' onClose={onClose}>
        <Container {...DrawerStyle.Container}>
          {(isLoading || isFetching) && !data && <Loading />}
          {data && isSuccess && (
            <Grid container spacing={2}>
              {gridValuePersonal.map((item, index) => (
                <Grid key={index} item xs={12}>
                  <TextField
                    variant='outlined'
                    label={gridKeyPersonal[index]}
                    value={item}
                    inputProps={{ readOnly: true }}
                    fullWidth
                  />
                </Grid>
              ))}
              <Grid item xs={12}>
                <Typography>Company Banner</Typography>
                <div
                  style={{
                    height: '300px',
                    width: '465px',
                    border: '1px solid #eee',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  {!companyBanner && (
                    <Typography style={{ color: '#cfcfcf' }}>Company Banner empty</Typography>
                  )}
                  {companyBanner && (
                    <BackdropImage
                      open={bannerBackdrop}
                      onOpen={() => setBannerBackdrop(true)}
                      onClose={() => setBannerBackdrop(false)}
                    >
                      <img
                        src={companyBanner as string | undefined}
                        alt=''
                        style={{ height: '300px', maxWidth: '465px' }}
                      />
                    </BackdropImage>
                  )}
                </div>
              </Grid>
              <Grid item xs={12}>
                <Typography>Company Logo</Typography>
                <div
                  style={{
                    height: '300px',
                    width: '465px',
                    border: '1px solid #eee',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  {!companyLogo && (
                    <Typography style={{ color: '#cfcfcf' }}>Company Logo empty</Typography>
                  )}
                  {companyLogo && (
                    <BackdropImage
                      open={previewBackdrop}
                      onOpen={() => setPreviewBackdrop(true)}
                      onClose={() => setPreviewBackdrop(false)}
                    >
                      <img
                        src={companyLogo as string | undefined}
                        alt=''
                        style={{ height: '300px', maxWidth: '465px' }}
                      />
                    </BackdropImage>
                  )}
                </div>
              </Grid>
            </Grid>
          )}

          {data && isSuccess && (
            <>
              <Accordion elevation={0} disableGutters>
                <AccordionSummary
                  expandIcon={<ExpandMoreOutlined />}
                  aria-controls='other-information'
                >
                  <Typography>Other Information</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    {gridValueOther.map((item, index) => (
                      <Grid key={index} item xs={12}>
                        <TextField
                          variant='outlined'
                          label={gridKeyOther[index]}
                          value={item}
                          inputProps={{ readOnly: true }}
                          fullWidth
                        />
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion elevation={0} disableGutters>
                <AccordionSummary
                  expandIcon={<ExpandMoreOutlined />}
                  aria-controls='basic-information'
                >
                  <Typography>Basic Information</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    {gridBasicValue.map((item, index) => (
                      <Grid key={index} item xs={12}>
                        <TextField
                          variant='outlined'
                          label={gridBasicLabel[index]}
                          value={item}
                          inputProps={{ readOnly: true }}
                          fullWidth
                        />
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </>
          )}
        </Container>
      </Drawer>
    </>
  )
}

export default Detail
