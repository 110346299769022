import { useState } from 'react'
import { isEmpty } from 'lodash'
import * as yup from 'yup'
import { Autocomplete, Box, CircularProgress, Container, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import Dialog from 'components/dialog/dialog'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import FORMIK from 'modules/formik'
import { useCreateListBannerMutation, useGetEventDropdownMutation } from 'store/serviceBookingEventBanner'
import type { PartialBannerCreateDataProps, PartialBannerCreateProps } from 'modules/partial'
import type {
    CallbackHandler, DetailStateProps,
    ErrorProps
} from 'modules/types'
import BackdropImage from 'components/backdropImage/backdropImage'
import GlobalStyle from 'modules/styles'
import DialogStyle from './style'


const Create = ({ open, onClose }: DetailStateProps) => {
    if (!open) return <></>
    
    const [getEventDropdown, eventDropdown] = useGetEventDropdownMutation()
    const [createBannerList, create] = useCreateListBannerMutation()

    const [previewImage, setPreviewImage] = useState<string | ArrayBuffer | null>(null)
    const [previewBackdrop, setPreviewBackdrop] = useState<boolean>(false)

    const scheme = yup.object<PartialBannerCreateProps>({
        Image: yup.string().required('BannerImage is required'),
        data: yup.object<PartialBannerCreateDataProps>({
            EventId: yup.string().required('EventId is required'),
            ValidFrom: yup.string().required('ValidFrom is required'),
            ValidUntil: yup.string().required('ValidUntil is required'),
            Status: yup.string().required('Status Type is required')
        })
    })

    const initialValues: PartialBannerCreateProps = {
        Image: {} as File,
        data: {
            bannerImage: '',
            EventId: '',
            ValidFrom: '',
            ValidUntil: '',
            Status: ''
        }
    }

    const formik = FORMIK.useFormFormik<PartialBannerCreateProps>(
        scheme,
        initialValues,
        (values) => {
            const formData = new FormData()

            const data = {
                ...values.data
            }
            
            if (values.Image) formData.append('Image', values.Image)
            formData.append('data', JSON.stringify(data))
            createBannerList(formData)
        }
    )

    const fileOnChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        field: string,
        callback: CallbackHandler<string | ArrayBuffer | null>
    ) => {
        const files = e && e.target && e.target.files
        const notEmpty = files && files.length > 0 && !isEmpty(files)

        if (notEmpty) {
            const reader = new FileReader()

            reader.onload = () => callback(reader.result)
            reader.readAsDataURL(files[0])
            formik.setFieldValue('data.bannerImage', files[0].name)
            formik.setFieldValue(field, files[0])
            return
        }
        
        formik.setFieldValue(field, {} as File)
        return callback(null)
    }

    const error = formik.errors && (formik.errors.data as unknown as PartialBannerCreateDataProps)
    const touched = formik.touched && (formik.touched as PartialBannerCreateDataProps)
    const requestToken = formik.values.data

    // console.log('validFrom', payloadDate.validFrom);
    // console.log('formik', formik);
    // const disabled =
    //     !formik.isValid || isEmpty(previewImage)


    return (
        <>
            <Dialog
                title='Create Banner'
                open={open}
                onCancel={onClose}
                onSubmit={() => formik.handleSubmit()}
                loading={create.isLoading}
                isDisabled={!formik.isValid}
            // isDisabled={disabled}
            >
                <Container {...DialogStyle.Container}>
                    <Autocomplete
                        options={(eventDropdown && eventDropdown.data) || []}
                        getOptionLabel={(list) => list.text}
                        isOptionEqualToValue={(option, value) =>
                            option && value ? option.value == value.value : false
                        }
                        onOpen={() => getEventDropdown()}
                        onChange={(_, id) => formik.setFieldValue('data.EventId', id && id.value)}
                        ListboxProps={GlobalStyle.ListBox}
                        renderOption={(props, item) => (
                            <li {...props} key={item.value}>
                                {item.text}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                name='EventId'
                                label='Event'
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {eventDropdown.isLoading && (
                                                <CircularProgress color='inherit' size={20} />
                                            )}
                                            {params.InputProps.endAdornment}
                                        </>
                                    )
                                }}
                            />
                        )}
                    />
                    <TextField
                        id='Image'
                        label='Banner Image'
                        type='file'
                        placeholder='Image'
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ accept: 'image/png, image/gif, image/jpeg' }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            fileOnChange(e, 'Image', (value) => setPreviewImage(value))
                        }
                        onBlur={formik.handleBlur}
                        fullWidth
                    />
                    {previewImage && (
                        <Grid item xs={12}>
                            <Typography {...DialogStyle.Title}>Banner Image</Typography>
                            <Box {...DialogStyle.Image}>
                                <BackdropImage
                                    open={previewBackdrop}
                                    onOpen={() => setPreviewBackdrop(true)}
                                    onClose={() => setPreviewBackdrop(false)}
                                >
                                    <img
                                        src={previewImage as string | undefined}
                                        alt='Banner Image'
                                        width='100%'
                                        height='100%'
                                    />
                                </BackdropImage>
                            </Box>
                        </Grid>
                    )}
                    <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                        <DatePicker
                            value={requestToken && requestToken.ValidFrom ? dayjs(requestToken.ValidFrom) : undefined}
                            onChange={(e: Dayjs | null) => {
                                const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                                formik.setFieldValue('data.ValidFrom', newDate)
                                // setPayloadDate({ ...payloadDate, validFrom: newDate })
                                // setDate((prevState) => ({ ...prevState, validFrom: e }))
                            }}
                            label='Valid From'
                            format='DD-MM-YYYY'
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                        <DatePicker
                            value={requestToken && requestToken.ValidUntil ? dayjs(requestToken.ValidUntil) : undefined}
                            minDate={requestToken && requestToken.ValidFrom ? dayjs(requestToken.ValidFrom) : undefined}
                            onChange={(e: Dayjs | null) => {

                                const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                                formik.setFieldValue('data.ValidUntil', newDate)
                                // setPayloadDate({ ...payloadDate, validUntil: newDate })
                                // setDate((prevState) => ({ ...prevState, validUntil: e }))
                            }}
                            label='Valid Until'
                            format='DD-MM-YYYY'
                        />
                    </LocalizationProvider>
                    <FormControl fullWidth>
                        <InputLabel id='Status'>Status</InputLabel>
                        <Select
                            labelId='Status'
                            id='Status'
                            variant='outlined'
                            label='Voucher Type *'
                            value={formik.values.data.Status}
                            onChange={(event) => {
                                formik.setFieldValue('data.Status', '')
                                formik.setFieldValue('data.Status', event.target.value)
                            }}
                            error={
                                touched && Boolean(touched.Status) && error && Boolean(error.Status)
                            }
                        >
                            <MenuItem value='Enabled'>Enabled</MenuItem>
                            <MenuItem value='Disabled'>Disabled</MenuItem>
                        </Select>
                    </FormControl>
                </Container>
            </Dialog>

            <Notification
                open={!create.isLoading && !create.isUninitialized}
                onClose={() => (create.isError ? create.reset() : location.reload())}
                isError={Boolean(create.error) && create.isError}
                message={GLOBAL.returnExceptionMessage(create.isError, create.error as ErrorProps)}
            />
        </>
    )
}

export default Create
