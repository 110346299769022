import CONSTANT from 'modules/constant'
import type {
  ChargerFilterProps,
  EvPartnerManagementDetailProps,
  EvPartnerManagementProps,
  PaginationProps,
  ResultProps
} from 'modules/types'
import { API, download, headers } from './api'

const EvPartnerAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getEvPartner: builder.mutation<PaginationProps<EvPartnerManagementProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_EV_PARTNER_LIST,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    downloadEvPartner: builder.mutation<PaginationProps<EvPartnerManagementProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_EV_PARTNER_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true,
        responseHandler: download
      })
    }),
    getEvDetailPartner: builder.query<EvPartnerManagementDetailProps, number>({
      query: (id) => ({
        url: CONSTANT.URL_EV_PARTNER_DETAIL + id,
        method: 'GET',
        headers: headers()
      })
    }),
    deleteEvPartner: builder.mutation<ResultProps, number>({
      query: (id) => ({
        url: CONSTANT.URL_EV_PARTNER_DELETE + id,
        method: 'DELETE',
        headers: headers()
      })
    }),
    createEvPartner: builder.mutation<ResultProps, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_EV_PARTNER_CREATE,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    updateEvPartner: builder.mutation<ResultProps, { body: FormData; id: number }>({
      query: ({ body, id }) => ({
        url: CONSTANT.URL_EV_PARTNER_UPDATE + id,
        method: 'PUT',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    filterEvPartner: builder.mutation<ChargerFilterProps[], void>({
      query: () => ({
        url: CONSTANT.URL_EV_PARTNER_UPDATE + 'ddl?start=0&length=100',
        method: 'GET',
        headers: headers()
      })
    })
  })
})

export const {
  useGetEvPartnerMutation,
  useDownloadEvPartnerMutation,
  useDeleteEvPartnerMutation,
  useCreateEvPartnerMutation,
  useUpdateEvPartnerMutation,
  useFilterEvPartnerMutation,

  useGetEvDetailPartnerQuery
} = EvPartnerAPI
