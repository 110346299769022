import { useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import {
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  OutlinedInput
} from '@mui/material'
import { VisibilityOff, Visibility } from '@mui/icons-material'
import Dialog from 'components/dialog/dialog'
import type { PartialChangePasswordProps } from 'modules/partial'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import { useUpdateChangePasswordMutation } from 'store/password'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import DialogStyle from './style'

const ChangePassword = ({ open, onClose }: DetailStateProps) => {
  const [changePassword, update] = useUpdateChangePasswordMutation()
  const [visibility, setVisibility] = useState<{ old: boolean; new: boolean; retype: boolean }>({
    old: false,
    new: false,
    retype: false
  })

  const onSubmit = (values: PartialChangePasswordProps) => changePassword(values)

  const scheme = yup.object<PartialChangePasswordProps>({
    OldPassword: yup.string().required('Old Password is required'),
    NewPassword: yup.string().required('New Password is required'),
    RetypePassword: yup.string().oneOf([yup.ref('NewPassword')], 'Passwords does not match'),
    LogoutOtherDevice: yup.boolean()
  })

  const formik = useFormik<PartialChangePasswordProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      OldPassword: '',
      NewPassword: '',
      RetypePassword: '',
      LogoutOtherDevice: true
    },
    onSubmit: onSubmit
  })

  return (
    <>
      <Dialog
        title='Change Password'
        open={open}
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={update.isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          <FormControl fullWidth>
            <InputLabel htmlFor='OldPassword'>Old Password</InputLabel>
            <OutlinedInput
              id='OldPassword'
              label='Old Password'
              type={visibility.old ? 'text' : 'password'}
              autoComplete='current-password'
              value={formik.values.OldPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched && formik.touched.OldPassword && Boolean(formik.errors.OldPassword)
              }
              inputProps={{
                autoComplete: 'off'
              }}
              endAdornment={
                <IconButton
                  aria-label='password visibility'
                  onClick={() => setVisibility({ ...visibility, old: !visibility.old })}
                  edge='end'
                >
                  {visibility.old ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              }
            />
            {formik.touched && formik.touched.OldPassword && formik.errors && (
              <FormHelperText error id='OldPassword'>
                {formik.errors.OldPassword}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth>
            <InputLabel htmlFor='NewPassword'>New Password</InputLabel>
            <OutlinedInput
              id='NewPassword'
              label='New Password'
              type={visibility.new ? 'text' : 'password'}
              autoComplete='current-password'
              value={formik.values.NewPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched && formik.touched.NewPassword && Boolean(formik.errors.NewPassword)
              }
              inputProps={{
                autoComplete: 'off'
              }}
              endAdornment={
                <IconButton
                  aria-label='password visibility'
                  onClick={() => setVisibility({ ...visibility, new: !visibility.new })}
                  edge='end'
                >
                  {visibility.new ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              }
            />
            {formik.touched && formik.touched.NewPassword && formik.errors && (
              <FormHelperText error id='NewPassword'>
                {formik.errors.NewPassword}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth>
            <InputLabel htmlFor='RetypePassword'>Retype Password</InputLabel>
            <OutlinedInput
              id='RetypePassword'
              label='Retype Password'
              type={visibility.retype ? 'text' : 'password'}
              autoComplete='current-password'
              value={formik.values.RetypePassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched &&
                formik.touched.RetypePassword &&
                Boolean(formik.errors.RetypePassword)
              }
              inputProps={{
                autoComplete: 'off'
              }}
              endAdornment={
                <IconButton
                  aria-label='password visibility'
                  onClick={() => setVisibility({ ...visibility, retype: !visibility.retype })}
                  edge='end'
                >
                  {visibility.retype ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              }
            />
            {formik.touched && formik.touched.RetypePassword && formik.errors && (
              <FormHelperText error id='RetypePassword'>
                {formik.errors.RetypePassword}
              </FormHelperText>
            )}
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                id='LogoutOtherDevice'
                defaultChecked
                value={formik.values.LogoutOtherDevice}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            }
            label='Logout from other device'
          />
        </Container>
      </Dialog>

      <Notification
        open={!update.isLoading && !update.isUninitialized}
        onClose={() => (update.isError ? update.reset() : location.reload())}
        isError={Boolean(update.error) && update.isError}
        message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
      />
    </>
  )
}

export default ChangePassword
