import CONSTANT from 'modules/constant'
import type {
  DropdownCategoryListProps,
  PaginationProps,
  ResultProps,
  TokenImportListProps,
  TokenSubImportListProps
} from 'modules/types'
import { API, download, headers } from './api'

const TokenImportAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getListTokenImport: builder.mutation<PaginationProps<TokenImportListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_TOKEN_IMPORT,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body
      })
    }),
    getTokenImportDetail: builder.query<TokenImportListProps, number | string>({
      query: (Id) => ({
        url: CONSTANT.URL_TOKEN_IMPORT_DETAIL + Id,
        method: 'GET',
        headers: headers()
      })
    }),
    downloadTokenImport: builder.mutation<PaginationProps<TokenImportListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_TOKEN_IMPORT_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        responseHandler: download,
        body: body,
        formData: true
      })
    }),
    getCampaignDropdown: builder.mutation<DropdownCategoryListProps[], void>({
      query: () => ({
        url: CONSTANT.URL_TOKEN_CAMPAIGN,
        method: 'GET',
        headers: headers()
      })
    }),
    getCampaignTypeDropdown: builder.mutation<DropdownCategoryListProps[], void>({
      query: () => ({
        url: CONSTANT.URL_TOKEN_CAMPAIGN_TYPE,
        method: 'GET',
        headers: headers()
      })
    }),

    getListTokenSubImport: builder.mutation<PaginationProps<TokenSubImportListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_TOKEN_SUB_IMPORT,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body
      })
    }),
    downloadTemplateImport: builder.mutation<PaginationProps<TokenImportListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_TOKEN_IMPORT_TEMPLATE,
        method: 'POST',
        headers: headers({ useContentType: false }),
        responseHandler: download,
        body: body
      })
    }),
    uploadTokenImport: builder.mutation<ResultProps, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_TOKEN_IMPORT_UPLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body
      })
    }),
    downloadTokenSubImport: builder.mutation<PaginationProps<TokenSubImportListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_TOKEN_SUBIMPORT_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        responseHandler: download,
        body: body,
        formData: true
      })
    })
  })
})

export const {
  useGetListTokenImportMutation,
  useGetTokenImportDetailQuery,
  useDownloadTokenImportMutation,
  useGetCampaignDropdownMutation,
  useGetCampaignTypeDropdownMutation,
  useGetListTokenSubImportMutation,
  useDownloadTemplateImportMutation,
  useUploadTokenImportMutation,
  useDownloadTokenSubImportMutation
} = TokenImportAPI
