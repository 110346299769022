import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  Typography
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import type { PartialCatalogHyundai } from 'modules/partial'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import {
  useDeleteHyundaiModelMutation,
  useDownloadHyundaiModelMutation,
  useGetHyundaiModelListMutation
} from 'store/hyundaiModel'
import type { ErrorProps } from 'modules/types'
import CONSTANT from 'modules/constant'
import Icon from 'components/icon'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import MenuModalItem from 'components/menuItem/menuItem'
import GlobalStyle from 'modules/styles'
import Detail from './detail/detail'
import Create from './create/create'
import Edit from './edit/edit'

const HyundaiCarModelList = () => {
  const navigate = useNavigate()
  const [getHyundaiModelList, hyundaiModel] = useGetHyundaiModelListMutation()
  const [downloadHyundaiModel, download] = useDownloadHyundaiModelMutation()
  const [deleteHyundaiModel, deleteHyundai] = useDeleteHyundaiModelMutation()
  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [drawerCreate, setDrawerCreate] = useState<boolean>(false)
  const [dialogUpdate, setDialogUpdate] = useState<boolean>(false)

  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [menu, setMenu] = useState<null | number>(null)
  const [filter, setFilter] = useState<string>('')
  const [payload, setPayload] = useState<PartialCatalogHyundai>({
    start: 0,
    length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    field: 'name,discontinued',
    search: { value: '' },
    discontinued: ''
  })

  const onSearch = (value: string) => setPayload({ ...payload, start: 0, search: { value } })
  const onFilter = (state: boolean) => setDialogFilter(state)
  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('start', String(0))
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(payload.discontinued)) formData.append('discontinued', payload.discontinued)
    if (type && !isEmpty(type)) formData.append('Download-Type', type)
    downloadHyundaiModel(formData)
  }
  const onCreate = () => setDrawerCreate(true)

  const onChangePage = (value: number) => setPayload({ ...payload, start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, length: value })

  const onResync = () => {
    const formData = new FormData()
    formData.append('start', payload.start.toString())
    formData.append('length', payload.length.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(payload.discontinued)) formData.append('discontinued', payload.discontinued)

    getHyundaiModelList(formData)
  }

  const tableHeadTitles = ['Hyundai Car Name', 'Discontinued']

  useEffect(() => {
    onResync()
  }, [payload])

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const openAnchor = Boolean(anchor)

  const totalPage = hyundaiModel.data && GLOBAL.tableTotalPage(hyundaiModel.data.recordsTotal)

  const numbers = GLOBAL.tableNumber(payload.start)

  return (
    <>
      <Content
        title='Hyundai Car Model'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        downloadLoading={download.isLoading}
        onAdditional={onCreate}
        additionalTitle='Create New Hyundai Car Model'
        additionalPrefixIcon='Create'
        totalPage={totalPage}
        page={payload.start + 1}
        isLoading={hyundaiModel.isLoading}
        totalRecords={hyundaiModel.data && hyundaiModel.data.recordsTotal}
        useDownloadDropdown
      >
        {hyundaiModel.isSuccess &&
          hyundaiModel.data &&
          hyundaiModel.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.id}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.id}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.id}`}
                  aria-labelledby={`button-${index}-${table.id}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem
                    onClick={() =>
                      navigate(GLOBAL.encodeUrl(table.name), { state: { id: table.id } })
                    }
                  >
                    Open
                  </MenuItem>
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View
                  </MenuItem>
                  <MenuItem onClick={() => onRemoveAnchor(() => setDialogUpdate(true))}>
                    Edit
                  </MenuItem>
                  <MenuModalItem
                    title={table.name}
                    onClick={() => onRemoveAnchor(() => deleteHyundaiModel({ id: table.id }))}
                  >
                    <Typography {...GlobalStyle.ErrorTypography}>Delete</Typography>
                  </MenuModalItem>
                </Menu>
                <Detail
                  id={table.id}
                  open={drawerDetail && menu == index}
                  onClose={() => setDrawerDetail(false)}
                />
                {dialogUpdate && menu == index && (
                  <Edit
                    id={table.id}
                    open={dialogUpdate && menu == index}
                    onClose={() => setDialogUpdate(false)}
                  />
                )}
              </TableCell>
              <TableCell>{table.name || '-'}</TableCell>
              <TableCell>{table.discontinued ? 'Yes' : 'No'}</TableCell>
            </TableRow>
          ))}
      </Content>
      {hyundaiModel.isLoading && <Loading />}
      {drawerCreate && <Create open={drawerCreate} onClose={() => setDrawerCreate(false)} />}
      <Notification
        open={!deleteHyundai.isLoading && !deleteHyundai.isUninitialized}
        onClose={() => (deleteHyundai.isError ? deleteHyundai.reset() : location.reload())}
        isError={Boolean(deleteHyundai.error) && deleteHyundai.isError}
        message={GLOBAL.returnExceptionMessage(
          deleteHyundai.isError,
          deleteHyundai.error as ErrorProps
        )}
      />
      <Notification
        open={!hyundaiModel.isLoading && !hyundaiModel.isUninitialized && !hyundaiModel.isSuccess}
        onClose={() => (hyundaiModel.isError ? hyundaiModel.reset() : location.reload())}
        isError={Boolean(hyundaiModel.error) && hyundaiModel.isError}
        message={GLOBAL.returnExceptionMessage(
          hyundaiModel.isError,
          hyundaiModel.error as ErrorProps
        )}
      />
      <Filter
        open={dialogFilter}
        title='Filter'
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        isSubmitDisabled={isEmpty(filter)}
        onReset={() => {
          setFilter('')
          setPayload({
            start: 0,
            length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
            field: 'name,discontinued',
            search: { value: '' },
            discontinued: ''
          })
        }}
        onSubmit={() => {
          setPayload({
            ...payload,
            discontinued: filter
          })
          onFilter(false)
        }}
      >
        <FormControl fullWidth>
          <InputLabel id='Discontinued'>Discontinued</InputLabel>
          <Select
            labelId='Discontinued'
            id='Discontinued'
            value={filter}
            label='Visibility'
            onChange={(event) => setFilter(event.target.value)}
          >
            <MenuItem value='true'>Yes</MenuItem>
            <MenuItem value='false'>No</MenuItem>
          </Select>
        </FormControl>
      </Filter>
    </>
  )
}

export default HyundaiCarModelList
