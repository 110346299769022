import { useEffect, useState } from "react"
import { Formik } from "formik"
import { isEmpty } from "lodash"
import { useLocation, useNavigate } from "react-router-dom"
import { Dialpad } from "@mui/icons-material"
import { Box, Container, FormControl, FormHelperText, IconButton, InputLabel, OutlinedInput } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import * as yup from 'yup'
import { useDispatch, useSelector } from "react-redux"
import Notification from 'components/notification'
import GLOBAL from "modules/global"
import type {
  ErrorProps,
  LoginOtpStateProps, ResponseValidateOtpProps, UserProps
} from "modules/types"
import { useOtpRequestMutation, useOtpValidationMutation } from "store/user"
import type { AppDispatch } from "store/api.store"
import { getUser } from "store/user.slice"
import type { PartialRequestOtpProps, PartialValidateOtpProps } from "modules/partial"
import LoginOtpStyle from "./style"



const LoginOtp = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const state = useSelector((state: { user: UserProps }) => state)

  const [otpRequest, otpRequestResponse] = useOtpRequestMutation()
  const [otpValidation, otpValidationResponse] = useOtpValidationMutation()
  const [initialValue] = useState({ otp: '' })
  const [validity, setValidity] = useState(60)
  const scheme = yup.object({
    otp: yup.string()
      .required('OTP Number is required.')
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(6, 'Must be exactly 6 digits')
      .max(6, 'Must be exactly 6 digits')
  })

  let intervalId: number;
  const startInterval = (initTimer: number) => {
    setValidity(initTimer)
    let countdown = initTimer

    intervalId = window.setInterval(() => {
      countdown -= 1
      setValidity(countdown)
      if (countdown <= 0) {
        clearInterval(intervalId)
      }
    }, 1000)
  }

  const sendOtp = (phonenumber: string) => {
    setValidity(60)
    const requestData: PartialRequestOtpProps = {
      PHONENUMBER: phonenumber,
      REQUESTUSING: 'PHONE'
    }
    otpRequest({
      body: requestData,
      token: location.state.loginResponse?.token
    }).unwrap().then((res) => {
      res?.id ? startInterval(60) : startInterval(3)
    }).catch(() => startInterval(3))
  }

  const setOtpValidationResponse = (isError: boolean, errProps: ErrorProps) => {
    otpValidationResponse.isError = isError
    otpValidationResponse.error = errProps
  }

  useEffect(() => {
    const requestState: LoginOtpStateProps = location.state

    if (process.env.REACT_APP_BYPASS_OTP === 'true') dispatch(getUser(location.state.loginResponse))
    if (!isEmpty(state.user.token)) return navigate('/')
    if (!requestState) return navigate('/login')
    else if (requestState.requestOtp) {
      sendOtp(requestState.phoneNumber)
      location.state.requestOtp = false
    }
    
    if (!otpValidationResponse.isLoading && otpValidationResponse.isSuccess) {
      const validateError: ErrorProps = {
        data: {Message: '', Status: 400},
        status: 400
      }

      try {
        const validateResponse = otpValidationResponse.data as ResponseValidateOtpProps
        validateError.data.Message = 'Invalid OTP code'

        if (validateResponse.isSuccess && validateResponse.data != null)
          dispatch(getUser(location.state.loginResponse))
        else
          setOtpValidationResponse(true, validateError)
      } catch (err) {
        validateError.data.Message = 'Unknown error'
        setOtpValidationResponse(true, validateError)
      }
    }

  }, [location, otpValidationResponse, state])

  return (
    <>
      <Container maxWidth={false} disableGutters>
        <Box {...LoginOtpStyle.Wrapper}>
          <Formik
            initialValues={initialValue}
            validationSchema={scheme}
            enableReinitialize
            validateOnMount
            onSubmit={(values, { setSubmitting }) => {
              const requestData: PartialValidateOtpProps = {
                Application: '',
                Email: '',
                IdOtpSSO: otpRequestResponse.isSuccess ? otpRequestResponse?.data.id : null,
                Key: null,
                OTP: values.otp,
                OtpFrom: 'WebAdmin',
                PhoneNumber: location.state.phoneNumber,
                RequestUsing: 'phone'
              }

              otpValidation({
                body: requestData,
                token: location.state.loginResponse?.token
              }).then(() => setSubmitting(false))
            }
            }
          >
            {({
              values,
              touched,
              errors,
              isValid,
              isSubmitting,
              handleBlur,
              handleChange,
              handleSubmit
            }) => (
              <form onSubmit={handleSubmit} {...LoginOtpStyle.Card}>
                <img src='/logo_myHyundai.png' alt='Logo' {...LoginOtpStyle.Logo} />
                <p><b>Verify Login</b><br />We sent OTP Verification to your registered number,<br />please check your mobile phone message.</p>
                <FormControl fullWidth>
                  <InputLabel htmlFor='otp'>OTP</InputLabel>
                  <OutlinedInput
                    id='otp'
                    label='OTP'
                    type='text'
                    value={values.otp}
                    inputProps={{ maxLength: 6 }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched && touched.otp && Boolean(errors.otp)}
                    endAdornment={
                      <IconButton aria-label='otp' edge='end'>
                        <Dialpad />
                      </IconButton>
                    }
                  />
                  {touched && touched.otp && errors && (
                    <FormHelperText error id='otp'>
                      {errors.otp}
                    </FormHelperText>
                  )}
                </FormControl>

                <p {...LoginOtpStyle.Validity}>
                  Didn&#39;t receive any codes?&nbsp;
                  {validity > 0 &&
                    <span>Resend after {validity} seconds.</span>
                  }
                  &nbsp;
                  {validity <= 0 &&
                    <a href="#" onClick={(e) => {
                      e.preventDefault()
                      sendOtp(location.state?.phoneNumber)
                    }}>Resend OTP</a>
                  }
                </p>

                <LoadingButton disabled={!isValid} loading={isSubmitting} {...LoginOtpStyle.Button}>
                  Verify
                </LoadingButton>
              </form>
            )}

          </Formik>
        </Box>
      </Container>

      <Notification
        open={!otpRequestResponse.isLoading && !otpRequestResponse.isUninitialized && !otpRequestResponse.isSuccess}
        isError={otpRequestResponse.isError || !otpRequestResponse.data?.id}
        message={GLOBAL.returnExceptionMessage(otpRequestResponse.isError, otpRequestResponse.error as ErrorProps)}
      />

      <Notification
        open={!otpValidationResponse.isLoading && !otpValidationResponse.isUninitialized}
        isError={otpValidationResponse.isError}
        message={GLOBAL.returnExceptionMessage(otpValidationResponse.isError, otpValidationResponse.error as ErrorProps)}
      />
    </>
  )
}

export default LoginOtp
