import type { BoxProps, ButtonProps } from '@mui/material'

class LoginStyle {
  static Wrapper: BoxProps = {
    display: 'flex',
    width: '100%',
    minHeight: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    sx: {
      backgroundColor: 'grey.100'
    }
  }

  static Card: React.HTMLAttributes<HTMLFormElement> = {
    style: {
      display: 'flex',
      minWidth: '30rem',
      padding: '4rem',
      gap: '1rem',
      borderRadius: '1rem',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: 'white',
      position: 'relative'
    }
  }

  static Logo: React.HTMLAttributes<HTMLImageElement> = {
    draggable: false,
    style: {
      width: '14rem',
      marginBottom: '1rem'
    }
  }

  static Button: ButtonProps = {
    type: 'submit',
    variant: 'contained',
    size: 'large',
    fullWidth: true,
    disableElevation: true,
    style: {
      marginTop: '1rem'
    }
  }

  static WrapperVersion: BoxProps = {
    sx: {
      position: 'absolute',
      bottom: '0',
      color: '#969696',
      fontSize: '12px',
      padding: '5px'
    }
  }
}

export default LoginStyle
