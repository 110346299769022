import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Grid,
  TextField,
  Typography
} from '@mui/material'
import { ExpandMoreOutlined } from '@mui/icons-material'
import Drawer from 'components/drawer/detail'
import type { DetailStateProps } from 'modules/types'
import Loading from 'components/loading/loading'
import { useGetDetailEvLocationQuery } from 'store/evLocation'
import DetailStyle from './style'

const Detail = ({ open, id, onClose }: DetailStateProps & { id: number }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const { data, isFetching, isLoading } = useGetDetailEvLocationQuery(id)
  const evLocationId = (data && data.code) || '-'
  const name = (data && data.name) || '-'
  const remarks = (data && data.remark) || '-'

  const provinceId = (data && data.provinceId) || '-'
  const streetAddress = (data && data.streetAddress) || '-'
  const city = (data && data.city) || '-'

  const floor = (data && data.floors) || '-'
  const country = (data && data.country) || '-'
  const place = (data && data.place) || '-'
  const postalCode = (data && data.postalCode) || '-'
  const kwhLimit = (data && data.kwhLimit) || '-'
  const operationalStartTime = (data && data.operationStartTime) || '-'
  const operationalEndTime = (data && data.operationEndTime) || '-'

  const gridDataLabel = [
    'City',
    'Street Address',
    'Province ID',
    'Floor',
    'Place',
    'Postal Code',
    'Country',
    'Kwh Limit',
    'Operational Start Time',
    'Operational End Time'
  ]
  const gridDataValue = [
    city,
    streetAddress,
    provinceId,
    floor,
    place,
    postalCode,
    country,
    kwhLimit,
    operationalStartTime,
    operationalEndTime
  ]

  const policyName = (data && data.policyName) || '-'
  const access = (data && data.visibility) || '-'
  const longitude = (data && data.longitude) || '-'
  const latitude = (data && data.latitude) || '-'
  const type = (data && data.type) || '-'

  const gridBasicLabel = ['Policy Name', 'Access', 'Longitude', 'Latitude', 'type']
  const gridBasicValue = [policyName, access, longitude, latitude, type]

  return (
    <Drawer open={open} title='EV Location Data Detail' onClose={onClose}>
      <Container {...DetailStyle.Container}>
        {(isLoading || isFetching) && !data && <Loading />}
        {data && (
          <TextField
            variant='outlined'
            label='EV Location ID'
            value={evLocationId}
            inputProps={{ readOnly: true }}
            fullWidth
          />
        )}
        {data && (
          <TextField
            variant='outlined'
            label='Name'
            value={name}
            inputProps={{ readOnly: true }}
            fullWidth
          />
        )}
        {data && (
          <TextField
            variant='outlined'
            label='Remarks'
            value={remarks}
            inputProps={{ readOnly: true }}
            fullWidth
          />
        )}
        {data && (
          <Grid container spacing={2}>
            {gridDataValue.map((item, index) => (
              <Grid key={index} item xs={6}>
                <TextField
                  variant='outlined'
                  label={gridDataLabel[index]}
                  value={item}
                  inputProps={{ readOnly: true }}
                  fullWidth
                />
              </Grid>
            ))}
          </Grid>
        )}
        {data && (
          <Accordion elevation={0} disableGutters>
            <AccordionSummary expandIcon={<ExpandMoreOutlined />} aria-controls='basic-information'>
              <Typography>Location</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {gridBasicValue.map((item, index) => (
                  <Grid key={index} item xs={12}>
                    <TextField
                      variant='outlined'
                      label={gridBasicLabel[index]}
                      value={item}
                      inputProps={{ readOnly: true }}
                      fullWidth
                    />
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
      </Container>
    </Drawer>
  )
}

export default Detail
