import CONSTANT from 'modules/constant'
import type { PartialRolesCreateProps, PartialRolesEditProps } from 'modules/partial'
import type {
  PaginationProps,
  ResultProps,
  RolesListDetailProps,
  RolesListDropdownProps,
  RolesListProps
} from 'modules/types'
import { API, download, headers } from './api'

const RolesAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getRolesList: builder.mutation<PaginationProps<RolesListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_ROLES_LIST,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    getRolesListDetail: builder.query<RolesListDetailProps, number | string>({
      query: (id) => ({
        url: CONSTANT.URL_ROLES_LIST_DETAIL + id,
        method: 'GET',
        headers: headers()
      })
    }),
    createRolesList: builder.mutation<ResultProps, PartialRolesCreateProps>({
      query: (body) => ({
        url: CONSTANT.URL_ROLES_LIST_CREATE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    updateRolesList: builder.mutation<ResultProps, PartialRolesEditProps>({
      query: (body) => ({
        url: CONSTANT.URL_ROLES_LIST_UPDATE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    getRolesListDropdown: builder.mutation<RolesListDropdownProps[], void>({
      query: () => ({
        url: CONSTANT.URL_ROLES_LIST_DROPDOWN,
        method: 'GET',
        headers: headers()
      })
    }),
    getRolesListTypeDropdown: builder.mutation<RolesListDropdownProps[], void>({
      query: () => ({
        url: CONSTANT.URL_ROLES_LIST_TYPE_DROPDOWN,
        method: 'GET',
        headers: headers()
      })
    }),
    downloadRolesList: builder.mutation<PaginationProps<RolesListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_ROLES_LIST_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        responseHandler: download,
        body: body,
        formData: true
      })
    })
  })
})

export const {
  useGetRolesListMutation,
  useGetRolesListDetailQuery,
  useCreateRolesListMutation,
  useUpdateRolesListMutation,
  useGetRolesListDropdownMutation,
  useGetRolesListTypeDropdownMutation,
  useDownloadRolesListMutation
} = RolesAPI
