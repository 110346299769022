import { useEffect, useState } from 'react'
import { gt, isEmpty } from 'lodash'
import { TableRow, TableCell, Autocomplete, CircularProgress, TextField } from '@mui/material'
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import CONSTANT from 'modules/constant'
import type { PartialEVChargeProps, PartialEvHistoryPartnerTransactionProps } from 'modules/partial'
import {
  useDownloadEvHistoryPartnerListMutation,
  useGetEvHistoryPartnerListMutation
} from 'store/evHistoryPartner'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { ErrorProps } from 'modules/types'
import { useGetChargePointDropdownListMutation } from 'store/evChargePoint'
import { useGetChargeBoxGroupDropdownListMutation } from 'store/evChargeBoxGroup'
import { useGetChargeBoxDropdownListMutation } from 'store/evChargeBox'
import {
  useGetSocketTypeDropdownListMutation,
  useGetStatusChargingRequestDropdownListMutation
} from 'store/dropdown'
import { useGetAccountCheckUserListMutation } from 'store/assignment'
import { useGetEvModelDropdownListMutation } from 'store/evModel'
import GlobalStyle from 'modules/styles'
import Loading from 'components/loading/loading'
import useDebounce from 'modules/useDebounce'

const EVTransactionHistory = () => {
  const [getEvHistoryPartner, historyPartner] = useGetEvHistoryPartnerListMutation()
  const [downloadEvHistoryPartner, download] = useDownloadEvHistoryPartnerListMutation()

  const [getChargePointDropdownList, chargePointDropdown] = useGetChargePointDropdownListMutation()
  const [getChargeBoxGroupDropdownList, chargeBoxGroupDropdown] =
    useGetChargeBoxGroupDropdownListMutation()
  const [getChargeBoxDropdownList, chargeBoxDropdown] = useGetChargeBoxDropdownListMutation()
  const [getSocketTypeDropdownList, socketTypeDropdown] = useGetSocketTypeDropdownListMutation()
  const [getStatusChargingRequestDropdownList, statusChargingRequestDropdown] =
    useGetStatusChargingRequestDropdownListMutation()
  const [getAccountList, accountList] = useGetAccountCheckUserListMutation()
  const [getEvModelDropdownList, evModelDropdown] = useGetEvModelDropdownListMutation()

  const [nameCheck, setNameCheck] = useState<string>('')
  const [focus, setFocus] = useState<boolean>(false)

  const [dialogFilter, setDialogFilter] = useState<boolean>(false)

  const [filter, setFilter] = useState<PartialEvHistoryPartnerTransactionProps>({
    chargePointId: 0,
    chargeBoxGroupId: 0,
    chargeBoxId: 0,
    connectorType: '',
    currentType: '',
    capacity: 0,
    kwhLimit: 0,
    minStartTime: '',
    maxStartTime: '',
    minStopTime: '',
    maxStopTime: '',
    minTimeElapsed: '',
    maxTimeElapsed: '',
    minKwhElapsed: 0,
    maxKwhElapsed: 0,
    reason: '',
    userId: '',
    vehicleId: 0,
    requestedStatus: 0
  })

  const [payload, setPayload] = useState<
    PartialEVChargeProps & PartialEvHistoryPartnerTransactionProps
  >({
    start: 0,
    length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    search: '',
    filter: '',
    sorting: '',
    chargePointId: 0,
    chargeBoxGroupId: 0,
    chargeBoxId: 0,
    connectorType: '',
    currentType: '',
    capacity: 0,
    kwhLimit: 0,
    minStartTime: '',
    maxStartTime: '',
    minStopTime: '',
    maxStopTime: '',
    minTimeElapsed: '',
    maxTimeElapsed: '',
    minKwhElapsed: 0,
    maxKwhElapsed: 0,
    reason: '',
    userId: '',
    vehicleId: 0,
    requestedStatus: 0
  })

  const onResync = () => {
    const formData = new FormData()

    formData.append('start', payload.start.toString())
    formData.append('length', payload.length.toString())
    formData.append('sorting', payload.sorting)

    if (!isEmpty(payload.search)) formData.append('search', payload.search)
    if (!isEmpty(payload.filter)) formData.append('filter', payload.filter)
    if (gt(payload.chargePointId, 0))
      formData.append('chargePointId', String(payload.chargePointId))
    if (gt(payload.chargeBoxGroupId, 0))
      formData.append('chargeBoxGroupId', String(payload.chargeBoxGroupId))
    if (gt(payload.chargeBoxId, 0)) formData.append('chargeBoxId', String(payload.chargeBoxId))
    if (!isEmpty(payload.connectorType)) formData.append('connectorType', payload.connectorType)
    if (!isEmpty(payload.currentType)) formData.append('currentType', payload.currentType)
    if (gt(payload.capacity, 0)) formData.append('capacity', String(payload.capacity))
    if (gt(payload.kwhLimit, 0)) formData.append('kwhLimit', String(payload.kwhLimit))
    if (!isEmpty(payload.minStartTime)) formData.append('minStartTime', payload.minStartTime)
    if (!isEmpty(payload.maxStartTime)) formData.append('maxStartTime', payload.maxStartTime)
    if (!isEmpty(payload.minStopTime)) formData.append('minStopTime', payload.minStopTime)
    if (!isEmpty(payload.maxStopTime)) formData.append('maxStopTime', payload.maxStopTime)
    if (!isEmpty(payload.minTimeElapsed)) formData.append('minTimeElapsed', payload.minTimeElapsed)
    if (!isEmpty(payload.maxTimeElapsed)) formData.append('maxTimeElapsed', payload.minTimeElapsed)
    if (gt(payload.minKwhElapsed, 0))
      formData.append('minKwhElapsed', String(payload.minKwhElapsed))
    if (gt(payload.maxKwhElapsed, 0))
      formData.append('maxKwhElapsed', String(payload.maxKwhElapsed))
    if (!isEmpty(payload.reason)) formData.append('reason', payload.reason)
    if (!isEmpty(payload.userId)) formData.append('userId', payload.userId)
    if (gt(payload.vehicleId, 0)) formData.append('vehicleId', String(payload.vehicleId))
    if (gt(payload.requestedStatus, 0))
      formData.append('requestedStatus', String(payload.requestedStatus))

    getEvHistoryPartner(formData)
  }

  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('start', String(0))
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())
    formData.append('sorting', payload.sorting)

    if (!isEmpty(payload.search)) formData.append('search', payload.search)
    if (!isEmpty(payload.filter)) formData.append('filter', payload.filter)
    if (gt(payload.chargePointId, 0))
      formData.append('chargePointId', String(payload.chargePointId))
    if (gt(payload.chargeBoxGroupId, 0))
      formData.append('chargeBoxGroupId', String(payload.chargeBoxGroupId))
    if (gt(payload.chargeBoxId, 0)) formData.append('chargeBoxId', String(payload.chargeBoxId))
    if (!isEmpty(payload.connectorType)) formData.append('connectorType', payload.connectorType)
    if (!isEmpty(payload.currentType)) formData.append('currentType', payload.currentType)
    if (gt(payload.capacity, 0)) formData.append('capacity', String(payload.capacity))
    if (gt(payload.kwhLimit, 0)) formData.append('kwhLimit', String(payload.kwhLimit))
    if (!isEmpty(payload.minStartTime)) formData.append('minStartTime', payload.minStartTime)
    if (!isEmpty(payload.maxStartTime)) formData.append('maxStartTime', payload.maxStartTime)
    if (!isEmpty(payload.minStopTime)) formData.append('minStopTime', payload.minStopTime)
    if (!isEmpty(payload.maxStopTime)) formData.append('maxStopTime', payload.maxStopTime)
    if (!isEmpty(payload.minTimeElapsed)) formData.append('minTimeElapsed', payload.minTimeElapsed)
    if (!isEmpty(payload.maxTimeElapsed)) formData.append('maxTimeElapsed', payload.minTimeElapsed)
    if (gt(payload.minKwhElapsed, 0))
      formData.append('minKwhElapsed', String(payload.minKwhElapsed))
    if (gt(payload.maxKwhElapsed, 0))
      formData.append('maxKwhElapsed', String(payload.maxKwhElapsed))
    if (!isEmpty(payload.reason)) formData.append('reason', payload.reason)
    if (!isEmpty(payload.userId)) formData.append('userId', payload.userId)
    if (gt(payload.vehicleId, 0)) formData.append('vehicleId', String(payload.vehicleId))
    if (gt(payload.requestedStatus, 0))
      formData.append('requestedStatus', String(payload.requestedStatus))
    if (type && !isEmpty(type)) formData.append('Content-Type', type)

    downloadEvHistoryPartner(formData)
  }

  const onSearch = (value: string) => setPayload({ ...payload, start: 0, search: value })

  const onChangePage = (value: number) => setPayload({ ...payload, start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, length: value })

  const onFilter = (state: boolean) => setDialogFilter(state)

  const tableHeadTitles = [
    'Charge Box Group ID',
    'Charge Box',
    'State',
    'Start Time',
    'Stop Time',
    'Elapsed Time',
    'kWh Speed',
    'EV Car'
  ]

  const totalPage = historyPartner.data && GLOBAL.tableTotalPage(historyPartner.data.recordsTotal)

  const numbers = GLOBAL.tableNumber(payload.start)

  const debounce = useDebounce(nameCheck)

  useEffect(() => {
    onResync()
  }, [payload])

  useEffect(() => {
    if (focus && dialogFilter) getAccountList({ nameCheck })
  }, [debounce])

  return (
    <>
      <Content
        title='Transaction History'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={historyPartner.data && historyPartner.data.recordsTotal}
        page={payload.start + 1}
        isLoading={historyPartner.isLoading}
        useDownloadDropdown
        useAction={false}
      >
        {historyPartner.isSuccess &&
          historyPartner.data &&
          historyPartner.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>{table.chargeBoxGroupCode || '-'}</TableCell>
              <TableCell>{table.chargeBoxCode || '-'}</TableCell>
              <TableCell>{table.status || '-'}</TableCell>
              <TableCell {...GlobalStyle.EllipsisTable}>
                {table.chargingStartTime
                  ? dayjs(table.chargingStartTime).format('DD-MM-YYYY HH:mm:ss')
                  : '-'}
              </TableCell>
              <TableCell {...GlobalStyle.EllipsisTable}>
                {table.chargingEndTime
                  ? dayjs(table.chargingEndTime).format('DD-MM-YYYY HH:mm:ss')
                  : '-'}
              </TableCell>
              <TableCell>{table.durationTimeString || '-'}</TableCell>
              <TableCell>{table.speed ? table.speed + table.speedUnit : '-'}</TableCell>
              <TableCell {...GlobalStyle.EllipsisTable}>{table.carName || '-'}</TableCell>
            </TableRow>
          ))}
      </Content>

      {historyPartner.isLoading && <Loading />}

      <Filter
        open={dialogFilter}
        title='Filter'
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
        onReset={() => {
          setFilter({
            chargePointId: 0,
            chargeBoxGroupId: 0,
            chargeBoxId: 0,
            connectorType: '',
            currentType: '',
            capacity: 0,
            kwhLimit: 0,
            minStartTime: '',
            maxStartTime: '',
            minStopTime: '',
            maxStopTime: '',
            minTimeElapsed: '',
            maxTimeElapsed: '',
            minKwhElapsed: 0,
            maxKwhElapsed: 0,
            reason: '',
            userId: '',
            vehicleId: 0,
            requestedStatus: 0
          })
          setPayload({
            ...payload,
            chargePointId: 0,
            chargeBoxGroupId: 0,
            chargeBoxId: 0,
            connectorType: '',
            currentType: '',
            capacity: 0,
            kwhLimit: 0,
            minStartTime: '',
            maxStartTime: '',
            minStopTime: '',
            maxStopTime: '',
            minTimeElapsed: '',
            maxTimeElapsed: '',
            minKwhElapsed: 0,
            maxKwhElapsed: 0,
            reason: '',
            userId: '',
            vehicleId: 0,
            requestedStatus: 0,
            start: 0
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({
            ...payload,
            ...filter,
            start: 0
          })
          onFilter(false)
        }}
      >
        <Autocomplete
          options={(chargePointDropdown && chargePointDropdown.data) || []}
          getOptionLabel={(option) => option.text}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.value == value.value : false
          }
          onOpen={() => getChargePointDropdownList(CONSTANT.DEFAULT_DROPDOWN_PAYLOAD)}
          onChange={(_, chargePoint) =>
            chargePoint &&
            setFilter({
              ...filter,
              chargePointId: chargePoint.value
            })
          }
          value={
            (chargePointDropdown &&
              chargePointDropdown.data &&
              chargePointDropdown.data.find((e) => e.value == filter.chargePointId)) ||
            null
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.value}>
              {item.text}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='chargePointId'
              label='Choose Charge Point'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {chargePointDropdown.isLoading && (
                      <CircularProgress color='inherit' size={20} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <Autocomplete
          options={(chargeBoxGroupDropdown && chargeBoxGroupDropdown.data) || []}
          getOptionLabel={(option) => option.text}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.value == value.value : false
          }
          onOpen={() =>
            getChargeBoxGroupDropdownList({
              ...CONSTANT.DEFAULT_DROPDOWN_PAYLOAD,
              id: filter.chargePointId
            })
          }
          onChange={(_, chargeBoxGroup) =>
            chargeBoxGroup &&
            setFilter({
              ...filter,
              chargeBoxGroupId: chargeBoxGroup.value
            })
          }
          value={
            (chargeBoxGroupDropdown &&
              chargeBoxGroupDropdown.data &&
              chargeBoxGroupDropdown.data.find((e) => e.value == filter.chargeBoxGroupId)) ||
            null
          }
          disabled={filter.chargePointId <= 0}
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.value}>
              {item.text}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='chargeBoxGroupId'
              label='EV Charge Box Group ID'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {chargeBoxGroupDropdown.isLoading && (
                      <CircularProgress color='inherit' size={20} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <Autocomplete
          options={(chargeBoxDropdown && chargeBoxDropdown.data) || []}
          getOptionLabel={(option) => option.text}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.value == value.value : false
          }
          onOpen={() =>
            getChargeBoxDropdownList({
              ...CONSTANT.DEFAULT_DROPDOWN_PAYLOAD,
              id: filter.chargeBoxId
            })
          }
          onChange={(_, chargeBox) =>
            chargeBox &&
            setFilter({
              ...filter,
              chargeBoxId: chargeBox.value
            })
          }
          value={
            (chargeBoxDropdown &&
              chargeBoxDropdown.data &&
              chargeBoxDropdown.data.find((e) => e.value == filter.chargeBoxId)) ||
            null
          }
          disabled={filter.chargeBoxGroupId <= 0}
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.value}>
              {item.text}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='chargeBoxId'
              label='EV Charge Box ID'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {chargeBoxDropdown.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <Autocomplete
          options={(socketTypeDropdown && socketTypeDropdown.data) || []}
          getOptionLabel={(option) => option.text}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.value == value.value : false
          }
          onOpen={() => getSocketTypeDropdownList(CONSTANT.DEFAULT_DROPDOWN_PAYLOAD)}
          onChange={(_, socketType) =>
            socketType &&
            setFilter({
              ...filter,
              connectorType: String(socketType.value)
            })
          }
          value={
            (socketTypeDropdown &&
              socketTypeDropdown.data &&
              socketTypeDropdown.data.find((e) => String(e.value) == filter.connectorType)) ||
            null
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.value}>
              {item.text}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='connectorType'
              label='Connector Type'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {socketTypeDropdown.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <TextField
          id='currentType'
          variant='outlined'
          label='Current Type'
          value={filter.currentType}
          onChange={(event) => setFilter({ ...filter, currentType: event.target.value })}
          fullWidth
        />
        <TextField
          id='capacity'
          variant='outlined'
          label='Capacity'
          value={filter.capacity}
          type='number'
          onChange={(event) => setFilter({ ...filter, capacity: Number(event.target.value) })}
          fullWidth
        />
        <TextField
          id='kwhLimit'
          variant='outlined'
          label='kWh Limit'
          value={filter.kwhLimit}
          type='number'
          onChange={(event) => setFilter({ ...filter, kwhLimit: Number(event.target.value) })}
          fullWidth
        />
        <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
          <DatePicker
            value={filter.minStartTime ? dayjs(filter.minStartTime) : null}
            onChange={(date: Dayjs | null) =>
              date && setFilter({ ...filter, minStartTime: date.format('YYYY-MM-DD') })
            }
            label='Min Start Time'
            format='DD-MM-YYYY'
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
          <DatePicker
            value={filter.maxStartTime ? dayjs(filter.maxStartTime) : null}
            onChange={(date: Dayjs | null) =>
              date && setFilter({ ...filter, maxStartTime: date.format('YYYY-MM-DD') })
            }
            label='Max Start Time'
            format='DD-MM-YYYY'
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
          <DatePicker
            value={filter.minStopTime ? dayjs(filter.minStopTime) : null}
            onChange={(date: Dayjs | null) =>
              date && setFilter({ ...filter, minStopTime: date.format('YYYY-MM-DD') })
            }
            label='Min Stop Time'
            format='DD-MM-YYYY'
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
          <DatePicker
            value={filter.maxStopTime ? dayjs(filter.maxStopTime) : null}
            onChange={(date: Dayjs | null) =>
              date && setFilter({ ...filter, maxStopTime: date.format('YYYY-MM-DD') })
            }
            label='Max Stop Time'
            format='DD-MM-YYYY'
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            ampm={false}
            value={filter.minTimeElapsed ? dayjs(filter.minTimeElapsed) : null}
            onChange={(time) =>
              time && setFilter({ ...filter, minTimeElapsed: time.format('HH:mm') })
            }
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            ampm={false}
            value={filter.maxTimeElapsed ? dayjs(filter.maxTimeElapsed) : null}
            onChange={(time) =>
              time && setFilter({ ...filter, maxTimeElapsed: time.format('HH:mm') })
            }
          />
        </LocalizationProvider>
        <TextField
          id='minKwhElapsed'
          variant='outlined'
          label='Min kWh Elapsed'
          value={filter.minKwhElapsed}
          type='number'
          onChange={(event) => setFilter({ ...filter, minKwhElapsed: Number(event.target.value) })}
          fullWidth
        />
        <TextField
          id='maxKwhElapsed'
          variant='outlined'
          label='Max kWh Elapsed'
          value={filter.maxKwhElapsed}
          type='number'
          onChange={(event) => setFilter({ ...filter, maxKwhElapsed: Number(event.target.value) })}
          fullWidth
        />
        <TextField
          id='reason'
          variant='outlined'
          label='Reason'
          value={filter.reason}
          onChange={(event) => setFilter({ ...filter, reason: event.target.value })}
          fullWidth
        />
        <Autocomplete
          options={accountList.data || []}
          getOptionLabel={(list) => list.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.userId == value.userId : false
          }
          onChange={(_, userId) => userId && setFilter({ ...filter, userId: userId.userId })}
          onInputChange={(_, value) => setNameCheck(value)}
          onFocus={() => setFocus(true)}
          value={
            (accountList &&
              accountList.data &&
              accountList.data.find((e) => e.userId == filter.userId)) ||
            null
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.userId}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='userId'
              label='User ID'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {false && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <Autocomplete
          options={(evModelDropdown && evModelDropdown.data) || []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.name == value.name : false
          }
          onOpen={() => getEvModelDropdownList()}
          onChange={(_, vehicleId) =>
            vehicleId &&
            setFilter({
              ...filter,
              vehicleId: vehicleId.id
            })
          }
          value={
            (evModelDropdown &&
              evModelDropdown.data &&
              evModelDropdown.data.find((e) => e.id == filter.vehicleId)) ||
            null
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.id}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='vehicleId'
              label='EV Car'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {evModelDropdown.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <Autocomplete
          options={(statusChargingRequestDropdown && statusChargingRequestDropdown.data) || []}
          getOptionLabel={(option) => option.text}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.value == value.value : false
          }
          onOpen={() => getStatusChargingRequestDropdownList(CONSTANT.DEFAULT_DROPDOWN_PAYLOAD)}
          onChange={(_, requestedStatus) =>
            requestedStatus &&
            setFilter({
              ...filter,
              requestedStatus: requestedStatus.value
            })
          }
          value={
            (statusChargingRequestDropdown &&
              statusChargingRequestDropdown.data &&
              statusChargingRequestDropdown.data.find((e) => e.value == filter.requestedStatus)) ||
            null
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.value}>
              {item.text}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='requestedStatus'
              label='Requested Status'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {statusChargingRequestDropdown.isLoading && (
                      <CircularProgress color='inherit' size={20} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
      </Filter>

      <Notification
        open={
          !historyPartner.isLoading && !historyPartner.isUninitialized && !historyPartner.isSuccess
        }
        onClose={() => historyPartner.reset()}
        isError={Boolean(historyPartner.error) && historyPartner.isError}
        message={GLOBAL.returnExceptionMessage(
          historyPartner.isError,
          historyPartner.error as ErrorProps
        )}
      />

      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />
    </>
  )
}

export default EVTransactionHistory
