import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Autocomplete,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  TextField
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import type { Dayjs } from 'dayjs'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import CONSTANT from 'modules/constant'
import Icon from 'components/icon'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { ErrorProps } from 'modules/types'
import GlobalStyle from 'modules/styles'
import type { PartialTokenCategoryProps } from 'modules/partial'
import {
  useDownloadGiftRedemptionMutation,
  useGetGiftRedemptionDropdownMutation,
  useGetListVoucherGiftRedemptionMutation,
  useGetRedemptionRedeemDropdownMutation,
  useGetVoucherRedemptionDropdownMutation
} from 'store/voucherGift'
import Detail from './detail/detail'

const GiftRedemption = () => {
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)

  const [menu, setMenu] = useState<null | number>(null)

  const [minDate, setMinDate] = useState<Dayjs | null>(null)
  const [maxDate, setMaxDate] = useState<Dayjs | null>(null)

  const [getListVoucherGiftRedemption, giftRedemption] = useGetListVoucherGiftRedemptionMutation()
  const [downloadGiftRedemption, download] = useDownloadGiftRedemptionMutation()

  const [getGiftRedemptionDropdown, dropdownGift] = useGetGiftRedemptionDropdownMutation()
  const [getRedemptionRedeemDropdown, dropdownRedeem] = useGetRedemptionRedeemDropdownMutation()
  const [getVoucherRedemptionDropdown, dropdownVoucher] = useGetVoucherRedemptionDropdownMutation()

  const [payload, setPayload] = useState<PartialTokenCategoryProps>({
    start: 0,
    length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    field: CONSTANT.DEFAULT_GIFT_REDEMPTION_FIELD.join(','),
    search: { value: '' },
    status: '',
    monetaryValue: '',
    companyId: ''
  })

  const [filter, setFilter] = useState({
    voucherId: '',
    voucherGiftId: '',
    redeemedBy: '',
    MinCreated: '',
    MaxCreated: ''
  })

  const onSearch = (value: string) => setPayload({ ...payload, start: 0, search: { value } })
  const onResync = () => {
    const formData = new FormData()
    formData.append('start', payload.start.toString())
    formData.append('length', payload.length.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(filter.MinCreated)) formData.append('min-created-date', filter.MinCreated)
    if (!isEmpty(filter.MaxCreated)) formData.append('max-created-date', filter.MaxCreated)
    if (!isEmpty(filter.voucherId)) formData.append('voucher-id', filter.voucherId)
    if (!isEmpty(filter.voucherGiftId)) formData.append('vouchergiftid', filter.voucherGiftId)
    if (!isEmpty(filter.redeemedBy)) formData.append('redeemedby', filter.redeemedBy)

    getListVoucherGiftRedemption(formData)
  }
  const onFilter = (state: boolean) => setDialogFilter(state)
  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('start', String(0))
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(filter.MinCreated)) formData.append('min-created-date', filter.MinCreated)
    if (!isEmpty(filter.MaxCreated)) formData.append('max-created-date', filter.MaxCreated)
    if (!isEmpty(filter.voucherId)) formData.append('voucher-id', filter.voucherId)
    if (!isEmpty(filter.voucherGiftId)) formData.append('vouchergiftid', filter.voucherGiftId)
    if (!isEmpty(filter.redeemedBy)) formData.append('redeemedby', filter.redeemedBy)

    if (type && !isEmpty(type)) formData.append('Download-Type', type)

    downloadGiftRedemption(formData)
  }

  const onChangePage = (value: number) => setPayload({ ...payload, start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, length: value })

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const tableHeadTitles = [
    'Campaign Name',
    'Voucher Name',
    'Redeemed By',
    'Partner',
    'Redemption Date',
    'Created Date'
  ]

  const openAnchor = Boolean(anchor)

  const totalPage = giftRedemption.data && GLOBAL.tableTotalPage(giftRedemption.data.recordsTotal)

  const numbers = GLOBAL.tableNumber(payload.start)

  useEffect(() => {
    onResync()
  }, [payload])

  const handleChangeMinCreated = (date: Dayjs | null) => {
    setMinDate(date)
    const newDate = (!isEmpty(date) && date?.format('YYYY-MM-DD')) || ''
    setFilter({ ...filter, MinCreated: newDate, MaxCreated: '' })
    setMaxDate(null)
  }

  const handleChangeMaxCreated = (date: Dayjs | null) => {
    setMaxDate(date)
    const newDate = (!isEmpty(date) && date?.format('YYYY-MM-DD')) || ''
    setFilter({ ...filter, MaxCreated: newDate })
  }

  return (
    <>
      <Content
        title='Gift Voucher Redemption'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={giftRedemption.data && giftRedemption.data.recordsTotal}
        page={payload.start + 1}
        isLoading={giftRedemption.isLoading}
        useDownloadDropdown
      >
        {giftRedemption.isSuccess &&
          giftRedemption.data &&
          giftRedemption.data.data.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${item.id}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${item.id}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${item.id}`}
                  aria-labelledby={`button-${index}-${item.id}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View Detail
                  </MenuItem>
                </Menu>
                {drawerDetail && menu == index && (
                  <Detail
                    id={item.id || ''}
                    open={drawerDetail && menu == index}
                    onClose={() => setDrawerDetail(false)}
                  />
                )}
              </TableCell>
              <TableCell>{item.voucherGiftName || '-'}</TableCell>
              <TableCell>{item.voucherName || '-'}</TableCell>
              <TableCell>{item.redeemedBy || '-'}</TableCell>
              <TableCell>{item.partnerName || '-'}</TableCell>
              <TableCell>
                {item.sendDate ? dayjs(item.sendDate).format('DD MMMM YYYY HH:mm:ss') : '-'}
              </TableCell>
              <TableCell>
                {item.sendDate ? dayjs(item.createdAt).format('DD MMMM YYYY HH:mm:ss') : '-'}
              </TableCell>
            </TableRow>
          ))}
      </Content>
      {giftRedemption.isLoading && <Loading />}

      <Filter
        open={dialogFilter}
        title='Filter Gift Voucher Redemption'
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        onReset={() => {
          setFilter({
            voucherId: '',
            voucherGiftId: '',
            redeemedBy: '',
            MinCreated: '',
            MaxCreated: ''
          })
          setMinDate(null)
          setMaxDate(null)
          setPayload({ ...payload, start: 0, ...filter })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({ ...payload, start: 0, ...filter })
          onFilter(false)
        }}
      >
        <Grid container spacing={2} columns={16}>
          <Grid xs={8} marginTop={1}>
            <Grid xs={16} margin={1}>
              <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                <DatePicker
                  value={minDate}
                  onChange={handleChangeMinCreated}
                  label='Min Created Date'
                  format='DD-MM-YYYY'
                  sx={{ width: '100%' }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid xs={16} margin={1}>
              <Autocomplete
                options={dropdownVoucher.data || []}
                getOptionLabel={(list) => list.name}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.id == value.id : false
                }
                onOpen={() => getVoucherRedemptionDropdown()}
                onChange={(_, id) => {
                  if (id) {
                    setFilter({
                      ...filter,
                      voucherId: id && id?.id.toString()
                    })
                  }
                }}
                ListboxProps={GlobalStyle.ListBox}
                value={
                  dropdownVoucher &&
                  dropdownVoucher.data &&
                  dropdownVoucher.data.find((e) => e.id == Number(filter.voucherId))
                }
                renderOption={(props, item) => (
                  <li {...props} key={item.id}>
                    {item.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='voucherId'
                    label='Voucher'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {dropdownVoucher.isLoading && (
                            <CircularProgress color='inherit' size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
              />
            </Grid>
            <Grid xs={16} margin={1}>
              <Autocomplete
                options={dropdownRedeem.data || []}
                getOptionLabel={(list) => list.name}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.userid == value.userid : false
                }
                onOpen={() => getRedemptionRedeemDropdown()}
                onChange={(_, id) => {
                  if (id) {
                    setFilter({
                      ...filter,
                      redeemedBy: id && id?.userid
                    })
                  }
                }}
                ListboxProps={GlobalStyle.ListBox}
                value={
                  dropdownRedeem &&
                  dropdownRedeem.data &&
                  dropdownRedeem.data.find((e) => e.userid == filter.redeemedBy)
                }
                renderOption={(props, item) => (
                  <li {...props} key={item.userid}>
                    {item.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='redeemedBy'
                    label='Redeemed By'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {dropdownRedeem.isLoading && (
                            <CircularProgress color='inherit' size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          {/* ============ Right Column ============== */}
          <Grid xs={8} marginTop={1}>
            <Grid xs={16} margin={1}>
              <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                <DatePicker
                  value={maxDate}
                  onChange={handleChangeMaxCreated}
                  label='Max Created Date'
                  format='DD-MM-YYYY'
                  sx={{ width: '100%' }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid xs={16} margin={1}>
              <Autocomplete
                options={dropdownGift.data || []}
                getOptionLabel={(list) => list.name}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.id == value.id : false
                }
                onOpen={() => getGiftRedemptionDropdown()}
                onChange={(_, id) => {
                  if (id) {
                    setFilter({
                      ...filter,
                      voucherGiftId: id && id?.id.toString()
                    })
                  }
                }}
                ListboxProps={GlobalStyle.ListBox}
                value={
                  dropdownGift &&
                  dropdownGift.data &&
                  dropdownGift.data.find((e) => e.id == Number(filter.voucherGiftId))
                }
                renderOption={(props, item) => (
                  <li {...props} key={item.id}>
                    {item.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='voucherGiftId'
                    label='Gift Voucher ID'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {dropdownGift.isLoading && <CircularProgress color='inherit' size={20} />}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Filter>

      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />
    </>
  )
}

export default GiftRedemption
