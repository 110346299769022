import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import * as yup from 'yup'
import {
  Autocomplete,
  Box,
  CircularProgress,
  Container,
  Grid,
  TextField,
  Typography
} from '@mui/material'
import ReactQuill from 'react-quill'
import { decode, encode } from 'html-entities'
import Dialog from 'components/dialog/dialog'
import type { PartialVoucherCreateDataProps, PartialVoucherCreateProps } from 'modules/partial'
import type { CallbackHandler, DetailStateProps, ErrorProps } from 'modules/types'
import FORMIK from 'modules/formik'
import {
  useGetVoucherCategoryDropdownMutation,
  useGetVoucherSubCategoryDropdownMutation
} from 'store/voucherCategory'
import GlobalStyle from 'modules/styles'
import { useGetTokenPartnerDropdownMutation } from 'store/token'
import { useGetTokenCategoryDropdownMutation } from 'store/tokenRequest'
import GLOBAL from 'modules/global'
import { useUpdateListVoucherMutation, useGetListVoucherDetailQuery } from 'store/voucher'
import Notification from 'components/notification'
import Loading from 'components/loading/loading'
import BackdropImage from 'components/backdropImage/backdropImage'
import DialogStyle from './style'

const Edit = ({ open, id, onClose }: DetailStateProps & { id: string | number }) => {
  if (!open) return <></>

  const { data, isFetching, isLoading } = useGetListVoucherDetailQuery(id)
  const loading = !data && (isLoading || isFetching)

  const [getVoucherCategoryDropdown, dropdownCategory] = useGetVoucherCategoryDropdownMutation()
  const [getVoucherSubCategoryDropdown, dropdownSubCategory] =
    useGetVoucherSubCategoryDropdownMutation()
  const [getTokenPartnerDropdown, partnerDropdown] = useGetTokenPartnerDropdownMutation()
  const [getTokenCategoryDropdown, tokenCategoryDropdown] = useGetTokenCategoryDropdownMutation()
  const [updateVoucher, update] = useUpdateListVoucherMutation()

  const [previewImage, setPreviewImage] = useState<string | ArrayBuffer | null>(null)
  const [previewBanner, setPreviewBanner] = useState<string | ArrayBuffer | null>(null)
  const [previewBackdrop, setPreviewBackdrop] = useState<boolean>(false)
  const [bannerBackdrop, setBannerBackdrop] = useState<boolean>(false)

  const scheme = yup.object<PartialVoucherCreateProps>({
    image: yup.mixed().required('Voucher Image is required'),
    banner: yup.mixed().required('Voucher Image Banner is required'),
    data: yup.object({
      nameEn: yup.string().required('Name in English is required'),
      nameId: yup.string().required('Name in Indonesia is required'),
      voucherType: yup.string().required('Voucher Type is required'),
      bookingType: yup.string().when('voucherType', {
        is: (val: string) => val === 'Booking',
        then: (schema) => schema.required('Booking Type is required')
      }),
      voucherCategoryId: yup.number().required('Voucher Category ID is required'),
      voucherSubCategoryId: yup.number().required('Voucher Sub Category ID is required'),
      voucherTokenCategoryId: yup.number().required('Voucher Token Category ID is required'),
      partnerId: yup.number().required('Partner ID is required'),
      applicableAreaMode: yup.string().required('Applicable Area Mode is required'),
      termsAndConditionsEn: yup.string().required('Terms and Condition in English is required'),
      termsAndConditionsId: yup.string().required('Terms and Condition in Indonesia is required'),
      informationEn: yup.string().required('Information in English is required'),
      informationId: yup.string().required('Information in Indonesia is required'),
      generateType: yup.string(),
      description: yup.string(),
      payloadGenerateRequest: yup
        .object({
          requestName: yup.string(),
          quantity: yup.string(),
          validFrom: yup.string(),
          validUntil: yup.string()
        })
        .notRequired()
    }),
    importToken: yup.mixed().notRequired()
  })

  const initialValues: PartialVoucherCreateProps = {
    image: {} as File,
    banner: {} as File,
    importToken: {} as File,
    data: {
      id: Number(id),
      nameEn: (data && data.nameEn) || '',
      nameId: (data && data.nameId) || '',
      voucherType: (data && data.voucherType) || '-',
      bookingType: (data && data.bookingType) || '-',
      voucherCategoryId: (data && data.voucherCategoryId) || null,
      voucherSubCategoryId: (data && data.voucherSubCategoryId) || null,
      voucherTokenCategoryId: (data && data.tokenCategoryId) || null,
      partnerId: (data && data.partnerId) || null,
      applicableAreaMode: (data && data.applicableAreaMode) || '',
      termsAndConditionsEn: (data && decode(data.termsAndConditionsEn)) || '',
      termsAndConditionsId: (data && decode(data.termsAndConditionsId)) || '',
      informationEn: (data && decode(data.descriptionEn)) || '',
      informationId: (data && decode(data.descriptionId)) || '',
      generateType: (data && data.generateType) || 'None',
      visibility: data && data.visibility,
      description: ''
    }
  }

  const formik = FORMIK.useFormFormik<PartialVoucherCreateProps>(
    scheme,
    initialValues,
    (values) => {
      const formData = new FormData()

      const data = {
        ...values.data,
        generateType: values.data.generateType === 'None' ? null : values.data.generateType,
        termsAndConditionsEn: encode(values.data.termsAndConditionsEn),
        termsAndConditionsId: encode(values.data.termsAndConditionsId),
        informationEn: encode(values.data.informationEn),
        informationId: encode(values.data.informationId)
      }

      if (values.image) formData.append('image', values.image)
      if (values.banner) formData.append('banner', values.banner)

      formData.append('data', JSON.stringify(data))

      updateVoucher(formData)
    }
  )

  const fileOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: string,
    callback: CallbackHandler<string | ArrayBuffer | null>
  ) => {
    const files = e && e.target && e.target.files
    const notEmpty = files && files.length > 0 && !isEmpty(files)

    if (notEmpty) {
      const reader = new FileReader()

      reader.onload = () => callback(reader.result)
      reader.readAsDataURL(files[0])

      formik.setFieldValue(field, files[0])
      return
    }

    formik.setFieldValue(field, {} as File)
    return callback(null)
  }

  const error = formik.errors && formik.errors.data
  const touched = formik.touched && (formik.touched as PartialVoucherCreateDataProps)

  const disabled = !formik.isValid || isEmpty(previewImage) || isEmpty(previewBanner)

  useEffect(() => {
    if (data) {
      if (data.voucherCategoryId) getVoucherCategoryDropdown()
      if (data.voucherSubCategoryId) getVoucherSubCategoryDropdown({ id: data.voucherCategoryId })
      if (data.partnerId) getTokenPartnerDropdown()
      if (data.tokenCategoryId) getTokenCategoryDropdown()
      if (data.image) setPreviewImage(data.image)
      if (data.banner) setPreviewBanner(data.banner)
    }
  }, [data])

  return (
    <>
      <Dialog
        title='Edit Voucher'
        open={open}
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={update.isLoading}
        isDisabled={disabled}
        maxWidth='md'
      >
        <Container {...DialogStyle.Container}>
          {loading && <Loading />}
          {!loading && (
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Box sx={{ display: 'flex', flex: 1, gap: 2, flexDirection: 'column' }}>
                <TextField
                  id='nameEn'
                  variant='outlined'
                  label='Voucher EN-US Name'
                  value={formik.values.data.nameEn}
                  onChange={(event) => formik.setFieldValue('data.nameEn', event.target.value)}
                  onBlur={(event) => formik.setFieldValue('data.nameEn', event.target.value)}
                  error={touched && Boolean(touched.nameEn) && error && Boolean(error.nameEn)}
                  helperText={touched && touched.nameEn && error && error.nameEn}
                  fullWidth
                />
                <TextField
                  id='nameId'
                  variant='outlined'
                  label='Voucher ID Name'
                  value={formik.values.data.nameId}
                  onChange={(event) => formik.setFieldValue('data.nameId', event.target.value)}
                  onBlur={(event) => formik.setFieldValue('data.nameId', event.target.value)}
                  error={touched && Boolean(touched.nameId) && error && Boolean(error.nameId)}
                  helperText={touched && touched.nameId && error && error.nameId}
                  fullWidth
                />
                <Autocomplete
                  options={(dropdownCategory && dropdownCategory.data) || []}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option && value ? option.id == value.id : false
                  }
                  onOpen={() => getVoucherCategoryDropdown()}
                  onChange={(_, voucherCategory) => {
                    dropdownSubCategory.reset()
                    formik.setFieldValue(
                      'data.voucherCategoryId',
                      String((voucherCategory && voucherCategory.id) || null)
                    )
                  }}
                  value={
                    (dropdownCategory &&
                      dropdownCategory.data &&
                      dropdownCategory.data.find(
                        (e) => e.id == formik.values.data.voucherCategoryId
                      )) ||
                    null
                  }
                  ListboxProps={GlobalStyle.ListBox}
                  renderOption={(props, item) => (
                    <li {...props} key={item.id}>
                      {item.name}
                    </li>
                  )}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name='voucherCategoryId'
                      label='Category ID'
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {dropdownCategory.isLoading && (
                              <CircularProgress color='inherit' size={20} />
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                />
                <Autocomplete
                  options={(dropdownSubCategory && dropdownSubCategory.data) || []}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option && value ? option.id == value.id : false
                  }
                  onOpen={() =>
                    formik.values.data.voucherCategoryId &&
                    getVoucherSubCategoryDropdown({ id: formik.values.data.voucherCategoryId })
                  }
                  onChange={(_, voucherCategory) =>
                    formik.setFieldValue(
                      'data.voucherSubCategoryId',
                      String((voucherCategory && voucherCategory.id) || null)
                    )
                  }
                  value={
                    (dropdownSubCategory &&
                      dropdownSubCategory.data &&
                      dropdownSubCategory.data.find(
                        (e) => e.id == formik.values.data.voucherSubCategoryId
                      )) ||
                    null
                  }
                  disabled={
                    formik.values.data.voucherCategoryId == null ||
                    formik.values.data.voucherCategoryId <= 0
                  }
                  ListboxProps={GlobalStyle.ListBox}
                  renderOption={(props, item) => (
                    <li {...props} key={item.id}>
                      {item.name}
                    </li>
                  )}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name='voucherSubCategoryId'
                      label='Voucher Sub Category ID'
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {dropdownSubCategory.isLoading && (
                              <CircularProgress color='inherit' size={20} />
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                />
                <Autocomplete
                  options={(partnerDropdown && partnerDropdown.data) || []}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option && value ? option.id == value.id : false
                  }
                  onOpen={() => getTokenPartnerDropdown()}
                  onChange={(_, partner) =>
                    formik.setFieldValue('data.partnerId', String((partner && partner.id) || null))
                  }
                  value={
                    (partnerDropdown &&
                      partnerDropdown.data &&
                      partnerDropdown.data.find((e) => e.id == formik.values.data.partnerId)) ||
                    null
                  }
                  ListboxProps={GlobalStyle.ListBox}
                  renderOption={(props, item) => (
                    <li {...props} key={item.id}>
                      {item.name}
                    </li>
                  )}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name='partnerId'
                      label='Partner'
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {partnerDropdown.isLoading && (
                              <CircularProgress color='inherit' size={20} />
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                />
                <Autocomplete
                  options={(tokenCategoryDropdown && tokenCategoryDropdown.data) || []}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option && value ? option.id == value.id : false
                  }
                  onOpen={() => getTokenCategoryDropdown()}
                  onChange={(_, tokenCategory) =>
                    formik.setFieldValue(
                      'data.voucherTokenCategoryId',
                      String((tokenCategory && tokenCategory.id) || null)
                    )
                  }
                  value={
                    (tokenCategoryDropdown &&
                      tokenCategoryDropdown.data &&
                      tokenCategoryDropdown.data.find(
                        (e) => e.id == formik.values.data.voucherTokenCategoryId
                      )) ||
                    null
                  }
                  ListboxProps={GlobalStyle.ListBox}
                  renderOption={(props, item) => (
                    <li {...props} key={item.id}>
                      {item.name}
                    </li>
                  )}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name='tokenCategoryId'
                      label='Token Category'
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {tokenCategoryDropdown.isLoading && (
                              <CircularProgress color='inherit' size={20} />
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                />
                <Grid item xs={12}>
                  <Typography {...DialogStyle.Title}>Description ID</Typography>
                  <ReactQuill
                    theme='snow'
                    value={formik.values.data.informationId}
                    onChange={(e) =>
                      formik.setFieldValue('data.informationId', GLOBAL.emptyQuill(e))
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography {...DialogStyle.Title}>Description EN-US</Typography>
                  <ReactQuill
                    theme='snow'
                    value={formik.values.data.informationEn}
                    onChange={(e) =>
                      formik.setFieldValue('data.informationEn', GLOBAL.emptyQuill(e))
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography {...DialogStyle.Title}>Terms and Condition ID</Typography>
                  <ReactQuill
                    theme='snow'
                    value={formik.values.data.termsAndConditionsId}
                    onChange={(e) =>
                      formik.setFieldValue('data.termsAndConditionsId', GLOBAL.emptyQuill(e))
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography {...DialogStyle.Title}>Terms and Condition EN-US</Typography>
                  <ReactQuill
                    theme='snow'
                    value={formik.values.data.termsAndConditionsEn}
                    onChange={(e) =>
                      formik.setFieldValue('data.termsAndConditionsEn', GLOBAL.emptyQuill(e))
                    }
                  />
                </Grid>
              </Box>
              <Box sx={{ display: 'flex', flex: 1, gap: 2, flexDirection: 'column' }}>
                <TextField
                  id='voucherType'
                  variant='outlined'
                  label='Voucher Type'
                  value={formik.values.data.voucherType}
                  inputProps={{ readOnly: true }}
                  fullWidth
                />
                {formik.values.data.voucherType == 'Booking' && (
                  <TextField
                    id='voucherType'
                    variant='outlined'
                    label='Booking Type'
                    value={formik.values.data.bookingType}
                    inputProps={{ readOnly: true }}
                    fullWidth
                  />
                )}
                {/* <FormControl fullWidth>
                  <InputLabel>Applicable Area Mode</InputLabel>
                  <Select
                    labelId='applicableAreaMode'
                    id='applicableAreaMode'
                    variant='outlined'
                    label='Applicable Area Mode'
                    value={formik.values.data.applicableAreaMode}
                    onChange={(event) =>
                      formik.setFieldValue('data.applicableAreaMode', event.target.value)
                    }
                    error={
                      touched &&
                      Boolean(touched.applicableAreaMode) &&
                      error &&
                      Boolean(error.applicableAreaMode)
                    }
                  >
                    <MenuItem value='' disabled />
                    <MenuItem value='All Branches'>All Branches</MenuItem>
                    <MenuItem value='Specific Branches'>Specific Branches</MenuItem>
                  </Select>
                  {touched && touched.applicableAreaMode && error && (
                    <FormHelperText error id='applicableAreaMode'>
                      {error.applicableAreaMode}
                    </FormHelperText>
                  )}
                </FormControl> */}
                <TextField
                  id='image'
                  label='Voucher Image *'
                  type='file'
                  placeholder='Image'
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ accept: 'image/png, image/gif, image/jpeg' }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    fileOnChange(e, 'image', (value) => setPreviewImage(value))
                  }
                  onBlur={formik.handleBlur}
                  fullWidth
                />
                {previewImage && (
                  <Grid item xs={12}>
                    <Typography {...DialogStyle.Title}>Voucher Image</Typography>
                    <Box {...DialogStyle.Image}>
                      <BackdropImage
                        open={previewBackdrop}
                        onOpen={() => setPreviewBackdrop(true)}
                        onClose={() => setPreviewBackdrop(false)}
                      >
                        <img
                          src={previewImage as string | undefined}
                          alt='Voucher Image'
                          width='100%'
                        />
                      </BackdropImage>
                    </Box>
                  </Grid>
                )}
                <TextField
                  id='banner'
                  label='Voucher Image Banner *'
                  type='file'
                  placeholder='Image Banner'
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ accept: 'image/png, image/gif, image/jpeg' }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    fileOnChange(e, 'banner', (value) => setPreviewBanner(value))
                  }
                  onBlur={formik.handleBlur}
                  fullWidth
                />
                {previewBanner && (
                  <Grid item xs={12}>
                    <Typography {...DialogStyle.Title}>Voucher Image Banner</Typography>
                    <Box {...DialogStyle.Image}>
                      <BackdropImage
                        open={bannerBackdrop}
                        onOpen={() => setBannerBackdrop(true)}
                        onClose={() => setBannerBackdrop(false)}
                      >
                        <img
                          src={previewBanner as string | undefined}
                          alt='Voucher Image Banner'
                          width='100%'
                        />
                      </BackdropImage>
                    </Box>
                  </Grid>
                )}
              </Box>
            </Box>
          )}
        </Container>
      </Dialog>
      <Notification
        open={!update.isLoading && !update.isUninitialized}
        onClose={() => (update.isError ? update.reset() : location.reload())}
        isError={Boolean(update.error) && update.isError}
        message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
      />
    </>
  )
}

export default Edit
