import CONSTANT from 'modules/constant'
import type {
  CityListProps,
  DropdownBrandCar,
  DropdownListProps,
  DropdownUsernameProps,
  MemberTypeDropDown,
  RolesListDropdownProps
} from 'modules/types'
import type { PartialEvChargerBoxDropdownProps, PartialDropdownListProps } from 'modules/partial'
import { API, headers } from './api'

const DropdownAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getCityDropdownList: builder.mutation<CityListProps[], void>({
      query: () => ({
        url: CONSTANT.URL_CITY,
        method: 'GET',
        headers: headers()
      })
    }),
    getCityDropdownListBy: builder.query<CityListProps[], void>({
      query: () => ({
        url: CONSTANT.URL_CITY,
        method: 'GET',
        headers: headers()
      })
    }),
    getSocketTypeDropdownList: builder.mutation<DropdownListProps[], PartialDropdownListProps>({
      query: () => ({
        url: CONSTANT.URL_GENERAL_SOCKET_TYPE,
        method: 'GET',
        headers: headers()
      })
    }),
    getSocketTypeDropdownListBy: builder.query<DropdownListProps[], PartialDropdownListProps>({
      query: () => ({
        url: CONSTANT.URL_GENERAL_SOCKET_TYPE,
        method: 'GET',
        headers: headers()
      })
    }),
    getStatusChargingDropdownList: builder.mutation<DropdownListProps[], PartialDropdownListProps>({
      query: () => ({
        url: CONSTANT.URL_GENERAL_STATUS_CHARGING,
        method: 'GET',
        headers: headers()
      })
    }),
    getStatusChargingDropdownListBy: builder.query<DropdownListProps[], PartialDropdownListProps>({
      query: () => ({
        url: CONSTANT.URL_GENERAL_STATUS_CHARGING,
        method: 'GET',
        headers: headers()
      })
    }),
    getStatusChargingRequestDropdownList: builder.mutation<
      DropdownListProps[],
      PartialDropdownListProps
    >({
      query: () => ({
        url: CONSTANT.URL_GENERAL_STATUS_CHARGING_REQUEST,
        method: 'GET',
        headers: headers()
      })
    }),
    getCarModelDropdownList: builder.mutation<RolesListDropdownProps[], void>({
      query: () => ({
        url: CONSTANT.URL_CAR_MODEL_DROPDOWN,
        method: 'GET',
        headers: headers()
      })
    }),
    getCarModelDropdownListBy: builder.query<RolesListDropdownProps[], void>({
      query: () => ({
        url: CONSTANT.URL_CAR_MODEL_DROPDOWN,
        method: 'GET',
        headers: headers()
      })
    }),
    getYearsDropdownListBy: builder.mutation<number[], void>({
      query: () => ({
        url: CONSTANT.URL_YEARS_DROPDOWN,
        method: 'GET',
        headers: headers()
      })
    }),
    getChargerPointDropdown: builder.mutation<
      DropdownListProps[],
      PartialEvChargerBoxDropdownProps
    >({
      query: (params) => ({
        url: CONSTANT.URL_EV_CHARGER_POINT_DROPDOWN,
        method: 'GET',
        headers: headers(),
        params: {
          Start: params.Start,
          Length: params.Length
        }
      })
    }),
    getChargerBoxGroupDropdown: builder.mutation<
      DropdownListProps[],
      { id: number | string; Start: number; Length: number }
    >({
      query: (params) => ({
        url: CONSTANT.URL_EV_CHARGER_GROUP_DROPDOWN + `${params.id}/ddl`,
        method: 'GET',
        headers: headers(),
        params: {
          Start: params.Start,
          Length: params.Length
        }
      })
    }),
    getChargerBoxDropdown: builder.mutation<
      DropdownListProps[],
      { id: number | string; Start: number; Length: number }
    >({
      query: (params) => ({
        url: CONSTANT.URL_EV_CHARGER_BOX_DROPDOWN + `${params.id}/ddl`,
        method: 'GET',
        headers: headers(),
        params: {
          Start: params.Start,
          Length: params.Length
        }
      })
    }),
    getCarBrandDropdown: builder.mutation<DropdownBrandCar[], void>({
      query: () => ({
        url: CONSTANT.URL_CAR_BRAND_DROPDOWN,
        method: 'GET',
        headers: headers()
      })
    }),
    getMemberTypeDropdown: builder.mutation<MemberTypeDropDown[], void>({
      query: () => ({
        url: CONSTANT.URL_MEMBER_TYPE_DROPDOWN,
        method: 'GET',
        headers: headers()
      })
    }),
    getUsernameDropdown: builder.mutation<DropdownUsernameProps[], void>({
      query: () => ({
        url: CONSTANT.URL_USERNAME_DROPDOWN,
        method: 'GET',
        headers: headers()
      })
    }),
  })
})

export const {
  useGetCityDropdownListMutation,
  useGetCityDropdownListByQuery,
  useGetSocketTypeDropdownListMutation,
  useGetSocketTypeDropdownListByQuery,
  useGetStatusChargingDropdownListMutation,
  useGetStatusChargingDropdownListByQuery,
  useGetStatusChargingRequestDropdownListMutation,
  useGetCarModelDropdownListMutation,
  useGetCarModelDropdownListByQuery,
  useGetYearsDropdownListByMutation,
  useGetChargerPointDropdownMutation,
  useGetChargerBoxGroupDropdownMutation,
  useGetChargerBoxDropdownMutation,
  useGetCarBrandDropdownMutation,
  useGetMemberTypeDropdownMutation,
  useGetUsernameDropdownMutation,
} = DropdownAPI
