/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Card,
  CardHeader,
  CardMedia,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography
} from '@mui/material'
import dayjs from 'dayjs'
import ReactQuill from 'react-quill'
import { decode } from 'html-entities'
import Drawer from 'components/drawer/detail'
import Loading from 'components/loading/loading'
import type { DetailStateProps } from 'modules/types'
import { useGetVoucherBenefitsDetailQuery } from 'store/voucherBenefits'
import DrawerStyle from './style'

const Detail = ({ open, id, onClose }: DetailStateProps & { id: string | number }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const { data, isFetching, isLoading } = useGetVoucherBenefitsDetailQuery(id)

  const arrData = [
    { value: 'voucherCampaignNameIND', label: 'Campaign Name ID' },
    { value: 'voucherCampaignNameENG', label: 'Campaign Name EN-US' },
    { value: 'voucherName', label: 'Voucher Name' },
    { value: 'benefitCategoryName', label: 'Benefits Category' },
    { value: 'memberTier', label: 'User Type', type: 'array' },
    { value: 'coins', label: 'Coins Amount' },
    { value: 'maxPurchaseperUser', label: 'Max Purchase per User' },
    { value: 'minCoinsAchievement', label: 'Min Coins Achievement' },
    // { value: 'maxCoinsAchievement', label: 'Max Coins Achievement' },
    { value: 'validFrom', label: 'Valid From', type: 'date' },
    { value: 'validUntil', label: 'Valid Until', type: 'date' },
    { value: 'partner', label: 'Partner' },
    { value: 'usageLimit', label: 'Usage Limit' },
    { value: 'remarks', label: 'Deep Link' },
    { value: 'status', label: 'Status' }
  ]

  const arrDataImage = [
    { value: 'useContentFromVoucher', label: 'Use Content from Voucher', type: 'check' },
    { value: 'descriptionId', label: 'Description ID', type: 'quill' },
    { value: 'descriptionEn', label: 'Description EN-US', type: 'quill' },
    { value: 'termsAndConditionsId', label: 'Terms and Condition ID', type: 'quill' },
    { value: 'termsAndConditionsEn', label: 'Terms and Condition EN-US', type: 'quill' },
    { value: 'image', label: 'Voucher Image', type: 'image' },
    { value: 'banner', label: 'Voucher Banner', type: 'image' }
  ]

  const checkArray = (str: any) => {
    if (typeof str === 'object' && str?.length > 0) {
      return str.map((item: any) => item.memberName).join(',')
    }

    return str
  }

  return (
    <>
      <Drawer open={open} title='Detail Voucher Benefits' onClose={onClose}>
        <Container {...DrawerStyle.Container} maxWidth='lg'>
          {(isLoading || isFetching) && !data && <Loading />}
          {data && (
            <>
              <Grid container spacing={2}>
                {arrData.map((item, index) => (
                  <Grid key={index} item xs={6}>
                    <TextField
                      key={index}
                      variant='outlined'
                      label={item.label}
                      value={
                        data && item?.type === 'date'
                          ? dayjs(data[item.value as never]).format('DD MMMM YYYY HH:mm')
                          : checkArray(data[item.value as never])
                      }
                      inputProps={{ readOnly: true }}
                      fullWidth
                    />
                  </Grid>
                ))}
              </Grid>
              <Grid container spacing={2} columns={16}>
                <Grid item xs={16}>
                  {arrDataImage.map((item, index) => {
                    if (item.type === 'check') {
                      return (
                        <Grid xs={16} key={index}>
                          <FormControlLabel
                            id='UseContent'
                            control={
                              <Checkbox
                                id='UseContent'
                                name='UseContent'
                                defaultChecked={Boolean(data[item.value as never])}
                                checked={Boolean(data[item.value as never])}
                              />
                            }
                            label={item.label}
                          />
                        </Grid>
                      )
                    }
                    if (item.type === 'quill') {
                      return (
                        <Grid xs={16} key={index}>
                          <Typography variant='button' gutterBottom>
                            {item.label}
                          </Typography>
                          <ReactQuill
                            theme='snow'
                            id='TnCEN'
                            value={decode(data[item.value as never])}
                            readOnly={true}
                          />
                        </Grid>
                      )
                    }
                    if (item.type === 'image') {
                      return (
                        <Grid xs={16} key={index} marginTop={1}>
                          <Card key={index}>
                            <CardHeader subheader={item.label} />
                            <CardMedia
                              component='img'
                              height='240'
                              image={data[item.value as never]}
                              alt={item.label}
                              sx={{ objectFit: 'contain' }}
                            />
                          </Card>
                        </Grid>
                      )
                    }
                  })}
                </Grid>
              </Grid>
            </>
          )}
        </Container>
      </Drawer>
    </>
  )
}

export default Detail
