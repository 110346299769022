import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { useNavigate } from 'react-router-dom'
import {
  Autocomplete,
  Chip,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField
} from '@mui/material'
import Content from 'components/content/content'
import Icon from 'components/icon'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import CONSTANT from 'modules/constant'
import GLOBAL from 'modules/global'
import type { PartialPartnerLocationProps } from 'modules/partial'
import type { ErrorProps } from 'modules/types'
import {
  useDownloadPartnerLocationMutation,
  useDownloadTemplatePartnerLocationMutation,
  useGetListPartnerLocationDropdownMutation,
  useGetListPartnerLocationMutation,
  useUpdateStatusPartnerLocationMutation
} from 'store/locationPartner'
import MenuModalItem from 'components/menuItem/menuItem'
import Filter from 'components/filter/filter'
import GlobalStyle from 'modules/styles'
import { useGetCityDropdownListMutation } from 'store/dropdown'
import Create from './create/create'
import Detail from './detail/detail'
import Upload from './upload/upload'
import Edit from './edit/edit'

const PartnerCategory = () => {
  const navigate = useNavigate()
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [getListPartnerLocation, partnerLocation] = useGetListPartnerLocationMutation()
  const [getListPartnerLocationDropdown, partnerLocationDropdown] =
    useGetListPartnerLocationDropdownMutation()
  const [downloadPartnerLocation, download] = useDownloadPartnerLocationMutation()
  const [updateStatusPartnerLocation, status] = useUpdateStatusPartnerLocationMutation()
  const [downloadTemplatePartnerLocation, template] = useDownloadTemplatePartnerLocationMutation()
  const [getCityDropdownList, cityDropdown] = useGetCityDropdownListMutation()
  const [menu, setMenu] = useState<null | number>(null)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [dialogCreate, setDialogCreate] = useState<boolean>(false)
  const [dialogUpload, setDialogUpload] = useState<boolean>(false)
  const [dialogUpdate, setDialogUpdate] = useState<boolean>(false)
  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [number, setNumber] = useState<number>(0)

  const [payload, setPayload] = useState<PartialPartnerLocationProps>({
    start: 0,
    length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    field: CONSTANT.DEFAULT_PARTNER_CATEGORY_FIELD.join(','),
    search: { value: '' },
    status: '',
    city: '',
    partnerId: ''
  })

  const [filter, setFilter] = useState({
    status: '',
    city: '',
    partnerId: ''
  })

  const onResync = () => {
    const formData = new FormData()
    formData.append('start', payload.start.toString())
    formData.append('length', payload.length.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(payload.status)) formData.append('status', payload.status)
    if (!isEmpty(payload.city)) formData.append('city', payload.city)
    if (!isEmpty(payload.partnerId)) formData.append('partnerId', payload.partnerId)

    getListPartnerLocation(formData)
  }

  const onUpload = () => setDialogUpload(true)
  const onFilter = (state: boolean) => setDialogFilter(state)

  const onChangePage = (value: number) => setPayload({ ...payload, start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, length: value })

  const onSearch = (value: string) => setPayload({ ...payload, start: 0, search: { value } })
  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }
  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('start', String(0))
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(payload.status)) formData.append('status', payload.status)
    if (!isEmpty(payload.city)) formData.append('city', payload.city)
    if (!isEmpty(payload.partnerId)) formData.append('partnerId', payload.partnerId)

    if (type && !isEmpty(type)) formData.append('Download-Type', type)

    downloadPartnerLocation(formData)
  }

  const onDownloadTemplate = (type?: string) => {
    const formData = new FormData()
    if (type && !isEmpty(type)) formData.append('Download-Type', type)
    downloadTemplatePartnerLocation(formData)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }
  const onCreate = () => setDialogCreate(true)

  const tableHeadTitles = [
    'Partner ID',
    'Partner Name',
    'Partner Short Name',
    'City',
    'Title English',
    'Title Indonesia',
    'Status'
  ]

  useEffect(() => {
    onResync()
  }, [payload])

  const openAnchor = Boolean(anchor)
  const totalPage = partnerLocation.data && GLOBAL.tableTotalPage(partnerLocation.data.recordsTotal)
  const numbers = GLOBAL.tableNumber(payload.start)

  return (
    <>
      <Content
        title='Partner Location'
        onSearch={onSearch}
        onResync={onResync}
        tableHeadTitles={tableHeadTitles}
        onDownload={onDownload}
        onFilter={() => onFilter(true)}
        onAdditional={onCreate}
        additionalTitle='Create New Partner Location'
        additionalPrefixIcon='Create'
        isLoading={partnerLocation.isLoading}
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={partnerLocation.data && partnerLocation.data.recordsTotal}
        onUpload={onUpload}
        uploadTitle='Upload csv'
        uploadPrefixIcon='FileUpload'
        useDownloadDropdown
        onTemplateDownload={onDownloadTemplate}
        templateDownloadLoading={template.isLoading}
        templateTitle='Template Partner'
        useTemplateDownloadDropdown
        page={payload.start + 1}
      >
        {partnerLocation.isSuccess &&
          partnerLocation.data &&
          partnerLocation.data.data.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${item.partnerId}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${item.partnerId}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${item.partnerId}`}
                  aria-labelledby={`button-${index}-${item.partnerId}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View Detail
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      onRemoveAnchor(() =>
                        navigate(GLOBAL.encodeUrl(item.partnerName), {
                          state: { id: item.partnerId }
                        })
                      )
                    }
                  >
                    Open
                  </MenuItem>
                  <MenuItem onClick={() => onRemoveAnchor(() => setDialogUpdate(true))}>
                    Update
                  </MenuItem>
                  <MenuModalItem
                    title={item.status == 'Enabled' ? 'Disable' : 'Enable'}
                    onClick={() =>
                      onRemoveAnchor(() =>
                        updateStatusPartnerLocation({
                          id: item.partnerId,
                          status: item.status == 'Enabled' ? 'Disabled' : 'Enabled'
                        })
                      )
                    }
                  >
                    {item.status == 'Enabled' ? 'Disable' : 'Enable'}
                  </MenuModalItem>
                </Menu>
                {drawerDetail && menu == index && (
                  <Detail
                    id={item.partnerId.toString()}
                    open={drawerDetail && menu == index}
                    onClose={() => setDrawerDetail(false)}
                  />
                )}

                {dialogUpdate && menu == index && (
                  <Edit
                    id={item.partnerId.toString()}
                    open={dialogUpdate && menu == index}
                    onClose={() => setDialogUpdate(false)}
                  />
                )}
              </TableCell>

              <TableCell sx={{ cursor: 'pointer' }}>{item.partnerId || '-'}</TableCell>
              <TableCell>{item.partnerName || '-'}</TableCell>
              <TableCell>{item.partnerShortName || '-'}</TableCell>
              <TableCell>{item.city || '-'}</TableCell>
              <TableCell>{item.titleEnglish || '-'}</TableCell>
              <TableCell>{item.titleIndonesia || '-'}</TableCell>
              <TableCell>
                <Chip label={item.status} color={item.status == 'Enabled' ? 'success' : 'error'} />
              </TableCell>
            </TableRow>
          ))}
      </Content>
      <Filter
        open={dialogFilter}
        title='Filter'
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
        onReset={() => {
          setFilter({
            status: '',
            city: '',
            partnerId: ''
          })
          setPayload({
            ...payload,
            start: 0,
            status: '',
            city: '',
            partnerId: ''
          })
          setNumber(number + 1)
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({ ...payload, start: 0, ...filter })
          onFilter(false)
        }}
      >
        <Autocomplete
          options={(partnerLocationDropdown && partnerLocationDropdown.data) || []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.id == value.id : false
          }
          onOpen={() => getListPartnerLocationDropdown()}
          onChange={(_, partner) =>
            setFilter({
              ...filter,
              partnerId: (partner && String(partner.id)) || ''
            })
          }
          value={
            (partnerLocationDropdown &&
              partnerLocationDropdown.data &&
              partnerLocationDropdown.data.find((e) => String(e.id) == filter.partnerId)) ||
            null
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.id}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='partnerId'
              label='Partner Name'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {partnerLocationDropdown.isLoading && (
                      <CircularProgress color='inherit' size={20} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <FormControl fullWidth>
          <InputLabel id='select-label'>Status</InputLabel>
          <Select
            labelId='select-label'
            id='id-select-label'
            value={filter.status}
            label='Status'
            onChange={(event) => setFilter({ ...filter, status: event.target.value })}
          >
            <MenuItem value='Enabled'>Enabled</MenuItem>
            <MenuItem value='Disabled'>Disabled</MenuItem>
          </Select>
        </FormControl>
        <Autocomplete
          key={'cityId' + number}
          options={(cityDropdown && cityDropdown.data) || []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.name == value.name : false
          }
          onOpen={() => getCityDropdownList()}
          onChange={(_, city) => {
            if (city) {
              setFilter({ ...filter, city: city.name })
            }
          }}
          value={
            (cityDropdown &&
              cityDropdown.data &&
              cityDropdown.data.find((e) => e.name == filter.city)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.id}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='cityId'
              label='City'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {cityDropdown.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
      </Filter>

      <Notification
        open={
          !partnerLocation.isLoading &&
          !partnerLocation.isUninitialized &&
          !partnerLocation.isSuccess
        }
        onClose={() => (partnerLocation.isError ? partnerLocation.reset() : location.reload())}
        isError={partnerLocation.isError}
        message={GLOBAL.returnExceptionMessage(
          partnerLocation.isError,
          partnerLocation.error as ErrorProps
        )}
      />

      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />

      <Notification
        open={!status.isLoading && !status.isUninitialized}
        onClose={() => (status.isError ? status.reset() : location.reload())}
        isError={Boolean(status.error) && status.isError}
        message={GLOBAL.returnExceptionMessage(status.isError, status.error as ErrorProps)}
      />

      {(partnerLocation.isLoading || download.isLoading) && <Loading />}

      {dialogUpload && <Upload open={dialogUpload} onClose={() => setDialogUpload(false)} />}
      {dialogCreate && <Create open={dialogCreate} onClose={() => setDialogCreate(false)} />}
    </>
  )
}

export default PartnerCategory
