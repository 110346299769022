import { useEffect } from 'react'
import { FormikProvider, useFormik } from 'formik'
import {
  Autocomplete,
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material'
import * as yup from 'yup'
import Dialog from 'components/dialog/dialog'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { PartialBranchPartnerUpdateProps } from 'modules/partial'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import {
  useGetDetailBranchPartnerQuery,
  useUpdateBranchPartnerMutation
} from 'store/locationPartner'
import GlobalStyle from 'modules/styles'
import { useGetCityDropdownListMutation } from 'store/dropdown'
import DialogStyle from './style'

const Edit = ({
  open,
  id,
  partnerKey,
  onClose,
  companyId,
}: DetailStateProps & { id: string | number; partnerKey: string, companyId: number }) => {
  if (!open) return <></>
  const [updateBranchPartner, updateBranch] = useUpdateBranchPartnerMutation()
  const { data, isFetching, isLoading, isSuccess } = useGetDetailBranchPartnerQuery(id)
  const [getCityDropdownList, cityDropdown] = useGetCityDropdownListMutation()

  const defaultValueCity = {
    id: (data && data.cityId) || null,
    description: 'NULL',
    code: 'NULL',
    name: (data && data.city) || '',
    active: true,
    remark: '',
    status: 'Enabled',
    provinceId: Math.floor(Math.random() * 6) + 1
  }

  const scheme = yup.object<PartialBranchPartnerUpdateProps>({
    BranchName: yup.string().required('BranchName is required'),
    PhoneNumber: yup.string().required('PhoneNumber is required'),
    Address: yup.string().required('Address is required'),
    Description: yup.string().required('Description is required'),
    GoogleMapUrl: yup.string().required('GoogleMapUrl is required'),
    PostalCode: yup.string().required('PostalCode is required'),
    Status: yup.string().required('Status is required'),
    Longitude: yup.number().required('Longitude is required'),
    Latitude: yup.number().required('Latitude is required'),
    CityId: yup.number().required('CityId is required'),
    CompanyId: yup.number().required('CompanyId is required'),
    BranchId: yup.number().required('BranchId is required')
  })

  const formik = useFormik<PartialBranchPartnerUpdateProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      BranchName: (data && data.branchName) || '',
      PhoneNumber: (data && data.phoneNumber) || '',
      Address:(data && data.address) || '',
      Description: (data && data.description) || '',
      GoogleMapUrl: (data && data.googleMapUrl) || '',
      PostalCode: (data && data.postalCode) || '',
      Status: (data && data.status) || '',
      Longitude: (data && data.longitude) || null,
      Latitude: (data && data.latitude) || null,
      CityId: null,
      CompanyId: companyId,
      BranchId: (data && parseInt(data.branchId)) || null
    },
    onSubmit: (values: PartialBranchPartnerUpdateProps) => handleSubmit(values)
  })

  const handleSubmit = (e: PartialBranchPartnerUpdateProps) => {
    updateBranchPartner(e).then(() =>
      setTimeout(() => {
        onClose()
      }, 1000)
    )
  }
  useEffect(() => {
    getCityDropdownList()

  }, [])
  useEffect(() => {
    if(isSuccess &&  data && cityDropdown.isSuccess) {
      const findCityId = cityDropdown.isSuccess && cityDropdown.data.find((item) => item.name === data.city)
      formik.setFieldValue('BranchId', data.branchId)
      formik.setFieldValue('CityId', findCityId?.id)
    }
  }, [isSuccess, cityDropdown.isSuccess])

  return (
    <>
      <Dialog
        open={open}
        title='Update Partner Branch'
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          {(isLoading || isFetching) && !data && <Loading />}
          {data && (
            <>
              <FormikProvider value={formik}>
                <TextField
                  id='BranchName'
                  variant='outlined'
                  label='Branch Name'
                  value={formik.values.BranchName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched && formik.touched.BranchName && Boolean(formik.errors.BranchName)
                  }
                  helperText={
                    formik.touched &&
                    formik.touched.BranchName &&
                    formik.errors &&
                    formik.errors.BranchName
                  }
                  fullWidth
                />
                <TextField
                  id='PhoneNumber'
                  variant='outlined'
                  label='Phone Number'
                  value={formik.values.PhoneNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched &&
                    formik.touched.PhoneNumber &&
                    Boolean(formik.errors.PhoneNumber)
                  }
                  helperText={
                    formik.touched &&
                    formik.touched.PhoneNumber &&
                    formik.errors &&
                    formik.errors.PhoneNumber
                  }
                  fullWidth
                />
                <TextField
                  variant='outlined'
                  label='Partner Name'
                  value={partnerKey || ''}
                  disabled
                  fullWidth
                />
                <TextField
                  id='Address'
                  variant='outlined'
                  label='Address'
                  value={formik.values.Address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched && formik.touched.Address && Boolean(formik.errors.Address)}
                  helperText={
                    formik.touched &&
                    formik.touched.Address &&
                    formik.errors &&
                    formik.errors.Address
                  }
                  fullWidth
                />
                <Autocomplete
                  key={'cityId'}
                  options={(cityDropdown && cityDropdown.data) || []}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option && value ? option.name == value.name : false
                  }
                  defaultValue={defaultValueCity}
                  onOpen={() => getCityDropdownList()}
                  onChange={(_, city) => formik.setFieldValue('CityId', city?.id)}
                  value={
                    (cityDropdown &&
                      cityDropdown.data &&
                      cityDropdown.data.find((e) => e.id == formik.values.CityId)) ||
                    undefined
                  }
                  ListboxProps={GlobalStyle.ListBox}
                  renderOption={(props, item) => (
                    <li {...props} key={item.id}>
                      {item.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name='cityId'
                      label='City'
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {cityDropdown.isLoading && (
                              <CircularProgress color='inherit' size={20} />
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                />
                <TextField
                  id='PostalCode'
                  variant='outlined'
                  label='Postal Code'
                  value={formik.values.PostalCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched && formik.touched.PostalCode && Boolean(formik.errors.PostalCode)
                  }
                  helperText={
                    formik.touched &&
                    formik.touched.PostalCode &&
                    formik.errors &&
                    formik.errors.PostalCode
                  }
                  fullWidth
                />

                <TextField
                  id='Longitude'
                  variant='outlined'
                  label='Longitude'
                  type='number'
                  value={formik.values.Longitude}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched && formik.touched.Longitude && Boolean(formik.errors.Longitude)
                  }
                  helperText={
                    formik.touched &&
                    formik.touched.Longitude &&
                    formik.errors &&
                    formik.errors.Longitude
                  }
                  fullWidth
                />
                <TextField
                  id='Latitude'
                  variant='outlined'
                  label='Latitude'
                  type='number'
                  value={formik.values.Latitude}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched && formik.touched.Latitude && Boolean(formik.errors.Latitude)
                  }
                  helperText={
                    formik.touched &&
                    formik.touched.Latitude &&
                    formik.errors &&
                    formik.errors.Latitude
                  }
                  fullWidth
                />

                <TextField
                  id='GoogleMapUrl'
                  variant='outlined'
                  label='GoogleMapUrl'
                  value={formik.values.GoogleMapUrl}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched &&
                    formik.touched.GoogleMapUrl &&
                    Boolean(formik.errors.GoogleMapUrl)
                  }
                  helperText={
                    formik.touched &&
                    formik.touched.GoogleMapUrl &&
                    formik.errors &&
                    formik.errors.GoogleMapUrl
                  }
                  fullWidth
                />
                <FormControl fullWidth>
                  <InputLabel id='select-label'>Status</InputLabel>
                  <Select
                    labelId='select-label'
                    id='id-select-label'
                    value={formik.values.Status}
                    label='Status'
                    defaultValue={formik.values.Status}
                    onChange={(event) => formik.setFieldValue('status', event.target.value)}
                  >
                    <MenuItem value='Enabled'>Enabled</MenuItem>
                    <MenuItem value='Disabled'>Disabled</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  id='Description'
                  variant='outlined'
                  label='Description'
                  value={formik.values.Description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched &&
                    formik.touched.Description &&
                    Boolean(formik.errors.Description)
                  }
                  helperText={
                    formik.touched &&
                    formik.touched.Description &&
                    formik.errors &&
                    formik.errors.Description
                  }
                  fullWidth
                />
              </FormikProvider>
            </>
          )}
        </Container>
      </Dialog>

      <Notification
        open={!updateBranch.isLoading && !updateBranch.isUninitialized}
        onClose={() => (updateBranch.isError ? updateBranch.reset() : location.reload)}
        isError={Boolean(updateBranch.error) && updateBranch.isError}
        message={GLOBAL.returnExceptionMessage(
          updateBranch.isError,
          updateBranch.error as ErrorProps
        )}
      />
    </>
  )
}

export default Edit
