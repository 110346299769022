import type { ContainerProps } from '@mui/material'

class BackdropStyle {
  static ContainerImage: ContainerProps = {
    maxWidth: false,
    disableGutters: true,
    sx: {
      display: 'flex',
      width: '40%',
      height: '90%',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }
}

export default BackdropStyle
