import { useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Autocomplete,
  Box,
  CircularProgress,
  Container,
  FormControlLabel,
  Switch,
  TextField,
  Typography
} from '@mui/material'
import * as yup from 'yup'
import { useGetChargePointDropdownListMutation } from 'store/evChargePoint'
import CONSTANT from 'modules/constant'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import Dialog from 'components/dialog/dialog'
import FORMIK from 'modules/formik'
import type { PartialEVPriceChargeBoxGroupProps } from 'modules/partial'
import GlobalStyle from 'modules/styles'
import { useGetChargeBoxGroupDropdownListMutation } from 'store/evChargeBoxGroup'
import { useCreatePriceChargeBoxGroupListMutation } from 'store/evPriceChargeBoxGroup'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import DialogStyle from './style'

const Create = ({ open, id, onClose }: DetailStateProps & { id: string }) => {
  if (!open) return <></>

  const [createPriceChargeBoxGroup, create] = useCreatePriceChargeBoxGroupListMutation()

  const [getChargePointDropdownList, chargePointDropdown] = useGetChargePointDropdownListMutation()
  const [getChargeBoxGroupDropdownList, chargeBoxGroupDropdown] =
    useGetChargeBoxGroupDropdownListMutation()

  const [isManual, setIsManual] = useState<boolean>(false)

  const scheme = yup.object<PartialEVPriceChargeBoxGroupProps>({
    id: yup.string().required('ID is required'),
    chargePointId: yup.string().required('Charge Point ID is required'),
    chargeBoxGroupIdentity: yup.string().required('Charge Box Group Identity is required')
  })

  const initialValues: PartialEVPriceChargeBoxGroupProps = {
    id,
    chargePointId: '',
    chargeBoxGroupIdentity: ''
  }

  const formik = FORMIK.useFormFormik<PartialEVPriceChargeBoxGroupProps>(
    scheme,
    initialValues,
    (values) => createPriceChargeBoxGroup(values)
  )

  return (
    <>
      <Dialog
        title='Assign New Charge Box Group'
        open={open}
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={create.isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          <Autocomplete
            options={(chargePointDropdown && chargePointDropdown.data) || []}
            getOptionLabel={(option) => option.text}
            isOptionEqualToValue={(option, value) =>
              option && value ? option.value == value.value : false
            }
            onOpen={() => getChargePointDropdownList(CONSTANT.DEFAULT_DROPDOWN_PAYLOAD)}
            onChange={(_, chargePoint) => {
              const point = (chargePoint && String(chargePoint.value)) || ''
              formik.setFieldValue('chargePointId', point)
            }}
            value={
              (chargePointDropdown &&
                chargePointDropdown.data &&
                chargePointDropdown.data.find(
                  (e) => String(e.value) == formik.values.chargePointId
                )) ||
              undefined
            }
            ListboxProps={GlobalStyle.ListBox}
            renderOption={(props, item) => (
              <li {...props} key={item.value}>
                {item.text}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                name='ChargePointId'
                label='* EV Charge Point Name'
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {chargePointDropdown.isLoading && (
                        <CircularProgress color='inherit' size={20} />
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
          <TextField
            id='chargePointId'
            variant='outlined'
            label='EV Charge Point ID'
            value={formik.values.chargePointId}
            inputProps={{ readOnly: true }}
            fullWidth
          />
          <Box {...DialogStyle.TitleSwitch}>
            <Typography>Charge Box Group ID</Typography>
            <FormControlLabel
              labelPlacement='start'
              control={
                <Switch
                  checked={isManual}
                  name='manualInput'
                  onChange={(e) => setIsManual(e.target.checked)}
                />
              }
              label='Manual Input'
            />
          </Box>
          {isManual && (
            <TextField
              id='chargeBoxGroupIdentity'
              variant='outlined'
              label='* EV Charge Box Group ID'
              value={formik.values.chargeBoxGroupIdentity}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              fullWidth
            />
          )}
          {!isManual && (
            <Autocomplete
              key={formik.values.chargePointId}
              options={(chargeBoxGroupDropdown && chargeBoxGroupDropdown.data) || []}
              getOptionLabel={(option) => option.text}
              isOptionEqualToValue={(option, value) =>
                option && value ? option.value == value.value : false
              }
              onOpen={() =>
                getChargeBoxGroupDropdownList({
                  ...CONSTANT.DEFAULT_DROPDOWN_PAYLOAD,
                  id: Number(formik.values.chargePointId)
                })
              }
              onChange={(_, chargeBoxGroup) => {
                const point = (chargeBoxGroup && String(chargeBoxGroup.text)) || ''
                formik.setFieldValue('chargeBoxGroupIdentity', point)
              }}
              value={
                (chargeBoxGroupDropdown &&
                  chargeBoxGroupDropdown.data &&
                  chargeBoxGroupDropdown.data.find(
                    (e) => String(e.text) == formik.values.chargeBoxGroupIdentity
                  )) ||
                undefined
              }
              disabled={isEmpty(formik.values.chargePointId)}
              ListboxProps={GlobalStyle.ListBox}
              renderOption={(props, item) => (
                <li {...props} key={item.value}>
                  {item.text}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='ChargeBoxGroupId'
                  label='* EV Charge Box Group ID'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {chargeBoxGroupDropdown.isLoading && (
                          <CircularProgress color='inherit' size={20} />
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
            />
          )}
        </Container>
      </Dialog>

      <Notification
        open={!create.isLoading && !create.isUninitialized}
        onClose={() => (create.isError ? create.reset() : location.reload())}
        isError={Boolean(create.error) && create.isError}
        message={GLOBAL.returnExceptionMessage(create.isError, create.error as ErrorProps)}
      />
    </>
  )
}

export default Create
