import CONSTANT from 'modules/constant'
import type {
  EvHistoryPartnerOverviewProps,
  EvHistoryPartnerRequestAuthProps,
  EvHistoryPartnerRequestProps,
  EvHistoryPartnerTransactionProps,
  PaginationProps,
  ResultProps
} from 'modules/types'
import type {
  PartialEVHistoryPartnerRequestAuthProps,
  PartialEVHistoryPartnerRequestProps,
  PartialEVHistoryPartnerRequestStartProps,
  PartialEvHistoryPartnerOverviewProps,
  PartialEvHistoryPartnerStopProps
} from 'modules/partial'
import { API, download, headers } from './api'

const HistoryPartnerAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    setKwhLimitRequest: builder.mutation<ResultProps, PartialEVHistoryPartnerRequestProps>({
      query: (query) => ({
        url: CONSTANT.URL_EV_HISTORY_PARTNER_KWH_LIMIT_REQUEST + query.chargePointId + '/kwhlimit',
        method: 'POST',
        headers: headers(),
        body: { kwhLimit: query.kwhLimit },
        formData: true
      })
    }),
    getEvHistoryPartnerRequestList: builder.mutation<
      PaginationProps<EvHistoryPartnerRequestProps>,
      FormData
    >({
      query: (body) => ({
        url: CONSTANT.URL_EV_HISTORY_PARTNER_REQUEST,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    authEvHistoryPartnerRequest: builder.mutation<
      EvHistoryPartnerRequestAuthProps,
      PartialEVHistoryPartnerRequestAuthProps
    >({
      query: (body) => ({
        url: CONSTANT.URL_EV_HISTORY_PARTNER_AUTHORIZATION,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    startEvHistoryPartnerCharging: builder.mutation<
      EvHistoryPartnerRequestAuthProps,
      PartialEVHistoryPartnerRequestStartProps
    >({
      query: (body) => ({
        url: CONSTANT.URL_EV_HISTORY_PARTNER_START_CHARGING,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    stopEvHistoryPartnerCharging: builder.mutation<ResultProps, PartialEvHistoryPartnerStopProps>({
      query: (body) => ({
        url: CONSTANT.URL_EV_HISTORY_PARTNER_STOP_CHARGING,
        headers: headers(),
        method: 'POST',
        body: body
      })
    }),
    getEvHistoryPartnerList: builder.mutation<
      PaginationProps<EvHistoryPartnerTransactionProps>,
      FormData
    >({
      query: (body) => ({
        url: CONSTANT.URL_EV_HISTORY_PARTNER_LIST,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    downloadEvHistoryPartnerList: builder.mutation<
      PaginationProps<EvHistoryPartnerTransactionProps>,
      FormData
    >({
      query: (body) => ({
        url: CONSTANT.URL_EV_HISTORY_PARTNER_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true,
        responseHandler: download
      })
    }),
    getEvHistoryPartnerSummary: builder.mutation<
      EvHistoryPartnerOverviewProps,
      PartialEvHistoryPartnerOverviewProps
    >({
      query: (body) => ({
        url: CONSTANT.URL_EV_HISTORY_PARTNER_SUMMARY,
        method: 'POST',
        headers: headers(),
        body: body
      })
    })
  })
})

export const {
  useSetKwhLimitRequestMutation,
  useGetEvHistoryPartnerRequestListMutation,
  useAuthEvHistoryPartnerRequestMutation,
  useStartEvHistoryPartnerChargingMutation,
  useStopEvHistoryPartnerChargingMutation,
  useGetEvHistoryPartnerListMutation,
  useDownloadEvHistoryPartnerListMutation,
  useGetEvHistoryPartnerSummaryMutation
} = HistoryPartnerAPI
