import type { LoadingButtonProps } from '@mui/lab'
import type { BoxProps, ContainerProps, StackProps } from '@mui/material'

class DrawerStyle {
  static Container: ContainerProps = {
    maxWidth: false,
    disableGutters: true,
    sx: {
      display: 'flex',
      gap: '2rem',
      flexDirection: 'column'
    }
  }

  static Stack: StackProps = {
    direction: 'column',
    spacing: 2
  }

  static ImageBox: BoxProps = {
    height: '300px',
    border: '1px solid #eee',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  }

  static Submit: LoadingButtonProps = {
    sx: {
      alignSelf: 'end'
    }
  }
}

export default DrawerStyle
