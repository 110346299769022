import { useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import { isEmpty } from 'lodash'
import * as yup from 'yup'
import { Autocomplete, CircularProgress, Container, TextField, Typography } from '@mui/material'
import Dialog from 'components/dialog/dialog'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { PartialEvChargerBoxDropdownProps, PartialCreateQrCode } from 'modules/partial'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import {
  useGetChargerBoxDropdownMutation,
  useGetChargerBoxGroupDropdownMutation,
  useGetChargerPointDropdownMutation
} from 'store/dropdown'
import { useCreateQrCodeMutation } from 'store/qrcode'
import GlobalStyle from 'modules/styles'
import BackdropImage from 'components/backdropImage/backdropImage'
import DialogStyle from './style'

const Create = ({ open, onClose }: DetailStateProps) => {
  const [createQrCode, create] = useCreateQrCodeMutation()
  const [getChargerPointDropdown, chargerPoint] = useGetChargerPointDropdownMutation()
  const [getChargerBoxGroupDropdown, chargerBoxGroup] = useGetChargerBoxGroupDropdownMutation()
  const [getChargerBoxDropdown, chargerBox] = useGetChargerBoxDropdownMutation()
  const [previewImage, setPreviewImage] = useState<string>('')
  const [previewBackdrop, setPreviewBackdrop] = useState<boolean>(false)

  const payload: PartialEvChargerBoxDropdownProps = {
    Start: 0,
    Length: 100
  }
  const scheme = yup.object<PartialCreateQrCode>({
    chargerPointId: yup.number().required('Policy Name is required'),
    chargerBoxGroupId: yup.number().required('Policy Name is required'),
    chargerBoxId: yup.number().required('Policy Name is required'),
    file: yup.mixed().required('Policy Name is required')
  })

  const formik = useFormik<PartialCreateQrCode>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      chargerPointId: '',
      chargerBoxGroupId: '',
      chargerBoxId: '',
      file: ''
    },
    onSubmit: (values: PartialCreateQrCode) => handleSubmit(values)
  })

  const handleSubmit = (e: PartialCreateQrCode) => {
    const formData = new FormData()
    formData.append('file', e.file)
    createQrCode({ id: e.chargerBoxId.toString(), body: formData })
  }
  return (
    <>
      <Dialog
        open={open}
        title='Create New EV QR Code'
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={false}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          <FormikProvider value={formik}>
            <Autocomplete
              fullWidth
              options={(chargerPoint && chargerPoint.data) || []}
              getOptionLabel={(option) => option.text}
              isOptionEqualToValue={(option, value) =>
                option && value ? option.value == value.value : false
              }
              onOpen={() => getChargerPointDropdown(payload)}
              onChange={(_, chargerPoint) => {
                if (chargerPoint) {
                  formik.setFieldValue('chargerPointId', chargerPoint.value.toString())
                }
              }}
              ListboxProps={GlobalStyle.ListBox}
              renderOption={(props, item) => (
                <li {...props} key={item.value}>
                  {item.text}
                </li>
              )}
              value={
                (chargerPoint &&
                  chargerPoint.data &&
                  chargerPoint.data.find(
                    (e) => String(e.value) == String(formik.values.chargerPointId)
                  )) ||
                undefined
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='Charger Box'
                  label='Charger Point'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {chargerPoint.isLoading && <CircularProgress color='inherit' size={20} />}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                  error={
                    formik.touched &&
                    formik.touched.chargerPointId &&
                    Boolean(formik.errors.chargerPointId)
                  }
                  helperText={
                    formik.touched &&
                    formik.touched.chargerPointId &&
                    formik.errors &&
                    formik.errors.chargerPointId
                  }
                />
              )}
            />

            <Autocomplete
              fullWidth
              options={(chargerBoxGroup && chargerBoxGroup.data) || []}
              getOptionLabel={(option) => option.text}
              isOptionEqualToValue={(option, value) =>
                option && value ? option.value == value.value : false
              }
              onOpen={() =>
                getChargerBoxGroupDropdown({
                  id: formik.values.chargerPointId,
                  Start: payload.Start,
                  Length: payload.Length
                })
              }
              onChange={(_, chargerBoxGroupId) => {
                if (chargerBoxGroupId) {
                  formik.setFieldValue('chargerBoxGroupId', chargerBoxGroupId.value.toString())
                }
              }}
              value={
                (chargerBoxGroup &&
                  chargerBoxGroup.data &&
                  chargerBoxGroup.data.find(
                    (e) => String(e.value) == String(formik.values.chargerBoxGroupId)
                  )) ||
                undefined
              }
              disabled={isEmpty(formik.values.chargerPointId)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='Charger Box'
                  label='Charger Box Group'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {chargerBoxGroup.isLoading && (
                          <CircularProgress color='inherit' size={20} />
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                  error={
                    formik.touched &&
                    formik.touched.chargerBoxGroupId &&
                    Boolean(formik.errors.chargerBoxGroupId)
                  }
                  helperText={
                    formik.touched &&
                    formik.touched.chargerBoxGroupId &&
                    formik.errors &&
                    formik.errors.chargerBoxGroupId
                  }
                />
              )}
            />

            <Autocomplete
              fullWidth
              options={(chargerBox && chargerBox.data) || []}
              getOptionLabel={(option) => option.text}
              isOptionEqualToValue={(option, value) =>
                option && value ? option.value == value.value : false
              }
              onOpen={() =>
                getChargerBoxDropdown({
                  id: formik.values.chargerBoxGroupId,
                  Start: payload.Start,
                  Length: payload.Length
                })
              }
              onChange={(_, chargerBox) => {
                if (chargerBox) {
                  formik.setFieldValue('chargerBoxId', chargerBox.value.toString())
                }
              }}
              value={
                (chargerBox &&
                  chargerBox.data &&
                  chargerBox.data.find(
                    (e) => String(e.value) == String(formik.values.chargerBoxId)
                  )) ||
                undefined
              }
              disabled={isEmpty(formik.values.chargerBoxGroupId)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='Charger Box'
                  label='Charger Box'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {chargerBox.isLoading && <CircularProgress color='inherit' size={20} />}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                  error={
                    formik.touched &&
                    formik.touched.chargerBoxId &&
                    Boolean(formik.errors.chargerBoxId)
                  }
                  helperText={
                    formik.touched &&
                    formik.touched.chargerBoxId &&
                    formik.errors &&
                    formik.errors.chargerBoxId
                  }
                />
              )}
            />

            <div
              style={{
                height: '300px',
                border: '1px solid #eee',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {!previewImage && (
                <Typography style={{ color: '#cfcfcf' }}>QR Code image Cannot be Empty</Typography>
              )}
              {previewImage && (
                <BackdropImage
                  open={previewBackdrop}
                  onOpen={() => setPreviewBackdrop(true)}
                  onClose={() => setPreviewBackdrop(false)}
                >
                  <img
                    src={previewImage}
                    alt=''
                    style={{ height: '300px', width: '505px', objectFit: 'contain' }}
                  />
                </BackdropImage>
              )}
            </div>
            <TextField
              required
              id='file'
              label='QR Code Image'
              type='file'
              placeholder='QR code Image'
              InputLabelProps={{ shrink: true }}
              inputProps={{ accept: 'image/png, image/gif, image/jpeg', shrink: true }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const files = e && e.target && e.target.files
                if (files && files.length > 0 && !isEmpty(files)) {
                  formik.setFieldValue('file', files[0])
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  const reader: any = new FileReader()
                  reader.onload = () => {
                    setPreviewImage(reader.result)
                  }
                  reader.readAsDataURL(files[0])
                } else {
                  setPreviewImage('')
                }
              }}
              onBlur={formik.handleBlur}
              error={formik.touched && formik.touched.file && Boolean(formik.errors.file)}
              helperText={
                formik.touched && formik.touched.file && formik.errors && formik.errors.file
              }
              fullWidth
            />
          </FormikProvider>
        </Container>
      </Dialog>
      <Notification
        open={!create.isLoading && !create.isUninitialized}
        onClose={() => (create.isError ? create.reset() : location.reload())}
        isError={Boolean(create.error) && create.isError}
        message={GLOBAL.returnExceptionMessage(create.isError, create.error as ErrorProps)}
      />
    </>
  )
}

export default Create
