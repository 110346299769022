import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import moment from 'moment'
import {
  Autocomplete,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import {
  useDownloadCarInformationMutation,
  useGetCarInformationMutation,
  useGetRegistrationStatusDropdownListMutation
} from 'store/carInformation'
import Icon from 'components/icon'
import CONSTANT from 'modules/constant'
import GLOBAL from 'modules/global'
import GlobalStyle from 'modules/styles'
import type { PartialCarInformation } from 'modules/partial'
import ChangeCarSharing from './changeCarSharing/changeCarSharing'
import Detail from './detail/detail'
import DetailRegistrationStatusPending from './detailRegistrationStatusPending/detailRegistrationStatusPending'
import UpdateAccountStatus from './updateAccountStatus/updateAccountStatus'

const CarInformation = () => {
  const [getCarInformation, carInformation] = useGetCarInformationMutation()
  const [downloadCarInformation, download] = useDownloadCarInformationMutation()
  const [getRegistrationStatusDropdownList, registrationStatusDropdown] =
    useGetRegistrationStatusDropdownListMutation()

  const [filter, setFilter] = useState({
    FullName: '',
    MemberId: '',
    Vin: '',
    ModelName: '',
    CarModelYear: '',
    LicensePlateNumber: '',
    StatusName: '',
    accountStatus: '',
    ApprovalDateFormated: '',
    RejectedDateFormated: '',
    CreatedAtFormated: '',
    Notes: ''
  })

  const [payload, setPayload] = useState<PartialCarInformation>({
    start: 0,
    length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    field: CONSTANT.DEFAULT_CAR_INFORMATION_FIELD.join(','),
    search: { value: '' },
    FullName: '',
    MemberId: '',
    Vin: '',
    ModelName: '',
    CarModelYear: '',
    LicensePlateNumber: '',
    StatusName: '',
    accountStatus: '',
    ApprovalDateFormated: '',
    RejectedDateFormated: '',
    CreatedAtFormated: '',
    Notes: ''
  })

  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [drawerDetailStatusPending, setDrawerDetailStatusPending] = useState<boolean>(false)
  const [dialogChangeCarSharing, setDialogChangeCarSharing] = useState<boolean>(false)
  const [vin, setVin] = useState<string>('')
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [menu, setMenu] = useState<null | number>(null)
  const [isUpdateAccountStatus, SetIsUpdateAccountStatus] = useState<boolean>(false)

  const formatDate = (e: string) => (!isEmpty(e) ? moment(e).format('DD-MM-YYYY HH:mm') : '-')
  const onSearch = (value: string) => setPayload({ ...payload, start: 0, search: { value } })
  const onChangePage = (value: number) => setPayload({ ...payload, start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, length: value })

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const onResync = () => {
    const formData = new FormData()

    formData.append('start', payload.start.toString())
    formData.append('length', payload.length.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(payload.FullName)) formData.append('FullName', payload.FullName)
    if (!isEmpty(payload.MemberId)) formData.append('MemberId', payload.MemberId)
    if (!isEmpty(payload.Vin)) formData.append('Vin', payload.Vin)
    if (!isEmpty(payload.ModelName)) formData.append('ModelName', payload.ModelName)
    if (!isEmpty(payload.CarModelYear)) formData.append('CarModelYear', payload.CarModelYear)
    if (!isEmpty(payload.LicensePlateNumber))
      formData.append('LicensePlateNumber', payload.LicensePlateNumber)
    if (!isEmpty(payload.StatusName)) formData.append('StatusName', payload.StatusName)
    if (!isEmpty(payload.accountStatus)) formData.append('accountStatus', payload.accountStatus)
    if (!isEmpty(payload.ApprovalDateFormated))
      formData.append('ApprovalDateFormated', payload.ApprovalDateFormated)
    if (!isEmpty(payload.RejectedDateFormated))
      formData.append('RejectedDateFormated', payload.RejectedDateFormated)
    if (!isEmpty(payload.CreatedAtFormated))
      formData.append('CreatedAtFormated', payload.CreatedAtFormated)
    if (!isEmpty(payload.Notes)) formData.append('Notes', payload.Notes)
    getCarInformation(formData)
  }

  const onFilter = (state: boolean) => setDialogFilter(state)

  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('start', String(0))
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.search)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(payload.FullName)) formData.append('FullName', payload.FullName)
    if (!isEmpty(payload.MemberId)) formData.append('MemberId', payload.MemberId)
    if (!isEmpty(payload.Vin)) formData.append('Vin', payload.Vin)
    if (!isEmpty(payload.ModelName)) formData.append('ModelName', payload.ModelName)
    if (!isEmpty(payload.CarModelYear)) formData.append('CarModelYear', payload.CarModelYear)
    if (!isEmpty(payload.LicensePlateNumber))
      formData.append('LicensePlateNumber', payload.LicensePlateNumber)
    if (!isEmpty(payload.StatusName)) formData.append('StatusName', payload.StatusName)
    if (!isEmpty(payload.accountStatus)) formData.append('accountStatus', payload.accountStatus)
    if (!isEmpty(payload.ApprovalDateFormated))
      formData.append('ApprovalDateFormated', payload.ApprovalDateFormated)
    if (!isEmpty(payload.RejectedDateFormated))
      formData.append('RejectedDateFormated', payload.RejectedDateFormated)
    if (!isEmpty(payload.CreatedAtFormated))
      formData.append('CreatedAtFormated', payload.CreatedAtFormated)
    if (!isEmpty(payload.Notes)) formData.append('Notes', payload.Notes)
    if (type && !isEmpty(type)) formData.append('Download-Type', type)

    downloadCarInformation(formData)
  }

  const tableHeadTitles = [
    'Full Name',
    'Member ID',
    'VIN',
    'Car Model',
    'Model Year',
    'Plate Number',
    'Registration Status',
    'Account Status',
    'Approval Date',
    'Rejected Date',
    'Created Date',
    'Notes'
  ]

  const openAnchor = Boolean(anchor)

  const totalPage = carInformation.data && GLOBAL.tableTotalPage(carInformation.data.recordsTotal)

  const numbers = GLOBAL.tableNumber(payload.start)

  const onDetail = (status: number) => {
    if (status === 5) {
      setDrawerDetailStatusPending(true)
    } else {
      setDrawerDetail(true)
    }
  }

  const onUpdateAccountStatus = (status: string) => {
    if (status === 'Approved') {
      SetIsUpdateAccountStatus(true)
    } else {
      SetIsUpdateAccountStatus(false)
    }
  }

  useEffect(() => {
    onResync()
  }, [payload])


  return (
    <>
      <Content
        title='Car Information'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={carInformation.data && carInformation.data.recordsTotal}
        page={payload.start + 1}
        isLoading={carInformation.isLoading}
        useDownloadDropdown
      >
        {carInformation.isSuccess &&
          carInformation.data &&
          carInformation.data.data?.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.id}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.id}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.id}`}
                  aria-labelledby={`button-${index}-${table.id}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => onDetail(table.statusId))}>
                    View Detail
                  </MenuItem>
                  {table.statusId === 3 && (
                    <MenuItem
                      onClick={() => onRemoveAnchor(() => onUpdateAccountStatus(table.statusName))}
                    >
                      Update Account Status
                    </MenuItem>
                  )}
                </Menu>
                <Detail
                  id={table.registrationCode}
                  vin={table.vin}
                  open={drawerDetail && menu == index}
                  onClose={() => setDrawerDetail(false)}
                  onPress={() => {
                    setVin(table.vin)
                    setDialogChangeCarSharing(true)
                  }}
                />
                <DetailRegistrationStatusPending
                  id={table.registrationCode}
                  vin={table.vin}
                  open={drawerDetailStatusPending && menu == index}
                  onClose={() => setDrawerDetailStatusPending(false)}
                />
                {/* {isUpdateAccountStatus && ( */}
                <UpdateAccountStatus
                  id={table.registrationCode}
                  vin={table.vin}
                  vehicleId={table.vehicleId}
                  accountStatus={table.accountStatus}
                  open={isUpdateAccountStatus && menu == index}
                  onClose={() => SetIsUpdateAccountStatus(false)}
                />
                {/* )} */}
              </TableCell>
              <TableCell>{table.fullName || '-'}</TableCell>
              <TableCell>{table.memberId || '-'}</TableCell>
              <TableCell>{table.vin || '-'}</TableCell>
              <TableCell>{table.modelName || '-'}</TableCell>
              <TableCell>{table.carModelYear || '-'}</TableCell>
              <TableCell>{table.licensePlateNumber || '-'}</TableCell>
              <TableCell>{table.statusName || '-'}</TableCell>
              <TableCell>{table.accountStatus || '-'}</TableCell>
              <TableCell>{formatDate(table.approvalDate) || '-'}</TableCell>
              <TableCell>{formatDate(table.rejectedDate) || '-'}</TableCell>
              <TableCell>{formatDate(table.createdAt) || '-'}</TableCell>
              <TableCell>{table.notes || '-'}</TableCell>
            </TableRow>
          ))}
      </Content>

      <Filter
        open={dialogFilter}
        title='Filter'
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        onSubmit={() => {
          setPayload({
            ...payload,
            ...filter,
            start: 0
          })
          onFilter(false)
        }}
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
        onReset={() => {
          setFilter({
            FullName: '',
            MemberId: '',
            Vin: '',
            ModelName: '',
            CarModelYear: '',
            LicensePlateNumber: '',
            StatusName: '',
            accountStatus: '',
            ApprovalDateFormated: '',
            RejectedDateFormated: '',
            CreatedAtFormated: '',
            Notes: ''
          })
          setPayload({
            ...payload,
            start: 0,
            search: { value: '' },
            FullName: '',
            MemberId: '',
            Vin: '',
            ModelName: '',
            CarModelYear: '',
            LicensePlateNumber: '',
            StatusName: '',
            accountStatus: '',
            ApprovalDateFormated: '',
            RejectedDateFormated: '',
            CreatedAtFormated: '',
            Notes: ''
          })
          onFilter(false)
        }}
      >
        <TextField
          id='FullName'
          variant='outlined'
          label='Full Name'
          value={filter.FullName}
          onChange={(event) => setFilter({ ...filter, FullName: event.target.value })}
          fullWidth
        />
        <TextField
          id='MemberId'
          variant='outlined'
          label='Member Id'
          value={filter.MemberId}
          onChange={(event) => setFilter({ ...filter, MemberId: event.target.value })}
          fullWidth
        />
        <TextField
          id='Vin'
          variant='outlined'
          label='VIN'
          value={filter.Vin}
          onChange={(event) => setFilter({ ...filter, Vin: event.target.value })}
          fullWidth
        />
        <TextField
          id='ModelName'
          variant='outlined'
          label='Car Model'
          value={filter.ModelName}
          onChange={(event) => setFilter({ ...filter, ModelName: event.target.value })}
          fullWidth
        />
        <TextField
          id='CarModelYear'
          variant='outlined'
          label='Model Year'
          type='number'
          value={filter.CarModelYear}
          onChange={(event) => setFilter({ ...filter, CarModelYear: event.target.value })}
          fullWidth
        />
        <TextField
          id='LicensePlateNumber'
          variant='outlined'
          label='Plate number'
          value={filter.LicensePlateNumber}
          onChange={(event) => setFilter({ ...filter, LicensePlateNumber: event.target.value })}
          fullWidth
        />
        <Autocomplete
          options={(registrationStatusDropdown && registrationStatusDropdown.data) || []}
          getOptionLabel={(list) => list.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.name == value.name : false
          }
          onOpen={() => getRegistrationStatusDropdownList(CONSTANT.DEFAULT_DROPDOWN_PAYLOAD)}
          // onChange={(_, chargePoint) =>
          //   chargePoint &&
          //   setFilter({
          //     ...filter,
          //     StatusName: String(chargePoint.name)
          //   })
          // }
          onInputChange={(_, StatusName) => setFilter({ ...filter, StatusName })}
          value={
            (registrationStatusDropdown &&
              registrationStatusDropdown.data &&
              registrationStatusDropdown.data.find((e) => String(e.name) == filter.StatusName)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.name}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='StatusName'
              label='Registration Status'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {registrationStatusDropdown.isLoading && (
                      <CircularProgress color='inherit' size={20} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <FormControl fullWidth>
          <InputLabel id='select-label'>Account Status</InputLabel>
          <Select
            labelId='select-label'
            id='id-select-label'
            value={filter.accountStatus}
            label='Account Status'
            onChange={(event) => setFilter({ ...filter, accountStatus: event.target.value })}
          >
            <MenuItem value='Enabled'>Enabled</MenuItem>
            <MenuItem value='Disabled'>Disabled</MenuItem>
          </Select>
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
          <DatePicker
            value={filter.ApprovalDateFormated ? dayjs(filter.ApprovalDateFormated) : null}
            onChange={(date: Dayjs | null) =>
              date && setFilter({ ...filter, ApprovalDateFormated: date.format('YYYY-MM-DD') })
            }
            label='Approval Date'
            format='DD-MM-YYYY'
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
          <DatePicker
            value={filter.RejectedDateFormated ? dayjs(filter.RejectedDateFormated) : null}
            onChange={(date: Dayjs | null) =>
              date && setFilter({ ...filter, RejectedDateFormated: date.format('YYYY-MM-DD') })
            }
            label='Reject Date'
            format='DD-MM-YYYY'
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
          <DatePicker
            value={filter.CreatedAtFormated ? dayjs(filter.CreatedAtFormated) : null}
            onChange={(date: Dayjs | null) =>
              date && setFilter({ ...filter, CreatedAtFormated: date.format('YYYY-MM-DD') })
            }
            label='Created Date'
            format='DD-MM-YYYY'
          />
        </LocalizationProvider>
        <TextField
          id='Notes'
          variant='outlined'
          label='Notes'
          value={filter.Notes}
          onChange={(event) => setFilter({ ...filter, Notes: event.target.value })}
          fullWidth
        />
      </Filter>

      {dialogChangeCarSharing && (
        <ChangeCarSharing
          id={vin}
          open={dialogChangeCarSharing}
          onClose={() => setDialogChangeCarSharing(false)}
        />
      )}
    </>
  )
}

export default CarInformation
