import { Container, TextField } from '@mui/material'
import Drawer from 'components/drawer/detail'
import Loading from 'components/loading/loading'
import type { DetailStateProps } from 'modules/types'
import { useGetVoucherSubCategoryDetailQuery } from 'store/voucherCategory'
import DrawerStyle from './style'

const Detail = ({ open, id, onClose }: DetailStateProps & { id: string | number }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const { data, isFetching, isLoading } = useGetVoucherSubCategoryDetailQuery(id)

  return (
    <>
      <Drawer open={open} title='Detail Voucher Sub Category' onClose={onClose}>
        <Container {...DrawerStyle.Container}>
          {(isLoading || isFetching) && !data && <Loading />}
          {data && (
            <>
              <TextField
                id='key'
                variant='outlined'
                label='Voucher Sub Category Name EN-US'
                value={data && data.nameEn}
                inputProps={{ readOnly: true }}
                fullWidth
              />
              <TextField
                id='key'
                variant='outlined'
                label='Voucher Sub Category Name ID'
                value={data && data.nameId}
                inputProps={{ readOnly: true }}
                fullWidth
              />
              <TextField
                id='key'
                variant='outlined'
                label='Category'
                value={data && data.categoryName}
                inputProps={{ readOnly: true }}
                fullWidth
              />
              <TextField
                id='key'
                variant='outlined'
                label='Description'
                value={data && data.description}
                inputProps={{ readOnly: true }}
                fullWidth
              />
              <TextField
                id='key'
                variant='outlined'
                label='Sequence'
                value={data && data.seq}
                inputProps={{ readOnly: true }}
                fullWidth
              />
            </>
          )}
        </Container>
      </Drawer>
    </>
  )
}

export default Detail
