import type { ContainerProps } from '@mui/material'

class LoadingStyle {
  static Container: ContainerProps = {
    maxWidth: false,
    disableGutters: true,
    sx: {
      display: 'flex',
      width: '100%',
      padding: 1,
      alignItems: 'center',
      justifyContent: 'center'
    }
  }
}
export default LoadingStyle
