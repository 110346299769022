import { FormikProvider, useFormik } from 'formik'
import { Autocomplete, CircularProgress, Container, TextField } from '@mui/material'
import * as yup from 'yup'
import Dialog from 'components/dialog/dialog'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { PayloadVoucherSubCategoryProps } from 'modules/partial'
import type { DetailStateCategoryProps, ErrorProps } from 'modules/types'
import {
  useCreateVoucherSubCategoryMutation,
  useGetVoucherCategoryDropdownMutation
} from 'store/voucherCategory'
import GlobalStyle from 'modules/styles'
import DialogStyle from './style'

const Create = ({ open, onClose, paramData }: DetailStateCategoryProps) => {
  const [createVoucherSubCategory, create] = useCreateVoucherSubCategoryMutation()
  const [getVoucherCategoryDropdown, dropdownCategory] = useGetVoucherCategoryDropdownMutation()

  const scheme = yup.object<PayloadVoucherSubCategoryProps>({
    nameEn: yup.string().required('Voucher Category Name English is required'),
    nameId: yup.string().required('Voucher Category Name ID is required'),
    Description: yup.string().required('Voucher Category Description is required'),
    Seq: yup.number().required('Voucher Category Sequence is required'),
    categoryId: yup.number().required(' Category Name is required')
  })

  const formik = useFormik<PayloadVoucherSubCategoryProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      nameEn: '',
      nameId: '',
      Description: '',
      Seq: '',
      categoryId: paramData.id || ''
    },
    onSubmit: (values: PayloadVoucherSubCategoryProps) => handleSubmit(values)
  })

  const handleSubmit = (e: PayloadVoucherSubCategoryProps) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const payload: any = {
      nameEn: e.nameEn,
      nameId: e.nameId,
      Description: e.Description,
      Seq: e.Seq,
      CategoryId: e.categoryId
    }

    createVoucherSubCategory(payload).then()
  }

  return (
    <>
      <Dialog
        open={open}
        title='Add Voucher Sub Category'
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={create.isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          <FormikProvider value={formik}>
            <TextField
              id='nameEn'
              variant='outlined'
              label='Voucher Sub Category Name EN-US'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched && formik.touched.nameEn && Boolean(formik.errors.nameEn)}
              helperText={
                formik.touched && formik.touched.nameEn && formik.errors && formik.errors.nameEn
              }
              fullWidth
            />
            <TextField
              id='nameId'
              variant='outlined'
              label='Voucher Sub Category Name ID'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched && formik.touched.nameId && Boolean(formik.errors.nameId)}
              helperText={
                formik.touched && formik.touched.nameId && formik.errors && formik.errors.nameId
              }
              fullWidth
            />

            <Autocomplete
              options={dropdownCategory.data || []}
              defaultValue={{ name: paramData.name, id: paramData.id, key: '', language: '' }}
              getOptionLabel={(list) => list.name}
              isOptionEqualToValue={(option, value) =>
                option && value ? option.id == value.id : false
              }
              onOpen={() => getVoucherCategoryDropdown()}
              onChange={(_, id) => formik.setFieldValue('categoryId', id && id.id)}
              ListboxProps={GlobalStyle.ListBox}
              renderOption={(props, item) => (
                <li {...props} key={item.id}>
                  {item.name}
                </li>
              )}
              readOnly
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='categoryId'
                  label='Select A Category'
                  error={
                    formik.touched && formik.touched.categoryId && Boolean(formik.errors.categoryId)
                  }
                  helperText={
                    formik.touched &&
                    formik.touched.categoryId &&
                    formik.errors &&
                    formik.errors.categoryId
                  }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {dropdownCategory.isLoading && (
                          <CircularProgress color='inherit' size={20} />
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
            />

            <TextField
              id='Description'
              variant='outlined'
              label='Description'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched && formik.touched.Description && Boolean(formik.errors.Description)
              }
              helperText={
                formik.touched &&
                formik.touched.Description &&
                formik.errors &&
                formik.errors.Description
              }
              fullWidth
            />

            <TextField
              id='Seq'
              variant='outlined'
              label='Sequence'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched && formik.touched.Seq && Boolean(formik.errors.Seq)}
              helperText={
                formik.touched && formik.touched.Seq && formik.errors && formik.errors.Seq
              }
              fullWidth
            />
          </FormikProvider>
        </Container>
      </Dialog>

      <Notification
        open={!create.isLoading && !create.isUninitialized}
        onClose={() => (create.isError ? create.reset() : location.reload())}
        isError={Boolean(create.error) && create.isError}
        message={GLOBAL.returnExceptionMessage(create.isError, create.error as ErrorProps)}
      />
    </>
  )
}

export default Create
