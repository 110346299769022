import CONSTANT from 'modules/constant'
import type { EVTopupProps, EVTopupStatusDropdownProps, PaginationProps } from 'modules/types'
import { API, download, headers } from './api'

const evTopupAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getTopupList: builder.mutation<PaginationProps<EVTopupProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_EV_TOPUP_LIST,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    downloadTopupList: builder.mutation<PaginationProps<EVTopupProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_EV_TOPUP_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true,
        responseHandler: download
      })
    }),
    getTopupDetail: builder.query<EVTopupProps, string>({
      query: (query) => ({
        url: CONSTANT.URL_EV_TOPUP_DETAIL + query,
        method: 'GET',
        headers: headers()
      })
    }),
    getTopupStatusDropdown: builder.mutation<EVTopupStatusDropdownProps[], void>({
      query: () => ({
        url: CONSTANT.URL_EV_TOPUP_STATUS_DROPDOWN,
        method: 'GET',
        headers: headers()
      })
    })
  })
})

export const {
  useGetTopupListMutation,
  useDownloadTopupListMutation,
  useGetTopupDetailQuery,
  useGetTopupStatusDropdownMutation
} = evTopupAPI
