import CONSTANT from 'modules/constant'
import type {
  BookingEventDropdownListProps,
  PaginationProps,
  ResultProps,
  ServiceBookingEventTransactionProps,
  TransactionDetailEventProps,
  TransactionDetailLaborProps
} from 'modules/types'
import type { PartialDropdownListProps, PartialUpdateAccountStatusProps } from 'modules/partial'
import { API, download, headers } from './api'


const serviceBookingEventTransaction = API.injectEndpoints({
  endpoints: (builder) => ({
    getListTransactionEvent: builder.mutation<
      PaginationProps<ServiceBookingEventTransactionProps>,
      FormData
    >({
      query: (body) => ({
        url: CONSTANT.URL_SERVICE_BOOKING_EVENT_TRANSACTION_LIST,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    getDealerDropdownList: builder.mutation<BookingEventDropdownListProps[], PartialDropdownListProps>({
      query: () => ({
        url: CONSTANT.URL_SERVICE_BOOKING_EVENT_TRANSACTION_DROPDOWN_DEALER,
        method: 'GET',
        headers: headers()
      })
    }),
    getBookingStatusDropdownList: builder.mutation<BookingEventDropdownListProps[], PartialDropdownListProps>({
      query: () => ({
        url: CONSTANT.URL_SERVICE_BOOKING_EVENT_TRANSACTION_DROPDOWN_BOOKING_STATUS,
        method: 'GET',
        headers: headers()
      })
    }),
    getDMSRepairStatusDropdownList: builder.mutation<BookingEventDropdownListProps[], PartialDropdownListProps>({
      query: () => ({
        url: CONSTANT.URL_SERVICE_BOOKING_EVENT_TRANSACTION_DROPDOWN_DMS_REPAIR_STATUS,
        method: 'GET',
        headers: headers()
      })
    }),
    getAppointmentConfirmationDropdownList: builder.mutation<BookingEventDropdownListProps[], PartialDropdownListProps>({
      query: () => ({
        url: CONSTANT.URL_SERVICE_BOOKING_EVENT_TRANSACTION_DROPDOWN_APPPOINTMENT_CONFIRMATION,
        method: 'GET',
        headers: headers()
      })
    }),
    downloadTransactionEvent: builder.mutation<
      PaginationProps<ServiceBookingEventTransactionProps>,
      FormData
    >({
      query: (body) => ({
        url: CONSTANT.URL_SERVICE_BOOKING_EVENT_TRANSACTION_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        responseHandler: download,
        body: body,
        formData: true
      })
    }),
    getDetailTransactionEvent: builder.query<TransactionDetailEventProps, number>({
      query: (id) => ({
        url: CONSTANT.URL_SERVICE_BOOKING_EVENT_TRANSACTION_DETAIL + id + '/Detail',
        method: 'GET',
        headers: headers()
      })
    }),
    UpdateAppointmentConfirmation: builder.mutation<ResultProps, PartialUpdateAccountStatusProps>({
      query: (body) => ({
        url: CONSTANT.URL_SERVICE_BOOKING_EVENT_TRANSACTION_UPDATE_APPOINTMENT + '/AppointmentConfirmation',
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    getDetailTransactionLaborAcc: builder.query<TransactionDetailLaborProps, number>({
      query: (id) => ({
        url: CONSTANT.URL_SERVICE_BOOKING_EVENT_TRANSACTION_DETAIL_LABOR + id,
        method: 'GET',
        headers: headers()
      })
    }),
    getEventNameDropdownList: builder.mutation<BookingEventDropdownListProps[], PartialDropdownListProps>({
      query: () => ({
        url: CONSTANT.URL_SERVICE_BOOKING_EVENT_NAMES,
        method: 'GET',
        headers: headers()
      })
    }),
  })
})

export const {
  useGetListTransactionEventMutation,
  useGetDealerDropdownListMutation,
  useGetBookingStatusDropdownListMutation,
  useGetDMSRepairStatusDropdownListMutation,
  useGetAppointmentConfirmationDropdownListMutation,
  useDownloadTransactionEventMutation,
  useUpdateAppointmentConfirmationMutation,
  useGetDetailTransactionLaborAccQuery,
  useGetEventNameDropdownListMutation,

  useGetDetailTransactionEventQuery
} = serviceBookingEventTransaction

