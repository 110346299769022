import { useState } from 'react'
import { isEmpty } from 'lodash'
import * as yup from 'yup'
import {
  Autocomplete,
  Box,
  CircularProgress,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import ReactQuill from 'react-quill'
import { encode } from 'html-entities'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import Dialog from 'components/dialog/dialog'
import type {
  PartialVoucherCreateDataProps,
  PartialVoucherCreateDataRequestProps,
  PartialVoucherCreateProps
} from 'modules/partial'
import type { CallbackHandler, DetailStateProps, ErrorProps } from 'modules/types'
import FORMIK from 'modules/formik'
import {
  useGetVoucherCategoryDropdownMutation,
  useGetVoucherSubCategoryDropdownMutation
} from 'store/voucherCategory'
import GlobalStyle from 'modules/styles'
import { useGetTokenPartnerDropdownMutation } from 'store/token'
import { useGetTokenCategoryDropdownMutation } from 'store/tokenRequest'
import GLOBAL from 'modules/global'
import { useCreateListVoucherMutation } from 'store/voucher'
import Notification from 'components/notification'
import BackdropImage from 'components/backdropImage/backdropImage'
import DialogStyle from './style'

const Create = ({ open, onClose }: DetailStateProps) => {
  if (!open) return <></>

  const [getVoucherCategoryDropdown, dropdownCategory] = useGetVoucherCategoryDropdownMutation()
  const [getVoucherSubCategoryDropdown, dropdownSubCategory] =
    useGetVoucherSubCategoryDropdownMutation()
  const [getTokenPartnerDropdown, partnerDropdown] = useGetTokenPartnerDropdownMutation()
  const [getTokenCategoryDropdown, tokenCategoryDropdown] = useGetTokenCategoryDropdownMutation()
  const [createVoucher, create] = useCreateListVoucherMutation()

  const [previewImage, setPreviewImage] = useState<string | ArrayBuffer | null>(null)
  const [previewBanner, setPreviewBanner] = useState<string | ArrayBuffer | null>(null)
  const [previewBackdrop, setPreviewBackdrop] = useState<boolean>(false)
  const [bannerBackdrop, setBannerBackdrop] = useState<boolean>(false)

  const scheme = yup.object<PartialVoucherCreateProps>({
    image: yup.mixed().required('Voucher Image is required'),
    banner: yup.mixed().required('Voucher Image Banner is required'),
    data: yup.object<PartialVoucherCreateDataProps>({
      nameEn: yup.string().required('Name in English is required'),
      nameId: yup.string().required('Name in Indonesia is required'),
      voucherType: yup.string().required('Voucher Type is required'),
      bookingType: yup.string().when('voucherType', {
        is: (val: string) => val === 'Booking',
        then: (schema) => schema.required('Booking Type is required')
      }),
      voucherCategoryId: yup.number().required('Voucher Category ID is required'),
      voucherSubCategoryId: yup.number().required('Voucher Sub Category ID is required'),
      voucherTokenCategoryId: yup.number().required('Voucher Token Category ID is required'),
      partnerId: yup.number().required('Partner ID is required'),
      applicableAreaMode: yup.string().required('Applicable Area Mode is required'),
      termsAndConditionsEn: yup.string().required('Terms and Condition in English is required'),
      termsAndConditionsId: yup.string().required('Terms and Condition in Indonesia is required'),
      informationEn: yup.string().required('Information in English is required'),
      informationId: yup.string().required('Information in Indonesia is required'),
      generateType: yup.string().required('Token Source is required'),
      description: yup.string(),
      payloadGenerateRequest: yup
        .object<PartialVoucherCreateDataRequestProps>()
        .when('generateType', {
          is: (value: string) => value === 'Request',
          then: () =>
            yup.object({
              requestName: yup.string().required('Request Name is required'),
              quantity: yup.string().required('Quantity is required'),
              validFrom: yup.string().required('Valid From is required'),
              validUntil: yup.string().required('Valid Until is required')
            })
        })
    }),
    importToken: yup.mixed()
  })

  const initialValues: PartialVoucherCreateProps = {
    image: {} as File,
    banner: {} as File,
    importToken: {} as File,
    data: {
      nameEn: '',
      nameId: '',
      voucherType: '',
      bookingType: '',
      voucherCategoryId: null,
      voucherSubCategoryId: null,
      voucherTokenCategoryId: null,
      partnerId: null,
      applicableAreaMode: 'All Branches',
      termsAndConditionsEn: '',
      termsAndConditionsId: '',
      informationEn: '',
      informationId: '',
      generateType: '',
      visibility: 'Visible',
      description: '',
      payloadGenerateRequest: {
        requestName: '',
        quantity: '',
        validFrom: '',
        validUntil: ''
      }
    }
  }

  const formik = FORMIK.useFormFormik<PartialVoucherCreateProps>(
    scheme,
    initialValues,
    (values) => {
      const formData = new FormData()

      const data = {
        ...values.data,
        generateType: values.data.generateType === 'None' ? null : values.data.generateType,
        termsAndConditionsEn: encode(values.data.termsAndConditionsEn),
        termsAndConditionsId: encode(values.data.termsAndConditionsId),
        informationEn: encode(values.data.informationEn),
        informationId: encode(values.data.informationId)
      }

      if (values.image) formData.append('image', values.image)
      if (values.banner) formData.append('banner', values.banner)
      if (values.importToken) formData.append('importToken', values.importToken)

      formData.append('data', JSON.stringify(data))

      createVoucher(formData)
    }
  )

  const fileOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: string,
    callback: CallbackHandler<string | ArrayBuffer | null>
  ) => {
    const files = e && e.target && e.target.files
    const notEmpty = files && files.length > 0 && !isEmpty(files)

    if (notEmpty) {
      const reader = new FileReader()

      reader.onload = () => callback(reader.result)
      reader.readAsDataURL(files[0])

      formik.setFieldValue(field, files[0])
      return
    }

    formik.setFieldValue(field, {} as File)
    return callback(null)
  }

  const error = formik.errors && (formik.errors.data as unknown as PartialVoucherCreateDataProps)
  const touched = formik.touched && (formik.touched as PartialVoucherCreateDataProps)
  const requestToken = formik.values.data.payloadGenerateRequest

  const disabled =
    formik.values.data.generateType === 'Import'
      ? !formik.isValid ||
        isEmpty(previewImage) ||
        isEmpty(previewBanner) ||
        !('size' in formik.values.importToken)
      : !formik.isValid || isEmpty(previewImage) || isEmpty(previewBanner)

  return (
    <>
      <Dialog
        title='Create New Voucher1'
        open={open}
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={create.isLoading}
        isDisabled={disabled}
        maxWidth='md'
      >
        <Container {...DialogStyle.Container}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Box sx={{ display: 'flex', flex: 1, gap: 2, flexDirection: 'column' }}>
              <TextField
                id='nameEn'
                variant='outlined'
                label='Voucher EN-US Name *'
                value={formik.values.data.nameEn}
                onChange={(event) => formik.setFieldValue('data.nameEn', event.target.value)}
                onBlur={formik.handleBlur}
                error={touched && Boolean(touched.nameEn) && error && Boolean(error.nameEn)}
                helperText={touched && touched.nameEn && error && error.nameEn}
                fullWidth
              />
              <TextField
                id='nameId'
                variant='outlined'
                label='Voucher ID Name *'
                value={formik.values.data.nameId}
                onChange={(event) => formik.setFieldValue('data.nameId', event.target.value)}
                onBlur={formik.handleBlur}
                error={touched && Boolean(touched.nameId) && error && Boolean(error.nameId)}
                helperText={touched && touched.nameId && error && error.nameId}
                fullWidth
              />
              <Autocomplete
                options={(dropdownCategory && dropdownCategory.data) || []}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.id == value.id : false
                }
                onOpen={() => getVoucherCategoryDropdown()}
                onChange={(_, voucherCategory) => {
                  dropdownSubCategory.reset()
                  formik.setFieldValue(
                    'data.voucherCategoryId',
                    String((voucherCategory && voucherCategory.id) || null)
                  )
                }}
                value={
                  (dropdownCategory &&
                    dropdownCategory.data &&
                    dropdownCategory.data.find(
                      (e) => e.id == formik.values.data.voucherCategoryId
                    )) ||
                  null
                }
                ListboxProps={GlobalStyle.ListBox}
                renderOption={(props, item) => (
                  <li {...props} key={item.id}>
                    {item.name}
                  </li>
                )}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='voucherCategoryId'
                    label='Category ID *'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {dropdownCategory.isLoading && (
                            <CircularProgress color='inherit' size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
              />
              <Autocomplete
                options={(dropdownSubCategory && dropdownSubCategory.data) || []}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.id == value.id : false
                }
                onOpen={() =>
                  formik.values.data.voucherCategoryId &&
                  getVoucherSubCategoryDropdown({ id: formik.values.data.voucherCategoryId })
                }
                onChange={(_, voucherCategory) =>
                  formik.setFieldValue(
                    'data.voucherSubCategoryId',
                    String((voucherCategory && voucherCategory.id) || null)
                  )
                }
                value={
                  (dropdownSubCategory &&
                    dropdownSubCategory.data &&
                    dropdownSubCategory.data.find(
                      (e) => e.id == formik.values.data.voucherSubCategoryId
                    )) ||
                  null
                }
                disabled={
                  formik.values.data.voucherCategoryId == null ||
                  formik.values.data.voucherCategoryId <= 0
                }
                ListboxProps={GlobalStyle.ListBox}
                renderOption={(props, item) => (
                  <li {...props} key={item.id}>
                    {item.name}
                  </li>
                )}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='voucherSubCategoryId'
                    label='Voucher Sub Category ID *'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {dropdownSubCategory.isLoading && (
                            <CircularProgress color='inherit' size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
              />
              <Autocomplete
                options={(partnerDropdown && partnerDropdown.data) || []}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.id == value.id : false
                }
                onOpen={() => getTokenPartnerDropdown()}
                onChange={(_, partner) =>
                  formik.setFieldValue('data.partnerId', String((partner && partner.id) || null))
                }
                value={
                  (partnerDropdown &&
                    partnerDropdown.data &&
                    partnerDropdown.data.find((e) => e.id == formik.values.data.partnerId)) ||
                  null
                }
                ListboxProps={GlobalStyle.ListBox}
                renderOption={(props, item) => (
                  <li {...props} key={item.id}>
                    {item.name}
                  </li>
                )}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='partnerId'
                    label='Partner *'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {partnerDropdown.isLoading && (
                            <CircularProgress color='inherit' size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
              />
              <Autocomplete
                options={(tokenCategoryDropdown && tokenCategoryDropdown.data) || []}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.id == value.id : false
                }
                onOpen={() => getTokenCategoryDropdown()}
                onChange={(_, tokenCategory) =>
                  formik.setFieldValue(
                    'data.voucherTokenCategoryId',
                    String((tokenCategory && tokenCategory.id) || null)
                  )
                }
                value={
                  (tokenCategoryDropdown &&
                    tokenCategoryDropdown.data &&
                    tokenCategoryDropdown.data.find(
                      (e) => e.id == formik.values.data.voucherTokenCategoryId
                    )) ||
                  null
                }
                ListboxProps={GlobalStyle.ListBox}
                renderOption={(props, item) => (
                  <li {...props} key={item.id}>
                    {item.name}
                  </li>
                )}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='tokenCategoryId'
                    label='Token Category *'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {tokenCategoryDropdown.isLoading && (
                            <CircularProgress color='inherit' size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
              />
              <Grid item xs={12}>
                <Typography {...DialogStyle.Title}>Description ID *</Typography>
                <ReactQuill
                  theme='snow'
                  value={formik.values.data.informationId}
                  onChange={(e) => formik.setFieldValue('data.informationId', GLOBAL.emptyQuill(e))}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography {...DialogStyle.Title}>Description EN-US *</Typography>
                <ReactQuill
                  theme='snow'
                  value={formik.values.data.informationEn}
                  onChange={(e) => formik.setFieldValue('data.informationEn', GLOBAL.emptyQuill(e))}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography {...DialogStyle.Title}>Terms and Condition ID *</Typography>
                <ReactQuill
                  theme='snow'
                  value={formik.values.data.termsAndConditionsId}
                  onChange={(e) =>
                    formik.setFieldValue('data.termsAndConditionsId', GLOBAL.emptyQuill(e))
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Typography {...DialogStyle.Title}>Terms and Condition EN-US *</Typography>
                <ReactQuill
                  theme='snow'
                  value={formik.values.data.termsAndConditionsEn}
                  onChange={(e) =>
                    formik.setFieldValue('data.termsAndConditionsEn', GLOBAL.emptyQuill(e))
                  }
                />
              </Grid>
            </Box>
            <Box sx={{ display: 'flex', flex: 1, gap: 2, flexDirection: 'column' }}>
              <FormControl fullWidth>
                <InputLabel id='voucherType'>Voucher Type *</InputLabel>
                <Select
                  labelId='voucherType'
                  id='voucherType'
                  variant='outlined'
                  label='Voucher Type *'
                  value={formik.values.data.voucherType}
                  onChange={(event) => {
                    formik.setFieldValue('data.bookingType', '')
                    formik.setFieldValue('data.voucherType', event.target.value)
                  }}
                  error={
                    touched && Boolean(touched.voucherType) && error && Boolean(error.voucherType)
                  }
                >
                  <MenuItem value='Voucher'>Voucher</MenuItem>
                  <MenuItem value='Booking'>Booking</MenuItem>
                </Select>
                {touched && touched.voucherType && error && (
                  <FormHelperText error id='voucherType'>
                    {error.voucherType}
                  </FormHelperText>
                )}
              </FormControl>
              {formik.values.data.voucherType == 'Booking' && (
                <FormControl fullWidth>
                  <InputLabel id='bookingType'>Booking Type *</InputLabel>
                  <Select
                    labelId='bookingType'
                    id='bookingType'
                    value={formik.values.data.bookingType}
                    label='Booking Type *'
                    onChange={(event) =>
                      formik.setFieldValue('data.bookingType', event.target.value)
                    }
                    error={
                      touched && Boolean(touched.bookingType) && error && Boolean(error.bookingType)
                    }
                  >
                    <MenuItem value='Lounge & Airport VIP Assistance'>
                      Lounge & Airport VIP Assistance
                    </MenuItem>
                    <MenuItem value='Airport VIP Assistance'>Airport VIP Assistance</MenuItem>
                    <MenuItem value='Airport Parking'>Airport Parking</MenuItem>
                    <MenuItem value='City Lounge'>City Lounge</MenuItem>
                    <MenuItem value='Mall Parking'>Mall Parking</MenuItem>
                  </Select>
                  {touched && touched.bookingType && error && (
                    <FormHelperText error id='bookingType'>
                      {error.bookingType}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
              {/* <FormControl fullWidth>
                <InputLabel id='applicableAreaMode'>Applicable Area Mode *</InputLabel>
                <Select
                  labelId='applicableAreaMode'
                  id='applicableAreaMode'
                  variant='outlined'
                  label='Applicable Area Mode *'
                  value={formik.values.data.applicableAreaMode}
                  readOnly
                  onChange={(event) =>
                    formik.setFieldValue('data.applicableAreaMode', event.target.value)
                  }
                  error={
                    touched &&
                    Boolean(touched.applicableAreaMode) &&
                    error &&
                    Boolean(error.applicableAreaMode)
                  }
                >
                  <MenuItem value='All Branches'>All Branches</MenuItem>
                  <MenuItem value='Specific Branches'>Specific Branches</MenuItem>
                </Select>
                {touched && touched.applicableAreaMode && error && (
                  <FormHelperText error id='applicableAreaMode'>
                    {error.applicableAreaMode}
                  </FormHelperText>
                )}
              </FormControl> */}
              <FormControl fullWidth>
                <InputLabel id='generateType'>Token Source *</InputLabel>
                <Select
                  labelId='generateType'
                  id='generateType'
                  variant='outlined'
                  label='Token Source *'
                  value={formik.values.data.generateType}
                  onChange={(event) => {
                    formik.setFieldValue('importToken', {} as File)
                    formik.setFieldValue('data.payloadGenerateRequest.requestName', '')
                    formik.setFieldValue('data.payloadGenerateRequest.quantity', '')
                    formik.setFieldValue('data.payloadGenerateRequest.validFrom', '')
                    formik.setFieldValue('data.payloadGenerateRequest.validUntil', '')
                    formik.setFieldValue('data.generateType', event.target.value)
                  }}
                  error={
                    touched && Boolean(touched.generateType) && error && Boolean(error.generateType)
                  }
                >
                  <MenuItem value='None'>None</MenuItem>
                  <MenuItem value='Import'>Import</MenuItem>
                  <MenuItem value='Request'>Request</MenuItem>
                </Select>
                {touched && touched.generateType && error && (
                  <FormHelperText error id='generateType'>
                    {error.generateType}
                  </FormHelperText>
                )}
              </FormControl>
              {formik.values.data.generateType === 'Import' && (
                <>
                  <Typography {...DialogStyle.Title}>Token Import</Typography>
                  <TextField
                    id='importToken'
                    label='Import Token *'
                    type='file'
                    placeholder='Import Token'
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      accept:
                        '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const files = e && e.target && e.target.files
                      const notEmpty = files && files.length > 0 && !isEmpty(files)

                      if (notEmpty) {
                        const reader = new FileReader()

                        reader.readAsDataURL(files[0])
                        formik.setFieldValue('importToken', files[0])
                        return
                      }

                      formik.setFieldValue('importToken', {} as File)
                    }}
                    onBlur={formik.handleBlur}
                    fullWidth
                  />
                </>
              )}
              {formik.values.data.generateType === 'Request' && (
                <>
                  <Typography {...DialogStyle.Title}>Token Request</Typography>
                  <TextField
                    id='data.payloadGenerateRequest.requestName'
                    variant='outlined'
                    label='Request Name *'
                    value={requestToken && requestToken.requestName}
                    onChange={(event) =>
                      formik.setFieldValue(
                        'data.payloadGenerateRequest.requestName',
                        event.target.value
                      )
                    }
                    onBlur={formik.handleBlur}
                    error={
                      touched &&
                      touched.payloadGenerateRequest &&
                      Boolean(touched.payloadGenerateRequest.requestName) &&
                      error &&
                      error.payloadGenerateRequest &&
                      Boolean(error.payloadGenerateRequest.requestName)
                    }
                    helperText={
                      touched &&
                      touched.payloadGenerateRequest &&
                      touched.payloadGenerateRequest.requestName &&
                      error &&
                      error &&
                      error.payloadGenerateRequest &&
                      error.payloadGenerateRequest.requestName
                    }
                    fullWidth
                  />
                  <TextField
                    id='data.payloadGenerateRequest.quantity'
                    variant='outlined'
                    label='Quantity *'
                    type='number'
                    value={
                      requestToken && requestToken.quantity && requestToken.quantity.toString()
                    }
                    onKeyDown={(event) => {
                      if (/^[.,\-\b]+$/.test(event.key)) event.preventDefault()
                    }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    inputProps={{ autoComplete: 'off' }}
                    error={
                      touched &&
                      touched.payloadGenerateRequest &&
                      Boolean(touched.payloadGenerateRequest.quantity) &&
                      error &&
                      error.payloadGenerateRequest &&
                      Boolean(error.payloadGenerateRequest.quantity)
                    }
                    helperText={
                      touched &&
                      touched.payloadGenerateRequest &&
                      touched.payloadGenerateRequest.quantity &&
                      error &&
                      error &&
                      error.payloadGenerateRequest &&
                      error.payloadGenerateRequest.quantity
                    }
                    fullWidth
                  />
                  <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                    <DatePicker
                      value={
                        requestToken && requestToken.validFrom
                          ? dayjs(requestToken.validFrom)
                          : undefined
                      }
                      onChange={(event: Dayjs | null) =>
                        formik.setFieldValue(
                          'data.payloadGenerateRequest.validFrom',
                          (event && event.format('YYYY-MM-DD')) || ''
                        )
                      }
                      label='Valid From *'
                      format='DD-MM-YYYY'
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                    <DatePicker
                      value={
                        requestToken && requestToken.validUntil
                          ? dayjs(requestToken.validUntil)
                          : undefined
                      }
                      minDate={
                        requestToken && requestToken.validFrom
                          ? dayjs(requestToken.validFrom)
                          : undefined
                      }
                      onChange={(event: Dayjs | null) =>
                        formik.setFieldValue(
                          'data.payloadGenerateRequest.validUntil',
                          (event && event.format('YYYY-MM-DD')) || ''
                        )
                      }
                      label='Valid Until *'
                      format='DD-MM-YYYY'
                    />
                  </LocalizationProvider>
                </>
              )}
              <TextField
                id='image'
                label='Voucher Image *'
                type='file'
                placeholder='Image'
                InputLabelProps={{ shrink: true }}
                inputProps={{ accept: 'image/png, image/gif, image/jpeg' }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  fileOnChange(e, 'image', (value) => setPreviewImage(value))
                }
                onBlur={formik.handleBlur}
                fullWidth
              />
              {previewImage && (
                <Grid item xs={12}>
                  <Typography {...DialogStyle.Title}>Voucher Image</Typography>
                  <Box {...DialogStyle.Image}>
                    <BackdropImage
                      open={previewBackdrop}
                      onOpen={() => setPreviewBackdrop(true)}
                      onClose={() => setPreviewBackdrop(false)}
                    >
                      <img
                        src={previewImage as string | undefined}
                        alt='Voucher Image'
                        width='100%'
                        height='100%'
                      />
                    </BackdropImage>
                  </Box>
                </Grid>
              )}
              <TextField
                id='banner'
                label='Voucher Image Banner *'
                type='file'
                placeholder='Image Banner'
                InputLabelProps={{ shrink: true }}
                inputProps={{ accept: 'image/png, image/gif, image/jpeg' }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  fileOnChange(e, 'banner', (value) => setPreviewBanner(value))
                }
                onBlur={formik.handleBlur}
                fullWidth
              />
              {previewBanner && (
                <Grid item xs={12}>
                  <Typography {...DialogStyle.Title}>Voucher Image Banner</Typography>
                  <Box {...DialogStyle.Image}>
                    <BackdropImage
                      open={bannerBackdrop}
                      onOpen={() => setBannerBackdrop(true)}
                      onClose={() => setBannerBackdrop(false)}
                    >
                      <img
                        src={previewBanner as string | undefined}
                        alt='Voucher Image Banner'
                        width='100%'
                        height='100%'
                      />
                    </BackdropImage>
                  </Box>
                </Grid>
              )}
            </Box>
          </Box>
        </Container>
      </Dialog>
      <Notification
        open={!create.isLoading && !create.isUninitialized}
        onClose={() => (create.isError ? create.reset() : location.reload())}
        isError={Boolean(create.error) && create.isError}
        message={GLOBAL.returnExceptionMessage(create.isError, create.error as ErrorProps)}
      />
    </>
  )
}

export default Create
