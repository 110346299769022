import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Grid,
  TextField,
  Typography
} from '@mui/material'
import { ExpandMoreOutlined } from '@mui/icons-material'
import Drawer from 'components/drawer/detail'
import Loading from 'components/loading/loading'
import { useGetRolesListDetailQuery } from 'store/roles'
import type { DetailStateProps } from 'modules/types'
import DetailStyle from './style'

const Detail = ({ open, id, onClose }: DetailStateProps & { id: number }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const { data, isFetching, isLoading } = useGetRolesListDetailQuery(id)

  const userId = (data && data.id) || '-'
  const name = (data && data.name) || '-'
  const roleType = (data && data.roleType) || '-'
  const description = (data && data.description) || '-'
  const status = (data && data.status) || '-'

  const gridDataLabel = ['ID', 'Name', 'Role Type', 'Description', 'Status']
  const gridDataValue = [userId, name, roleType, description, status]

  const createdBy = data && data.createdBy
  const createdAt = data && data.createdAt
  const modifiedBy = data && data.modifiedBy
  const modifiedAt = data && data.modifiedAt

  const gridBasicLabel = ['Created By', 'Created At', 'Modified By', 'Modified At']
  const gridBasicValue = [createdBy, createdAt, modifiedBy, modifiedAt]

  return (
    <Drawer open={open} title='Roles Detail' onClose={onClose}>
      <Container {...DetailStyle.Container}>
        {(isLoading || isFetching) && !data && <Loading />}
        {data && (
          <Grid container spacing={2}>
            {gridDataValue.map((item, index) => (
              <Grid key={index} item xs={12}>
                <TextField
                  variant='outlined'
                  label={gridDataLabel[index]}
                  value={item}
                  inputProps={{ readOnly: true }}
                  multiline
                  fullWidth
                />
              </Grid>
            ))}
          </Grid>
        )}
        {data && (
          <Accordion elevation={0} disableGutters>
            <AccordionSummary expandIcon={<ExpandMoreOutlined />} aria-controls='basic-information'>
              <Typography>Basic Information</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {gridBasicValue.map((item, index) => (
                  <Grid key={index} item xs={12}>
                    <TextField
                      variant='outlined'
                      label={gridBasicLabel[index]}
                      value={item}
                      inputProps={{ readOnly: true }}
                      fullWidth
                    />
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
      </Container>
    </Drawer>
  )
}

export default Detail
