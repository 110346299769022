import { Typography } from '@mui/material'
import DialogLoadingCancel from 'components/dialogLoadingCancel/dialogLoadingCancel'

type ModalConfirmProps = {
  open: boolean
  title?: string
  text?: string
  textButtonSubmit?: string
  loading: boolean
  onClose?: () => void
  onSubmit?: () => void
}

const ApproveRejectConfirm = ({
  title,
  open,
  text,
  textButtonSubmit,
  loading = false,
  onClose,
  onSubmit
}: ModalConfirmProps) => (
  <DialogLoadingCancel
    title={title ?? ''}
    submitText={textButtonSubmit}
    open={open}
    onCancel={onClose}
    onSubmit={onSubmit}
    loading={loading}
  >
    <Typography>Are you sure want to {text}?</Typography>
  </DialogLoadingCancel>
)

export default ApproveRejectConfirm
