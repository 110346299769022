// import { useEffect } from 'react'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import {
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material'
import Dialog from 'components/dialog/dialog'
import type { PartialPushNotifProps } from 'modules/partial'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import { useCreateBlastPushMutation } from 'store/pushnotif'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import {
  // useGetCarModelDropdownListByQuery,
  // useGetCarModelDropdownListMutation,
  // useGetYearsDropdownListByMutation
} from 'store/dropdown'
// import GlobalStyle from 'modules/styles'
import DialogStyle from './style'

const Create = ({ open, onClose }: DetailStateProps) => {
  const [createBlastPush, create] = useCreateBlastPushMutation()
  // const [getYearsDropdownListBy, years] = useGetYearsDropdownListByMutation()
  // const [getCarModelDropdownList, model] = useGetCarModelDropdownListMutation()

  // const { data: carModel, isLoading: carModelIsLoading } = useGetCarModelDropdownListByQuery()

  // useEffect(() => {
  //   getYearsDropdownListBy()
  // }, [])

  // const selectedCar =
  //   (carModel && carModel.find((car) => car.name.toLowerCase() == carName.toLowerCase())) || null

  const scheme = yup.object<PartialPushNotifProps>({
    topic: yup.string().required('Assigned To Users is required')
  })

  const formik = useFormik<PartialPushNotifProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      topic: '',
      referenceId: '',
      memberId: 4,
      urlRedirect: '',
      title: 'Dewa',
      body: 'test by Dewa.',
      imageUrl: 'https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.vecteezy.com%2Fvector-art%2F4244268-cute-dog-cartoon-character-vector-illustration&psig=AOvVaw23L45TfAGf8Nobr1IXscAM&ust=1699587292228000&source=images&cd=vfe&opi=89978449&ved=0CBIQjRxqFwoTCOj45Zj-tYIDFQAAAAAdAAAAABAJ',
      sendByTopic: true,
      insertData: true,
      minuteSpan: 0
    },
    onSubmit: (values: PartialPushNotifProps) => createBlastPush(values)
  })

  return (
    <>
      <Dialog
        open={open}
        title='Create New Push Notification'
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={false}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          <FormikProvider value={formik}>
          <FormControl>
              <InputLabel id='topic'>Assigned To Users</InputLabel>
              <Select
                labelId='topic'
                id='topic'
                value={formik.values.topic}
                label='Visibility'
                onChange={(event) => formik.setFieldValue('topic', event.target.value)}
              >
                <MenuItem value='myhyundai_user'>All User</MenuItem>
                <MenuItem value='myhyundai_blue'>Blue Member</MenuItem>
                <MenuItem value='myhyundai_signature'>Signature Member</MenuItem>

              </Select>
            </FormControl>
            <TextField
              id='referenceId'
              variant='outlined'
              label='referenceId'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              // error={formik.touched && formik.errors}
              // helperText={
              //   formik.touched && formik.errors
              // }
              fullWidth
            />

            <TextField
              id='Users'
              variant='outlined'
              label='Users'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              // error={formik.touched && formik.errors}
              // helperText={
              //   formik.touched && formik.errors
              // }
              fullWidth
            />
            <TextField
              id='Title'
              variant='outlined'
              label='Title'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              // error={formik.touched}
              // helperText={
              //   formik.touched && formik.touched.Color && formik.errors && formik.errors.Color
              // }
              fullWidth
            />

            <TextField
              id='Body'
              variant='outlined'
              label='Body'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              // error={formik.touched && formik.touched.ColorCode && Boolean(formik.errors.ColorCode)}
              // helperText={
              //   formik.touched &&
              //   formik.touched.ColorCode &&
              //   formik.errors &&
              //   formik.errors.ColorCode
              // }
              fullWidth
            />

            <TextField
              id='Notes'
              variant='outlined'
              label='Notes'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              // error={formik.touched && formik.touched.ColorCode && Boolean(formik.errors.ColorCode)}
              // helperText={
              //   formik.touched &&
              //   formik.errors 
              // }
              fullWidth
            />

          </FormikProvider>
        </Container>
      </Dialog>
      <Notification
        open={!create.isLoading && !create.isUninitialized}
        onClose={() => (create.isError ? create.reset() : location.reload())}
        isError={Boolean(create.error) && create.isError}
        message={GLOBAL.returnExceptionMessage(create.isError, create.error as ErrorProps)}
      />
    </>
  )
}

export default Create
