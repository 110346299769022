import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { useNavigate, useParams } from 'react-router-dom'
import {
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
  Chip,
  FormControl,
  InputLabel,
  Select,
  Typography
} from '@mui/material'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import Icon from 'components/icon'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import CONSTANT from 'modules/constant'
import GLOBAL from 'modules/global'
import GlobalStyle from 'modules/styles'
import {
  useGetFaqCategoryMutation,
  useDownloadFaqCategoryMutation,
  useDeleteFaqCategoryMutation,
  useEnableFaqCategoryMutation
} from 'store/faq'
import type { PartialFaqCategoryProps } from 'modules/partial'
import type { ErrorProps, ParamProps } from 'modules/types'
import MenuModalItem from 'components/menuItem/menuItem'
import Create from './create/create'
import Detail from './detail/detail'
import Edit from './edit/edit'

const FaqCategory = () => {
  const navigate = useNavigate()
  const params = useParams<ParamProps>()

  const [getFaqCategory, faqCategory] = useGetFaqCategoryMutation()
  const [deleteFaqCategory, deleteCategory] = useDeleteFaqCategoryMutation()
  const [enableFaqCategory, enableCategory] = useEnableFaqCategoryMutation()
  const [downloadFaqCategory, download] = useDownloadFaqCategoryMutation()

  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [dialogCreate, setDialogCreate] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [dialogUpdate, setDialogUpdate] = useState<boolean>(false)
  const [menu, setMenu] = useState<null | number>(null)

  const [filter, setFilter] = useState({
    language: '',
    status: ''
  })

  const [payload, setPayload] = useState<PartialFaqCategoryProps>({
    start: 0,
    length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    field: CONSTANT.DEFAULT_FAQ_CATEGORY_FIELD.join(','),
    search: { value: '' },
    language: params.language || 'en-us',
    status: ''
  })

  const onFilter = (state: boolean) => setDialogFilter(state)
  const onSearch = (value: string) => setPayload({ ...payload, start: 0, search: { value } })

  const onResync = () => {
    const formData = new FormData()

    formData.append('start', payload.start.toString())
    formData.append('length', payload.length.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(payload.language)) formData.append('language', params.language || payload.language)
    if (!isEmpty(payload.status)) formData.append('status', payload.status)

    getFaqCategory(formData)
  }

  const onCreate = () => setDialogCreate(true)

  const onChangePage = (value: number) => setPayload({ ...payload, start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, length: value })

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('start', String(0))
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(payload.language)) formData.append('language', params.language || payload.language)
    if (!isEmpty(payload.status)) formData.append('status', payload.status)
    if (type && !isEmpty(type)) formData.append('Download-Type', type)

    downloadFaqCategory(formData)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const tableHeadTitles = ['Category', 'Tag', 'Status']

  const openAnchor = Boolean(anchor)

  const totalPage = faqCategory.data && GLOBAL.tableTotalPage(faqCategory.data.recordsTotal)

  const numbers = GLOBAL.tableNumber(payload.start)

  useEffect(() => {
    onResync()
  }, [payload, params.language])

  return (
    <>
      <Content
        title='FAQ Category'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        onAdditional={onCreate}
        additionalTitle='Create New FAQ Category'
        additionalPrefixIcon='Create'
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={faqCategory.data && faqCategory.data.recordsTotal}
        page={payload.start + 1}
        isLoading={faqCategory.isLoading}
        useDownloadDropdown
      >
        {faqCategory.isSuccess &&
          faqCategory.data &&
          faqCategory.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.key}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.key}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.key}`}
                  aria-labelledby={`button-${index}-${table.key}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem
                    onClick={() => onRemoveAnchor(() => navigate(GLOBAL.encodeUrl(table.key)))}
                  >
                    Open
                  </MenuItem>
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View Detail
                  </MenuItem>
                  <MenuItem onClick={() => onRemoveAnchor(() => setDialogUpdate(true))}>
                    Edit
                  </MenuItem>
                  <MenuModalItem
                    title={table.status == 'Enabled' ? 'Disable' : 'Enable'}
                    onClick={() =>
                      onRemoveAnchor(() =>
                        enableFaqCategory({
                          key: table.key,
                          status: table.status == 'Enabled' ? 'Disabled' : 'Enabled'
                        })
                      )
                    }
                  >
                    {table.status == 'Enabled' ? 'Disable' : 'Enable'}
                  </MenuModalItem>
                  <MenuModalItem
                    title='Delete'
                    loading={deleteCategory.isLoading}
                    onClick={() => onRemoveAnchor(() => deleteFaqCategory({ key: table.key }))}
                  >
                    <Typography {...GlobalStyle.ErrorTypography}>Delete</Typography>
                  </MenuModalItem>
                </Menu>
                {drawerDetail && menu == index && (
                  <Detail
                    id={table.key}
                    open={drawerDetail && menu == index}
                    onClose={() => setDrawerDetail(false)}
                  />
                )}
                {dialogUpdate && menu == index && (
                  <Edit
                    id={table.key}
                    open={dialogUpdate && menu == index}
                    onClose={() => setDialogUpdate(false)}
                  />
                )}
              </TableCell>
              <TableCell
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate(GLOBAL.encodeUrl(table.key))}
              >
                {table.key}
              </TableCell>
              <TableCell>{table.tags || '-'}</TableCell>
              <TableCell>
                <Chip
                  label={table.status}
                  color={table.status == 'Enabled' ? 'success' : 'error'}
                />
              </TableCell>
            </TableRow>
          ))}
      </Content>

      {faqCategory.isLoading && <Loading />}

      {dialogCreate && <Create open={dialogCreate} onClose={() => setDialogCreate(false)} />}

      <Filter
        open={dialogFilter}
        title='Filter'
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
        onReset={() => {
          setFilter({
            status: '',
            language: ''
          })
          setPayload({
            ...payload,
            start: 0,
            status: '',
            language: 'en-us'
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({ ...payload, start: 0, ...filter })
          onFilter(false)
        }}
      >
        <FormControl fullWidth>
          <InputLabel id='select-label'>Status</InputLabel>
          <Select
            labelId='select-label'
            id='id-select-label'
            value={filter.status}
            label='Status'
            onChange={(event) => setFilter({ ...filter, status: event.target.value })}
          >
            <MenuItem value='Enabled'>Enabled</MenuItem>
            <MenuItem value='Disabled'>Disabled</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id='select-label'>Language</InputLabel>
          <Select
            labelId='select-label'
            id='id-select-label'
            value={filter.language}
            label='Language'
            onChange={(event) => setFilter({ ...filter, language: event.target.value })}
          >
            <MenuItem value='id'>ID</MenuItem>
            <MenuItem value='en-us'>EN-US</MenuItem>
          </Select>
        </FormControl>
      </Filter>

      <Notification
        open={!faqCategory.isLoading && !faqCategory.isUninitialized && !faqCategory.isSuccess}
        onClose={() => (faqCategory.isError ? faqCategory.reset() : location.reload())}
        isError={Boolean(faqCategory.error) && faqCategory.isError}
        message={GLOBAL.returnExceptionMessage(
          faqCategory.isError,
          faqCategory.error as ErrorProps
        )}
      />

      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />

      <Notification
        open={!deleteCategory.isLoading && !deleteCategory.isUninitialized}
        onClose={() => (deleteCategory.isError ? deleteCategory.reset() : location.reload())}
        isError={Boolean(deleteCategory.error) && deleteCategory.isError}
        message={GLOBAL.returnExceptionMessage(
          deleteCategory.isError,
          deleteCategory.error as ErrorProps
        )}
      />

      <Notification
        open={!enableCategory.isLoading && !enableCategory.isUninitialized}
        onClose={() => (enableCategory.isError ? enableCategory.reset() : location.reload())}
        isError={Boolean(enableCategory.error) && enableCategory.isError}
        message={GLOBAL.returnExceptionMessage(
          enableCategory.isError,
          enableCategory.error as ErrorProps
        )}
      />
    </>
  )
}

export default FaqCategory
