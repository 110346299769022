import type { LoadingButtonProps } from '@mui/lab'
import type { BoxProps, ContainerProps, StackProps, TypographyProps } from '@mui/material'

class DialogStyle {
  static Container: ContainerProps = {
    maxWidth: false,
    disableGutters: true,
    sx: {
      display: 'flex',
      gap: '1rem',
      flexDirection: 'column'
    }
  }

  static Stack: StackProps = {
    direction: 'column',
    spacing: 2
  }

  static Permission: TypographyProps = {
    fontWeight: '500',
    fontSize: 18
  }

  static Submit: LoadingButtonProps = {
    sx: {
      alignSelf: 'end'
    }
  }

  static Title: TypographyProps = {
    fontWeight: 'bold',
    marginBottom: '.5rem'
  }

  static Image: BoxProps = {
    display: 'flex',
    width: '100%',
    minHeight: '4rem',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    borderRadius: 1,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center'
  }

}

export default DialogStyle
