import { Container, Grid, TextField } from '@mui/material'
import Drawer from 'components/drawer/detail'
import type { DetailStateProps } from 'modules/types'
import { useGetChargeBoxDetailQuery } from 'store/evChargeBox'
import Loading from 'components/loading/loading'
import DrawerStyle from './style'

const Detail = ({ open, id, onClose }: DetailStateProps & { id: number }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const { data, isFetching, isLoading } = useGetChargeBoxDetailQuery(id)

  const chargeBoxName = (data && data.label) || '-'
  const chargeBoxIdByPlugIt = (data && data.code) || '-'
  const chargeBoxIdByMyHyundai = (data && data.id) || '-'
  const chargeBoxGroupIdByPlugIt = (data && data.chargeBoxGroupCode) || '-'
  const chargeBoxGroupIdByMyHyundai = (data && data.chargeBoxGroupId) || '-'
  const chargePointName = (data && data.chargePointName) || '-'
  const connectorType = (data && data.socketType) || '-'
  const qrCode = (data && data.qrCode) || '-'
  const chargeLevel = (data && data.chargeLevel) || '-'
  const Power = (data && data.power) || '-'
  const chargeBoxStatus = (data && data.status) || '-'

  const gridDataLabel = [
    'Charge Box Name',
    'Charge Box ID by PlugIt',
    'Charge Box ID by MyHyundai',
    'Charge Box Group ID by PlugIt',
    'Charge Box Group ID by MyHyundai',
    'Charge Point Name',
    'Connector Type',
    'QR Code',
    'Charge Level',
    'Charging Capacity',
    'Charge Box Status'
  ]

  const gridDataValue = [
    chargeBoxName,
    chargeBoxIdByPlugIt,
    chargeBoxIdByMyHyundai,
    chargeBoxGroupIdByPlugIt,
    chargeBoxGroupIdByMyHyundai,
    chargePointName,
    connectorType,
    qrCode,
    chargeLevel,
    Power,
    chargeBoxStatus
  ]

  return (
    <Drawer open={open} title='EV Charge Box Detail' onClose={onClose}>
      <Container {...DrawerStyle.Container}>
        {(isLoading || isFetching) && !data && <Loading />}
        {data && (
          <Grid container spacing={2}>
            {gridDataValue.map((item, index) => (
              <Grid key={index} item xs={12}>
                <TextField
                  variant='outlined'
                  label={gridDataLabel[index]}
                  value={item}
                  inputProps={{ readOnly: true }}
                  fullWidth
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
    </Drawer>
  )
}

export default Detail
