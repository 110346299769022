import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import type { GridColDef } from '@mui/x-data-grid'
import { DataGrid } from '@mui/x-data-grid'
import { Autocomplete, CircularProgress, Container, Grid, TextField } from '@mui/material'
import Drawer from 'components/drawer/detail'
import Loading from 'components/loading/loading'
import { useGetAccountListMutation, useGetAccountPermissionDetailQuery } from 'store/assignment'
import { useGetUsersAccountDetailQuery } from 'store/control'
import { useGetMenuTreeQuery } from 'store/menu'
import { useGetRolesListDropdownMutation } from 'store/roles'
import type { DetailStateProps, RolesAssignmentMenuProps } from 'modules/types'
import GlobalStyle from 'modules/styles'
import DetailStyle from './style'

const Detail = ({
  open,
  id,
  nameCheck,
  onClose
}: DetailStateProps & { id: string; nameCheck: string }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const [getRolesListDropdown, rolesList] = useGetRolesListDropdownMutation()
  const [getAccountList, accountList] = useGetAccountListMutation()

  const {
    data: dataAccount,
    isFetching: isAccountFetching,
    isLoading: isAccountLoading
  } = useGetUsersAccountDetailQuery(id)

  const {
    data: dataPermission,
    isFetching: isPermissionFetching,
    isLoading: isPermissionLoading
  } = useGetAccountPermissionDetailQuery(id)

  const {
    data: dataMenu,
    isFetching: isMenuFetching,
    isLoading: isMenuLoading
  } = useGetMenuTreeQuery()

  const selectedColumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 96 },
    { field: 'menu', headerName: 'Main Menu', minWidth: 256 },
    { field: 'name', headerName: 'Sub Menu', minWidth: 256 },
    {
      field: 'convertedFunctions',
      headerName: 'Function Name',
      minWidth: 256,
      flex: 1
    }
  ]

  const selectedRole =
    rolesList &&
    rolesList.data &&
    rolesList.data.find((el) => el.name == (dataPermission && dataPermission.roleName))

  const selectedAssignee =
    accountList &&
    accountList.data &&
    accountList.data.find((el) => el.name == (dataPermission && dataPermission.fullName))

  const selectedMainMenu =
    dataMenu &&
    dataMenu.mainMenu &&
    dataMenu.mainMenu
      .map((main) => {
        const subMenu = main.subMenu.map((subMenu) => {
          const functions = subMenu.listFunction.filter(
            (func) => dataPermission && dataPermission.permissions.includes(func.name)
          )

          const filtered = functions.map((func) => ({
            id: func.functionId,
            name: func.name,
            type: func.type
          }))

          const list = {
            menu: main.name,
            name: subMenu.name,
            id: subMenu.subMenuId,
            functions: filtered,
            convertedFunctions: filtered.map((func) => func.name).join(', ')
          }

          return list
        })

        const filtered = subMenu.filter((sub) => sub.functions.length)

        return filtered
      })
      .filter((menu) => menu.length)
      .flat(1)

  const userId = (dataAccount && dataAccount.userId) || '-'
  const email = (dataAccount && dataAccount.email) || '-'
  const details = dataAccount && dataAccount.usersDetails && dataAccount.usersDetails[0]
  const fullName =
    (details &&
      details.firstName &&
      details.lastName &&
      details.firstName + ' ' + details.lastName) ||
    (details && details.firstName) ||
    '-'
  const mobilePhone = (details && details.mobilePhone) || '-'

  const gridDataLabel = ['User ID', 'email', 'Full Name', 'Mobile Phone']
  const gridDataValue = [userId, email, fullName, mobilePhone]

  const [selectedRow, setSelectedRow] = useState<(RolesAssignmentMenuProps | undefined)[]>(
    selectedMainMenu as (RolesAssignmentMenuProps | undefined)[]
  )

  useEffect(() => {
    getRolesListDropdown()
    getAccountList({ nameCheck })
  }, [])

  useEffect(() => {
    if (isEmpty(selectedRow) && selectedMainMenu) setSelectedRow(selectedMainMenu)
  }, [selectedMainMenu])

  return (
    <Drawer open={open} title='Check Access Detail' onClose={onClose}>
      <Container {...DetailStyle.Container}>
        {(isAccountLoading || isAccountFetching) &&
          !dataAccount &&
          (isPermissionLoading || isPermissionFetching) &&
          !dataPermission &&
          (isMenuLoading || isMenuFetching) &&
          !dataMenu && <Loading />}

        {dataAccount && (
          <Grid container spacing={2}>
            {gridDataValue.map((item, index) => (
              <Grid key={index} item xs={12}>
                <TextField
                  variant='outlined'
                  label={gridDataLabel[index]}
                  value={item}
                  inputProps={{ readOnly: true }}
                  multiline
                  fullWidth
                />
              </Grid>
            ))}
            {selectedRole && (
              <Grid item xs={12}>
                <Autocomplete
                  options={rolesList.data || []}
                  getOptionLabel={(list) => list.name}
                  isOptionEqualToValue={(option, value) =>
                    option && value ? option.id == value.id : false
                  }
                  value={selectedRole}
                  readOnly
                  ListboxProps={GlobalStyle.ListBox}
                  renderOption={(props, item) => (
                    <li {...props} key={item.id}>
                      {item.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name='roleId'
                      label='Role'
                      value={(selectedRole && selectedRole.id) || 0}
                      InputProps={{
                        ...params.InputProps,
                        readOnly: true,
                        endAdornment: (
                          <>
                            {rolesList.isLoading && <CircularProgress color='inherit' size={20} />}
                            {params.InputProps.endAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                />
              </Grid>
            )}
            {selectedAssignee && (
              <Grid item xs={12}>
                <Autocomplete
                  options={accountList.data || []}
                  getOptionLabel={(list) => list.name}
                  isOptionEqualToValue={(option, value) =>
                    option && value ? option.userId == value.userId : false
                  }
                  value={selectedAssignee}
                  readOnly
                  ListboxProps={GlobalStyle.ListBox}
                  renderOption={(props, item) => (
                    <li {...props} key={item.userId}>
                      {item.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name='userId'
                      label='Assign Access To'
                      value={(selectedAssignee && selectedAssignee.userId) || ''}
                      InputProps={{
                        ...params.InputProps,
                        readOnly: true,
                        endAdornment: (
                          <>
                            {false && <CircularProgress color='inherit' size={20} />}
                            {params.InputProps.endAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>
        )}
        <DataGrid rows={selectedRow || []} columns={selectedColumns} hideFooter autoHeight />
      </Container>
    </Drawer>
  )
}

export default Detail
