import CONSTANT from 'modules/constant'
import type { PartialDropdownListProps } from 'modules/partial'
import type { DropdownListProps } from 'modules/types'
import { API, headers } from './api'

const PolicyAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getPolicyDropdownList: builder.mutation<DropdownListProps[], PartialDropdownListProps>({
      query: (query) => ({
        url: CONSTANT.URL_POLICY_DROPDOWN + `?start=${query.start}&length=${query.length}`,
        method: 'GET',
        headers: headers()
      })
    }),
    getPolicyDropdownListBy: builder.query<DropdownListProps[], PartialDropdownListProps>({
      query: (query) => ({
        url: CONSTANT.URL_POLICY_DROPDOWN + `?start=${query.start}&length=${query.length}`,
        method: 'GET',
        headers: headers()
      })
    })
  })
})

export const { useGetPolicyDropdownListMutation, useGetPolicyDropdownListByQuery } = PolicyAPI
