import CONSTANT from 'modules/constant'
import type {
  LocationDealerDetailProps,
  LocationDealerDropdownProps,
  LocationDealerProps,
  PaginationProps,
  ResultProps
} from 'modules/types'
import { API, download, headers } from './api'

const DealerAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getDealer: builder.mutation<PaginationProps<LocationDealerProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_LOCATION_DEALER,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    getDealerDropdown: builder.mutation<LocationDealerDropdownProps[], void>({
      query: () => ({
        url: CONSTANT.URL_LOCATION_DEALER_DROPDOWN,
        method: 'GET',
        headers: headers()
      })
    }),
    getDealerDetail: builder.query<LocationDealerDetailProps, string>({
      query: (dealerId) => ({
        url: CONSTANT.URL_LOCATION_DEALER_DETAIL + dealerId,
        method: 'GET',
        headers: headers()
      })
    }),
    resyncDealer: builder.mutation({
      query: () => ({
        url: CONSTANT.URL_LOCATION_DEALER_SYNC,
        method: 'GET',
        headers: headers()
      })
    }),
    downloadDealer: builder.mutation<PaginationProps<LocationDealerProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_LOCATION_DEALER_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        responseHandler: download,
        body: body,
        formData: true
      })
    }),
    activateDealer: builder.mutation<ResultProps, { dealerId: string; IsActive: boolean }>({
      query: (body) => ({
        url: CONSTANT.URL_LOCATION_DEALER_ACTIVATE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    })
  })
})

export const {
  useGetDealerMutation,
  useGetDealerDropdownMutation,
  useGetDealerDetailQuery,
  useResyncDealerMutation,
  useDownloadDealerMutation,
  useActivateDealerMutation
} = DealerAPI
