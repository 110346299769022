import { useState } from 'react'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'

const EVPartnerVoucher = () => {
  const [dialogFilter, setDialogFilter] = useState<boolean>(false)

  const onSearch = () => console.log('onSearch')
  const onResync = () => console.log('onResync')
  const onFilter = (state: boolean) => setDialogFilter(state)
  const onDownload = () => console.log('onDownload')
  const onCreate = () => console.log('onCreate')

  const tableHeadTitles = ['Name', 'Type', 'Description', 'status']

  return (
    <>
      <Content
        title='EV Partner and Voucher'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        // downloadLoading={download.isLoading}
        onAdditional={onCreate}
        additionalTitle='Create New EV Partner & Voucher'
        additionalPrefixIcon='Create'
        // pagination={onChangePage}
        // onChangePerPage={onChangePerPage}
        // totalPage={totalPage}
        // page={payload.start + 1}
        // isLoading={isLoading}
      />

      <Filter
        open={dialogFilter}
        title='Filter'
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
      />
    </>
  )
}

export default EVPartnerVoucher
