import CONSTANT from 'modules/constant'
import type {
  EvBrandDetailProps,
  EvBrandListProps,
  PaginationProps,
  ResultProps
} from 'modules/types'
import type {
  PartialEvBrandCreateProps,
  PartialEvBrandDeleteProps,
  PartialEvBrandUpdateProps
} from 'modules/partial'
import { API, download, headers } from './api'

const EvBrandAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getEvBrand: builder.mutation<PaginationProps<EvBrandListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_CATALOG_EV_CAR_BRAND_LIST,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    downloadEvBrand: builder.mutation<PaginationProps<EvBrandListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_CATALOG_EV_CAR_BRAND_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true,
        responseHandler: download
      })
    }),
    getEvBrandDetail: builder.query<EvBrandDetailProps, number | string>({
      query: (id) => ({
        url: CONSTANT.URL_CATALOG_EV_CAR_BRAND_DETAIL + id,
        method: 'GET',
        headers: headers()
      })
    }),
    deleteEvBrand: builder.mutation<ResultProps, PartialEvBrandDeleteProps>({
      query: (body) => ({
        url: CONSTANT.URL_CATALOG_EV_CAR_BRAND_DELETE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    createEvBrand: builder.mutation<ResultProps, PartialEvBrandCreateProps>({
      query: (body) => ({
        url: CONSTANT.URL_CATALOG_EV_CAR_BRAND_CREATE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    updateEvBrand: builder.mutation<ResultProps, PartialEvBrandUpdateProps>({
      query: (body) => ({
        url: CONSTANT.URL_CATALOG_EV_CAR_BRAND_UPDATE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    })
  })
})

export const {
  useGetEvBrandMutation,
  useDownloadEvBrandMutation,
  useGetEvBrandDetailQuery,
  useDeleteEvBrandMutation,
  useCreateEvBrandMutation,
  useUpdateEvBrandMutation
} = EvBrandAPI
