import { useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  TextField,
  Typography
} from '@mui/material'
import { ExpandMoreOutlined } from '@mui/icons-material'
import Drawer from 'components/drawer/detail'
import Loading from 'components/loading/loading'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import {
  useGetVoucherUsageTokenDetailQuery,
  useMoveVoucherUsageTokenMutation,
  useRevokeVoucherUsageTokenMutation
} from 'store/voucherUsage'
import GLOBAL from 'modules/global'
import Notification from 'components/notification'
import Dialog from 'components/dialog/dialog'
import { useGetVoucherListDropdownMutation } from 'store/tokenRequest'
import GlobalStyle from 'modules/styles'
import type { PartialVoucherUsageTokenStateProps } from 'modules/partial'
import DrawerStyle from './style'

const Detail = ({ open, id, onClose }: DetailStateProps & { id: number }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const { data, isFetching, isLoading } = useGetVoucherUsageTokenDetailQuery(id)

  const [getVoucherListDropdown, voucherDropdown] = useGetVoucherListDropdownMutation()
  const [moveToken, move] = useMoveVoucherUsageTokenMutation()
  const [revokeToken, revoke] = useRevokeVoucherUsageTokenMutation()

  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [isRevoke, setIsRevoke] = useState<boolean>(false)
  const [dialogTitle, setDialogTitle] = useState<string>('')
  const [changeValue, setChangeValue] = useState<string | null>(null)

  const loading = !data && (isLoading || isFetching)

  const code = (data && data.code) || '-'
  const partnerName = (data && data.partnerName) || '-'
  const voucherName = (data && data.voucherName) || '-'
  const voucherId = (data && data.voucherId) || '-'
  const validFrom = (data && data.validFrom && GLOBAL.formatDateWithTime(data.validFrom)) || '-'
  const validUntil = (data && data.validUntil && GLOBAL.formatDateWithTime(data.validUntil)) || '-'
  const status = (data && data.status) || '-'

  const gridDataLabel = [
    'Code',
    'Partner Name',
    'Voucher Name',
    'Voucher ID',
    'Valid From',
    'Valid Until',
    'Status'
  ]

  const gridDataValue = [code, partnerName, voucherName, voucherId, validFrom, validUntil, status]

  const createdBy = (data && data.createdBy) || '-'
  const createdAt = (data && data.createdAt) || '-'
  const modifiedBy = (data && data.modifiedBy) || '-'
  const modifiedAt = (data && data.modifiedAt) || '-'

  const gridBasicLabel = ['Created By', 'Created At', 'Modified By', 'Modified At']
  const gridBasicValue = [createdBy, createdAt, modifiedBy, modifiedAt]

  const stateVoucher = (title: 'Move' | 'Revoke') => {
    setIsRevoke(title === 'Revoke')
    setDialogTitle(title)
    setOpenDialog(true)
  }

  const submitChangeValue = () => {
    const payload: PartialVoucherUsageTokenStateProps = {
      voucherTokenCodes: [code],
      [isRevoke ? 'reason' : 'to']: isRevoke ? changeValue : Number(changeValue)
    }

    return isRevoke ? revokeToken(payload) : moveToken(payload)
  }

  return (
    <>
      <Drawer open={open} title='Voucher Usage Token Detail' onClose={onClose}>
        <Container {...DrawerStyle.Container}>
          {loading && <Loading />}
          {!loading && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  gap: 1
                }}
              >
                <Button
                  variant='contained'
                  color='error'
                  onClick={() => stateVoucher('Move')}
                  disabled={data && isEmpty(data.code)}
                  fullWidth
                >
                  Move
                </Button>
                <Button
                  variant='contained'
                  color='error'
                  onClick={() => stateVoucher('Revoke')}
                  disabled={data && isEmpty(data.code)}
                  fullWidth
                >
                  Revoke
                </Button>
              </Box>
              <Grid container spacing={2}>
                {gridDataValue.map((item, index) => (
                  <Grid key={index} item xs={12}>
                    <TextField
                      variant='outlined'
                      label={gridDataLabel[index]}
                      value={item}
                      inputProps={{ readOnly: true }}
                      fullWidth
                    />
                  </Grid>
                ))}
              </Grid>
              <Accordion elevation={0} disableGutters>
                <AccordionSummary
                  expandIcon={<ExpandMoreOutlined />}
                  aria-controls='basic-information'
                >
                  <Typography>Basic Information</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    {gridBasicValue.map((item, index) => (
                      <Grid key={index} item xs={12}>
                        <TextField
                          variant='outlined'
                          label={gridBasicLabel[index]}
                          value={item}
                          inputProps={{ readOnly: true }}
                          fullWidth
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </>
          )}
        </Container>
      </Drawer>

      <Dialog
        title={`${dialogTitle} Token`}
        open={openDialog}
        onCancel={() => {
          setChangeValue(null)
          setOpenDialog(false)
        }}
        onSubmit={() => submitChangeValue()}
        loading={isRevoke ? revoke.isLoading : move.isLoading}
        isDisabled={isEmpty(changeValue)}
      >
        {!isRevoke && (
          <Autocomplete
            key={changeValue}
            options={(voucherDropdown && voucherDropdown.data) || []}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) =>
              option && value ? option.id == value.id : false
            }
            onOpen={() => getVoucherListDropdown()}
            onChange={(_, voucher) => setChangeValue((voucher && String(voucher.id)) || null)}
            value={
              (voucherDropdown &&
                voucherDropdown.data &&
                voucherDropdown.data.find((e) => e.id == changeValue)) ||
              null
            }
            ListboxProps={GlobalStyle.ListBox}
            renderOption={(props, item) => (
              <li {...props} key={item.id}>
                {item.name}
              </li>
            )}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                name='voucherToken'
                label='Voucher Name'
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {voucherDropdown.isLoading && <CircularProgress color='inherit' size={20} />}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
        )}
        {isRevoke && (
          <TextField
            label='Reason'
            id='Reason'
            multiline
            rows={4}
            onChange={(event) => setChangeValue(event.target.value)}
          />
        )}
      </Dialog>

      <Notification
        open={!revoke.isLoading && !revoke.isUninitialized}
        onClose={() => (revoke.isError ? revoke.reset() : location.reload())}
        isError={Boolean(revoke.error) && revoke.isError}
        message={GLOBAL.returnExceptionMessage(revoke.isError, revoke.error as ErrorProps)}
      />

      <Notification
        open={!move.isLoading && !move.isUninitialized}
        onClose={() => (move.isError ? move.reset() : location.reload())}
        isError={Boolean(move.error) && move.isError}
        message={GLOBAL.returnExceptionMessage(move.isError, move.error as ErrorProps)}
      />
    </>
  )
}

export default Detail
