import CONSTANT from 'modules/constant'
import type {
  EvHistoryDetailProps,
  EVTransactionListProps,
  EvHistoryOverviewListProps,
  PaginationProps
} from 'modules/types'
import type { PartialEvHistoryOverview } from 'modules/partial'
import { API, download, headers } from './api'

const EvHistoryAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getEvHistoryOngoing: builder.mutation<PaginationProps<EVTransactionListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_EV_HISTORY_ONGOING_LIST,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    downloadEvHistoryOngoing: builder.mutation<PaginationProps<EVTransactionListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_EV_HISTORY_ONGOING_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true,
        responseHandler: download
      })
    }),
    getEvHistoryFinished: builder.mutation<PaginationProps<EVTransactionListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_EV_HISTORY_FINISHED_LIST,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    downloadEvHistoryFinished: builder.mutation<PaginationProps<EVTransactionListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_EV_HISTORY_FINISHED_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true,
        responseHandler: download
      })
    }),
    getEvHistoryOverview: builder.mutation<EvHistoryOverviewListProps, PartialEvHistoryOverview>({
      query: (body) => ({
        url: CONSTANT.URL_EV_HISTORY_OVERVIEW_LIST,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    getEvHistoryDetail: builder.query<EvHistoryDetailProps, string>({
      query: (id) => ({
        url: CONSTANT.URL_EV_HISTORY_DETAIL + id,
        method: 'GET',
        headers: headers()
      })
    })
  })
})

export const {
  useGetEvHistoryOngoingMutation,
  useDownloadEvHistoryOngoingMutation,
  useGetEvHistoryFinishedMutation,
  useDownloadEvHistoryFinishedMutation,
  useGetEvHistoryOverviewMutation,

  useGetEvHistoryDetailQuery
} = EvHistoryAPI
