import { useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import { isEmpty } from 'lodash'
import { Container, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import * as yup from 'yup'
import Dialog from 'components/dialog/dialog'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { PayloadVoucherCategoryProps } from 'modules/partial'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import { useCreateVoucherCategoryMutation } from 'store/voucherCategory'
import IconMoons from 'assets/selection.json'
import IconMoon from 'components/iconmoon'
import BackdropImage from 'components/backdropImage/backdropImage'
import DialogStyle from './style'

const Create = ({ open, onClose }: DetailStateProps) => {
  const [createVoucherCategory, create] = useCreateVoucherCategoryMutation()
  const [previewBanner, setPreviewBanner] = useState<string>('')
  const [iconName, setIconName] = useState('')
  const [bannerBackdrop, setBannerBackdrop] = useState<boolean>(false)

  const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    setIconName(newAlignment)
    formik.setFieldValue('icon', newAlignment)
  }

  const scheme = yup.object<PayloadVoucherCategoryProps>({
    icon: yup.mixed().required('Icon Voucher Category is required'),
    image: yup.mixed().required('Image Voucher Category is required'),
    nameEn: yup.string().required('Voucher Category Name English is required'),
    nameId: yup.string().required('Voucher Category Name ID is required'),
    Description: yup.string().required('Voucher Category Description is required'),
    Seq: yup.number().required('Voucher Category Sequence is required')
  })

  const formik = useFormik<PayloadVoucherCategoryProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      image: '',
      icon: '',
      nameEn: '',
      nameId: '',
      Description: '',
      Seq: ''
    },
    onSubmit: (values: PayloadVoucherCategoryProps) => handleSubmit(values)
  })

  const handleSubmit = (e: PayloadVoucherCategoryProps) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const payload: any = {
      nameEn: e.nameEn,
      nameId: e.nameId,
      Description: e.Description,
      Seq: e.Seq,
      icon: iconName
    }
    const formData = new FormData()
    formData.append('image', e.image)
    formData.append('data', JSON.stringify(payload))
    createVoucherCategory(formData).then()
  }

  return (
    <>
      <Dialog
        open={open}
        title='Add Voucher Category'
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={create.isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          <FormikProvider value={formik}>
            <TextField
              id='nameEn'
              variant='outlined'
              label='Voucher Category Name EN-US'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched && formik.touched.nameEn && Boolean(formik.errors.nameEn)}
              helperText={
                formik.touched && formik.touched.nameEn && formik.errors && formik.errors.nameEn
              }
              fullWidth
            />
            <TextField
              id='nameId'
              variant='outlined'
              label='Voucher Category Name ID'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched && formik.touched.nameId && Boolean(formik.errors.nameId)}
              helperText={
                formik.touched && formik.touched.nameId && formik.errors && formik.errors.nameId
              }
              fullWidth
            />
            <TextField
              id='Description'
              variant='outlined'
              label='Description'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched && formik.touched.Description && Boolean(formik.errors.Description)
              }
              helperText={
                formik.touched &&
                formik.touched.Description &&
                formik.errors &&
                formik.errors.Description
              }
              fullWidth
            />
            <TextField
              id='Seq'
              variant='outlined'
              label='Sequence'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched && formik.touched.Seq && Boolean(formik.errors.Seq)}
              helperText={
                formik.touched && formik.touched.Seq && formik.errors && formik.errors.Seq
              }
              fullWidth
            />
            <TextField
              id='icon'
              variant='outlined'
              label='Icon'
              value={formik.initialValues.icon}
              error={formik.touched && formik.touched.icon && Boolean(formik.errors.icon)}
              helperText={
                formik.touched && formik.touched.icon && formik.errors && formik.errors.icon
              }
              sx={{ display: { xs: 'none', sm: 'none' } }}
            />
            <div
              style={{
                height: '300px',
                border: '1px solid #eee',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {!previewBanner && (
                <Typography style={{ color: '#cfcfcf' }}>
                  Voucher Category Image Cannot be Empty
                </Typography>
              )}
              {previewBanner && (
                <BackdropImage
                  open={bannerBackdrop}
                  onOpen={() => setBannerBackdrop(true)}
                  onClose={() => setBannerBackdrop(false)}
                >
                  <img
                    src={previewBanner}
                    alt=''
                    style={{ height: '300px', width: '537px', objectFit: 'contain' }}
                  />
                </BackdropImage>
              )}
            </div>
            <TextField
              required
              id='image'
              label='Voucher Category Image'
              type='file'
              placeholder='Voucher Category Image'
              InputLabelProps={{ shrink: true }}
              inputProps={{ accept: 'image/png, image/gif, image/jpeg', shrink: 'true' }}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const files = e && e.target && e.target.files
                if (files && files.length > 0 && !isEmpty(files)) {
                  formik.setFieldValue('image', files[0])
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  const reader: any = new FileReader()
                  reader.onload = () => {
                    setPreviewBanner(reader.result)
                  }
                  reader.readAsDataURL(files[0])
                } else {
                  setPreviewBanner('')
                }
              }}
              onBlur={formik.handleBlur}
              error={formik.touched && formik.touched.image && Boolean(formik.errors.image)}
              helperText={
                formik.touched && formik.touched.image && formik.errors && formik.errors.image
              }
              fullWidth
            />

            <div
              style={{
                height: '160px',
                border: '1px solid #eee',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                flexWrap: 'wrap',
                padding: 8,
                position: 'relative'
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: '-12px',
                  left: '8px',
                  height: '20px',
                  backgroundColor: 'white',
                  textAlign: 'center',
                  zIndex: 100,
                  display: 'flex',
                  flex: 1,
                  fontSize: 12,
                  color: 'gray',
                  padding: 4
                }}
              >
                Voucher Category Icon *
              </div>
              <div
                style={{
                  height: '150px',
                  overflow: 'auto',
                  padding: '8px 8px 8px 8px',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                {IconMoons.icons.map((item, idx) => (
                  <ToggleButtonGroup
                    color='error'
                    value={iconName}
                    exclusive
                    onChange={handleChange}
                    aria-label='Platform'
                    key={idx}
                  >
                    <ToggleButton value={item.properties.name}>
                      <IconMoon
                        icon={item.properties.name}
                        key={idx}
                        size={25}
                        style={{ marginRight: 4 }}
                        color={iconName == item.properties.name ? 'red' : '#002C5F'}
                      />
                    </ToggleButton>
                  </ToggleButtonGroup>
                ))}
              </div>
            </div>
          </FormikProvider>
        </Container>
      </Dialog>

      <Notification
        open={!create.isLoading && !create.isUninitialized}
        onClose={() => (create.isError ? create.reset() : location.reload())}
        isError={Boolean(create.error) && create.isError}
        message={GLOBAL.returnExceptionMessage(create.isError, create.error as ErrorProps)}
      />
    </>
  )
}

export default Create
