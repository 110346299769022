import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TableCell,
  TableRow
} from '@mui/material'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import Icon from 'components/icon'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import CONSTANT from 'modules/constant'
import GLOBAL from 'modules/global'
import {
  useGetUsersAccountListMutation,
  useDownloadUsersAccountListMutation,
  useUpdateUsersAccountListMutation
} from 'store/control'
import type { PartialUsersAccountProps } from 'modules/partial'
import type { ErrorProps } from 'modules/types'
import MenuModalItem from 'components/menuItem/menuItem'
import Create from './create/create'
import Detail from './detail/detail'
import Reset from './reset/reset'

const Account = () => {
  const [getUsersAccountList, account] = useGetUsersAccountListMutation()
  const [updateUsersAccountList, update] = useUpdateUsersAccountListMutation()
  const [downloadUsersAccountList, download] = useDownloadUsersAccountListMutation()

  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [dialogCreate, setDialogCreate] = useState<boolean>(false)
  const [dialogReset, setDialogReset] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [menu, setMenu] = useState<null | number>(null)

  const [filter, setFilter] = useState({
    status: ''
  })

  const [payload, setPayload] = useState<PartialUsersAccountProps>({
    start: 0,
    length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    field: CONSTANT.DEFAULT_ACCESS_CONTROL_FIELD.join(','),
    search: { value: '' },
    status: ''
  })

  const onSearch = (value: string) => setPayload({ ...payload, start: 0, search: { value } })
  const onFilter = (state: boolean) => setDialogFilter(state)

  const onResync = () => {
    const formData = new FormData()

    formData.append('start', payload.start.toString())
    formData.append('length', payload.length.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(payload.status)) formData.append('status', payload.status)

    getUsersAccountList(formData)
  }

  const onCreate = () => setDialogCreate(true)

  const onChangePage = (value: number) => setPayload({ ...payload, start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, length: value })

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('start', String(0))
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(payload.status)) formData.append('status', payload.status)
    if (type && !isEmpty(type)) formData.append('Download-Type', type)

    downloadUsersAccountList(formData)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const tableHeadTitles = ['User Name', 'Full Name', 'Email', 'phone', 'status']

  const openAnchor = Boolean(anchor)

  const totalPage = account.data && GLOBAL.tableTotalPage(account.data.recordsTotal)

  const numbers = GLOBAL.tableNumber(payload.start)

  useEffect(() => {
    onResync()
  }, [payload])

  return (
    <>
      <Content
        title='Users Account'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        onAdditional={onCreate}
        additionalTitle='Create New User Account'
        additionalPrefixIcon='Create'
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={account.data && account.data.recordsTotal}
        page={payload.start + 1}
        isLoading={account.isLoading}
        useDownloadDropdown
      >
        {account.isSuccess &&
          account.data &&
          account.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.userId}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.userId}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.userId}`}
                  aria-labelledby={`button-${index}-${table.userId}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View
                  </MenuItem>
                  <MenuModalItem
                    title={table.status == 'Enabled' ? 'Disable' : 'Enable'}
                    onClick={() =>
                      onRemoveAnchor(() =>
                        updateUsersAccountList({
                          userId: table.userId,
                          IsActive: table.status != 'Enabled'
                        })
                      )
                    }
                  >
                    {table.status == 'Enabled' ? 'Disable' : 'Enable'}
                  </MenuModalItem>
                  <MenuItem onClick={() => onRemoveAnchor(() => setDialogReset(true))}>
                    Reset Password
                  </MenuItem>
                </Menu>
                <Reset
                  open={dialogReset && menu == index}
                  user={table}
                  onClose={() => setDialogReset(false)}
                />
                <Detail
                  id={table.userId}
                  open={drawerDetail && menu == index}
                  onClose={() => setDrawerDetail(false)}
                />
              </TableCell>
              <TableCell>{table.userName || '-'}</TableCell>
              <TableCell>{table.fullName || '-'}</TableCell>
              <TableCell>{table.email || '-'}</TableCell>
              <TableCell>{table.phone || '-'}</TableCell>
              <TableCell>
                <Chip
                  label={table.status}
                  color={table.status == 'Enabled' ? 'success' : 'error'}
                />
              </TableCell>
            </TableRow>
          ))}
      </Content>

      {account.isLoading && <Loading />}

      <Create open={dialogCreate} onClose={() => setDialogCreate(false)} />

      <Filter
        open={dialogFilter}
        title='Filter'
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
        onReset={() => {
          setFilter({
            status: ''
          })
          setPayload({
            ...payload,
            start: 0,
            status: ''
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({ ...payload, start: 0, ...filter })
          onFilter(false)
        }}
      >
        <FormControl fullWidth>
          <InputLabel id='select-label'>Status</InputLabel>
          <Select
            labelId='select-label'
            id='id-select-label'
            value={filter.status}
            label='Status'
            onChange={(event) => setFilter({ ...filter, status: event.target.value })}
          >
            <MenuItem value='Enabled'>Enabled</MenuItem>
            <MenuItem value='Disabled'>Disabled</MenuItem>
          </Select>
        </FormControl>
      </Filter>

      <Notification
        open={!account.isLoading && !account.isUninitialized && !account.isSuccess}
        onClose={() => (account.isError ? account.reset() : location.reload())}
        isError={Boolean(account.error) && account.isError}
        message={GLOBAL.returnExceptionMessage(account.isError, account.error as ErrorProps)}
      />

      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />

      <Notification
        open={!update.isLoading && !update.isUninitialized}
        onClose={() => (update.isError ? update.reset() : location.reload())}
        isError={Boolean(update.error) && update.isError}
        message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
      />
    </>
  )
}

export default Account
