import { useEffect } from 'react'
import { FormikProvider, useFormik } from 'formik'
import { Container, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import * as yup from 'yup'
import Dialog from 'components/dialog/dialog'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { PartialHyundaiCatalogCreateProps } from 'modules/partial'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import {
  useGetDetailHyundaiCatalogQuery,
  useUpdateHyundaiCatalogMutation
} from 'store/hyundaiModel'
import {
  useGetCarModelDropdownListMutation,
  useGetYearsDropdownListByMutation
} from 'store/dropdown'
import DialogStyle from './style'

const Edit = ({ open, onClose, id }: DetailStateProps & { id: number }) => {
  const { data, isFetching, isLoading } = useGetDetailHyundaiCatalogQuery(id)
  const [getYearsDropdownListBy, years] = useGetYearsDropdownListByMutation()
  const [getCarModelDropdownList, model] = useGetCarModelDropdownListMutation()
  const [updateHyundaiCatalog, update] = useUpdateHyundaiCatalogMutation()

  const onDefault = (val: boolean | string) => (val ? 'true' : 'false')

  useEffect(() => {
    getYearsDropdownListBy()
    getCarModelDropdownList()
  }, [])

  const scheme = yup.object<PartialHyundaiCatalogCreateProps>({
    Trim: yup.string().required('Trim is required'),
    Color: yup.string().required('Color is required'),
    ColorCode: yup.string().required('ColorCode is required'),
    Year: yup.string().required('Year is required'),
    Description: yup.string().required('Default is required'),
    Remark: yup.string().required('Remark is required'),
    CarModelId: yup.string().required('Car Model Type is required'),
    LtmId: yup.string().required('LtmId is required'),
    LtmName: yup.string().required('LtmName is required')
  })

  const formik = useFormik<PartialHyundaiCatalogCreateProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      Trim: (data && data.trim) || '',
      Color: (data && data.color) || '',
      ColorCode: (data && data.colorCode) || '',
      Year: (data && data.year) || null,
      IsDefault: (data && onDefault(data.isDefault)) || '',
      Description: (data && data.description) || '',
      Remark: (data && data.remark) || '',
      CarModelId: (data && data.carModelId) || null,
      LtmId: (data && data.ltmId) || '',
      LtmName: (data && data.ltmName) || ''
    },
    onSubmit: (values: PartialHyundaiCatalogCreateProps) => updateHyundaiCatalog(values)
  })
  return (
    <>
      <Dialog
        open={open}
        title='Update Hyundai Catalog'
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          {(isLoading || isFetching) && <Loading />}
          {data && (
            <FormikProvider value={formik}>
              <TextField
                id='Trim'
                variant='outlined'
                label='Trim'
                onChange={formik.handleChange}
                value={formik.values.Trim}
                onBlur={formik.handleBlur}
                error={formik.touched && formik.touched.Trim && Boolean(formik.errors.Trim)}
                helperText={
                  formik.touched && formik.touched.Trim && formik.errors && formik.errors.Trim
                }
                fullWidth
              />
              <FormControl>
                <InputLabel id='Year'>Year</InputLabel>
                <Select
                  labelId='Year'
                  id='Year'
                  value={formik.values.Year}
                  label='Visibility'
                  onChange={(event) => formik.setFieldValue('Year', event.target.value)}
                >
                  {years.data &&
                    years.isSuccess &&
                    years.data.map((item, index) => (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <TextField
                id='Color'
                variant='outlined'
                label='Color'
                value={formik.values.Color}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched && formik.touched.Color && Boolean(formik.errors.Color)}
                helperText={
                  formik.touched && formik.touched.Color && formik.errors && formik.errors.Color
                }
                fullWidth
              />
              <TextField
                id='ColorCode'
                variant='outlined'
                label='Color Code'
                value={formik.values.ColorCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched && formik.touched.ColorCode && Boolean(formik.errors.ColorCode)
                }
                helperText={
                  formik.touched &&
                  formik.touched.ColorCode &&
                  formik.errors &&
                  formik.errors.ColorCode
                }
                fullWidth
              />

              <FormControl>
                <InputLabel id='Model'>Model</InputLabel>
                <Select
                  labelId='Model'
                  id='CarModelId'
                  value={formik.values.CarModelId}
                  label='Visibility'
                  onChange={(event) => formik.setFieldValue('CarModelId', event.target.value)}
                >
                  {model.data &&
                    model.isSuccess &&
                    model.data.map((item, index) => (
                      <MenuItem value={item.id} key={index}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id='IsDefault'>Default</InputLabel>
                <Select
                  labelId='IsDefault'
                  id='IsDefault'
                  value={formik.values.IsDefault}
                  label='Visibility'
                  onChange={(event) => formik.setFieldValue('IsDefault', event.target.value)}
                >
                  <MenuItem value='true'>Yes</MenuItem>
                  <MenuItem value='false'>No</MenuItem>
                </Select>
              </FormControl>
              <TextField
                id='LtmId'
                variant='outlined'
                label='LtmId'
                value={formik.values.LtmId}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched && formik.touched.LtmId && Boolean(formik.errors.LtmId)}
                helperText={
                  formik.touched && formik.touched.LtmId && formik.errors && formik.errors.LtmId
                }
                fullWidth
              />
              <TextField
                id='LtmName'
                variant='outlined'
                label='LtmName'
                value={formik.values.LtmName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched && formik.touched.LtmName && Boolean(formik.errors.LtmName)}
                helperText={
                  formik.touched && formik.touched.LtmName && formik.errors && formik.errors.LtmName
                }
                fullWidth
              />

              <TextField
                id='Remark'
                variant='outlined'
                label='Remark'
                value={formik.values.Remark}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched && formik.touched.Remark && Boolean(formik.errors.Remark)}
                helperText={
                  formik.touched && formik.touched.Remark && formik.errors && formik.errors.Remark
                }
                fullWidth
              />
              <TextField
                id='Description'
                variant='outlined'
                label='Description'
                value={formik.values.Description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched && formik.touched.Description && Boolean(formik.errors.Description)
                }
                helperText={
                  formik.touched &&
                  formik.touched.Description &&
                  formik.errors &&
                  formik.errors.Description
                }
                fullWidth
              />
            </FormikProvider>
          )}
        </Container>
      </Dialog>

      <Notification
        open={!update.isLoading && !update.isUninitialized}
        onClose={() => (update.isError ? update.reset() : location.reload())}
        isError={Boolean(update.error) && update.isError}
        message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
      />
    </>
  )
}

export default Edit
