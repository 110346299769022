import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TableCell,
  TableRow
} from '@mui/material'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import Icon from 'components/icon'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import CONSTANT from 'modules/constant'
import GLOBAL from 'modules/global'
import {
  useDeleteEventLocationMutation,
  useDownloadEventLocationMutation,
  useDownloadTemplateEventLocationMutation,
  useGetEventLocationMutation,
  useUpdateEventLocationMutation
} from 'store/event'
import type { PartialEventLocationProps } from 'modules/partial'
import type { ErrorProps } from 'modules/types'
import MenuModalItem from 'components/menuItem/menuItem'
import Upload from './upload/upload'
import Detail from './detail/detail'
import Create from './create/create'
import Edit from './edit/edit'

const Event = () => {
  const [getEventLocation, eventLocation] = useGetEventLocationMutation()
  const [downloadEventLocation, download] = useDownloadEventLocationMutation()
  const [updateEventLocation, update] = useUpdateEventLocationMutation()
  const [downloadTemplateEventLocation, template] = useDownloadTemplateEventLocationMutation()
  const [deleteEventLocation, deleteEvent] = useDeleteEventLocationMutation()
  const [dialogFilter, setDialogFilter] = useState<boolean>(false)

  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [menu, setMenu] = useState<null | number>(null)
  const [dialogUpload, setDialogUpload] = useState<boolean>(false)
  const [dialogCreate, setDialogCreate] = useState<boolean>(false)
  const [dialogUpdate, setDialogUpdate] = useState<boolean>(false)

  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)

  const [filter, setFilter] = useState({
    status: '',
    city: '',
    district: '',
    subDistrict: ''
  })

  const [payload, setPayload] = useState<PartialEventLocationProps>({
    start: 0,
    length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    field: CONSTANT.DEFAULT_EVENT_FIELD.join(','),
    search: { value: '' },
    status: ''
  })

  const onDownloadTemplate = (type?: string) => {
    const formData = new FormData()

    if (type && !isEmpty(type)) formData.append('Download-Type', type)

    downloadTemplateEventLocation(formData)
  }

  const onFilter = (state: boolean) => setDialogFilter(state)

  const onResync = () => {
    const formData = new FormData()

    formData.append('start', payload.start.toString())
    formData.append('length', payload.length.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(payload.status)) formData.append('status', payload.status)

    getEventLocation(formData)
  }

  useEffect(() => {
    onResync()
  }, [payload])

  const onUpload = () => setDialogUpload(true)

  const onChangePage = (value: number) => setPayload({ ...payload, start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, length: value })

  const onSearch = (value: string) => setPayload({ ...payload, start: 0, search: { value } })

  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('start', String(0))
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(payload.status)) formData.append('status', payload.status)
    if (type && !isEmpty(type)) formData.append('Download-Type', type)

    downloadEventLocation(formData)
  }

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }
  const tableHeadTitles = [
    'Event Id',
    'Event Name',
    'Address',
    'City',
    'District',
    'Subdistrict',
    'Postal Code',
    'Status'
  ]
  const onCreate = () => setDialogCreate(true)

  const openAnchor = Boolean(anchor)

  const totalPage = eventLocation.data && GLOBAL.tableTotalPage(eventLocation.data.recordsTotal)

  const numbers = GLOBAL.tableNumber(payload.start)

  return (
    <>
      <Content
        title='Event Location'
        onSearch={onSearch}
        onResync={onResync}
        tableHeadTitles={tableHeadTitles}
        onDownload={onDownload}
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        onFilter={() => onFilter(true)}
        isLoading={eventLocation.isLoading}
        onAdditional={onCreate}
        additionalTitle='Create Event Location'
        additionalPrefixIcon='Create'
        onUpload={onUpload}
        uploadTitle='Upload csv'
        uploadPrefixIcon='FileUpload'
        totalPage={totalPage}
        page={payload.start + 1}
        totalRecords={eventLocation.data && eventLocation.data.recordsTotal}
        downloadLoading={download.isLoading}
        useDownloadDropdown
        onTemplateDownload={onDownloadTemplate}
        templateDownloadLoading={template.isLoading}
        templateTitle='Template Event Location'
        useTemplateDownloadDropdown
      >
        {eventLocation.isSuccess &&
          eventLocation.data &&
          eventLocation.data.data.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${item.id}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${item.id}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${item.id}`}
                  aria-labelledby={`button-${index}-${item.id}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View Detail
                  </MenuItem>
                  <MenuItem onClick={() => onRemoveAnchor(() => setDialogUpdate(true))}>
                    Edit
                  </MenuItem>
                  <MenuModalItem
                    title={item.status == 'Enabled' ? 'Disable' : 'Enable'}
                    onClick={() =>
                      onRemoveAnchor(() =>
                        updateEventLocation({
                          id: item.id,
                          status: item.status == 'Enabled' ? 'Disabled' : 'Enabled'
                        })
                      )
                    }
                  >
                    {item.status == 'Enabled' ? 'Disable' : 'Enable'}
                  </MenuModalItem>
                  <MenuItem
                    onClick={() =>
                      onRemoveAnchor(() =>
                        deleteEventLocation({ id: item.id, status: item.status })
                      )
                    }
                    style={{ color: 'red' }}
                  >
                    Delete
                  </MenuItem>
                </Menu>
                {drawerDetail && menu == index && (
                  <Detail
                    id={item.id}
                    open={drawerDetail && menu == index}
                    onClose={() => setDrawerDetail(false)}
                  />
                )}
                {dialogUpdate && menu == index && (
                  <Edit
                    id={item.id.toString()}
                    open={dialogUpdate && menu == index}
                    onClose={() => setDialogUpdate(false)}
                  />
                )}
              </TableCell>
              <TableCell>{item.eventId || '-'}</TableCell>
              <TableCell>{item.eventName || '-'}</TableCell>
              <TableCell>{item.address || '-'}</TableCell>
              <TableCell>{item.city || '-'}</TableCell>
              <TableCell>{item.district || '-'}</TableCell>
              <TableCell>{item.subdistrict || '-'}</TableCell>
              <TableCell>{item.postalCode || '-'}</TableCell>
              <TableCell>
                <Chip label={item.status} color={item.status == 'Enabled' ? 'success' : 'error'} />
              </TableCell>
            </TableRow>
          ))}
      </Content>
      {eventLocation.isLoading && <Loading />}
      {dialogCreate && <Create open={dialogCreate} onClose={() => setDialogCreate(false)} />}

      <Filter
        open={dialogFilter}
        title='Filter'
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
        onReset={() => {
          setFilter({
            status: '',
            city: '',
            district: '',
            subDistrict: ''
          })
          setPayload({
            ...payload,
            start: 0
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({ ...payload, start: 0, ...filter })
          onFilter(false)
        }}
      >
        <FormControl fullWidth>
          <InputLabel id='select-label'>Status</InputLabel>
          <Select
            labelId='select-label'
            id='id-select-label'
            value={filter.status}
            label='Status'
            onChange={(event) => setFilter({ ...filter, status: event.target.value })}
          >
            <MenuItem value='Enabled'>Enabled</MenuItem>
            <MenuItem value='Disabled'>Disabled</MenuItem>
          </Select>
        </FormControl>
      </Filter>

      {dialogUpload && <Upload open={dialogUpload} onClose={() => setDialogUpload(false)} />}

      <Notification
        open={
          !eventLocation.isLoading && !eventLocation.isUninitialized && !eventLocation.isSuccess
        }
        onClose={() => (eventLocation.isError ? {} : location.reload())}
        isError={Boolean(eventLocation.error) && eventLocation.isError}
        message={GLOBAL.returnExceptionMessage(
          eventLocation.isError,
          eventLocation.error as ErrorProps
        )}
      />

      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />
      <Notification
        open={!deleteEvent.isLoading && !deleteEvent.isUninitialized}
        onClose={() => (deleteEvent.isError ? deleteEvent.reset() : location.reload())}
        isError={Boolean(deleteEvent.error) && deleteEvent.isError}
        message={GLOBAL.returnExceptionMessage(
          deleteEvent.isError,
          deleteEvent.error as ErrorProps
        )}
      />
      <Notification
        open={!update.isLoading && !update.isUninitialized}
        onClose={() => (update.isError ? update.reset() : location.reload())}
        isError={Boolean(update.error) && update.isError}
        message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
      />
    </>
  )
}

export default Event
