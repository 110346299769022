import CONSTANT from 'modules/constant'
import type {
  DropdownCategoryListProps,
  PaginationProps,
  ResultProps,
  VoucherListPackageProps,
  VoucherPackageDetailProps,
  VoucherPackageListProps
} from 'modules/types'
import { API, download, headers } from './api'

const VoucherPackageAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getListVoucherPackage: builder.mutation<PaginationProps<VoucherPackageListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_PACKAGE,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body
      })
    }),
    getVoucherPackageDetail: builder.query<VoucherPackageDetailProps, number | string>({
      query: (Id) => ({
        url: CONSTANT.URL_VOUCHER_PACKAGE_DETAIL + Id,
        method: 'GET',
        headers: headers()
      })
    }),
    getVoucherPackageDropdown: builder.mutation<VoucherListPackageProps[], number | string>({
      query: (Id) => ({
        url: CONSTANT.URL_VOUCHER_PACKAGE_DROPDOWN + Id,
        method: 'GET',
        headers: headers()
      })
    }),
    createVoucherPackage: builder.mutation<ResultProps, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_PACKAGE_CREATE,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body
      })
    }),
    deleteVoucherPackage: builder.mutation<ResultProps, { Id: number | string }>({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_PACKAGE_DELETE + body.Id,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    getCarModelPackageDropdown: builder.mutation<DropdownCategoryListProps[], void>({
      query: () => ({
        url: CONSTANT.URL_VOUCHER_CARMODEL_DROPDOWN,
        method: 'GET',
        headers: headers()
      })
    }),
    getVoucherFilterDropdown: builder.mutation<DropdownCategoryListProps[], void>({
      query: () => ({
        url: CONSTANT.URL_VOUCHER_PACKAGE_FILTER,
        method: 'GET',
        headers: headers()
      })
    }),
    enableVoucherPackage: builder.mutation<ResultProps, { Id: number | string; Status: string }>({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_PACKAGE_ENABLE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    downloadVoucherPackage: builder.mutation<PaginationProps<VoucherPackageListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_PACKAGE_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        responseHandler: download,
        body: body,
        formData: true
      })
    }),
    updateVoucherPackage: builder.mutation<ResultProps, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_VOUCHER_PACKAGE_UPDATE,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body
      })
    })
  })
})

export const {
  useGetListVoucherPackageMutation,
  useGetVoucherPackageDetailQuery,
  useGetVoucherPackageDropdownMutation,
  useCreateVoucherPackageMutation,
  useDeleteVoucherPackageMutation,
  useGetCarModelPackageDropdownMutation,
  useGetVoucherFilterDropdownMutation,
  useEnableVoucherPackageMutation,
  useDownloadVoucherPackageMutation,
  useUpdateVoucherPackageMutation
} = VoucherPackageAPI
