import CONSTANT from 'modules/constant'
import type { EvChargeBoxGroupProps, PaginationProps } from 'modules/types'
import { API, download, headers } from './api'

const EvChargePointBoxGroupAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getChargePointBoxGroupList: builder.mutation<
      PaginationProps<EvChargeBoxGroupProps>,
      { body: FormData; id: string }
    >({
      query: (request) => ({
        url: CONSTANT.URL_EV_CHARGE_POINT_BOX_GROUP_LIST + request.id + '/group/data',
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: request.body,
        formData: true
      })
    }),
    downloadChargePointBoxGroupList: builder.mutation<
      PaginationProps<EvChargeBoxGroupProps>,
      { body: FormData; id: string }
    >({
      query: (request) => ({
        url: CONSTANT.URL_EV_CHARGE_POINT_BOX_GROUP_DOWNLOAD + request.id + '/group/data/export',
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: request.body,
        formData: true,
        responseHandler: download
      })
    })
    // getChargePointBoxGroupDetail: builder.query({
    //   query: (id) => ({
    //     url: CONSTANT.URL_EV_CHARGE_POINT_BOX_GROUP_DETAIL + id,
    //     method: 'GET',
    //     headers: headers()
    //   })
    // }),
    // createChargePointBoxGroup: builder.mutation({
    //   query: (body) => ({
    //     url: CONSTANT.URL_EV_CHARGE_POINT_BOX_GROUP_CREATE,
    //     method: 'POST',
    //     headers: headers({ useContentType: false }),
    //     body: body,
    //     formData: true
    //   })
    // }),
    // updateChargePointBoxGroup: builder.query({
    //   query: (id) => ({
    //     url: CONSTANT.URL_EV_CHARGE_POINT_BOX_GROUP_UPDATE + id,
    //     method: 'PUT',
    //     headers: headers()
    //   })
    // }),
    // deleteChargePointBoxGroup: builder.query({
    //   query: (id) => ({
    //     url: CONSTANT.URL_EV_CHARGE_POINT_BOX_GROUP_DELETE + id,
    //     method: 'DELETE',
    //     headers: headers()
    //   })
    // })
  })
})

export const {
  useGetChargePointBoxGroupListMutation,
  useDownloadChargePointBoxGroupListMutation
  // useCreateChargePointBoxGroupMutation,
  // useGetChargePointBoxGroupDetailQuery,
  // useUpdateChargeBoxPointGroupQuery,
  // useDeleteChargeBoxPointGroupQuery
} = EvChargePointBoxGroupAPI
