import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
  CircularProgress,
  TextField,
  Autocomplete,
  FormControl,
  InputLabel,
  Select
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useNavigate } from 'react-router-dom'
import {
  useDownloadVoucherUsageListMutation,
  useGetVoucherUsageDateTimeRangeDropdownMutation,
  useGetVoucherUsageListMutation,
  useGetVoucherUsageStatusDropdownMutation
} from 'store/voucherUsage'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import CONSTANT from 'modules/constant'
import type { PartialFormDataCapsProps, PartialVoucherUsageProps } from 'modules/partial'
import Notification from 'components/notification'
import type { ErrorProps } from 'modules/types'
import GLOBAL from 'modules/global'
import Loading from 'components/loading/loading'
import Icon from 'components/icon'
import GlobalStyle from 'modules/styles'
import {
  useGetVoucherCategoryDropdownMutation,
  useGetVoucherSubCategoryDropdownMutation
} from 'store/voucherCategory'
import { useGetListPartnerLocationDropdownMutation } from 'store/locationPartner'
import Detail from './detail/detail'

const VoucherUsageList = () => {
  const navigate = useNavigate()

  const [getVoucherUsageList, listVoucherUsage] = useGetVoucherUsageListMutation()
  const [downloadVoucherUsageList, download] = useDownloadVoucherUsageListMutation()

  const [getVoucherCategoryDropdown, dropdownCategory] = useGetVoucherCategoryDropdownMutation()
  const [getVoucherSubCategoryDropdown, dropdownSubCategory] =
    useGetVoucherSubCategoryDropdownMutation()
  const [getPartnerDropdownList, partnerDropdown] = useGetListPartnerLocationDropdownMutation()
  const [getVoucherUsageStatusDropdownList, statusDropdown] =
    useGetVoucherUsageStatusDropdownMutation()
  const [getVoucherUsageDateTimeRangeDropdownList, dateTimeRangeDropdown] =
    useGetVoucherUsageDateTimeRangeDropdownMutation()

  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [menu, setMenu] = useState<null | number>(null)
  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [randomKey, setRandomKey] = useState<number>(0)

  const [filter, setFilter] = useState<PartialVoucherUsageProps>({
    CategoryId: '',
    SubCategoryId: '',
    CompanyId: '',
    MinValidFrom: '',
    MaxValidFrom: '',
    MinValidUntil: '',
    MaxValidUntil: '',
    Status: '',
    DateTimeRange: '',
    HasExpired: ''
  })

  const [payload, setPayload] = useState<PartialVoucherUsageProps & PartialFormDataCapsProps>({
    Start: 0,
    Length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    Search: { value: '' },
    CategoryId: '',
    SubCategoryId: '',
    CompanyId: '',
    MinValidFrom: '',
    MaxValidFrom: '',
    MinValidUntil: '',
    MaxValidUntil: '',
    Status: '',
    DateTimeRange: '',
    HasExpired: ''
  })

  const onSearch = (value: string) => setPayload({ ...payload, Start: 0, Search: { value } })
  const onChangePage = (value: number) => setPayload({ ...payload, Start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, Length: value })

  const onResync = () => {
    const formData = new FormData()

    formData.append('Start', payload.Start.toString())
    formData.append('Length', payload.Length.toString())

    if (!isEmpty(payload.Search) && !isEmpty(payload.Search.value))
      formData.append('Search', payload.Search.value)

    if (!isEmpty(payload.SubCategoryId)) formData.append('SubCategoryId', payload.SubCategoryId)
    if (!isEmpty(payload.CompanyId)) formData.append('CompanyId', payload.CompanyId)
    if (!isEmpty(payload.MinValidFrom)) formData.append('MinValidFrom', payload.MinValidFrom)
    if (!isEmpty(payload.MaxValidFrom)) formData.append('MaxValidFrom', payload.MaxValidFrom)
    if (!isEmpty(payload.MinValidUntil)) formData.append('MinValidUntil', payload.MinValidUntil)
    if (!isEmpty(payload.MaxValidUntil)) formData.append('MaxValidUntil', payload.MaxValidUntil)
    if (!isEmpty(payload.Status)) formData.append('Status', payload.Status)
    if (!isEmpty(payload.DateTimeRange)) formData.append('DateTimeRange', payload.DateTimeRange)
    if (!isEmpty(payload.HasExpired)) formData.append('HasExpired', String(payload.HasExpired))

    getVoucherUsageList(formData)
  }

  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('start', String(0))
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())

    if (!isEmpty(payload.Search) && !isEmpty(payload.Search.value))
      formData.append('Search', payload.Search.value)

    if (!isEmpty(payload.SubCategoryId)) formData.append('SubCategoryId', payload.SubCategoryId)
    if (!isEmpty(payload.CompanyId)) formData.append('CompanyId', payload.CompanyId)
    if (!isEmpty(payload.MinValidFrom)) formData.append('MinValidFrom', payload.MinValidFrom)
    if (!isEmpty(payload.MaxValidFrom)) formData.append('MaxValidFrom', payload.MaxValidFrom)
    if (!isEmpty(payload.MinValidUntil)) formData.append('MinValidUntil', payload.MinValidUntil)
    if (!isEmpty(payload.MaxValidUntil)) formData.append('MaxValidUntil', payload.MaxValidUntil)
    if (!isEmpty(payload.Status)) formData.append('Status', payload.Status)
    if (!isEmpty(payload.DateTimeRange)) formData.append('DateTimeRange', payload.DateTimeRange)
    if (!isEmpty(payload.HasExpired)) formData.append('HasExpired', String(payload.HasExpired))

    if (type && !isEmpty(type)) formData.append('Content-Type', type)

    downloadVoucherUsageList(formData)
  }

  const onFilter = (state: boolean) => setDialogFilter(state)

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback: CallableFunction) => {
    setAnchor(null)
    callback(callback)
  }

  const tableHeadTitles = [
    'Voucher Name',
    'Partner',
    'Token Count',
    'Available',
    'Purchased',
    'Claimed',
    'Revoked',
    'Expired'
  ]

  const openAnchor = Boolean(anchor)
  const totalPage =
    listVoucherUsage.data && GLOBAL.tableTotalPage(listVoucherUsage.data.recordsTotal)
  const numbers = GLOBAL.tableNumber(payload.Start)

  useEffect(() => {
    onResync()
  }, [payload])

  return (
    <>
      <Content
        title='Voucher Usage List'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={listVoucherUsage.data && listVoucherUsage.data.recordsTotal}
        page={payload.Start + 1}
        isLoading={listVoucherUsage.isLoading}
        useDownloadDropdown
      >
        {listVoucherUsage.isSuccess &&
          listVoucherUsage.data &&
          listVoucherUsage.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.id}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.id}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.id}`}
                  aria-labelledby={`button-${index}-${table.id}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View Detail
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      onRemoveAnchor(() =>
                        navigate(GLOBAL.encodeUrl(String(table.id)), {
                          state: { voucherName: table.voucherName }
                        })
                      )
                    }
                  >
                    View Token List
                  </MenuItem>
                </Menu>
                <Detail
                  open={drawerDetail && menu == index}
                  onClose={() => setDrawerDetail(false)}
                  id={table.id}
                />
              </TableCell>
              <TableCell>{table.voucherName || '-'}</TableCell>
              <TableCell>{table.partnerName || '-'}</TableCell>
              <TableCell>{table.tokenCount}</TableCell>
              <TableCell>{table.available}</TableCell>
              <TableCell>{table.purchased}</TableCell>
              <TableCell>{table.claimed}</TableCell>
              <TableCell>{table.revoked}</TableCell>
              <TableCell>{table.expired}</TableCell>
            </TableRow>
          ))}
      </Content>

      {listVoucherUsage.isLoading && <Loading />}

      <Filter
        open={dialogFilter}
        title='Filter'
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        onReset={() => {
          setRandomKey(randomKey + 1)
          setFilter({
            CategoryId: '',
            SubCategoryId: '',
            CompanyId: '',
            MinValidFrom: '',
            MaxValidFrom: '',
            MinValidUntil: '',
            MaxValidUntil: '',
            Status: '',
            DateTimeRange: '',
            HasExpired: ''
          })
          setPayload({
            ...payload,
            Start: 0,
            CategoryId: '',
            SubCategoryId: '',
            CompanyId: '',
            MinValidFrom: '',
            MaxValidFrom: '',
            MinValidUntil: '',
            MaxValidUntil: '',
            Status: '',
            DateTimeRange: '',
            HasExpired: ''
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({ ...payload, Start: 0, ...filter })
          onFilter(false)
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
          <DatePicker
            value={filter.MinValidFrom ? dayjs(filter.MinValidFrom) : null}
            minDate={dayjs(filter.MinValidFrom)}
            onChange={(event: Dayjs | null) =>
              setFilter({ ...filter, MinValidFrom: (event && event.format('YYYY-MM-DD')) || '' })
            }
            label='Min Valid From'
            format='DD-MM-YYYY'
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
          <DatePicker
            value={filter.MaxValidFrom ? dayjs(filter.MaxValidFrom) : null}
            minDate={dayjs(filter.MinValidFrom)}
            onChange={(event: Dayjs | null) =>
              setFilter({ ...filter, MaxValidFrom: (event && event.format('YYYY-MM-DD')) || '' })
            }
            label='Max Valid From'
            format='DD-MM-YYYY'
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
          <DatePicker
            value={filter.MinValidUntil ? dayjs(filter.MinValidUntil) : null}
            minDate={dayjs(filter.MinValidFrom)}
            onChange={(event: Dayjs | null) =>
              setFilter({ ...filter, MinValidUntil: (event && event.format('YYYY-MM-DD')) || '' })
            }
            label='Min Valid Until'
            format='DD-MM-YYYY'
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
          <DatePicker
            value={filter.MaxValidUntil ? dayjs(filter.MaxValidUntil) : null}
            minDate={dayjs(filter.MinValidFrom)}
            onChange={(event: Dayjs | null) =>
              setFilter({ ...filter, MaxValidUntil: (event && event.format('YYYY-MM-DD')) || '' })
            }
            label='Max Valid Until'
            format='DD-MM-YYYY'
          />
        </LocalizationProvider>
        <Autocomplete
          key={'categoryId-' + randomKey}
          options={(dropdownCategory && dropdownCategory.data) || []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.id == value.id : false
          }
          onOpen={() => getVoucherCategoryDropdown()}
          onChange={(_, voucherCategory) => {
            dropdownSubCategory.reset()
            setFilter({
              ...filter,
              CategoryId: String((voucherCategory && voucherCategory.id) || ''),
              SubCategoryId: ''
            })
          }}
          value={
            (dropdownCategory &&
              dropdownCategory.data &&
              dropdownCategory.data.find((e) => String(e.id) == filter.CategoryId)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.id}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='categoryId'
              label='Category ID'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {dropdownCategory.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <Autocomplete
          key={'subCategoryId-' + randomKey}
          options={(dropdownSubCategory && dropdownSubCategory.data) || []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.id == value.id : false
          }
          onOpen={() => getVoucherSubCategoryDropdown({ id: filter.CategoryId })}
          onChange={(_, voucherSubCategory) =>
            setFilter({
              ...filter,
              SubCategoryId: String((voucherSubCategory && voucherSubCategory.id) || '')
            })
          }
          value={
            (dropdownSubCategory &&
              dropdownSubCategory.data &&
              dropdownSubCategory.data.find((e) => String(e.id) == filter.SubCategoryId)) ||
            undefined
          }
          disabled={!filter.CategoryId}
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.id}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='subCategoryId'
              label='Sub Category ID'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {dropdownSubCategory.isLoading && (
                      <CircularProgress color='inherit' size={20} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <Autocomplete
          options={(partnerDropdown && partnerDropdown.data) || []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.id == value.id : false
          }
          onOpen={() => getPartnerDropdownList()}
          onChange={(_, partner) =>
            setFilter({
              ...filter,
              CompanyId: (partner && String(partner.id)) || ''
            })
          }
          value={
            (partnerDropdown &&
              partnerDropdown.data &&
              partnerDropdown.data.find((e) => String(e.id) == filter.CompanyId)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.id}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='partnerId'
              label='Partner'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {partnerDropdown.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <Autocomplete
          options={(statusDropdown && statusDropdown.data) || []}
          getOptionLabel={(option) => option.text}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.value == value.value : false
          }
          onOpen={() => getVoucherUsageStatusDropdownList()}
          onChange={(_, status) =>
            setFilter({
              ...filter,
              Status: (status && String(status.value)) || ''
            })
          }
          value={
            (statusDropdown &&
              statusDropdown.data &&
              statusDropdown.data.find((e) => String(e.value) == filter.Status)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.value}>
              {item.text}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='status'
              label='Status'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {statusDropdown.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <FormControl fullWidth>
          <InputLabel id='hasExpired'>Expired (Yes/No)</InputLabel>
          <Select
            labelId='hasExpired'
            id='hasExpired'
            value={filter.HasExpired}
            label='Expired (Yes/No)'
            onChange={(event) => setFilter({ ...filter, HasExpired: event.target.value })}
          >
            <MenuItem value='true'>Yes</MenuItem>
            <MenuItem value='false'>No</MenuItem>
          </Select>
        </FormControl>
        <Autocomplete
          options={(dateTimeRangeDropdown && dateTimeRangeDropdown.data) || []}
          getOptionLabel={(option) => option.text}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.value == value.value : false
          }
          onOpen={() => getVoucherUsageDateTimeRangeDropdownList()}
          onChange={(_, dateTimeRange) =>
            setFilter({
              ...filter,
              DateTimeRange: (dateTimeRange && String(dateTimeRange.value)) || ''
            })
          }
          value={
            (dateTimeRangeDropdown &&
              dateTimeRangeDropdown.data &&
              dateTimeRangeDropdown.data.find((e) => String(e.value) == filter.DateTimeRange)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.value}>
              {item.text}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='dateTimeRange'
              label='Date Time Range'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {dateTimeRangeDropdown.isLoading && (
                      <CircularProgress color='inherit' size={20} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
      </Filter>

      <Notification
        open={
          !listVoucherUsage.isLoading &&
          !listVoucherUsage.isUninitialized &&
          !listVoucherUsage.isSuccess
        }
        onClose={() => (listVoucherUsage.isError ? listVoucherUsage.reset() : location.reload())}
        isError={Boolean(listVoucherUsage.error) && listVoucherUsage.isError}
        message={GLOBAL.returnExceptionMessage(
          listVoucherUsage.isError,
          listVoucherUsage.error as ErrorProps
        )}
      />

      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />
    </>
  )
}
export default VoucherUsageList
