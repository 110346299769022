import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Autocomplete,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  TableCell,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import CONSTANT from 'modules/constant'
import type { PartialChargeBoxFilterProps, PartialEVChargeProps } from 'modules/partial'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { ErrorProps } from 'modules/types'
import MenuModalItem from 'components/menuItem/menuItem'
import GlobalStyle from 'modules/styles'
import Icon from 'components/icon'
import {
  useGetChargeBoxListMutation,
  useDownloadChargeBoxListMutation,
  useDeleteChargeBoxMutation,
  useResyncChargeBoxListMutation
} from 'store/evChargeBox'
import Loading from 'components/loading/loading'
import { useGetChargePointDropdownListMutation } from 'store/evChargePoint'
import { useGetSocketTypeDropdownListMutation } from 'store/dropdown'
import Detail from './detail/detail'
import Create from './create/create'
import Edit from './edit/edit'

const EVChargeBox = () => {
  const [getChargeBox, chargeBox] = useGetChargeBoxListMutation()
  const [resyncChargeBox, resync] = useResyncChargeBoxListMutation()
  const [downloadChargeBox, download] = useDownloadChargeBoxListMutation()
  const [deleteChargeBox, deleteBox] = useDeleteChargeBoxMutation()

  const [getChargePointDropdownList, chargePointDropdown] = useGetChargePointDropdownListMutation()
  const [getSocketTypeDropdownList, socketTypeDropdown] = useGetSocketTypeDropdownListMutation()

  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [menu, setMenu] = useState<null | number>(null)
  const [dialogCreate, setDialogCreate] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [drawerUpdate, setDrawerUpdate] = useState<boolean>(false)

  const [filter, setFilter] = useState<PartialChargeBoxFilterProps>({
    ChargePointId: '',
    SocketType: '',
    ChargeLevel: '',
    Power: ''
  })

  const [payload, setPayload] = useState<PartialEVChargeProps & PartialChargeBoxFilterProps>({
    start: 0,
    length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    search: '',
    filter: '',
    sorting: 'id asc',
    ChargePointId: '',
    SocketType: '',
    ChargeLevel: '',
    Power: ''
  })

  const onResync = () => {
    const formData = new FormData()

    formData.append('start', payload.start.toString())
    formData.append('length', payload.length.toString())
    formData.append('sorting', payload.sorting)

    if (!isEmpty(payload.search)) formData.append('search', payload.search)
    if (!isEmpty(payload.filter)) formData.append('filter', payload.filter)
    if (!isEmpty(payload.ChargePointId)) formData.append('ChargePointId', payload.ChargePointId)
    if (!isEmpty(payload.SocketType)) formData.append('SocketType', payload.SocketType)
    if (!isEmpty(payload.Power)) formData.append('Power', payload.Power)

    getChargeBox(formData)
  }

  const onResyncThirdParty = async () => await resyncChargeBox().then(() => onResync())

  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('start', String(0))
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())
    formData.append('sorting', payload.sorting)

    if (!isEmpty(payload.search)) formData.append('search', payload.search)
    if (!isEmpty(payload.filter)) formData.append('filter', payload.filter)
    if (!isEmpty(payload.ChargePointId)) formData.append('ChargePointId', payload.ChargePointId)
    if (!isEmpty(payload.SocketType)) formData.append('SocketType', payload.SocketType)
    if (!isEmpty(payload.ChargeLevel)) formData.append('ChargeLevel', payload.ChargeLevel)
    if (!isEmpty(payload.Power)) formData.append('Power', payload.Power)
    if (type && !isEmpty(type)) formData.append('Content-Type', type)

    downloadChargeBox(formData)
  }

  const onSearch = (value: string) => setPayload({ ...payload, start: 0, search: value })
  const onChangePage = (value: number) => setPayload({ ...payload, start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, length: value })

  const onFilter = (state: boolean) => setDialogFilter(state)
  const onCreate = () => setDialogCreate(true)

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const tableHeadTitles = [
    'Charge Point Name',
    'Charge Box Group ID',
    'Charge Box ID',
    'Charge Box Name',
    'Socket Type',
    'Charge Level',
    'QR Code',
    'Status'
  ]

  const openAnchor = Boolean(anchor)

  const totalPage = chargeBox.data && GLOBAL.tableTotalPage(chargeBox.data.recordsTotal)

  const numbers = GLOBAL.tableNumber(payload.start)

  useEffect(() => {
    onResync()
  }, [payload])

  return (
    <>
      <Content
        title='EV Charge Box'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResyncThirdParty}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        onAdditional={onCreate}
        additionalTitle='Create New EV Charge Box'
        additionalPrefixIcon='Create'
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={chargeBox.data && chargeBox.data.recordsTotal}
        page={payload.start + 1}
        isLoading={chargeBox.isLoading || resync.isLoading}
        useDownloadDropdown
      >
        {chargeBox.isSuccess &&
          chargeBox.data &&
          chargeBox.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.id}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.id}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.id}`}
                  aria-labelledby={`button-${index}-${table.id}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View
                  </MenuItem>
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerUpdate(true))}>
                    Edit
                  </MenuItem>
                  <MenuModalItem
                    title='Delete'
                    loading={deleteBox.isLoading}
                    onClick={() => onRemoveAnchor(() => deleteChargeBox(table.id))}
                  >
                    <Typography {...GlobalStyle.ErrorTypography}>Delete</Typography>
                  </MenuModalItem>
                </Menu>
                <Detail
                  id={table.id}
                  open={drawerDetail && menu == index}
                  onClose={() => setDrawerDetail(false)}
                />
                <Edit
                  id={table.id}
                  open={drawerUpdate && menu == index}
                  onClose={() => setDrawerUpdate(false)}
                />
              </TableCell>
              <TableCell>{table.chargePointName || '-'}</TableCell>
              <TableCell>{table.chargeBoxGroupCode || '-'}</TableCell>
              <TableCell>{table.code || '-'}</TableCell>
              <TableCell>{table.label || '-'}</TableCell>
              <TableCell>{table.socketType || '-'}</TableCell>
              <TableCell>{table.chargeLevel || '-'}</TableCell>
              <TableCell>{table.qrCode || '-'}</TableCell>
              <TableCell>{table.status || '-'}</TableCell>
            </TableRow>
          ))}
      </Content>

      {(chargeBox.isLoading || resync.isLoading) && <Loading />}

      <Create open={dialogCreate} onClose={() => setDialogCreate(false)} />

      <Filter
        open={dialogFilter}
        title='Filter'
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        onReset={() => {
          setFilter({
            ChargePointId: '',
            SocketType: '',
            ChargeLevel: '',
            Power: ''
          })
          setPayload({
            ...payload,
            ChargePointId: '',
            SocketType: '',
            ChargeLevel: '',
            Power: '',
            start: 0
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({
            ...payload,
            ...filter,
            start: 0
          })
          onFilter(false)
        }}
      >
        <Autocomplete
          options={(chargePointDropdown && chargePointDropdown.data) || []}
          getOptionLabel={(option) => option.text}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.value == value.value : false
          }
          onOpen={() => getChargePointDropdownList(CONSTANT.DEFAULT_DROPDOWN_PAYLOAD)}
          onChange={(_, chargePoint) =>
            setFilter({
              ...filter,
              ChargePointId: String((chargePoint && chargePoint.value) || '')
            })
          }
          value={
            (chargePointDropdown &&
              chargePointDropdown.data &&
              chargePointDropdown.data.find((e) => String(e.value) == filter.ChargePointId)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.value}>
              {item.text}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='ChargePointId'
              label='EV Charge Point'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {chargePointDropdown.isLoading && (
                      <CircularProgress color='inherit' size={20} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <Autocomplete
          options={(socketTypeDropdown && socketTypeDropdown.data) || []}
          getOptionLabel={(option) => option.text}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.value == value.value : false
          }
          onOpen={() => getSocketTypeDropdownList(CONSTANT.DEFAULT_DROPDOWN_PAYLOAD)}
          onChange={(_, socketType) =>
            setFilter({
              ...filter,
              SocketType: String((socketType && socketType.value) || '')
            })
          }
          value={
            (socketTypeDropdown &&
              socketTypeDropdown.data &&
              socketTypeDropdown.data.find((e) => String(e.value) == filter.SocketType)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.value}>
              {item.text}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='SocketType'
              label='Connector Type'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {socketTypeDropdown.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <FormControl fullWidth>
          <InputLabel htmlFor='ChargeLevel'>Charge Level</InputLabel>
          <OutlinedInput
            id='ChargeLevel'
            label='Charge Level'
            type='number'
            value={filter.ChargeLevel}
            onChange={(event) => setFilter({ ...filter, ChargeLevel: event.target.value })}
            endAdornment={<div>%</div>}
          />
        </FormControl>
        <TextField
          id='Power'
          variant='outlined'
          label='Charging Capacity'
          type='number'
          value={filter.Power}
          onChange={(event) => setFilter({ ...filter, Power: event.target.value })}
          fullWidth
        />
      </Filter>

      <Notification
        open={!chargeBox.isLoading && !chargeBox.isUninitialized && !chargeBox.isSuccess}
        onClose={() => (chargeBox.isError ? chargeBox.reset() : location.reload())}
        isError={Boolean(chargeBox.error) && chargeBox.isError}
        message={GLOBAL.returnExceptionMessage(chargeBox.isError, chargeBox.error as ErrorProps)}
      />

      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />

      <Notification
        open={!deleteBox.isLoading && !deleteBox.isUninitialized}
        onClose={() => (deleteBox.isError ? deleteBox.reset() : location.reload())}
        isError={Boolean(deleteBox.error) && deleteBox.isError}
        message={GLOBAL.returnExceptionMessage(deleteBox.isError, deleteBox.error as ErrorProps)}
      />
    </>
  )
}

export default EVChargeBox
