import { useState } from 'react'
import { useFormik } from 'formik'
import { isEmpty } from 'lodash'
import * as yup from 'yup'
import { Container, TextField } from '@mui/material'
import type { Dayjs } from 'dayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import Dialog from 'components/dialog/dialog'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { PartialPromoCodeSubProps } from 'modules/partial'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import { useUpdatePromoCodeBatchSubValidityMutation } from 'store/promoCodeBatch'
import DialogStyle from './style'

const Edit = ({ open, listChecked, onClose }: DetailStateProps & { listChecked: string[] }) => {
  if (!open) return <></>
  const [updatePromoCodeBatchSubValidity, update] = useUpdatePromoCodeBatchSubValidityMutation()
  const [date, setDate] = useState<{ validFrom: Dayjs | null; validTo: Dayjs | null }>({
    validFrom: null,
    validTo: null
  })
  const formatListChecked = listChecked.toString()

  const scheme = yup.object<{ validFrom: string; validTo: string }>({
    validFrom: yup.string().required('Valid From is required'),
    validTo: yup.string().required('Valid To is required')
  })

  const formik = useFormik<{ validFrom: string; validTo: string }>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      validFrom: '',
      validTo: ''
    },
    onSubmit: (values: { validFrom: string; validTo: string }) => handleSubmit(values)
  })
  const handleSubmit = (values: { validFrom: string; validTo: string }) => {
    const payload: PartialPromoCodeSubProps = {
      filter: {
        filterType: 'ContainsCodes',
        value: listChecked
      },
      ...values
    }
    updatePromoCodeBatchSubValidity(payload)
  }

  return (
    <>
      <Dialog
        open={open}
        title='Edit Validity'
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={update.isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          <>
            <TextField
              multiline
              rows={5}
              maxRows={5}
              disabled
              label={`${listChecked.length} Promo Code(s) found.`}
              value={formatListChecked}
              InputLabelProps={{ shrink: true }}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
              <DatePicker
                value={date.validFrom}
                sx={{ width: '100%' }}
                onChange={(e) => {
                  const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                  formik.setFieldValue('validFrom', newDate)
                  setDate((prevState) => ({ ...prevState, validFrom: e }))
                }}
                label='Valid From'
                format='DD-MM-YYYY'
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
              <DatePicker
                value={date.validTo}
                minDate={dayjs(formik.values.validFrom)}
                sx={{ width: '100%' }}
                onChange={(e) => {
                  const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                  formik.setFieldValue('validTo', newDate)
                  setDate((prevState) => ({ ...prevState, validTo: e }))
                }}
                label='Valid To'
                format='DD-MM-YYYY'
              />
            </LocalizationProvider>
          </>
        </Container>
      </Dialog>
      <Notification
        open={!update.isLoading && !update.isUninitialized}
        onClose={() => (update.isError ? update.reset() : location.reload())}
        isError={Boolean(update.error) && update.isError}
        message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
      />
    </>
  )
}

export default Edit
