import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  Typography
} from '@mui/material'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import CONSTANT from 'modules/constant'
import type { PartialPolicyChargerProps } from 'modules/partial'
import {
  useDeletePolicyMutation,
  useDownloadEvPolicyMutation,
  useGetPolicyMutation
} from 'store/catalogPolicy'
import Icon from 'components/icon'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { ErrorProps } from 'modules/types'
import MenuModalItem from 'components/menuItem/menuItem'
import GlobalStyle from 'modules/styles'
import Detail from './detail/detail'
import Create from './create/create'
import Edit from './edit/edit'

const EVPolicyCharger = () => {
  const [getPolicy, policy] = useGetPolicyMutation()
  const [deletePolicy, deletePol] = useDeletePolicyMutation()
  const [downloadEvPolicy, download] = useDownloadEvPolicyMutation()
  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [dialogCreate, setDialogCreate] = useState<boolean>(false)
  const [dialogUpdate, setDialogUpdate] = useState<boolean>(false)
  const [valFilter, setValFilter] = useState<string>('')
  const [filter, setFilter] = useState({
    IsKwhLimit: '',
    IsQuota: '',
    IsVoucher: '',
    IsRequestByPartner: ''
  })
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [menu, setMenu] = useState<null | number>(null)
  const [payload, setPayload] = useState<PartialPolicyChargerProps>({
    start: 0,
    length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    search: '',
    filter: '',
    sorting: '',
    field: '',
    IsKwhLimit: '',
    IsQuota: '',
    IsToken: '',
    IsRequestByPartner: ''
  })

  const onSearch = (value: string) => setPayload({ ...payload, start: 0, search: value })

  const onResync = () => {
    const formData = new FormData()
    formData.append('start', payload.start.toString())
    formData.append('length', payload.length.toString())

    if (!isEmpty(payload.search)) formData.append('search', payload.search)
    if (!isEmpty(payload.field)) formData.append('field', payload.field)
    if (!isEmpty(payload.sorting)) formData.append('sorting', payload.sorting)
    if (!isEmpty(payload.filter)) formData.append('filter', payload.filter)
    if (!isEmpty(payload.IsKwhLimit)) formData.append('IsKwhLimit', payload.IsKwhLimit)
    if (!isEmpty(payload.IsQuota)) formData.append('IsQuota', payload.IsQuota)
    if (!isEmpty(payload.IsToken)) formData.append('IsToken', payload.IsToken)
    if (!isEmpty(payload.IsRequestByPartner))
      formData.append('IsRequestByPartner', payload.IsRequestByPartner)

    getPolicy(formData)
  }
  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }
  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('start', payload.start.toString())
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())

    if (!isEmpty(payload.search)) formData.append('search', payload.search)
    if (!isEmpty(payload.field)) formData.append('field', payload.field)
    if (!isEmpty(payload.sorting)) formData.append('sorting', payload.sorting)
    if (!isEmpty(payload.filter)) formData.append('filter', payload.filter)
    if (!isEmpty(payload.IsKwhLimit)) formData.append('IsKwhLimit', payload.IsKwhLimit)
    if (!isEmpty(payload.IsQuota)) formData.append('IsQuota', payload.IsQuota)
    if (!isEmpty(payload.IsToken)) formData.append('IsToken', payload.IsToken)
    if (!isEmpty(payload.IsRequestByPartner))
      formData.append('IsRequestByPartner', payload.IsRequestByPartner)
    if (type && !isEmpty(type)) formData.append('Content-Type', type)

    downloadEvPolicy(formData)
  }
  const handleValueTable = (val: boolean) => (val ? 'true' : 'false')

  useEffect(() => {
    onResync()
  }, [payload])
  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onChangeFilter = (type: string) => {
    switch (type) {
      case 'IsKwhLimit':
        setFilter({ IsQuota: '', IsVoucher: '', IsRequestByPartner: '', IsKwhLimit: 'true' })
        break
      case 'IsQuota':
        setFilter({ IsQuota: 'true', IsVoucher: '', IsRequestByPartner: '', IsKwhLimit: '' })
        break
      case 'IsToken':
        setFilter({ IsQuota: '', IsVoucher: 'true', IsRequestByPartner: '', IsKwhLimit: '' })
        break
      case 'IsRequestByPartner':
        setFilter({ IsQuota: '', IsVoucher: '', IsRequestByPartner: 'true', IsKwhLimit: '' })
        break
      // code block
    }
    setValFilter(type)
  }

  const onFilter = (state: boolean) => setDialogFilter(state)
  const onCreate = () => setDialogCreate(true)
  const onChangePage = (value: number) => setPayload({ ...payload, start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, length: value })

  const tableHeadTitles = [
    'Policy Type',
    'Kwh Limit',
    'Qouta',
    'Pay As You Go',
    'Requested/Authorized by Partner'
  ]

  const openAnchor = Boolean(anchor)

  const totalPage = policy.data && GLOBAL.tableTotalPage(policy.data.recordsTotal)

  const numbers = GLOBAL.tableNumber(payload.start)

  return (
    <>
      <Content
        title='EV Policy Charger'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onFilter={() => onFilter(true)}
        onAdditional={onCreate}
        additionalTitle='Create New EV Policy Charger'
        additionalPrefixIcon='Create'
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={policy.data && policy.data.recordsTotal}
        page={payload.start + 1}
        isLoading={policy.isLoading}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        useDownloadDropdown
      >
        {policy.isSuccess &&
          policy.data &&
          policy.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.id}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.id}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.id}`}
                  aria-labelledby={`button-${index}-${table.id}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View Detail
                  </MenuItem>
                  <MenuItem onClick={() => onRemoveAnchor(() => setDialogUpdate(true))}>
                    Edit
                  </MenuItem>
                  <MenuModalItem
                    title={table.policyName}
                    onClick={() => onRemoveAnchor(() => deletePolicy(table.id))}
                  >
                    <Typography {...GlobalStyle.ErrorTypography}>Delete</Typography>
                  </MenuModalItem>
                </Menu>
                {drawerDetail && menu == index && (
                  <Detail
                    id={table.id}
                    open={drawerDetail && menu == index}
                    onClose={() => setDrawerDetail(false)}
                  />
                )}
                {dialogUpdate && menu == index && (
                  <Edit
                    id={table.id}
                    open={dialogUpdate && menu == index}
                    onClose={() => setDialogUpdate(false)}
                  />
                )}
              </TableCell>
              <TableCell>{table.policyName || '-'}</TableCell>
              <TableCell>{handleValueTable(table.isKwhLimit) || '-'}</TableCell>
              <TableCell>{handleValueTable(table.isQuota) || '-'}</TableCell>
              <TableCell>{handleValueTable(table.isToken) || '-'}</TableCell>
              <TableCell>{handleValueTable(table.isRequestByPartner) || '-'}</TableCell>
            </TableRow>
          ))}
      </Content>
      {dialogCreate && <Create open={dialogCreate} onClose={() => setDialogCreate(false)} />}
      {policy.isLoading && <Loading />}
      <Notification
        open={!policy.isLoading && !policy.isUninitialized && !policy.isSuccess}
        onClose={() => (policy.isError ? policy.reset() : location.reload())}
        isError={Boolean(policy.error) && policy.isError}
        message={GLOBAL.returnExceptionMessage(policy.isError, policy.error as ErrorProps)}
      />
      <Notification
        open={!deletePol.isLoading && !deletePol.isUninitialized}
        onClose={() => (deletePol.isError ? deletePol.reset() : location.reload())}
        isError={Boolean(deletePol.error) && deletePol.isError}
        message={GLOBAL.returnExceptionMessage(deletePol.isError, deletePol.error as ErrorProps)}
      />

      <Filter
        open={dialogFilter}
        title='Filter'
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
        onReset={() => {
          setValFilter('')
          setFilter({ IsKwhLimit: '', IsQuota: '', IsVoucher: '', IsRequestByPartner: '' })
          setPayload({
            start: 0,
            length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
            search: '',
            filter: '',
            sorting: '',
            field: '',
            IsKwhLimit: '',
            IsQuota: '',
            IsToken: '',
            IsRequestByPartner: ''
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({ ...payload, start: 0, ...filter })
          onFilter(false)
        }}
      >
        <FormControl fullWidth>
          <InputLabel id='Policy Name'>Policy</InputLabel>
          <Select
            labelId='Policy Name'
            id='Policy Name'
            value={valFilter}
            label='Visibility'
            onChange={(event) => onChangeFilter(event.target.value)}
          >
            <MenuItem value='IsQuota'>Qouta</MenuItem>
            <MenuItem value='IsRequestByPartner'>Request Partner</MenuItem>
            <MenuItem value='IsToken'>Pay As You Go </MenuItem>
            <MenuItem value='IsKwhLimit'>Kwh Limit</MenuItem>
          </Select>
        </FormControl>
      </Filter>
    </>
  )
}

export default EVPolicyCharger
