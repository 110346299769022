import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Grid,
  TextField,
  Typography
} from '@mui/material'
import { ExpandMoreOutlined } from '@mui/icons-material'
import Drawer from 'components/drawer/detail'
import Loading from 'components/loading/loading'
import { useGetFaqCategoryDetailQuery } from 'store/faq'
import type { DetailStateProps } from 'modules/types'
import type { PartialFaqCategoryDetailDataProps } from 'modules/partial'
import DrawerStyle from './style'

const Detail = ({ open, id, onClose }: DetailStateProps & { id: string }) => {
  if (!open) return <></>

  const { data, isFetching, isLoading } = useGetFaqCategoryDetailQuery({ key: id })

  const lang = ['EN-US', 'ID']

  const gridDataLabel = ['Tags', 'Name', 'Remark']
  const gridDataValue = ['tags', 'name', 'remark']

  const gridBasicLabel = ['Created By', 'Created At', 'Modified By', 'Modified At']
  const gridBasicValue = ['createdBy', 'createdAt', 'modifiedBy', 'modifiedAt']

  const list = data && data.data
  const key = data && data.key

  return (
    <>
      <Drawer open={open} title='Detail FAQ Category' onClose={onClose}>
        <Container {...DrawerStyle.Container}>
          {(isLoading || isFetching) && !data && <Loading />}
          {data && (
            <>
              <TextField
                id='key'
                variant='outlined'
                label='Category Name'
                value={key}
                inputProps={{ readOnly: true }}
                fullWidth
              />
              {lang.map((_, index) => (
                <>
                  <Grid container spacing={2}>
                    {gridDataLabel.map((title, id) => (
                      <Grid key={id} item xs={12}>
                        <TextField
                          variant='outlined'
                          label={title}
                          value={
                            (list &&
                              list[index][
                                gridDataValue[id] as keyof PartialFaqCategoryDetailDataProps
                              ]) ||
                            '-'
                          }
                          inputProps={{ readOnly: true }}
                          InputLabelProps={{
                            shrink: true
                          }}
                          fullWidth
                        />
                      </Grid>
                    ))}
                  </Grid>
                  <Accordion elevation={0} disableGutters>
                    <AccordionSummary
                      expandIcon={<ExpandMoreOutlined />}
                      aria-controls='basic-information'
                    >
                      <Typography>Basic Information</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        {gridBasicLabel.map((title, id) => (
                          <Grid key={id} item xs={12}>
                            <TextField
                              variant='outlined'
                              label={title}
                              value={
                                (list &&
                                  list[index][
                                    gridBasicValue[id] as keyof PartialFaqCategoryDetailDataProps
                                  ]) ||
                                '-'
                              }
                              inputProps={{ readOnly: true }}
                              InputLabelProps={{
                                shrink: true
                              }}
                              fullWidth
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </>
              ))}
            </>
          )}
        </Container>
      </Drawer>
    </>
  )
}

export default Detail
