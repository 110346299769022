import { useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import { isEmpty } from 'lodash'
import { Autocomplete, CircularProgress, Container, TextField } from '@mui/material'
import * as yup from 'yup'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import type { Dayjs } from 'dayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import Dialog from 'components/dialog/dialog'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { PartialTokenStateProps } from 'modules/partial'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import GlobalStyle from 'modules/styles'
import {
  useCreateTokenRequestMutation,
  useGetVoucherListDropdownMutation
} from 'store/tokenRequest'
import DialogStyle from './style'

const Create = ({ open, onClose }: DetailStateProps) => {
  const [createTokenRequest, create] = useCreateTokenRequestMutation()
  const [getVoucherListDropdown, voucherDropdown] = useGetVoucherListDropdownMutation()
  const [startDate, setStartDate] = useState<Dayjs | null>(null)
  const [endDate, setEndDate] = useState<Dayjs | null>(null)

  const scheme = yup.object<PartialTokenStateProps>({
    RequestName: yup.string().required('Request Name  is required'),
    VoucherId: yup.string().required('Voucher Name is required'),
    Quantity: yup.number().required('Quantity is required'),
    ValidFrom: yup.string().required('Valid From is required'),
    ValidUntil: yup.string().required('Valid Until is required')
  })

  const formik = useFormik<PartialTokenStateProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      RequestName: '',
      VoucherId: '',
      Quantity: '',
      ValidFrom: '',
      ValidUntil: ''
    },
    onSubmit: (values: PartialTokenStateProps) => handleSubmit(values)
  })

  const handleChangeStartDate = (date: Dayjs | null) => {
    setStartDate(date)
    const newDate = (!isEmpty(date) && date.format('YYYY-MM-DD')) || ''
    formik.setFieldValue('ValidFrom', newDate)
  }

  const handleChangeEndDate = (date: Dayjs | null) => {
    setEndDate(date)
    const newDate = (!isEmpty(date) && date.format('YYYY-MM-DD')) || ''
    formik.setFieldValue('ValidUntil', newDate)
  }

  const handleSubmit = (e: PartialTokenStateProps) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const payload: any = {
      RequestName: e.RequestName,
      VoucherId: e.VoucherId,
      Quantity: e.Quantity,
      ValidFrom: e.ValidFrom,
      ValidUntil: e.ValidUntil
    }
    createTokenRequest(payload).then()
  }

  return (
    <>
      <Dialog
        open={open}
        title='Add Token Request'
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={create.isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          <FormikProvider value={formik}>
            <TextField
              id='RequestName'
              variant='outlined'
              label='Request Name'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched && formik.touched.RequestName && Boolean(formik.errors.RequestName)
              }
              helperText={
                formik.touched &&
                formik.touched.RequestName &&
                formik.errors &&
                formik.errors.RequestName
              }
              fullWidth
            />

            <Autocomplete
              options={voucherDropdown.data || []}
              getOptionLabel={(list) => list.name}
              isOptionEqualToValue={(option, value) =>
                option && value ? option.id == value.id : false
              }
              onOpen={() => getVoucherListDropdown()}
              onChange={(_, id) => formik.setFieldValue('VoucherId', id && id.id)}
              ListboxProps={GlobalStyle.ListBox}
              renderOption={(props, item) => (
                <li {...props} key={item.id}>
                  {item.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='VoucherId'
                  label='Voucher Name'
                  error={
                    formik.touched && formik.touched.VoucherId && Boolean(formik.errors.VoucherId)
                  }
                  helperText={
                    formik.touched &&
                    formik.touched.VoucherId &&
                    formik.errors &&
                    formik.errors.VoucherId
                  }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {voucherDropdown.isLoading && (
                          <CircularProgress color='inherit' size={20} />
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
            />

            <TextField
              id='Quantity'
              variant='outlined'
              label='Quantity'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched && formik.touched.Quantity && Boolean(formik.errors.Quantity)}
              helperText={
                formik.touched && formik.touched.Quantity && formik.errors && formik.errors.Quantity
              }
              type='number'
              fullWidth
            />

            <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
              <DatePicker
                value={startDate}
                onChange={handleChangeStartDate}
                label='Valid From'
                format='DD-MM-YYYY'
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
              <DatePicker
                value={endDate}
                minDate={dayjs(startDate)}
                onChange={handleChangeEndDate}
                label='Valid Until'
                format='DD-MM-YYYY'
              />
            </LocalizationProvider>
          </FormikProvider>
        </Container>
      </Dialog>

      <Notification
        open={!create.isLoading && !create.isUninitialized}
        onClose={() => (create.isError ? create.reset() : location.reload())}
        isError={Boolean(create.error) && create.isError}
        message={GLOBAL.returnExceptionMessage(create.isError, create.error as ErrorProps)}
      />
    </>
  )
}

export default Create
