import { cloneElement } from 'react'
import { Backdrop, Button, Container } from '@mui/material'
import type { Children } from 'modules/types'
import BackdropStyle from './style'

const BackdropImage = ({
  children,
  open,
  onOpen,
  onClose
}: Children & { open: boolean; onOpen: () => void; onClose: () => void }) => {
  const image = children as React.DetailedReactHTMLElement<
    React.HTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >

  const element = cloneElement(image, {
    style: { width: '100%', height: '100%', objectFit: 'contain' }
  })

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={onClose}
      >
        <Container {...BackdropStyle.ContainerImage}>{element}</Container>
      </Backdrop>
      <Button type='button' variant='text' onClick={onOpen}>
        {children}
      </Button>
    </>
  )
}

export default BackdropImage
