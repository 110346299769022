import CONSTANT from 'modules/constant'
import type {
  DropdownCategoryListProps,
  PaginationProps,
  ResultProps,
  TokenCategoryListProps,
  TokenListProps
} from 'modules/types'
import type { PartialTokenStateProps } from 'modules/partial'
import { API, download, headers } from './api'

const VoucherTokenAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getListToken: builder.mutation<PaginationProps<TokenListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_TOKEN_LIST,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body
      })
    }),
    getTokenDetail: builder.query<TokenListProps, number | string>({
      query: (Id) => ({
        url: CONSTANT.URL_TOKEN_DETAIL + Id,
        method: 'GET',
        headers: headers()
      })
    }),
    getTokenDetailFailed: builder.query<TokenListProps, number | string>({
      query: (Id) => ({
        url: CONSTANT.URL_TOKEN_DETAIL_FAILED + Id,
        method: 'GET',
        headers: headers()
      })
    }),
    getListTokenCategory: builder.mutation<PaginationProps<TokenCategoryListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_TOKEN_CATEGORY,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body
      })
    }),
    downloadTokenCategory: builder.mutation<PaginationProps<TokenCategoryListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_TOKEN_CATEGORY_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        responseHandler: download,
        body: body,
        formData: true
      })
    }),
    deleteTokenCategory: builder.mutation<ResultProps, { Id: number | string }>({
      query: (body) => ({
        url: CONSTANT.URL_TOKEN_CATEGORY_DELETE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    enableTokenCategory: builder.mutation<ResultProps, { Id: number | string; Status: string }>({
      query: (body) => ({
        url: CONSTANT.URL_TOKEN_CATEGORY_ENABLE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    createTokenCategory: builder.mutation<ResultProps, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_TOKEN_CATEGORY_CREATE,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body
      })
    }),
    getTokenPartnerDropdown: builder.mutation<DropdownCategoryListProps[], void>({
      query: () => ({
        url: CONSTANT.URL_TOKEN_CATEGORY_PARTNER,
        method: 'GET',
        headers: headers()
      })
    }),
    getTokenCategoryDetail: builder.query<TokenCategoryListProps, number | string>({
      query: (Id) => ({
        url: CONSTANT.URL_TOKEN_CATEGORY_DETAIL + Id,
        method: 'GET',
        headers: headers()
      })
    }),
    updateTokenCategory: builder.mutation<ResultProps, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_TOKEN_CATEGORY_UPDATE,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body
      })
    }),
    uploadTokenCategory: builder.mutation<ResultProps, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_TOKEN_CATEGORY_UPLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body
      })
    }),
    revokeToken: builder.mutation<ResultProps, PartialTokenStateProps>({
      query: (body) => ({
        url: CONSTANT.URL_TOKEN_REVOKE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    moveToken: builder.mutation<ResultProps, PartialTokenStateProps>({
      query: (body) => ({
        url: CONSTANT.URL_TOKEN_MOVE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    downloadToken: builder.mutation<PaginationProps<TokenListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_TOKEN_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        responseHandler: download,
        body: body,
        formData: true
      })
    }),
    downloadTemplateCategory: builder.mutation<PaginationProps<TokenCategoryListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_TOKEN_CATEGORY_TEMPLATE,
        method: 'POST',
        headers: headers({ useContentType: false }),
        responseHandler: download,
        body: body
      })
    })
  })
})

export const {
  useGetListTokenMutation,
  useGetTokenDetailQuery,
  useGetTokenDetailFailedQuery,
  useGetListTokenCategoryMutation,
  useDownloadTokenCategoryMutation,
  useDeleteTokenCategoryMutation,
  useEnableTokenCategoryMutation,
  useCreateTokenCategoryMutation,
  useGetTokenPartnerDropdownMutation,
  useGetTokenCategoryDetailQuery,
  useUpdateTokenCategoryMutation,
  useUploadTokenCategoryMutation,
  useRevokeTokenMutation,
  useMoveTokenMutation,
  useDownloadTokenMutation,
  useDownloadTemplateCategoryMutation
} = VoucherTokenAPI
