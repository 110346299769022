import { useEffect, useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import { isEmpty } from 'lodash'
import {
  Autocomplete,
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import * as yup from 'yup'
import Dialog from 'components/dialog/dialog'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { PartialUpdatePartnerProps } from 'modules/partial'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import {
  useGetDetailPartnerLocationQuery,
  useUpdatePartnerLocationMutation
} from 'store/locationPartner'
import { useGetCityDropdownListMutation } from 'store/dropdown'
import GlobalStyle from 'modules/styles'
import BackdropImage from 'components/backdropImage/backdropImage'
import DialogStyle from './style'

const Edit = ({ open, onClose, id }: DetailStateProps & { id: string | number }) => {
  if (!open) return <></>

  const { data, isFetching, isLoading, isSuccess } = useGetDetailPartnerLocationQuery(id)
  const [updatePartnerLocation, update] = useUpdatePartnerLocationMutation()
  const [getCityDropdownList, cityDropdown] = useGetCityDropdownListMutation()
  const [previewBanner, setPreviewBanner] = useState<string>((data && data.companyBanner) || '')
  const [previewLogo, setPreviewLogo] = useState<string>((data && data.companyLogo) || '')
  const [description, setDescription] = useState<string>((data && data.description) || '')
  const [previewBackdrop, setPreviewBackdrop] = useState<boolean>(false)
  const [bannerBackdrop, setBannerBackdrop] = useState<boolean>(false)

  const [payload, setPayload] = useState<{
    Banner: Blob | string
    Logo: Blob | string
    appStoreUrl: string
    playStoreUrl: string
    description: string
  }>({
    Banner: '',
    Logo: '',
    appStoreUrl: '',
    playStoreUrl: '',
    description: ''
  })
  useEffect(() => {
    getCityDropdownList()
    if (isSuccess && data.companyBanner && data.companyLogo) {
      setPreviewLogo(data?.companyLogo)
      setPreviewBanner(data?.companyBanner)
      setPayload({
        Banner: data?.companyBanner,
        Logo: data?.companyLogo,
        appStoreUrl: data.appStoreUrl,
        playStoreUrl: data.playStoreUrl,
        description: data.description || ''
      })
    }
  }, [isSuccess])

  const scheme = yup.object<PartialUpdatePartnerProps>({
    partnerId: yup.number().required('Partner ID is required'),
    partnerName: yup.string().required('Partner Name is required'),
    phoneNumber: yup.string().required('Phone Number is required'),
    email: yup.string().required('Email is required'),
    address: yup.string().required('Address is required'),
    cityId: yup.number().required('City is required'),
    postalCode: yup.string().required('Postal Code is required'),
    latitude: yup.number().required('Latitude is required'),
    longitude: yup.number().required('Longitude is required'),
    googleMapUrl: yup.string().required('Google Map Url is required'),
    website: yup.string().required('Website is required'),
    titleEnglish: yup.string().required('TItle English is required'),
    titleIndonesia: yup.string().required('Title Indonesia is required')
  })

  const formik = useFormik<PartialUpdatePartnerProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      partnerId: (data && data.partnerId) || '',
      partnerName: (data && data.partnerName) || '',
      partnerShortName: (data && data.partnerShortName) || '',
      phoneNumber: (data && data.phoneNumber) || '',
      email: (data && data.email) || '',
      address: (data && data.address) || '',
      cityId: (data && data.cityId) || 0,
      postalCode: (data && data.postalCode) || '',
      latitude: (data && data.latitude) || 0,
      longitude: (data && data.longitude) || 0,
      googleMapUrl: (data && data.googleMapUrl) || '',
      website: (data && data.website) || '',
      titleEnglish: (data && data.titleEnglish) || '',
      titleIndonesia: (data && data.titleIndonesia) || '',
      status: (data && data.status) || ''
    },
    onSubmit: (values: PartialUpdatePartnerProps) => handleSubmit(values)
  })

  const handleSubmit = (e: PartialUpdatePartnerProps) => {
    const formatPayload = {
      ...e,
      partnerId: id,
      appStoreUrl: !isEmpty(payload.appStoreUrl) ? payload.appStoreUrl : null,
      playStoreUrl: !isEmpty(payload.playStoreUrl) ? payload.playStoreUrl : null,
      description: !isEmpty(payload.description) ? payload.description : null
    }

    const formData = new FormData()

    if (previewBanner !== payload.Banner) formData.append('Banner', payload.Banner)
    if (previewLogo !== payload.Logo) formData.append('Logo', payload.Logo)

    formData.append('data', JSON.stringify(formatPayload))

    updatePartnerLocation(formData)
  }
  return (
    <>
      <Dialog
        open={open}
        title='Edit Partner Location'
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          {(isLoading || isFetching) && data == undefined && <Loading />}
          {data && (
            <>
              <TextField
                InputLabelProps={{
                  shrink: true
                }}
                id='partnerName'
                variant='outlined'
                label='Partner Name'
                value={formik.values.partnerName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched && formik.touched.partnerName && Boolean(formik.errors.partnerName)
                }
                helperText={
                  formik.touched &&
                  formik.touched.partnerName &&
                  formik.errors &&
                  formik.errors.partnerName
                }
                fullWidth
              />
              <TextField
                InputLabelProps={{
                  shrink: true
                }}
                id='partnerShortName'
                variant='outlined'
                label='Partner Short Name'
                value={formik.values.partnerShortName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched &&
                  formik.touched.partnerShortName &&
                  Boolean(formik.errors.partnerShortName)
                }
                helperText={
                  formik.touched &&
                  formik.touched.partnerShortName &&
                  formik.errors &&
                  formik.errors.partnerShortName
                }
                fullWidth
              />
              <FormikProvider value={formik}>
                <TextField
                  InputLabelProps={{
                    shrink: true
                  }}
                  id='phoneNumber'
                  variant='outlined'
                  label='Phone Number *'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phoneNumber}
                  error={
                    formik.touched &&
                    formik.touched.phoneNumber &&
                    Boolean(formik.errors.phoneNumber)
                  }
                  helperText={
                    formik.touched &&
                    formik.touched.phoneNumber &&
                    formik.errors &&
                    formik.errors.phoneNumber
                  }
                  fullWidth
                />
                <TextField
                  InputLabelProps={{
                    shrink: true
                  }}
                  id='email'
                  variant='outlined'
                  label='Email *'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  error={formik.touched && formik.touched.email && Boolean(formik.errors.email)}
                  helperText={
                    formik.touched && formik.touched.email && formik.errors && formik.errors.email
                  }
                  fullWidth
                />
                <TextField
                  InputLabelProps={{
                    shrink: true
                  }}
                  id='address'
                  variant='outlined'
                  label='Address *'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address}
                  error={formik.touched && formik.touched.address && Boolean(formik.errors.address)}
                  helperText={
                    formik.touched &&
                    formik.touched.address &&
                    formik.errors &&
                    formik.errors.address
                  }
                  fullWidth
                />
                <Autocomplete
                  key='cityId'
                  defaultValue={{
                    name: data.city,
                    id: data.cityId,
                    description: 'NULL',
                    code: 'NULL',
                    remark: '',
                    status: '',
                    provinceId: cityDropdown.data?.find((item) => item.id === data.cityId)
                      ?.provinceId,
                    active: true
                  }}
                  options={(cityDropdown && cityDropdown.data) || []}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option && value ? option.name == value.name : false
                  }
                  onOpen={() => getCityDropdownList()}
                  onChange={(_, city) => formik.setFieldValue('cityId', city?.id)}
                  value={
                    cityDropdown &&
                    cityDropdown.data &&
                    cityDropdown.data.find((e) => e.id == formik.values.cityId)
                  }
                  ListboxProps={GlobalStyle.ListBox}
                  renderOption={(props, item) => (
                    <li {...props} key={item.id}>
                      {item.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name='cityId'
                      label='City *'
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {cityDropdown.isLoading && (
                              <CircularProgress color='inherit' size={20} />
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                />
                <TextField
                  InputLabelProps={{
                    shrink: true
                  }}
                  id='postalCode'
                  variant='outlined'
                  label='Postal Code *'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.postalCode}
                  error={
                    formik.touched && formik.touched.postalCode && Boolean(formik.errors.postalCode)
                  }
                  helperText={
                    formik.touched &&
                    formik.touched.postalCode &&
                    formik.errors &&
                    formik.errors.postalCode
                  }
                  fullWidth
                />
                <TextField
                  InputLabelProps={{
                    shrink: true
                  }}
                  id='latitude'
                  variant='outlined'
                  label='Latitude *'
                  type='number'
                  onChange={formik.handleChange}
                  value={formik.values.latitude}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched && formik.touched.latitude && Boolean(formik.errors.latitude)
                  }
                  helperText={
                    formik.touched &&
                    formik.touched.latitude &&
                    formik.errors &&
                    formik.errors.latitude
                  }
                  fullWidth
                />
                <TextField
                  InputLabelProps={{
                    shrink: true
                  }}
                  id='longitude'
                  variant='outlined'
                  label='Longitude *'
                  type='number'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.longitude}
                  error={
                    formik.touched && formik.touched.longitude && Boolean(formik.errors.longitude)
                  }
                  helperText={
                    formik.touched &&
                    formik.touched.longitude &&
                    formik.errors &&
                    formik.errors.longitude
                  }
                  fullWidth
                />
                <TextField
                  InputLabelProps={{
                    shrink: true
                  }}
                  id='googleMapUrl'
                  variant='outlined'
                  label='Google Map Url *'
                  value={formik.values.googleMapUrl}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched &&
                    formik.touched.googleMapUrl &&
                    Boolean(formik.errors.googleMapUrl)
                  }
                  helperText={
                    formik.touched &&
                    formik.touched.googleMapUrl &&
                    formik.errors &&
                    formik.errors.googleMapUrl
                  }
                  fullWidth
                />
                <TextField
                  InputLabelProps={{
                    shrink: true
                  }}
                  id='website'
                  variant='outlined'
                  label='Website *'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.website}
                  error={formik.touched && formik.touched.website && Boolean(formik.errors.website)}
                  helperText={
                    formik.touched &&
                    formik.touched.website &&
                    formik.errors &&
                    formik.errors.website
                  }
                  fullWidth
                />
                <TextField
                  InputLabelProps={{
                    shrink: true
                  }}
                  id='appStoreUrl'
                  variant='outlined'
                  label='AppStore Url'
                  value={payload.appStoreUrl}
                  onChange={(e) => setPayload({ ...payload, appStoreUrl: e.target.value })}
                  fullWidth
                />
                <TextField
                  InputLabelProps={{
                    shrink: true
                  }}
                  id='playStoreUrl'
                  variant='outlined'
                  label='PlayStore Url'
                  value={payload.playStoreUrl}
                  onChange={(e) => setPayload({ ...payload, playStoreUrl: e.target.value })}
                  fullWidth
                />
                <TextField
                  InputLabelProps={{
                    shrink: true
                  }}
                  id='titleEnglish'
                  variant='outlined'
                  label='Title English *'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.titleEnglish}
                  error={
                    formik.touched &&
                    formik.touched.titleEnglish &&
                    Boolean(formik.errors.titleEnglish)
                  }
                  helperText={
                    formik.touched &&
                    formik.touched.titleEnglish &&
                    formik.errors &&
                    formik.errors.titleEnglish
                  }
                  fullWidth
                />
                <TextField
                  InputLabelProps={{
                    shrink: true
                  }}
                  id='titleIndonesia'
                  variant='outlined'
                  label='Title Indonesia *'
                  onChange={formik.handleChange}
                  value={formik.values.titleIndonesia}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched &&
                    formik.touched.titleIndonesia &&
                    Boolean(formik.errors.titleIndonesia)
                  }
                  helperText={
                    formik.touched &&
                    formik.touched.titleIndonesia &&
                    formik.errors &&
                    formik.errors.titleIndonesia
                  }
                  fullWidth
                />
                <div
                  style={{
                    height: '300px',
                    border: '1px solid #eee',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  {!previewBanner && (
                    <Typography style={{ color: '#cfcfcf' }}>Banner Empty</Typography>
                  )}
                  {previewBanner && (
                    <BackdropImage
                      open={bannerBackdrop}
                      onOpen={() => setBannerBackdrop(true)}
                      onClose={() => setBannerBackdrop(false)}
                    >
                      <img
                        src={previewBanner}
                        alt='Banner'
                        style={{ height: '300px', maxWidth: '537px', objectFit: 'contain' }}
                      />
                    </BackdropImage>
                  )}
                </div>

                <TextField
                  required
                  id='Banner'
                  label='Banner'
                  type='file'
                  placeholder='Banner'
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ accept: 'image/png, image/gif, image/jpeg' }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const files = e && e.target && e.target.files

                    if (files && files.length > 0 && !isEmpty(files)) {
                      setPayload({ ...payload, Banner: files[0] })
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      const reader: any = new FileReader()
                      reader.onload = () => {
                        setPreviewBanner(reader.result)
                      }
                      reader.readAsDataURL(files[0])
                    } else {
                      setPreviewBanner('')
                    }
                  }}
                  fullWidth
                />
                <div
                  style={{
                    height: '300px',
                    border: '1px solid #eee',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  {!previewLogo && <Typography style={{ color: '#cfcfcf' }}>Logo Empty</Typography>}
                  {previewLogo && (
                    <BackdropImage
                      open={previewBackdrop}
                      onOpen={() => setPreviewBackdrop(true)}
                      onClose={() => setPreviewBackdrop(false)}
                    >
                      <img
                        src={previewLogo}
                        alt='Logo'
                        style={{ height: '300px', maxWidth: '537px', objectFit: 'contain' }}
                      />
                    </BackdropImage>
                  )}
                </div>
                <TextField
                  required
                  id='Logo'
                  label='Logo'
                  type='file'
                  placeholder='Logo'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const files = e && e.target && e.target.files
                    if (files && files.length > 0 && !isEmpty(files)) {
                      setPayload({ ...payload, Logo: files[0] })
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      const reader: any = new FileReader()
                      reader.onload = () => {
                        setPreviewLogo(reader.result)
                      }
                      reader.readAsDataURL(files[0])
                    } else {
                      setPreviewLogo('')
                    }
                  }}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ accept: 'image/png, image/gif, image/jpeg' }}
                  fullWidth
                />
                <FormControl fullWidth>
                  <InputLabel id='select-label'>Status</InputLabel>
                  <Select
                    labelId='select-label'
                    id='id-select-label'
                    value={formik.values.status}
                    label='Status'
                    defaultValue={formik.values.status}
                    onChange={(event) => formik.setFieldValue('status', event.target.value)}
                  >
                    <MenuItem value='Enabled'>Enabled</MenuItem>
                    <MenuItem value='Disabled'>Disabled</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  InputLabelProps={{
                    shrink: true
                  }}
                  id='description'
                  variant='outlined'
                  label='Description'
                  value={description}
                  onChange={(e) => {
                    formik.setFieldValue('description', e.target.value)
                    setDescription(e.target.value)
                  }}
                  fullWidth
                  multiline
                />
              </FormikProvider>
            </>
          )}
        </Container>
      </Dialog>
      <Notification
        open={!update.isLoading && !update.isUninitialized}
        onClose={() => (update.isError ? update.reset() : location.reload())}
        isError={Boolean(update.error) && update.isError}
        message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
      />
    </>
  )
}

export default Edit
