import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Grid,
  TextField,
  Typography
} from '@mui/material'
import { ExpandMoreOutlined } from '@mui/icons-material'
import Drawer from 'components/drawer/detail'
import Loading from 'components/loading/loading'
import type { DetailStateProps } from 'modules/types'
import { useGetTokenImportDetailQuery } from 'store/tokenImport'
import DrawerStyle from './style'

const Detail = ({ open, id, onClose }: DetailStateProps & { id: string | number }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const { data, isFetching, isLoading } = useGetTokenImportDetailQuery(id)

  const arrData = [
    { value: 'voucherName', label: 'Voucher Name' },
    { value: 'partnerName', label: 'Partner' },
    { value: 'fileName', label: 'File Name' },
    { value: 'tokenCategory', label: 'Token Category' },
    { value: 'taskStatus', label: 'Task Status' },
    { value: 'notes', label: 'Notes' }
  ]

  const arrDataDetail = [
    { value: 'id', label: 'ID' },
    { value: 'taskStatus', label: 'Status' },
    { value: 'createdBy', label: 'Created By' },
    { value: 'modifiedBy', label: 'Last Modified By' },
    { value: 'createdAtFormated', label: 'Created Date' },
    { value: 'modifiedAtFormated', label: 'Last Modified Date' }
  ]

  return (
    <>
      <Drawer open={open} title='Detail Token Import' onClose={onClose}>
        <Container {...DrawerStyle.Container}>
          {(isLoading || isFetching) && !data && <Loading />}
          {data && (
            <>
              {arrData.map((item, index) => (
                <TextField
                  key={index}
                  variant='outlined'
                  label={item.label}
                  value={data[item.value as never]}
                  inputProps={{ readOnly: true }}
                  fullWidth
                />
              ))}
            </>
          )}
          {data && (
            <Accordion elevation={0} disableGutters>
              <AccordionSummary
                expandIcon={<ExpandMoreOutlined />}
                aria-controls='basic-information'
              >
                <Typography>Basic Information</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {arrDataDetail.map((item, index) => (
                    <Grid key={index} item xs={12}>
                      <TextField
                        variant='outlined'
                        label={item.label}
                        value={data[item.value as never]}
                        inputProps={{ readOnly: true }}
                        fullWidth
                      />
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          )}
        </Container>
      </Drawer>
    </>
  )
}

export default Detail
