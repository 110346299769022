import { useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import { isEmpty } from 'lodash'
import { Autocomplete, CircularProgress, Container, TextField, Typography } from '@mui/material'
import * as yup from 'yup'
import Dialog from 'components/dialog/dialog'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { PartialPartnerPayloadProps } from 'modules/partial'
import type { DetailStateProps, ErrorProps } from 'modules/types'
import { useCreatePartnerLocationMutation } from 'store/locationPartner'
import GlobalStyle from 'modules/styles'
import { useGetCityDropdownListMutation } from 'store/dropdown'
import BackdropImage from 'components/backdropImage/backdropImage'
import DialogStyle from './style'

const Create = ({ open, onClose }: DetailStateProps) => {
  const [createPartnerLocation, create] = useCreatePartnerLocationMutation()
  const [getCityDropdownList, cityDropdown] = useGetCityDropdownListMutation()
  const [previewBanner, setPreviewBanner] = useState<string | ArrayBuffer | null>('')
  const [previewLogo, setPreviewLogo] = useState<string | ArrayBuffer | null>('')
  const [previewBackdrop, setPreviewBackdrop] = useState<boolean>(false)
  const [bannerBackdrop, setBannerBackdrop] = useState<boolean>(false)

  const [payload, setPayload] = useState<{
    Banner: Blob | string
    Logo: Blob | string
    appStoreUrl: string
    playStoreUrl: string
    description: string
  }>({
    Banner: '',
    Logo: '',
    appStoreUrl: '',
    playStoreUrl: '',
    description: ''
  })

  const scheme = yup.object<PartialPartnerPayloadProps>({
    partnerShortName: yup.string().required('Partner Shortname is required'),
    partnerName: yup.string().required('Partner Name is required'),
    phoneNumber: yup.string().required('Phone Number is required'),
    email: yup.string().required('Email is required'),
    address: yup.string().required('Address is required'),
    cityId: yup.number().required('City is required'),
    postalCode: yup.string().required('Postal Code is required'),
    latitude: yup.number().required('Latitude is required'),
    longitude: yup.number().required('Longitude is required'),
    googleMapUrl: yup.string().required('Google Map Url is required'),
    website: yup.string().required('Website is required'),
    titleEnglish: yup.string().required('TItle English is required'),
    titleIndonesia: yup.string().required('Title Indonesia is required')
  })

  const formik = useFormik<PartialPartnerPayloadProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      partnerShortName: '',
      partnerName: '',
      phoneNumber: '',
      email: '',
      address: '',
      cityId: null,
      postalCode: '',
      latitude: null,
      longitude: null,
      googleMapUrl: '',
      website: '',
      titleEnglish: '',
      titleIndonesia: ''
    },
    onSubmit: (values: PartialPartnerPayloadProps) => handleSubmit(values)
  })

  const handleSubmit = (e: PartialPartnerPayloadProps) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const formatPayload = {
      ...e,
      appStoreUrl: !isEmpty(payload.appStoreUrl) ? payload.appStoreUrl : null,
      playStoreUrl: !isEmpty(payload.playStoreUrl) ? payload.playStoreUrl : null,
      description: !isEmpty(payload.description) ? payload.description : null
    }
    const formData = new FormData()

    if (!isEmpty(previewBanner)) formData.append('Banner', payload.Banner)
    if (!isEmpty(previewLogo)) formData.append('Logo', payload.Logo)
    formData.append('data', JSON.stringify(formatPayload))
    createPartnerLocation(formData)
  }
  return (
    <>
      <Dialog
        open={open}
        title='Create Partner Location'
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={create.isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          <FormikProvider value={formik}>
            <TextField
              id='partnerName'
              variant='outlined'
              label='Partner Name'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched && formik.touched.partnerName && Boolean(formik.errors.partnerName)
              }
              helperText={
                formik.touched &&
                formik.touched.partnerName &&
                formik.errors &&
                formik.errors.partnerName
              }
              fullWidth
            />
            <TextField
              id='partnerShortName'
              variant='outlined'
              label='Partner Short Name'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched &&
                formik.touched.partnerShortName &&
                Boolean(formik.errors.partnerShortName)
              }
              helperText={
                formik.touched &&
                formik.touched.partnerShortName &&
                formik.errors &&
                formik.errors.partnerShortName
              }
              fullWidth
            />
            <TextField
              id='phoneNumber'
              variant='outlined'
              label='Phone Number'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched && formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
              }
              helperText={
                formik.touched &&
                formik.touched.phoneNumber &&
                formik.errors &&
                formik.errors.phoneNumber
              }
              fullWidth
            />
            <TextField
              id='email'
              variant='outlined'
              label='Email'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched && formik.touched.email && Boolean(formik.errors.email)}
              helperText={
                formik.touched && formik.touched.email && formik.errors && formik.errors.email
              }
              fullWidth
            />
            <TextField
              id='address'
              variant='outlined'
              label='Address'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched && formik.touched.address && Boolean(formik.errors.address)}
              helperText={
                formik.touched && formik.touched.address && formik.errors && formik.errors.address
              }
              fullWidth
            />
            <Autocomplete
              key={'cityId'}
              options={(cityDropdown && cityDropdown.data) || []}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) =>
                option && value ? option.name == value.name : false
              }
              onOpen={() => getCityDropdownList()}
              onChange={(_, city) => formik.setFieldValue('cityId', city?.id)}
              value={
                (cityDropdown &&
                  cityDropdown.data &&
                  cityDropdown.data.find((e) => e.id == formik.values.cityId)) ||
                undefined
              }
              ListboxProps={GlobalStyle.ListBox}
              renderOption={(props, item) => (
                <li {...props} key={item.id}>
                  {item.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='cityId'
                  label='City'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {cityDropdown.isLoading && <CircularProgress color='inherit' size={20} />}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
            />
            <TextField
              id='postalCode'
              variant='outlined'
              label='Postal Code'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched && formik.touched.postalCode && Boolean(formik.errors.postalCode)
              }
              helperText={
                formik.touched &&
                formik.touched.postalCode &&
                formik.errors &&
                formik.errors.postalCode
              }
              fullWidth
            />
            <TextField
              id='latitude'
              variant='outlined'
              label='Latitude'
              type='number'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched && formik.touched.latitude && Boolean(formik.errors.latitude)}
              helperText={
                formik.touched && formik.touched.latitude && formik.errors && formik.errors.latitude
              }
              fullWidth
            />
            <TextField
              id='longitude'
              variant='outlined'
              label='Longitude'
              type='number'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched && formik.touched.longitude && Boolean(formik.errors.longitude)}
              helperText={
                formik.touched &&
                formik.touched.longitude &&
                formik.errors &&
                formik.errors.longitude
              }
              fullWidth
            />
            <TextField
              id='googleMapUrl'
              variant='outlined'
              label='Google Map Url'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched && formik.touched.googleMapUrl && Boolean(formik.errors.googleMapUrl)
              }
              helperText={
                formik.touched &&
                formik.touched.googleMapUrl &&
                formik.errors &&
                formik.errors.googleMapUrl
              }
              fullWidth
            />
            <TextField
              id='website'
              variant='outlined'
              label='Website'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched && formik.touched.website && Boolean(formik.errors.website)}
              helperText={
                formik.touched && formik.touched.website && formik.errors && formik.errors.website
              }
              fullWidth
            />
            <TextField
              id='appStoreUrl'
              variant='outlined'
              label='AppStore Url'
              value={payload.appStoreUrl}
              onChange={(e) => setPayload({ ...payload, appStoreUrl: e.target.value })}
              fullWidth
            />
            <TextField
              id='playStoreUrl'
              variant='outlined'
              label='PlayStore Url'
              value={payload.playStoreUrl}
              onChange={(e) => setPayload({ ...payload, playStoreUrl: e.target.value })}
              fullWidth
            />
            <TextField
              id='titleEnglish'
              variant='outlined'
              label='Title English'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched && formik.touched.titleEnglish && Boolean(formik.errors.titleEnglish)
              }
              helperText={
                formik.touched &&
                formik.touched.titleEnglish &&
                formik.errors &&
                formik.errors.titleEnglish
              }
              fullWidth
            />
            <TextField
              id='titleIndonesia'
              variant='outlined'
              label='Title Indonesia'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched &&
                formik.touched.titleIndonesia &&
                Boolean(formik.errors.titleIndonesia)
              }
              helperText={
                formik.touched &&
                formik.touched.titleIndonesia &&
                formik.errors &&
                formik.errors.titleIndonesia
              }
              fullWidth
            />
            <div
              style={{
                height: '300px',
                border: '1px solid #eee',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {!previewBanner && <Typography style={{ color: '#cfcfcf' }}>Banner Empty</Typography>}
              {previewBanner && (
                <BackdropImage
                  open={bannerBackdrop}
                  onOpen={() => setBannerBackdrop(true)}
                  onClose={() => setBannerBackdrop(false)}
                >
                  <img
                    src={previewBanner as string | undefined}
                    alt=''
                    style={{ height: '300px', width: '537px', objectFit: 'contain' }}
                  />
                </BackdropImage>
              )}
            </div>
            <TextField
              required
              id='Banner'
              label='Banner'
              type='file'
              placeholder='Banner'
              InputLabelProps={{ shrink: true }}
              inputProps={{ accept: 'image/png, image/gif, image/jpeg' }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const files = e && e.target && e.target.files
                if (files && files.length > 0 && !isEmpty(files)) {
                  setPayload({ ...payload, Banner: files[0] })
                  const reader: FileReader = new FileReader()
                  reader.onload = () => {
                    setPreviewBanner(reader.result)
                  }
                  reader.readAsDataURL(files[0])
                } else {
                  setPreviewBanner('')
                }
              }}
              fullWidth
            />
            <div
              style={{
                height: '300px',
                border: '1px solid #eee',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {!previewLogo && <Typography style={{ color: '#cfcfcf' }}>Logo Empty</Typography>}

              {previewLogo && (
                <BackdropImage
                  open={previewBackdrop}
                  onOpen={() => setPreviewBackdrop(true)}
                  onClose={() => setPreviewBackdrop(false)}
                >
                  <img src={previewLogo as string | undefined} alt='' style={{ height: '300px' }} />
                </BackdropImage>
              )}
            </div>
            <TextField
              required
              id='Logo'
              label='Logo'
              type='file'
              placeholder='Logo'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const files = e && e.target && e.target.files
                if (files && files.length > 0 && !isEmpty(files)) {
                  setPayload({ ...payload, Logo: files[0] })
                  const reader: FileReader = new FileReader()
                  reader.onload = () => {
                    setPreviewLogo(reader.result)
                  }
                  reader.readAsDataURL(files[0])
                } else {
                  setPreviewLogo('')
                }
              }}
              InputLabelProps={{ shrink: true }}
              inputProps={{ accept: 'image/png, image/gif, image/jpeg' }}
              fullWidth
            />
            <TextField
              id='description'
              variant='outlined'
              label='Description'
              onChange={(e) => setPayload({ ...payload, description: e.target.value })}
              value={payload.description}
              name='description'
              fullWidth
              multiline
            />
          </FormikProvider>
        </Container>
      </Dialog>
      <Notification
        open={!create.isLoading && !create.isUninitialized}
        onClose={() => (create.isError ? create.reset() : location.reload())}
        isError={Boolean(create.error) && create.isError}
        message={GLOBAL.returnExceptionMessage(create.isError, create.error as ErrorProps)}
      />
    </>
  )
}

export default Create
