import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { Checkbox, Container, FormControlLabel, TextField, Typography } from '@mui/material'
import Dialog from 'components/dialog/dialog'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { PartialCreatePolicyChargerProps } from 'modules/partial'
import type { ChargerPayloadTempProps, DetailStateProps, ErrorProps } from 'modules/types'
import { useCreatePolicyMutation } from 'store/catalogPolicy'
import DialogStyle from './style'

const Create = ({ open, onClose }: DetailStateProps) => {
  const [createPolicy, create] = useCreatePolicyMutation()

  const scheme = yup.object<ChargerPayloadTempProps>({
    policyName: yup.string().required('Policy Name is required'),
    // isQuota: yup.bool().oneOf([true], 'Qouta is required'),
    // isVoucher: yup.bool().oneOf([true], 'Voucher is required'),
    // isRequestByPartner: yup.bool().oneOf([true], 'Request by Partner is required'),
    // isKwhLimit: yup.bool().oneOf([true], 'Kwh Limit is required'),
    remark: yup.string().required('remarks is required')
  })

  const formik = useFormik<ChargerPayloadTempProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      policyName: '',
      isQuota: false,
      isToken: false,
      isRequestByPartner: false,
      remark: '',
      isKwhLimit: false
    },
    onSubmit: (values: ChargerPayloadTempProps) => handleSubmit(values)
  })

  // const formatPayloadPolicy = (e: string) => (e === 'True' ? true : false)

  const handleSubmit = (e: ChargerPayloadTempProps) => {
    const payload: PartialCreatePolicyChargerProps = {
      policyName: e.policyName,
      isQuota: e.isQuota,
      isToken: e.isToken,
      isRequestByPartner: e.isRequestByPartner,
      isKwhLimit: e.isKwhLimit,
      active: true,
      remark: e.remark
    }
    createPolicy(payload)
  }

  return (
    <>
      <Dialog
        open={open}
        title='Create New EV Policy Charger'
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={false}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          <FormikProvider value={formik}>
            <TextField
              id='policyName'
              variant='outlined'
              label='Policy Name'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched && formik.touched.policyName && Boolean(formik.errors.policyName)
              }
              helperText={
                formik.touched &&
                formik.touched.policyName &&
                formik.errors &&
                formik.errors.policyName
              }
              fullWidth
            />
            <Typography>Condition</Typography>

            <FormControlLabel
              required
              control={
                <Checkbox
                  id='isQuota'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={true}
                />
              }
              label='Quota'
            />
            <FormControlLabel
              required
              control={
                <Checkbox
                  id='isToken'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={true}
                />
              }
              label='Pay As You Go'
            />
            <FormControlLabel
              required
              control={
                <Checkbox
                  id='isRequestByPartner'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={true}
                />
              }
              label='Request/Authorization by Partner'
            />
            <FormControlLabel
              required
              control={
                <Checkbox
                  id='isKwhLimit'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={true}
                />
              }
              label='Kwh Limit'
            />

            <TextField
              id='remark'
              variant='outlined'
              label='Charger Policy Description'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched && formik.touched.remark && Boolean(formik.errors.remark)}
              helperText={
                formik.touched && formik.touched.remark && formik.errors && formik.errors.remark
              }
              fullWidth
            />
          </FormikProvider>
        </Container>
      </Dialog>
      <Notification
        open={!create.isLoading && !create.isUninitialized}
        onClose={() => (create.isError ? create.reset() : location.reload())}
        isError={Boolean(create.error) && create.isError}
        message={GLOBAL.returnExceptionMessage(create.isError, create.error as ErrorProps)}
      />
    </>
  )
}

export default Create
