/* eslint-disable @typescript-eslint/no-explicit-any */

import { useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { Autocomplete, CircularProgress, Container, TextField } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import type { Dayjs } from 'dayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import Dialog from 'components/dialog/dialog'
import Loading from 'components/loading/loading'
import type { DetailStateProps, ErrorProps, VoucherCategoryListProps } from 'modules/types'
import {
  useGetVoucherCategoryDetailQuery,
  useGetVoucherCategoryDropdownMutation,
  useUpdateVoucherCategoryMutation
} from 'store/voucherCategory'
import type { PayloadVoucherCategoryProps } from 'modules/partial'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import GlobalStyle from 'modules/styles'
import DialogStyle from './style'

const Edit = ({ open, id, onClose }: DetailStateProps & { id: string | number }) => {
  if (!open) return <></>

  const { data, isFetching, isLoading } = useGetVoucherCategoryDetailQuery(id)
  const [updateVoucherCategory, update] = useUpdateVoucherCategoryMutation()
  const [getVoucherCategoryDropdown, dropdownCategory] = useGetVoucherCategoryDropdownMutation()
  const [startDate, setStartDate] = useState<Dayjs | null>(null)
  const [endDate, setEndDate] = useState<Dayjs | null>(null)

  const scheme = yup.object<PayloadVoucherCategoryProps>({
    image: yup.mixed().required('Image Voucher Category is required'),
    icon: yup.mixed().required('Icon Voucher Category is required'),
    nameEn: yup.string().required('Voucher Category Name English is required'),
    nameId: yup.string().required('Voucher Category Name ID is required'),
    description: yup.string().required('Voucher Category Description is required'),
    seq: yup.number().required('Voucher Category Sequence is required')
  })

  const formik = useFormik<VoucherCategoryListProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      id: (data && data?.id) || '',
      nameEn: (data && data?.nameEn) || '',
      nameId: (data && data?.nameId) || '',
      description: (data && data?.description) || '',
      seq: (data && data?.seq) || '',
      image: (data && data.image) || '',
      icon: (data && data.icon) || '',
      createdAt: '',
      status: ''
    },
    onSubmit: (values: VoucherCategoryListProps) => handleSubmit(values)
  })

  const handleSubmit = (e: VoucherCategoryListProps) => {
    const payload: any = {
      nameEn: e.nameEn,
      nameId: e.nameId,
      Description: e.description,
      Seq: Number(e.seq),
      Id: e.id,
      icon: ''
    }
    const formData = new FormData()
    formData.append('Image', e.image)
    formData.append('data', JSON.stringify(payload))
    updateVoucherCategory(formData)
  }

  const handleChangeStartDate = (date: Dayjs | null) => {
    setStartDate(date)
    // const newDate = (!isEmpty(date) && date?.format('YYYY-MM-DD')) || ''
  }

  const handleChangeEndDate = (date: Dayjs | null) => {
    setEndDate(date)
    // const newDate = (!isEmpty(date) && date?.format('YYYY-MM-DD')) || ''
  }

  return (
    <>
      <Dialog
        open={open}
        title='Update Token Request'
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          {(isLoading || isFetching) && !data && <Loading />}
          {data && (
            <>
              <FormikProvider value={formik}>
                <TextField
                  id='nameEn'
                  variant='outlined'
                  label='Request Name'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.nameEn}
                  error={formik.touched && formik.touched.nameEn && Boolean(formik.errors.nameEn)}
                  helperText={
                    formik.touched && formik.touched.nameEn && formik.errors && formik.errors.nameEn
                  }
                  fullWidth
                />
                <Autocomplete
                  options={dropdownCategory.data || []}
                  getOptionLabel={(list) => list.name}
                  isOptionEqualToValue={(option, value) =>
                    option && value ? option.id == value.id : false
                  }
                  onOpen={() => getVoucherCategoryDropdown()}
                  onChange={(_, id) => formik.setFieldValue('categoryId', id && id.id)}
                  ListboxProps={GlobalStyle.ListBox}
                  renderOption={(props, item) => (
                    <li {...props} key={item.id}>
                      {item.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name='partnerId'
                      label='Voucher Name'
                      // error={
                      //   formik.touched && formik.touched.partnerId && Boolean(formik.errors.partnerId)
                      // }
                      // helperText={
                      //   formik.touched &&
                      //   formik.touched.partnerId &&
                      //   formik.errors &&
                      //   formik.errors.partnerId
                      // }
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {dropdownCategory.isLoading && (
                              <CircularProgress color='inherit' size={20} />
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                />
                <TextField
                  id='nameId'
                  variant='outlined'
                  label='Quantity'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.nameId}
                  error={formik.touched && formik.touched.nameId && Boolean(formik.errors.nameId)}
                  helperText={
                    formik.touched && formik.touched.nameId && formik.errors && formik.errors.nameId
                  }
                  fullWidth
                />

                <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                  <DatePicker
                    value={startDate}
                    onChange={handleChangeStartDate}
                    label='Valid From'
                    format='DD-MM-YYYY'
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                  <DatePicker
                    value={endDate}
                    minDate={dayjs('')}
                    onChange={handleChangeEndDate}
                    label='Valid Until'
                    format='DD-MM-YYYY'
                  />
                </LocalizationProvider>
              </FormikProvider>
            </>
          )}
        </Container>
      </Dialog>

      <Notification
        open={!update.isLoading && !update.isUninitialized}
        onClose={() => (update.isError ? update.reset() : location.reload())}
        isError={Boolean(update.error) && update.isError}
        message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
      />
    </>
  )
}

export default Edit
