import CONSTANT from 'modules/constant'
import type {
  CityListProps,
  CompanyListDropdownProps,
  CompanyListProps,
  DetailPartnerBranchProps,
  DetailPartnerLocationProps,
  PaginationProps,
  PartnerBranchListProps,
  ResultProps
} from 'modules/types'
import type { PartialBranchPartnerUpdateProps, PartialPartnerListProps } from 'modules/partial'
import { API, download, headers } from './api'

const LocationPartnerAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getListPartnerLocation: builder.mutation<PaginationProps<CompanyListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_LOCATION_PARTNER,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body
      })
    }),
    getListPartnerLocationDropdown: builder.mutation<CompanyListDropdownProps[], void>({
      query: () => ({
        url: CONSTANT.URL_LOCATION_PARTNER_DROPDOWN,
        method: 'GET',
        headers: headers()
      })
    }),
    downloadPartnerLocation: builder.mutation<PaginationProps<CompanyListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_LOCATION_PARTNER_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        responseHandler: download,
        body: body
      })
    }),
    createPartnerLocation: builder.mutation<ResultProps, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_LOCATION_PARTNER_CREATE,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body
      })
    }),
    uploadPartnerLocation: builder.mutation<ResultProps, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_LOCATION_PARTNER_UPLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body
      })
    }),
    updateStatusPartnerLocation: builder.mutation<
      ResultProps,
      { id: string | number; status: string }
    >({
      query: (body) => ({
        url: CONSTANT.URL_LOCATION_PARTNER_STATUS,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    getDetailPartnerLocation: builder.query<DetailPartnerLocationProps, number | string>({
      query: (id) => ({
        url: CONSTANT.URL_LOCATION_PARTNER_DETAIL + id,
        method: 'GET',
        headers: headers()
      })
    }),
    getListBranchPartner: builder.mutation<PaginationProps<PartnerBranchListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_LOCATION_PARTNER_BRANCH,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body
      })
    }),
    downloadBranchPartner: builder.mutation<PaginationProps<CompanyListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_LOCATION_PARTNER_BRANCH_DOWNLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        responseHandler: download,
        body: body
      })
    }),
    updateStatusBranchPartner: builder.mutation<
      ResultProps,
      { id: string | number; status: string }
    >({
      query: (body) => ({
        url: CONSTANT.URL_LOCATION_PARTNER_BRANCH_STATUS,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    getDetailBranchPartner: builder.query<DetailPartnerBranchProps, number | string>({
      query: (id) => ({
        url: CONSTANT.URL_LOCATION_PARTNER_BRANCH_DETAIL + id,
        method: 'GET',
        headers: headers()
      })
    }),
    updateBranchPartner: builder.mutation<
      PaginationProps<PartnerBranchListProps>,
      PartialBranchPartnerUpdateProps
    >({
      query: (body) => ({
        url: CONSTANT.URL_LOCATION_PARTNER_BRANCH_UPDATE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    updatePartnerLocation: builder.mutation<ResultProps, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_LOCATION_PARTNER_UPDATE,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body
      })
    }),
    downloadTemplatePartnerLocation: builder.mutation<PaginationProps<CompanyListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_LOCATION_PARTNER_DOWNLOAD_TEMPLATE,
        method: 'POST',
        headers: headers({ useContentType: false }),
        responseHandler: download,
        body: body
      })
    }),
    getCityList: builder.mutation<CityListProps[], void>({
      query: () => ({
        url: CONSTANT.URL_CITY,
        method: 'GET',
        headers: headers()
      })
    }),
    createPartnerBranch: builder.mutation<ResultProps, PartialPartnerListProps>({
      query: (body) => ({
        url: CONSTANT.URL_LOCATION_PARTNER_BRANCH_CREATE,
        method: 'POST',
        headers: headers(),
        body: body
      })
    }),
    downloadTemplatePartnerBranch: builder.mutation<PaginationProps<CompanyListProps>, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_LOCATION_PARTNER_BRANCH_TEMPlATE,
        method: 'POST',
        headers: headers({ useContentType: false }),
        responseHandler: download,
        body: body
      })
    }),
    deletePartnerBranch: builder.mutation<ResultProps, number>({
      query: (body) => ({
        url: CONSTANT.URL_LOCATION_PARTNER_BRANCH_DELETE,
        method: 'POST',
        headers: headers(),
        body: {
          id: body
        }
      })
    }),
    uploadPartnerBranch: builder.mutation<ResultProps, FormData>({
      query: (body) => ({
        url: CONSTANT.URL_LOCATION_PARTNER_BRANCH_UPLOAD,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body
      })
    })
  })
})

export const {
  useGetListPartnerLocationMutation,
  useGetListPartnerLocationDropdownMutation,
  useDownloadPartnerLocationMutation,
  useUploadPartnerLocationMutation,
  useUpdateStatusPartnerLocationMutation,
  useGetListBranchPartnerMutation,
  useDownloadBranchPartnerMutation,
  useUpdateStatusBranchPartnerMutation,
  useUpdateBranchPartnerMutation,
  useCreatePartnerLocationMutation,
  useUpdatePartnerLocationMutation,
  useDownloadTemplatePartnerLocationMutation,
  useGetCityListMutation,
  useCreatePartnerBranchMutation,
  useDownloadTemplatePartnerBranchMutation,
  useDeletePartnerBranchMutation,
  useUploadPartnerBranchMutation,

  useGetDetailPartnerLocationQuery,
  useGetDetailBranchPartnerQuery
} = LocationPartnerAPI
