import {
  Container,
  Grid,
  TextField,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails
} from '@mui/material'
import { ExpandMoreOutlined } from '@mui/icons-material'
import Drawer from 'components/drawer/detail'
import Loading from 'components/loading/loading'
import { useGetDealerDetailQuery } from 'store/dealer'
import type { DetailStateProps } from 'modules/types'
import DetailStyle from './style'

const Detail = ({ open, id, onClose }: DetailStateProps & { id: string }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const { data, isLoading, isFetching } = useGetDealerDetailQuery(id)

  const dealerId = data && data.dealerId
  const dealerCode = data && data.dealerCode
  const dealerStatus = data && data.dealerStatus
  const name = data && data.name
  const province = data && data.province
  const city = data && data.city
  const address = data && data.address
  const postalCode = data && data.postalCode

  const gridDataLabel = [
    'dealerId',
    'dealerCode',
    'dealerStatus',
    'name',
    'province',
    'city',
    'address',
    'postalCode'
  ]
  const gridDataValue = [
    dealerId,
    dealerCode,
    dealerStatus,
    name,
    province,
    city,
    address,
    postalCode
  ]

  const status = data && data.status
  const createdBy = data && data.createdBy
  const createdAt = data && data.createdAt
  const modifiedBy = data && data.modifiedBy
  const modifiedAt = data && data.modifiedAt

  const gridBasicLabel = ['status', 'Created By', 'Created At', 'Modified By', 'Modified At']
  const gridBasicValue = [status, createdBy, createdAt, modifiedBy, modifiedAt]

  return (
    <Drawer open={open} title='Dealer Location Detail' onClose={onClose}>
      <Container {...DetailStyle.Container}>
        {(isLoading || isFetching) && !data && <Loading />}
        {data && (
          <Grid container spacing={2}>
            {gridDataValue.map((item, index) => (
              <Grid key={index} item xs={12}>
                <TextField
                  variant='outlined'
                  label={gridDataLabel[index]}
                  value={item}
                  inputProps={{ readOnly: true }}
                  fullWidth
                />
              </Grid>
            ))}
          </Grid>
        )}
        {data && (
          <Accordion elevation={0} disableGutters>
            <AccordionSummary expandIcon={<ExpandMoreOutlined />} aria-controls='basic-information'>
              <Typography>Basic Information</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {gridBasicValue.map((item, index) => (
                  <Grid key={index} item xs={12}>
                    <TextField
                      variant='outlined'
                      label={gridBasicLabel[index]}
                      value={item}
                      inputProps={{ readOnly: true }}
                      fullWidth
                    />
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
      </Container>
    </Drawer>
  )
}

export default Detail
