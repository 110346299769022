import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Popper,
  TextField,
  Typography
} from '@mui/material'
import type { PopperProps } from '@mui/material'
import Content from 'components/content/content'
import { useGetAccountCheckUserListMutation } from 'store/assignment'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { ErrorProps } from 'modules/types'
import GlobalStyle from 'modules/styles'
import useDebounce from 'modules/useDebounce'
import Detail from './detail/detail'

const Check = () => {
  const [getAccountList, accountList] = useGetAccountCheckUserListMutation()
  const [userId, setUserId] = useState<string>('')
  const [nameCheck, setNameCheck] = useState<string>('')
  const [focus, setFocus] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)

  const AutoCompletePopper = (props: PopperProps) => (
    <Popper {...props} style={{ width: 250 }} placement='bottom' />
  )

  const onCheckAccess = () => setDrawerDetail(true)

  const debounce = useDebounce(nameCheck)

  useEffect(() => {
    if (focus) getAccountList({ nameCheck })
  }, [debounce])

  return (
    <>
      <Content title='Check Access' useTable={false}>
        <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
          <Typography>Review the level of access a user has to this resource.</Typography>
          <Box sx={{ width: 250 }}>
            <Autocomplete
              PopperComponent={AutoCompletePopper}
              options={accountList.data || []}
              getOptionLabel={(list) => list.name}
              isOptionEqualToValue={(option, value) =>
                option && value ? option.name == value.name : false
              }
              onChange={(_, userId) => setUserId((userId && userId.userId) || '')}
              onInputChange={(_, value) => setNameCheck(value)}
              onFocus={() => setFocus(true)}
              ListboxProps={GlobalStyle.ListBox}
              renderOption={(props, item) => (
                <li {...props} key={item.userId}>
                  {item.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='userId'
                  label='Assign Access To'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {false && <CircularProgress color='inherit' size={20} />}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
            />
          </Box>
          <Box sx={{ width: 250 }}>
            <Button variant='contained' disabled={isEmpty(userId)} onClick={onCheckAccess}>
              Check Access
            </Button>
          </Box>
        </Box>
        <Detail
          id={userId}
          open={drawerDetail}
          nameCheck={nameCheck}
          onClose={() => setDrawerDetail(false)}
        />
      </Content>

      <Notification
        open={!accountList.isLoading && !accountList.isUninitialized && !accountList.isSuccess}
        onClose={() => (accountList.isError ? accountList.reset() : location.reload())}
        isError={accountList.isError}
        message={GLOBAL.returnExceptionMessage(
          accountList.isError,
          accountList.error as ErrorProps
        )}
      />
    </>
  )
}

export default Check
