import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import Drawer from 'components/drawer/detail'
import Icon from 'components/icon'
import Loading from 'components/loading/loading'
import type { DetailStateProps } from 'modules/types'
import { useGetEvDetailPartnerQuery } from 'store/evPartner'
import { useGetChargePointListMutation } from 'store/evChargePoint'
import CONSTANT from 'modules/constant'
import type { PartialChargePointFilterProps, PartialEVChargeProps } from 'modules/partial'
import BackdropImage from 'components/backdropImage/backdropImage'
import DialogStyle from './style'

const Detail = ({ open, id, onClose }: DetailStateProps & { id: number }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />
  const { data, isFetching, isLoading, isSuccess } = useGetEvDetailPartnerQuery(id)
  const [getChargePoint, chargePoint] = useGetChargePointListMutation()
  const [previewBackdrop, setPreviewBackdrop] = useState<boolean>(false)

  const [payload] = useState<PartialEVChargeProps & PartialChargePointFilterProps>({
    start: 0,
    length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    search: '',
    filter: '',
    sorting: 'name asc',
    OwnerId: '',
    PartnerId: (data && data.id.toString()) || '',
    PolicyId: '',
    CityId: ''
  })

  const onGetChargerPoint = () => {
    const formData = new FormData()

    formData.append('start', payload.start.toString())
    formData.append('length', payload.length.toString())
    formData.append('sorting', payload.sorting)

    if (!isEmpty(payload.search)) formData.append('search', payload.search)
    if (!isEmpty(payload.filter)) formData.append('filter', payload.filter)
    if (!isEmpty(payload.OwnerId)) formData.append('OwnerId', payload.OwnerId)
    formData.append('PartnerId', (data && data.id.toString()) || '')
    if (!isEmpty(payload.PolicyId)) formData.append('PolicyId', payload.PolicyId)
    if (!isEmpty(payload.CityId)) formData.append('CityId', payload.CityId)

    getChargePoint(formData)
  }

  useEffect(() => {
    if (isSuccess) {
      onGetChargerPoint()
    }
  }, [isSuccess])

  const name = (data && data.name) || '-'
  const shortName = (data && data.shortName) || '-'
  const userId = (data && data.userId) || '-'
  const password = data && (data.password || '-')
  const phoneNumber = (data && data.phoneNumber) || '-'
  const email = (data && data.email) || '-'

  const streetAddress = (data && data.streetAddress) || '-'
  const city = (data && data.city) || '-'
  const district = (data && data.district) || '-'

  const subDistrict = (data && data.subDistrict) || '-'
  const postalCode = (data && data.postalCode) || '-'
  const remark = (data && data.remark) || '-'
  const termsAndCondition = (data && data.termsAndCondition) || '-'
  const imageUrl = (data && data.imageUrl) || ''

  const gridDataLabel = [
    'Partner Name',
    'Partner Short Name',
    'Member ID',
    'Password',
    'Phone Number',
    'Email'
  ]
  const gridDataValue = [name, shortName, userId, password, phoneNumber, email]

  const gridDataLabelRight = [
    'Address',
    'City',
    'District',
    'Sub District',
    'Postal Code',
    'Description',
    'Terms and Condition'
  ]
  const gridDataValueRight = [
    streetAddress,
    city,
    district,
    subDistrict,
    postalCode,
    remark,
    termsAndCondition
  ]

  return (
    <Dialog open={open} fullWidth maxWidth='md' onClose={onClose}>
      <DialogContent>
        <DialogTitle
          sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
        >
          <Typography variant='h6'>Detail EV Partner Management System</Typography>
          <Box>
            <IconButton onClick={onClose}>
              <Icon icon='Close' />
            </IconButton>
          </Box>
        </DialogTitle>
        <Box {...DialogStyle.wrapperBox}>
          {(isLoading || isFetching) && !data && <Loading />}

          <Box {...DialogStyle.wrapperLeftContent}>
            {data && (
              <Grid container spacing={2} sx={{ marginBottom: '10px' }}>
                {gridDataValue.map((item, index) => (
                  <Grid key={index} item xs={12}>
                    <TextField
                      variant='outlined'
                      label={gridDataLabel[index]}
                      value={item}
                      inputProps={{ readOnly: true }}
                      fullWidth
                    />
                  </Grid>
                ))}
              </Grid>
            )}
            <TableContainer
              component={Paper}
              sx={{ maxHeight: 370, marginTop: '10px' }}
              variant='outlined'
            >
              <Table aria-label='Data Table' stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Charger Point</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {chargePoint.isSuccess &&
                    chargePoint.data &&
                    chargePoint.data.data.map((table, index) => (
                      <TableRow key={index}>
                        <TableCell>{table.name}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box sx={{ borderLeft: '0.5px solid gray', height: '120%' }}>
            <Box sx={{ padding: '10px 24px' }}>
              {data && (
                <Grid container spacing={2} sx={{ marginBottom: '10px' }}>
                  {gridDataValueRight.map((item, index) => (
                    <Grid key={index} item xs={12}>
                      <TextField
                        variant='outlined'
                        label={gridDataLabelRight[index]}
                        value={item}
                        inputProps={{ readOnly: true }}
                        fullWidth
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
              <Box {...DialogStyle.wrapperImage}>
                {!imageUrl && <Typography style={{ color: '#cfcfcf' }}>No Image</Typography>}
                {imageUrl && (
                  <BackdropImage
                    open={previewBackdrop}
                    onOpen={() => setPreviewBackdrop(true)}
                    onClose={() => setPreviewBackdrop(false)}
                  >
                    <img
                      src={imageUrl}
                      alt={imageUrl}
                      style={{ height: '300px', width: '394px', objectFit: 'contain' }}
                    />
                  </BackdropImage>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default Detail
