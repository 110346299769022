import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { isEmpty } from 'lodash'
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import * as yup from 'yup'
import moment from 'moment'
import Dialog from 'components/dialog/dialog'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { PartialPromoCodeBatchCreateTempProps } from 'modules/partial'
import GlobalStyle from 'modules/styles'
import type { DetailStateProps, ErrorProps, MemberTypeDropDown } from 'modules/types'
import { useGetMemberTypeDropdownMutation } from 'store/dropdown'
import {
  useDropDownPromoCodeBatchRewardMutation,
  useGetDetailPromoCodeBatchQuery,
  useUpdatePromoCodeBatchMutation
} from 'store/promoCodeBatch'
import { useGetVoucherListDropdownMutation } from 'store/tokenRequest'
import DialogStyle from './style'

const Update = ({ open, onClose, id }: DetailStateProps & { id: number }) => {
  if (!open) return <></>
  const { data, isLoading, isFetching, isSuccess } = useGetDetailPromoCodeBatchQuery(id)
  const [dropDownPromoCodeBatchReward, dropdownReward] = useDropDownPromoCodeBatchRewardMutation()
  const [getVoucherListDropdown, voucherDropdown] = useGetVoucherListDropdownMutation()
  const [getMemberTypeDropdown, memberType] = useGetMemberTypeDropdownMutation()
  const [updatePromoCodeBatch, update] = useUpdatePromoCodeBatchMutation()
  const [valVoucher, setValVoucher] = useState<string>('')
  const [qty, setQty] = useState<number | string>('')
  const [rewardType, setRewardType] = useState<string>((data && data.rewardType) || '')
  const [userType, setUserType] = useState<MemberTypeDropDown[]>([])
  const [payloadDate, setPayloadDate] = useState<{
    minSignUpDate: string | null
    maxSignUpDate: string | null
  }>({
    minSignUpDate: '',
    maxSignUpDate: ''
  })
  const [payload, setPayload] = useState<{
    coinsAmount: number | null
    maxRedemption: number | null
  }>({
    coinsAmount: (data && data.coinsAmount) || null,
    maxRedemption: (data && data.maxRedemption) || null
  })
  const [date, setDate] = useState<{
    minDateSignUp: Dayjs | null
    maxDateSignUp: Dayjs | null
    validFrom: Dayjs | null
    validUntil: Dayjs | null
  }>({
    minDateSignUp: (data && dayjs(data.minSignUpDate)) || null,
    maxDateSignUp: (data && dayjs(data.maxSignUpDate)) || null,
    validFrom: (data && dayjs(data.validFrom)) || null,
    validUntil: (data && dayjs(data.validTo)) || null
  })
  const [time, setTime] = useState({
    startTime: '',
    endTime: ''
  })

  const scheme = yup.object<PartialPromoCodeBatchCreateTempProps>({
    codeLength: yup.number().required('Code Length Name is required'),
    name: yup.string().required('Campaign Name is required'),
    prefix: yup.string().required('Prefix is required'),
    rewardType: yup.string().required('Reward is required'),
    validFrom: yup.string().required('Valid From is required'),
    validTo: yup.string().required('Valid Untill is required'),
    messageTitle: yup.string().required('Message Title is required'),
    messageBody: yup.string().required('Message Titleis required')
  })

  const formik = useFormik<PartialPromoCodeBatchCreateTempProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      codeLength: (data && data.codeLength) || null,
      name: (data && data.name) || '',
      prefix: (data && data.prefix) || '',
      rewardType: (data && data.rewardType) || '',
      validFrom: (data && GLOBAL.formatDate(data.validFrom)) || '',
      validTo: (data && GLOBAL.formatDate(data.validTo)) || '',
      messageTitle: (data && data.messageTitle) || '',
      messageBody: (data && data.messageBody) || '',
      quantity: (data && data.quantity) || null
    },
    onSubmit: (values: PartialPromoCodeBatchCreateTempProps) => handleSubmit(values)
  })

  useEffect(() => {
    if (data && isSuccess) {
      const defaultUserType =
        (data &&
          data.members &&
          data.members.map((item) => ({
            id: item.memberId,
            key: item.memberName,
            name: item.memberName
          }))) ||
        []
      setUserType(defaultUserType)

      setDate({
        minDateSignUp: (data.minSignUpDate && dayjs(data.minSignUpDate)) || null,
        maxDateSignUp: (data.maxSignUpDate && dayjs(data.maxSignUpDate)) || null,
        validFrom: (data.validFrom && dayjs(data.validFrom)) || null,
        validUntil: (data.validTo && dayjs(data.validTo)) || null
      })
      setPayloadDate({
        minSignUpDate:
          (data.minSignUpDate && moment(data.minSignUpDate).format('YYYY-MM-DD')) || null,
        maxSignUpDate:
          (data.maxSignUpDate && moment(data.maxSignUpDate).format('YYYY-MM-DD')) || null
      })
      setTime({
        startTime: (data && GLOBAL.formatDate(data.validFrom)) || '',
        endTime: (data && GLOBAL.formatDate(data.validTo)) || ''
      })

      setPayload({
        coinsAmount: (data && data.coinsAmount) || null,
        maxRedemption: (data && data.maxRedemption) || null
      })
    }
  }, [isSuccess, data])

  const handleSubmit = (e: PartialPromoCodeBatchCreateTempProps) => {
    const formatUser =
      userType?.length > 0
        ? userType.map((item) => ({
            memberId: item.id,
            memberName: item.name
          }))
        : []

    const formatPayload = {
      ...e,
      minSignUpDate: !isEmpty(payloadDate.minSignUpDate) ? payloadDate.minSignUpDate : null,
      maxSignUpDate: !isEmpty(payloadDate.maxSignUpDate) ? payloadDate.maxSignUpDate : null,
      ...payload,
      validFrom: dayjs(e.validFrom).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      validTo: dayjs(e.validTo).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      members: formatUser
    }

    updatePromoCodeBatch({ body: formatPayload, id })
  }

  const DescMessage = () => (
    <Box sx={{ marginBottom: '10px' }}>
      <Typography variant='caption' display='block' gutterBottom color='#525252'>
        *You can use these placeholder in the field above
      </Typography>
      <Typography variant='caption' display='block' gutterBottom color='#525252'>
        <span
          style={{ fontSize: '14px', fontWeight: '500', color: '#242423' }}
        >{`{userFullName} `}</span>
        Name of the recipient
      </Typography>
      <Typography variant='caption' display='block' gutterBottom color='#525252'>
        <span
          style={{ fontSize: '14px', fontWeight: '500', color: '#242423' }}
        >{`{voucherName} `}</span>
        Voucher Title
      </Typography>
      <Typography variant='caption' display='block' gutterBottom color='#525252'>
        <span
          style={{ fontSize: '14px', fontWeight: '500', color: '#242423' }}
        >{`{coinsAmount} `}</span>
        Coin amount given to user
      </Typography>
    </Box>
  )
  return (
    <>
      <Dialog
        isDisabled={!formik.isValid}
        title='Update Promo Code Batch'
        open={open}
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={update.isLoading}
        maxWidth='md'
      >
        <Container {...DialogStyle.Container}>
          {(isLoading || isFetching) && <Loading />}

          {data && isSuccess && (
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Box sx={{ display: 'flex', flex: 1, gap: 2, flexDirection: 'column' }}>
                <TextField
                  {...DialogStyle.textField}
                  id='name'
                  disabled
                  variant='outlined'
                  label='Campaign Name'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  error={formik.touched && formik.touched.name && Boolean(formik.errors.name)}
                  helperText={
                    formik.touched && formik.touched.name && formik.errors && formik.errors.name
                  }
                  fullWidth
                />
                <TextField
                  id='prefix'
                  variant='outlined'
                  label='Prefix'
                  disabled
                  onChange={formik.handleChange}
                  {...DialogStyle.textField}
                  onBlur={formik.handleBlur}
                  value={formik.values.prefix}
                  error={formik.touched && formik.touched.prefix && Boolean(formik.errors.prefix)}
                  helperText={
                    formik.touched && formik.touched.prefix && formik.errors && formik.errors.prefix
                  }
                  fullWidth
                />
                <Autocomplete
                  sx={{ marginBottom: '10px' }}
                  options={(dropdownReward && dropdownReward.data) || []}
                  getOptionLabel={(option) => option.text}
                  isOptionEqualToValue={(option, value) =>
                    option && value ? option.value == value.value : false
                  }
                  disabled
                  defaultValue={{
                    text: (data && data.rewardType) || '',
                    value: Math.floor(Math.random() * 11),
                    group: ''
                  }}
                  onOpen={() => dropDownPromoCodeBatchReward()}
                  onChange={(_, reward) => {
                    if (!isEmpty(reward)) {
                      formik.setFieldValue('rewardType', reward.text)
                      setRewardType(reward.text)
                    }
                  }}
                  value={
                    (dropdownReward &&
                      dropdownReward.data &&
                      dropdownReward.data.find((e) => e.text == rewardType)) ||
                    undefined
                  }
                  ListboxProps={GlobalStyle.ListBox}
                  renderOption={(props, item) => (
                    <li {...props} key={item.value}>
                      {item.text}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name='Reward'
                      label='Reward'
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {dropdownReward.isLoading && (
                              <CircularProgress color='inherit' size={20} />
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                  }}
                >
                  <Autocomplete
                    key={'Voucher-'}
                    sx={{ width: '70%', marginBottom: '10px', marginRight: '5px' }}
                    options={voucherDropdown.data || []}
                    getOptionLabel={(list) => list.name}
                    isOptionEqualToValue={(option, value) =>
                      option && value ? option.id == value.id : false
                    }
                    disabled
                    onOpen={() => getVoucherListDropdown()}
                    onChange={(_, vouc) => {
                      if (vouc) {
                        // const newVal = [vouc]
                        setValVoucher(vouc.name)
                      }
                    }}
                    ListboxProps={GlobalStyle.ListBox}
                    value={
                      voucherDropdown &&
                      voucherDropdown.data &&
                      voucherDropdown.data.find((e) => e.name == valVoucher)
                    }
                    renderOption={(props, item) => (
                      <li {...props} key={item.id}>
                        {item.name}
                      </li>
                    )}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name='voucherId'
                        label='Voucher'
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {voucherDropdown.isLoading && (
                                <CircularProgress color='inherit' size={20} />
                              )}
                              {params.InputProps.endAdornment}
                            </>
                          )
                        }}
                      />
                    )}
                  />
                  <TextField
                    sx={{ width: '30%' }}
                    id='longitude'
                    variant='outlined'
                    label='Qty'
                    type='number'
                    inputProps={{ min: 1 }}
                    value={qty}
                    disabled
                    fullWidth
                    onChange={(e) => setQty(parseInt(e.target.value))}
                  />
                  <Button sx={{ height: '56px', marginLeft: '5px' }} variant='contained' disabled>
                    Add
                  </Button>
                </Box>
                <TableContainer
                  component={Paper}
                  sx={{ maxHeight: 512, marginBottom: '10px' }}
                  variant='outlined'
                >
                  <Table aria-label='Data Table' stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Voucher</TableCell>
                        <TableCell>Qty</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data &&
                        data.vouchers.map((table, index) => (
                          <TableRow key={index}>
                            <TableCell>{table.voucherName}</TableCell>
                            <TableCell>{table.quantity}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TextField
                  {...DialogStyle.textField}
                  id='coin'
                  variant='outlined'
                  label='Coint Amount'
                  value={payload.coinsAmount || '-'}
                  disabled
                  onChange={(e) =>
                    setPayload({ ...payload, coinsAmount: parseInt(e.target.value) })
                  }
                  fullWidth
                />
                <TextField
                  disabled
                  id='quantity'
                  variant='outlined'
                  {...DialogStyle.textField}
                  label='Quantity'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.quantity}
                  error={
                    formik.touched && formik.touched.quantity && Boolean(formik.errors.quantity)
                  }
                  type='number'
                  inputProps={{ min: 1 }}
                  helperText={
                    formik.touched &&
                    formik.touched.quantity &&
                    formik.errors &&
                    formik.errors.quantity
                  }
                  fullWidth
                />
                <TextField
                  disabled
                  id='codeLength'
                  variant='outlined'
                  {...DialogStyle.textField}
                  label='Code Length'
                  type='number'
                  inputProps={{ min: 1 }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.codeLength}
                  error={
                    formik.touched && formik.touched.codeLength && Boolean(formik.errors.codeLength)
                  }
                  helperText={
                    formik.touched &&
                    formik.touched.codeLength &&
                    formik.errors &&
                    formik.errors.codeLength
                  }
                  fullWidth
                />
                <TextField
                  inputProps={{ min: '0' }}
                  id='max'
                  variant='outlined'
                  {...DialogStyle.textField}
                  label='Maximal Redemption Count(max token distributed for this campaign)'
                  fullWidth
                  value={payload.maxRedemption}
                  onChange={(e) =>
                    setPayload({ ...payload, maxRedemption: parseInt(e.target.value) })
                  }
                  type='number'
                />
                <TextField
                  id='startTime'
                  variant='outlined'
                  {...DialogStyle.textField}
                  label='Start Time'
                  value={time.startTime}
                  disabled
                  fullWidth
                />
                <TextField
                  id='endTime'
                  variant='outlined'
                  {...DialogStyle.textField}
                  value={time.endTime}
                  label='End Time'
                  disabled
                  fullWidth
                />
              </Box>

              <Box sx={{ display: 'flex', flex: 1, gap: 2, flexDirection: 'column' }}>
                <Autocomplete
                  fullWidth
                  sx={{ marginBottom: '10px' }}
                  multiple
                  id='fixed-tags-demo'
                  value={userType}
                  onOpen={() => getMemberTypeDropdown()}
                  isOptionEqualToValue={(option, value) =>
                    option && value ? option.name == value.name : false
                  }
                  defaultValue={userType}
                  onChange={(event, newValue) => {
                    setUserType(newValue)
                  }}
                  options={(memberType && memberType.data) || []}
                  getOptionLabel={(option) => option.name}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip label={option.name} {...getTagProps({ index })} key={index} />
                    ))
                  }
                  renderInput={(params) => <TextField {...params} label='User Type' />}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                  <DatePicker
                    value={date.validFrom}
                    sx={{ width: '100%', marginBottom: '10px' }}
                    onChange={(e) => {
                      const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                      formik.setFieldValue('validFrom', newDate)
                      setDate((prevState) => ({ ...prevState, validFrom: e }))
                    }}
                    label='Valid From'
                    format='DD-MM-YYYY'
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                  <DatePicker
                    minDate={dayjs(formik.values.validFrom)}
                    value={date.validUntil}
                    sx={{ width: '100%', marginBottom: '10px' }}
                    onChange={(e) => {
                      const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                      formik.setFieldValue('validTo', newDate)
                      setDate((prevState) => ({ ...prevState, validUntil: e }))
                    }}
                    label='Valid Until'
                    format='DD-MM-YYYY'
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                  <DatePicker
                    value={date.minDateSignUp}
                    sx={{ width: '100%', marginBottom: '10px' }}
                    onChange={(e) => {
                      const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                      setPayloadDate({ ...payloadDate, minSignUpDate: newDate })
                      setDate((prevState) => ({ ...prevState, minDateSignUp: e }))
                    }}
                    label='Minimum Sign Up Date'
                    format='DD-MM-YYYY'
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                  <DatePicker
                    minDate={dayjs(payloadDate.minSignUpDate)}
                    value={date.maxDateSignUp}
                    sx={{ width: '100%', marginBottom: '10px' }}
                    onChange={(e) => {
                      const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                      setPayloadDate({ ...payloadDate, maxSignUpDate: newDate })
                      setDate((prevState) => ({ ...prevState, maxDateSignUp: e }))
                    }}
                    label='Maximum Sign Up Date'
                    format='DD-MM-YYYY'
                  />
                </LocalizationProvider>
                <TextField
                  id='messageTitle'
                  variant='outlined'
                  label='Message Title'
                  {...DialogStyle.textField}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.messageTitle}
                  error={
                    formik.touched &&
                    formik.touched.messageTitle &&
                    Boolean(formik.errors.messageTitle)
                  }
                  helperText={
                    formik.touched &&
                    formik.touched.messageTitle &&
                    formik.errors &&
                    formik.errors.messageTitle
                  }
                  fullWidth
                />
                <DescMessage />
                <TextField
                  id='messageBody'
                  variant='outlined'
                  label='Message Body'
                  {...DialogStyle.textField}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.messageBody}
                  error={
                    formik.touched &&
                    formik.touched.messageBody &&
                    Boolean(formik.errors.messageBody)
                  }
                  helperText={
                    formik.touched &&
                    formik.touched.messageBody &&
                    formik.errors &&
                    formik.errors.messageBody
                  }
                  fullWidth
                />
                <DescMessage />
              </Box>
            </Box>
          )}
        </Container>
      </Dialog>
      <Notification
        open={!update.isLoading && !update.isUninitialized}
        onClose={() => (update.isError ? update.reset() : location.reload())}
        isError={Boolean(update.error) && update.isError}
        message={GLOBAL.returnExceptionMessage(update.isError, update.error as ErrorProps)}
      />
    </>
  )
}

export default Update
