import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Grid,
  TextField,
  Typography
} from '@mui/material'
import { ExpandMoreOutlined } from '@mui/icons-material'
import Drawer from 'components/drawer/detail'
import type { DetailStateProps } from 'modules/types'
import { useGetChargingDetailQuery } from 'store/evChargingUrl'
import Loading from 'components/loading/loading'
import DetailStyle from './style'

const Detail = ({ open, id, onClose }: DetailStateProps & { id: number }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const { data, isLoading, isFetching } = useGetChargingDetailQuery(id)

  const loading = (isLoading || isFetching) && !data

  const identity = (data && data.id) || '-'
  const code = (data && data.code) || '-'
  const url = (data && data.url) || '-'
  const remark = (data && data.remark) || '-'

  const gridDataLabel = ['ID', 'QR Value', 'Charge Box ID', 'Notes']
  const gridDataValue = [identity, code, url, remark]

  const createdBy = (data && data.createdBy) || '-'
  const createdAt = (data && data.createdAt) || '-'
  const modifiedBy = (data && data.modifiedBy) || '-'
  const modifiedAt = (data && data.modifiedAt) || '-'

  const gridBasicLabel = ['Created By', 'Created At', 'Modified By', 'Modified At']
  const gridBasicValue = [createdBy, createdAt, modifiedBy, modifiedAt]

  return (
    <Drawer title='EV Charging URL Detail' open={open} onClose={onClose}>
      <Container {...DetailStyle.Container}>
        {loading && <Loading />}
        {!loading && (
          <>
            <Grid container spacing={2}>
              {gridDataValue.map((item, index) => (
                <Grid key={index} item xs={12}>
                  <TextField
                    variant='outlined'
                    label={gridDataLabel[index]}
                    value={item}
                    inputProps={{ readOnly: true }}
                    fullWidth
                  />
                </Grid>
              ))}
            </Grid>
            <Accordion elevation={0} disableGutters>
              <AccordionSummary
                expandIcon={<ExpandMoreOutlined />}
                aria-controls='basic-information'
              >
                <Typography>Basic Information</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {gridBasicValue.map((item, index) => (
                    <Grid key={index} item xs={12}>
                      <TextField
                        variant='outlined'
                        label={gridBasicLabel[index]}
                        value={item}
                        inputProps={{ readOnly: true }}
                        fullWidth
                      />
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </>
        )}
      </Container>
    </Drawer>
  )
}

export default Detail
