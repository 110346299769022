import { useState } from 'react'
import { Container, TextField, Typography } from '@mui/material'
import Drawer from 'components/drawer/detail'
import Loading from 'components/loading/loading'
import type { DetailStateProps } from 'modules/types'
import { useGetVoucherCategoryDetailQuery } from 'store/voucherCategory'
import IconMoon from 'components/iconmoon'
import BackdropImage from 'components/backdropImage/backdropImage'
import DrawerStyle from './style'

const Detail = ({ open, id, onClose }: DetailStateProps & { id: string | number }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const { data, isFetching, isLoading } = useGetVoucherCategoryDetailQuery(id)
  const [previewBackdrop, setPreviewBackdrop] = useState<boolean>(false)

  return (
    <>
      <Drawer open={open} title='Detail Voucher Category' onClose={onClose}>
        <Container {...DrawerStyle.Container}>
          {(isLoading || isFetching) && !data && <Loading />}
          {data && (
            <>
              <TextField
                id='key'
                variant='outlined'
                label='Voucher Category Name EN-US'
                value={data && data.nameEn}
                inputProps={{ readOnly: true }}
                fullWidth
              />
              <TextField
                id='key'
                variant='outlined'
                label='Voucher Category Name ID'
                value={data && data.nameId}
                inputProps={{ readOnly: true }}
                fullWidth
              />
              <TextField
                id='key'
                variant='outlined'
                label='Description'
                value={data && data.description}
                inputProps={{ readOnly: true }}
                fullWidth
              />
              <TextField
                id='key'
                variant='outlined'
                label='Sequence'
                value={data && data.seq}
                inputProps={{ readOnly: true }}
                fullWidth
              />
              <Typography style={{ fontSize: 12, color: 'gray', marginBottom: -15 }}>
                Voucher Category Image *
              </Typography>
              <div
                style={{
                  height: '300px',
                  border: '1px solid #eee',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                {typeof data.image == 'undefined' && (
                  <Typography style={{ color: '#cfcfcf' }}>Image Not Found</Typography>
                )}
                {data && data.image && (
                  <BackdropImage
                    open={previewBackdrop}
                    onOpen={() => setPreviewBackdrop(true)}
                    onClose={() => setPreviewBackdrop(false)}
                  >
                    <img
                      src={data.image}
                      alt=''
                      style={{ height: '300px', width: '537px', objectFit: 'contain' }}
                    />
                  </BackdropImage>
                )}
              </div>
              <Typography style={{ fontSize: 12, color: 'gray', marginBottom: -15 }}>
                Voucher Category Icon *
              </Typography>
              <Typography>
                {data && data.icon && <IconMoon icon={data.icon} size={25} />}
              </Typography>
            </>
          )}
        </Container>
      </Drawer>
    </>
  )
}

export default Detail
