import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { useLocation, useParams } from 'react-router-dom'
import {
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
  Checkbox,
  TextField,
  CircularProgress,
  Autocomplete
} from '@mui/material'
import Content from 'components/content/content'
import type { ErrorProps, ParamProps } from 'modules/types'
import {
  useDownloadVoucherUsageTokenListMutation,
  useGetVoucherUsageTokenListMutation,
  useMoveVoucherUsageTokenMutation,
  useRevokeVoucherUsageTokenMutation
} from 'store/voucherUsage'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import Loading from 'components/loading/loading'
import CONSTANT from 'modules/constant'
import type {
  PartialVoucherUsageTokenProps,
  PartialFormDataCapsProps,
  PartialVoucherUsageTokenStateProps
} from 'modules/partial'
import Icon from 'components/icon'
import Dialog from 'components/dialog/dialog'
import { useGetVoucherListDropdownMutation } from 'store/tokenRequest'
import GlobalStyle from 'modules/styles'
import Detail from './detail/detail'

const VoucherUsageTokenList = () => {
  const params = useParams<ParamProps>()
  const locations = useLocation()
  const state = locations.state as { voucherName: string }

  const [getVoucherUsageTokenList, listVoucherUsageToken] = useGetVoucherUsageTokenListMutation()
  const [downloadVoucherUsageTokenList, download] = useDownloadVoucherUsageTokenListMutation()
  const [getVoucherListDropdown, voucherDropdown] = useGetVoucherListDropdownMutation()
  const [moveToken, move] = useMoveVoucherUsageTokenMutation()
  const [revokeToken, revoke] = useRevokeVoucherUsageTokenMutation()

  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [menu, setMenu] = useState<null | number>(null)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [dialog, setDialog] = useState<boolean>(false)
  const [isRevoke, setIsRevoke] = useState<boolean>(false)
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const [listChecked, setListChecked] = useState<string[]>([])
  const [changeValue, setChangeValue] = useState<string | null>(null)

  const [payload, setPayload] = useState<PartialVoucherUsageTokenProps & PartialFormDataCapsProps>({
    Start: 0,
    Length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    Search: { value: '' },
    VoucherId: params.id || ''
  })

  const onSearch = (value: string) => setPayload({ ...payload, Start: 0, Search: { value } })
  const onChangePage = (value: number) => setPayload({ ...payload, Start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, Length: value })

  const onResync = () => {
    const formData = new FormData()

    formData.append('Start', payload.Start.toString())
    formData.append('Length', payload.Length.toString())
    formData.append('VoucherId', payload.VoucherId.toString())

    if (!isEmpty(payload.Search) && !isEmpty(payload.Search.value))
      formData.append('Search', payload.Search.value)

    getVoucherUsageTokenList(formData)
  }

  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('start', String(0))
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())
    formData.append('VoucherId', payload.VoucherId.toString())

    if (!isEmpty(payload.Search) && !isEmpty(payload.Search.value))
      formData.append('Search', payload.Search.value)

    if (type && !isEmpty(type)) formData.append('Content-Type', type)

    downloadVoucherUsageTokenList(formData)
  }

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback: CallableFunction) => {
    setAnchor(null)
    callback(callback)
  }

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) =>
    event.target.checked
      ? setListChecked([...listChecked, event.target.value])
      : setListChecked(listChecked.filter((v) => v !== event.target.value))

  const onCheckBoxAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(!isChecked)

    if (event.target.checked) {
      const list = listVoucherUsageToken.data && listVoucherUsageToken.data.data.map((x) => x.code)

      if (list) setListChecked([...listChecked, ...list])
      return
    }

    setListChecked([])
    return
  }

  const onDialogOpen = (type: string) => {
    setIsRevoke(type == 'revoke')
    setDialog(true)
  }

  const submitChangeValue = () => {
    const payload: PartialVoucherUsageTokenStateProps = {
      voucherTokenCodes: listChecked,
      [isRevoke ? 'reason' : 'to']: isRevoke ? changeValue : Number(changeValue)
    }

    return isRevoke ? revokeToken(payload) : moveToken(payload)
  }

  const tableHeadTitles = ['Code', 'Partner', 'Valid From', 'Valid Until', 'Status']

  const openAnchor = Boolean(anchor)
  const totalPage =
    listVoucherUsageToken.data && GLOBAL.tableTotalPage(listVoucherUsageToken.data.recordsTotal)
  const numbers = GLOBAL.tableNumber(payload.Start)

  useEffect(() => {
    onResync()
  }, [payload])

  return (
    <>
      <Content
        title={`Voucher Usage Token List › ${state.voucherName}`}
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        onCheckBoxAll={onCheckBoxAll}
        onRevokeToken={() => onDialogOpen('revoke')}
        onMoveToken={() => onDialogOpen('move')}
        allChecked={listChecked}
        totalPage={totalPage}
        totalRecords={listVoucherUsageToken.data && listVoucherUsageToken.data.recordsTotal}
        page={payload.Start + 1}
        isLoading={listVoucherUsageToken.isLoading}
        useBackButton
        useDownloadDropdown
        useCheckbox
      >
        {listVoucherUsageToken.isSuccess &&
          listVoucherUsageToken.data &&
          listVoucherUsageToken.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>
                <Checkbox
                  value={table.code}
                  onChange={handleCheck}
                  checked={listChecked.includes(table.code)}
                />
              </TableCell>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.id}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.id}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.id}`}
                  aria-labelledby={`button-${index}-${table.id}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View
                  </MenuItem>
                </Menu>
                <Detail
                  open={drawerDetail && menu == index}
                  onClose={() => setDrawerDetail(false)}
                  id={table.id}
                />
              </TableCell>
              <TableCell>{table.code || '-'}</TableCell>
              <TableCell>{table.partnerName || '-'}</TableCell>
              <TableCell>
                {(table.validFrom && GLOBAL.formatDateWithTime(table.validFrom)) || '-'}
              </TableCell>
              <TableCell>
                {(table.validUntil && GLOBAL.formatDateWithTime(table.validUntil)) || '-'}
              </TableCell>
              <TableCell>{table.status || '-'}</TableCell>
            </TableRow>
          ))}
      </Content>

      {listVoucherUsageToken.isLoading && <Loading />}

      <Dialog
        open={dialog}
        title={isRevoke ? 'Revoke Voucher Usage Token' : 'Move Voucher Usage Token'}
        loading={isRevoke ? revoke.isLoading : move.isLoading}
        onCancel={() => {
          setChangeValue(null)
          setDialog(false)
        }}
        onSubmit={submitChangeValue}
        isDisabled={isEmpty(changeValue)}
      >
        <TextField
          multiline
          rows={4}
          value={listChecked.join(',\n')}
          inputProps={{ readOnly: true }}
        />
        {!isRevoke && (
          <Autocomplete
            key={changeValue}
            options={(voucherDropdown && voucherDropdown.data) || []}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) =>
              option && value ? option.id == value.id : false
            }
            onOpen={() => getVoucherListDropdown()}
            onChange={(_, voucher) => setChangeValue((voucher && String(voucher.id)) || null)}
            value={
              (voucherDropdown &&
                voucherDropdown.data &&
                voucherDropdown.data.find((e) => e.id == changeValue)) ||
              null
            }
            ListboxProps={GlobalStyle.ListBox}
            renderOption={(props, item) => (
              <li {...props} key={item.id}>
                {item.name}
              </li>
            )}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                name='voucherToken'
                label='Voucher Name'
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {voucherDropdown.isLoading && <CircularProgress color='inherit' size={20} />}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
        )}
        {isRevoke && (
          <TextField
            label='Reason'
            id='Reason'
            multiline
            rows={4}
            onChange={(event) => setChangeValue(event.target.value)}
          />
        )}
      </Dialog>

      <Notification
        open={
          !listVoucherUsageToken.isLoading &&
          !listVoucherUsageToken.isUninitialized &&
          !listVoucherUsageToken.isSuccess
        }
        onClose={() =>
          listVoucherUsageToken.isError ? listVoucherUsageToken.reset() : location.reload()
        }
        isError={Boolean(listVoucherUsageToken.error) && listVoucherUsageToken.isError}
        message={GLOBAL.returnExceptionMessage(
          listVoucherUsageToken.isError,
          listVoucherUsageToken.error as ErrorProps
        )}
      />

      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />

      <Notification
        open={!revoke.isLoading && !revoke.isUninitialized}
        onClose={() => (revoke.isError ? revoke.reset() : location.reload())}
        isError={Boolean(revoke.error) && revoke.isError}
        message={GLOBAL.returnExceptionMessage(revoke.isError, revoke.error as ErrorProps)}
      />

      <Notification
        open={!move.isLoading && !move.isUninitialized}
        onClose={() => (move.isError ? move.reset() : location.reload())}
        isError={Boolean(move.error) && move.isError}
        message={GLOBAL.returnExceptionMessage(move.isError, move.error as ErrorProps)}
      />
    </>
  )
}

export default VoucherUsageTokenList
