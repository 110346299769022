import { useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import { isEmpty } from 'lodash'
import {
  Autocomplete,
  Box,
  CircularProgress,
  Container,
  Grid,
  TextField,
  Typography
} from '@mui/material'
import * as yup from 'yup'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import type { Dayjs } from 'dayjs'
import Dialog from 'components/dialog/dialog'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { PayloadVoucherGiftProps } from 'modules/partial'
import type { DetailStateProps, ErrorProps, voucherGiftProps } from 'modules/types'
import GlobalStyle from 'modules/styles'
import { useGetUsernameDropdownMutation } from 'store/dropdown'
import { useGetVoucherListDropdownMutation } from 'store/tokenRequest'
import { useCreateVoucherGiftMutation } from 'store/voucherGift'
import DialogStyle from './style'

const Create = ({ open, onClose }: DetailStateProps) => {
  const [createVoucherGift, create] = useCreateVoucherGiftMutation()
  const [getVoucherListDropdown, voucherDropdown] = useGetVoucherListDropdownMutation()
  const [getUsernameDropdown, dropdownUser] = useGetUsernameDropdownMutation()

  const [startDate, setStartDate] = useState<Dayjs | null>(null)
  const [endDate, setEndDate] = useState<Dayjs | null>(null)
  const [validFrom, setValidFrom] = useState<Dayjs | null>(null)
  const [validUntil, setValidUntil] = useState<Dayjs | null>(null)

  const scheme = yup.object<voucherGiftProps>({
    voucherGiftName: yup.string().required('Voucher Gift Name is required'),
    assignmentMethode: yup.string().required('Assignment Methode is required'),
    assignValidFrom: yup.string().when('assignmentMethode', {
      is: (val: string) => val && val.toLocaleLowerCase().includes('url'),
      then: () => yup.string().required('Assignment Valid From is required'),
      otherwise: () => yup.array().notRequired()
    }),
    assignValidUntil: yup.string().when('assignmentMethode', {
      is: (val: string) => val && val.toLocaleLowerCase().includes('url'),
      then: () => yup.string().required('Assignment Valid Until is required'),
      otherwise: () => yup.array().notRequired()
    }),
    voucherId: yup.string().required('Voucher is required'),
    assignmentMode: yup.string().required('Assignment Mode is required'),
    token: yup.string().when('assignmentMode', {
      is: (val: string) => val == 'AssignCustomToken',
      then: () => yup.string().required('Token is required'),
      otherwise: () => yup.array().notRequired()
    }),
    validFrom: yup.string().when('assignmentMode', {
      is: (val: string) => val != 'UseAvailableToken',
      then: () => yup.string().required('Token Valid From is required'),
      otherwise: () => yup.array().notRequired()
    }),
    validUntil: yup.string().when('assignmentMode', {
      is: (val: string) => val != 'UseAvailableToken',
      then: () => yup.string().required('Token Valid Until is required'),
      otherwise: () => yup.array().notRequired()
    }),
    notes: yup.string().required('Notes is required'),
    notiftitle: yup.string().required('Notification Title is required'),
    notifdata: yup.string().required('Notification Body is required')
  })

  const formik = useFormik<voucherGiftProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      voucherGiftName: '',
      assignmentMethode: '',
      assignValidFrom: '',
      assignValidUntil: '',
      voucherId: '',
      assignmentMode: '',
      token: '',
      validFrom: '',
      validUntil: '',
      notes: '',
      userId: '',
      notiftitle: '',
      notifdata: '',
      notifurl: '',
      notifImage: ''
    },
    onSubmit: (values: voucherGiftProps) => handleSubmit(values)
  })

  const handleSubmit = (e: voucherGiftProps) => {
    const notification = {
      notiftitle: e.notiftitle,
      notifdata: e.notifdata,
      notifurl: '',
      notifImage: ''
    }

    const data: PayloadVoucherGiftProps = {
      voucherGiftName: e.voucherGiftName,
      assignmentMethode: e.assignmentMethode,
      assignValidFrom: e.assignValidFrom
        ? dayjs(e.assignValidFrom).startOf('day').format('YYYY-MM-DD HH:mm:ss')
        : '',
      assignValidUntil: e.assignValidUntil
        ? dayjs(e.assignValidUntil).endOf('day').format('YYYY-MM-DD HH:mm:ss')
        : '',
      voucherId: e.voucherId,
      assignmentMode: e.assignmentMode,
      token: e.token,
      validFrom: e.validFrom ? dayjs(e.validFrom).startOf('day').format('YYYY-MM-DD HH:mm:ss') : '',
      validUntil: e.validUntil
        ? dayjs(e.validUntil).endOf('day').format('YYYY-MM-DD HH:mm:ss')
        : '',
      notes: e.notes,
      userId: e.userId,
      dataObjectNotif: notification
    }

    createVoucherGift(data)
  }

  const handleChangeStartDate = (date: Dayjs | null) => {
    setStartDate(date)
    const newDate = (!isEmpty(date) && date.format('YYYY-MM-DD')) || ''
    formik.setFieldValue('assignValidFrom', newDate)
  }

  const handleChangeEndDate = (date: Dayjs | null) => {
    setEndDate(date)
    const newDate = (!isEmpty(date) && date.format('YYYY-MM-DD')) || ''
    formik.setFieldValue('assignValidUntil', newDate)
  }

  const handleChangeValidFrom = (date: Dayjs | null) => {
    setValidFrom(date)
    const newDate = (!isEmpty(date) && date.format('YYYY-MM-DD')) || ''
    formik.setFieldValue('validFrom', newDate)
  }

  const handleChangeValidUntil = (date: Dayjs | null) => {
    setValidUntil(date)
    const newDate = (!isEmpty(date) && date.format('YYYY-MM-DD')) || ''
    formik.setFieldValue('validUntil', newDate)
  }

  const masterAssignmentMethod = [
    { value: 'url', name: 'URL' },
    { value: 'immediately', name: 'Immediately' }
  ]

  const masterAssignmentMode = [
    { value: 'GenerateNewToken', name: 'GenerateNewToken' },
    { value: 'UseAvailableToken', name: 'UseAvailableToken' },
    { value: 'AssignCustomToken', name: 'AssignCustomToken' }
  ]

  return (
    <>
      <Dialog
        open={open}
        title='Add Gift Voucher'
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={create.isLoading}
        isDisabled={!formik.isValid}
        maxWidth='md'
      >
        <Container {...DialogStyle.Container}>
          <FormikProvider value={formik}>
            <Grid container spacing={2} columns={16}>
              <Grid xs={8} marginTop={1}>
                <Grid xs={16} margin={1}>
                  <TextField
                    id='voucherGiftName'
                    variant='outlined'
                    label='Gift Voucher Campaign Name'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched &&
                      formik.touched.voucherGiftName &&
                      Boolean(formik.errors.voucherGiftName)
                    }
                    helperText={
                      formik.touched &&
                      formik.touched.voucherGiftName &&
                      formik.errors &&
                      formik.errors.voucherGiftName
                    }
                    fullWidth
                  />
                </Grid>
                <Grid xs={16} margin={1}>
                  <Autocomplete
                    options={masterAssignmentMethod || []}
                    getOptionLabel={(list) => list.name}
                    isOptionEqualToValue={(option, value) =>
                      option && value ? option.value == value.value : false
                    }
                    onChange={(_, id) => formik.setFieldValue('assignmentMethode', id && id.value)}
                    ListboxProps={GlobalStyle.ListBox}
                    renderOption={(props, item) => (
                      <li {...props} key={item.name}>
                        {item.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name='assignmentMethode'
                        label='Assignment Method'
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: <>{params.InputProps.endAdornment}</>
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid
                  xs={16}
                  margin={1}
                  display={
                    formik.values.assignmentMethode.toLocaleLowerCase().includes('immediately')
                      ? 'none'
                      : ''
                  }
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                    <DatePicker
                      value={startDate}
                      onChange={handleChangeStartDate}
                      label='Assignment Valid From (for the URL)'
                      format='DD-MM-YYYY'
                      sx={{ width: '100%' }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid
                  xs={16}
                  margin={1}
                  display={
                    formik.values.assignmentMethode.toLocaleLowerCase().includes('immediately')
                      ? 'none'
                      : ''
                  }
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                    <DatePicker
                      value={endDate}
                      minDate={dayjs(startDate)}
                      onChange={handleChangeEndDate}
                      label='Assignment Valid Until (for the URL)'
                      format='DD-MM-YYYY'
                      sx={{ width: '100%' }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid xs={16} margin={1}>
                  <Autocomplete
                    options={voucherDropdown.data || []}
                    getOptionLabel={(list) => list.name}
                    isOptionEqualToValue={(option, value) =>
                      option && value ? option.id == value.id : false
                    }
                    onOpen={() => getVoucherListDropdown()}
                    onChange={(_, id) => formik.setFieldValue('voucherId', id && id.id)}
                    ListboxProps={GlobalStyle.ListBox}
                    renderOption={(props, item) => (
                      <li {...props} key={item.id}>
                        {item.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name='voucherId'
                        label='Voucher'
                        error={
                          formik.touched &&
                          formik.touched.voucherId &&
                          Boolean(formik.errors.voucherId)
                        }
                        helperText={
                          formik.touched &&
                          formik.touched.voucherId &&
                          formik.errors &&
                          formik.errors.voucherId
                        }
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {voucherDropdown.isLoading && (
                                <CircularProgress color='inherit' size={20} />
                              )}
                              {params.InputProps.endAdornment}
                            </>
                          )
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid xs={16} margin={1}>
                  <Autocomplete
                    options={masterAssignmentMode || []}
                    getOptionLabel={(list) => list.name}
                    isOptionEqualToValue={(option, value) =>
                      option && value ? option.value == value.value : false
                    }
                    onChange={(_, id) => formik.setFieldValue('assignmentMode', id && id.value)}
                    ListboxProps={GlobalStyle.ListBox}
                    renderOption={(props, item) => (
                      <li {...props} key={item.name}>
                        {item.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name='assignmentMode'
                        label='Assignment Mode'
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: <>{params.InputProps.endAdornment}</>
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  xs={16}
                  margin={1}
                  display={
                    ['GenerateNewToken', 'UseAvailableToken'].includes(formik.values.assignmentMode)
                      ? 'none'
                      : ''
                  }
                >
                  <TextField
                    id='token'
                    variant='outlined'
                    label='Token'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched && formik.touched.token && Boolean(formik.errors.token)}
                    helperText={
                      formik.touched && formik.touched.token && formik.errors && formik.errors.token
                    }
                    fullWidth
                  />
                </Grid>
                <Grid
                  xs={16}
                  margin={1}
                  display={
                    ['UseAvailableToken'].includes(formik.values.assignmentMode) ? 'none' : ''
                  }
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                    <DatePicker
                      value={validFrom}
                      onChange={handleChangeValidFrom}
                      label='Valid From'
                      format='DD-MM-YYYY'
                      sx={{ width: '100%' }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid
                  xs={16}
                  margin={1}
                  display={
                    ['UseAvailableToken'].includes(formik.values.assignmentMode) ? 'none' : ''
                  }
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                    <DatePicker
                      value={validUntil}
                      minDate={dayjs(validFrom)}
                      onChange={handleChangeValidUntil}
                      label='Valid Until'
                      format='DD-MM-YYYY'
                      sx={{ width: '100%' }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid xs={16} margin={1}>
                  <TextField
                    multiline
                    id='notes'
                    label='Notes'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched && formik.touched.notes && Boolean(formik.errors.notes)}
                    helperText={
                      formik.touched && formik.touched.notes && formik.errors && formik.errors.notes
                    }
                    fullWidth
                    rows={2}
                  />
                </Grid>
              </Grid>

              {/* Right Column */}
              <Grid xs={8} marginTop={1}>
                <Grid xs={16} margin={1}>
                  <Autocomplete
                    options={dropdownUser.data || []}
                    getOptionLabel={(list) => list.fullName}
                    isOptionEqualToValue={(option, value) =>
                      option && value ? option.userId == value.userId : false
                    }
                    onOpen={() => getUsernameDropdown()}
                    onChange={(_, id) => formik.setFieldValue('userId', id && id.userId)}
                    ListboxProps={GlobalStyle.ListBox}
                    renderOption={(props, item) => (
                      <li {...props} key={item.userId}>
                        {item.fullName}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name='userId'
                        label='User'
                        error={
                          formik.touched && formik.touched.userId && Boolean(formik.errors.userId)
                        }
                        helperText={
                          formik.touched &&
                          formik.touched.userId &&
                          formik.errors &&
                          formik.errors.userId
                        }
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {dropdownUser.isLoading && (
                                <CircularProgress color='inherit' size={20} />
                              )}
                              {params.InputProps.endAdornment}
                            </>
                          )
                        }}
                      />
                    )}
                  />
                </Grid>
                <Box sx={{ border: 0.5, margin: 1, borderColor: 'lightgray' }}>
                  <Grid xs={16} margin={1}>
                    <TextField
                      id='notiftitle'
                      variant='outlined'
                      label='Notification Title'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched &&
                        formik.touched.notiftitle &&
                        Boolean(formik.errors.notiftitle)
                      }
                      helperText={
                        formik.touched &&
                        formik.touched.notiftitle &&
                        formik.errors &&
                        formik.errors.notiftitle
                      }
                      fullWidth
                    />
                  </Grid>

                  <Grid xs={16} margin={1}>
                    <TextField
                      multiline
                      id='notifdata'
                      label='Notification Message Body'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched &&
                        formik.touched.notifdata &&
                        Boolean(formik.errors.notifdata)
                      }
                      helperText={
                        formik.touched &&
                        formik.touched.notifdata &&
                        formik.errors &&
                        formik.errors.notifdata
                      }
                      fullWidth
                      rows={4}
                    />
                  </Grid>
                  <Grid xs={16} margin={1}>
                    <Typography
                      sx={{
                        fontStyle: 'italic',
                        color: 'GrayText',
                        fontSize: 12,
                        flexWrap: 'nowrap'
                      }}
                    >
                      * Override default notification body. You can use these placeholders:
                      <br />
                      <span style={{ fontWeight: 'bold', color: 'black' }}>- {`{Fullname}`} </span>:
                      Recipient&#39;s Full Name
                      <br />
                      <span style={{ fontWeight: 'bold', color: 'black' }}>
                        - {`{VoucherTitle}`}{' '}
                      </span>
                      : The Voucher Name
                      <br />
                      <span style={{ fontWeight: 'bold', color: 'black' }}>
                        - {`{MerchantName}`}{' '}
                      </span>
                      : The Merchant Name
                      <br />
                      <span style={{ fontWeight: 'bold', color: 'black' }}>
                        - {`{tokenValidUntil}`}{' '}
                      </span>
                      : The Voucher Token expired on date &#40;in UTC timezone&#41;
                    </Typography>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </FormikProvider>
        </Container>
      </Dialog>

      <Notification
        open={!create.isLoading && !create.isUninitialized}
        onClose={() => (create.isError ? create.reset() : location.reload())} //
        isError={Boolean(create.error) && create.isError}
        message={GLOBAL.returnExceptionMessage(create.isError, create.error as ErrorProps)}
      />
    </>
  )
}

export default Create
