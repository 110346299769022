import type { ContainerProps, StackProps } from '@mui/material'

class AccessStyle {
  static Container: ContainerProps = {
    maxWidth: false,
    disableGutters: true,
    sx: {
      display: 'flex',
      padding: 2,
      gap: '1rem',
      flexDirection: 'column'
    }
  }

  static Basic: ContainerProps = {
    maxWidth: false,
    disableGutters: true,
    sx: {
      display: 'flex',
      gap: '1rem',
      flexDirection: 'column'
    }
  }

  static Stack: StackProps = {
    direction: 'column',
    spacing: 2
  }
  static Box: StackProps = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    bgcolor: '#002C5F',
    width: '170px',
    height: '140px',
    gap: 2,
    flex: 1,
    borderRadius: 5
  }
}

export default AccessStyle
