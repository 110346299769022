import type { LinkProps } from 'react-router-dom'
import type { ContainerProps, StackProps, TypographyProps } from '@mui/material'

class MenuStyle {
  static Container: ContainerProps = {
    maxWidth: false,
    disableGutters: true,
    sx: {
      display: 'flex',
      padding: 2,
      alignItems: 'center',
      overflowX: 'auto',
    }
  }

  static Wrapper: StackProps = {
    direction: 'row',
    spacing: 2
  }

  static Link: LinkProps = {
    to: '',
    style: {
      color: 'inherit',
      textDecoration: 'none'
    }
  }

  static Title: TypographyProps = {
    fontSize: 14,
    fontWeight: 500
  }
}

export default MenuStyle
