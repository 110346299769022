import type { ContainerProps } from '@mui/material'

class FilterStyle {
  static Container: ContainerProps = {
    maxWidth: false,
    disableGutters: true,
    sx: {
      display: 'flex',
      gap: '1rem',
      paddingY: 2,
      flexDirection: 'column'
    }
  }
}

export default FilterStyle
