import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TableCell,
  TableRow
} from '@mui/material'
import moment from 'moment'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import { useGetCoinListMutation } from 'store/coin'
import type { PartialFormDataProps, PartialCoinFilterProps } from 'modules/partial'
import CONSTANT from 'modules/constant'
import GLOBAL from 'modules/global'
import Loading from 'components/loading/loading'
import type { ErrorProps } from 'modules/types'
import Notification from 'components/notification'
import Create from './create/create'

const CoinSubmission = () => {
  const [getCoinList, listCoin] = useGetCoinListMutation()

  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [dialogCreate, setDialogCreate] = useState<boolean>(false)

  const defaultField = 'fullName'

  const [filter, setFilter] = useState<PartialCoinFilterProps>({
    status: '',
    minCreatedDate: '',
    maxCreatedDate: ''
  })

  const [payload, setPayload] = useState<
  PartialCoinFilterProps & PartialFormDataProps
  >({
    start: 0,
    length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    field: [defaultField, ...CONSTANT.DEFAULT_COIN_FIELD].join(','),
    search: { value: '' },
    status: '',
    minCreatedDate: '',
    maxCreatedDate: ''
  })

  const onCreate = () => setDialogCreate(true)

  const onSearch = (value: string) => setPayload({ ...payload, start: 0, search: { value } })

  const onResync = () => {
    const formData = new FormData()

    formData.append('start', payload.start.toString())
    formData.append('length', payload.length.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(payload.status) && payload.status) formData.append('status', payload.status)
    if (!isEmpty(payload.minCreatedDate) && payload.minCreatedDate)
      formData.append('minCreatedDate', payload.minCreatedDate)
    if (!isEmpty(payload.maxCreatedDate) && payload.maxCreatedDate)
      formData.append('maxCreatedDate', payload.maxCreatedDate)

    getCoinList(formData)
  }

  const onFilter = (state: boolean) => setDialogFilter(state)

  const onChangePage = (value: number) => setPayload({ ...payload, start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, length: value })

  const tableHeadTitles = ['Name', 'Member Code', 'Amount', 'Status', 'Created Date']

  const totalPage =
    listCoin.data && GLOBAL.tableTotalPage(listCoin.data.recordsFiltered)

  const numbers = GLOBAL.tableNumber(payload.start)

  useEffect(() => {
    onResync()
  }, [payload])

  return (
    <>
      <Content
        title='Coin Submission'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onFilter={() => onFilter(true)}
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={listCoin.data && listCoin.data.recordsTotal}
        page={payload.start + 1}
        isLoading={listCoin.isLoading}
        useAction={false}
        onAdditional={onCreate}
        additionalTitle='Create Coin Submission'
        additionalPrefixIcon='Create'
      >
        {listCoin.isSuccess &&
          listCoin.data &&
          listCoin.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>{table.fullName || '-'}</TableCell>
              <TableCell>{table.memberCode || '-'}</TableCell>
              <TableCell> {table.amount || '-'}</TableCell>
              <TableCell>{table.requestStatus || '-'}</TableCell>
              <TableCell>
                {table.createdAt ? moment(table.createdAt).format('DD-MM-YYYY HH:mm:ss') : '-'}
              </TableCell>
            </TableRow>
          ))}
      </Content>

      {listCoin.isLoading && <Loading />}

      <Create open={dialogCreate} onClose={() => setDialogCreate(false)} />

      <Filter
        open={dialogFilter}
        title='Filter'
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        onReset={() => {
          setFilter({
            status: '',
            minCreatedDate: '',
            maxCreatedDate: ''
          })
          setPayload({
            ...payload,
            start: 0,
            status: '',
            minCreatedDate: '',
            maxCreatedDate: ''
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({ ...payload, start: 0, ...filter })
          onFilter(false)
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
          <DatePicker
            value={filter.minCreatedDate ? dayjs(filter.minCreatedDate) : null}
            minDate={dayjs(filter.minCreatedDate)}
            onChange={(event: Dayjs | null) =>
              setFilter({ ...filter, minCreatedDate: (event && event.format('YYYY-MM-DD')) || '' })
            }
            label='Min Created Date'
            format='DD-MM-YYYY'
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
          <DatePicker
            value={filter.maxCreatedDate ? dayjs(filter.maxCreatedDate) : null}
            minDate={dayjs(filter.minCreatedDate)}
            onChange={(event: Dayjs | null) =>
              setFilter({ ...filter, maxCreatedDate: (event && event.format('YYYY-MM-DD')) || '' })
            }
            label='Max Created Date'
            format='DD-MM-YYYY'
          />
        </LocalizationProvider>
        <FormControl fullWidth>
          <InputLabel id='Status'>Status</InputLabel>
          <Select
            labelId='Status'
            id='Status'
            value={filter.status}
            label='Status'
            onChange={(event) => setFilter({ ...filter, status: event.target.value })}
          >
            <MenuItem value='Pending'>Pending</MenuItem>
            <MenuItem value='Processing'>Processing</MenuItem>
            <MenuItem value='Sent'>Sent</MenuItem>
            <MenuItem value='Error'>Error</MenuItem>
          </Select>
        </FormControl>
      </Filter>

      <Notification
        open={
          !listCoin.isLoading &&
          !listCoin.isUninitialized &&
          !listCoin.isSuccess
        }
        onClose={() => (listCoin.isError ? listCoin.reset() : location.reload())}
        isError={Boolean(listCoin.error) && listCoin.isError}
        message={GLOBAL.returnExceptionMessage(
          listCoin.isError,
          listCoin.error as ErrorProps
        )}
      />
    </>
  )
}

export default CoinSubmission
