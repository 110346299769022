import CONSTANT from 'modules/constant'
import type { EVPriceChargeBoxGroupProps, PaginationProps, ResultProps } from 'modules/types'
import type { PartialEVPriceChargeBoxGroupProps } from 'modules/partial'
import { API, headers } from './api'

const evPriceChargeBoxGroupAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getPriceChargeBoxGroupList: builder.mutation<
      PaginationProps<EVPriceChargeBoxGroupProps>,
      { id: string; body: FormData }
    >({
      query: (query) => ({
        url: CONSTANT.URL_EV_PRICE_CHARGE_BOX_GROUP_LIST + query.id + '/chargerboxgroup/data',
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: query.body,
        formData: true
      })
    }),
    createPriceChargeBoxGroupList: builder.mutation<ResultProps, PartialEVPriceChargeBoxGroupProps>(
      {
        query: (query) => ({
          url: CONSTANT.URL_EV_PRICE_CHARGE_BOX_GROUP_UPDATE + query.id + '/chargerboxgroup',
          method: 'POST',
          headers: headers(),
          body: query
        })
      }
    ),
    updatePriceChargeBoxGroupList: builder.mutation<
      ResultProps,
      { id: string; body: PartialEVPriceChargeBoxGroupProps }
    >({
      query: (query) => ({
        url: CONSTANT.URL_EV_PRICE_CHARGE_BOX_GROUP_UPDATE + query.id + '/chargerboxgroup',
        method: 'PUT',
        headers: headers(),
        body: query.body
      })
    }),
    deletePriceChargeBoxGroupList: builder.mutation<
      ResultProps,
      { id: string; body: PartialEVPriceChargeBoxGroupProps }
    >({
      query: (query) => ({
        url: CONSTANT.URL_EV_PRICE_CHARGE_BOX_GROUP_DELETE + query.id + '/chargerboxgroup',
        method: 'DELETE',
        headers: headers(),
        body: query.body
      })
    })
  })
})

export const {
  useGetPriceChargeBoxGroupListMutation,
  useCreatePriceChargeBoxGroupListMutation,
  useUpdatePriceChargeBoxGroupListMutation,
  useDeletePriceChargeBoxGroupListMutation
} = evPriceChargeBoxGroupAPI
