import CONSTANT from 'modules/constant'
import type { CoinProps, MemberCoinProps, PaginationProps } from 'modules/types'
import type { PartialNewSubmissionCoinProps } from 'modules/partial'
import { API, headers } from './api'

const CoinAPI = API.injectEndpoints({
  endpoints: (builder) => ({
    getCoinList: builder.mutation<
      PaginationProps<CoinProps>,
      FormData
    >({
      query: (body) => ({
        url: CONSTANT.URL_COIN_LIST,
        method: 'POST',
        headers: headers({ useContentType: false }),
        body: body,
        formData: true
      })
    }),
    getListUserByMemberCode: builder.mutation<MemberCoinProps[], string[]>({
      query: (body) => ({
        url: CONSTANT.URL_COIN_MEMBER,
        method: 'POST',
        headers: headers(),
        body: {MemberCode: body}
      })
    }),
    submitCoinSubmission: builder.mutation<MemberCoinProps[], PartialNewSubmissionCoinProps>({
      query: (body) => ({
        url: CONSTANT.URL_COIN_SUBMISSION,
        method: 'POST',
        headers: headers(),
        body: body
      })
    })
  })
})

export const { useGetCoinListMutation, useGetListUserByMemberCodeMutation, useSubmitCoinSubmissionMutation } =
  CoinAPI
