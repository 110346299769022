/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Autocomplete,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import type { Dayjs } from 'dayjs'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import CONSTANT from 'modules/constant'
import type { PartialVoucherCategoryProps } from 'modules/partial'
import Icon from 'components/icon'
import MenuModalItem from 'components/menuItem/menuItem'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { ErrorProps } from 'modules/types'
import GlobalStyle from 'modules/styles'
import {
  useDeleteVoucherBenefitsMutation,
  useDownloadVoucherBenefitsMutation,
  useEnableVoucherBenefitsMutation,
  useGetBenefitsCategoryDropdownMutation,
  useGetListVoucherBenefitsMutation,
  useGetPartnerFilterDropdownMutation,
  useGetVoucherFilterDropdownMutation,
  useGetVoucherMemberDropdownMutation
} from 'store/voucherBenefits'
import Create from './create/create'
import Detail from './detail/detail'
import Edit from './edit/edit'

const VoucherBenefits = () => {
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [dialogCreate, setDialogCreate] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [dialogUpdate, setDialogUpdate] = useState<boolean>(false)
  const [menu, setMenu] = useState<null | number>(null)
  const [getListVoucherBenefits, voucherBenefits] = useGetListVoucherBenefitsMutation()
  const [downloadVoucherBenefits, download] = useDownloadVoucherBenefitsMutation()
  const [deleteVoucherBenefits, deleteBenefits] = useDeleteVoucherBenefitsMutation()
  const [enableVoucherBenefits, enableBenefits] = useEnableVoucherBenefitsMutation()
  const [getVoucherMemberDropdown, voucherMemberId] = useGetVoucherMemberDropdownMutation()
  const [getBenefitsCategoryDropdown, benefitsCategory] = useGetBenefitsCategoryDropdownMutation()
  const [getPartnerFilterDropdown, partnerFilter] = useGetPartnerFilterDropdownMutation()
  const [getVoucherFilterDropdown, voucherFilter] = useGetVoucherFilterDropdownMutation()
  const [startDate, setStartDate] = useState<Dayjs | null>(null)
  const [endDate, setEndDate] = useState<Dayjs | null>(null)

  const [payload, setPayload] = useState<PartialVoucherCategoryProps>({
    start: 0,
    length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    field: CONSTANT.DEFAULT_VOUCHER_BENEFITS_FIELD.join(','),
    search: { value: '' },
    status: ''
  })

  const [filter, setFilter] = useState({
    MinStartTime: '',
    MaxStartTime: '',
    UserType: '',
    benefitCategoryid: '',
    status: '',
    MinCoins: '',
    MaxCoins: '',
    MinUsage: '',
    MaxUsage: '',
    VoucherId: '',
    PartnerId: ''
  })

  const onSearch = (value: string) => setPayload({ ...payload, start: 0, search: { value } })
  const onResync = () => {
    const formData = new FormData()
    formData.append('start', payload.start.toString())
    formData.append('length', payload.length.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)

    if (!isEmpty(filter.MinStartTime)) formData.append('min-valid-from', filter.MinStartTime)
    if (!isEmpty(filter.MaxStartTime)) formData.append('max-valid-from', filter.MaxStartTime)
    if (!isEmpty(filter.status)) formData.append('status', filter.status)
    if (!isEmpty(filter.VoucherId)) formData.append('voucher-id', filter.VoucherId)
    if (!isEmpty(filter.MinCoins)) formData.append('min-coins-amount', filter.MinCoins)
    if (!isEmpty(filter.MaxCoins)) formData.append('max-coins-amount', filter.MaxCoins)
    if (!isEmpty(filter.PartnerId)) formData.append('partner-id', filter.PartnerId)
    if (!isEmpty(filter.UserType)) formData.append('user-type', filter.UserType)
    if (!isEmpty(filter.MinUsage)) formData.append('min-usage-limit', filter.MinUsage)
    if (!isEmpty(filter.MaxUsage)) formData.append('max-usage-limit', filter.MaxUsage)
    if (!isEmpty(filter.benefitCategoryid))
      formData.append('benefit-category-id', filter.benefitCategoryid)

    getListVoucherBenefits(formData)
  }
  const onFilter = (state: boolean) => setDialogFilter(state)
  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('start', String(0))
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(filter.MinStartTime)) formData.append('min-valid-from', filter.MinStartTime)
    if (!isEmpty(filter.MaxStartTime)) formData.append('max-valid-from', filter.MaxStartTime)
    if (!isEmpty(filter.status)) formData.append('status', filter.status)
    if (!isEmpty(filter.VoucherId)) formData.append('voucher-id', filter.VoucherId)
    if (!isEmpty(filter.MinCoins)) formData.append('min-coins-amount', filter.MinCoins)
    if (!isEmpty(filter.MaxCoins)) formData.append('max-coins-amount', filter.MaxCoins)
    if (!isEmpty(filter.PartnerId)) formData.append('partner-id', filter.PartnerId)
    if (!isEmpty(filter.UserType)) formData.append('user-type', filter.UserType)
    if (!isEmpty(filter.MinUsage)) formData.append('min-usage-limit', filter.MinUsage)
    if (!isEmpty(filter.MaxUsage)) formData.append('max-usage-limit', filter.MaxUsage)
    if (!isEmpty(filter.benefitCategoryid))
      formData.append('benefit-category-id', filter.benefitCategoryid)
    if (type && !isEmpty(type)) formData.append('Download-Type', type)

    downloadVoucherBenefits(formData)
  }
  const onCreate = () => setDialogCreate(true)

  const onChangePage = (value: number) => setPayload({ ...payload, start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, length: value })

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const tableHeadTitles = [
    'Campaign Name',
    'Voucher Name',
    'Benefits Category',
    'Coins',
    'Member Tier',
    'Partner',
    'Valid From',
    'Valid Until',
    'Usage Limit',
    'Status'
  ]

  const openAnchor = Boolean(anchor)

  const totalPage = voucherBenefits.data && GLOBAL.tableTotalPage(voucherBenefits.data.recordsTotal)

  const numbers = GLOBAL.tableNumber(payload.start)

  useEffect(() => {
    onResync()
  }, [payload])

  const handleChangeStartDate = (date: Dayjs | null) => {
    setStartDate(date)
    const newDate = (!isEmpty(date) && date?.format('YYYY-MM-DD')) || ''
    setFilter({ ...filter, MinStartTime: newDate, MaxStartTime: '' })
    setEndDate(null)
  }

  const handleChangeEndDate = (date: Dayjs | null) => {
    setEndDate(date)
    const newDate = (!isEmpty(date) && date?.format('YYYY-MM-DD')) || ''
    setFilter({ ...filter, MaxStartTime: newDate })
  }

  const dataBoolean = [
    { value: 'Enabled', name: 'Enabled' },
    { value: 'Disabled', name: 'Disabled' }
  ]

  return (
    <>
      <Content
        title='Voucher Benefits'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        onAdditional={onCreate}
        additionalTitle='Create New Voucher Benefits'
        additionalPrefixIcon='Add'
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={voucherBenefits.data && voucherBenefits.data.recordsTotal}
        page={payload.start + 1}
        isLoading={voucherBenefits.isLoading}
        useDownloadDropdown
      >
        {voucherBenefits.isSuccess &&
          voucherBenefits.data &&
          voucherBenefits.data.data.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${item.voucherCampaignId}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${item.voucherCampaignId}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${item.voucherCampaignId}`}
                  aria-labelledby={`button-${index}-${item.voucherCampaignId}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View Detail
                  </MenuItem>
                  <MenuItem onClick={() => onRemoveAnchor(() => setDialogUpdate(true))}>
                    Edit
                  </MenuItem>
                  <MenuModalItem
                    title={item.status == 'Enabled' ? 'Disable' : 'Enable'}
                    onClick={() =>
                      onRemoveAnchor(() =>
                        enableVoucherBenefits({
                          Id: item.voucherCampaignId,
                          Status: item.status == 'Enabled' ? 'Disabled' : 'Enabled'
                        })
                      )
                    }
                  >
                    {item.status == 'Enabled' ? 'Disable' : 'Enable'}
                  </MenuModalItem>
                  <MenuModalItem
                    title='Delete'
                    loading={deleteBenefits.isLoading}
                    onClick={() =>
                      onRemoveAnchor(() => deleteVoucherBenefits({ Id: item.voucherCampaignId }))
                    }
                  >
                    <Typography {...GlobalStyle.ErrorTypography}>Delete</Typography>
                  </MenuModalItem>
                </Menu>
                {drawerDetail && menu == index && (
                  <Detail
                    id={item.voucherCampaignId}
                    open={drawerDetail && menu == index}
                    onClose={() => setDrawerDetail(false)}
                  />
                )}
                {dialogUpdate && menu == index && (
                  <Edit
                    id={item.voucherCampaignId}
                    open={dialogUpdate && menu == index}
                    onClose={() => setDialogUpdate(false)}
                  />
                )}
              </TableCell>
              <TableCell>{item.voucherCampaignName || '-'}</TableCell>
              <TableCell>{item.voucherName || '-'}</TableCell>
              <TableCell>{item.benefitCategoryName || '-'}</TableCell>
              <TableCell>{item.coins.toLocaleString('id') || '-'}</TableCell>
              <TableCell>{item.memberTier || '-'}</TableCell>
              <TableCell>{item.partner || '-'}</TableCell>
              <TableCell>{dayjs(item.validFrom).format('DD MMMM YYYY HH:mm') || '-'}</TableCell>
              <TableCell>{dayjs(item.validUntil).format('DD MMMM YYYY HH:mm') || '-'}</TableCell>
              <TableCell>{item.usageLimit || '-'}</TableCell>
              <TableCell>
                <Chip label={item.status} color={item.status == 'Enabled' ? 'success' : 'error'} />
              </TableCell>
            </TableRow>
          ))}
      </Content>
      {voucherBenefits.isLoading && <Loading />}

      <Filter
        open={dialogFilter}
        title='Filter Voucher Benefits'
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
        onReset={() => {
          setFilter({
            MinStartTime: '',
            MaxStartTime: '',
            UserType: '',
            benefitCategoryid: '',
            status: '',
            MinCoins: '',
            MaxCoins: '',
            MinUsage: '',
            MaxUsage: '',
            VoucherId: '',
            PartnerId: ''
          })
          setStartDate(null)
          setEndDate(null)
          setPayload({ ...payload, start: 0, ...filter })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({ ...payload, start: 0, ...filter })
          onFilter(false)
        }}
      >
        <Grid container spacing={2} columns={16}>
          <Grid xs={8} marginTop={1}>
            <Grid xs={16} margin={1}>
              <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                <DatePicker
                  value={startDate}
                  onChange={handleChangeStartDate}
                  label='Min Valid From'
                  format='DD-MM-YYYY'
                  sx={{ width: '100%' }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid xs={16} margin={1}>
              <TextField
                id='MinCoins'
                variant='outlined'
                label='Min Coins Amount'
                type='number'
                value={filter.MinCoins}
                onChange={(event) => setFilter({ ...filter, MinCoins: event.target.value })}
                fullWidth
              />
            </Grid>
            <Grid xs={16} margin={1}>
              <TextField
                id='MinUsage'
                variant='outlined'
                label='Min Usage Limit'
                type='number'
                value={filter.MinUsage}
                onChange={(event) => setFilter({ ...filter, MinUsage: event.target.value })}
                fullWidth
              />
            </Grid>
            <Grid xs={16} margin={1}>
              <Autocomplete
                options={voucherMemberId.data || []}
                getOptionLabel={(list) => list.name}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.id == value.id : false
                }
                onOpen={() => getVoucherMemberDropdown()}
                onChange={(_, data) => {
                  if (data) {
                    setFilter({
                      ...filter,
                      UserType: data && data?.name
                    })
                  }
                }}
                value={
                  voucherMemberId && voucherMemberId.data?.find((e) => e.name == filter.UserType)
                }
                ListboxProps={GlobalStyle.ListBox}
                renderOption={(props, item) => (
                  <li {...props} key={item.id}>
                    {item.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='UserType'
                    label='User Type'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {voucherMemberId.isLoading && (
                            <CircularProgress color='inherit' size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
              />
            </Grid>
            <Grid xs={16} margin={1}>
              <Autocomplete
                options={benefitsCategory.data || []}
                getOptionLabel={(list) => list.name}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.id == value.id : false
                }
                onOpen={() => getBenefitsCategoryDropdown()}
                onChange={(_, id) => {
                  if (id) {
                    setFilter({
                      ...filter,
                      benefitCategoryid: id && id?.id.toString()
                    })
                  }
                }}
                ListboxProps={GlobalStyle.ListBox}
                value={
                  benefitsCategory &&
                  benefitsCategory.data?.find((e) => e.id == Number(filter.benefitCategoryid))
                }
                renderOption={(props, item) => (
                  <li {...props} key={item.id}>
                    {item.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='benefitCategoryid'
                    label='Benefits Category'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: <>{params.InputProps.endAdornment}</>
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          {/* =========== Right Coloumn ============= */}
          <Grid xs={8} marginTop={1}>
            <Grid xs={16} margin={1}>
              <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                <DatePicker
                  value={endDate}
                  minDate={dayjs(startDate)}
                  onChange={handleChangeEndDate}
                  label='Max Valid From'
                  format='DD-MM-YYYY'
                  sx={{ width: '100%' }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid xs={16} margin={1}>
              <TextField
                id='MaxCoins'
                variant='outlined'
                label='Max Coins Amount'
                type='number'
                value={filter.MaxCoins}
                onChange={(event) => setFilter({ ...filter, MaxCoins: event.target.value })}
                fullWidth
              />
            </Grid>

            <Grid xs={16} margin={1}>
              <TextField
                id='MaxUsage'
                variant='outlined'
                label='Max Usage Limit'
                type='number'
                value={filter.MaxUsage}
                onChange={(event) => setFilter({ ...filter, MaxUsage: event.target.value })}
                fullWidth
              />
            </Grid>

            <Grid xs={16} margin={1}>
              <Autocomplete
                options={voucherFilter.data || []}
                getOptionLabel={(list) => list.name}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.id == value.id : false
                }
                onOpen={() => getVoucherFilterDropdown()}
                onChange={(_, id) => {
                  if (id) {
                    setFilter({
                      ...filter,
                      VoucherId: id && id?.id.toString()
                    })
                  }
                }}
                ListboxProps={GlobalStyle.ListBox}
                value={
                  voucherFilter &&
                  voucherFilter?.data?.find((e) => e.id == Number(filter.VoucherId))
                }
                renderOption={(props, item) => (
                  <li {...props} key={item.id}>
                    {item.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='VoucherId'
                    label='Voucher Name'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: <>{params.InputProps.endAdornment}</>
                    }}
                  />
                )}
              />
            </Grid>
            <Grid xs={16} margin={1}>
              <Autocomplete
                options={partnerFilter.data || []}
                getOptionLabel={(list) => list.name}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.id == value.id : false
                }
                onOpen={() => getPartnerFilterDropdown()}
                onChange={(_, id) => {
                  if (id) {
                    setFilter({
                      ...filter,
                      PartnerId: id && id?.id.toString()
                    })
                  }
                }}
                ListboxProps={GlobalStyle.ListBox}
                value={
                  partnerFilter &&
                  partnerFilter?.data?.find((e) => e.id == Number(filter.PartnerId))
                }
                renderOption={(props, item) => (
                  <li {...props} key={item.id}>
                    {item.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='PartnerId'
                    label='Partner'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: <>{params.InputProps.endAdornment}</>
                    }}
                  />
                )}
              />
            </Grid>
            <Grid xs={16} margin={1}>
              <Autocomplete
                options={dataBoolean || []}
                getOptionLabel={(list) => list.name}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.value == value.value : false
                }
                onChange={(_, id) => {
                  if (id) {
                    setFilter({
                      ...filter,
                      status: id && id?.value
                    })
                  }
                }}
                ListboxProps={GlobalStyle.ListBox}
                value={dataBoolean && dataBoolean.find((e) => e.value == filter.status)}
                renderOption={(props, item) => (
                  <li {...props} key={item.value}>
                    {item.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='status'
                    label='Status'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: <>{params.InputProps.endAdornment}</>
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Filter>
      {dialogCreate && <Create open={dialogCreate} onClose={() => setDialogCreate(false)} />}

      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />

      <Notification
        open={!deleteBenefits.isLoading && !deleteBenefits.isUninitialized}
        onClose={() => (deleteBenefits.isError ? deleteBenefits.reset() : location.reload())}
        isError={Boolean(deleteBenefits.error) && deleteBenefits.isError}
        message={GLOBAL.returnExceptionMessage(
          deleteBenefits.isError,
          deleteBenefits.error as ErrorProps
        )}
      />
      <Notification
        open={!enableBenefits.isLoading && !enableBenefits.isUninitialized}
        onClose={() => (enableBenefits.isError ? enableBenefits.reset() : location.reload())}
        isError={Boolean(enableBenefits.error) && enableBenefits.isError}
        message={GLOBAL.returnExceptionMessage(
          enableBenefits.isError,
          enableBenefits.error as ErrorProps
        )}
      />
    </>
  )
}

export default VoucherBenefits
