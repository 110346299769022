import type { BoxProps, ContainerProps, FilledInputProps, TypographyProps } from '@mui/material'

class HeaderStyle {
  static Container: ContainerProps = {
    maxWidth: false,
    disableGutters: true,
    sx: {
      position: 'fixed',
      display: 'flex',
      top: 0,
      height: '5rem',
      padding: 2,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: 'divider',
      backgroundColor: 'white',
      zIndex: 1201,
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  }

  static Link = {
    height: '100%'
  }

  static Logo: React.HTMLAttributes<HTMLImageElement> = {
    draggable: false,
    style: {
      height: '100%'
    }
  }

  static Search: FilledInputProps = {
    hiddenLabel: true,
    sx: {
      width: '100%',
      maxWidth: '30%'
    }
  }

  static User: BoxProps = {
    position: 'relative',
    display: 'flex',
    gap: 2,
    alignItems: 'center',
    justifyContent: 'flex-end'
  }

  static Avatar: BoxProps = {
    display: 'flex',
    alignItems: 'center'
  }

  static profile: BoxProps = {
    display: 'flex',
    marginLeft: 1,
    flexDirection: 'column'
  }

  static Name: TypographyProps = {
    fontWeight: 500,
    fontSize: 14
  }

  static Role: TypographyProps = {
    fontSize: 14,
    color: 'grey.500'
  }
}

export default HeaderStyle
