import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import {
  Autocomplete,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import {
  useDeletePriceChargeBoxGroupListMutation,
  useGetPriceChargeBoxGroupListMutation
} from 'store/evPriceChargeBoxGroup'
import type {
  PartialEVPriceChargeBoxGroupFilterProps,
  PartialFormDataCapsProps
} from 'modules/partial'
import CONSTANT from 'modules/constant'
import type { ErrorProps, ParamProps } from 'modules/types'
import GLOBAL from 'modules/global'
import Icon from 'components/icon'
import Loading from 'components/loading/loading'
import { useGetChargePointDropdownListMutation } from 'store/evChargePoint'
import GlobalStyle from 'modules/styles'
import MenuModalItem from 'components/menuItem/menuItem'
import Notification from 'components/notification'
import Detail from './detail/detail'
import Create from './create/create'
import Edit from './edit/edit'

const EVPriceChargerBoxGroup = () => {
  const params = useParams<ParamProps>()
  const id = params.id || ''

  const [getPriceChargeBoxGroupList, priceChargeBoxGroupList] =
    useGetPriceChargeBoxGroupListMutation()
  const [deletePriceChargeBoxGroupList, deletePriceChargeBoxGroup] =
    useDeletePriceChargeBoxGroupListMutation()
  const [getChargePointDropdownList, chargePointDropdown] = useGetChargePointDropdownListMutation()

  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [menu, setMenu] = useState<null | number>(null)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [dialogCreate, setDialogCreate] = useState<boolean>(false)
  const [dialogUpdate, setDialogUpdate] = useState<boolean>(false)

  const [filter, setFilter] = useState<PartialEVPriceChargeBoxGroupFilterProps>({
    ChargePointId: ''
  })

  const [payload, setPayload] = useState<
    PartialFormDataCapsProps & PartialEVPriceChargeBoxGroupFilterProps
  >({
    Start: 0,
    Length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    Search: { value: '' },
    ChargePointId: ''
  })

  const onSearch = (value: string) => setPayload({ ...payload, Start: 0, Search: { value } })
  const onChangePage = (value: number) => setPayload({ ...payload, Start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, Length: value })

  const onFilter = (state: boolean) => setDialogFilter(state)
  const onCreate = () => setDialogCreate(true)

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const onResync = () => {
    const formData = new FormData()

    formData.append('Start', payload.Start.toString())
    formData.append('Length', payload.Length.toString())

    if (!isEmpty(payload.Search) && !isEmpty(payload.Search.value))
      formData.append('Search', payload.Search.value)
    if (!isEmpty(payload.ChargePointId)) formData.append('ChargePointId', payload.ChargePointId)

    getPriceChargeBoxGroupList({ id: GLOBAL.decodeUrl(id), body: formData })
  }

  const tableHeadTitles = ['Charge Box Group ID', 'EV Charge Point Name', 'EV Charge Point ID']

  const openAnchor = Boolean(anchor)
  const totalPage =
    priceChargeBoxGroupList.data && GLOBAL.tableTotalPage(priceChargeBoxGroupList.data.recordsTotal)
  const numbers = GLOBAL.tableNumber(payload.Start)

  useEffect(() => {
    onResync()
  }, [payload])

  return (
    <>
      <Content
        title='Charge Box Group'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onFilter={() => onFilter(true)}
        onAdditional={onCreate}
        additionalTitle='Assign New Charge Box Group'
        additionalPrefixIcon='Create'
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={priceChargeBoxGroupList.data && priceChargeBoxGroupList.data.recordsTotal}
        page={payload.Start + 1}
        isLoading={priceChargeBoxGroupList.isLoading}
        useBackButton
      >
        {priceChargeBoxGroupList.isSuccess &&
          priceChargeBoxGroupList.data &&
          priceChargeBoxGroupList.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.chargerBoxGroupCode}`}
                  aria-haspopup='true'
                  aria-controls={
                    openAnchor ? `menu-${index}-${table.chargerBoxGroupCode}` : undefined
                  }
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.chargerBoxGroupCode}`}
                  aria-labelledby={`button-${index}-${table.chargerBoxGroupCode}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View
                  </MenuItem>
                  <MenuItem onClick={() => onRemoveAnchor(() => setDialogUpdate(true))}>
                    Edit
                  </MenuItem>
                  <MenuModalItem
                    title='Delete'
                    loading={deletePriceChargeBoxGroup.isLoading}
                    onClick={() =>
                      onRemoveAnchor(() =>
                        deletePriceChargeBoxGroupList({
                          id,
                          body: {
                            id,
                            chargePointId: table.chargePointId,
                            chargeBoxGroupIdentity: table.chargeBoxIdentity
                          }
                        })
                      )
                    }
                  >
                    <Typography {...GlobalStyle.ErrorTypography}>Delete</Typography>
                  </MenuModalItem>
                </Menu>
                <Detail
                  open={drawerDetail && menu == index}
                  id={table.chargerBoxGroupId}
                  onClose={() => setDrawerDetail(false)}
                />
                <Edit
                  open={dialogUpdate && menu == index}
                  priceId={String(id)}
                  id={String(table.chargerBoxGroupId)}
                  onClose={() => setDialogUpdate(false)}
                />
              </TableCell>
              <TableCell>{table.chargerBoxGroupCode || '-'}</TableCell>
              <TableCell>{table.chargePointName || '-'}</TableCell>
              <TableCell>{table.chargePointId || '-'}</TableCell>
            </TableRow>
          ))}
      </Content>

      {priceChargeBoxGroupList.isLoading && <Loading />}

      <Create open={dialogCreate} id={id} onClose={() => setDialogCreate(false)} />

      <Filter
        open={dialogFilter}
        title='Filter'
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
        onReset={() => {
          setFilter({
            ChargePointId: ''
          })
          setPayload({
            ...payload,
            Start: 0,
            ChargePointId: ''
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({
            ...payload,
            ...filter,
            Start: 0
          })
          onFilter(false)
        }}
      >
        <Autocomplete
          options={(chargePointDropdown && chargePointDropdown.data) || []}
          getOptionLabel={(option) => option.text}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.value == value.value : false
          }
          onOpen={() => getChargePointDropdownList(CONSTANT.DEFAULT_DROPDOWN_PAYLOAD)}
          onChange={(_, chargePoint) =>
            chargePoint &&
            setFilter({
              ...filter,
              ChargePointId: String(chargePoint.value)
            })
          }
          value={
            (chargePointDropdown &&
              chargePointDropdown.data &&
              chargePointDropdown.data.find((e) => String(e.value) == filter.ChargePointId)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.value}>
              {item.text}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='ChargePointId'
              label='EV Charge Point'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {chargePointDropdown.isLoading && (
                      <CircularProgress color='inherit' size={20} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
      </Filter>

      <Notification
        open={
          !priceChargeBoxGroupList.isLoading &&
          !priceChargeBoxGroupList.isUninitialized &&
          !priceChargeBoxGroupList.isSuccess
        }
        onClose={() =>
          priceChargeBoxGroupList.isError ? priceChargeBoxGroupList.reset() : location.reload()
        }
        isError={Boolean(priceChargeBoxGroupList.error) && priceChargeBoxGroupList.isError}
        message={GLOBAL.returnExceptionMessage(
          priceChargeBoxGroupList.isError,
          priceChargeBoxGroupList.error as ErrorProps
        )}
      />

      <Notification
        open={!deletePriceChargeBoxGroup.isLoading && !deletePriceChargeBoxGroup.isUninitialized}
        onClose={() =>
          deletePriceChargeBoxGroup.isError ? deletePriceChargeBoxGroup.reset() : location.reload()
        }
        isError={Boolean(deletePriceChargeBoxGroup.error) && deletePriceChargeBoxGroup.isError}
        message={GLOBAL.returnExceptionMessage(
          deletePriceChargeBoxGroup.isError,
          deletePriceChargeBoxGroup.error as ErrorProps
        )}
      />
    </>
  )
}

export default EVPriceChargerBoxGroup
