import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  TableCell,
  TableRow,
  TextField
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import dayjs from 'dayjs'
import Content from 'components/content/content'
import type {
  PartialEVChargeProps,
  PartialEVHistoryPartnerRequestProps
  // PartialEVHistoryPartnerRequestStartProps
} from 'modules/partial'
import { useGetChargePointDropdownListMutation } from 'store/evChargePoint'
import CONSTANT from 'modules/constant'
import {
  useAuthEvHistoryPartnerRequestMutation,
  useGetEvHistoryPartnerRequestListMutation,
  useSetKwhLimitRequestMutation,
  // useStartEvHistoryPartnerChargingMutation,
  useStopEvHistoryPartnerChargingMutation
} from 'store/evHistoryPartner'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { ErrorProps } from 'modules/types'
import ModalConfirm from 'components/modalConfirm/modalConfirm'
import GlobalStyle from 'modules/styles'
import Loading from 'components/loading/loading'
import PageStyle from './style'

const EVRequest = () => {
  const [setKWhLimitRequest, kwhLimitRequest] = useSetKwhLimitRequestMutation()

  const [getChargePointDropdownList, chargePointDropdown] = useGetChargePointDropdownListMutation()
  const [getEvHistoryPartnerRequest, partnerRequest] = useGetEvHistoryPartnerRequestListMutation()
  const [authEvHistoryPartnerRequest, authPartnerRequest] = useAuthEvHistoryPartnerRequestMutation()

  // const [startEvHistoryPartnerCharging, startPartnerCharging] =
  //   useStartEvHistoryPartnerChargingMutation()

  const [stopEvHistoryPartnerCharging, stopPartnerCharging] =
    useStopEvHistoryPartnerChargingMutation()

  const [submitKWhDialog, setSubmitKWhDialog] = useState<boolean>(false)
  const [submitAuthorize, setSubmitAuthorize] = useState<boolean>(false)
  const [submitReject, setSubmitReject] = useState<boolean>(false)
  const [submitStartCharging, setSubmitStartCharging] = useState<boolean>(false)
  const [submitStopCharging, setSubmitStopCharging] = useState<boolean>(false)

  const [transactionNo, setTransactionNo] = useState<string>('')

  // const [evData, setEvData] = useState<PartialEVHistoryPartnerRequestStartProps>({
  //   chargeBoxId: '',
  //   vehicleId: 0,
  //   policyId: 0
  // })

  const [kWhLimit, setKWhLimit] = useState<PartialEVHistoryPartnerRequestProps>({
    chargePointId: 0,
    kwhLimit: null
  })

  const [payload, setPayload] = useState<PartialEVChargeProps>({
    start: 0,
    length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    search: '',
    filter: '',
    sorting: ''
  })

  const onResync = () => {
    const formData = new FormData()

    formData.append('start', payload.start.toString())
    formData.append('length', payload.length.toString())

    if (!isEmpty(payload.search)) formData.append('search', payload.search)
    if (!isEmpty(payload.filter)) formData.append('filter', payload.filter)
    if (!isEmpty(payload.sorting)) formData.append('sorting', payload.sorting)

    getEvHistoryPartnerRequest(formData)
  }

  const onChangePage = (value: number) => setPayload({ ...payload, start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, length: value })

  const onApply = () => setSubmitKWhDialog(true)
  const onSubmit = () => setKWhLimitRequest(kWhLimit)

  const tableHeadTitles = [
    'Charge Point Name',
    'EV Car',
    'Charge Box Group ID',
    'Charge Box Code',
    'Charge Box Status',
    'Request Time',
    'Start Time',
    'Transaction (Time Elapsed)',
    'Transaction (kWh Elapsed)',
    'Authorization',
    'Start/Stop Charging'
  ]

  const totalPage = partnerRequest.data && GLOBAL.tableTotalPage(partnerRequest.data.recordsTotal)

  const numbers = GLOBAL.tableNumber(payload.start)

  useEffect(() => {
    onResync()
  }, [payload])

  return (
    <>
      <Content title='Request' useTable={false}>
        <Box {...PageStyle.Container}>
          <Box {...PageStyle.Box}>
            <Autocomplete
              options={(chargePointDropdown && chargePointDropdown.data) || []}
              getOptionLabel={(option) => option.text}
              isOptionEqualToValue={(option, value) =>
                option && value ? option.value == value.value : false
              }
              onOpen={() =>
                getChargePointDropdownList({
                  ...CONSTANT.DEFAULT_DROPDOWN_PAYLOAD,
                  IsPartner: 'true'
                })
              }
              onChange={(_, chargePoint) =>
                setKWhLimit({
                  ...kWhLimit,
                  chargePointId: (chargePoint && chargePoint.value) || 0
                })
              }
              value={
                (chargePointDropdown &&
                  chargePointDropdown.data &&
                  chargePointDropdown.data.find((e) => e.value == kWhLimit.chargePointId)) ||
                undefined
              }
              ListboxProps={GlobalStyle.ListBox}
              renderOption={(props, item) => (
                <li {...props} key={item.value}>
                  {item.text}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='chargePointId'
                  label='Choose Charge Point'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {chargePointDropdown.isLoading && (
                          <CircularProgress color='inherit' size={20} />
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
            />
            <Box {...PageStyle.Container}>
              <TextField
                id='kwhLimit'
                variant='outlined'
                label='Set kWh Limit'
                type='number'
                value={kWhLimit.kwhLimit && kWhLimit.kwhLimit.toString()}
                onKeyDown={(event) => {
                  if (/^[.,\-\b]+$/.test(event.key)) event.preventDefault()
                }}
                onChange={(event) => {
                  if (event.target.value === '' || /^[0-9\b]+$/.test(event.target.value)) {
                    const result = event.target.value ? Number(event.target.value) : null

                    setKWhLimit({
                      ...kWhLimit,
                      kwhLimit: result
                    })
                  }
                }}
                fullWidth
              />
              <LoadingButton
                loading={false}
                disabled={
                  kWhLimit.chargePointId <= 0 ||
                  (kWhLimit && kWhLimit.kwhLimit !== null && kWhLimit.kwhLimit <= 0)
                }
                {...PageStyle.Button}
                onClick={onApply}
              >
                Apply
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Content>

      <Content
        title=''
        tableHeadTitles={tableHeadTitles}
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={partnerRequest.data && partnerRequest.data.recordsTotal}
        page={payload.start + 1}
        isLoading={partnerRequest.isLoading}
        useAction={false}
      >
        {partnerRequest.isSuccess &&
          partnerRequest.data &&
          partnerRequest.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell {...GlobalStyle.keepTextTable}>{table.chargePointName || '-'}</TableCell>
              <TableCell>{table.carName || '-'}</TableCell>
              <TableCell>{table.chargeBoxGroupCode || '-'}</TableCell>
              <TableCell>{table.chargeBoxCode || '-'}</TableCell>
              <TableCell>{table.status || '-'}</TableCell>
              <TableCell {...GlobalStyle.EllipsisTable}>
                {table.requestedDate
                  ? dayjs(table.requestedDate).format('DD-MM-YYYY HH:mm:ss')
                  : '-'}
              </TableCell>
              <TableCell {...GlobalStyle.EllipsisTable}>
                {table.chargingStartTime
                  ? dayjs(table.chargingStartTime).format('DD-MM-YYYY HH:mm:ss')
                  : '-'}
              </TableCell>
              <TableCell>{table.chargingTimeElapsed || '-'}</TableCell>
              <TableCell>{table.chargingKwhElapsed || '-'}</TableCell>
              <TableCell>
                {table.requiredAuthorization ? (
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Button
                      variant='contained'
                      onClick={() => {
                        setTransactionNo(table.transactionCode)
                        setSubmitAuthorize(true)
                      }}
                    >
                      Authorize
                    </Button>
                    <Button
                      variant='contained'
                      color='error'
                      onClick={() => {
                        setTransactionNo(table.transactionCode)
                        setSubmitReject(true)
                      }}
                    >
                      Reject
                    </Button>
                  </Box>
                ) : (
                  '-'
                )}
              </TableCell>
              <TableCell>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Button
                    variant='contained'
                    disabled={!table.startAvailable}
                    onClick={() => {
                      // setEvData({
                      //   chargeBoxId: table.chargeBoxId,
                      //   vehicleId: table.vehicleId,
                      //   policyId: table.policyId
                      // })
                      setTransactionNo(table.transactionCode)
                      setSubmitStartCharging(true)
                    }}
                  >
                    Start
                  </Button>
                  <Button
                    variant='contained'
                    color='error'
                    disabled={!table.stopAvailable}
                    onClick={() => {
                      setTransactionNo(table.transactionCode)
                      setSubmitStopCharging(true)
                    }}
                  >
                    Stop
                  </Button>
                </Box>
              </TableCell>
            </TableRow>
          ))}
      </Content>

      {partnerRequest.isLoading && <Loading />}

      <ModalConfirm
        title='Set kWh Limit'
        open={submitKWhDialog}
        onClose={() => setSubmitKWhDialog(false)}
        onSubmit={onSubmit}
        loading={kwhLimitRequest.isLoading}
        text='Set kWh Limit'
      />

      <ModalConfirm
        title='Authorize'
        open={submitAuthorize}
        onClose={() => setSubmitAuthorize(false)}
        onSubmit={() => authEvHistoryPartnerRequest({ transactionNo, approvalType: 0 })}
        loading={authPartnerRequest.isLoading}
        text='Authorize'
      />

      <ModalConfirm
        title='Reject'
        open={submitReject}
        onClose={() => setSubmitReject(false)}
        onSubmit={() => authEvHistoryPartnerRequest({ transactionNo, approvalType: 1 })}
        loading={authPartnerRequest.isLoading}
        text='Reject'
      />

      <ModalConfirm
        title='Start Charging'
        open={submitStartCharging}
        onClose={() => setSubmitStartCharging(false)}
        // onSubmit={() => startEvHistoryPartnerCharging(evData)}
        onSubmit={() => authEvHistoryPartnerRequest({ transactionNo, approvalType: 0 })}
        loading={authPartnerRequest.isLoading}
        text='Start Charging'
      />

      <ModalConfirm
        title='Stop Charging'
        open={submitStopCharging}
        onClose={() => setSubmitStopCharging(false)}
        onSubmit={() =>
          stopEvHistoryPartnerCharging({
            transactionNo,
            reasonStopType: 2,
            remarks: 'web'
          })
        }
        loading={stopPartnerCharging.isLoading}
        text='Stop Charging'
      />

      <Notification
        open={!kwhLimitRequest.isLoading && !kwhLimitRequest.isUninitialized}
        onClose={() => (kwhLimitRequest.isError ? kwhLimitRequest.reset() : location.reload())}
        isError={Boolean(kwhLimitRequest.error) && kwhLimitRequest.isError}
        message={GLOBAL.returnExceptionMessage(
          kwhLimitRequest.isError,
          kwhLimitRequest.error as ErrorProps
        )}
      />

      <Notification
        open={
          !partnerRequest.isLoading && !partnerRequest.isUninitialized && !partnerRequest.isSuccess
        }
        onClose={() => (partnerRequest.isError ? partnerRequest.reset() : location.reload())}
        isError={Boolean(partnerRequest.error) && partnerRequest.isError}
        message={GLOBAL.returnExceptionMessage(
          partnerRequest.isError,
          partnerRequest.error as ErrorProps
        )}
      />

      <Notification
        open={!authPartnerRequest.isLoading && !authPartnerRequest.isUninitialized}
        onClose={() =>
          authPartnerRequest.isError ? authPartnerRequest.reset() : location.reload()
        }
        isError={Boolean(authPartnerRequest.error) && authPartnerRequest.isError}
        message={GLOBAL.returnExceptionMessage(
          authPartnerRequest.isError,
          authPartnerRequest.error as ErrorProps
        )}
      />

      {/* <Notification
        open={!startPartnerCharging.isLoading && !startPartnerCharging.isUninitialized}
        onClose={() =>
          startPartnerCharging.isError ? startPartnerCharging.reset() : location.reload()
        }
        isError={Boolean(startPartnerCharging.error) && startPartnerCharging.isError}
        message={GLOBAL.returnExceptionMessage(
          startPartnerCharging.isError,
          startPartnerCharging.error as ErrorProps
        )}
      /> */}

      <Notification
        open={!stopPartnerCharging.isLoading && !stopPartnerCharging.isUninitialized}
        onClose={() =>
          stopPartnerCharging.isError ? stopPartnerCharging.reset() : location.reload()
        }
        isError={Boolean(stopPartnerCharging.error) && stopPartnerCharging.isError}
        message={GLOBAL.returnExceptionMessage(
          stopPartnerCharging.isError,
          stopPartnerCharging.error as ErrorProps
        )}
      />
    </>
  )
}

export default EVRequest
