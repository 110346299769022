import type { ContainerProps, StackProps } from '@mui/material'

class DetailStyle {
  static Container: ContainerProps = {
    maxWidth: false,
    disableGutters: true,
    sx: {
      display: 'flex',
      padding: '16px 16px 32px 16px',
      gap: '1rem',
      flexDirection: 'column'
    }
  }

  static Stack: StackProps = {
    direction: 'column',
    spacing: 2
  }
}

export default DetailStyle
