import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Autocomplete,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import CONSTANT from 'modules/constant'
import Icon from 'components/icon'
import MenuModalItem from 'components/menuItem/menuItem'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { ErrorProps } from 'modules/types'
import GlobalStyle from 'modules/styles'
import {
  useDeleteTokenCategoryMutation,
  useDownloadTemplateCategoryMutation,
  useDownloadTokenCategoryMutation,
  useEnableTokenCategoryMutation,
  useGetListTokenCategoryMutation,
  useGetTokenPartnerDropdownMutation
} from 'store/token'
import type { PartialTokenCategoryProps } from 'modules/partial'
import Create from './create/create'
import Detail from './detail/detail'
import Edit from './edit/edit'
import Upload from './upload/upload'

const TokenCategory = () => {
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [dialogCreate, setDialogCreate] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [dialogUpdate, setDialogUpdate] = useState<boolean>(false)
  const [dialogUpload, setDialogUpload] = useState<boolean>(false)
  const [menu, setMenu] = useState<null | number>(null)
  const [getListTokenCategory, tokenCategory] = useGetListTokenCategoryMutation()
  const [downloadTokenCategory, download] = useDownloadTokenCategoryMutation()
  const [deleteTokenCategory, deleteCategory] = useDeleteTokenCategoryMutation()
  const [enableTokenCategory, enableCategory] = useEnableTokenCategoryMutation()
  const [getTokenPartnerDropdown, dropdownPartner] = useGetTokenPartnerDropdownMutation()
  const [downloadTemplateCategory, template] = useDownloadTemplateCategoryMutation()
  // const [dialogUpload, setDialogUpload] = useState<boolean>(false)

  const [payload, setPayload] = useState<PartialTokenCategoryProps>({
    start: 0,
    length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    field: CONSTANT.DEFAULT_TOKEN_CATEGORY_FIELD.join(','),
    search: { value: '' },
    status: '',
    monetaryValue: '',
    companyId: ''
  })

  const [filter, setFilter] = useState({
    companyId: '',
    monetaryValue: '',
    status: ''
  })

  const onSearch = (value: string) => setPayload({ ...payload, start: 0, search: { value } })
  const onResync = () => {
    const formData = new FormData()
    formData.append('start', payload.start.toString())
    formData.append('length', payload.length.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(filter.companyId)) formData.append('companyId', filter.companyId.toString())
    if (!isEmpty(filter.monetaryValue))
      formData.append('monetaryValue', filter.monetaryValue.toString())
    if (!isEmpty(filter.status)) formData.append('status', filter.status)

    getListTokenCategory(formData)
  }
  const onFilter = (state: boolean) => setDialogFilter(state)
  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('start', String(0))
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())
    formData.append('field', payload.field)

    if (!isEmpty(payload.search.value)) formData.append('search[value]', payload.search.value)
    if (!isEmpty(filter.status)) formData.append('status', filter.status)
    if (!isEmpty(filter.companyId)) formData.append('companyId', filter.companyId.toString())
    if (!isEmpty(filter.monetaryValue))
      formData.append('monetaryValue', filter.monetaryValue.toString())

    if (type && !isEmpty(type)) formData.append('Download-Type', type)

    downloadTokenCategory(formData)
  }
  const onCreate = () => setDialogCreate(true)

  const onChangePage = (value: number) => setPayload({ ...payload, start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, length: value })

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  // const dataStatus = [
  //   { value: 'Enabled', name: 'Enabled' },
  //   { value: 'Disabled', name: 'Disabled' }
  // ]

  const tableHeadTitles = [
    'Token Category Name',
    'Partner Name',
    'Partner Sort Name',
    'Monetary Value',
    'Status'
  ]

  const openAnchor = Boolean(anchor)

  const totalPage = tokenCategory.data && GLOBAL.tableTotalPage(tokenCategory.data.recordsTotal)

  const numbers = GLOBAL.tableNumber(payload.start)

  useEffect(() => {
    onResync()
  }, [payload])

  // const onUpload = () => setDialogUpload(true)

  const onDownloadTemplate = (type?: string) => {
    const formData = new FormData()
    if (type && !isEmpty(type)) formData.append('Download-Type', type)
    downloadTemplateCategory(formData)
  }

  return (
    <>
      <Content
        title='Token Category'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        onAdditional={onCreate}
        additionalTitle='Create New Token Category'
        additionalPrefixIcon='Add'
        onUpload={() => setDialogUpload(true)}
        uploadTitle='Upload CSV'
        uploadPrefixIcon='FileUpload'
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={tokenCategory.data && tokenCategory.data.recordsTotal}
        page={payload.start + 1}
        isLoading={tokenCategory.isLoading}
        onTemplateDownload={onDownloadTemplate}
        templateDownloadLoading={template.isLoading}
        templateTitle='Template Category'
        useDownloadDropdown
        useTemplateDownloadDropdown
      >
        {tokenCategory.isSuccess &&
          tokenCategory.data &&
          tokenCategory.data.data.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${item.id}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${item.id}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${item.id}`}
                  aria-labelledby={`button-${index}-${item.id}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View Detail
                  </MenuItem>
                  <MenuItem onClick={() => onRemoveAnchor(() => setDialogUpdate(true))}>
                    Edit
                  </MenuItem>
                  <MenuModalItem
                    title={item.status == 'Enabled' ? 'Disable' : 'Enable'}
                    onClick={() =>
                      onRemoveAnchor(() =>
                        enableTokenCategory({
                          Id: item?.id || '',
                          Status: item.status == 'Enabled' ? 'Disabled' : 'Enabled'
                        })
                      )
                    }
                  >
                    {item.status == 'Enabled' ? 'Disable' : 'Enable'}
                  </MenuModalItem>
                  <MenuModalItem
                    title='Delete'
                    loading={deleteCategory.isLoading}
                    onClick={() => onRemoveAnchor(() => deleteTokenCategory({ Id: item.id || '' }))}
                  >
                    <Typography {...GlobalStyle.ErrorTypography}>Delete</Typography>
                  </MenuModalItem>
                </Menu>
                {drawerDetail && menu == index && (
                  <Detail
                    id={item.id || ''}
                    open={drawerDetail && menu == index}
                    onClose={() => setDrawerDetail(false)}
                  />
                )}
                {dialogUpdate && menu == index && (
                  <Edit
                    id={item.id || ''}
                    open={dialogUpdate && menu == index}
                    onClose={() => setDialogUpdate(false)}
                  />
                )}
              </TableCell>
              <TableCell>{item.nameEn || '-'}</TableCell>
              <TableCell>{item.companyName || '-'}</TableCell>
              <TableCell>{item.companyShortName || '-'}</TableCell>
              <TableCell>{item.monetaryValue.toLocaleString('ID') || '-'}</TableCell>
              <TableCell>
                <Chip label={item.status} color={item.status == 'Enabled' ? 'success' : 'error'} />
              </TableCell>
            </TableRow>
          ))}
      </Content>
      {tokenCategory.isLoading && <Loading />}

      <Filter
        open={dialogFilter}
        title='Filter'
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        onReset={() => {
          setFilter({
            companyId: '',
            monetaryValue: '',
            status: ''
          })
          setPayload({ ...payload, start: 0, ...filter })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({ ...payload, start: 0, ...filter })
          onFilter(false)
        }}
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
      >
        <Autocomplete
          options={(dropdownPartner && dropdownPartner.data) || []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.id == value.id : false
          }
          onOpen={() => getTokenPartnerDropdown()}
          onChange={(_, companyId) =>
            setFilter({
              ...filter,
              companyId: (companyId && String(companyId.id)) || ''
            })
          }
          ListboxProps={GlobalStyle.ListBox}
          value={
            dropdownPartner &&
            dropdownPartner.data &&
            dropdownPartner.data.find((e) => e.id == Number(filter.companyId))
          }
          renderOption={(props, item) => (
            <li {...props} key={item.id}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='companyId'
              label='Select A Partner'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {dropdownPartner.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />

        {/* <Autocomplete
          options={dataStatus || []}
          getOptionLabel={(list) => list.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.value == value.value : false
          }
          onOpen={() => getTokenPartnerDropdown()}
          onChange={(_, id) => {
            if (id) {
              setFilter({
                ...filter,
                status: id?.value
              })
            }
          }}
          ListboxProps={GlobalStyle.ListBox}
          value={dataStatus && dataStatus.find((e) => e.value == filter.status)}
          renderOption={(props, item) => (
            <li {...props} key={item.value}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='status'
              label='Select A Status'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {dropdownPartner.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        /> */}
        <Grid>
          <FormControl fullWidth>
            <InputLabel id='select-label'>Select A Status</InputLabel>
            <Select
              labelId='select-label'
              id='id-select-label'
              value={filter.status}
              label='Select A Status'
              onChange={(event) => setFilter({ ...filter, status: event.target.value })}
            >
              <MenuItem value='Enabled'>Enabled</MenuItem>
              <MenuItem value='Disabled'>Disabled</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Filter>
      {dialogCreate && <Create open={dialogCreate} onClose={() => setDialogCreate(false)} />}
      {dialogUpload && <Upload open={dialogUpload} onClose={() => setDialogUpload(false)} />}

      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />

      <Notification
        open={!deleteCategory.isLoading && !deleteCategory.isUninitialized}
        onClose={() => (deleteCategory.isError ? deleteCategory.reset() : location.reload())}
        isError={Boolean(deleteCategory.error) && deleteCategory.isError}
        message={GLOBAL.returnExceptionMessage(
          deleteCategory.isError,
          deleteCategory.error as ErrorProps
        )}
      />
      <Notification
        open={!enableCategory.isLoading && !enableCategory.isUninitialized}
        onClose={() => (enableCategory.isError ? enableCategory.reset() : location.reload())}
        isError={Boolean(enableCategory.error) && enableCategory.isError}
        message={GLOBAL.returnExceptionMessage(
          enableCategory.isError,
          enableCategory.error as ErrorProps
        )}
      />
    </>
  )
}

export default TokenCategory
